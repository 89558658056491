/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { useMemo } from "react";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import GroupSelectionDataType from "../../../data-model/GroupSelectionDataType";
import Theme from "../../../data-model/types/professional/Theme";
import { IFormState } from "../../hooks/useFormState";
import { I18Namespaces } from "../../language/I18Namespaces";
import GroupedChipList, { ChipSize, IChip } from "../form/GroupedChipList";
import { ISelectObject } from "../form/select/BaseSimpleSelect";
import MultiGroupSelect from "../form/select/MultiGroupSelect";
import InlineEditableControls from "./common/InlineEditableControls";

interface IEAGroupSelectableProps<K, T extends GroupSelectionDataType<K, any>> {
  cancelBtnTestId?: string;
  chipListTestId?: string;
  dataObject: T;
  editBtnTestId?: string;
  formState: IFormState;
  label: string;
  labelTestId?: string;
  menuWithTooltip?: boolean;
  onSave?: () => void | Promise<void>;
  placeholderText?: string | null;
  saveBtnTestId?: string;
  selectTestId?: string;

  /**
   * Optional function that performs translation, and should accept a "full" nexus key (namepace:key) as 'item'
   */
  translateFunction?: TFunction<I18Namespaces>;
}

const getChipsFromDataobject = <K, T extends GroupSelectionDataType<K, any>>(
  dataObject: T,
  translate: TFunction<I18Namespaces>,
  chipSize?: ChipSize,
): Array<IChip> => {
  const selectedChips: ISelectObject<string>[] =
    dataObject.getSelectedOptions(translate);

  if (!selectedChips || selectedChips.length === 0) return [];

  return selectedChips.map((selectedOption) => ({
    checkMark: false,
    color: selectedOption.color ?? "#000001",
    fadeOut: false,
    label: selectedOption.label,
    size: chipSize ?? ChipSize.large,
    tooltip: selectedOption.toolTip ? translate(selectedOption.toolTip) : "",
  }));
};

export default function InlineEditableMultiGroupSelect<
  K,
  T extends GroupSelectionDataType<K, any>,
>({
  label,
  placeholderText,
  dataObject,
  formState,
  onSave,
  translateFunction,
  labelTestId,
  editBtnTestId,
  saveBtnTestId,
  cancelBtnTestId,
  chipListTestId,
  menuWithTooltip,
}: IEAGroupSelectableProps<K, T>) {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  const _translate = useMemo(
    () => translateFunction ?? translate,
    [translate, translateFunction],
  );

  const path: string = dataObject.getType().getPath();

  const chips = useMemo(() => {
    return getChipsFromDataobject<K, T>(dataObject, translate);
  }, [dataObject, translate]);

  const formStateInputProps = useMemo(
    () => formState.getInputProps(path),
    [formState],
  );

  return (
    <Box mt={3}>
      <InlineEditableControls
        cancelBtnTestId={cancelBtnTestId}
        edit={
          formState ? (
            <MultiGroupSelect<string, Theme>
              closeMenuOnSelect
              options={dataObject.getSelectOptions(_translate)}
              placeholder={placeholderText}
              {...formState.getInputProps(path)}
              menuWithTooltip={menuWithTooltip}
              onChange={(selected) => {
                formStateInputProps.onChange(selected);
              }}
            />
          ) : null
        }
        editBtnTestId={editBtnTestId}
        editable={formState !== undefined}
        label={label || _translate("common:-", "-")}
        labelTestId={labelTestId}
        onDiscard={() => {
          formState.setValue(path, chips);

          return true;
        }}
        onSave={async () => {
          if (formState.validate(path)) {
            if (onSave) await onSave();

            return true;
          } else {
            return false;
          }
        }}
        read={<GroupedChipList chipListTestId={chipListTestId} chips={chips} />}
        saveBtnTestId={saveBtnTestId}
      />
    </Box>
  );
}
