import { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import { IWithChildren } from "../../../baseInterfaces";
import AuthContext from "../../providers/auth/AuthContext";
import { IAuthContext } from "../../providers/auth/auth";
import OnlineContext from "../../../utils/pusher/channels/context/OnlineContext";
import LoadingPage from "../LoadingPage";
import Sidebar from "./Sidebar";

const SidebarWithState = ({ children }: IWithChildren) => {
  const { currentUser, logout } = useContext<IAuthContext>(AuthContext);
  const { unsubscribeFromAllChannels } = useContext(OnlineContext);
  const [isMenuOpen, openMenu] = useState(false);

  const logoutAndUnsubscribe = async () => {
    unsubscribeFromAllChannels();
    logout();
  };

  if (!currentUser) return <LoadingPage full />;

  return (
    <Grid
      component="main"
      container
      sx={(theme) => ({
        background: theme.palette.white.light,
        height: "100vh",
        overflow: "hidden",

        [theme.breakpoints.down("sm")]: {
          height: "100%",
          marginBottom: theme.spacing(5),
        },
      })}
    >
      <Sidebar
        currentUser={currentUser}
        isMenuOpen={isMenuOpen}
        logout={logoutAndUnsubscribe}
        openMenu={openMenu}
      />
      <Grid
        container
        item
        md={9}
        sm={9}
        sx={(theme) => ({
          background: theme.palette.white.light,
          height: "100vh",
          overflow: "hidden",

          [theme.breakpoints.down("md")]: {
            overflowY: "auto",
          },

          [theme.breakpoints.down("sm")]: {
            height: "calc(100vh - 80px - 72px)",
          },
        })}
        wrap="wrap"
        xs={12}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default SidebarWithState;
