import { useTranslation } from "react-i18next";
import Human from "../../../data-model/types/human/Human";
import { I18Namespaces } from "../../language/I18Namespaces";
import Professional from "../../../data-model/types/professional/Professional";
import EmptyList from "../../ui/EmptyList";
import { RouteNames } from "../../routes/routeNames";
import AddConsultationForm from "./AddConsultationForm";

interface IAddConsultationPage {
  clients: Array<Human>;
  formState: any;
  onSubmit: () => void;
  professional: Professional;
  submitting: boolean;
}

export default function AddConsultationPage({
  professional,
  clients,
  formState,
  onSubmit,
  submitting,
}: IAddConsultationPage) {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  return clients.length ? (
    <AddConsultationForm
      clients={clients}
      formState={formState}
      onSubmit={onSubmit}
      professional={professional}
      submitting={submitting}
    />
  ) : (
    <EmptyList
      description={translate("common:add_consultation.description")}
      navigationRoute={RouteNames.Home.Clients.AddClient.path}
      submitLabel={translate("common:add_consultation.submit_label")}
      title={translate("common:add_consultation.title")}
    />
  );
}
