import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import "moment-timezone";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import ConsultationBooker from "../../../../pages/professional-call/components/ConsultationBooker";
import AddConsultationInCallProvider from "../../../../pages/professional-call/context/AddConsultationInCallProvider";
import { IClientInformationDrawerContext } from "../../../../pages/professional-call/context/ClientInformationDrawer";
import ClientInformationDrawerContext from "../../../../pages/professional-call/context/ClientInformationDrawerContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import Consultation from "../../../../../data-model/types/consultation/Consultation";
import SidePane from "./SidePane";

interface IStandardConsultationBookerSidePane {
  consultation: Consultation;
}

const StandardConsultationBookerSidePane = ({
  consultation,
}: IStandardConsultationBookerSidePane) => {
  const { professionalConsultations } =
    useContext<IClientInformationDrawerContext>(ClientInformationDrawerContext);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
  ]);

  return (
    <SidePane
      TitleIcon={MoreHorizIcon}
      title={translate(
        "ui:sidepane.menu.book.consultation.title",
        "Plan gesprek",
      )}
    >
      <AddConsultationInCallProvider
        consultation={consultation}
        professionalConsultations={professionalConsultations}
      >
        <ConsultationBooker />
      </AddConsultationInCallProvider>
    </SidePane>
  );
};

export default StandardConsultationBookerSidePane;
