export const modules = [
  {
    id: 0,
    title: { en: "Sleep better", fr: "Mieux dormir", nl: "Beter slapen" },
  },
  {
    id: 1,
    title: {
      en: "Feeling Great",
      fr: "Bien dans sa peau",
      nl: "Goed in je vel",
    },
  },
  // {
  //   id: 2,
  //   title: {
  //     en: "Coping with Covid-19",
  //     fr: "Gestion du Covid-19",
  //     nl: "Omgaan met Covid-19",
  //   },
  // },
  {
    id: 3,
    title: { en: "Depression", fr: "Dépression", nl: "Stemming (Depressie)" },
  },
  // {
  //   id: 4,
  //   title: { en: "Health Anxiety", fr: "Hypocondrie", nl: "Ziektevrees" },
  // },
  // {
  //   id: 5,
  //   title: {
  //     en: "Mindfulness and Meditation",
  //     fr: "Pleine conscience et méditation",
  //     nl: "Mindfulness en meditatie",
  //   },
  // },
  {
    id: 6,
    title: { en: "Burnout", fr: "Burnout", nl: "Burn-out" },
  },
  {
    id: 7,
    title: {
      en: "Dealing with Emotions",
      fr: "Gestion des émotions",
      nl: "Emotiehantering",
    },
  },
  {
    id: 8,
    title: {
      en: "Less Stress",
      fr: "Gérer son stress",
      nl: "Omgaan met stress",
    },
  },

  // {
  //   id: 9,
  //   title: {
  //     en: "Stress Management",
  //     fr: "Gérer son stress",
  //     nl: "Omgaan met stress",
  //   },
  // },

  // {
  //   id: 10,
  //   title: {
  //     en: "Panic Attacks",
  //     fr: "Crises de panique",
  //     nl: "Paniekaanvallen",
  //   },
  // },

  // {
  //   id: 11,
  //   title: { en: "Self-care", fr: "Prendre soin de soi", nl: "Zelfzorg" },
  // },
  { id: 12, title: { en: "...", fr: "...", nl: "..." } },
];

export const skills = [
  {
    id: 0,
    title: {
      en: "Making the Right Choice",
      fr: "Le stress du choix",
      nl: "Keuzestress",
    },
  },
  {
    id: 1,
    title: {
      en: "Time Management",
      fr: "Gestion du temps",
      nl: "Time Management",
    },
  },
  {
    id: 2,
    title: { en: "Communication", fr: "Communication", nl: "Communicatie" },
  },
  {
    id: 3,
    title: {
      en: "Servant Leadership",
      fr: "Dienend leiderschap",
      nl: "Dienend leiderschap",
    },
  },
  {
    id: 4,
    title: { en: "Assertiveness", fr: "Assertivité", nl: "Assertiviteit" },
  },
  //   {
  //     id: 5,
  //     title: { en: "Ergonomics", fr: "L'ergonomie", nl: "Ergonomie" },
  //   },
  {
    id: 6,
    title: {
      en: "Better Breathing",
      fr: "La respiration",
      nl: "De juiste ademhaling",
    },
  },
];

export const tests = [
  { id: 0, title: { en: "Sleep", fr: "Sommeil", nl: "Slaap" } },
  { id: 1, title: { en: "Depression", fr: "Dépression", nl: "Depressie" } },
  { id: 2, title: { en: "Anxiety", fr: "Anxiété", nl: "Angst" } },
  {
    id: 3,
    title: {
      en: "Match test",
      fr: "Test d'Match",
      nl: "Oriëntatietest",
    },
  },
  {
    id: 4,
    title: { en: "Self image", fr: "L'estime de soi", nl: "Zelfbeeld" },
  },
];
