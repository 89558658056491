import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import AddressFields from "../../../ui/form/AddressFields";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import LoadingPage from "../../../layout/LoadingPage";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import Label from "../../../ui/form/FormLabel";
import TextField from "../../../ui/form/TextField";
import BillingAddress from "../../../../data-model/types/billing/BillingAddress";
import AccountNumber from "../../../../data-model/types/billing/AccountNumber";

const AddressStep = () => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
  ]);

  const { next, previous, step, totalSteps, formState } = useContext(
    ProfessionalOnboardingContext,
  );

  if (!formState) return <LoadingPage />;

  const error =
    formState.testForErrors(BillingAddress.getPath()) ||
    formState.testForErrors(AccountNumber.getPath());

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("professional:onboarding.billing.description")}
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title")}
        labelBack={translate("common:onboarding.button.label.previous")}
        labelNext={translate("common:onboarding.button.label.next")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate("professional:onboarding.billing.title")}
        subheadicon={<HomeWorkOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Label
          label={translate(
            "professional:onboarding.billing.adressfields.label",
          )}
        />
        <AddressFields
          autoFocus
          {...formState.getInputProps(BillingAddress.getPath())}
          isMulti={false}
          postcodeTextFieldTestId={
            "test_onboarding_professional_billing_postcode"
          }
          streetNameTextFieldTestId={
            "test_onboarding_professional_billing_street_name"
          }
        />
        <Label
          label={translate(
            "professional:onboarding.billing.bankaccountnumberfield.label",
          )}
        />
        <TextField
          placeholder={translate(
            "professional:onboarding.billing.bankaccountnumberfiled.placeholder",
          )}
          style={{
            marginBottom: 20,
          }}
          {...formState.getInputProps(AccountNumber.getPath())}
          autoUpperCase={true}
          id={`test_onboarding_professional_visum_number_textfield`}
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
};

export default AddressStep;
