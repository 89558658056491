import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ScreenShareOutlinedIcon from "@mui/icons-material/ScreenShareOutlined";
import {
  useContentShareControls,
  useContentShareState,
} from "amazon-chime-sdk-component-library-react";
import "moment-timezone";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IWithChildren } from "../../../../../baseInterfaces";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import { ChimeVideoCall } from "../../ChimeVideoCall.types";
import { ICallContext } from "../../context/Call";
import CallContext from "../../context/CallContext";
import MenuItemSidePane from "./MenuItemSidePane";
import SidePane from "./SidePane";

const StandardMenuSidePane = ({ children }: IWithChildren) => {
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Ui]);

  const { setPaneToPresent } = useContext<ICallContext>(CallContext);

  const { toggleContentShare } = useContentShareControls();

  const { isLocalUserSharing } = useContentShareState();

  return (
    <SidePane TitleIcon={MoreHorizIcon} title={t("ui:sidepane.menu.title")}>
      {children}
      <MenuItemSidePane
        Icon={ScreenShareOutlinedIcon}
        onClick={toggleContentShare}
        text={
          isLocalUserSharing
            ? t("ui:sidepane.menu.item.stop.screenshare")
            : t("ui:sidepane.menu.item.screenshare")
        }
      />
      <MenuItemSidePane
        Icon={DevicesOutlinedIcon}
        onClick={() => {
          setPaneToPresent(ChimeVideoCall.SidePaneProfessional.settingsPane);
        }}
        text={t("ui:sidepane.menu.videoaudio.title")}
      />
    </SidePane>
  );
};

export default StandardMenuSidePane;
