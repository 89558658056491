import { useCallback } from "react";
import TextField from "../../ui/form/TextField";
import { SharedAnswerProps } from "./answers";

export default function AnswerOpen({ onAnswerChanged }: SharedAnswerProps) {
  //const [answer, setAnswer] = useState<string>("");

  const onChange = useCallback(
    (answer) => {
      //setAnswer(answer);
      onAnswerChanged([answer]);
    },
    [onAnswerChanged],
  );

  //const answer = selectedAnswers ? selectedAnswers[0] : "";

  return (
    <TextField
      autoFocus
      inputProps={{ maxLength: 400 }}
      multiline={true}
      onChange={onChange}
      rows={5}
      style={{ maxWidth: "80%" }}
    />
  );
}
