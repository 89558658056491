import { Box, Typography, useTheme } from "@mui/material";
import { graphql } from "../../../../api/__generated__";
import { useQuery } from "@apollo/client";
import { IntroDetails } from "../Match";
import { Fragment, useContext, useEffect, useState } from "react";
import AuthContext from "../../../providers/auth/AuthContext";
import StepHeader from "../Common/StepHeader";
import StepMainContent from "../Common/StepMainContent";
import Footer from "../Common/Footer";
import { useTranslation } from "react-i18next";
import ProfCard from "./ProfCard";
import Divider from "./Divider";
import RefreshIcon from "@mui/icons-material/Refresh";
import ConsultationRules from "./ConsultationRules";

const proposedMatchesQuery = graphql(`
  query ProposedMatches {
    currentMatchedHuman {
      id
      ...ProfCardMatchedHuman
      proposedMatches {
        id
        ...ProfCardProf
      }
      subtopic {
        id
        titleTranslationKey
      }
    }
  }
`);

const ChooseProfStep = ({
  bookIntroConsult,
  setIntroDetails,
  stepBack,
  stepForward,
}: {
  bookIntroConsult: (introDetails: IntroDetails) => Promise<void>;
  setIntroDetails: (introDetails: IntroDetails) => void;
  stepBack: () => void;
  stepForward: (nextStep: string) => void;
}) => {
  const { data: matchesData, loading } = useQuery(proposedMatchesQuery, {
    fetchPolicy: "network-only",
  });

  const [timeoutCompleted, setTimeoutCompleted] = useState(false);
  const [showExtra, setShowExtra] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimeoutCompleted(true);
    }, 4000);
  }, []);

  const theme = useTheme();
  const { t } = useTranslation();
  const { currentUser } = useContext(AuthContext);

  const book = async (introDetails: IntroDetails) => {
    setIntroDetails(introDetails);

    if (currentUser) {
      await bookIntroConsult(introDetails);

      stepForward("endMatching");
    } else {
      stepForward("register");
    }
  };

  if (!timeoutCompleted || loading) {
    return (
      <>
        <StepHeader
          imageUrl={
            "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D48-talk-to-professional%2C+Size%3DSmall.png"
          }
          subtitle={
            matchesData?.currentMatchedHuman?.subtopic?.titleTranslationKey &&
            t(matchesData.currentMatchedHuman.subtopic.titleTranslationKey)
          }
          title={t("match:treatmentStep.treatments.coaching.title")}
        />
        <StepMainContent>
          <Box sx={{ textAlign: "center" }}>
            <Box
              component={"img"}
              src="https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D76-selfhelp%2C+Size%3DSmall.png"
              sx={{
                maxHeight: "320px",
                maxWidth: "520px",
                [theme.breakpoints.down(500)]: {
                  maxHeight: "190px",
                  maxWidth: "312px",
                },
              }}
            />
            <Box sx={{ marginTop: "50px" }}>
              <Typography variant="h2">
                {t("match:loadingStep.body.title")}
              </Typography>
              <Typography variant="body1">
                {t("match:loadingStep.body.caption")}
              </Typography>
            </Box>
          </Box>
        </StepMainContent>
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <StepHeader
          imageUrl={
            "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D75-coaching%2C+Size%3DSmall.png"
          }
          subtitle={
            matchesData?.currentMatchedHuman?.subtopic?.titleTranslationKey &&
            t(matchesData.currentMatchedHuman.subtopic.titleTranslationKey)
          }
          title={t("match:treatmentStep.treatments.coaching.button")}
        />
        <StepMainContent
          nextButtonIcon={<RefreshIcon />}
          nextButtonLabel={t("match:chooseProfStep.showMore")}
          onClickNext={
            !showExtra &&
            (matchesData?.currentMatchedHuman?.proposedMatches.length ?? 0) > 3
              ? () => {
                  setShowExtra(true);
                }
              : undefined
          }
          onClickPrevious={stepBack}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              padding: "50px 200px 150px",
              [theme.breakpoints.down(950)]: {
                padding: "40px 40px",
              },
            }}
          >
            <Typography
              sx={{
                marginBottom: "50px",
                maxWidth: "830px",
                whiteSpace: "pre-line",
              }}
              variant="body1"
            >
              {t("match:chooseProfStep.body.disclaimer")}
            </Typography>
            {matchesData?.currentMatchedHuman?.proposedMatches
              .slice(0, showExtra ? 6 : 3)
              .map((professional, index, row) => (
                <Fragment key={professional.id}>
                  <ProfCard
                    bookIntroConsult={book}
                    matchedHuman={matchesData.currentMatchedHuman ?? undefined}
                    prof={professional}
                  />
                  {index + 1 !== row.length && <Divider />}
                </Fragment>
              ))}
          </Box>
        </StepMainContent>
        <ConsultationRules />
        <Footer />
      </>
    );
  }
};

export default ChooseProfStep;
