import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import AddressFields from "../../../ui/form/AddressFields";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import LoadingPage from "../../../layout/LoadingPage";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import Address from "../../../../data-model/types/profile/Address";

const AddressStep = () => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
  ]);

  const { next, previous, step, totalSteps, formState } = useContext(
    ProfessionalOnboardingContext,
  );

  if (!formState) return <LoadingPage />;

  const error = formState.testForErrors(Address.getPath());

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("professional:onboarding.address.description")}
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title")}
        labelBack={translate("common:onboarding.button.label.previous")}
        labelNext={translate("common:onboarding.button.label.next")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate("professional:onboarding.address.title")}
        subheadicon={<HomeWorkOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <AddressFields
          {...formState.getInputProps(Address.getPath())}
          isMulti={false}
          postcodeTextFieldTestId={
            "test_onboarding_professional_address_postcode"
          }
          streetNameTextFieldTestId={
            "test_onboarding_professional_address_street_name"
          }
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
};

export default AddressStep;
