import { useApolloClient } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import useFetchMe from "../../../api/me/hooks/useFetchMe";
import Human from "../../../data-model/types/human/Human";
import Professional from "../../../data-model/types/professional/Professional";
import FirstName from "../../../data-model/types/profile/FirstName";
import LastName from "../../../data-model/types/profile/LastName";
import Phone from "../../../data-model/types/profile/Phone";
import { UserRoles } from "../../../data-model/types/User";
import { getTokenPayload } from "../../../utils/auth";
import useStorage from "../../hooks/useStorage";
import { APIAuthentication } from "../../../api/auth/auth";
import { APIHuman } from "../../../api/humans/humans";
import { IAuthContext } from "./auth";
import AuthContext from "./AuthContext";

export default function AuthStateProvider({
  loading: defaultLoading,
  isAuthenticated: defaultIsAuthenticated,
  accessToken,
  refreshToken,
  children,
}: IAuthContext) {
  const {
    peristTokensToStorage,
    removeAuthTokensFromStorage: removeTokensFromStorage,
    getAuthTokensFromStorage: getTokensFromStorage,
    getAccessTokenFromStorage,
    removeMatchingTokenFromStorage,
  } = useStorage();

  const apolloclient = useApolloClient();
  const { fetchMe, loading: fetchingMe, user: loggedInUser } = useFetchMe();
  const [loadingData, setLoadingData] = useState<boolean>(defaultLoading);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    defaultIsAuthenticated,
  );

  const [tokens, setTokens] = useState<
    | {
        accessToken: string | undefined;
        refreshToken: string | undefined;
      }
    | undefined
  >({
    accessToken,
    refreshToken,
  });

  const [user, setUser] = useState<Professional | Human | undefined>(undefined);

  const [logoutTimeout, setLogoutTimeout] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  const cleanUpTimeout = useCallback(() => {
    if (logoutTimeout) {
      clearTimeout(logoutTimeout);
      setLogoutTimeout(undefined);
    }
  }, [logoutTimeout]);

  const checkExistingTokens = useCallback(async (): Promise<
    APIAuthentication.Result | undefined
  > => {
    let tokens = await getTokensFromStorage();

    if (tokens) {
      setTokens(tokens);
      setIsAuthenticated(true);
    } else {
      tokens = undefined;
      setTokens(undefined);
      setIsAuthenticated(false);
    }

    return tokens;
  }, [getTokensFromStorage]);

  const internalAppLogin = useCallback(
    async (accessToken: string, refreshToken: string): Promise<boolean> => {
      setLoadingData(true);

      await peristTokensToStorage({ accessToken, refreshToken });
      setTokens({ accessToken, refreshToken });

      const tokenPayload = getTokenPayload(accessToken);

      await fetchMe(tokenPayload.scope);
      setIsAuthenticated(true);
      setLoadingData(false);

      return true;
    },
    [fetchMe, peristTokensToStorage],
  );

  useEffect(() => {
    const loadInitialData = async () => {
      setLoadingData(true);

      const validAccessTokens = await checkExistingTokens();

      if (validAccessTokens) {
        const { accessToken, refreshToken } = validAccessTokens;

        if (accessToken && refreshToken) {
          await internalAppLogin(accessToken, refreshToken);
        }
      }

      setLoadingData(false);
    };

    // Load and check the tokens that were returned from the login call (in login page)
    // into local state. Then start the call for the logged in user's information,
    // using these tokens (passed via apolloclient).
    loadInitialData();

    //Clean up
    return () => cleanUpTimeout();
  }, [
    checkExistingTokens,
    cleanUpTimeout,
    getTokensFromStorage,
    internalAppLogin,
  ]);

  const setCurrentUser = useCallback(
    (user: Professional | Human) => setUser(user),
    [],
  );

  useEffect(() => {
    if (loggedInUser && !fetchingMe) {
      cleanUpTimeout();
      setCurrentUser(loggedInUser);
      setIsAuthenticated(true);
    }
  }, [loggedInUser, fetchingMe, cleanUpTimeout, setCurrentUser]);

  const clearUser = useCallback(() => setUser(undefined), []);

  const getUserRole = useCallback(async (): Promise<UserRoles | undefined> => {
    if (!user) return undefined;

    let role: UserRoles | undefined = user.getRole();

    if (!role) {
      const accessToken = await getAccessTokenFromStorage();

      if (accessToken) {
        const { scope } = getTokenPayload(accessToken);

        role = scope;
      }
    }

    return role;
  }, [getAccessTokenFromStorage, user]);

  const refetchMe = useCallback(async () => {
    const role = await getUserRole();

    if (role === undefined) return;

    fetchMe(role);
  }, [fetchMe, getUserRole]);

  const logout = useCallback(async (): Promise<void> => {
    setTokens({ accessToken: undefined, refreshToken: undefined });
    await Promise.all([
      removeTokensFromStorage(),
      removeMatchingTokenFromStorage(),
    ]);

    clearUser();
    await apolloclient.clearStore();
    setIsAuthenticated(false);
  }, [
    apolloclient,
    clearUser,
    removeMatchingTokenFromStorage,
    removeTokensFromStorage,
  ]);

  const updateCurrentHuman = useCallback(
    async (input: APIHuman.Input) => {
      const updatedUser: Human | Professional = user ? user : new Human();

      updatedUser.setFirstName(new FirstName(input.firstName));
      updatedUser.setLastName(new LastName(input.lastName));
      updatedUser.setPhone(new Phone(input.phone));
      const role = await getUserRole();

      if (role) updatedUser.setRole(role);

      setCurrentUser(updatedUser);
    },
    [getUserRole, setCurrentUser, user],
  );

  return (
    <AuthContext.Provider
      value={{
        accessToken: tokens?.accessToken,
        clearUser,
        currentUser: user,
        internalAppLogin,
        isAuthenticated,
        loading: loadingData || fetchingMe,
        logout,
        refetchMe,
        refreshToken: tokens?.refreshToken,
        setCurrentUser,
        updateCurrentHuman,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
