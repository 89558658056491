import { components } from "react-select";
import Color from "color";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { hexColorCheck } from "../util";

export const ColoredGroupHeading = (props) => {
  const { data, children } = props;
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        borderBottom: `${Color(hexColorCheck(data.color))
          .alpha(0.2)
          .string()} 2px solid`,
      }}
    >
      <components.GroupHeading {...props}>
        <Box sx={{ fontWeight: 600 }}>{t(children)}</Box>
      </components.GroupHeading>
    </Box>
  );
};
