import { useMutation, useQuery } from "@apollo/client";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { graphql } from "../../api/__generated__";
import { I18Namespaces } from "../language/I18Namespaces";
import Button from "../ui/buttons/Button";
import CustomTextField from "../ui/form/CustomTextField";
import Label from "../ui/text/Label";
import SanitizedText from "../ui/text/SanitizedText";
import { useNavigate } from "react-router-dom";

const professionalCancelConsultationQuery = graphql(`
  query ProfessionalCancelConsultation($uuid: String!) {
    consultation(uuid: $uuid) {
      id
    }
  }
`);

const professionalCancelConsultationMutation = graphql(`
  mutation ProfessionalCancelConsultationMutation(
    $input: CancelConsultationInput!
  ) {
    cancelConsultation(input: $input) {
      id
      message {
        body
        id
      }
    }
  }
`);

const ProfessionalCancelConsultation = ({
  onCancel,
  consultationUuid,
  cancelCancellation,
}: {
  cancelCancellation: () => void;
  consultationUuid: string;
  onCancel: () => void;
}) => {
  const { t } = useTranslation<I18Namespaces>(["common"]);
  const navigate = useNavigate();

  const { data } = useQuery(professionalCancelConsultationQuery, {
    variables: {
      uuid: consultationUuid,
    },
  });

  const [cancelConsulation, { loading: cancelling }] = useMutation(
    professionalCancelConsultationMutation,
    {
      onCompleted: () => {
        navigate("/home/consultations");
      },
      update: (cache) => {
        // Cancelling a consultation will affect which filters it appears in
        // We delete all relevant queries from the cache so it will refetch
        // them if rendered
        const cacheData = cache.extract();

        Object.keys(cacheData.ROOT_QUERY).forEach((key) => {
          if (key.startsWith("myProfessionalConsultations")) {
            cache.evict({ fieldName: key, id: "ROOT_QUERY" });
          }
        });

        cache.gc();
      },
    },
  );

  const smallScreen = useMediaQuery("(max-width: 500px)");

  if (!data?.consultation?.id) return null;

  return (
    <Formik
      initialValues={{ message: "" }}
      onSubmit={({ message }) => {
        if (data.consultation?.id) {
          cancelConsulation({
            variables: {
              input: {
                id: data.consultation.id,
                message,
              },
            },
          });
        }

        onCancel();
      }}
      validationSchema={Yup.object({
        message: Yup.string().required("Required"),
      }).required()}
    >
      {(props) => {
        return (
          <Form>
            <Box mt={1}>
              <Box>
                <Label value={t("common:consultation.cancel.comment")} />
                <CustomTextField
                  data-testid="cancel-consultation-message"
                  error={props.errors.message as any}
                  onChange={(event) =>
                    props.setFieldValue("message", event.target.value)
                  }
                  placeholder={t(
                    "common:consultation.cancel.comment_placeholder",
                  )}
                  value={props.values.message}
                />
                <Box mb={3} mt={2}>
                  <SanitizedText
                    input={t("common:consultation.cancellation_policy")}
                  />
                </Box>
              </Box>
            </Box>
            <Box display={"flex"} gap={3} marginBottom={"2rem"} marginTop={6}>
              <Button
                bloomColor="green"
                icon={<CheckCircleIcon />}
                label={t("common:confirm_cancellation")}
                loading={cancelling}
                size={smallScreen ? "medium" : "large"}
                type="submit"
              />
              <Button
                bloomColor="red"
                icon={<CancelIcon />}
                label={t("common:decline_cancellation")}
                onClick={cancelCancellation}
                size={smallScreen ? "medium" : "large"}
              />
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfessionalCancelConsultation;
