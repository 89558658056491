import { Route, Routes } from "react-router-dom";
import RegisterProfessionalPage from "../pages/professional-register/RegisterProfessionalPage";
import RegisterProfessionalVerifyPage from "../pages/professional-register/RegisterProfessionalVerifyPage";
import ProfessionalRegistrationProvider from "../pages/professional-register/context/ProfessionalRegistrationProvider";
import { defaultProfessionalRegistrationValues } from "../pages/professional-register/context/ProfessionalRegistrationContext";
import RegisterProfessionalThankyouPage from "../pages/professional-register/RegisterProfessionalThankyouPage";
import { RouteNames } from "./routeNames";

export default function ProfessionalRegistrationRoutes() {
  return (
    <ProfessionalRegistrationProvider
      {...defaultProfessionalRegistrationValues}
    >
      <Routes>
        <Route element={<RegisterProfessionalPage />} path={""} />
        <Route
          element={<RegisterProfessionalPage />}
          path={RouteNames.Register.Professional.Start.name}
        />
        <Route
          element={<RegisterProfessionalVerifyPage />}
          path={RouteNames.Register.Professional.Verify.name}
        />
        <Route
          element={<RegisterProfessionalThankyouPage />}
          path={RouteNames.Register.Professional.Success.name}
        />
      </Routes>
    </ProfessionalRegistrationProvider>
  );
}
