import moment, { Moment } from "moment";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/en-gb";

moment.locale("nl-BE");

// consultation date display helper

export const callIsOver = (endDate, addMsToEndTime = 0) => {
  const endDiff = new Date(endDate).getTime() + addMsToEndTime - Date.now();

  return endDiff < 0;
};

export const canCancel = (startTime: string | Moment) => {
  return moment().isBefore(moment(startTime).subtract(1, "day"));
};
