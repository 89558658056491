import _ from "lodash";
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import { DataTypePaths } from "../../dataTypePaths";
import SelectionDataType from "../../SelectionDataType";
import { ConsultationStatus as ConsultationStatuses } from "../../../api/__generated__/graphql";
import { allStatuses } from "./ConsultationStatuses";

export default class ConsultationStatus extends SelectionDataType<
  ConsultationStatuses,
  ConsultationStatuses,
  DataTypePaths.Consultation.Status
> {
  protected static path: DataTypePaths.Consultation.Status =
    "consultationStatus";

  protected type: any = ConsultationStatus;
  private translationKey = "common:consultation_status";

  public getSelectOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<ISelectObject<ConsultationStatuses>> {
    const nextStatusses: Array<ConsultationStatuses> | undefined = allStatuses;

    const options: Array<ISelectObject<ConsultationStatuses>> =
      nextStatusses.map((value) => {
        return {
          label: translate(`${this.translationKey}.${value}`),
          value,
        };
      });

    return _.orderBy<ISelectObject<ConsultationStatuses>>(
      options,
      ["label"],
      ["asc"],
    );
  }

  public getSelectedOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<ISelectObject<ConsultationStatuses>> {
    if (this.value === null) return [];

    const selected = this.value;

    return [
      {
        label: translate(`${this.translationKey}.${selected}`),
        value: selected,
      },
    ];
  }

  public getTranslatedValues(
    translate: TFunction<I18Namespaces>,
  ): Array<string> {
    if (this.value === null) return [];

    const selected = this.value;
    const translatedValues = [selected].map((val) =>
      translate(`${this.translationKey}.${val}`),
    );

    return _.orderBy(translatedValues, ["label"], ["asc"]);
  }

  public getOptions(): Array<ConsultationStatuses> {
    return allStatuses;
  }

  /**
   * Was the consultation cancelled?
   *
   * @return {Boolean} The cancelled status of this consultation.
   */
  public isDone(): boolean {
    return this.getValue() === "DONE";
  }

  /**
   * Was the consultation cancelled?
   *
   * @return {Boolean} The cancelled status of this consultation.
   */
  public isCancelled(): boolean {
    return (
      this.getValue() === "CANCELLED" ||
      this.getValue() === "CANCELLED_BY_HUMAN" ||
      this.getValue() === "CANCELLED_BY_PROFESSIONAL"
    );
  }

  /**
   * Has the cliënt or professional confirmed the time slot?
   *
   * @returns {Boolean} Has this consult been accepted? That means that the client or professional confirmed the time.
   */
  public isAccepted(): boolean {
    return this.getValue() === "ACCEPTED";
  }

  public isWaiting(): boolean {
    return this.getValue() === "WAITING";
  }

  /**
   * Is this consultation waiting for either parties to confirm?
   *
   * @return {boolean} Whether or not we are waiting.
   */
  public isWaitingForConfirmation(): boolean {
    return this.getValue() === "REQUESTED";
  }

  /**
   * Is this consultation in progress?
   */
  public isInProgress(): boolean {
    return this.getValue() === "IN_PROGRESS";
  }

  public isNoShowHuman(): boolean {
    return this.getValue() === "NO_SHOW_HUMAN";
  }

  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): IFormValidation[] {
    return [
      {
        message: translate(
          "validation:status.valid",
          "Dit is geen geldige consultatiestatus",
        ),
        path: this.getPath(),
        validate: (): boolean => {
          return false;
        },
      },
    ];
  }

  getAsFormStateValue(
    translate: TFunction<I18Namespaces>,
  ): Partial<
    Record<
      DataTypePaths.Consultation.Status,
      ISelectObject<ConsultationStatuses>
    >
  > {
    return {
      [ConsultationStatus.path]: {
        label: translate(`${this.translationKey}.${this.value}`, this.value),
        value: this.value,
      },
    };
  }
}
