import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import TextField from "../../ui/form/TextField";
import TermsAndConditions from "../TermsAndConditions";

interface UserInfoStep {
  formState: any;
  onTAndCCheck?: (checked: boolean) => void;
}

const RegisterFormInfo = ({ formState, onTAndCCheck }: UserInfoStep) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item md={6} sm={6} xs={12}>
        <TextField
          autoComplete="given-name"
          autoFocus
          id="firstName"
          label={t("common:firstname")}
          {...formState.getInputProps("firstName")}
        />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <TextField
          autoComplete="family-name"
          id="lastName"
          label={t("common:lastname")}
          {...formState.getInputProps("lastName")}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <TextField
          autoComplete="email"
          disabled
          id="email"
          label={t("common:email")}
          type="email"
          {...formState.getInputProps("email")}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <TextField
          autoComplete="phone"
          id="phone"
          label={t("common:phone", {
            context: "with_example",
            defaultValue: "Telefoonnummer (bv. 0032123456789)",
          })}
          type="tel"
          {...formState.getInputProps("phone")}
        />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <TermsAndConditions onCheck={onTAndCCheck} />
      </Grid>
    </Grid>
  );
};

export default RegisterFormInfo;
