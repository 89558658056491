import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class NationalId extends DataType<
  string,
  DataTypePaths.Human.NationalId
> {
  protected static path: DataTypePaths.Human.NationalId = "nationalId";

  protected static defaultValue = "";

  protected type: any = NationalId;

  static padZero(num, size) {
    num = num.toString();

    while (num.length < size) num = "0" + num;

    return num;
  }

  static checkSum: any = (e) => {
    let checksum = false;

    if (
      /^[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{2}[.\- ]{0,1}[0-9]{3}[.\- ]{0,1}[0-9]{2}$/.test(
        e,
      )
    ) {
      const cleanedNumber: string = e.replace(/\D/g, "");
      const birthYear: number = parseInt(
        cleanedNumber.toString().substring(0, 2),
      );

      const currYear: number = parseInt(
        String(new Date().getFullYear()).slice(-2),
      );

      const birthDate: string = this.padZero(cleanedNumber.substring(0, 6), 6);

      const serialNumber: string = this.padZero(
        cleanedNumber.substring(6, 9),
        3,
      );

      const rest: number =
        birthYear <= currYear
          ? parseInt("2" + birthDate + serialNumber) % 97
          : parseInt(birthDate.toString() + serialNumber.toString()) % 97;

      checksum = 97 - rest === parseInt(String(cleanedNumber).slice(-2));
    }

    return checksum;
  };

  /**
   * Return an array with validation functions for this data type.
   *
   * @param translate
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:national.id.correctformat",
          "Geef een correct rijksregisternummer op.",
        ),
        path: this.getPath(),
        validate: (e: string): boolean => {
          if (!e) return false;

          return this.checkSum(e);
        },
      },
    ];
  }

  getAsFormStateValue(): Record<DataTypePaths.Human.NationalId, string> {
    return { nationalId: this.value };
  }
}
