import { createContext } from "react";
import { IRegistrationContext } from "./registration";

export const defaultRegistrationValues: IRegistrationContext = {
  formState: {},
  handleSubmit: () => {},
  isSubmitting: false,
  next: () => {},
  previous: () => {},
  setStep: () => {},
  step: 0,
  totalSteps: 0,
};

const RegistrationContext = createContext<IRegistrationContext>(
  defaultRegistrationValues,
);

export default RegistrationContext;
