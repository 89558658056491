import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { IMutationInput } from "../../api";
import { MUTATION_REQUEST_REIMBURSEMENT } from "../mutations";

interface IRequestReimbursementResult {
  result: boolean;
}

interface IRequestReimbursementInput {
  consultationUuid: string;
  nationalId: string;
}

export default function useRequestReimbursement() {
  const [execute, { data, loading, error }] = useMutation<
    IRequestReimbursementResult,
    IMutationInput<IRequestReimbursementInput>
  >(MUTATION_REQUEST_REIMBURSEMENT);

  const requestReimbursement = useCallback(
    (input: IRequestReimbursementInput) => execute({ variables: { input } }),
    [execute],
  );

  return {
    data,
    error,
    loading,
    requestReimbursement,
  };
}
