import { FormHelperText } from "@mui/material";
import Select, { MultiValue } from "react-select";
import Color from "color";
import { ISelectObject } from "./BaseSimpleSelect";
import { ColoredGroupHeading } from "./custom-render-select/ColoredGroupHeading";
import { MenuWithTooltip } from "./custom-render-select/MenuWithTooltip";
import { RootStyle, classes } from "./SharedStyles";
import { hexColorCheck } from "./util";
import TranslatedMultiValueLabel from "./custom-render-select/TranslatedMultiValueLabel";

export interface IGroupSelectObject<T = any> {
  color?: string;
  label: string;
  options: Array<ISelectObject<T>>;
}

export interface IGroupSelectProps<T = any> {
  closeMenuOnSelect?: boolean;
  errorText?: string;
  hasError?: boolean;
  menuWithTooltip?: boolean;
  name?: string;
  options: Array<IGroupSelectObject<T>>;
  placeholder?: string | null;
}

interface IBaseGroupSelect<T, K = any> extends IGroupSelectProps<T> {
  isMulti: boolean;
  onChange: (newValue: MultiValue<ISelectObject<T>>) => void;
  values?: Array<K>;
}

// TODO The css styling switches between useStyles and this styleObject depending on menuWithTooltip.
// Need to merge both style objects.
const colourStyles = {
  groupHeading: (base, props) => {
    return {
      ...base,

      alignItems: "left",
      bottomPadding: "15px",
      color: hexColorCheck(props.data.color),
      display: "flex",
      fontSize: 16,
      fontWeight: 500,
      justifyContent: "flex-start",
      minWidth: 200,
      textTransform: "capitalize",
    };
  },

  multiValue: (styles, { data }) => ({
    ...styles,

    borderColor: `${Color(hexColorCheck(data.color)).alpha(0.5)} !important`,
    borderRadius: 14,
    borderWidth: 0,
  }),

  multiValueLabel: (styles, { data }) => ({
    ...styles,

    color: hexColorCheck(data.color),
  }),

  option: (styles) => ({
    ...styles,
    "&:hover": {
      borderColor: "#69A1AC",
      borderRadius: 5,
      borderStyle: "solid",
      borderWidth: 1,
    },
    background: "white",
    borderColor: "#69A1AC",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: "2px #69A1AC",
    cursor: "pointer",
    minHeight: 52,

    minWidth: 200,
  }),

  placeholder: (styles) => ({
    ...styles,

    color: "#252525",
    fontSize: 14,
    padding: 15,
  }),

  valueContainer: (styles) => ({
    ...styles,

    display: "flex",
    fontSize: 14,
    padding: "18.5px 15px",
    paddingBottom: 10,
    paddingTop: 10,
    textAlign: "left",
    width: "100%",
  }),
};

export default function BaseGroupSelect<T>({
  name,
  onChange,
  errorText,
  options,
  placeholder,
  hasError = false,
  isMulti = true,
  values = [],
  closeMenuOnSelect = true,
}: IBaseGroupSelect<T>) {
  return (
    <RootStyle>
      <Select
        className={classes.root}
        classNamePrefix="select"
        closeMenuOnSelect={closeMenuOnSelect}
        components={{
          GroupHeading: ColoredGroupHeading,
          MultiValueLabel: TranslatedMultiValueLabel,
          Option: MenuWithTooltip,
        }}
        isMulti={isMulti}
        name={name}
        onChange={onChange as any}
        options={options}
        placeholder={placeholder}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary: "#69A1AC",
          },
        })}
        value={values}
      />
      {hasError && (
        <FormHelperText className={classes.formHelper}>
          {errorText}
        </FormHelperText>
      )}
    </RootStyle>
  );
}
