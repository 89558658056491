/* eslint-disable react-hooks/exhaustive-deps */

import { FetchResult, useMutation } from "@apollo/client";
import moment from "moment";
import { useCallback, useContext } from "react";
import { IAuthContext } from "../../../components/providers/auth/auth";
import AuthContext from "../../../components/providers/auth/AuthContext";
import Consultation from "../../../data-model/types/consultation/Consultation";
import { APIConsultation } from "../consultations";
import { MUTATION_CONSULTATION_CREATE } from "../mutations";

const useCreateConsultation = () => {
  const { currentUser } = useContext<IAuthContext>(AuthContext);

  const [createConsultationFunction, { loading, error }] = useMutation<
    APIConsultation.Create.Result,
    APIConsultation.Create.Input
  >(MUTATION_CONSULTATION_CREATE);

  const parseResult = (
    result: FetchResult<APIConsultation.Create.Result>,
  ): Consultation | undefined => {
    let newConsultation: Consultation | undefined = undefined;

    if (result.data?.createConsultation) {
      newConsultation = new Consultation(result.data.createConsultation);
    }

    return newConsultation;
  };

  const createConsultation = useCallback(
    async (
      consultationDetails: Consultation,
      isFree = false,
      price?: number,
    ): Promise<Consultation | undefined> => {
      try {
        //If a consultation is requested by a client, the other party is the professional,
        // and the other way around.
        const otherPartyId = consultationDetails.requestedByClient()
          ? consultationDetails.getProfessional().getID()
          : consultationDetails.getClient().getID();

        if (isFree) price = 0;

        const input = {
          fromMatching: consultationDetails.fromMatch(),
          otherPartyId,
          price: price ? Number(price) : undefined,
          scheduledFrom: moment(consultationDetails.getStartDate()).toDate(),
          scheduledTo: moment(consultationDetails.getEndDate()).toDate(),
          status: consultationDetails.getStatus().getValue(),
          type: consultationDetails.getConsultationType(),
        };

        const result = await createConsultationFunction({
          variables: { input },
        });

        return parseResult(result);
      } catch (error) {
        console.error(
          `[useCreateConsultation] Could not create consultation: ${error}`,
        );

        throw error;
      }
    },
    [currentUser, loading],
  );

  return {
    createConsultation,
    error,
    loading,
  };
};

export default useCreateConsultation;
