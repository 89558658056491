/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC, ReactNode, useContext, useRef } from "react";
import CashTransaction from "../../../data-model/types/CashTransaction";
import { VideoTileGrid } from "./components/VideoTileGrid";
import AudioVisualSettingsContainer from "./components/audioAndVideoComponents/AudioVisualSettingsContainer";
import { ICallContext } from "./context/Call";
import CallContext from "./context/CallContext";

const PREFIX = "ChimeVideoContainer";

const classes = {
  overlay: `${PREFIX}-overlay`,
  root: `${PREFIX}-root`,
  videoContainer: `${PREFIX}-videoContainer`,
  videoTiles: `${PREFIX}-videoTiles`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.white.dark,
    height: "100vh",
    position: "relative",
  },

  [`& .${classes.overlay}`]: {
    height: "100%",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },

  [`& .${classes.videoContainer}`]: {
    height: "100%",
    position: "fixed",
    top: 0,
    width: "100%",
  },

  [`& .${classes.videoTiles}`]: {
    backgroundColor: theme.palette.white.dark + " !important",
  },
}));

interface ChimeVideoContainerProps {
  cashTransaction?: CashTransaction;
  children: ReactNode;
  redirectToPayment?: () => void;
}

const ChimeVideoContainer: FC<ChimeVideoContainerProps> = ({
  cashTransaction,
  redirectToPayment,
  children,
}) => {
  const { callInfo } = useContext<ICallContext>(CallContext);

  const audioEl = useRef<HTMLAudioElement | null>(null);

  return (
    <Root>
      {callInfo && !callInfo.isCallStarted && (
        <AudioVisualSettingsContainer
          cashTransaction={cashTransaction}
          redirectToPayment={redirectToPayment}
        />
      )}
      <Box className={classes.root}>
        <Box className={classes.overlay}>{children}</Box>
        <audio ref={audioEl} style={{ display: "none" }} />
        {callInfo && callInfo.isCallStarted && (
          <Box className={classes.videoContainer}>
            <VideoTileGrid className={classes.videoTiles} layout={"standard"} />
          </Box>
        )}
      </Box>
    </Root>
  );
};

export default ChimeVideoContainer;
