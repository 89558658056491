import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { CSSProperties } from "react";
import { IOption } from "../UiTypes";

const PREFIX = "Toggle";

const classes = {
  active: `${PREFIX}-active`,
  root: `${PREFIX}-root`,
  switch: `${PREFIX}-switch`,
  switch_empty: `${PREFIX}-switch_empty`,
  switch_text: `${PREFIX}-switch_text`,
};

const StyledBox = styled(Box)<ToggleStylingProperties>(
  ({
    theme,
    width,
    height,
    activeSwitchWidth,
    fontWeight,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  }) => ({
    [`&.${classes.root}`]: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: paddingBottom || 10,
      paddingLeft: paddingLeft || 10,
      paddingRight: paddingRight || 10,
      paddingTop: paddingTop || 10,
      textAlign: "center",
      width: "100%",
    },

    [`& .${classes.switch}`]: {
      alignItems: "center",
      border: `2px solid ${theme.palette.bloom.main}`,
      borderRadius: 50,
      display: "flex",
      height: height ?? 75,
      justifyContent: "center",
      padding: 2,
      position: "relative",
      width: width ?? 700,
    },

    [`& .${classes.switch_empty}`]: {
      borderRadius: 50,
      color: theme.palette.bloom.main,
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      width: "50%",
    },

    [`& .${classes.active}`]: {
      backgroundColor: theme.palette.bloom.main,
      color: theme.palette.whiteSmoke.main,
      cursor: "default",
      width: activeSwitchWidth || undefined,
    },

    [`& .${classes.switch_text}`]: {
      display: "inline",
      fontSize: theme.typography.sizes.normal,
      fontWeight: fontWeight || 500,
      position: "relative",
      top: -1,
      width: "100%",
    },
  }),
);

export interface ToggleStylingProperties {
  activeSwitchWidth?: CSSProperties["width"];
  fontWeight?: CSSProperties["fontWeight"];
  height?: CSSProperties["height"];
  paddingBottom?: CSSProperties["paddingBottom"];
  paddingLeft?: CSSProperties["paddingLeft"];
  paddingRight?: CSSProperties["paddingRight"];
  paddingTop?: CSSProperties["paddingTop"];
  width?: CSSProperties["width"];
}

interface IToggle<T> {
  onChange: (value: T) => void;
  options: Array<IOption<T>>;
  styleProperties?: ToggleStylingProperties;
  value: T;
}

export default function Toggle<T>({
  options,
  value,
  onChange,
  styleProperties = {},
}: IToggle<T>) {
  return (
    <StyledBox className={classes.root} {...styleProperties}>
      <Box className={classes.switch}>
        {options.map((option, index) => (
          <Box
            className={clsx(classes.switch_empty, {
              [classes.active]: value === option.value,
            })}
            key={index}
            onClick={() => {
              onChange(option.value);
            }}
          >
            <Typography className={classes.switch_text}>
              {option.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </StyledBox>
  );
}
