import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IWithChildren } from "../../../../../baseInterfaces";

const PREFIX = "PopUpMessage";

const classes = {
  content: `${PREFIX}-content`,
  contentText: `${PREFIX}-contentText`,
  headSection: `${PREFIX}-headSection`,
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    borderRadius: 20,
    color: theme.palette.black.main,
    left: "50%",
    maxWidth: 250,
    position: "absolute",
    top: "40%",
    transform: "translate(-50%,-50%)",
    zIndex: 7,
  },

  [`& .${classes.headSection}`]: {
    background: theme.palette.bloom.light,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    padding: 20,
    textAlign: "center",
  },

  [`& .${classes.title}`]: {
    fontFamily: "Inter",
    fontSize: 21,
    fontWeight: "bold",
  },

  [`& .${classes.content}`]: {
    background: theme.palette.bloom.light,
    borderRadius: 20,
    padding: 20,
    textAlign: "center",
  },

  [`& .${classes.contentText}`]: {
    fontFamily: "Inter",
    fontSize: 21,
    fontWeight: "bold",
  },
}));

interface IPopUpMessage extends IWithChildren {
  contentText: string;
}

export default function PopUpMessage({ contentText }: IPopUpMessage) {
  return (
    <StyledBox className={classes.root}>
      <Box className={classes.content}>
        <Typography className={classes.contentText}>{contentText}</Typography>
      </Box>
    </StyledBox>
  );
}
