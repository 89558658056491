/**
 * Gender of a psy, client or user.
 */
import type { TFunction } from "i18next";
import {
  FemaleIcon,
  MaleIcon,
  NoGenderPreferenceIcon,
} from "../../../components/icons/emoticons";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";
import { GenderOptions } from "../profile/Gender";

class PreferredGender extends DataType<
  GenderOptions,
  DataTypePaths.Human.PreferredGender
> {
  protected static path: DataTypePaths.Human.PreferredGender =
    "preferredProfessionalGender";

  protected static defaultValue = GenderOptions.OTHER;

  protected type: any = PreferredGender;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "gender.professional.mandatory",
          "Selecteer het geslacht van de psycholoog waarmee je wenst te spreken.",
        ),
        path: this.getPath(),
        validate: (g: string): boolean => !!g && g.trim() !== "",
      },
    ];
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This contains the value, label and an optionaly, an icon.
   *
   * @param  {any} translate The i18next translate hook with the common namespace.
   */
  static getSelectOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<Record<string, unknown>> {
    return [
      {
        icon: <FemaleIcon />,
        id: "female",
        label: translate("common:gender.female", "Vrouw"),
        value: GenderOptions.FEMALE,
      },
      {
        icon: <MaleIcon />,
        id: "male",
        label: translate("common:gender.male", "Man"),
        value: GenderOptions.MALE,
      },
      {
        icon: <NoGenderPreferenceIcon />,
        id: "none",
        label: translate("common:gender.other", "Eender"),
        value: GenderOptions.OTHER,
      },
    ];
  }

  getAsFormStateValue(): Record<
    DataTypePaths.Human.PreferredGender,
    GenderOptions
  > {
    return { preferredProfessionalGender: this.value };
  }
}

export default PreferredGender;
