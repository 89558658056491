/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useFetchHuman from "../../../api/humans/hooks/useFetchHuman";
import HealthInsurance, {
  HealthInsuranceCompanies,
} from "../../../data-model/types/reimbursement/HealthInsurance";
import { IFormState } from "../../hooks/useFormState";
import bancontact from "../../illustrations/payment/bancontact.png";
import belfius from "../../illustrations/payment/belfius.png";
import kbc from "../../illustrations/payment/kbc.png";
import mastercard from "../../illustrations/payment/mastercard.png";
import payPal from "../../illustrations/payment/payPal.png";
import payconiqByBancontact from "../../illustrations/payment/payconiqByBancontact.png";
import visa from "../../illustrations/payment/visa.png";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import LoadingPage from "../../layout/LoadingPage";
import ToastContext from "../../providers/toast/ToastContext";
import { IToastContext } from "../../providers/toast/toast";
import Button from "../../ui/buttons/Button";
import CheckBox from "../../ui/form/CheckBox";
import { _paymentFormState } from "../usePaymentFormState";

const PREFIX = "AffiliatedWithInsuranceCompany";

const classes = {
  bloomConstainer: `${PREFIX}-bloomConstainer`,
  description: `${PREFIX}-description`,
  memberCheck: `${PREFIX}-memberCheck`,
  payButton: `${PREFIX}-payButton`,
  paymentMethodImages: `${PREFIX}-paymentMethodImages`,
  paymentMethodLargeImage: `${PREFIX}-paymentMethodLargeImage`,
  paymentMethodMediumImages: `${PREFIX}-paymentMethodMediumImages`,
  paymentMethodSmallImages: `${PREFIX}-paymentMethodSmallImages`,
  paymentMethodSmallImagesContainer1: `${PREFIX}-paymentMethodSmallImagesContainer1`,
  paymentMethodSmallImagesContainer2: `${PREFIX}-paymentMethodSmallImagesContainer2`,
  paymentMethodsText: `${PREFIX}-paymentMethodsText`,
  price: `${PREFIX}-price`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.bloomConstainer}`]: {
    alignItems: "center",
    backgroundColor: theme.palette.bloom.light,
    borderRadius: 14,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(2),
    position: "relative",
    textAlign: "center",
  },

  [`& .${classes.price}`]: {
    color: theme.palette.porple.main,
    fontSize: "35px",
    marginBottom: theme.spacing(3),

    marginTop: theme.spacing(3),
  },

  [`& .${classes.memberCheck}`]: {
    color: theme.palette.porple.main,
    fontWeight: 800,
    marginBottom: theme.spacing(2),
    maxWidth: 300,
  },

  [`& .${classes.description}`]: {
    marginBottom: theme.spacing(4),
    maxWidth: 300,
  },

  [`& .${classes.payButton}`]: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down(933)]: {
      marginBottom: theme.spacing(5),
    },
  },

  [`& .${classes.paymentMethodsText}`]: {
    color: theme.palette.bloom.main,
  },

  [`& .${classes.paymentMethodImages}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
    marginLeft: 16,
    marginRight: 16,
  },

  [`& .${classes.paymentMethodLargeImage}`]: {
    flex: 1,
    flexGrow: 1,
    marginBottom: theme.spacing(1),
    order: 1,
  },

  [`& .${classes.paymentMethodMediumImages}`]: {
    display: "flex",
    flex: 1,
    flexGrow: 1,
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    marginBottom: theme.spacing(1),
    order: 2,
    [theme.breakpoints.down(933)]: {
      order: 3,
    },
  },

  [`& .${classes.paymentMethodSmallImages}`]: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    marginBottom: theme.spacing(1),
    order: 3,
    [theme.breakpoints.down(933)]: {
      order: 2,
    },
  },

  [`& .${classes.paymentMethodSmallImagesContainer1}`]: {
    display: "flex",
  },

  [`& .${classes.paymentMethodSmallImagesContainer2}`]: {
    display: "flex",
  },
}));

interface IAffiliatedWithInsuranceCompany {
  description: string;
  eligibleForReimbursement: boolean;
  formState: IFormState<_paymentFormState>;
  pay: (uuid: string) => void;
  price?: number;
  uuid: string | undefined;
}

export default function AffiliatedWithInsuranceCompany({
  price,
  description,
  pay,
  uuid,
  formState,
  eligibleForReimbursement,
}: IAffiliatedWithInsuranceCompany) {
  const { setToast } = useContext<IToastContext>(ToastContext);
  const { human, loading } = useFetchHuman();

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Payments,
  ]);

  useEffect(() => {
    if (!loading && human && formState) {
      formState.setValue(
        HealthInsurance.getPath(),
        human.getHealthInsurance().getValue(),
      );
    }
  }, [human, loading]);

  const payButtonHandler = useCallback(() => {
    if (uuid) {
      pay(uuid);
    } else {
      setToast({
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [uuid, pay]);

  if (!human) return <LoadingPage full />;

  return (
    <StyledBox className={classes.bloomConstainer}>
      <Box className={classes.price}>
        <Typography>{price || 0} EUR</Typography>
      </Box>
      {eligibleForReimbursement && (
        <>
          <Box className={classes.memberCheck}>
            <CheckBox
              defaultChecked={
                human.getHealthInsurance().getValue() ===
                HealthInsuranceCompanies.CM
              }
              handleNativeChange={(_, checked) => {
                if (formState) {
                  formState.setValue(
                    HealthInsurance.getPath(),
                    checked ? HealthInsuranceCompanies.CM : null,
                  );
                }
              }}
              helperStyle={{ fontWeight: "bold" }}
              label={translate(
                "payments:payment.page.checkbox.text",
                "Ik ben lid bij CM",
              )}
              name=""
              tooltipTitle=""
            />
          </Box>
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </>
      )}
      <Button
        className={classes.payButton}
        label={translate("payments:pay", "Betalen")}
        onClick={payButtonHandler}
      />

      <Box className={classes.paymentMethodsText}>
        <Typography className={classes.description} variant="body1">
          {translate(
            "payments:payment.page.method.text",
            "Betalen kan op deze manieren:",
          )}
        </Typography>
      </Box>
      <Box className={classes.paymentMethodImages}>
        <Box className={classes.paymentMethodLargeImage}>
          <img
            alt={"Payconiq By Bancontact"}
            height={"63px"}
            src={payconiqByBancontact}
            width={"63px"}
          />
        </Box>
        <Box className={classes.paymentMethodMediumImages}>
          <img alt={"PayPal"} height={"39px"} src={payPal} width={"86px"} />
          <img alt={"Belfius"} height={"39px"} src={belfius} width={"86px"} />
        </Box>
        <Box className={classes.paymentMethodSmallImages}>
          <Box className={classes.paymentMethodSmallImagesContainer1}>
            <img
              alt={"Bancontact"}
              height={"39px"}
              src={bancontact}
              width={"64px"}
            />

            <img alt={"KBC"} height={"39px"} src={kbc} width={"64px"} />
          </Box>

          <Box className={classes.paymentMethodSmallImagesContainer2}>
            <img alt={"Visa"} height={"39px"} src={visa} width={"64px"} />
            <img
              alt={"Mastercard"}
              height={"39px"}
              src={mastercard}
              width={"64px"}
            />
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
}
