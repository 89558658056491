import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const PREFIX = "SearchBox";

const classes = {
  inputInput: `${PREFIX}-inputInput`,
  inputRoot: `${PREFIX}-inputRoot`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.search}`]: {
    "&:hover": {
      backgroundColor: alpha(theme.palette.white.main, 0.25),
    },
    backgroundColor: alpha(theme.palette.white.main, 0.15),
    borderColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
    borderWidth: 1,
    marginLeft: 0,
    marginRight: theme.spacing(2),
    position: "relative",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },

  [`& .${classes.searchIcon}`]: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    pointerEvents: "none",
    position: "absolute",
  },

  [`& .${classes.inputRoot}`]: {
    color: "inherit",
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function SearchBar({ search, setSearch }) {
  return (
    <Root className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon color="secondary" />
      </div>
      <InputBase
        classes={{
          input: classes.inputInput,
          root: classes.inputRoot,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={(event) => {
          const { value } = event.target;

          setSearch(value.toLowerCase());
        }}
        placeholder="Search…"
        value={search}
      />
    </Root>
  );
}
