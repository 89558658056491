import { RouteNames } from "../routes/routeNames";

export namespace QualityQuestions {
  export function getQuestionPath(
    entrypoint: Entrypoints,
    redirectPath?: string,
    thankYouPage?: boolean,
    consultationId?: number,
  ) {
    const questionPath =
      RouteNames.QualityQuestions.Question.EntryPointOrId.path.replace(
        ":entrypointOrId",
        entrypoint,
      );

    if (redirectPath) {
      if (thankYouPage) {
        return `${questionPath}?redirectPath=${redirectPath}&thankyouPage=${thankYouPage}&consultationId=${consultationId}`;
      }

      return `${questionPath}?redirectPath=${redirectPath}&consultationId=${consultationId}`;
    }

    return `${questionPath}?consultationId=${consultationId}`;
  }

  export enum Entrypoints {
    AfterConsultation = "after_consultation",
    AfterIntro = "after_intro",
    AfterLastPlannedConsultation = "after_last_planned_consultation",
    BeforeConsultation = "before_consultation",
    DuringSelfHelp = "during_self_help",
    EndOfTreatment = "end_of_treatment",
    InAppNotification = "in_app_notification",
  }

  export const stringToEntryPoint = new Map<string, Entrypoints>([
    ["before_consultation", Entrypoints.BeforeConsultation],
    ["during_self_help", Entrypoints.DuringSelfHelp],
    ["after_consultation", Entrypoints.AfterConsultation],
    ["after_intro", Entrypoints.AfterIntro],
    [
      "after_last_planned_consultation",
      Entrypoints.AfterLastPlannedConsultation,
    ],
    ["end_of_treatment", Entrypoints.EndOfTreatment],
    ["in_app_notification", Entrypoints.InAppNotification],
  ]);

  export enum Category {
    B2B = "b2b",
    CallQuality = "call_quality",
    EndOfTreatment = "end_of_treatment",
    MatchQuality = "match_quality",
    NPS = "nps",
    ProfessionalQuality = "professional_quality",
    ShareWithBloomUp = "share_with_bloomup",
  }

  export enum Type {
    Likert = "likert",
    MultiSelect = "multi_select",
    Open = "open",
    SingleSelect = "single_select",
    Slider = "slider",
    Toggle = "toggle",
    YesNo = "yes_no",
    YesNoMaybe = "yes_no_maybe",
  }

  export enum Component {
    Checkbox = "checkbox",
    Dropdown = "dropdown",
    Radio = "radio",
  }

  export namespace Options {
    export interface Option {
      label: string;
      translationKey?: string;
      value: string | number;
    }
    export type OptionArray = Array<Option>;

    export interface Slider {
      max: Option;
      min: Option;
    }
    export type LikertStep = Options.Option & { step: number };

    export type Likert = Array<LikertStep>;
    export type Checkbox = OptionArray;
    export type RadioButtons = OptionArray;
    export type SingleSelect = OptionArray;
    export type MultiSelect = OptionArray;
    export type Toggle = OptionArray;
  }
}
