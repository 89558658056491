/* eslint-disable react-hooks/exhaustive-deps */

import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import Specialties from "../../../data-model/types/professional/Specialties";
import Theme, {
  ThemeModel,
} from "../../../data-model/types/professional/Theme";
import { QUERY_FETCH_ALL_SPECIALTIES } from "../queries";

interface IGetSpecialties {
  specialties: Array<ThemeModel>;
}

export default function useFetchAllSpecialties(forProfessionals?: boolean) {
  const { data, loading, error } = useQuery<IGetSpecialties>(
    QUERY_FETCH_ALL_SPECIALTIES,
    // DON'T REMOVE: This fetchpolicy makes sure we don't put all of the available specialties in the cache, which
    // would override and complement the ones that are actually chosen by the logged in professional.
    { fetchPolicy: "no-cache" },
  );

  const specialties = useMemo(() => {
    if (data && data.specialties) {
      const selectOptions: Array<Theme> = data.specialties.map(
        (sp) => new Theme(sp),
      );

      const filteredSelectOptions = data.specialties.map((sp) => {
        return {
          ...sp,
          subcategories: sp.subcategories.filter(
            (sub) => sub.translationKey !== "specialty.other",
          ),
        };
      });

      //Don't remove this. This fills the dropdown for specialties.
      Specialties.setSelectOptions(
        forProfessionals ? filteredSelectOptions : data.specialties,
      );

      return new Specialties(selectOptions);
    }
  }, [data]);

  return {
    error,
    loading: loading,
    specialties: specialties,
  };
}
