import { CashTransactionStatusType } from "../../api/__generated__/graphql";
import BaseType from "../BaseType";
import { DataTypePaths, PossibleFormStatePaths } from "../dataTypePaths";

export interface CashTransactionModel {
  amount: number;
  createdAt?: string;
  id: number;
  mollieTransaction?: string;
  status: CashTransactionStatusType;
  updatedAt?: string;
}

export default class CashTransaction extends BaseType<
  CashTransactionModel,
  DataTypePaths.CashTransactionPath
> {
  protected type: any = CashTransaction;

  protected id: number;
  protected status: CashTransactionStatusType;
  protected mollieTransaction?: string;
  protected amount: number;

  constructor(model: CashTransactionModel) {
    super(model);
    this.id = model.id;
    this.status = model.status;
    this.amount = model.amount;

    if (model.mollieTransaction)
      this.mollieTransaction = model.mollieTransaction;
  }

  update(model: CashTransactionModel) {
    this.id = model.id;
    this.status = model.status;
    this.amount = model.amount;

    if (model.mollieTransaction) {
      this.mollieTransaction = model.mollieTransaction;
    }
  }

  getAmount(): number {
    return this.amount;
  }

  getStatus(): CashTransactionStatusType {
    return this.status;
  }

  getAsFormStateValue(): Partial<
    Record<PossibleFormStatePaths, CashTransactionModel>
  > {
    return { cashTransaction: this.value };
  }
}
