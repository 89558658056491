import { useCallback } from "react";
import Storage from "../../utils/BULocalStorage";
import Worries from "../../data-model/types/profile/Worries";
import { APIAuthentication } from "../../api/auth/auth";

export type Token = string | null | undefined;

interface IStorageFunctions {
  getAccessTokenFromStorage: () => Promise<Token>;
  getAuthTokensFromStorage: () => Promise<APIAuthentication.Result | undefined>;
  getCurrentOrganizationId: () => Promise<number | null>;
  getMatchingTokenFromStorage: () => Promise<Token>;
  getRefreshTokenFromStorage: () => Promise<Token>;
  getWithKeyFromStorage: <T = any>(
    key: string,
    parse?: boolean,
  ) => Promise<T | null>;
  getWorriesFromStorage: () => Promise<Array<any>>;
  peristTokensToStorage: (tokens: APIAuthentication.Result) => Promise<void>;
  persistAccessTokenToStorage: (accesstoken: string) => Promise<void>;
  persistCurrentOrganizationId: (orgId: number) => Promise<void>;
  persistMatchingTokenToStorage: (matchingToken: string) => Promise<void>;
  persistRefreshTokenToStorage: (refreshToken: string) => Promise<void>;
  persistWithKeyToStorage: (key: string, value: any) => Promise<void>;
  persistWorriesToStorage: (worries: Array<Worries>) => Promise<void>;
  removeAccessTokenFromStorage: () => Promise<void>;
  removeAuthTokensFromStorage: () => Promise<void>;
  removeMatchingTokenFromStorage: () => Promise<void>;
  removeRefreshTokenFromStorage: () => Promise<void>;
}

export default function useStorage(): IStorageFunctions {
  const storage = Storage.getInstance();
  const getTokens = useCallback(async (): Promise<
    APIAuthentication.Result | undefined
  > => {
    const [accessToken, refreshToken] = await Promise.all([
      storage.getAccessToken(),
      storage.getRefreshToken(),
    ]);

    if (accessToken && refreshToken) {
      return { accessToken, refreshToken };
    }

    return undefined;
  }, [storage]);

  const getAccessToken = async (): Promise<string | undefined | null> =>
    storage.getAccessToken();

  const getRefreshToken = async (): Promise<string | undefined | null> =>
    storage.getRefreshToken();

  const getMatchingToken = async (): Promise<string | undefined | null> =>
    storage.getMatchingToken();

  const getWorries = async (): Promise<Array<any>> => {
    //TODO; check how worries are stored and if we can do it better.
    const worriesFromStorage: string = (await storage.getWorries()) as string;

    if (worriesFromStorage) return JSON.parse(worriesFromStorage);

    return [];
  };

  const getCurrentOrganizationId = async (): Promise<number | null> =>
    storage.getCurrentOrganizationId();

  async function getWithKeyFromStorage<T = any>(
    key: string,
    parse = true,
  ): Promise<T | null> {
    const value: T | null = await storage.loadWithKey<T>(key);

    if (value !== null) {
      return parse ? JSON.parse(value as any) : value;
    }

    return null;
  }

  const setWithKey = async (key: string, value: any): Promise<void> =>
    await storage.storeWithKey(key, JSON.stringify(value));

  const setTokens = useCallback(
    async ({
      accessToken,
      refreshToken,
    }: APIAuthentication.Result): Promise<void> => {
      await Promise.all([
        storage.setAccessToken(accessToken),
        storage.setRefreshToken(refreshToken),
      ]);
    },
    [storage],
  );

  const setAccessToken = async (accessToken: string) => {
    storage.setAccessToken(accessToken);
  };

  const setRefreshToken = async (refreshToken: string) => {
    storage.setRefreshToken(refreshToken);
  };

  const setMatchingToken = async (matchingToken: string) => {
    storage.setMatchingToken(matchingToken);
  };

  const setWorries = async (worries: any /*Array<Worries>*/) => {
    storage.setWorries(JSON.stringify(worries));
  };

  const removeAuthTokens = async () => {
    await Promise.all([
      storage.removeMatchingToken(),
      storage.removeAccessToken(),
      storage.removeRefreshToken(),
    ]);
  };

  const setCurrentOrganizationId = async (orgId: number) => {
    storage.setCurrentOrganisationId(orgId);
  };

  const removeAccessToken = async () => storage.removeAccessToken();
  const removeRefreshToken = async () => storage.removeRefreshToken();
  const removeMatchingToken = async () => storage.removeMatchingToken();

  return {
    getAccessTokenFromStorage: getAccessToken,
    getAuthTokensFromStorage: getTokens,
    getCurrentOrganizationId,
    getMatchingTokenFromStorage: getMatchingToken,
    getRefreshTokenFromStorage: getRefreshToken,
    getWithKeyFromStorage,
    getWorriesFromStorage: getWorries,
    peristTokensToStorage: setTokens,
    persistAccessTokenToStorage: setAccessToken,
    persistCurrentOrganizationId: setCurrentOrganizationId,
    persistMatchingTokenToStorage: setMatchingToken,
    persistRefreshTokenToStorage: setRefreshToken,
    persistWithKeyToStorage: setWithKey,
    persistWorriesToStorage: setWorries,
    removeAccessTokenFromStorage: removeAccessToken,
    removeAuthTokensFromStorage: removeAuthTokens,
    removeMatchingTokenFromStorage: removeMatchingToken,
    removeRefreshTokenFromStorage: removeRefreshToken,
  };
}
