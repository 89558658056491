/**
 * Bio of a psy.
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { DatabaseLanguageEquivalents } from "../../../components/language/languagesUtil";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType, { PartialRecord } from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";
import Description from "./Description";

export default class Bio extends DataType<
  Map<string, Description>,
  | DataTypePaths.Professional.Bio
  | DataTypePaths.Professional.LanguageSpecificBio.FR
  | DataTypePaths.Professional.LanguageSpecificBio.NL
  | DataTypePaths.Professional.LanguageSpecificBio.EN,
  string | Map<string, Description>
> {
  protected static path: DataTypePaths.Professional.Bio = "profile";
  protected static defaultValue = "";

  protected type: any = Bio;

  parseFromJsonInput(
    value: string,
  ): DataType<
    Map<string, Description>,
    | DataTypePaths.Professional.Bio
    | DataTypePaths.Professional.LanguageSpecificBio.FR
    | DataTypePaths.Professional.LanguageSpecificBio.NL
    | DataTypePaths.Professional.LanguageSpecificBio.EN,
    string | Map<string, Description>
  > {
    const mapwithDescription = new Map<string, Description>();

    if (value) {
      const parsed = JSON.parse(value);

      if (parsed) {
        Object.keys(parsed).forEach((languageKey) => {
          if (
            parsed[languageKey] !== undefined &&
            parsed[languageKey] !== null
          ) {
            const description = new Description(parsed[languageKey]);

            mapwithDescription.set(languageKey, description);
          }
        });
      }
    }

    this.value = mapwithDescription;

    return this;
  }

  getDescriptionByLanguage(languageKey: string): Description | null {
    if (this.value) {
      const description = this.value.get(languageKey);

      if (description) return description;
    }

    return null;
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<unknown>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:profile.mandatory",
          "Geef een korte beschrijving van jezelf op.",
        ),
        //TODO: check validation! (returns descriptions now!)
        path: this.getPath(),
        validate: (/*profile: string*/): boolean => {
          //if (profile) return profile.trim() !== "";
          return true;
        },
      },
      {
        message: translate(
          "validation:profile.maxlength",
          "Je beschrijving mag niet langer zijn dan 300 karakters.",
        ),
        path: this.getPath(),
        validate: (/*profile: string*/): boolean => {
          // if (profile) return profile.length <= 300;
          return true;
        },
      },
    ];
  }

  getAsFormStateValue(): PartialRecord<
    | DataTypePaths.Professional.Bio
    | DataTypePaths.Professional.LanguageSpecificBio.FR
    | DataTypePaths.Professional.LanguageSpecificBio.NL
    | DataTypePaths.Professional.LanguageSpecificBio.EN,
    string | Map<string, Description>
  > {
    const nl = this.value.get(DatabaseLanguageEquivalents.FLEMISH);
    const fr = this.value.get(DatabaseLanguageEquivalents.FRENCH);
    const en = this.value.get(DatabaseLanguageEquivalents.ENGLISH);

    const returnObject = {};

    if (nl) returnObject["description-nlBe"] = nl.getValue();

    if (fr) returnObject["description-frBe"] = fr.getValue();

    if (en) returnObject["description-en"] = en.getValue();

    return returnObject;
  }
}
