import React from "react";

const LogoutIcon = () => (
  <svg
    fill="none"
    height="24"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15,3h4c1.1,0,2,0.9,2,2v14c0,1.1-0.9,2-2,2h-4" />
    <polyline points="8,7 3,12 8,17 " />
    <line x1="15" x2="3" y1="12" y2="12" />
  </svg>
);

export default LogoutIcon;
