import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class AccountNumber extends DataType<
  string,
  DataTypePaths.Billing.AccountNumber
> {
  protected static path: DataTypePaths.Billing.AccountNumber = "accountNumber";

  protected static defaultValue = "";

  protected type: any = AccountNumber;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param translate
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "accountNumber.correctformat",
          "Geef een correcte IBAN rekeningnummer op.",
        ),
        path: this.getPath(),
        validate: (e: string): boolean =>
          /^([A-Z]{2}[ -]?[0-9]{2})(?=(?:[ -]?[A-Z0-9]){9,30}$)((?:[ -]?[A-Z0-9]{3,5}){2,7})([ -]?[A-Z0-9]{1,3})?$/.test(
            e,
          ),
      },
    ];
  }

  getAsFormStateValue(): Record<"accountNumber", string> {
    return { accountNumber: this.value };
  }
}
