import { Route, Routes } from "react-router-dom";
import ResetPasswordPageWithState from "../pages/reset-password/ResetPasswordPageWithState";
import { RouteNames } from "./routeNames";
import ChoosePasswordPage from "../pages/choose-password/ChoosePasswordPage";

export default function PasswordRoutes() {
  return (
    <Routes>
      <Route element={<ResetPasswordPageWithState />} path={""} />
      <Route
        element={<ResetPasswordPageWithState />}
        path={RouteNames.Password.Reset.name}
      />
      <Route
        element={<ChoosePasswordPage />}
        path={RouteNames.Password.Choose.name}
      />
      <Route
        element={<ChoosePasswordPage />}
        path={RouteNames.Password.Renew.name}
      />
    </Routes>
  );
}
