import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { MUTATION_CONSULTATION_CONFIRM } from "../mutations";
import { ConfirmConsultationInput } from "../../__generated__/graphql";

export default function useConfirmConsultation() {
  const [execute, { data, loading, error }] = useMutation(
    MUTATION_CONSULTATION_CONFIRM,
  );

  const confirmConsultation = useCallback(
    (input: ConfirmConsultationInput) => execute({ variables: { input } }),
    [execute],
  );

  return {
    confirmConsultation,
    data,
    error,
    loading,
  };
}
