import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";
import VisumNumber from "./VisumNumber";

export default class CMNumber extends DataType<
  string | undefined,
  DataTypePaths.Professional.CMNumber
> {
  protected static path: DataTypePaths.Professional.CMNumber = "cmNumber";
  protected static defaultValue = "";

  protected type: any = CMNumber;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param translate
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "cmnumber.mandatory",
          "Geef minstens 1 van beide nummers op (CM erkenning en visumnummer).",
        ),
        path: this.getPath(),
        validate: (e: string, others1, others2): boolean => {
          const others = {
            ...others1,
            ...others2,
          };

          if (!e && !others[VisumNumber.getPath()]) return false;

          //This makes sure this field can be left empty if the other one (CM number) is filled in, but we don't execute .trim() on 'null'
          if (!e) return true;

          return (
            (e.trim() === "" && others[VisumNumber.getPath()].trim() !== "") ||
            e.trim() !== ""
          );
        },
      },
      {
        message: translate(
          "cmnumber.correctformat",
          "Een CM erkenningsnummer bestaat uit 8 cijfers.",
        ),
        path: this.getPath(),
        validate: (e: string): boolean => /^\d{8}$/.test(e) || e === "",
      },
    ];
  }

  getAsFormStateValue(): Partial<
    Record<DataTypePaths.Professional.CMNumber, string | undefined>
  > {
    return { cmNumber: this.value };
  }
}
