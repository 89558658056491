import DOMPurify from "dompurify";

const SanitizedText = ({ input }: { input: string }) => {
  DOMPurify.addHook("afterSanitizeAttributes", function (node) {
    // set all elements owning target to target=_blank
    if ("target" in node) {
      node.setAttribute("target", "_blank");
    }

    // set non-HTML/MathML links to xlink:show=new
    if (
      !node.hasAttribute("target") &&
      (node.hasAttribute("xlink:href") || node.hasAttribute("href"))
    ) {
      node.setAttribute("xlink:show", "new");
    }
  });

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(input),
      }}
    />
  );
};

export default SanitizedText;
