/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Storage from "../../../utils/BULocalStorage";
import { QualityQuestions } from "../../quality-questions/QualityQuestions.types";
import { RouteNames } from "../../routes/routeNames";
import { ICallContext } from "../../ui/call/context/Call";
import CallContext from "../../ui/call/context/CallContext";
import { ConsultationTypes } from "../../../data-model/types/consultation/Consultation";
import HumanCallContainer from "./HumanCallContainer";

export default function HumanCallPageWithState() {
  const storage = Storage.getInstance();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { callInfo, consultation } = useContext<ICallContext>(CallContext);
  //TODO: check if the logged in user has access to this consultation (his userId should match that of the consultation).

  useEffect(() => {
    // Clean up --> Removes the consultation from storage when a user closes the page without using the prompt.
    return () => {
      storage.removeConsultation();
    };
  }, [storage]);

  useEffect(() => {
    if (callInfo && callInfo.isCallEnded) {
      if (callInfo && callInfo.someoneHasEntered && callInfo.endTime && uuid) {
        const redirectPath = RouteNames.Home.path;
        const entrypoint =
          consultation?.getConsultationType() === ConsultationTypes.APPOINTMENT
            ? QualityQuestions.Entrypoints.AfterConsultation
            : QualityQuestions.Entrypoints.AfterIntro;

        navigate(
          QualityQuestions.getQuestionPath(entrypoint, redirectPath, true),
        );
      } else {
        navigate(RouteNames.Home.path);
      }
    }
  }, [callInfo, consultation, navigate, uuid]);

  return <HumanCallContainer />;
}
