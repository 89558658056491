/* eslint-disable react-hooks/exhaustive-deps */

import { Theme, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Toggle, { ToggleStylingProperties } from "../ui/form/Toggle";
import { IOption } from "../ui/UiTypes";

interface NavigationToggleProps<T> {
  onSelectChanged: (value: T) => void;
  options: Array<IOption<T>>;
  toggleStylingProperties?: ToggleStylingProperties;
}

export default function NavigationToggle<T>({
  options,
  onSelectChanged,
  toggleStylingProperties = {},
}: NavigationToggleProps<T>) {
  const [selected, setSelected] = useState<T>(options[0].value);

  const [chunksForResponsiveContent, setchunksForResponsiveContent] = useState<
    Array<Array<IOption<T>>>
  >([]);

  const [
    stylingPropertiesForResponsiveContent,
    setStylingPropertiesForResponsiveContent,
  ] = useState<ToggleStylingProperties>(toggleStylingProperties ?? {});

  const extraSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(350),
  );

  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down(600));

  const medium = useMediaQuery((theme: Theme) => theme.breakpoints.down(900));

  const splitToChunks = (
    myArray: Array<IOption<T>>,
    chunk_size: number,
  ): Array<Array<IOption<T>>> => {
    let index = 0;
    const arrayLength = myArray.length;
    const tempArray: Array<Array<IOption<T>>> = [];

    for (index = 0; index < arrayLength; index += chunk_size) {
      const chunk = myArray.slice(index, index + chunk_size);

      tempArray.push(chunk);
    }

    return tempArray;
  };

  useEffect(() => {
    if (extraSmall) {
      setchunksForResponsiveContent(splitToChunks(options, 2));
      setStylingPropertiesForResponsiveContent({
        ...toggleStylingProperties,
        paddingBottom: "0px",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "15px",
      });
    } else if (small) {
      setchunksForResponsiveContent(splitToChunks(options, 3));
      setStylingPropertiesForResponsiveContent({
        ...toggleStylingProperties,
        paddingBottom: "0px",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingTop: "15px",
      });
    } else if (medium) {
      setchunksForResponsiveContent(splitToChunks(options, 6));
      setStylingPropertiesForResponsiveContent(toggleStylingProperties);
    } else {
      setchunksForResponsiveContent(splitToChunks(options, options.length));
      setStylingPropertiesForResponsiveContent(toggleStylingProperties);
    }
  }, [small, medium, extraSmall, toggleStylingProperties, options]);

  const onChange = useCallback(
    (value) => {
      setSelected(value);
      onSelectChanged(value);
    },
    [onSelectChanged],
  );

  return (
    <>
      {chunksForResponsiveContent.map((content) => (
        <Toggle
          onChange={onChange}
          options={content}
          styleProperties={{
            ...stylingPropertiesForResponsiveContent,
            activeSwitchWidth: content.length === 1 ? "100%" : undefined,
          }}
          value={selected}
        />
      ))}
    </>
  );
}
