import { useContext } from "react";
import { PresenceChannel } from "pusher-js";
import OnlineContext from "../context/OnlineContext";
import { CHANNEL_PRESENCE_PROFESSIONAL } from "../channels";
import { IOnlineContext } from "../context/onlineContext.s";
import Presence, { SpecificPresenceProps } from "./Presence";

export default function ProfessionalsPresence({
  children,
}: SpecificPresenceProps) {
  const { setProfessionalsChannel } = useContext<IOnlineContext>(OnlineContext);

  const professionalAdded = () => {};
  const professionalRemoved = () => {};
  const subscribed = (channel: PresenceChannel) =>
    setProfessionalsChannel(channel);

  return (
    <Presence
      channel={CHANNEL_PRESENCE_PROFESSIONAL}
      memberAdded={professionalAdded}
      memberRemoved={professionalRemoved}
      subscribedToChannel={subscribed}
    >
      {children}
    </Presence>
  );
}
