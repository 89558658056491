import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";

const PREFIX = "ChipList";

const classes = {
  chip: `${PREFIX}-chip`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.chip}`]: {
    ...theme.controls.chip,
    "& .MuiChip-label": {
      ...theme.controls.chip?.label,
    },
    marginBottom: 5,

    marginRight: 10,
  },
}));

interface IChipList {
  chipListTestId?: string | undefined;
  list: Array<any>;
  reader?: (list: Array<any>) => Array<any>;
}

const ChipList = ({ reader, list, chipListTestId }: IChipList) => {
  const parsedList = reader ? reader(list) : list;
  const { t } = useTranslation();

  return (
    <StyledBox display="flex" flexWrap="wrap" id={chipListTestId} mt={1}>
      {parsedList.map((item) => (
        <Box key={item}>
          <Chip classes={{ root: classes.chip }} label={t(item)} />
        </Box>
      ))}
    </StyledBox>
  );
};

export default ChipList;
