import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "../../../ui/buttons/Button";
import SearchBox from "../../../ui/SearchBox";
import AdminPageHeader from "../../../layout/AdminPageHeader";
import Professional from "../../../../data-model/types/professional/Professional";
import ProfessionalsTable from "./ProfessionalsTable";

interface IAdminProfessionalsOverview {
  onCreateProfessional: () => void;
  onDeleteProfessionalComplete: () => void;
  professionals: Array<Professional>;
  refetchProfessionals: () => void;
  search: string;
  setSearch: (input: string) => void;
}

const AdminProfessoinalsOverview = ({
  professionals,
  search,
  setSearch,
  onCreateProfessional,
  onDeleteProfessionalComplete,
  refetchProfessionals,
}: IAdminProfessionalsOverview) => (
  <div style={{ background: "whiteSmoke" }}>
    <AdminPageHeader />
    <Container maxWidth="xl">
      <Paper>
        <Toolbar>
          <Typography color="primary" variant="h6">
            Administrators and Professionals
          </Typography>
          <Button
            color="secondary"
            icon="+"
            label="Add person"
            onClick={onCreateProfessional}
            style={{ color: "white", marginLeft: 16 }}
            variant="contained"
          />
          <SearchBox search={search} setSearch={setSearch} />
        </Toolbar>
        <Box p={4}>
          <Divider />
          <ProfessionalsTable
            onDeleteComplete={onDeleteProfessionalComplete}
            professionals={professionals}
            refetch={refetchProfessionals}
          />
        </Box>
      </Paper>
    </Container>
  </div>
);

export default AdminProfessoinalsOverview;
