import { I18NexusLanguages } from "../../../language/languagesUtil";

export const getTitleAndPlaceholderForDescriptionField = (
  languageKey: I18NexusLanguages,
): { label: string; placeholder: string } => {
  let label = "";
  let placeholder = " ";

  switch (languageKey) {
    case I18NexusLanguages.ENGLISH:
      label = "About me (in English)";
      placeholder =
        "Type your text here in which you describe yourself. Your clients will see this text.";
      break;

    case I18NexusLanguages.FLEMISH:
      label = "Over mezelf (in het Nederlands)";
      placeholder =
        "Typ hier je tekst neer waarin je jezelf omschrijft. Deze tekst krijgen je cliënten te zien.";
      break;

    case I18NexusLanguages.FRENCH:
      label = "À propos de moi (en Français)";
      placeholder =
        "Tapez ici votre texte dans lequel vous vous décrivez. Vos clients verront ce texte.";
      break;

    default:
      label = "Over mezelf (in het Nederlands)";
      placeholder =
        "Typ hier je tekst neer waarin je jezelf omschrijft. Deze tekst krijgen je cliënten te zien.";
      break;
  }

  return {
    label,
    placeholder,
  };
};
