import { createContext } from "react";
import { ChimeVideoCall } from "../ChimeVideoCall.types";
import { ICallContext } from "./Call";

export const defaultCallValues: ICallContext = {
  callInfo: null,
  consultation: null,
  exitMeeting: () => {},
  isJoinedMeeting: false,
  isSmallerThanFirstBreakpoint: false,
  isSmallerThanSecondBreakpoint: false,
  isSmallerThanThirdBreakpoint: false,
  isWaitingRoomReady: false,
  paneToPresent: ChimeVideoCall.SidePaneProfessional.none,
  returnToPreviousPane: () => {},
  setPaneToPresent: () => {},
  startMeeting: () => {},
  startMeetingStatus: ChimeVideoCall.StartMeetingStatus.loading,
};

const CallContext = createContext<ICallContext>(defaultCallValues);

export default CallContext;
