/**
 * Diffrent health insurance companies. Companies like 'CM'.
 */

import { orderBy } from "lodash";
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import { DataTypePaths } from "../../dataTypePaths";
import SelectionDataType from "../../SelectionDataType";

export enum HealthInsuranceCompanies {
  BondMoyson = "bond_moyson",
  CAAMI = "caami",
  CM = "cm",
  DeVoorzorg = "de_voorzorg",
  FreieKrankenkasse = "freie_krankenkasse",
  FSMB = "fsmb",
  Helan = "helan",
  HKIV = "hkiv",
  HZIV = "hziv",
  LaMutualiteNeutre = "la_mutualite_neutre",
  LM = "lm",
  ML = "ml",
  MLOZ = "mloz",
  Mutualia = "mutualia",
  None = "none",
  NZV = "nzv",
  RailCare = "railcare",
  Solidaris = "solidaris",
  VNZ = "vnz",
}

export default class HealthInsurance extends SelectionDataType<
  HealthInsuranceCompanies | null,
  HealthInsuranceCompanies,
  DataTypePaths.Reimbursement.HealthInsurance
> {
  protected static path: DataTypePaths.Reimbursement.HealthInsurance =
    "healthInsurance";

  protected static defaultValue = null;
  protected static translationKey = "glossary:healthinsurance";

  protected fromGraphQL = (value: string): string => JSON.parse(value);
  protected forGraphQL = (value: string): string => JSON.stringify(value);

  protected type: any = HealthInsurance;

  constructor(value?: any, fromGQL = false) {
    super(value);
    this.setValue(value || HealthInsurance.defaultValue, fromGQL);
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This contains the value, label and an optionally, an icon.
   *
   * @param  {TFunction} translate The i18next translate hook with the glossary namespace.
   */
  public getSelectOptions(
    translate: TFunction,
  ): Array<ISelectObject<HealthInsuranceCompanies>> {
    const options: Array<ISelectObject<HealthInsuranceCompanies>> =
      Object.values(HealthInsuranceCompanies).map((value) => {
        return {
          label: translate(`${HealthInsurance.translationKey}.${value}`),
          value,
        };
      });

    return orderBy(options, ["label"], ["asc"]);
  }

  /**
   * Get all the options this data type can hold.
   *
   * @return {Object<Constant>}
   */
  public getOptions(): any {
    return HealthInsuranceCompanies;
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {TFunction} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(translate: TFunction): Array<IFormValidation> {
    return [
      {
        message: translate(
          "healthinsurance.toomany",
          "Geef maximum 1 mutualiteit op.",
        ),
        path: this.getPath(),
        validate: (): boolean => true,
      },
    ];
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This function only returns the values set to this object.
   *
   * @param  {[type]} translate The i18next translate hook with the glossary namespace.
   * @return {Array<ISelectObject>}
   */
  public getSelectedOptions(
    translate: TFunction,
  ): Array<ISelectObject<HealthInsuranceCompanies>> {
    if (this.value === null)
      return [
        {
          label: translate(
            `${HealthInsurance.translationKey}.${HealthInsuranceCompanies.None}`,
          ),
          value: HealthInsuranceCompanies.None,
        },
      ];

    return [
      {
        label: translate(`${HealthInsurance.translationKey}.${this.value}`),
        value: this.value,
      },
    ];
  }

  /**
   * Return an array of translated values.
   * This function only returns the values set to this object.
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   * @return {Array<String>}
   */
  public getTranslatedValues(translate: TFunction): Array<string> {
    if (this.value === null) return [];

    return [translate(`${HealthInsurance.translationKey}.${this.value}`)];
  }

  /**
   * Get the humanreadible string for this data type's value.
   * Ex: Mediatie, Familie-therapie
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   */
  public prettyPrint(translate: TFunction): string {
    return this.getTranslatedValues(translate).filter(Boolean).join(", ");
  }

  getAsFormStateValue(
    translate: TFunction<I18Namespaces>,
  ): Record<
    DataTypePaths.Reimbursement.HealthInsurance,
    ISelectObject<HealthInsuranceCompanies>[]
  > {
    let formStateValues: ISelectObject<HealthInsuranceCompanies>[] = [];

    if (this.value !== null)
      formStateValues = [
        {
          label: translate(`${HealthInsurance.translationKey}.${this.value}`),
          value: this.value,
        },
      ];

    return {
      [HealthInsurance.path]: formStateValues,
    };
  }
}
