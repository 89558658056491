import { LazyQueryResult, useLazyQuery } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { QualityQuestions } from "../../../components/quality-questions/QualityQuestions.types";
import { APIQualityQuestions } from "../qualityQuestions";
import { QUERY_GET_QUESTION_BY_ENTRYPOINT } from "../queries";

interface IUseFetchQualityQuestionForUserByEntrypointResult {
  error?: any;
  fetchQuestionForUserByEntrypoint: (
    entrypoint: QualityQuestions.Entrypoints,
  ) => Promise<
    LazyQueryResult<
      APIQualityQuestions.ByEntrypoint.Result,
      { input: APIQualityQuestions.ByEntrypoint.Input }
    >
  >;
  loading: boolean;
  question?: APIQualityQuestions.Question;
}

export default function useFetchQualityQuestionForUserByEntrypoint(): IUseFetchQualityQuestionForUserByEntrypointResult {
  const [execute, { data, loading, error }] = useLazyQuery<
    APIQualityQuestions.ByEntrypoint.Result,
    { input: APIQualityQuestions.ByEntrypoint.Input }
  >(QUERY_GET_QUESTION_BY_ENTRYPOINT);

  const fetchQuestionForUserByEntrypoint = useCallback(
    (entrypoint: QualityQuestions.Entrypoints) =>
      execute({ variables: { input: { entrypoint } } }),
    [execute],
  );

  const question = useMemo(() => {
    if (data && !loading) {
      return data.questionForUserAtEntryPoint;
    }
  }, [loading, data]);

  return {
    error,
    fetchQuestionForUserByEntrypoint,
    loading,
    question,
  };
}
