import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useFormState from "../../hooks/useFormState";
import createFormValidation from "../../../utils/forms/createFormValidation";
import Email from "../../../data-model/types/profile/Email";
import { I18Namespaces } from "../../language/I18Namespaces";

const INITIAL_VALUES = {
  ...Email.getInitialValue(),
};

export default function useResetPasswordFormState(
  initialValues?: any,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>([
    "human",
    "validation",
  ]);

  const validation = useMemo(
    () => createFormValidation([...Email.getFormValidation(translate)]),
    [translate],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
