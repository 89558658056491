import { graphql } from "../__generated__";

export const MUTATION_HUMAN_REGISTER = graphql(`
  mutation completeHumanRegistration($input: HumanInput!) {
    completeHumanRegistration(input: $input) {
      ...HumanRegistration
      id
    }
  }
`);

export const MUTATION_HUMAN_REGISTER_WITH_INVITE = graphql(`
  mutation registerHumanWithInvite($input: HumanInput!, $inviteToken: String!) {
    registerHumanWithInvite(input: $input, inviteToken: $inviteToken) {
      accessToken
      human {
        ...HumanRegistration
        id
      }
      refreshToken
    }
  }
`);

export const MUTATION_HUMAN_UPDATE = graphql(`
  mutation updateHuman($input: HumanInput!) {
    updateHuman(input: $input) {
      ...Human
    }
  }
`);
