import { useQuery } from "@apollo/client";
import AddIcon from "@mui/icons-material/Add";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { graphql } from "../../api/__generated__";
import ProfessionalConsultationCancellation from "../../components/professionals/ProfessionalCancelConsulation";
import TitleWithButton from "../../components/ui/text/TitleWithButton";
import LeftSection from "../layout/LeftSection";
import { RouteNames } from "../routes/routeNames";
import ClientConsultationDetail from "./ClientConsultationDetail";
import ProfessionalConsultationList from "./ProfessionalConsultationList";
import ProfessionalConsultationActions from "./ProfessionalConsultationActions";
import ProfessionalScheduleConsultation from "./ProfessionalRequestConsultation";
import ProfessionalRescheduleConsultation from "./ProfessionalRescheduleConsultation";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";

const myProfessionalConsultationQuery = graphql(`
  query ProfessionalConsultations($after: String) {
    currentUser {
      id
      professional {
        id
      }
    }
    PlannedConsultations: myProfessionalConsultations(
      after: $after
      filter: { status: [ACCEPTED, IN_PROGRESS, WAITING] }
      first: 20
      order: { scheduledFrom: asc }
    ) {
      edges {
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      ...ProfessionalConsultationList
    }
    RequestedConsultations: myProfessionalConsultations(
      filter: { status: [REQUESTED] }
      first: 200
      order: { scheduledFrom: asc }
    ) {
      ...ProfessionalConsultationList
    }
  }
`);

export default function ProfessionalConsultations() {
  const { data, fetchMore } = useQuery(myProfessionalConsultationQuery);
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const { t } = useTranslation();
  const { uuid } = useParams();
  const [rightPanelRenderState, setRightPanelRenderState] = useState<
    "DETAIL" | "RESCHEDULE" | "CANCEL" | "CREATE" | null
  >("DETAIL");

  const showMore = () => {
    setRightPanelRenderState("DETAIL");
  };

  const showRescheduleSection = () => {
    setRightPanelRenderState("RESCHEDULE");
  };

  const showCancelSection = () => {
    setRightPanelRenderState("CANCEL");
  };

  const showCreatePanel = () => {
    setRightPanelRenderState("CREATE");
  };

  const ClientList = (
    <InfiniteScroll
      dataLength={data?.PlannedConsultations.edges.length ?? 0}
      hasChildren
      hasMore={data?.PlannedConsultations.pageInfo?.hasNextPage ?? false}
      loader={
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      }
      next={() =>
        fetchMore({
          variables: {
            after: data?.PlannedConsultations.pageInfo?.endCursor,
          },
        })
      }
      scrollableTarget="leftSection"
    >
      {data?.RequestedConsultations && (
        <ProfessionalConsultationList
          consultations={data.RequestedConsultations}
          expensionLabel={t("common:consultation.list.requested")}
          onOpenConsultation={(uuid) => {
            setRightPanelRenderState("DETAIL");
            navigate(RouteNames.Home.Consultations.path + "/" + uuid);
          }}
        />
      )}
      {data?.PlannedConsultations && (
        <ProfessionalConsultationList
          consultations={data.PlannedConsultations}
          expensionLabel={t("common:consultation.list.planned")}
          onOpenConsultation={(uuid) => {
            setRightPanelRenderState("DETAIL");
            navigate(RouteNames.Home.Consultations.path + "/" + uuid);
          }}
        />
      )}
    </InfiniteScroll>
  );

  const RightSectionContent = (
    <Grid
      item
      md={6}
      sm={12}
      sx={({ breakpoints, spacing }) => ({
        height: "100%",
        overflowY: "auto",
        padding: spacing(4, 0, 4, 2),

        [breakpoints.down("md")]: {
          height: "auto",
          padding: spacing(4, 1),
        },
      })}
      xs={12}
      xss={12}
    >
      <Box
        sx={({ spacing, breakpoints }) => ({
          display: "flex",
          flexFlow: "column nowrap",
          height: "100%",
          padding: spacing(0, 1),
          position: "relative",

          [breakpoints.down("md")]: {
            height: "auto",
          },
        })}
      >
        <TitleWithButton
          buttonIcon={<CloseOutlinedIcon />}
          buttonLabel={t("common:close")}
          handleButton={() => {
            setRightPanelRenderState(null);
            navigate(RouteNames.Home.Consultations.path);
          }}
          title={t("common:consultation")}
        />
        {rightPanelRenderState === "CREATE" &&
          data?.currentUser?.professional &&
          data.currentUser.professional.id && (
            <ProfessionalScheduleConsultation
              onSchedule={(scheduledUuid) => {
                setRightPanelRenderState("DETAIL");
                navigate(
                  RouteNames.Home.Consultations.path + "/" + scheduledUuid,
                );
              }}
              professionalId={data.currentUser.professional.id}
            />
          )}

        {uuid && (
          <Box>
            {rightPanelRenderState !== "CREATE" && (
              <ClientConsultationDetail
                consultationUuid={uuid}
                showMore={
                  rightPanelRenderState === "DETAIL" ||
                  rightPanelRenderState === "CANCEL"
                }
              />
            )}
            {rightPanelRenderState === "CANCEL" && (
              <ProfessionalConsultationCancellation
                cancelCancellation={showMore}
                consultationUuid={uuid}
                onCancel={showMore}
              />
            )}

            {rightPanelRenderState === "RESCHEDULE" && (
              <ProfessionalRescheduleConsultation
                cancelReschedule={showMore}
                consultationUuid={uuid}
                onReschedule={showMore}
              />
            )}

            {rightPanelRenderState === "DETAIL" && (
              <ProfessionalConsultationActions
                consultationUuid={uuid}
                onCancel={showCancelSection}
                onReschedule={showRescheduleSection}
              />
            )}
          </Box>
        )}
      </Box>
    </Grid>
  );

  const shouldShowRightSide = uuid || rightPanelRenderState === "CREATE";

  if (small) {
    return (
      <>
        {shouldShowRightSide ? (
          RightSectionContent
        ) : (
          <LeftSection
            buttonIcon={<AddIcon />}
            buttonLabel={t("common:add")}
            handleTitleButton={() => showCreatePanel()}
            pageTitle={t("common:consultations")}
            short={false}
          >
            {ClientList}
          </LeftSection>
        )}
      </>
    );
  } else {
    return (
      <>
        <LeftSection
          buttonIcon={<AddIcon />}
          buttonLabel={t("common:add")}
          handleTitleButton={() => showCreatePanel()}
          pageTitle={t("common:consultations")}
          short={false}
        >
          {ClientList}
        </LeftSection>
        {shouldShowRightSide && RightSectionContent}
      </>
    );
  }
}
