import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import LanguageSelector from "./LanguageSelector";
import SanitizedText from "./text/SanitizedText";

const PREFIX = "Footer";

const classes = {
  footer: `${PREFIX}-footer`,
  footer__copyright: `${PREFIX}-footer__copyright`,
  footer__legal: `${PREFIX}-footer__legal`,
  footer__legal__item: `${PREFIX}-footer__legal__item`,
  text: `${PREFIX}-text`,
};

const Root = styled("footer")(({ theme }) => ({
  [`&.${classes.footer}`]: {
    display: "block",
    marginTop: 80,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: "80%",
  },

  [`& .${classes.text}`]: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },

  [`& .${classes.footer__legal}`]: {
    "& A": {
      "&:hover": {
        textDecoration: "underline !important",
      },
      color: `${theme.palette.gray.dark} !important`,

      textDecoration: "none !important",
    },
    display: "flex",

    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginBottom: 25,
    },
  },

  [`& .${classes.footer__legal__item}`]: {
    "&:hover": {
      cursor: "pointer",
    },

    marginRight: 20,

    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },

  [`& .${classes.footer__copyright}`]: {
    marginRight: 30,
  },
}));

interface IFooter {
  showLanguageSelector?: boolean;
  showSpecialConditionsForTherapist?: boolean;
  style?: Record<string, unknown>;
}

export default function Footer({
  showSpecialConditionsForTherapist = false,
  style = {},
  showLanguageSelector = true,
}: IFooter) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  return (
    <Root className={classes.footer} style={style}>
      {showLanguageSelector && (
        <div>
          <LanguageSelector />
        </div>
      )}
      <div className={classes.footer__legal}>
        <Typography
          align="center"
          className={clsx(classes.footer__copyright, classes.text)}
          color="textSecondary"
          variant="body2"
        >
          <SanitizedText input={translate("common:footer.copyright")} />{" "}
          {new Date().getFullYear()}
        </Typography>
        <Typography
          align="center"
          className={classes.footer__legal__item}
          color="textSecondary"
          variant="body2"
        >
          <SanitizedText input={translate("common:footer.privacy")} />
        </Typography>
        <Typography
          align="center"
          className={classes.footer__legal__item}
          color="textSecondary"
          variant="body2"
        >
          <SanitizedText input={translate("common:footer.terms")} />
        </Typography>
        {showSpecialConditionsForTherapist && (
          <Typography
            align="center"
            className={classes.footer__legal__item}
            color="textSecondary"
            variant="body2"
          >
            <SanitizedText
              input={translate("common:footer.professionals.terms")}
            />
          </Typography>
        )}
      </div>
    </Root>
  );
}
