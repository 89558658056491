import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Human from "../../data-model/types/human/Human";
import Professional from "../../data-model/types/professional/Professional";
import BirthDate from "../../data-model/types/profile/BirthDate";
import PreferredLanguage from "../../data-model/types/profile/PreferredLanguage";
import Timezone, { Timezones } from "../../data-model/types/profile/Timezone";
import { FemaleIcon, MaleIcon } from "../icons/emoticons";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import LoadingPage from "../layout/LoadingPage";
import { theme } from "../providers/theme/ThemeProvider";
import DeprecatedAvatar from "../ui/DeprecatedAvatar";
import InlineEditableDate from "../ui/edit/InlineEditableDate";
import InlineEditableField from "../ui/edit/InlineEditableField";
import InlineEditableSingleSelect from "../ui/edit/InlineEditableSingleSelect";
import { ISelectObject } from "../ui/form/select/BaseSimpleSelect";
import ValueWithLabel from "../ui/text/ValueWithLabel";
import InlineEditablePreferredLanguageSelector from "./InlineEditablePreferredLanguageSelector";

const PREFIX = "EditableProfile";

const classes = {
  avatar: `${PREFIX}-avatar`,
  firstLastName: `${PREFIX}-firstLastName`,
  genderIcon: `${PREFIX}-genderIcon`,
  otherItems: `${PREFIX}-otherItems`,
  titleBox: `${PREFIX}-titleBox`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.titleBox}`]: {
    "& .edit .inline-edit-controls-edit": {
      width: "187px !important",
    },
    "@media (max-width: 440px)": {
      display: "block",
    },
    display: "grid",

    gridTemplateColumns: "140px auto",
    width: "100%",
  },

  [`& .${classes.avatar}`]: {
    "@media (max-width: 440px)": {
      marginBottom: 24,
    },
    gridColumnStart: 1,
    gridRowStart: 1,
    marginTop: 5,
    position: "relative",
  },

  [`& .${classes.firstLastName}`]: {
    gridColumnStart: 2,
    gridRowStart: 1,
  },

  [`& .${classes.otherItems}`]: {
    padding: "30px 5px",
    width: "100%",
  },

  [`& .${classes.genderIcon}`]: {
    "& SVG": {
      fill: "white",
      height: 50,
      width: 50,
    },
    position: "absolute",

    top: 85,
  },
}));

interface IProfileInfo {
  creditsLeft?: number | null;
  forColleague?: boolean;
  formState?: any;
  onSave: () => void;
  organizationName?: string | null;
  person: Professional | Human;
  updatePreferredLanguage: (
    preferredLanguage: ISelectObject<PreferredLanguage>,
  ) => void;
  updateTimezone: (timezone: ISelectObject<Timezones | string>) => void;
}

export default function EditableProfileInfo({
  person,
  formState,
  onSave,
  updateTimezone,
  updatePreferredLanguage,
  forColleague,
  creditsLeft,
  organizationName,
}: IProfileInfo) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Ui,
    BloomUpNamespacesEnum.Human,
  ]);

  if (!person) return <LoadingPage logText="ProfileInfo" />;

  const gender = person.getGender().getValue();

  return (
    <StyledGrid container>
      <Box className={classes.titleBox}>
        <Box className={classes.avatar}>
          <DeprecatedAvatar size={120} user={person} />
          {(gender === "male" || gender === "female") && (
            <Box className={classes.genderIcon}>
              {gender === "female" && <FemaleIcon />}
              {gender === "male" && <MaleIcon />}
            </Box>
          )}
        </Box>
        <Box className={classes.firstLastName}>
          <Box mb={1}>
            <InlineEditableField
              dataObject={person.getFirstName()}
              formState={formState}
              label={translate("common:firstname", "Voornaam")}
              large
              onSave={onSave}
            />
          </Box>
          <Box>
            <InlineEditableField
              dataObject={person.getLastName()}
              formState={formState}
              label={translate("common:lastname", "Familienaam")}
              large
              onSave={onSave}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.otherItems}>
        {creditsLeft && (
          <Box mb={3}>
            <ValueWithLabel label={translate("human:profile.credits")}>
              {creditsLeft}
            </ValueWithLabel>
          </Box>
        )}
        {organizationName && (
          <>
            <Box mb={3}>
              <ValueWithLabel label={translate("human:profile.organization")}>
                {/* {organizationName} */}
                {organizationName}
              </ValueWithLabel>
            </Box>
            <Box mb={3}>
              <ValueWithLabel
                buttons={[
                  {
                    Icon: CancelIcon,
                    color: theme.palette.red.dark,
                    href: "mailto:support@bloomup.org?subject=Ontkoppelen van organisatie",
                    text: translate("human:profile.unlink.organization.button"),
                  },
                ]}
                label={translate("human:profile.unlink.organization")}
              >
                {translate("human:profile.unlink.organization.description")}
              </ValueWithLabel>
            </Box>
          </>
        )}
        {!forColleague && (
          <Box mb={3}>
            <InlineEditableDate<BirthDate>
              autoFocus
              dataObject={person.getBirthDate()}
              formState={formState}
              format="YYYY"
              fullWidth
              label={translate("common:birthdate", "Geboortejaar (optioneel)")}
              maxDate={moment().subtract(18, "year")}
              minDate={moment().subtract(100, "year")}
              onSave={onSave}
              openTo="year"
              placeholder={translate("ui:birthyear.placeholder") ?? undefined}
              views={["year"]}
            />
          </Box>
        )}
        <Box mb={3}>
          <ValueWithLabel label={translate("common:email", "E-mail")}>
            {person.getEmail().getValue()}
          </ValueWithLabel>
        </Box>
        <Box mb={3}>
          <InlineEditableField
            dataObject={person.getPhone()}
            formState={formState}
            label={translate("common:phone", "Telefoonnummer")}
            onSave={onSave}
          />
        </Box>
        <Box mb={3}>
          <InlineEditablePreferredLanguageSelector
            formState={formState}
            onSave={updatePreferredLanguage}
            person={person}
            translate={translate}
          />
        </Box>
        <Box>
          <InlineEditableSingleSelect<Timezone>
            cancelBtnTestId="test_profile_timezone_select_cancel"
            dataObject={person.getTimezone()}
            editBtnTestId="test_profile_timezone_select_edit"
            formState={formState}
            label={translate("common:preferences.timezone", "Mijn tijdszone")}
            labelTestId="test_profile_timezone_label"
            onSave={() =>
              updateTimezone(formState.getValue(Timezone.getPath()))
            }
            placeholderText={translate(
              "common:preferences.timezone.mandatory",
              "Geef je tijdszone op.",
            )}
            saveBtnTestId="test_profile_timezone_select_save"
            selectTestId="test_profile_timezone_select"
            translateFunction={(item) => {
              // Need to remove the . added in the translate function in InlineEditableSingleSelect
              // Translations are not necessary for timezones
              return item.substring(1);
            }}
          />
        </Box>
      </Box>
    </StyledGrid>
  );
}
