import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import { RouteNames } from "../../routes/routeNames";
import FormLabel from "../../ui/form/FormLabel";
import LoginContainer from "./LoginContainer";
import { graphql } from "../../../api/__generated__";
import { useMutation } from "@apollo/client";
import { Formik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../ui/form/CustomTextField";
import ToastContext from "../../providers/toast/ToastContext";
import AuthContext from "../../providers/auth/AuthContext";
import { getRedirectRoute } from "../../routes/routingUtils";

const LoginMutation = graphql(`
  mutation Login($input: LoginV2Input!) {
    loginV2(input: $input) {
      ... on TokenResponse {
        accessToken
        refreshToken
      }
      ... on LoginV2FailureResponse {
        reason
      }
    }
  }
`);

export default function LoginForm({
  replaceRedirect,
  labelBack,
  onClickBack,
}: {
  labelBack?: string;
  onClickBack?: () => void;
  replaceRedirect?: () => Promise<void>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { setToast, close: closeToast } = useContext(ToastContext);
  const { internalAppLogin } = useContext(AuthContext);

  const [show2FA, setShow2FA] = useState(false);

  const [login] = useMutation(LoginMutation, {
    onCompleted: async (data) => {
      if (data.loginV2.__typename === "LoginV2FailureResponse") {
        if (data.loginV2.reason === "INCORRECT_CREDENTIALS") {
          setShow2FA(false);
          setToast({
            message: t("api_errors:login.failure"),
            noHide: true,
            severity: "warning",
          });
        } else if (data.loginV2.reason === "NEEDS_2FA") {
          setShow2FA(true);
        } else {
          setShow2FA(true);
          setToast({
            message: t("api_errors:login.2fa_failure"),
            noHide: true,
            severity: "warning",
          });
        }
      } else if (data.loginV2.__typename === "TokenResponse") {
        // Temporary fallback while mindlabToken is optional on the graph
        if (!data.loginV2.accessToken || !data.loginV2.refreshToken) {
          setToast({
            message: t("api_errors:login.failure"),
            noHide: true,
            severity: "warning",
          });

          return;
        }

        closeToast();

        await internalAppLogin(
          data.loginV2.accessToken,
          data.loginV2.refreshToken,
        );

        replaceRedirect
          ? await replaceRedirect()
          : navigate(getRedirectRoute(location, RouteNames.Home.path));
      }
    },
  });

  return (
    <Formik
      initialValues={{ email: "", password: "", token2FA: "" }}
      onSubmit={(values) => {
        closeToast();
        login({ variables: { input: values } });
      }}
      validateOnChange={false}
      validationSchema={Yup.object({
        email: Yup.string()
          .required(t("validation:email.mandatory"))
          .email(t("validation:email.validity")),
        password: Yup.string(),
        token2FA: Yup.string(),
      }).required()}
    >
      {(formikProps) => {
        return (
          <OnboardingContainer>
            <LoginContainer
              colorNext="green"
              fullCopyright
              headline={t("common:signin")}
              labelBack={labelBack}
              labelNext={t("common:signin")}
              onClickBack={onClickBack}
              onClickNext={() => {
                formikProps.submitForm();
              }}
              subheadicon={<LockOutlinedIcon />}
            >
              {show2FA ? (
                <>
                  <FormLabel label={t("common:token2fa")} />
                  <CustomTextField
                    autoComplete="one-time-code"
                    autoFocus
                    error={formikProps.errors.token2FA}
                    id="token2FA"
                    name="token2FA"
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? formikProps.submitForm() : null
                    }
                    sx={{
                      marginBottom: 4,
                    }}
                    type="text"
                    value={formikProps.values.token2FA}
                  />
                </>
              ) : (
                <>
                  <FormLabel label={t("common:email")} />
                  <CustomTextField
                    autoComplete="email"
                    autoFocus
                    error={formikProps.errors.email}
                    id="email"
                    name="email"
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    sx={{
                      marginBottom: 4,
                    }}
                    value={formikProps.values.email}
                  />

                  <FormLabel label={t("common:password")} />
                  <CustomTextField
                    autoComplete="password"
                    error={formikProps.errors.password}
                    id="password"
                    name="password"
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    onKeyDown={(e) =>
                      e.key === "Enter" ? formikProps.submitForm() : null
                    }
                    type="password"
                    value={formikProps.values.password}
                  />
                  <Grid
                    style={{
                      marginTop: 10,
                      textAlign: "right",
                    }}
                  >
                    <Typography
                      onClickCapture={(event) => {
                        event.preventDefault();
                        navigate(RouteNames.Password.Reset.path);
                      }}
                      sx={(theme) => ({
                        "&:hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                        color: theme.palette.bloom.dark,
                        fontWeight: 500,
                      })}
                    >
                      {t("common:forgotpassword")}
                    </Typography>
                  </Grid>
                </>
              )}
            </LoginContainer>
          </OnboardingContainer>
        );
      }}
    </Formik>
  );
}
