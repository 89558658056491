import { useContext, useState } from "react";
import useUpdateHumanMutation from "../../../api/humans/hooks/useUpdateHumanMutation";
import useUpdateProfessionalMutation from "../../../api/professionals/hooks/useUpdateProfessionalMutation";
import { prepareLanguageInputForAPI } from "../../language/languagesUtil";
import { IToastContext } from "../../providers/toast/toast";
import ToastContext from "../../providers/toast/ToastContext";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import { APIProfessional } from "../../../api/professionals/professionals";
import PreferredLanguage from "../../../data-model/types/profile/PreferredLanguage";
import Timezone from "../../../data-model/types/profile/Timezone";

type HumanOrProfessional = "human" | "professional";

interface IUseUpdateUserResult {
  updatePreferredLanguage: (
    selectedTimeZone: ISelectObject<PreferredLanguage>,
  ) => Promise<void>;
  updateTimezone: (
    selectedTimeZone: ISelectObject<Timezone | string>,
  ) => Promise<void>;
  updating: boolean;
}

export default function useUpdateUser(
  type: HumanOrProfessional,
): IUseUpdateUserResult {
  const [updating, setUpdating] = useState<boolean>(false);
  const { setToast } = useContext<IToastContext>(ToastContext);
  const { updateProfessional } = useUpdateProfessionalMutation();
  const { updateHuman } = useUpdateHumanMutation();

  const updateTimezone = (
    selectedTimeZone: ISelectObject<Timezone | string>,
  ) => {
    const timezone = `${selectedTimeZone.value}`;

    return updateUserFn({ timezone });
  };

  const updatePreferredLanguage = (
    preferredLanguage: ISelectObject<PreferredLanguage>,
  ) => {
    const prepared = prepareLanguageInputForAPI([`${preferredLanguage.value}`]);

    return updateUserFn({ preferredLanguage: prepared[0] });
  };

  const updateUserFn = async (
    data: Partial<
      APIProfessional.Input & {
        contactForFeedback?: boolean;
      }
    >,
  ) => {
    try {
      setUpdating(true);

      if (type === "professional") {
        await updateProfessional(data);
      } else {
        await updateHuman(data);
      }
    } catch (e) {
      console.error(e);

      setToast({
        message:
          "There was an error updating your timezone. Please check your answers and try again.",
        severity: "warning",
      });
    } finally {
      setUpdating(false);
    }
  };

  return {
    updatePreferredLanguage,
    updateTimezone,
    updating,
  };
}
