import { useMutation } from "@apollo/client";
import { MUTATION_UPDATE_SPECIALTIES } from "../mutations";
import { IMutationInput } from "../../api";

interface IUpdateSpecialtiesResult {
  updateSpecialties: boolean;
}

interface IUpdateSpecialtiesInput {
  specialtiesThemeComboIds: Array<string>;
}

export default function useUpdateSpecialtiesMutation() {
  const [execute, { loading, data, error }] = useMutation<
    IUpdateSpecialtiesResult,
    IMutationInput<IUpdateSpecialtiesInput>
  >(MUTATION_UPDATE_SPECIALTIES);

  const updateSpecialties = (input: IUpdateSpecialtiesInput) => {
    return execute({
      variables: { input },
    });
  };

  return {
    data: data?.updateSpecialties,
    error,
    loading,
    updateSpecialties,
  };
}
