import { useContext } from "react";
import { UserRoles } from "../../../data-model/types/User";
import { IWithChildren } from "../../../baseInterfaces";
import AuthContext from "../../providers/auth/AuthContext";
import Guard from "./Guard";

export default function HumanGuard({ children }: IWithChildren) {
  const { currentUser } = useContext(AuthContext);

  return (
    <Guard
      condition={
        currentUser?.getRole() !== UserRoles.PROFESSIONAL &&
        currentUser?.getRole() !== UserRoles.ADMIN
      }
      logText={"Human guard"}
    >
      {children}
    </Guard>
  );
}
