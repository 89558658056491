import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class Age extends DataType<number, DataTypePaths.Profile.Age> {
  protected static path = "age";
  protected static defaultValue = 16;

  protected type: any = Age;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {any} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "age",
          "Sorry, we richten ons op iets oudere gebruikers.",
        ),
        path: this.getPath(),
        validate: (age: number): boolean => age >= 16,
      },
    ];
  }

  getAsFormStateValue(): Partial<Record<DataTypePaths.Profile.Age, number>> {
    return { age: this.value };
  }
}
