import { Box, SvgIconTypeMap, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import "moment-timezone";

const PREFIX = "MenuItemSidePane";

const classes = {
  icon: `${PREFIX}-icon`,
  root: `${PREFIX}-root`,
  text: `${PREFIX}-text`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    flexFlow: "row nowrap",
    paddingTop: 20,
    width: "100%",
  },

  [`& .${classes.text}`]: {
    ...theme.typography.h6,
  },

  [`& .${classes.icon}`]: {
    marginRight: 30,
  },
}));

interface IMenuItemSidePane {
  Icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">>;
  onClick?: () => void;
  text: string;
}

const MenuItemSidePane = ({ Icon, text, onClick }: IMenuItemSidePane) => {
  return (
    <StyledBox className={classes.root} onClick={onClick}>
      <Icon className={classes.icon} style={{ fontSize: 40 }} />
      <Typography className={classes.text}>{text}</Typography>
    </StyledBox>
  );
};

export default MenuItemSidePane;
