import { createContext } from "react";
import { IToastContext } from "./toast";

export const defaultToastProviderValues = {
  close: () => {},
  open: false,
  setOpen: () => {},
  setToast: () => {},
  toast: undefined,
};

const ToastContext = createContext<IToastContext>(defaultToastProviderValues);

export default ToastContext;
