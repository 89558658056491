/* eslint-disable react-hooks/exhaustive-deps */

import Box from "@mui/material/Box";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Consultation, {
  ConsultationTypes,
} from "../../../data-model/types/consultation/Consultation";
import Human from "../../../data-model/types/human/Human";
import Professional from "../../../data-model/types/professional/Professional";
import ConsultationDateSelection from "../../consultations/ConsultationDateSelection";
import ConsultationPriceSelection from "../../consultations/ConsultationPriceSelection";
import { IFormState } from "../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import Button from "../../ui/buttons/Button";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import SingleSelect from "../../ui/form/select/SingleSelect";
import Label from "../../ui/text/Label";

interface IAddConsultationForm {
  clients: Array<Human>;
  formState: IFormState;
  onSubmit: () => void;
  professional: Professional;
  submitting: boolean;
}

const AddConsultationForm = ({
  professional,
  clients,
  formState,
  onSubmit,
  submitting,
}: IAddConsultationForm) => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const [disabled, setDisabled] = useState(
    !formState.values.user || formState.testForErrors(),
  );

  useEffect(() => {
    setDisabled(!formState.values.user || formState.testForErrors());
  }, [formState, formState.testForErrors()]);

  const answerOptions: Array<ISelectObject<number>> = useMemo(
    () =>
      clients.map((c: Human) => {
        return {
          label: c.getScreenName(),
          value: c.getID(),
        };
      }),
    [clients],
  );

  const consultation = new Consultation();

  useEffect(() => {
    if (formState.getValue("type") === null) {
      formState.setValue("type", {
        label: translate(
          "common:consultation_type." + ConsultationTypes.APPOINTMENT,
        ),
        value: ConsultationTypes.APPOINTMENT,
      });
    }
  }, []);

  let currentClient: Human | undefined;

  if (formState.getValue("user")) {
    currentClient = clients.find(
      (client) => client.getID() === formState.getValue("user").value,
    );
  }

  const creditsLeft = currentClient?.getCreditsLeft();

  return (
    <>
      <Box mb={4} mt={2}>
        <Box mb={3} py={1}>
          <Label value={translate("professional:consultations.select.type")} />
          <Box mt={1}>
            <SingleSelect
              options={[
                {
                  label: translate(
                    "common:consultation_type." + ConsultationTypes.APPOINTMENT,
                  ),
                  value: ConsultationTypes.APPOINTMENT,
                },

                {
                  label: translate(
                    "common:consultation_type." + ConsultationTypes.INTRO,
                  ),
                  value: ConsultationTypes.INTRO,
                },
                ...(professional.getForBgt()
                  ? [
                      {
                        label: translate(
                          "common:consultation_type." +
                            ConsultationTypes.CHECKIN,
                        ),
                        value: ConsultationTypes.CHECKIN,
                      },
                    ]
                  : []),
              ]}
              {...formState.getInputProps("type")}
              onChange={(value) => {
                formState.setValue("type", value);
                formState.getInputProps("type").onChange(value);
              }}
              placeholder={translate(
                "professional:consultations.select.type",
                "Kies type consultatie",
              )}
            />
          </Box>
        </Box>
        <Box mb={3} py={1}>
          <Label value={translate("common:client")} />
          <Box mt={1}>
            <SingleSelect<number>
              options={answerOptions}
              {...formState.getInputProps("user")}
              placeholder={translate(
                "professional:consultations.select.user",
                "Kies een cliënt",
              )}
            />
          </Box>
        </Box>
        <ConsultationDateSelection
          consultation={consultation}
          formState={formState}
          isEditing={true}
          professional={professional}
        />
        <ConsultationPriceSelection
          canPayWithCredits={currentClient?.canPayWithCredits ?? false}
          creditsLeft={creditsLeft}
          formState={formState}
          isEditing={true}
        />
      </Box>
      <Box textAlign="left">
        <Button
          disabled={disabled}
          label={translate("common:book", "Boek consultatie")}
          loading={submitting}
          onClick={onSubmit}
        />
      </Box>
    </>
  );
};

export default AddConsultationForm;
