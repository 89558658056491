import _ from "lodash";
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { I18NexusLanguages } from "../../../components/language/languagesUtil";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import { DataTypePaths } from "../../dataTypePaths";
import SelectionDataType from "../../SelectionDataType";

export default class PreferredLanguage extends SelectionDataType<
  I18NexusLanguages,
  string,
  DataTypePaths.Profile.PreferredLanguage
> {
  protected static path: DataTypePaths.Profile.PreferredLanguage =
    "preferredLanguage";

  protected static defaultValue = "nlBe";
  private translationKey = "ui:languages";
  protected type: any = PreferredLanguage;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "preferences.preferred_language.mandatory",
          "Gelieve een voorkeurtaal aan te geven.",
        ),
        path: this.getPath(),
        validate: (
          preferredLanguage: ISelectObject<I18NexusLanguages>,
        ): boolean => !!preferredLanguage,
      },
    ];
  }

  public getSelectOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<ISelectObject<I18NexusLanguages>> {
    const options: Array<ISelectObject<I18NexusLanguages>> = Object.values(
      I18NexusLanguages,
    ).map((value) => {
      return {
        label: translate(`${this.translationKey}.${value}`),
        value,
      };
    });

    return _.orderBy<ISelectObject<I18NexusLanguages>>(
      options,
      ["label"],
      ["asc"],
    );
  }

  public getSelectedOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<ISelectObject<I18NexusLanguages>> {
    if (this.value === null) return [];

    const selected = this.value;

    return [
      {
        label: translate(`${this.translationKey}.${selected}`),
        value: selected,
      },
    ];
  }

  public getTranslatedValues(
    translate: TFunction<I18Namespaces>,
    originalLanguage = true,
  ): Array<string> {
    if (this.value === null) return [];

    const selected = this.value;
    const translatedValues = [selected].map((val) => {
      if (originalLanguage) return translate(`${this.translationKey}.${val}`);
      else return translate(`${this.translationKey}.translated.${val}`);
    });

    return _.orderBy(translatedValues, ["label"], ["asc"]);
  }

  public getOptions(): any {
    return I18NexusLanguages;
  }

  getAsFormStateValue(
    translate: TFunction<I18Namespaces>,
  ): Partial<
    Record<
      DataTypePaths.Profile.PreferredLanguage,
      ISelectObject<I18NexusLanguages>
    >
  > {
    return {
      [PreferredLanguage.path]: {
        label: translate(`${this.translationKey}.${this.value}`, this.value),
        value: this.value,
      },
    };
  }
}
