import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { IWithChildren } from "../../../baseInterfaces";

const PREFIX = "OnboardingHeader";

const classes = {
  onboardingHeader: `${PREFIX}-onboardingHeader`,
  shrink: `${PREFIX}-shrink`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.onboardingHeader}`]: {
    "& .header": {
      left: "50%",
      position: "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    alignItems: "center",
    background: theme.palette.bloom.light,
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    minHeight: 130,
    paddingBottom: 50,
    paddingTop: 50,

    position: "relative",
  },

  [`&.${classes.shrink}`]: {
    [theme.breakpoints.between("md", "xl")]: {
      height: "30%",
      maxHeight: 130,
    },
  },
}));

interface IOnboardingHeader extends IWithChildren {
  small: boolean;
}

const OnboardingHeader = ({ small, children }: IOnboardingHeader) => {
  return (
    <StyledBox
      className={`${classes.onboardingHeader} ${small ? classes.shrink : ""}`}
    >
      {children}
    </StyledBox>
  );
};

export default OnboardingHeader;
