import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { BloomUpNamespacesEnum } from "../../language/I18Namespaces";

const PREFIX = "TabBar";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledPaper = styled(Paper)({
  [`&.${classes.root}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    flexWrap: "nowrap",
    fontFamily: "inherit",
    justifyContent: "flex-start",
    textAlign: "left",
    width: "fit-content",
  },
});

const HRDashboards = BloomUpNamespacesEnum.HRDashboards;

export default function TabBar() {
  const navigate = useNavigate();

  function gotoPage(arg: string): void {
    navigate(arg);
  }

  return (
    <StyledPaper className={classes.root}>
      <Tab
        label="Landing"
        onClick={() => gotoPage("/home/hr-dashboards/landing-page")}
      ></Tab>
      <Tab
        label={t(`${HRDashboards}:translation.wellbeing.header`)}
        onClick={() => gotoPage("/home/hr-dashboards/wellbeing-page")}
      ></Tab>
      <Tab
        label={t(`${HRDashboards}:translation.videoConsultPage.videoConsult`)}
        onClick={() => gotoPage("/home/hr-dashboards/")}
      ></Tab>
    </StyledPaper>
  );
}
