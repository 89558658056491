export namespace ChimeVideoCall {
  export enum SidePaneProfessional {
    bookingPane,
    clientInfoPane,
    settingsPane,
    chatPane,
    menuPane,
    none,
  }

  export enum StartMeetingStatus {
    loading,
    success,
    error,
  }
}
