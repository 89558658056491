import { CameraSelection } from "amazon-chime-sdk-component-library-react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";

export default function VideoInputDeviceSelection() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
  ]);

  return (
    <CameraSelection
      label={
        translate("ui:video.input.device.selection.label", "Video Camera") ??
        undefined
      }
    />
  );
}
