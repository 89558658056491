import { ChangeEventHandler } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import FormikTextField from "../form/FormikTextField";
import ValueWithLabel from "../text/ValueWithLabel";
import InlineEditableControls from "./common/InlineEditableControls";

interface InlineEditableFieldNewFormStateProps {
  errorText?: string;
  hasError: boolean;
  id: string;
  label: string;
  large?: boolean;
  multiline?: boolean;
  name: string;
  onChange: ChangeEventHandler;
  onDiscard: () => void;
  onSave: () => void;
  placeholder: string;
  value: string;
}

export default function InlineEditableFieldNewFormState({
  id,
  name,
  label,
  value,
  onSave,
  onChange,
  onDiscard,
  hasError,
  errorText,
  large = false,
  multiline = false,
  placeholder,
}: InlineEditableFieldNewFormStateProps) {
  const { t: translate } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Common,
  );

  const CHAR_LIMIT = 300;

  return (
    <InlineEditableControls
      edit={
        <FormikTextField
          autoFocus
          errorText={errorText}
          hasError={hasError}
          id={id}
          inputProps={{ maxLength: CHAR_LIMIT }}
          multiline={multiline}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          rows={multiline ? 4 : 1}
          value={value}
        />
      }
      editable={true}
      key={"testkey"}
      label={label || translate("common:-", "-")}
      onDiscard={() => {
        onDiscard();

        return true;
      }}
      onSave={async () => {
        if (hasError) return false;

        onSave();

        return true;
      }}
      read={
        <ValueWithLabel label={""} large={large}>
          {value}
        </ValueWithLabel>
      }
    />
  );
}
