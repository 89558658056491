import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box } from "@mui/material";
import Color from "color";
import { useTranslation } from "react-i18next";
import StyledTooltip from "../../../Tooltip";
import { hexColorCheck } from "../util";

//TODO; type
export const MenuWithTooltip = ({ innerRef, innerProps, children, data }) => {
  const { t } = useTranslation();

  return (
    <Box
      ref={innerRef}
      sx={(theme) => ({
        "&:hover": {
          backgroundColor: theme.palette.bloom.light,
        },
        alignItems: "center",
        background: `${Color(hexColorCheck(data.color)).alpha(0.1).string()}`,
        color: `${hexColorCheck(data.color)}`,
        cursor: "pointer",
        display: "flex",
        fontWeight: 600,
        height: "40px",
        justifyContent: "flex-start",

        minWidth: "200px",
      })}
      {...innerProps}
    >
      <Box sx={{ flexGrow: 4, marginLeft: "10px", textAlign: "left" }}>
        {t(children)}
      </Box>
      <Box
        sx={(theme) => ({
          color: theme.palette.bloom.dark,
          marginRight: "10px",
        })}
      >
        <StyledTooltip text={t(data.toolTip)}>
          <InfoOutlinedIcon />
        </StyledTooltip>
      </Box>
    </Box>
  );
};
