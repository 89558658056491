/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useInterval from "../../../../custom-hooks/useInterval";
import { I18Namespaces } from "../../../language/I18Namespaces";
import {
  ClockState,
  FULL_DAY_MS,
  ONE_HOUR_MS,
  ONE_MINUTE_MS,
} from "../components/CallClock";
import { IClockContext } from "./clock";
import ClockContext from "./ClockContext";

const ClockStateProvider = ({
  children,
  count: defaultCount,
  defaultStarted,
  canStart,
}: IClockContext) => {
  const { t: translate } = useTranslation<I18Namespaces>("ui");

  const [running, setRunning] = useState<boolean>(defaultStarted);
  const [clockState, setClockState] = useState<ClockState>({
    count: defaultCount,
    displayValue: translate("ui:clock.starting", "Starten..."),
  });

  useEffect(() => {
    if (canStart && canStart !== running) setRunning(true);
  }, [canStart, setRunning]);

  const resetClock = useCallback(() => {
    setRunning(false);
    setClockState({ count: 0, displayValue: "00:00:00" });
  }, [setRunning, setClockState]);

  const toggleRunning = () => setRunning(!running);
  const startClock = useCallback(() => {
    setRunning(true);
  }, [setRunning]);

  const stopClock = useCallback(() => setRunning(false), [setRunning]);

  const updateClock = useCallback(
    (currentCount: number) => {
      const hours = Math.floor((currentCount % FULL_DAY_MS) / ONE_HOUR_MS);

      const minutes = Math.floor((currentCount % ONE_HOUR_MS) / ONE_MINUTE_MS);

      const seconds = Math.floor((currentCount % ONE_MINUTE_MS) / 1000);

      const hoursTxt: string = hours < 10 ? `0${hours}` : `${hours}`;
      const minutesTxt: string = minutes < 10 ? `0${minutes}` : `${minutes}`;

      const secondsTxt = seconds < 10 ? `0${seconds}` : `${seconds}`;

      const newTime = `${hoursTxt}:${minutesTxt}:${secondsTxt}`;

      setClockState({
        count: currentCount + 1000,
        displayValue: newTime,
      });
    },
    [setClockState],
  );

  useInterval(() => updateClock(clockState.count), running ? 1000 : null);

  return (
    <ClockContext.Provider
      value={{
        canStart,
        count: clockState.count,
        defaultStarted,
        displayValue: clockState.displayValue,
        resetClock,
        running,
        setClockState,
        startClock,
        stopClock,
        toggleRunning,
        updateClockFunction: updateClock,
      }}
    >
      {children}
    </ClockContext.Provider>
  );
};

export default ClockStateProvider;
