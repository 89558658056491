import moment from "moment";
import { createContext } from "react";
import { defaultFormStateValues } from "../../../hooks/useFormState";
import { IProfessionalAvailabilityContext } from "./ProfessionalAvailability";

const defaultProfessionalAvailabilityProviderValues: IProfessionalAvailabilityContext =
  {
    calendarRef: undefined,
    copyAvailabilityToThisWeek: async () => {},
    createCalendarEvent: async () => {},
    creatingCalendarEventError: undefined,
    dateRangeOfCalendar: {
      end: moment().endOf("week").toDate(),
      start: moment().startOf("week").toDate(),
    },
    deleteCalendarEvent: async () => {},
    deleteCalendarEventError: undefined,
    events: undefined,
    formState: defaultFormStateValues,
    isCopyingCalendarEvents: false,
    isCreatingCalendarEvent: false,
    isDeletingCalendarEvent: false,
    isLoadingProfessional: false,
    onlineUntil: null,
    popUpAnchorEl: null,
    popUpMenuOnClose: () => {},
    popUpMenuOnSelectItem: () => {},
    professional: undefined,
    removeAvailabilityOfVisiblePeriod: () => {},
    setDateRangeOfCalendar: () => {},
    updateCalendarEvent: async () => {},
    updateIsAcceptingNewClients: () => {},
    updateIsOnline: () => {},
  };

const ProfessionalAvailabilityContext =
  createContext<IProfessionalAvailabilityContext>(
    defaultProfessionalAvailabilityProviderValues,
  );

export default ProfessionalAvailabilityContext;
