import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { styled } from "@mui/material/styles";
import {
  DatePicker as MUIDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/nl-be";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getMomentLocale } from "../../language/languagesUtil";
import TextField from "./TextField";

const PREFIX = "DatePicker";

const classes = {
  textField: `${PREFIX}-textField`,
};

const Root = styled("span")(({ theme }) => ({
  [`& .${classes.textField}`]: {
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,

      cursor: "pointer",
      ...theme.controls.border?.normal,
      ...theme.controls.input,

      "& .MuiIconButton-root": {
        "& .MuiSvgIcon-root": {
          fontSize: "1.5rem",
        },
        marginRight: "-4px",
      },
      "& INPUT": {
        padding: 0,
      },

      "&:hover": {
        ...theme.controls.border?.hover,
      },

      marginTop: 5,

      paddingRight: 5,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));

const CustomTextField = (props) => {
  return (
    <TextField
      className={classes.textField}
      inputProps={{ style: { cursor: "pointer" } }}
      {...props}
    />
  );
};

const DatePicker = ({ fullWidth, appointment, setAppointment, ...props }) => {
  const { t: translate, i18n } = useTranslation(["common"]);

  const handleChange = (value) => {
    const formattedDate = value.format("DD/MM/YYYY");

    setAppointment({
      ...appointment,
      date: formattedDate,
    });
  };

  moment.locale(getMomentLocale(i18n.language));

  const testDate = moment().format("DD/MM/YYYY") || appointment.date;

  return (
    <Root>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        localeText={{
          cancelButtonLabel: translate("common:cancel"),
          clearButtonLabel: translate("common:clear"),
          okButtonLabel: translate("common:pick"),
        }}
      >
        <MUIDatePicker
          format="DD/MM/YYYY"
          maxDate={props.maxDate ?? undefined}
          minDate={props.mindDate ?? Date.now()}
          onChange={handleChange}
          slotProps={{
            textField: { fullWidth },
          }}
          slots={{
            textField: CustomTextField,
          }}
          value={
            testDate || moment(appointment.date, "DD/MM/YYYY")
          } /* MUI Bug */
          {...props}
        />
      </LocalizationProvider>
    </Root>
  );
};

export default DatePicker;

interface ICustomDatePicker {
  errorText: string;
  fullWidth: boolean;
  hasError: boolean;
  name: string;
  onChange: (something) => void;
  value: Date | Moment;
}

export const CustomDatePicker = ({
  fullWidth,
  name,
  onChange,
  value,
  hasError,
  ...other
}: ICustomDatePicker) => {
  const [open, setOpen] = useState(false);

  const { i18n } = useTranslation();
  const momentLocale = getMomentLocale(i18n.language);

  moment.locale(momentLocale);

  return (
    <Root>
      <LocalizationProvider
        adapterLocale={momentLocale}
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <MUIDatePicker
          className={classes.textField}
          format="DD/MM/YYYY"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          slotProps={{
            textField: {
              error: hasError,
              fullWidth,
              name,
              placeholder: "dd/mm/yyyy",
            },
          }}
          value={moment(value)}
          {...other}
          onChange={onChange}
        />
      </LocalizationProvider>
    </Root>
  );
};
