import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  FormControlLabel,
  Link,
  Typography,
  useTheme,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { graphql } from "../../../../api/__generated__";
import StepHeader from "../Common/StepHeader";
import StepMainContent from "../Common/StepMainContent";
import AgeRangeSlider from "../../../ui/form/AgeRangeSlider";
import Footer from "../Common/Footer";
import PrivacyBlock from "./PrivacyBlock";
import Arrow from "./Arrow.svg";
import { Trans, useTranslation } from "react-i18next";
import SanitizedText from "../../../ui/text/SanitizedText";

interface PrivacyStepProps {
  stepForward: (nextStep: string, token: string) => void;
}

const startMatchingMutation = graphql(`
  mutation StartMatchingV2($input: StartMatchingV2Input!) {
    startMatchingV2(input: $input) {
      matchingToken
      nextStep
    }
  }
`);

const PrivacyAgeStep = ({ stepForward }: PrivacyStepProps) => {
  const [startMatchingV2] = useMutation(startMatchingMutation);
  const localCode = localStorage.getItem("orgCode");
  const [age, setAge] = useState<number>(16);
  const [terms, setTerms] = useState<boolean>(false);
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  return (
    <>
      <StepHeader
        imageUrl={
          "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D50-ages%2C+Size%3DSmall.png"
        }
        subtitle={t("match:privacyAgeStep.header.subtitle")}
        title={t("match:privacyAgeStep.header.title")}
      />
      <StepMainContent
        enableNext={age >= 16 && terms}
        onClickNext={async () => {
          const { data } = await startMatchingV2({
            variables: {
              input: {
                age,
                language:
                  i18n.language === "nl-BE"
                    ? "nl"
                    : i18n.language === "fr-BE"
                    ? "fr"
                    : "en",
                orgCode: localCode,
              },
            },
          });

          data &&
            stepForward(
              data.startMatchingV2.nextStep,
              data.startMatchingV2.matchingToken,
            );
        }}
      >
        <Box
          sx={{
            "@media screen and (max-width: 500px)": {
              gap: "1rem",
              padding: "2rem 0.5rem 0",
            },
            alignItems: "center",
            display: "Flex",
            flexDirection: "column",
            gap: "3rem",
            padding: "0 1rem 0",
          }}
        >
          <Typography
            sx={{
              fontSize: "24px",
            }}
          >
            {t("match:privacyAgeStep.main.slider.title", { age })}
          </Typography>
          <Box
            sx={{
              "@media screen and (max-width: 600px)": {
                width: "unset",
              },
              width: "500px",
            }}
          >
            <AgeRangeSlider
              customThumb
              defaultValue={16}
              min={0}
              name={"age"}
              onChange={(e) => {
                setAge(e);
              }}
              value={age}
            />
            <Box>
              {age < 16 ? (
                <Alert icon={false} severity="error">
                  <AlertTitle>{t("human:onboarding.age.alert")}</AlertTitle>
                  <SanitizedText input={t("human:onboarding.age.referral")} />
                </Alert>
              ) : (
                <Typography sx={{ marginTop: "10px" }}>
                  {t("match:privacyAgeStep.main.slider.subTitle")}
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              "@media screen and (max-width: 500px)": {
                marginTop: "0.5rem",
              },
              marginTop: "1.5rem",
              position: "relative",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => setTerms(e.target.checked)}
                  size="large"
                  value={terms}
                />
              }
              label={t("match:privacyAgeStep.main.privacy.title")}
            />

            <Box
              sx={{
                fontSize: "16px",
                marginBottom: "48px",
                marginLeft: "10px",
              }}
            >
              <ul>
                <li>
                  <Trans
                    components={{
                      Link: (
                        <Link
                          href={t(
                            "match:privacyAgeStep.main.privacy.terms.link",
                          )}
                          variant="accessibleLink"
                        />
                      ),
                    }}
                    i18nKey={t("match:privacyAgeStep.main.privacy.terms")}
                  />
                </li>
                <li>
                  <Trans
                    components={{
                      Link: (
                        <Link
                          href={t(
                            "match:privacyAgeStep.main.privacy.data.link",
                          )}
                          variant="accessibleLink"
                        />
                      ),
                    }}
                    i18nKey={t("match:privacyAgeStep.main.privacy.data")}
                  />
                </li>
                <li>
                  <Trans
                    components={{
                      Link: (
                        <Link
                          href={t(
                            "match:privacyAgeStep.main.privacy.medicalData.link",
                          )}
                          variant="accessibleLink"
                        />
                      ),
                    }}
                    i18nKey={t("match:privacyAgeStep.main.privacy.medicalData")}
                  />
                </li>
              </ul>
            </Box>
            <Box
              component="img"
              src={Arrow}
              sx={{
                "@media screen and (max-width: 800px)": {
                  display: "none",
                },
                bottom: "-135px",
                fill: theme.palette.green.dark,
                height: "175px",
                left: "-35px",
                position: "absolute",
                zIndex: 1,
              }}
            />
          </Box>
        </Box>
      </StepMainContent>
      <PrivacyBlock />
      <Footer waveBackground="#FBF5F0" />
    </>
  );
};

export default PrivacyAgeStep;
