import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import HttpBackend from "i18next-http-backend/cjs";
import LanguageDetector from "i18next-browser-languagedetector";
import { BloomUpNamespacesEnum } from "../language/I18Namespaces";
import environment from "../../environment/environment";

// We use i18nexus as our translations repo.
// https://medium.com/javascript-in-plain-english/react-localization-the-winner-is-i18next-i18nexus-b7cd9f14094e
// Export to new version for production: https://app.i18nexus.com

const loadPath =
  environment.REACT_APP_ENV !== "production"
    ? `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${environment.REACT_APP_I18N_APP_KEY}`
    : `https://cdn.i18nexus.com/versions/${environment.REACT_APP_I18N_VERSION}/translations/{{lng}}/{{ns}}.json?api_key=${environment.REACT_APP_I18N_APP_KEY}`;

let backendConfig = {};

// Don't use localStorage on dev.
if (environment.REACT_APP_ENV === "production") {
  backendConfig = {
    backendOptions: [
      {
        defaultVersion: environment.REACT_APP_I18N_VERSION,
      },
      {
        loadPath,
      },
    ],
    backends: [LocalStorageBackend, HttpBackend],
  };
} else {
  backendConfig = {
    loadPath,
  };
}

const namespaces: string[] = Object.values(BloomUpNamespacesEnum);
const backend =
  environment.REACT_APP_ENV === "production" ? ChainedBackend : HttpBackend;

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use<HttpBackend | ChainedBackend>(backend)

  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: backendConfig,

    debug: environment.REACT_APP_ENV !== "production" && false,
    defaultNS: "common",

    detection: {
      // cache user language on
      caches: ["localStorage"],

      // order and from where user language should be detected
      order: ["querystring", "localStorage", "navigator"],
    },
    fallbackLng: "nl-BE",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    keySeparator: false,
    load: "currentOnly",

    ns: namespaces,

    supportedLngs: ["nl-BE", "en", "fr-BE"],
  });

export default i18n;
