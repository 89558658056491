import { useMutation, useQuery } from "@apollo/client";
import CallIcon from "@mui/icons-material/Call";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SyncIcon from "@mui/icons-material/Sync";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { graphql } from "../../api/__generated__";
import Button from "../ui/buttons/Button";
import { RouteNames } from "../routes/routeNames";
import { QualityQuestions } from "../quality-questions/QualityQuestions.types";
import { useNavigate } from "react-router-dom";
import { OrgProfileDialog } from "../organizations/OrgProfileDialog";
import { useCallback, useState } from "react";
import useSelectTranslation from "../../api/languages/useSelectTranslation";

const clientConsultationActionQuery = graphql(`
  query ConsultationDetailActions($uuid: String!) {
    consultation(uuid: $uuid) {
      acceptedByHuman
      acceptedByProfessional
      human {
        id
        organization {
          id
          name {
            ...useSelectTranslationStringInput
          }
          ...OrgProfileDialogOrganization
          profile {
            ...useSelectTranslationStringInput
          }
        }
      }
      id
      status
      type
    }
    currentUser {
      email
      id
    }
  }
`);

const confirmConsultationMutation = graphql(`
  mutation ConfirmConsultationMutationProfessional(
    $input: ConfirmConsultationInput!
  ) {
    confirmConsultation(input: $input) {
      acceptedByHuman
      acceptedByProfessional
      id
      status
    }
  }
`);

function ProfessionalConsultstionActions({
  consultationUuid,
  onCancel,
  onReschedule,
}: {
  consultationUuid: string;
  onCancel?: () => void;
  onReschedule?: () => void;
}) {
  const [orgDialogOpen, setOrgDialogOpen] = useState(false);

  const { data } = useQuery(clientConsultationActionQuery, {
    variables: {
      uuid: consultationUuid,
    },
  });

  const [confirmConsultation, { loading: confirmingConsulation }] = useMutation(
    confirmConsultationMutation,
    {
      onCompleted: () => {
        setOrgDialogOpen(false);
      },
      refetchQueries: ["ProfessionalConsultations"],
    },
  );

  const handleConfirm = useCallback(() => {
    if (data?.currentUser) {
      confirmConsultation({
        variables: {
          input: {
            email: data.currentUser.email,
            uuid: consultationUuid,
          },
        },
      });
    }
  }, [confirmConsultation, consultationUuid, data?.currentUser]);

  const smallScreen = useMediaQuery("(max-width: 500px)");

  const navigate = useNavigate();

  const selectTranslation = useSelectTranslation();

  const { t } = useTranslation();

  const startCall = () => {
    const redirectPath = RouteNames.Call.Enter.path.replace(
      ":uuid",
      consultationUuid,
    );

    navigate(
      QualityQuestions.getQuestionPath(
        QualityQuestions.Entrypoints.BeforeConsultation,
        redirectPath,
        false,
        data?.consultation?.id,
      ),
    );
  };

  if (!data?.consultation) {
    return null;
  }

  const showStartConsultation =
    data.consultation.status !== "CANCELLED" &&
    data.consultation.status !== "CANCELLED_BY_HUMAN" &&
    data.consultation.status !== "CANCELLED_BY_PROFESSIONAL" &&
    data.consultation.status !== "DONE" &&
    data.consultation.status !== "NO_SHOW_HUMAN" &&
    data.consultation.status !== "NO_SHOW_PROFESSIONAL";

  const showConfirmConsultation =
    data.consultation.status === "REQUESTED" &&
    data.consultation.acceptedByProfessional === false;

  const showReschedule =
    data.consultation.status !== "CANCELLED" &&
    data.consultation.status !== "CANCELLED_BY_HUMAN" &&
    data.consultation.status !== "CANCELLED_BY_PROFESSIONAL" &&
    data.consultation.status !== "DONE" &&
    data.consultation.status !== "NO_SHOW_HUMAN" &&
    data.consultation.status !== "NO_SHOW_PROFESSIONAL";

  const showCancel =
    data.consultation.status !== "CANCELLED" &&
    data.consultation.status !== "CANCELLED_BY_HUMAN" &&
    data.consultation.status !== "CANCELLED_BY_PROFESSIONAL" &&
    data.consultation.status !== "DONE" &&
    data.consultation.status !== "NO_SHOW_HUMAN" &&
    data.consultation.status !== "NO_SHOW_PROFESSIONAL";

  return (
    <Box mt={3}>
      {showStartConsultation && (
        <Box
          sx={{
            display: "inline-block",
            marginBottom: "1rem",
            marginRight: "1rem",
          }}
        >
          <Button
            icon={<CallIcon />}
            label={t("common:startconversation")}
            onClick={startCall}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      )}

      {showConfirmConsultation && (
        <Box
          sx={{
            display: "inline-block",
            marginBottom: "1rem",
            marginRight: "1rem",
          }}
        >
          <Button
            bloomColor="green"
            icon={<CheckCircleIcon />}
            label={t("common:confirm")}
            loading={confirmingConsulation}
            onClick={() => {
              if (
                data.consultation &&
                data.consultation.type === "INTRO" &&
                selectTranslation(
                  data.consultation.human?.organization?.profile,
                )
              ) {
                setOrgDialogOpen(true);
              } else {
                handleConfirm();
              }
            }}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      )}
      {showReschedule && onReschedule && (
        <Box
          sx={{
            display: "inline-block",

            marginBottom: "1rem",
            marginRight: "1rem",
          }}
        >
          <Button
            bloomColor="orange"
            icon={<SyncIcon />}
            label={t("common:consultation.edit")}
            onClick={() => onReschedule()}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      )}
      {showCancel && onCancel && (
        <Box
          sx={{
            display: "inline-block",
            marginBottom: "1rem",
            marginRight: "1rem",
          }}
        >
          <Button
            bloomColor="red"
            icon={<CancelIcon />}
            label={t("common:cancel_consultation")}
            onClick={() => onCancel()}
            size={smallScreen ? "medium" : "large"}
          />
        </Box>
      )}
      {data.consultation.human?.organization && (
        <OrgProfileDialog
          confirmationFooter={true}
          onClose={(accepted) => {
            if (accepted) {
              handleConfirm();
            } else {
              setOrgDialogOpen(false);
            }
          }}
          open={orgDialogOpen}
          organization={data.consultation.human.organization}
        />
      )}
    </Box>
  );
}

export default ProfessionalConsultstionActions;
