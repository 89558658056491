import { useTranslation } from "react-i18next";
import { enGB, fr, Locale, nlBE } from "date-fns/locale";

const dateLocales = {
  en: enGB,
  "fr-BE": fr,
  "nl-BE": nlBE,
};

export const useDateLocale = (): Locale => {
  const { i18n } = useTranslation();

  return dateLocales[i18n.language];
};
