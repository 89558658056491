import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactComponent as Plant } from "./plant-thinking.svg";
const PREFIX = "SpeechPlant";

const classes = {
  bubbleBox: `${PREFIX}-bubbleBox`,
  bubbleText: `${PREFIX}-bubbleText`,
  rootBox: `${PREFIX}-rootBox`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.rootBox}`]: {
    alignItems: "end",
    display: "flex",
    flexDirection: "row",
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    top: "1,5rem",
  },

  [`& .${classes.bubbleBox}`]: {
    "&:after": {
      backgroundColor: theme.palette.background.default,
      borderBottomRightRadius: "10px",
      bottom: 0,
      content: '""',
      height: "25px",
      left: "-10px",
      position: "absolute",
      width: "10px",
      [theme.breakpoints.down(440)]: {
        display: "none",
      },
    },
    "&:before": {
      backgroundColor: "#E5DCD4",
      borderBottomRightRadius: "16px 14px",
      bottom: 0,
      content: '""',
      height: "25px",
      left: "-7px",
      position: "absolute",
      width: "20px",
      [theme.breakpoints.down(440)]: {
        display: "none",
      },
    },
    backgroundColor: "#E5DCD4",
    borderRadius: "24px",
    flex: 1,
    marginBottom: "30px",
    maxWidth: "470px",
    padding: "16px",
    position: "relative",
  },

  [`& .${classes.bubbleText}`]: {
    zIndex: 1,
  },
}));

interface SpeechPlantProps {
  text: string;
}

const SpeechPlant = ({ text }: SpeechPlantProps) => {
  return (
    <StyledBox className={classes.rootBox}>
      <Box
        sx={(theme) => ({
          height: 120,
          width: 100,
          zIndex: 2,
          [theme.breakpoints.down(440)]: { display: "none" },
        })}
      >
        <Plant />
      </Box>

      <Box className={classes.bubbleBox}>
        <Typography className={classes.bubbleText}>{text}</Typography>
      </Box>
    </StyledBox>
  );
};

export default SpeechPlant;
