import React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useTranslation } from "react-i18next";

import TitleWithButton from "../ui/text/TitleWithButton";

const PREFIX = "RightSection";

const classes = {
  grid: `${PREFIX}-grid`,
  section: `${PREFIX}-section`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.grid}`]: {
    height: "100%",
    overflowY: "auto",
    padding: theme.spacing(4, 0, 4, 2),

    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: theme.spacing(4, 1),
    },
  },

  [`& .${classes.section}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    padding: theme.spacing(0, 1),
    position: "relative",

    [theme.breakpoints.down("md")]: {
      height: "auto",
      padding: theme.spacing(2),
    },
  },
}));

export default function RightSection({ children, handleClose, title }) {
  const { t: translate } = useTranslation(["common"]);

  return (
    <StyledGrid className={classes.grid} item md={6} sm={12} xs={12}>
      <section className={classes.section}>
        <TitleWithButton
          buttonIcon={<CloseOutlinedIcon />}
          buttonLabel={
            handleClose ? translate("common:close", "Sluit") : undefined
          }
          handleButton={handleClose}
          title={title}
        />
        {children}
      </section>
    </StyledGrid>
  );
}
