import { useMemo } from "react";
import { IFormValidationFunc } from "../../../../utils/forms/createFormValidation";
import useFormState from "../../../hooks/useFormState";

const INITIAL_VALUES = {
  isAcceptingNewClients: false,
  isOnline: false,
  isOnlineOption: null,
};

export default function useAvailabilityFormState(
  initialValues?: any,
  options?: any,
) {
  const validation: IFormValidationFunc = useMemo(() => () => [], []);

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
