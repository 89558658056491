/**
 * Therapy types of a psy. This can be something like 'Bemiddeling'
 */

import _ from "lodash";
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import { DataTypePaths } from "../../dataTypePaths";
import SelectionDataType from "../../SelectionDataType";

export enum TherapyOptions {
  AAT = "aat",
  ACT = "act",
  BEHAVIORAL = "behavioral",
  CLIENT_ORIENTED = "client-oriented",
  COGNITIVE_BEHAVIOR = "cognitive-behavior",
  EFT = "eft",
  EMDR = "emdr",
  FAMILY = "family",
  FIRST_LINE = "first-line",
  FOCUSING = "focusing",
  GESTALT = "gestalt",
  INTEGRATION_PSYCHO = "integration-psycho",
  MBCT = "mbct",
  MEDIATION = "mediation",
  PSYCHO_ANALYTICAL = "psycho-analytical",
  PSYCHO_DIAGNOSTICS = "psycho-diagnostics",
  PSYCHO_ONCOLOGY = "psycho-oncology",
  RELATION = "relation",
  SCHEMA = "schema",
  SEXUOLOGY = "sexuology",
  SOLUTION = "solution",
  SUPERVISION = "supervision",
  SYSTEM = "system",
  TALK_THERAPY = "talk",
  VOICE_DIALOGUE = "voice-dialogue",
}

export default class Therapies extends SelectionDataType<
  Array<TherapyOptions>,
  TherapyOptions,
  DataTypePaths.Professional.TherapyTypes
> {
  protected static path: DataTypePaths.Professional.TherapyTypes =
    "therapyTypes";

  protected static defaultValue = [];
  protected static translationKey = "glossary:therapytype";

  protected fromGraphQL = (value: string): Array<string> => JSON.parse(value);
  protected forGraphQL = (value: Array<string>): string =>
    JSON.stringify(value);

  protected type: any = Therapies;

  /**
   * Return an array of objects usable for a select-type of control.
   * This contains the value, label and an optionally, an icon.
   *
   * @param  {TFunction} translate The i18next translate hook with the glossary namespace.
   */
  public getSelectOptions(): Array<ISelectObject<TherapyOptions>> {
    const options: Array<ISelectObject<TherapyOptions>> = Object.values(
      TherapyOptions,
    ).map((value) => {
      return {
        label: `${Therapies.translationKey}.${value}`,
        value,
      };
    });

    return _.orderBy(options, ["label"], ["asc"]);
  }

  /**
   * Get all the options this data type can hold.
   *
   * @return {Object<Constant>}
   */
  public getOptions(): any {
    return TherapyOptions;
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {TFunction} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(translate: TFunction): Array<IFormValidation> {
    return [
      {
        message: translate(
          "therapies.toomany",
          "Geef maximum 5 therapievormen op.",
        ),
        path: this.getPath(),
        validate: (
          therapyTypes: Array<ISelectObject<TherapyOptions>>,
        ): boolean => therapyTypes.length < 6,
      },
      {
        message: translate(
          "therapies.mandatory",
          "Geef minstens 1 therapievorm op.",
        ),
        path: this.getPath(),
        validate: (
          therapyTypes: Array<ISelectObject<TherapyOptions>>,
        ): boolean => therapyTypes.length > 0,
      },
    ];
  }

  constructor(value?: any, fromGQL = false) {
    super(value);
    this.setValue(value || Therapies.defaultValue, fromGQL);
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This function only returns the values set to this object.
   *
   * @param  {[type]} translate The i18next translate hook with the glossary namespace.
   * @return {Array<ISelectObject>}
   */
  public getSelectedOptions(): Array<ISelectObject<TherapyOptions>> {
    const options: Array<ISelectObject<TherapyOptions>> = [];

    if (this.getValue() === null) return [];

    for (const option of this.value) {
      options.push({
        label: `${Therapies.translationKey}.${option}`,
        value: option,
      });
    }

    return _.orderBy(options, ["label"], ["asc"]);
  }

  /**
   * Return an array of translated values.
   * This function only returns the values set to this object.
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   * @return {Array<String>}
   */
  public getTranslatedValues(): Array<string> {
    throw new Error(
      "Please don't use this function, it's only here to statisfy typescript",
    );
  }

  /**
   * Get the humanreadible string for this data type's value.
   * Ex: Mediatie, Familie-therapie
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   */
  public prettyPrint(): string {
    return this.getSelectedOptions()
      .map(({ label }) => label)
      .filter(Boolean)
      .join(", ");
  }

  getAsFormStateValue(
    translate: TFunction<I18Namespaces>,
  ): Record<
    DataTypePaths.Professional.TherapyTypes,
    ISelectObject<TherapyOptions>[]
  > {
    const formStateValues = this.value.map((v) => {
      return {
        label: translate(`${Therapies.translationKey}.${v}`),
        value: v,
      };
    });

    return { [Therapies.path]: formStateValues };
  }
}
