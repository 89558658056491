import { createContext } from "react";
import { IClockContext } from "./clock";

export const defaultClockValues: IClockContext = {
  canStart: false,
  count: 0,
  defaultStarted: false,
  displayValue: "",
  resetClock: () => {},
  running: false,
  setClockState: () => {},
  startClock: () => {},
  stopClock: () => {},
  toggleRunning: () => {},
  updateClockFunction: () => {},
};

const ClockContext = createContext<IClockContext>(defaultClockValues);

export default ClockContext;
