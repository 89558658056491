import React, { useContext } from "react";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { IToast } from "../../providers/toast/toast";
import ToastContext from "../../providers/toast/ToastContext";

const FullAppToast = React.forwardRef(({ ...snackbarProps }: IToast, ref) => {
  const { toast, open, setOpen } = useContext(ToastContext);

  return toast ? (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      autoHideDuration={
        !toast.noHide ? (toast.closeAfter ? toast.closeAfter : 8000) : undefined
      }
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      ref={ref}
      {...snackbarProps}
    >
      <MuiAlert
        elevation={6}
        onClose={() => {
          setOpen(false);
        }}
        severity={toast.severity}
        variant="filled"
      >
        {toast.message}
      </MuiAlert>
    </Snackbar>
  ) : null;
});

export default FullAppToast;
