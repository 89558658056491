import { createContext } from "react";
import { IAuthContext } from "./auth";

export const defaultAuthProviderValues: IAuthContext = {
  accessToken: undefined,
  clearUser: () => {},
  currentUser: undefined,
  internalAppLogin: () => new Promise(() => {}),
  isAuthenticated: false,
  loading: false,
  logout: () => {},
  refetchMe: () => {},
  refreshToken: undefined,
  setCurrentUser: () => {},
  updateCurrentHuman: () => {},
};

const AuthContext = createContext<IAuthContext>(defaultAuthProviderValues);

export default AuthContext;
