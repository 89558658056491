import { APIHuman } from "../../api/humans/humans";
import { APIProfessional } from "../../api/professionals/professionals";
import { UserRoles } from "../../data-model/types/User";
import Human from "../../data-model/types/human/Human";
import Professional from "../../data-model/types/professional/Professional";

/**
 * Use this function if the model can be any type of user. This will throw back the correct data object.
 * Can return Human or Professional.
 */
export function createCorrectUser(
  model: APIHuman.GeneralizedResult | APIProfessional.Result,
  role: UserRoles,
): Human | Professional | undefined {
  switch (role) {
    case UserRoles.HUMAN:
      return new Human(model, true);

    case UserRoles.PROFESSIONAL:
      return new Professional(model, true);

    case UserRoles.ADMIN:
      return new Professional(model, true, true);

    case UserRoles.TEST:
      return new Human(model, true);

    case UserRoles.ORGANIZATION_ADMIN:
      return new Human(model, true, true);

    default:
      return undefined;
  }
}
