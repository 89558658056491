import { ReactNode, useContext } from "react";
import { useTranslation } from "react-i18next";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box } from "@mui/material";
import TextField from "../../../ui/form/TextField";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import { IProfessionalOnboardingContext } from "../context/professionalOnboarding";
import { getTitleAndPlaceholderForDescriptionField } from "../../professional-profile/util/profileHelpers";
import Label from "../../../ui/text/Label";
import { prepareOneLanguageForAPI } from "../../../language/languagesUtil";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import ConsultationLanguages from "../../../../data-model/types/professional/ConsultationLanguages";
import { DESCRIPTION_PREFIX } from "../../../../data-model/types/professional/Description";

export default function ProfileStep() {
  const { next, previous, step, totalSteps, formState } =
    useContext<IProfessionalOnboardingContext>(ProfessionalOnboardingContext);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
  ]);

  let languages = formState.getInputProps(
    ConsultationLanguages.getPath(),
  ).value;

  const getMultipleDescriptionFields = (): Array<ReactNode | ReactNode[]> => {
    if (!languages) languages = [];

    return languages.map(({ value: language }: ISelectObject) => {
      const fields = getTitleAndPlaceholderForDescriptionField(language);
      const lang = prepareOneLanguageForAPI(language);

      return (
        <Box mt={3}>
          <Label value={fields.label} />
          <TextField
            autoFocus
            key={lang}
            multiline
            rows={4}
            {...formState.getInputProps(`${DESCRIPTION_PREFIX}-${lang}`)}
            id={`test_onboarding_professional_description_${lang}`}
            placeholder={fields.placeholder}
          />
        </Box>
      );
    });
  };

  const error = languages.reduce((acc, language) => {
    const lang = prepareOneLanguageForAPI(language.value);
    const lbl = `${DESCRIPTION_PREFIX}-${lang}`;
    const errors = formState.testForErrors(lbl);

    return acc || errors;
  }, false);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("professional:onboarding.bio.description")}
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je Profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={next}
        showLanguageSelector={false}
        step={step}
        subhead={translate("professional:onboarding.bio.title", "Wie ben je?")}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        {getMultipleDescriptionFields()}
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
