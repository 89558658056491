import { ResponsiveFunnel } from "@nivo/funnel";
import { styled } from "@mui/material/styles";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { ResponsiveBar } from "@nivo/bar";
import { Grid, Paper } from "@mui/material";
import { useLocation } from "react-router-dom";
import CardItem from "../../ui/hr-dashboard/CardItem";
import { BloomUpNamespacesEnum } from "../../language/I18Namespaces";
import DatePicker from "../../ui/form/DatePicker";
import BreadCrumbs from "../../ui/hr-dashboard/BreadCrumbs";
import { graphql } from "../../../api/__generated__";
const PREFIX = "VideoConsultPage";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    "& > *": {
      height: theme.spacing(16),
      margin: theme.spacing(1),
      width: theme.spacing(16),
    },
    display: "flex",
    flexWrap: "wrap",
  },
}));

const HRDashboards = BloomUpNamespacesEnum.HRDashboards;

const query = graphql(`
  query VideoConsultPage {
    videoConsultBarchartEmployeesWithAnAmountOfConsults {
      name
      value
    }
    videoConsultCardAverageConsultPerEmployee
    videoConsultCardCancelledConsults
    videoConsultCardCompletedConsults
    videoConsultCardNoShowConsults
    videoConsultFunnel {
      name
      value
    }
  }
`);

function VideoConsultPage() {
  const location = useLocation();

  const { t } = useTranslation();
  const { data } = useQuery(query);

  const funnelData = data?.videoConsultFunnel.map((item) => {
    return {
      id: item.name,
      label: t(item.name),
      value: item.value,
    };
  });

  const barchartData =
    data?.videoConsultBarchartEmployeesWithAnAmountOfConsults.map((item) => {
      return {
        name: item.name,
        value: item.value,
      };
    });

  return (
    <Root style={{ width: "100%" }}>
      <BreadCrumbs
        pages={location.pathname
          .replace(" ", "")
          .replace("/home/hr-dashboards", "")
          .replace("/", "")
          .split("/")}
      ></BreadCrumbs>
      <div style={{ margin: "0px auto", width: "80%" }}>
        <Grid
          container
          spacing={1}
          style={{
            width: "100%",
          }}
        >
          <Grid
            item
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
            xs={12}
          >
            <h1 style={{ flexShrink: 0 }}>
              {t(`${HRDashboards}:translation.videoConsultPage.videoConsult`)}
            </h1>
            <div
              style={{ alignItems: "center", display: "flex", flexShrink: 0 }}
            >
              <span
                style={{
                  paddingRight: "10px",
                }}
              >
                {t(
                  `${HRDashboards}:translation.landingLineChart.viewReportFrom`,
                )}
              </span>
              <DatePicker
                appointment={false}
                disabled
                fullWidth={false}
                setAppointment={false}
              ></DatePicker>
              <span
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {t(`${HRDashboards}:translation.landingLineChart.to`)}
              </span>
              <DatePicker
                appointment={false}
                disabled
                fullWidth={false}
                setAppointment={false}
              ></DatePicker>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid
            className={classes.root}
            item
            style={{ height: 250, marginBottom: 1, width: "100%" }}
            xs={12}
          >
            <Paper
              style={{
                fontWeight: "bold",
                height: "100%",
                margin: 0,
                width: "100%",
              }}
              variant="outlined"
            >
              {funnelData && (
                <div style={{ height: "90%" }}>
                  <ResponsiveFunnel
                    afterSeparatorLength={20}
                    afterSeparatorOffset={20}
                    animate={true}
                    beforeSeparatorLength={20}
                    beforeSeparatorOffset={20}
                    borderColor={{
                      from: "color",
                      modifiers: [["darker", 0.2]],
                    }}
                    borderWidth={20}
                    colors={{ scheme: "spectral" }}
                    currentBorderWidth={40}
                    currentPartSizeExtension={10}
                    data={funnelData}
                    direction="horizontal"
                    isInteractive={true}
                    labelColor={{
                      from: "color",
                      modifiers: [["darker", 3]],
                    }}
                    margin={{ bottom: 20, left: 20, right: 20, top: 20 }}
                    motionConfig="wobbly"
                    shapeBlending={1}
                    valueFormat=">-.1s"
                  />
                </div>
              )}
              <p style={{ margin: 0, textAlign: "center" }}>
                {t(
                  `${HRDashboards}:translation.videoConsultFunnel.employee_per_x`,
                )}
              </p>
            </Paper>
          </Grid>
          <Grid item sm={4} style={{ height: 150 }} xs={12}>
            <CardItem
              title={t(
                `${HRDashboards}:translation.videoConsultCard.completed_consults`,
              )}
              value={data?.videoConsultCardCompletedConsults}
            ></CardItem>
          </Grid>
          <Grid item sm={4} style={{ height: 150 }} xs={12}>
            <CardItem
              title={t(
                `${HRDashboards}:translation.videoConsultCard.canceled_consults`,
              )}
              value={data?.videoConsultCardCancelledConsults}
            ></CardItem>
          </Grid>
          <Grid item sm={4} style={{ height: 150 }} xs={12}>
            <CardItem
              title={t(
                `${HRDashboards}:translation.videoConsultCard.no_show_consults`,
              )}
              value={data?.videoConsultCardNoShowConsults}
            ></CardItem>
          </Grid>
          <Grid item sm={4} style={{ height: 150 }} xs={12}>
            <CardItem
              title={t(
                `${HRDashboards}:translation.videoConsultCard.average_amount_consults_per_employee`,
              )}
              value={Math.round(
                data?.videoConsultCardAverageConsultPerEmployee || 0,
              )}
            ></CardItem>
          </Grid>
          <Grid item sm={8} style={{ height: 150 }} xs={12}>
            <Paper
              style={{
                fontWeight: "bold",
                height: "100%",
                margin: 0,
                width: "100%",
              }}
              variant="outlined"
            >
              {barchartData && (
                <ResponsiveBar
                  ariaLabel="employees with x amount of consults"
                  axisBottom={{
                    legend: t(
                      `${HRDashboards}:translation.videoConsultBarChart.employees`,
                    ),
                    legendOffset: 32,
                    legendPosition: "middle",
                    tickPadding: 10,
                    tickRotation: 0,
                    tickSize: 1,
                  }}
                  axisLeft={{
                    format: (e) => Math.floor(e) === e && e,
                    legend: t(
                      `${HRDashboards}:translation.videoConsultBarChart.consults`,
                    ),
                    legendOffset: -40,
                    legendPosition: "middle",
                    tickPadding: 10,
                    tickRotation: 0,
                    tickSize: 1,
                  }}
                  axisRight={null}
                  axisTop={null}
                  barAriaLabel={function (e) {
                    return e.id + ": " + e.value;
                  }}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  borderWidth={1}
                  colors="#84C59C"
                  data={barchartData}
                  groupMode="grouped"
                  indexBy="name"
                  indexScale={{ round: true, type: "band" }}
                  keys={["value"]}
                  labelSkipHeight={12}
                  labelSkipWidth={12}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  margin={{ bottom: 50, left: 60, right: 10, top: 20 }}
                  padding={0.3}
                  valueScale={{ type: "linear" }}
                />
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </Root>
  );
}

export default VideoConsultPage;
