import { ERROR_CODE_UPLOAD } from "../constants";

const UNAUTHENTICATED = "UNAUTHENTICATED";

const isUnauthenticatedError = (error) =>
  error.extensions.code === UNAUTHENTICATED;

export const containsUnauthenticatedError = ({ networkError }) =>
  typeof networkError !== "undefined" &&
  networkError !== null &&
  networkError.response &&
  networkError.response.status === 400 &&
  !!networkError.result.errors.find((error) => isUnauthenticatedError(error));

export const connectionToCollection = (connection = { edges: [] }) =>
  connection.edges.map((edge) => edge.node);

export const parseGraphQLErrors = (error) => {
  if (!error) return undefined;

  const { graphQLErrors } = error;

  if (graphQLErrors && Array.isArray(graphQLErrors)) {
    const [extras] = graphQLErrors;
    let invalidFields, code;

    if (extras && extras.extensions) {
      invalidFields = extras.extensions.invalidFields;
      code = extras.extensions.code;
    }

    if (code === ERROR_CODE_UPLOAD) return code;

    if (typeof invalidFields === "undefined") {
      return [
        {
          message: extras ? extras.message : "",
          path: undefined,
        },
      ];
    } else {
      return Object.keys(invalidFields).map((key) => ({
        message: invalidFields[key],
        path: key,
      }));
    }
  }
};
