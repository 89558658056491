import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchProfessionalsForHuman from "../../../api/professionals/hooks/useFetchProfessionalsForHuman";
import { UserRoles } from "../../../data-model/types/User";
import LoadingPage from "../../layout/LoadingPage";
import AuthContext from "../../providers/auth/AuthContext";
import { I18Namespaces } from "../../language/I18Namespaces";
import ProfessionalsPage from "./ProfessionalsPage";

export default function ProfessionalPageWithState() {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  const { currentUser } = useContext(AuthContext);
  const [focus, setFocus] = useState<number | undefined>(undefined);
  const { professionals, loading } = useFetchProfessionalsForHuman();

  const handleClick = (id: number | undefined) => setFocus(id);

  if (loading || !currentUser) return <LoadingPage />;

  return (
    <ProfessionalsPage
      focus={focus}
      handleClick={handleClick}
      onResponse={() => console.log("On Response")}
      pageTitle={translate("common:pros", "Professionals")}
      professionals={professionals}
      roleType={currentUser.getRole() ?? UserRoles.HUMAN}
    />
  );
}
