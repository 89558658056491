/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import TextField from "../../../ui/form/TextField";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import Label from "../../../ui/form/FormLabel";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import CMNumber from "../../../../data-model/types/professional/CMNumber";
import VisumNumber from "../../../../data-model/types/professional/VisumNumber";

export default function VisumAndCMNumberStep() {
  const { next, previous, step, totalSteps, formState } = useContext(
    ProfessionalOnboardingContext,
  );

  const { t: translate } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Professional,
  );

  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    const CMNumberError = formState.testForErrors(CMNumber.getPath());

    const VisumNumberError = formState.testForErrors(VisumNumber.getPath());

    const errorFromState = CMNumberError || VisumNumberError;

    if (errorFromState !== error) {
      setError(errorFromState);
    }
  }, [formState.errors]);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate(
          "professional:onboarding.visumandcmnumber.description",
          "Geef minstens 1 van beide erkenningsnummers op.",
        )}
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je Profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={next}
        showLanguageSelector={false}
        step={step}
        subhead={translate(
          "professional:onboarding.visumandcmnumber.title",
          "Erkenningsnummers",
        )}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Label
          label={translate(
            "professional:onboarding.visumandcmnumber.visum.description",
            "Wat is je visumnummer?",
          )}
        />
        <TextField
          autoFocus
          placeholder={translate(
            "professional:onboarding.visumandcmnumber.visum.placeholder",
            "vb: 123456",
          )}
          style={{
            marginBottom: 20,
          }}
          {...formState.getInputProps(VisumNumber.getPath())}
          id={`test_onboarding_professional_visum_number_textfield`}
        />
        <Label
          label={translate(
            "professional:onboarding.visumandcmnumber.cm.description",
            "Wat is je CM erkenningsnummer? (optioneel)",
          )}
        />
        <TextField
          placeholder={translate(
            "professional:onboarding.visumandcmnumber.cm.placeholder",
            "vb: 1234567",
          )}
          style={{
            marginBottom: 20,
          }}
          {...formState.getInputProps(CMNumber.getPath())}
          id={`test_onboarding_professional_cm_number_textfield`}
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
