import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import StyledTooltip from "../Tooltip";

const PREFIX = "Label";

const classes = {
  itemTitle: `${PREFIX}-itemTitle`,
  root: `${PREFIX}-root`,
  tooltip: `${PREFIX}-tooltip`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.itemTitle}`]: {
    "&.variant-error": {
      color: theme.palette.red.dark,
      fontWeight: 600,
    },
    "&.variant-warning": {
      color: theme.palette.orange.dark,
      fontWeight: 600,
    },
    color: theme.palette.bloom.dark,
    display: "inline",
    fontSize: theme.typography.sizes.normal,

    fontWeight: 600,

    width: "100%",
  },

  [`& .${classes.tooltip}`]: {
    display: "inline",
    position: "relative",
  },
}));

/**
 * A text label, typically shown near values.
 *
 * @param   {String} value The value to be displayed.
 * @param   {Boolean} noWrap
 *          If true the text will not wrap but truncate with ellipsis.
 * @param   {String} [variant="normal"] The variant of the label.
 *          This can be "normal", "warning", "error"
 *
 * @constructor
 */
interface ILabel {
  noWrap?: boolean;
  tooltip?: string | null;
  value: string;
  variant?: string;
}

export default function Label({
  value,
  noWrap = false,
  variant = "normal",
  tooltip,
}: ILabel) {
  return (
    <StyledBox className={classes.root}>
      <Typography
        className={`
                ${classes.itemTitle}
                variant-${variant}`}
        noWrap={noWrap}
      >
        {value}
      </Typography>
      {tooltip && (
        <Box className={classes.tooltip}>
          <StyledTooltip text={tooltip} />
        </Box>
      )}
    </StyledBox>
  );
}
