import { Route, Routes } from "react-router-dom";

import { RouteNames } from "./routeNames";
import LoginPage from "../pages/login/LoginPage";
import SsoLanding from "../pages/login/SsoLanding";
import SsoRedirect from "../pages/login/SsoRedirect";

export default function LoginRoutes() {
  return (
    <Routes>
      <Route element={<LoginPage />} path={""} />
      <Route element={<SsoLanding />} path={RouteNames.Login.Sso.name} />
      <Route
        element={<SsoRedirect />}
        path={RouteNames.Login.SsoRedirect.name}
      />
    </Routes>
  );
}
