import { Box, styled, Typography } from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Button from "../../../ui/buttons/Button";
import { graphql } from "../../../../api/__generated__";
import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import AuthContext from "../../../providers/auth/AuthContext";
import ToastContext from "../../../providers/toast/ToastContext";
import StepHeader from "../Common/StepHeader";
import StepMainContent from "../Common/StepMainContent";
import Footer from "../Common/Footer";
import { Login, PersonAddAlt1 } from "@mui/icons-material";
import StyledInput from "./StyledInput";
import Email from "./Email.svg";
import LockOpen from "./LockOpen.svg";
import SafetyCheck from "./SafetyCheck.svg";

const loginMutation = graphql(`
  mutation LoginInMatching($input: LoginV2Input!) {
    loginV2(input: $input) {
      ... on TokenResponse {
        accessToken
        refreshToken
      }
      ... on LoginV2FailureResponse {
        reason
      }
    }
  }
`);

const StyledLabel = styled("label")(({ theme }) => ({
  color: theme.palette.black.main,
  display: "block",
  fontSize: "20px",
}));

const LoginStep = ({
  bookIntroConsult,
  stepBack,
  stepForward,
}: {
  bookIntroConsult: () => Promise<void>;
  stepBack: () => void;
  stepForward: (nextStep: string) => void;
}) => {
  const { t } = useTranslation();
  const [login] = useMutation(loginMutation);
  const { internalAppLogin } = useContext(AuthContext);
  const { setToast, close: closeToast } = useContext(ToastContext);
  const [show2FA, setShow2FA] = useState(false);

  return (
    <>
      <StepHeader
        imageUrl={
          "https://production-guidedgrowthtracks.s3.eu-central-1.amazonaws.com/shared/plants/ID%3D86-login%2C+Size%3DSmall.png"
        }
        subtitle={t("match:loginStep.header.subtitle")}
        title={t("match:loginStep.header.title")}
      />
      <StepMainContent
        nextButtonIcon={<PersonAddAlt1 />}
        nextButtonLabel={t("match:loginStep.registerButton")}
        onClickNext={() => stepForward("register")}
        onClickPrevious={stepBack}
      >
        <Box
          sx={{
            "@media screen and (max-width: 800px)": {
              marginBottom: "48px",
            },
            marginBottom: "96px",
            maxWidth: "1000px",
          }}
        >
          <Formik
            initialValues={{ email: "", password: "", token2FA: "" }}
            onSubmit={async (values) => {
              closeToast();

              const { data } = await login({
                variables: {
                  input: {
                    email: values.email,
                    password: values.password,
                    token2FA: values.token2FA,
                  },
                },
              });

              if (!data) {
                setShow2FA(false);
                setToast({
                  message: t("api_errors:login.failure"),
                  noHide: true,
                  severity: "warning",
                });
              } else if (data.loginV2.__typename === "LoginV2FailureResponse") {
                if (data.loginV2.reason === "INCORRECT_CREDENTIALS") {
                  setShow2FA(false);
                  setToast({
                    message: t("api_errors:login.failure"),
                    noHide: true,
                    severity: "warning",
                  });
                } else if (data.loginV2.reason === "NEEDS_2FA") {
                  setShow2FA(true);
                } else {
                  setShow2FA(true);
                  setToast({
                    message: t("api_errors:login.2fa_failure"),
                    noHide: true,
                    severity: "warning",
                  });
                }
              } else if (data.loginV2.__typename === "TokenResponse") {
                // Temporary fallback while mindlabToken is optional on the graph
                if (!data.loginV2.accessToken || !data.loginV2.refreshToken) {
                  setToast({
                    message: t("api_errors:login.failure"),
                    noHide: true,
                    severity: "warning",
                  });

                  return;
                }

                await internalAppLogin(
                  data.loginV2.accessToken,
                  data.loginV2.refreshToken,
                );

                await bookIntroConsult();
                stepForward("endMatching");
              }
            }}
            validateOnChange={false}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .required(t("validation:email.mandatory"))
                .email(t("validation:email.validity")),
              password: Yup.string().required(
                t("validation:password.mandatory"),
              ),
              token2FA: Yup.string(),
            })}
          >
            {({
              handleSubmit,
              errors,
              handleBlur,
              values,
              handleChange,
              submitForm,
              touched,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {show2FA ? (
                    <>
                      <StyledLabel htmlFor="token2FA">
                        {t("common:token2fa")}
                      </StyledLabel>
                      <StyledInput
                        autoComplete="one-time-code"
                        autoFocus
                        id="token2FA"
                        name="token2FA"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(e) =>
                          e.key === "Enter" ? submitForm() : null
                        }
                        sx={{
                          backgroundImage: `url('${SafetyCheck}')`,
                          marginTop: "10px",
                        }}
                        type="text"
                        value={values.token2FA}
                      />
                      {touched.token2FA && errors.token2FA && (
                        <Typography
                          sx={(theme) => ({
                            color: theme.palette.error.main,
                            fontSize: "14px",
                          })}
                          variant={"caption"}
                        >
                          {errors.token2FA}
                        </Typography>
                      )}
                    </>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "30px",
                      }}
                    >
                      <Box>
                        <StyledLabel htmlFor="email" sx={{ marginTop: "30px" }}>
                          {t("common:email")}
                        </StyledLabel>
                        <StyledInput
                          autoComplete="email"
                          autoFocus
                          id="email"
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          sx={{
                            backgroundImage: `url('${Email}')`,
                            marginTop: "10px",
                          }}
                          type="email"
                          value={values.email}
                        />
                        {touched.email && errors.email && (
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.error.main,
                              fontSize: "14px",
                            })}
                            variant={"caption"}
                          >
                            {errors.email}
                          </Typography>
                        )}
                      </Box>
                      <Box>
                        <StyledLabel htmlFor="password">
                          {t("common:password")}
                        </StyledLabel>
                        <StyledInput
                          autoComplete="password"
                          id="password"
                          name="password"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          onKeyDown={(e) =>
                            e.key === "Enter" ? submitForm() : null
                          }
                          sx={{
                            backgroundImage: `url('${LockOpen}')`,
                            marginTop: "10px",
                          }}
                          type="password"
                          value={values.password}
                        />
                        {touched.password && errors.password && (
                          <Typography
                            sx={(theme) => ({
                              color: theme.palette.error.main,
                              fontSize: "14px",
                            })}
                            variant={"caption"}
                          >
                            {errors.password}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      "@media screen and (max-width: 800px)": {
                        marginTop: "32px",
                      },
                      marginTop: "96px",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      bloomColor="bloom"
                      fullyColored
                      icon={<Login />}
                      label={t("common:login")}
                      style={{
                        borderRadius: "50px",
                        fontSize: "20px",
                        fontWeight: "bold",
                        height: "unset",
                        padding: "8px 32px",
                      }}
                      type="submit"
                    />
                  </Box>
                </form>
              );
            }}
          </Formik>
        </Box>
      </StepMainContent>
      <Footer />
    </>
  );
};

export default LoginStep;
