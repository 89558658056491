/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import { I18Namespaces } from "../language/I18Namespaces";
import { RouteNames } from "../routes/routeNames";

export default function PaymentFailurePage() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { t: translate } = useTranslation<I18Namespaces>(["ui", "payments"]);

  useEffect(() => {
    // Someone arrived here without a uuid in the link.
    if (!uuid) navigate(RouteNames.Home.path);
  }, []);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullCopyright
        headline={translate("ui:something.went.wrong", "Er ging iets mis")}
        labelNext={translate("ui:retry", "Probeer het nog eens")}
        onClickNext={() =>
          navigate(
            RouteNames.Payment.Consultation.Pay.path.replace(
              ":uuid",
              uuid || "",
            ),
          )
        }
      >
        <Box>
          <Typography variant="h2">
            {translate(
              "payments:payment.failed.message",
              "Er ging iets mis met je betaling",
            )}
          </Typography>
          <Box mt={5}>
            <Typography>
              {translate(
                "payments:failure.text",
                "Er ging iets mis tijdens het verwerken van je betaling. Druk op de knop hieronder om het nog eens te proberen.",
              )}
            </Typography>
          </Box>
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
