import { TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "../../../ui/form/FormLabel";
import Button from "../../../ui/buttons/Button";
import { UserRoles } from "../../../../data-model/types/User";

const PREFIX = "CreateUserModal";

const classes = {
  formControl: `${PREFIX}-formControl`,
  paper: `${PREFIX}-paper`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.primary.main}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    position: "absolute",
    width: 500,
  },

  [`& .${classes.formControl}`]: {
    flex: 1,
    margin: theme.spacing(1),
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    left: `${left}%`,
    top: `${top}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};

const ROLE_OPTIONS = [
  {
    label: "Administrator",
    value: UserRoles.ADMIN,
  },
  {
    label: "Professional",
    value: UserRoles.PROFESSIONAL,
  },
];

const CreateUserModal = ({
  isOpen,
  onClose,
  formState,
  isCreatingUser,
  onCreatePsy,
  onCancel,
}) => {
  const { getInputProps, getErrorMessages } = formState;

  return (
    <StyledModal onClose={onClose} open={isOpen}>
      <Box className={classes.paper} style={getModalStyle()}>
        <Box display="flex" mt={2}>
          <FormControl className={classes.formControl} variant="standard">
            <TextField
              label="Voornaam"
              variant="outlined"
              {...getInputProps("firstName")}
            />
            <Typography color="error">
              {getErrorMessages("firstName")}
            </Typography>
          </FormControl>
          <FormControl className={classes.formControl} variant="standard">
            <TextField
              label="Familienaam"
              variant="outlined"
              {...getInputProps("lastName")}
            />
            <Typography color="error">
              {getErrorMessages("lastName")}
            </Typography>
          </FormControl>
        </Box>
        <Box display="flex">
          <FormControl className={classes.formControl} variant="standard">
            <TextField
              label="Email"
              variant="standard"
              {...getInputProps("email")}
            />
            <Typography color="error">{getErrorMessages("email")}</Typography>
          </FormControl>
          <FormControl className={classes.formControl} variant="standard">
            <TextField
              label="Telefoon"
              variant="standard"
              {...getInputProps("phone")}
            />
            <Typography color="error">{getErrorMessages("phone")}</Typography>
          </FormControl>
        </Box>
        <Box display="flex" mt={2}>
          <FormControl className={classes.formControl} variant="standard">
            <InputLabel>Rol</InputLabel>
            <Select variant="standard" {...getInputProps("roleType")}>
              {ROLE_OPTIONS.map((option) => {
                const { value, label } = option;

                return <MenuItem value={value}>{label}</MenuItem>;
              })}
            </Select>
            <Typography color="error">
              {getErrorMessages("roleType")}
            </Typography>
          </FormControl>
        </Box>
        <Box display="flex" justifyContent="space-between" mt={6}>
          <Button
            disabled={isCreatingUser}
            label="Annuleer"
            onClick={onCancel}
            variant="outlined"
          />
          <Button
            disabled={isCreatingUser}
            label="Sla op"
            onClick={onCreatePsy}
            variant="outlined"
          />
        </Box>
      </Box>
    </StyledModal>
  );
};

export default CreateUserModal;
