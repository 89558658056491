import { useState } from "react";
import { useTranslation } from "react-i18next";
import useFormState from "../../../hooks/useFormState";
import createFormValidation from "../../../../utils/forms/createFormValidation";
import { isValue } from "../../../../utils/validation";
import FirstNameDataType from "../../../../data-model/types/profile/FirstName.ts";
import LastNameDataType from "../../../../data-model/types/profile/LastName.ts";
import PhoneDataType from "../../../../data-model/types/profile/Phone.ts";
import Email from "../../../../data-model/types/profile/Email";

export default function useCreatePsyForm(initialValues = {}, options) {
  const INITIAL_VALUES = {
    ...Email.getInitialValue(),
    ...FirstNameDataType.getInitialValue(),
    ...LastNameDataType.getInitialValue(),
    ...PhoneDataType.getInitialValue(),
    roleType: "",
  };

  const [preloadedState, setPreloadedState] = useState(INITIAL_VALUES);
  const { t: translate } = useTranslation(["validation"]);

  const validation = createFormValidation([
    ...Email.getFormValidation(translate),
    ...FirstNameDataType.getFormValidation(translate),
    ...LastNameDataType.getFormValidation(translate),
    ...PhoneDataType.getFormValidation(translate),
    {
      message: "Selecteer een rol.",
      path: "roleType",
      validate: (roleType) => isValue(roleType),
    },
  ]);

  return {
    formState: useFormState(
      {
        ...initialValues,
        ...preloadedState,
      },
      {
        validation,
        ...options,
      },
    ),
    setPreloadedState,
  };
}
