/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRosterState } from "amazon-chime-sdk-component-library-react";
import useLazyGetLastCashTransactionByConsultationId from "../../../api/cashTransactions/hooks/useLazyGetLastCashTransactionByConsultationId";
import useSubscribeToPaymentUpdates from "../../../utils/pusher/channels/hooks/useSubscribeToPaymentUpdates";
import LoadingPage from "../../layout/LoadingPage";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { RouteNames } from "../../routes/routeNames";
import ChimeVideoContainer from "../../ui/call/ChimeVideoContainer";
import ChimeStandardButtonBar from "../../ui/call/components/chimeButtonComponents/ChimeStandardButtonBar";
import PopUpMessage from "../../ui/call/components/popUp/PopUpMessage";
import { ICallContext } from "../../ui/call/context/Call";
import CallContext from "../../ui/call/context/CallContext";
import ChimeStandardOverlaySidePane from "../../ui/call/components/sidePaneComponents/ChimeStandardOverlaySidePane";
import StandardMenuSidePane from "../../ui/call/components/sidePaneComponents/StandardMenuPane";
import { ChimeVideoCall } from "../../ui/call/ChimeVideoCall.types";
import { Consultation } from "../../../data-model/types/consultation/Consultation";

function openPaymentPage(consultation: Consultation) {
  const hostname = window.location.hostname;
  const isLocalhost = hostname.includes("localhost");
  const baseURL = `${isLocalhost ? "http" : "https"}://${
    isLocalhost ? `${hostname}:3001` : hostname
  }`;

  const link =
    baseURL +
    RouteNames.Payment.Consultation.Pay.path.replace(
      ":uuid",
      consultation.getUUID(),
    );

  const win = window.open(link, "_blank");

  win?.focus();
}

// Use this interface if we ever add more props to this component
//export interface IHumanCallContainer extends ICallContainerBase {}
export default function HumanCallContainer() {
  const { paneToPresent, consultation, callInfo } =
    useContext<ICallContext>(CallContext);

  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Human]);

  const { cashTransaction, refetch, getCashTransaction } =
    useLazyGetLastCashTransactionByConsultationId();

  const { addOnReceivedFunction } = useSubscribeToPaymentUpdates(consultation);

  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  useEffect(() => {
    if (addOnReceivedFunction && refetch !== undefined) {
      addOnReceivedFunction(() => refetch());
    }
  }, [addOnReceivedFunction, refetch]);

  useEffect(() => {
    if (consultation) {
      getCashTransaction(consultation.getID());
    }
  }, [consultation]);

  if (!consultation) return <LoadingPage full />;

  return (
    <ChimeVideoContainer
      cashTransaction={cashTransaction}
      redirectToPayment={() => openPaymentPage(consultation)}
    >
      <ChimeStandardOverlaySidePane>
        {paneToPresent === ChimeVideoCall.SidePaneProfessional.menuPane && (
          <StandardMenuSidePane />
        )}
      </ChimeStandardOverlaySidePane>
      {callInfo && callInfo.isCallStarted && attendees.length < 2 && (
        <PopUpMessage contentText={t("human:videocall.popup.patience")} />
      )}
      <ChimeStandardButtonBar />
    </ChimeVideoContainer>
  );
}
