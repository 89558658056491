export enum BloomUpNamespacesEnum {
  ApiErrors = "api_errors",
  Client = "client",
  Common = "common",
  Dashboards = "dashboards",
  Errors = "errors",
  Glossary = "glossary",
  HRDashboards = "hr_dashboards",
  Human = "human",
  Match = "match",
  Organization = "organization",
  Partners = "partners",
  Payments = "payments",
  Professional = "professional",
  Profile = "profile",
  QualityQuestions = "quality_questions",
  Topics = "topics",
  Ui = "ui",
  Validation = "validation",
}

export type BloomUpNamespaces =
  | "validation"
  | "client"
  | "common"
  | "glossary"
  | "human"
  | "professional"
  | "ui"
  | "partners"
  | "payments"
  | "errors"
  | "quality_questions"
  | "dashboards"
  | "hr_dashboards"
  | "organization"
  | "match"
  | "api_errors"
  | "profile"
  | "topics";

export type I18Namespaces = Array<BloomUpNamespaces> | BloomUpNamespaces;
