import { LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

import {
  I18NexusLanguages,
  languageToCaptionMap,
} from "../language/languagesUtil";

const PREFIX = "LanguageSelector";

const classes = {
  languageSelectorSection: `${PREFIX}-languageSelectorSection`,
  text: `${PREFIX}-text`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.languageSelectorSection}`]: {
    display: "table",
    margin: "0 auto 15px auto",
    position: "relative",
    textAlign: "center",
  },

  [`& .${classes.text}`]: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
      lineHeight: "14px",
    },
  },
}));

interface LanguageSelectorProps {
  children?: React.ReactNode;
}

export default function LanguageSelector({ children }: LanguageSelectorProps) {
  const { i18n, ready } = useTranslation();

  if (!ready) return <LinearProgress />;

  return (
    <Root>
      <section className={classes.languageSelectorSection}>
        <Breadcrumbs
          aria-label="breadcrumb"
          className={classes.text}
          separator="|"
        >
          {Object.keys(I18NexusLanguages).map((key) => {
            const lang = I18NexusLanguages[key];

            return (
              <Link
                component="button"
                key={languageToCaptionMap.get(lang)}
                onClick={() => i18n.changeLanguage(lang)}
                underline={i18n.language === lang ? "always" : "none"}
              >
                {languageToCaptionMap.get(lang)}
              </Link>
            );
          })}
        </Breadcrumbs>
      </section>
      {children}
    </Root>
  );
}
