import React from "react";
import { useTranslation } from "react-i18next";

import RightSection from "../../layout/RightSection";
import Professional from "../../../data-model/types/professional/Professional";
import { I18Namespaces } from "../../language/I18Namespaces";
import ProfessionalSummary from "./ProfessionalSummary";

interface IProfessionalDetail {
  handleClick: (id: number | undefined) => void;
  professional: Professional;
}

export default function ProfessionalDetail({
  professional,
  handleClick,
}: IProfessionalDetail) {
  const { t: translate } = useTranslation<I18Namespaces>(["client", "common"]);

  return (
    <RightSection
      handleClose={() => handleClick(undefined)}
      title={translate("client:pros.pro.title", "Profiel")}
    >
      <ProfessionalSummary professional={professional} />
    </RightSection>
  );
}
