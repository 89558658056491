/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import SingleSelect from "../form/select/SingleSelect";
import SelectionDataType from "../../../data-model/SelectionDataType";
import ValueWithLabel from "../text/ValueWithLabel";
import { isSelectObject } from "../form/select/BaseSimpleSelect";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { IEASelectableProps } from "./InlineEditableMultiSelect";
import InlineEditableControls from "./common/InlineEditableControls";

export default function InlineEditableSingleSelect<
  T extends SelectionDataType<any, any, any>,
>({
  label,
  placeholderText,
  dataObject,
  formState,
  translateFunction,
  onSave,
  onChange,
  onDiscard,
  selectTestId,
  editBtnTestId,
  labelTestId,
  saveBtnTestId,
  cancelBtnTestId,
}: IEASelectableProps<T>) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Ui,
  ]);

  const path: string = dataObject.getType().getPath();

  const _translate = useMemo(
    () => translateFunction ?? translate,
    [translate, translateFunction],
  );

  const initialValue = useMemo(() => {
    return dataObject.getValue();
  }, []);

  const selectedValue = () => {
    const selectedOptions = dataObject.getSelectedOptions(_translate);

    return selectedOptions[0].label;
  };

  const formStateInputProps = formState.getInputProps(path);

  return (
    <Box>
      <InlineEditableControls
        cancelBtnTestId={cancelBtnTestId}
        edit={
          <SingleSelect<any>
            options={dataObject.getSelectOptions(_translate)}
            placeholder={placeholderText}
            {...formStateInputProps}
            onChange={(selected) => {
              if (onChange) onChange(selected);

              const newValue = isSelectObject(selected)
                ? selected.value
                : selected;

              dataObject.setValue(newValue);

              formStateInputProps.onChange(selected);
            }}
          />
        }
        editBtnTestId={editBtnTestId}
        editable={formState !== undefined}
        label={label || translate("common:-", "-")}
        labelTestId={labelTestId}
        onDiscard={() => {
          if (initialValue) {
            dataObject.setValue(initialValue);
            formState.setValue(path, initialValue);
          }

          if (onDiscard) onDiscard(initialValue);

          return true;
        }}
        onSave={async () => {
          if (formState.validate(path)) {
            if (onSave) onSave();

            return true;
          } else {
            return false;
          }
        }}
        read={
          <ValueWithLabel label="" large={false}>
            {selectedValue()}
          </ValueWithLabel>
        }
        saveBtnTestId={saveBtnTestId}
        selectTestId={selectTestId}
      />
    </Box>
  );
}
