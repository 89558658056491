import {
  ChangeEvent,
  CSSProperties,
  useCallback,
  useEffect,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

const PREFIX = "CheckBox";

const classes = {
  checkbox: `${PREFIX}-checkbox`,
  checkboxForm: `${PREFIX}-checkboxForm`,
  helperStyle: `${PREFIX}-helperStyle`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.checkbox}`]: {
    color: theme.palette.gray.main,
    [theme.breakpoints.down(400)]: {
      marginTop: 0,
    },
  },

  [`&.${classes.checkboxForm}`]: {
    "& .MuiFormControlLabel-root": {
      "& P": {
        textAlign: "left",
      },
    },
  },
  [`& .${classes.helperStyle}`]: {
    ...theme.controls.validation?.error,
  },
}));

interface ICheckBox extends CheckboxProps {
  defaultChecked?: boolean;
  errorText?: string;
  handleNativeChange?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  hasError?: boolean;
  helperStyle?: CSSProperties;
  label?: React.ReactNode | string;
  tooltipTitle?: string;
}

const CheckBox = ({
  name,
  checked,
  defaultChecked,
  onChange,
  handleNativeChange,
  label,
  style,
  tooltipTitle,
  hasError,
  errorText,
  helperStyle,
}: ICheckBox) => {
  const [_checked, setChecked] = useState<boolean>(defaultChecked ?? false);
  const onChangeEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: boolean) => {
      event.stopPropagation();
      setChecked(value);

      if (onChange) onChange(event, value);

      if (handleNativeChange) handleNativeChange(event, value);
    },
    [onChange, handleNativeChange],
  );

  useEffect(() => {
    if (defaultChecked !== undefined) setChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <StyledFormControl
      className={classes.checkboxForm}
      error={hasError}
      required
      variant="standard"
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={checked ?? _checked}
            checkedIcon={<CheckBoxIcon />}
            className={classes.checkbox}
            icon={<CheckBoxOutlineBlankIcon />}
            name={name}
            onChange={onChangeEvent}
          />
        }
        label={
          tooltipTitle ? (
            <Tooltip placement="right" title={tooltipTitle}>
              <Typography
                align="center"
                onClick={(e) => e.stopPropagation()}
                variant="body2"
              >
                {label}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              align="center"
              onClick={(e) => e.stopPropagation()}
              variant="body2"
            >
              {label}
            </Typography>
          )
        }
        style={style}
      />
      {hasError && (
        <FormHelperText
          className={classes.helperStyle}
          error={hasError}
          required
          style={helperStyle}
        >
          {errorText}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default CheckBox;
