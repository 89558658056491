import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";
import Avatar from "react-avatar-edit";

const PREFIX = "AvatarUpload";

const classes = {
  formHelper: `${PREFIX}-formHelper`,
  main: `${PREFIX}-main`,
  removeLink: `${PREFIX}-removeLink`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.main}`]: {
    "& LABEL": {
      color: `${theme.palette.black.main} !important`,
      fontFamily: "inherit !important",
      fontSize: `${theme.typography.sizes.normal}px !important`,
      fontWeight: "normal !important",
      lineHeight: "21px !important",
      margin: "50px 10px 0 !important",
      padding: "0px 10px !important",
    },
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",

    width: "100%",
  },

  [`& .${classes.formHelper}`]: {
    ...theme.controls.validation.error,
  },

  [`& .${classes.removeLink}`]: {
    ...theme.controls.validation.error,
  },
}));

function dataURLtoFile(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export default function AvatarUpload({
  hasError,
  errorText,
  onChange,
  value,
  label,
  avatarUploadTestId = "",
}) {
  const theme = useTheme();

  let originalFile = value instanceof File ? value.original : undefined;

  const onCrop = (preview) => {
    const file = dataURLtoFile(preview, "avatar");

    const fileWithPreview = Object.defineProperty(file, "preview", {
      value: URL.createObjectURL(file),
      writable: false,
    });

    file.original = originalFile;

    onChange(fileWithPreview);
  };

  const onFileLoad = (file) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      originalFile = reader.result;
    };
  };

  return (
    <StyledBox className={classes.main} id={avatarUploadTestId}>
      <Avatar
        closeIconColor={theme.palette.bloom.light}
        cropColor={theme.palette.bloom.light}
        height={200}
        imageWidth={800}
        label={label}
        lineWidth={6}
        mimeTypes="image/jpeg,image/gif,image/png"
        onCrop={onCrop}
        onFileLoad={onFileLoad}
        shadingColor={theme.palette.bloom.dark}
        shadingOpacity={0.8}
        src={value instanceof File ? value.original : ""}
        width={200}
      />
      {hasError && (
        <FormHelperText className={classes.formHelper}>
          {errorText}
        </FormHelperText>
      )}
    </StyledBox>
  );
}
