/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import LoadingPage from "../../../layout/LoadingPage";
import UnauthorizedPage from "../../../layout/UnauthorizedPage";
import useFetchProfessionals from "../../../../api/professionals/hooks/useFetchProfessionals";
import { IAuthContext } from "../../../providers/auth/auth";
import AuthContext from "../../../providers/auth/AuthContext";
import { UserRoles } from "../../../../data-model/types/User";
import { APIProfessionals } from "../../../../api/professionals/professionals";
import CreateProfessionalModalWithState from "./CreateProfessionalModalWithState";
import AdminProfessionalsOverview from "./AdminProfessionalsOverview";

const AdminProfessionalPageWithState = () => {
  const [search, setSearch] = useState("");
  const { fetchProfessionals, loading, professionals } = useFetchProfessionals({
    forAdmin: true,
  });

  const { currentUser } = useContext<IAuthContext>(AuthContext);

  const [createProfessionalDialogIsOpen, setCreateProfessionalDialogIsOpen] =
    useState<boolean>(false);

  const fetchingInput: APIProfessionals.Fetch.All.Input = {
    adminsIncluded: true,
    adminsOnly: false,
    verifiedIncluded: true,
    verifiedOnly: false,
  };

  useEffect(() => {
    fetchProfessionals(fetchingInput);
  }, []);

  if (currentUser?.getRole() !== UserRoles.ADMIN) return <UnauthorizedPage />;

  if (loading) return <LoadingPage />;

  return (
    <>
      <AdminProfessionalsOverview
        onCreateProfessional={() => setCreateProfessionalDialogIsOpen(true)}
        onDeleteProfessionalComplete={() => fetchProfessionals(fetchingInput)}
        professionals={professionals}
        refetchProfessionals={() => fetchProfessionals(fetchingInput)}
        search={search}
        setSearch={setSearch}
      />
      {createProfessionalDialogIsOpen && (
        <CreateProfessionalModalWithState
          isOpen={createProfessionalDialogIsOpen}
          onClose={() => setCreateProfessionalDialogIsOpen(false)}
          onSubmit={() => {
            fetchProfessionals(fetchingInput);
            setCreateProfessionalDialogIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AdminProfessionalPageWithState;
