import { graphql } from "../__generated__";

export const QUERY_GET_LAST_CASH_TRANSACTION_BY_CONSULTATION_ID = graphql(`
  query getCashLastTransactionByConsultationId($consultationId: Int!) {
    lastCashTransactionByConsultationId(consultationId: $consultationId) {
      ...CashTransaction
    }
  }
`);

export const QUERY_GET_CASH_TRANSACTIONS_BY_CONSULTATION_ID = graphql(`
  query getCashTransactionsByConsultationId($consultationId: Int!) {
    cashTransactionsByConsultationId(consultationId: $consultationId) {
      ...CashTransaction
    }
  }
`);
