import { IconButton, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Edit as EditIcon,
  Clear as ClearIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Button from "../../../ui/buttons/Button";
import Table from "../../../ui/Table";
import { DateFormat } from "../../../../data-model/Date";
import Professional from "../../../../data-model/types/professional/Professional";
const PREFIX = "ProfessionalTableRow";

const classes = {
  row: `${PREFIX}-row`,
};

const StyledTableRow = styled(Table.Row)(() => ({
  [`& .${classes.row}`]: {
    "& TD": {
      textOverflow: "ellipsis",
      wordBreak: "break-all",
    },
  },
}));

interface IProfessionalTableRow {
  isRemoving: boolean;
  isVerifying: boolean;
  onCancelDelete: () => void;
  onDeleteRow: () => void;
  onEditRow: () => void;
  onSubmitDelete: () => void;
  onVerifyProfessional: (id: number) => void;
  professional: Professional;
}

export default function ProfessionalTableRow({
  isRemoving,
  isVerifying,
  onEditRow,
  onDeleteRow,
  onVerifyProfessional: onVerifyPsy,
  onSubmitDelete,
  onCancelDelete,
  professional,
}: IProfessionalTableRow) {
  return (
    <StyledTableRow className={classes.row}>
      <Table.Cell>{professional.getFirstName().prettyPrint()}</Table.Cell>
      <Table.Cell>{professional.getLastName().prettyPrint()}</Table.Cell>
      <Table.Cell>{professional.getRole()}</Table.Cell>
      <Table.Cell>{professional.getEmail().prettyPrint()}</Table.Cell>
      <Table.Cell>{professional.getPhone().prettyPrint()}</Table.Cell>
      <Table.Cell>
        {professional.getLicenseFiles()
          ? professional.getLicenseFiles().map((license) => (
              <IconButton
                key={license.id}
                onClick={() => window.open(license.url, "_blank")}
                size="large"
              >
                <Avatar src="">PDF</Avatar>
              </IconButton>
            ))
          : null}
      </Table.Cell>
      <Table.Cell style={{ wordBreak: "keep-all" }}>
        {professional.verifiedAt() === null ? (
          <Button
            disabled={isVerifying}
            label="Verifieer"
            onClick={() => onVerifyPsy(professional.getID())}
          />
        ) : (
          professional.verifiedAt()?.format(DateFormat.SHORT)
        )}
      </Table.Cell>
      <Table.Cell>
        {isRemoving ? (
          <>
            <Button label="Confirm delete" onClick={onSubmitDelete} />
            <IconButton onClick={onCancelDelete} size="large">
              <ClearIcon />
            </IconButton>
          </>
        ) : (
          <>
            <IconButton onClick={onEditRow} size="large">
              <EditIcon />
            </IconButton>
            <IconButton onClick={onDeleteRow} size="large">
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </Table.Cell>
    </StyledTableRow>
  );
}
