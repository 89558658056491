import { graphql } from "../__generated__";

export const QUERY_CLIENTS_FOR_PROFESSIONAL = graphql(`
  query clientsForProfessional {
    clientsForProfessional {
      edges {
        node {
          ...ClientForProfessional
          ...RestrictedClientForProfessional
        }
      }
    }
  }
`);

export const QUERY_CHECK_IF_HUMAN_IS_CLIENT = graphql(`
  query checkIfHumanIsAClient($humanId: Int!) {
    checkIfHumanIsAClient(humanId: $humanId)
  }
`);
