import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { OrganizationFragment } from "../../../../api/organizations/fragments";
import { getFragmentData, graphql } from "../../../../api/__generated__";

const queryLandingPage = graphql(`
  query OrganisationLandingPage($emailOrCode: String!) {
    organizationByEmailOrCode(emailOrCode: $emailOrCode) {
      ...Organization
    }
  }
`);

const useOrganizationForLandingPage = ({
  emailOrCode,
}: {
  emailOrCode?: string | null;
}) => {
  const navigate = useNavigate();

  const { data } = useQuery(queryLandingPage, {
    onError: () => {
      navigate("/employee/verify", { replace: true });
    },
    variables: {
      // variables cannot be null, so are replaced with empty strings that will fail to fetch
      emailOrCode: emailOrCode ?? "",
    },
  });

  const organization = data?.organizationByEmailOrCode;

  return getFragmentData(OrganizationFragment, organization);
};

export default useOrganizationForLandingPage;
