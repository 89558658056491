/* eslint-disable react-hooks/exhaustive-deps */
import { Channel } from "pusher-js";
import { useCallback, useEffect, useState } from "react";
import Consultation from "../../../../data-model/types/consultation/Consultation";
import { constructPaymentChannelName } from "../channels";
import { PusherEvents } from "../pusherEvents";
import usePusher from "./usePusher";

type CallbackFunction = (data: unknown) => any;

export default function useSubscribeToPaymentUpdates(
  consultation: Consultation | null,
  onReceived?: CallbackFunction,
) {
  const { isReady, pusher } = usePusher();
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [channel, setChannel] = useState<Channel | undefined>();
  // Watch out. This should be a function that returns a function because of:
  // type SetStateAction<S> = S | ((prevState: S) => S);
  // See: https://dev.to/sarioglu/how-to-store-a-function-using-react-usestate-4ffh
  const [onReceivedFunction, setOnReceivedFunction] = useState<
    CallbackFunction | undefined
  >(onReceived ? () => (data) => onReceived(data) : undefined);

  const addOnReceivedFunction = useCallback((onReceived: CallbackFunction) => {
    if (onReceived) {
      // Watch out. This should be a function that returns a function because of:
      // type SetStateAction<S> = S | ((prevState: S) => S);
      setOnReceivedFunction(() => (data) => onReceived(data));
    }
  }, []);

  useEffect(() => {
    //Clean up subscriptions.
    return () => {
      if (channel) channel.unsubscribe();
    };
  }, [channel]);

  const callback = useCallback(
    (data?) => {
      if (onReceivedFunction) {
        onReceivedFunction(data);
      }
    },
    [onReceivedFunction],
  );

  useEffect(() => {
    if (consultation) {
      let newChannel: Channel | undefined = undefined;

      if (isReady && pusher && !isSubscribed) {
        newChannel = pusher.subscribe(
          constructPaymentChannelName(consultation),
        );
        newChannel
          .bind(PusherEvents.CUSTOM_PAYMENT_STATUS_CHANGED, (data) => {
            callback(data);
          })
          .bind(PusherEvents.SUBSCRIPTION_ERROR, () => setIsSubscribed(false))
          .bind(PusherEvents.SUBSCRIPTION_SUCCEEDED, () =>
            setIsSubscribed(true),
          );

        setChannel(newChannel);
      }
    }
  }, [isReady, pusher, isSubscribed, callback]);

  return {
    addOnReceivedFunction,
  };
}
