import type { TFunction } from "i18next";
import { I18Namespaces } from "../components/language/I18Namespaces";
import { ISelectObject } from "../components/ui/form/select/BaseSimpleSelect";
import { PossibleFormStatePaths } from "./dataTypePaths";

export type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>;

export default abstract class DataType<
  T,
  PathName extends string,
  FormStateValueType = T,
> {
  protected static path = "";
  protected static defaultValue: any = "";

  protected value: T;
  protected type = DataType;

  /**
   * Get an object that can be put in the formState values for this data type.
   * This method should be overridden in each DataType implementation
   */
  abstract getAsFormStateValue(
    translate?: TFunction<I18Namespaces>,
  ): PartialRecord<
    PossibleFormStatePaths,
    | FormStateValueType
    | ISelectObject<FormStateValueType>
    | ISelectObject<FormStateValueType>[]
  >;

  constructor(value?: T, fromGQL = false) {
    this.value = DataType.defaultValue;

    if (value) {
      this.setValue(value, fromGQL);
    } else {
      this.setValue(this.getType().getDefault());
    }
  }

  /**
   * Transforms data retrieved from GraphQL into
   * client-side data type.
   */
  protected fromGraphQL(value: any): any {
    return value;
  }

  /**
   * Transforms data and makes it ready for GraphQL.
   */
  protected forGraphQL(value: any): any {
    return value;
  }

  /**
   * What is the default value of this data type?
   */
  static getDefault(): any {
    return this.defaultValue;
  }

  /**
   * What is the reference name of this data type?
   * This is used in form validation and GraphQL
   */
  static getPath(): string {
    return this.path;
  }

  /**
   * The initial value object that can be destructured
   * in the initial values of formStates.
   */
  public static getInitialValue(): Record<string, unknown> {
    return {
      [this.path]: this.defaultValue,
    };
  }

  /**
   * Set the value of this data type.
   */
  public setValue(
    value: T,
    fromGQL = false,
  ): DataType<T, PathName, FormStateValueType> {
    // We used to return here without setting the value when the value was undefined or null. Why?
    if (value === undefined || value === null) {
      this.value = value;

      return this;
    }

    this.value = fromGQL ? this.fromGraphQL(value) : value;

    return this;
  }

  /**
   * Get the value of this data type instance.
   */
  public getValue(forGQL = false): T {
    return forGQL ? this.forGraphQL(this.value) : this.value;
  }

  /**
   * Get the humanreadible string for this data type's value.
   */
  /*eslint-disable */
  public prettyPrint(translate?: TFunction): string {
    return `${this.value}`;
  }

  /**
   * Get the data type object itself.
   * Useful to call static members without import after passing it around.
   */
  public getType(): any {
    return this.type;
  }
}
