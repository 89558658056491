import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import { PropsWithChildren, forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import tinycolor from "tinycolor2";
import { SingleWave } from "wave-box";
import { NavLink, NavLinkProps, useSearchParams } from "react-router-dom";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import LanguageDropdown from "../../../ui/LanguageDropdown";
import {
  I18NexusLanguages,
  languageToCaptionMap,
} from "../../../language/languagesUtil";
import { ReactComponent as LoginCircle } from "./login-circle.svg";

const MenuItemLink = styled(NavLink)(({ theme }) => ({
  "&:hover": {
    "& .menuItemUnderline": {
      background: theme.palette.green.main,
    },
  },
  color: theme.palette.black.main,
  cursor: "pointer",
  padding: "0 1rem",
  [theme.breakpoints.down(750)]: {
    display: "none",
  },
  position: "relative",
  textDecoration: "none",
}));

interface NavigationBarProps {
  baseUrl: string;
  loginUrl?: string;
  navigation: { active?: boolean; label: string; path: string }[];
  organizationColor: string;
  organizationLogoUrl?: string | null;
  organizationName?: string | null;
}

const NavigationBar = ({
  navigation,
  organizationColor,
  organizationLogoUrl,
  baseUrl,
  loginUrl,
  organizationName,
}: NavigationBarProps) => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  const availableLanguages = Object.entries(I18NexusLanguages).map(
    ([_, value]) => {
      return { label: languageToCaptionMap.get(value), value: value };
    },
  );

  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(false);
  const [sideMenuLanguageIsOpen, setSideMenuLanguageIsOpen] = useState(false);

  const textColor = tinycolor
    .mostReadable(organizationColor, [
      theme.palette.black.main,
      theme.palette.white.main,
    ])
    .toHexString();

  const bloomUpLogoUrl =
    "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/Logo_d2b040aaa2.svg";

  const handleSideMenuLanguageClick = () => {
    setSideMenuLanguageIsOpen(!sideMenuLanguageIsOpen);
  };

  const Link = forwardRef<HTMLAnchorElement, NavLinkProps>(
    function Link(itemProps, ref) {
      return <NavLink ref={ref} {...itemProps} role={undefined} />;
    },
  );

  function ListItemLink({ to, children }: PropsWithChildren<{ to: string }>) {
    return (
      <ListItemButton component={Link} to={to}>
        {children}
      </ListItemButton>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: organizationColor,
          color: textColor,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            height: "50px",
            justifyContent: "space-between",
            maxWidth: "1400px",
            width: "100%",
          }}
        >
          {organizationLogoUrl ? (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                padding: "0 1rem",
              }}
            >
              <Box
                alt="logo"
                component="img"
                src={organizationLogoUrl}
                sx={{ height: "auto", maxHeight: "25px", maxWidth: "100%" }}
              />
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  padding: "0 1rem",
                }}
              >
                {t("organization:landing.navbar.works.with", "werkt samen met")}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                padding: "0 1rem",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Mulish",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  padding: "0 1rem",
                }}
              >
                {organizationName +
                  " " +
                  t("organization:landing.navbar.works.with")}
              </Typography>
            </Box>
          )}
          <Box
            sx={(theme) => ({
              alignItems: "center",
              color: "white",
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
              padding: "0 1rem",
              [theme.breakpoints.down(750)]: {
                display: "none",
              },
            })}
          >
            <LanguageDropdown textColor={textColor} />
          </Box>
        </Box>
      </Box>
      <SingleWave reverseWave waveColor={organizationColor} waveHeight="50px" />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          marginTop: "-12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "1400px",
            width: "100%",
          }}
        >
          <NavLink to={`${baseUrl}/?${searchParams.toString()}`}>
            <Box
              alt="logo"
              component="img"
              src={bloomUpLogoUrl}
              sx={{
                cursor: "pointer",
                height: "auto",
                maxWidth: "100%",
                minWidth: "110px",
                paddingLeft: "1rem",
                paddingRight: "3rem",
                paddingTop: "0.5rem",
              }}
            />
          </NavLink>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {navigation.map((nav) => (
                <MenuItemLink key={nav.label} to={nav.path}>
                  <Typography variant="h4">{nav.label}</Typography>
                  <Box
                    className="menuItemUnderline"
                    sx={(theme) => ({
                      backgroundColor: nav.active
                        ? theme.palette.green.main
                        : undefined,
                      borderRadius: "10px",
                      height: "4px",
                      position: "relative",
                      top: "5px",
                      transform: "matrix(1, -0.03, 0.09, 1, 0, 0)",
                    })}
                  />
                </MenuItemLink>
              ))}
            </Box>
            {loginUrl && (
              <Box
                sx={{
                  ":hover .login-circle": {
                    color: theme.palette.green.main,
                  },
                  paddingRight: "1.5rem",
                  position: "relative",
                }}
              >
                <MenuItemLink to={loginUrl}>
                  <Box
                    className="login-circle"
                    sx={(theme) => ({
                      color: theme.palette.bloom.main,
                      lineHeight: 0,
                      position: "absolute",
                      [theme.breakpoints.down(750)]: {
                        display: "none",
                      },
                    })}
                  >
                    <LoginCircle />
                  </Box>
                  <Typography sx={{ padding: "0 27px" }} variant="h4">
                    {t("organization:landing.navbar.login")}
                  </Typography>
                </MenuItemLink>
              </Box>
            )}
          </Box>
          <Box
            onClick={() => setSideMenuIsOpen(true)}
            sx={(theme) => ({
              display: "none",
              [theme.breakpoints.down(750)]: {
                alignItems: "center",
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
              },
            })}
          >
            <Typography sx={{ marginRight: "0.5rem" }} variant="h4">
              {t("organization:landing.navbar.menu", "Menu")}
            </Typography>
            <MenuIcon sx={{ marginRight: "1rem" }} />
          </Box>
          <Drawer
            anchor={"right"}
            onClose={() => setSideMenuIsOpen(false)}
            open={sideMenuIsOpen}
          >
            <List>
              <ListItemButton>
                <ListItemIcon onClick={() => setSideMenuIsOpen(false)}>
                  <HighlightOffIcon
                    sx={(theme) => ({
                      ...theme.typography.h4,
                      color: theme.palette.black.main,
                    })}
                  />
                </ListItemIcon>
              </ListItemButton>
              {navigation.map((nav) => (
                <ListItemLink key={nav.label} to={nav.path}>
                  <ListItemText>
                    <Typography variant="h4">{nav.label}</Typography>
                  </ListItemText>
                </ListItemLink>
              ))}
              <ListItemLink to="/login">
                <ListItemText>
                  <Typography sx={{ paddingRight: "1.5rem" }} variant="h4">
                    {t("organization:landing.navbar.login")}
                  </Typography>
                </ListItemText>
              </ListItemLink>
              <Divider />
              <ListItemButton onClick={handleSideMenuLanguageClick}>
                <ListItemIcon>
                  <LanguageIcon sx={{ color: theme.palette.black.main }} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="h4">
                    {t("organization:landing.navbar.language", "Taal")}
                  </Typography>
                </ListItemText>
                {sideMenuLanguageIsOpen ? (
                  <ExpandLess sx={{ color: theme.palette.black.main }} />
                ) : (
                  <ExpandMore sx={{ color: theme.palette.black.main }} />
                )}
              </ListItemButton>
              <Collapse
                in={sideMenuLanguageIsOpen}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {availableLanguages.map((language) => (
                    <ListItemButton
                      key={language.label}
                      sx={{ paddingLeft: "1.5rem" }}
                    >
                      <ListItemText
                        onClick={() => {
                          i18n.changeLanguage(language.value);
                        }}
                      >
                        <Typography variant="h4">{language.label}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </List>
          </Drawer>
        </Box>
      </Box>
    </Box>
  );
};

export default NavigationBar;
