import { styled } from "@mui/material";

const StyledInput = styled("input")(() => ({
  backgroundPosition: "24px center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "19px 19px",
  border: "2px solid #797272",
  borderRadius: "60px",
  fontFamily: "mulish",
  fontSize: "16px",
  opacity: 0.8,
  padding: "12px 24px 12px 48px",
  width: "100%",
}));

export default StyledInput;
