import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import createFormValidation, {
  IFormValidation,
} from "../../../utils/forms/createFormValidation";
import useFormState from "../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import {
  DatabaseLanguageEquivalents,
  I18NexusLanguages,
} from "../../language/languagesUtil";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import { APIAddress } from "../../../api/addresses/addresses";
import BillingInformation from "../../../data-model/types/billing/BillingInformation";
import ConsultationDuration, {
  ConsultationDurationOptions,
} from "../../../data-model/types/consultation/ConsultationDuration";
import ConsultationRate from "../../../data-model/types/consultation/ConsultationRate";
import CMNumber from "../../../data-model/types/professional/CMNumber";
import ConsultationLanguages from "../../../data-model/types/professional/ConsultationLanguages";
import Description, {
  DESCRIPTION_PREFIX,
} from "../../../data-model/types/professional/Description";
import Experience from "../../../data-model/types/professional/Experience";
import Specialties from "../../../data-model/types/professional/Specialties";
import Therapies from "../../../data-model/types/professional/Therapies";
import VisumNumber from "../../../data-model/types/professional/VisumNumber";
import Address from "../../../data-model/types/profile/Address";
import BirthDate from "../../../data-model/types/profile/BirthDate";
import Gender, {
  GenderOptions,
} from "../../../data-model/types/profile/Gender";
import PreferredLanguage from "../../../data-model/types/profile/PreferredLanguage";
import Timezone from "../../../data-model/types/profile/Timezone";
import Avatar from "../../../data-model/types/profile/Avatar";

//TODO: we can make these enums too.
export const FORM_KEY_ACCEPTED_CONSENT = "acceptedConsent";

export const FORM_KEY_IS_FIRST_LINE = "isFirstLine";

export const FORM_KEY_DOES_SUPERVISION = "supervision";

export interface _sharedProfessionalFormState {
  accountNumber: string;
  address: APIAddress.Result;
  avatar: Avatar;
  billingAddress: APIAddress.Result;
  birthDate: Date;
  cmNumber: string;
  consultationLanguages: Array<ISelectObject<ConsultationLanguages>>;
  contactForFeedback: boolean;
  email: string;
  experienceSince: Date;
  gender: GenderOptions;
  isFirstLine: boolean;
  preferredLanguage: ISelectObject<I18NexusLanguages>;
  specialties: Array<ISelectObject<Specialties>>;
  standardDuration: ConsultationDurationOptions;
  standardRate: number;
  supervision: boolean;
  therapyTypes: Array<ISelectObject<Therapies>>;
  timezone: Timezone | string;
  visumNumber: string;
  website: string;
}

const INITIAL_VALUES = {
  ...Avatar.getInitialValue(),
  ...Address.getInitialValue(),
  ...ConsultationRate.getInitialValue(),
  ...ConsultationDuration.getInitialValue(),
  ...Specialties.getInitialValue(),
  ...PreferredLanguage.getInitialValue(),
  ...Experience.getInitialValue(),
  ...Gender.getInitialValue(),
  ...Therapies.getInitialValue(),
  ...VisumNumber.getInitialValue(),
  ...CMNumber.getInitialValue(),
  ...ConsultationLanguages.getInitialValue(),
  ...BirthDate.getInitialValue(),
  ...BillingInformation.getInitialValue(),
  [FORM_KEY_ACCEPTED_CONSENT]: false,
  [FORM_KEY_DOES_SUPERVISION]: false,
  [FORM_KEY_IS_FIRST_LINE]: false,
};

export default function useProfessionalOnboardFormState(
  initialValues: Partial<_sharedProfessionalFormState> = INITIAL_VALUES,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Validation,
  );

  const validation = useMemo(() => {
    // weird flex to get the descriptions to be validated.
    const descriptionValidations: Array<IFormValidation> = [];

    Object.values(DatabaseLanguageEquivalents).forEach((dbLang) =>
      Description.getFormValidation(
        translate,
        `${DESCRIPTION_PREFIX}-${dbLang}`,
      ).forEach((validation: IFormValidation) =>
        descriptionValidations.push(validation),
      ),
    );

    return createFormValidation([
      ...VisumNumber.getFormValidation(translate),
      ...CMNumber.getFormValidation(translate),
      ...Specialties.getFormValidation(translate),
      ...Therapies.getFormValidation(translate),
      ...ConsultationRate.getFormValidation(translate),
      ...ConsultationLanguages.getFormValidation(translate),
      ...Avatar.getFormValidation(translate),
      ...Address.getFormValidation(translate),
      ...Experience.getFormValidation(translate),
      ...BirthDate.getFormValidation(translate),
      ...BillingInformation.getFormValidation(translate),
      ...ConsultationDuration.getFormValidation(translate),
      {
        message: translate(
          "consent.mandatory",
          "Je moet akkoord gaan om verder te kunnen.",
        ),
        path: FORM_KEY_ACCEPTED_CONSENT,
        validate: (consent) => consent,
      },
      ...descriptionValidations,
    ]);
  }, [translate]);

  return useFormState<_sharedProfessionalFormState>(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
