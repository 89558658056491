import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERY_FETCH_PROFESSIONAL_CURRENT } from "../queries";
import Professional from "../../../data-model/types/professional/Professional";
import { APIProfessional } from "../professionals";

interface IProfessionalResult {
  professional: APIProfessional.Result;
}

const useFetchOneProfessional = (QUERY = QUERY_FETCH_PROFESSIONAL_CURRENT) => {
  const { data, loading, error, updateQuery } =
    useQuery<IProfessionalResult>(QUERY);

  const parsedData = useMemo((): Professional | undefined => {
    if (!loading && data) return new Professional(data.professional, true);
  }, [loading, data]);

  return {
    error,
    loading,
    professional: parsedData,
    updateQuery,
  };
};

export default useFetchOneProfessional;
