import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { RequestedByType } from "@prisma/client";
import Professional from "../../../data-model/types/professional/Professional";
import DeprecatedAvatar from "../../ui/DeprecatedAvatar";
// import Avatar from '@mui/material/Avatar';
import ButtonWithStatus from "../../ui/buttons/ButtonWithStatus";

const PREFIX = "ProfessionalForOtherUser";

const classes = {
  fullName: `${PREFIX}-fullName`,
  text: `${PREFIX}-text`,
  time: `${PREFIX}-time`,
};

const StyledButtonWithStatus = styled(ButtonWithStatus)(({ theme }) => ({
  [`& .${classes.text}`]: {
    paddingLeft: 15,
  },

  [`& .${classes.fullName}`]: {
    fontSize: theme.typography.sizes.xl,
  },

  [`& .${classes.time}`]: {
    fontSize: theme.typography.sizes.normal,
  },
}));

interface IProfessionalForClient {
  handleClick?: (id: number) => void;
  isOpen: boolean;
  professional: Professional;
}

export default function ProfessionalListItem({
  isOpen,
  professional,
  handleClick,
}: IProfessionalForClient) {
  return (
    <StyledButtonWithStatus
      isOpen={isOpen}
      key={professional.getID()}
      onClick={() => {
        if (handleClick) handleClick(professional.getID());
      }}
      requestedBy={undefined as unknown as RequestedByType}
      status={undefined}
    >
      <Grid container>
        <Grid item md={1} sm={1} xs={1}>
          <DeprecatedAvatar size={30} user={professional} />
        </Grid>
        <Grid className={classes.text} item md={11} sm={11} xs={11}>
          <Typography align="left" className={classes.fullName} noWrap>
            {professional.getScreenName()}
          </Typography>
        </Grid>
      </Grid>
    </StyledButtonWithStatus>
  );
}
