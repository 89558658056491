import React, { useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import LoadingPage from "./layout/LoadingPage";
import AuthContext from "./providers/auth/AuthContext";
import AppRouter from "./AppRouter";
import FullAppToast from "./ui/alerts/Toast";
import { IAuthContext } from "./providers/auth/auth";

const App = () => {
  const { loading } = useContext<IAuthContext>(AuthContext);

  return (
    <>
      <CssBaseline />
      {loading && (
        <LoadingPage full logText="App Loader (loading from AuthContext)" />
      )}
      <FullAppToast />
      <AppRouter />
    </>
  );
};

export default App;
