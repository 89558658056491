import { Box, Typography } from "@mui/material";
import Header from "./Header";
import { SingleWave } from "wave-box";

interface StepHeaderProps {
  imageUrl: string;
  subtitle?: string;
  title: string;
}

const StepHeader = ({ imageUrl, subtitle, title }: StepHeaderProps) => {
  return (
    <Box sx={{ backgroundColor: "#FBF5F0" }}>
      <Header />

      <Box
        sx={(theme) => ({
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          [theme.breakpoints.down(500)]: {
            padding: "1rem 0.5rem 0.5rem",
          },
        })}
      >
        <Box
          alt="graphic"
          component="img"
          src={imageUrl}
          sx={(theme) => ({
            marginY: "1rem",
            maxHeight: "160px",
            maxWidth: "250px",
            [theme.breakpoints.down(500)]: {
              maxHeight: "85px",
              maxWidth: "133px",
            },
          })}
        />
        <Typography sx={{ textAlign: "center" }} variant={"h3"}>
          {title}
        </Typography>
        {subtitle && (
          <Typography sx={{ textAlign: "center", whiteSpace: "pre-line" }}>
            {subtitle}
          </Typography>
        )}
      </Box>
      <SingleWave waveColor="#FFF" />
    </Box>
  );
};

export default StepHeader;
