import { TFunction } from "i18next";
import { Treatment } from "../../../../api/__generated__/graphql";
import environment from "../../../../environment/environment";

const getCostSentence = ({
  coachingEnabled,
  coachingsPerHuman,
  consultsPerHuman,
  contractStart,
  duration,
  orgName,
  price,
  mutualityName,
  t,
  treatment,
  videoEnabled,
}: {
  coachingEnabled?: boolean;
  coachingsPerHuman?: number;
  consultsPerHuman?: number;
  contractStart?: string;
  duration?: number;
  mutualityName?: string;
  orgName?: string;
  price?: number;
  t: TFunction;
  treatment: Treatment;
  videoEnabled?: boolean;
}) => {
  // Same for Helan & CM
  const reimbursementPrice = price ? price - 10 : undefined;

  if (
    (videoEnabled === true && treatment === "THERAPY") ||
    (coachingEnabled === true && treatment === "COACHING")
  ) {
    if (consultsPerHuman === undefined) {
      return t("match:chooseProfStep.card.cost_org_unlimited", { orgName });
    } else if (treatment === "COACHING") {
      return t("match:chooseProfStep.card.cost_org_limited_coaching", {
        contractStart,
        count: coachingsPerHuman,
        duration,
        orgName,
      });
    } else {
      return mutualityName === "CM"
        ? t("match:chooseProfStep.card.cost_org_limited_consult_cm", {
            contractStart,
            count: consultsPerHuman,
            duration,
            orgName,
            price,
            reimbursementPrice,
          })
        : environment.HELAN_REIMBURSEMENTS_ACTIVE && mutualityName === "Helan"
        ? t("match:chooseProfStep.card.cost_org_limited_consult_helan", {
            contractStart,
            count: consultsPerHuman,
            duration,
            orgName,
            price,
            reimbursementPrice,
          })
        : t("match:chooseProfStep.card.cost_org_limited_consult", {
            contractStart,
            count: consultsPerHuman,
            duration,
            orgName,
            price,
            reimbursementPrice,
          });
    }
  } else {
    return mutualityName === "CM"
      ? t("match:chooseProfStep.card.cost_cm", {
          duration,
          price,
          reimbursementPrice,
        })
      : environment.HELAN_REIMBURSEMENTS_ACTIVE && mutualityName === "Helan"
      ? t("match:chooseProfStep.card.cost_helan", {
          duration,
          price,
          reimbursementPrice,
        })
      : t("match:chooseProfStep.card.cost", { duration, price });
  }
};

export default getCostSentence;
