import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormLabel from "../../ui/form/FormLabel";
import TextField from "../../ui/form/TextField";
import RadioQuestion from "../../ui/form/RadioQuestion";
import CheckBox from "../../ui/form/CheckBox";
import FirstNameDataType from "../../../data-model/types/profile/FirstName";
import SanitizedText from "../../ui/text/SanitizedText";
import MultiSelect from "../../ui/form/select/MultiSelect";
import { useMemo } from "react";
import ConsultationLanguages from "../../../data-model/types/professional/ConsultationLanguages";

interface IRegisterForm {
  checked: boolean;
  formState: any;
  setChecked: (value: boolean) => void;
}

export default function RegisterForm({
  checked,
  setChecked,
  formState,
}: IRegisterForm) {
  const { t } = useTranslation();

  const languageSelectDefaultValue = () => {
    const selectedItems = new ConsultationLanguages();

    selectedItems.setValue(formState.getValue(ConsultationLanguages.getPath()));
    const selected = selectedItems.getSelectedOptions(t);

    return selected;
  };

  const languageSelectOptions = useMemo(
    () => [
      ...new ConsultationLanguages().getSelectOptions(t),
      { label: t("ui:languages.translated.es"), value: "es" },
      { label: t("ui:languages.translated.it"), value: "it" },
      { label: t("ui:languages.translated.pt"), value: "pt" },
      { label: t("ui:languages.translated.de"), value: "de" },
      { label: t("ui:languages.translated.pl"), value: "pl" },
      { label: t("ui:languages.translated.ru"), value: "ru" },
      { label: t("ui:languages.translated.tr"), value: "tr" },
      { label: t("ui:languages.translated.ro"), value: "ro" },
      { label: t("ui:languages.translated.ar"), value: "ar" },
    ],
    [t],
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            autoComplete="given-name"
            autoFocus
            id="firstName"
            label={t("common:firstname")}
            {...formState?.getInputProps(FirstNameDataType.getPath())}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <TextField
            autoComplete="family-name"
            id="lastName"
            label={t("common:lastname")}
            {...formState?.getInputProps("lastName")}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            autoComplete="email"
            id="email"
            label={t("common:email")}
            type="email"
            {...formState?.getInputProps("email")}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <TextField
            autoComplete="phone"
            id="phone"
            label={t("common:phone", {
              context: "with_example",
              defaultValue: "Telefoonnummer (bv. 0032123456789)",
            })}
            type="tel"
            {...formState?.getInputProps("phone")}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <FormLabel
            component="legend"
            label={t("professional:register.iampro.label")}
          />
          <RadioQuestion
            options={[
              {
                label: t("professional:register.iampro.clinicalpsy"),
                value: "cp",
              },
              {
                label: t("professional:register.iampro.clinicalpsywiththerapy"),
                value: "cppt",
              },
              {
                label: t(
                  "professional:register.iampro.professionalwiththerapy",
                ),
                value: "pt",
              },
            ]}
            {...formState?.getInputProps("professionalType")}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <FormLabel
            label={t("professional:onboarding.languages.description")}
          />
          <Box>
            <MultiSelect
              closeMenuOnSelect
              options={languageSelectOptions}
              placeholder={t("professional:onboarding.languages.label")}
              {...formState.getInputProps(ConsultationLanguages.getPath())}
              defaultValue={languageSelectDefaultValue()}
              selectTestId={`test_onboarding_professional_consultation_language_select`}
            />
          </Box>
        </Grid>
      </Grid>
      <Box py={2}>
        <CheckBox
          checked={checked}
          errorText={formState.getErrorMessage()}
          hasError={formState?.hasError()}
          helperStyle={{}}
          label={
            <SanitizedText input={t("professional:register.termsandprivacy")} />
          }
          name=""
          onChange={() => setChecked(!checked)}
          style={{}}
          tooltipTitle=""
        />
      </Box>
    </>
  );
}
