/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useContext, useEffect, useState } from "react";
import useBeams from "../hooks/useBeams";
import AuthContext from "../../../../components/providers/auth/AuthContext";
import { IAuthContext } from "../../../../components/providers/auth/auth";
import { UserRoles } from "../../../../data-model/types/User";
import { IBeamsContext, INotificationSubscription } from "./beams";
import BeamsContext from "./BeamsContext";

export default function BeamsProvider({ children }: IBeamsContext) {
  const { client, error, registered, triggerPermissionRequest } = useBeams();
  const [allInterests, setAllInterests] = useState<string[]>();
  const [subscriptions, setSubscriptions] = useState<
    INotificationSubscription[]
  >([]);

  //TODO: refactor this to use the new provider for a logged in user?
  const { currentUser } = useContext<IAuthContext>(AuthContext);

  useEffect(() => {
    //Clean up
    return () => {
      if (client) client.clearDeviceInterests();
    };
  }, []);

  const askPermissions = useCallback(
    () => triggerPermissionRequest(),
    [triggerPermissionRequest],
  );

  const constructBeamsNames = useCallback(
    (
      subscriptions: INotificationSubscription[],
    ): INotificationSubscription[] => {
      try {
        return subscriptions.map((sub: INotificationSubscription) => {
          if (!sub.beamName || sub.beamName === "") {
            const { id } = sub;

            if (!id) throw new Error("id cannot be undefined.");

            sub.beamName = `notify-${
              currentUser?.getRole() === UserRoles.PROFESSIONAL
                ? "psy"
                : "client"
            }-${id}`;
          }

          return sub;
        });
      } catch (error) {
        throw new Error(`(constructBeamsName) ${error}`);
      }
    },
    [],
  );

  const setMultipleSubscriptions = useCallback(
    async (subscriptionList: INotificationSubscription[]) => {
      if (!registered) return;

      try {
        const subs = constructBeamsNames(subscriptionList);

        subscriptions.concat(subs);
        setSubscriptions(subscriptions);

        if (client) {
          const interests = subs
            .map((s: INotificationSubscription) => s.beamName ?? "")
            .filter((i: string) => i !== "");

          await client.setDeviceInterests(interests);
          setAllInterests(interests);
        }
      } catch (error) {
        console.error("Could not subscribe to interest: ", error);
      }
    },
    [client, constructBeamsNames, registered, subscriptions],
  );

  const addSubscription = async (subscription: INotificationSubscription) => {
    if (!registered) return;

    try {
      const interest = constructBeamsNames([subscription]);

      subscriptions.push(subscription);
      setSubscriptions(subscriptions);

      if (client) {
        if (interest[0].beamName) {
          const beamName = interest[0].beamName;

          await client.addDeviceInterest(beamName);

          const interests: string[] = await client.getDeviceInterests();

          setAllInterests(interests);
        }
      }
    } catch (error) {
      console.error("Could not subscribe to interest: ", error);
    }
  };

  useEffect(() => {
    if (currentUser && registered) {
      // Subscribe to beam 'notify-myId'
      addSubscription({ id: currentUser.getID() });
    }
  }, [currentUser, registered]);

  return (
    <BeamsContext.Provider
      value={{
        addSubscription,
        askPermissions,
        error,
        interests: allInterests,
        ready: !!client && registered,
        setMultipleSubscriptions,
      }}
    >
      {children}
    </BeamsContext.Provider>
  );
}
