import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import environment from "../environment/environment";
import { graphql } from "./__generated__";

const refreshAccessTokenMutation = graphql(`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessTokenV2(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`);

const createUnauthorizedApolloClient = () =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: new HttpLink({
      uri: `${environment.REACT_APP_SERVER_URL}${environment.REACT_APP_GRAPHQL}`,
    }),
  });

export default async function refreshAccessToken(refreshToken: string) {
  const apolloClient = createUnauthorizedApolloClient();

  try {
    const response = await apolloClient.mutate({
      mutation: refreshAccessTokenMutation,
      variables: { refreshToken },
    });

    return response.data?.refreshAccessTokenV2;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Failed to refresh access token. Error:", e);

    return null;
  }
}
