/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useFormState from "../../hooks/useFormState";
import createFormValidation from "../../../utils/forms/createFormValidation";
import ConsultationStatus from "../../../data-model/types/consultation/ConsultationStatus";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import Consultation, {
  ConsultationTypes,
} from "../../../data-model/types/consultation/Consultation";

const INITIAL_VALUES = {
  date: moment().seconds(0).milliseconds(0),
  duration: 50,
  timeFrom: moment().seconds(0).milliseconds(0),
  timeTo: moment().seconds(0).milliseconds(0).add(50),
  type: null,
  user: null,
};

export default function useAddConsultationFormState(
  initialValues?: any,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const localInitialValues = useMemo(() => initialValues, []);

  const validation = useMemo(
    () =>
      createFormValidation([
        {
          message: translate(
            "validation:need.to.select.client",
            "Je moet een cliënt selecteren.",
          ),
          path: "user",
          validate: (user) => user && user !== "",
        },
        {
          message: translate(
            "validation:price.minimum",
            "Gelieve een prijs op te geven van minimum 1 EUR.",
          ),
          path: "price",
          validate: (price, other) => {
            if (other?.type?.value === ConsultationTypes.APPOINTMENT) {
              return price >= 1;
            }

            return true;
          },
        },
        ...Consultation.getFormValidation(translate),
        ...ConsultationStatus.getFormValidation(translate),
      ]),
    [translate],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...localInitialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
