import BaseSimpleSelect, {
  ISelectObject,
  ISelectProps,
} from "./BaseSimpleSelect";

interface IMultiSelect<T = any> extends ISelectProps<T> {
  onChange: (value: ISelectObject<T>[]) => void;
  value?: ISelectObject<T>[];
}

export default function MultiSelect<T>({
  name,
  value,
  options,
  placeholder,
  hasError,
  errorText,
  menuWithTooltip,
  onChange,
}: IMultiSelect<T>) {
  return (
    <BaseSimpleSelect<T>
      closeMenuOnSelect={false}
      errorText={errorText}
      hasError={hasError}
      isMulti={true}
      menuWithTooltip={menuWithTooltip}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
}
