import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

const PREFIX = "MenuOption";

const classes = {
  root: `${PREFIX}-root`,
  toolTip: `${PREFIX}-toolTip`,
  value: `${PREFIX}-value`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    "&:hover": {
      backgroundColor: theme.palette.bloom.light,
    },
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    fontSize: "16px",
    height: 40,
    justifyContent: "flex-start",

    minWidth: 200,
  },

  [`& .${classes.value}`]: { flexGrow: 4, marginLeft: 10, textAlign: "left" },
  [`& .${classes.toolTip}`]: {
    color: theme.palette.bloom.dark,
    marginRight: 10,
  },
}));

//TODO; type
export const MenuOption = ({ innerRef, innerProps, children }) => {
  const { t } = useTranslation();

  return (
    <StyledBox className={classes.root} ref={innerRef} {...innerProps}>
      <Box className={classes.value}>{t(children)}</Box>
    </StyledBox>
  );
};
