/**
 * Billing information for an organization or professional.
 */
import type { TFunction } from "i18next";
import DataType from "../../DataType";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import { DataTypePaths } from "../../dataTypePaths";
import { APIBillingInformation } from "../../../api/billingInformation/billingInformation";
import AccountNumber from "./AccountNumber";
import BillingAddress from "./BillingAddress";

export default class BillingInformation extends DataType<
  APIBillingInformation.Output,
  DataTypePaths.Billing.BillingInformation
> {
  protected type: any = BillingInformation;
  protected static path: DataTypePaths.Billing.BillingInformation =
    "billingInformation";

  protected address: BillingAddress = new BillingAddress();
  protected accountNumber: AccountNumber = new AccountNumber();

  constructor(model?: APIBillingInformation.Output) {
    super();

    if (!model) return;

    if (model.address) this.address = new BillingAddress(model.address);

    if (model.accountNumber)
      this.accountNumber = new AccountNumber(model.accountNumber);
  }

  /**
   * Get the billing address.
   * You can call .getValue from this to get it textual.
   */
  public getAddress(): BillingAddress {
    return this.address;
  }

  public getAccountNumber(): AccountNumber {
    return this.accountNumber;
  }

  static getInitialValue() {
    return {
      ...BillingAddress.getInitialValue(),
      ...AccountNumber.getInitialValue(),
    };
  }

  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      ...BillingAddress.getFormValidation(translate),
      ...AccountNumber.getFormValidation(translate),
    ];
  }

  /**
   * Return an object containing the values to be used
   * as the values in the formState.
   */
  public getAsFormStateValues() {
    return {
      ...this.accountNumber.getAsFormStateValue(),
      ...this.address.getAsFormStateValue(),
    };
  }

  getAsFormStateValue(): Record<
    DataTypePaths.Billing.BillingInformation,
    APIBillingInformation.Output
  > {
    return { billingInformation: this.value };
  }
}
