import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment";
import { DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import ValueWithLabel from "../text/ValueWithLabel";
import DataType from "../../../data-model/DataType";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { IFormState } from "../../hooks/useFormState";
import { CustomDatePicker } from "../form/DatePicker";
import { DateFormat } from "../../../data-model/Date";
import InlineEditableControls from "./common/InlineEditableControls";

type IEDProps<T extends DataType<Date | null, any>> = {
  dataObject: T;
  formState: IFormState;
  fullWidth?: boolean;
  label: string;
  large?: boolean;
  multiline?: boolean;
  onSave: () => void;
  pathOverride?: string;
  placeholder?: string | null;
} & Omit<DatePickerProps<Moment>, "onChange" | "value">;

export default function InlineEditableDate<
  T extends DataType<Date | null, any>,
>({
  label,
  dataObject,
  onSave,
  large = false,
  formState,
  pathOverride,
  fullWidth,
  ...dateProps
}: IEDProps<T>) {
  const { t: translate } = useTranslation<I18Namespaces>("common");
  const [updatedValue, setUpdatedValue] = useState<Date | null>(
    dataObject ? dataObject.getValue() : null,
  );

  const path: string =
    pathOverride ?? dataObject ? dataObject.getType().getPath() : null;

  let errorText = "";

  if (formState) {
    errorText =
      formState.getErrorMessages(path) && formState.getErrorMessages(path)[0];
  }

  useEffect(() => {
    setUpdatedValue(dataObject.getValue());
  }, [dataObject]);

  return (
    <InlineEditableControls
      edit={
        <CustomDatePicker
          autoFocus
          errorText={errorText}
          fullWidth={fullWidth}
          value={updatedValue}
          {...dateProps}
          {...(formState ? formState.getValidationProps(path) : {})}
          onChange={setUpdatedValue}
        />
      }
      editable={true}
      key={path}
      label={label || translate("common:-", "-")}
      onDiscard={() => {
        formState.values[path] = dataObject.getValue();

        if (formState.validate(path)) {
          setUpdatedValue(dataObject.getValue());

          return true;
        } else {
          return false;
        }
      }}
      onSave={async () => {
        // TODO figure out why setValue on formstate doesnt work and this does
        formState.values[path] = updatedValue;

        if (formState.validate(path)) {
          dataObject.setValue(updatedValue);
          setUpdatedValue(updatedValue);

          await onSave();

          return true;
        } else {
          return false;
        }
      }}
      read={
        <ValueWithLabel label="" large={large}>
          {(updatedValue &&
            moment(updatedValue).format(
              dateProps.format || DateFormat.ONLY_DATE_SHORT,
            )) ||
            translate(
              `${BloomUpNamespacesEnum.Ui}:inlineeditable.read.unknown`,
            )}
        </ValueWithLabel>
      }
    />
  );
}
