import { useState, useContext } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useTranslation } from "react-i18next";
import ToastContext from "../../providers/toast/ToastContext";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import TextField from "../../ui/form/TextField";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
import useResetPasswordMutation from "../../../api/auth/hooks/useResetPasswordMutation";
import Email from "../../../data-model/types/profile/Email";
import { I18Namespaces } from "../../language/I18Namespaces";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import useResetPasswordFormState from "./useResetPasswordFormState";

const ResetPasswordPageWithState = () => {
  const { setToast } = useContext(ToastContext);
  const [passwordHasBeenReset, setPasswordHasBeenReset] =
    useState<boolean>(false);

  const { t } = useTranslation<I18Namespaces>(["ui", "common"]);
  const { resetPassword, loading: isSubmitting } = useResetPasswordMutation();
  const formState = useResetPasswordFormState();

  const handleSubmit = async (event?: Event) => {
    if (event) event.preventDefault();

    const isValid = formState.validate();

    if (isValid) {
      try {
        await resetPassword(formState.getValue(Email.getPath()));
        setPasswordHasBeenReset(true);
      } catch {
        //const graphqlErrors = parseGraphQLErrors(error);
        //formState.updateErrors(graphqlErrors);
        setToast({
          message: t("ui:toast.something_went_wrong"),
          severity: "error",
        });
      }
    }
  };

  return (
    <OnboardingContainer>
      {passwordHasBeenReset ? (
        <ResetPasswordSuccess />
      ) : (
        <OnboardingStepContainer
          description={t("common:password.reset.description")}
          disable={isSubmitting}
          fullCopyright
          headline={t("common:password")}
          labelNext={t("common:send")}
          onClickNext={handleSubmit}
          subhead={t("common:password.reset.title")}
          subheadicon={<LockOutlinedIcon />}
        >
          <TextField
            autoComplete="email"
            autoFocus
            id="email"
            label="Email"
            {...formState.getInputProps(Email.getPath())}
            onKeyPress={(e) => {
              return e.key === "Enter" ? handleSubmit() : null;
            }}
          />
        </OnboardingStepContainer>
      )}
    </OnboardingContainer>
  );
};

export default ResetPasswordPageWithState;
