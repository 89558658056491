//TODO: give this file a better name or extract to hook / provider

export const getRedirectRoute = (location, defaultRoute: string) => {
  let route = defaultRoute ?? "/";

  //Todo: location.state is typed as any because typescript doesnt like unknown type. We need a better solution for this problem.
  if (location.state) {
    const { from, originalRequest, search } = location.state as any;

    if (originalRequest) {
      route = originalRequest;

      if (search) route = `${route}${search}`;
    } else if (from) {
      route = (location.state as any).from;

      if (search) route = `${route}${search}`;
    }
  }

  return route;
};
