import { graphql } from "../__generated__";

export const MUTATION_CLIENT_INVITE = graphql(`
  mutation inviteClient($input: AddClientInput!) {
    inviteClient(input: $input)
  }
`);

export const MUTATION_CLIENT_ACCEPT = graphql(`
  mutation acceptInvite($input: AcceptInviteInput!) {
    acceptInvite(input: $input) {
      ...Client
      id
    }
  }
`);
