import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRosterState } from "amazon-chime-sdk-component-library-react";
import LoadingPage from "../../layout/LoadingPage";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { ChimeVideoCall } from "../../ui/call/ChimeVideoCall.types";
import ChimeVideoContainer from "../../ui/call/ChimeVideoContainer";
import ChimeButton from "../../ui/call/components/chimeButtonComponents/ChimeButton";
import ChimeStandardButtonBar from "../../ui/call/components/chimeButtonComponents/ChimeStandardButtonBar";
import PopUpMessage from "../../ui/call/components/popUp/PopUpMessage";
import ChimeStandardOverlaySidePane from "../../ui/call/components/sidePaneComponents/ChimeStandardOverlaySidePane";
import MenuItemSidePane from "../../ui/call/components/sidePaneComponents/MenuItemSidePane";
import StandardConsultationBookerSidePane from "../../ui/call/components/sidePaneComponents/StandardConsultationBookerSidePane";
import StandardInfoSidePane from "../../ui/call/components/sidePaneComponents/StandardInfoSidePane";
import StandardMenuSidePane from "../../ui/call/components/sidePaneComponents/StandardMenuPane";
import { ICallContext } from "../../ui/call/context/Call";
import CallContext from "../../ui/call/context/CallContext";
import ClientInformationDrawerProvider from "./context/ClientInformationDrawerProvider";
import { IClockContext } from "./context/clock";
import ClockContext from "./context/ClockContext";
import { useMutation, useQuery } from "@apollo/client";
import ToastContext from "../../providers/toast/ToastContext";
import { IToastContext } from "../../providers/toast/toast";
import { graphql } from "../../../api/__generated__";

const sidePaneQuery = graphql(`
  query ProfCallSidepane($organizationId: Int!) {
    organizationById(id: $organizationId) {
      ggtEnabled
      id
    }
  }
`);

const sendAppInviteMutation = graphql(`
  mutation SendAppInvite($humanId: Int!) {
    sendAppInvite(humanId: $humanId)
  }
`);

export default function ProfessionalCallContainer() {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Validation,
    BloomUpNamespacesEnum.Professional,
  ]);

  const {
    paneToPresent,
    setPaneToPresent,
    consultation,
    isSmallerThanFirstBreakpoint,
    callInfo,
  } = useContext<ICallContext>(CallContext);

  const { roster } = useRosterState();

  const attendees = Object.values(roster);

  const { stopClock, startClock } = useContext<IClockContext>(ClockContext);
  const { setToast } = useContext<IToastContext>(ToastContext);

  useEffect(() => {
    if (attendees.length === 2) {
      startClock();
    } else {
      stopClock();
    }
  }, [attendees, startClock, stopClock]);

  const [sendAppInvite] = useMutation(sendAppInviteMutation);
  const organizationId =
    consultation?.getClient().getOrganization().getID() ?? 0;

  // Should later be part of consultation data fetched above, but querying here due to context mess
  const { data } = useQuery(sidePaneQuery, {
    skip: !organizationId,
    variables: {
      organizationId: organizationId,
    },
  });

  if (!consultation) return <LoadingPage full />;

  return (
    <ChimeVideoContainer>
      {callInfo && callInfo.isCallStarted && attendees.length < 2 && (
        <PopUpMessage
          contentText={t("professional:videocall.popup.patience")}
        />
      )}
      <ChimeStandardOverlaySidePane>
        <ClientInformationDrawerProvider consult={consultation}>
          {paneToPresent ===
            ChimeVideoCall.SidePaneProfessional.clientInfoPane && (
            <StandardInfoSidePane consultation={consultation} />
          )}
          {paneToPresent === ChimeVideoCall.SidePaneProfessional.menuPane && (
            <StandardMenuSidePane>
              <MenuItemSidePane
                Icon={EventAvailableOutlinedIcon}
                onClick={() => {
                  setPaneToPresent(
                    ChimeVideoCall.SidePaneProfessional.bookingPane,
                  );
                }}
                text={t("ui:sidepane.menu.item.book.consultation")}
              />
              {data?.organizationById?.ggtEnabled && (
                <MenuItemSidePane
                  Icon={ForwardToInboxIcon}
                  onClick={() => {
                    try {
                      sendAppInvite({
                        variables: {
                          humanId: consultation.getClient().getID(),
                        },
                      });
                      setToast({
                        message: t("sidepane.menu.item.app.invite.success"),
                        severity: "success",
                      });
                    } catch {
                      setToast({
                        message: t("sidepane.menu.item.app.invite.failure"),
                        severity: "error",
                      });
                    }
                  }}
                  text={t("ui:sidepane.menu.item.app.invite")}
                />
              )}
              {isSmallerThanFirstBreakpoint && (
                <MenuItemSidePane
                  Icon={PersonOutlineIcon}
                  onClick={() => {
                    setPaneToPresent(
                      ChimeVideoCall.SidePaneProfessional.clientInfoPane,
                    );
                  }}
                  text={t("ui:sidepane.menu.item.info")}
                />
              )}
              {/* Temporary disabled until chat function is integrated.*/}
              {/* {isLargerThanSecondBreakpoint && <MenuItemSidePane Icon={SmsOutlinedIcon} text={translate("ui:sidepane.menu.item.book.consultation", "Chat")} onClick={() => { setPaneToPresent(SidePaneProfessional.chatPane) }} />} */}
            </StandardMenuSidePane>
          )}
          {paneToPresent ===
            ChimeVideoCall.SidePaneProfessional.bookingPane && (
            <StandardConsultationBookerSidePane consultation={consultation} />
          )}
        </ClientInformationDrawerProvider>
      </ChimeStandardOverlaySidePane>
      <ChimeStandardButtonBar>
        {!isSmallerThanFirstBreakpoint && (
          <ChimeButton
            bloomColor={
              paneToPresent ===
              ChimeVideoCall.SidePaneProfessional.clientInfoPane
                ? "green"
                : "bloom"
            }
            onClick={() => {
              if (setPaneToPresent) {
                setPaneToPresent(
                  paneToPresent ===
                    ChimeVideoCall.SidePaneProfessional.clientInfoPane
                    ? ChimeVideoCall.SidePaneProfessional.none
                    : ChimeVideoCall.SidePaneProfessional.clientInfoPane,
                );
              }
            }}
          >
            <PersonOutlineIcon />
          </ChimeButton>
        )}
        {/* This is temporary disable because chat is not ready for implementation. */}

        {/* {!isLargerThanSecondBreakpoint &&
                                <ChimeButton
                                    color={
                                        paneToPresent ===
                                            SidePaneProfessional.chatPane
                                            ? "#4caf50"
                                            : "#6bafbc"
                                    }
                                    onClick={() => {
                                        if (setPaneToPresent) {
                                            setPaneToPresent(
                                                paneToPresent ===
                                                    SidePaneProfessional.chatPane
                                                    ? SidePaneProfessional.none
                                                    : SidePaneProfessional.chatPane
                                            );
                                        }
                                    }}
                                >
                                    <SmsOutlinedIcon />
                                </ChimeButton>
                            } */}
      </ChimeStandardButtonBar>
    </ChimeVideoContainer>
  );
}
