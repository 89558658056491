import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "moment-timezone";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { IWithChildren } from "../../../../../baseInterfaces";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import CallClockWithContext from "../../../../pages/professional-call/components/CallClockWithContext";
import AuthContext from "../../../../providers/auth/AuthContext";
import { IAuthContext } from "../../../../providers/auth/auth";
import Consultation from "../../../../../data-model/types/consultation/Consultation";
import Professional from "../../../../../data-model/types/professional/Professional";
import InfoItemSidePane from "./InfoItemSidePane";
import InfoItemSidePanePartnerBox from "./InfoItemSidePanePartnerBox";
import InfoItemSidePanePaymentBox from "./InfoItemSidePanePaymentBox";
import InfoItemSidePaneTimeZone from "./InfoItemSidePaneTimeZone";
import SidePane from "./SidePane";

interface StandardInfoSidePane extends IWithChildren {
  consultation: Consultation;
}

const StandardInfoSidePane = ({
  consultation,
  children,
}: StandardInfoSidePane) => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
  ]);

  const { currentUser } = useContext<IAuthContext>(AuthContext);

  const human = consultation.getClient();
  const organization = human.getOrganization();
  const organizationId = organization.getID();

  return (
    <SidePane
      FooterContent={CallClockWithContext}
      TitleIcon={InfoOutlinedIcon}
      title={
        human.getFullName() ?? translate("ui:sidepane.Info.pane.title", "Info")
      }
    >
      {human.getPreferredLanguage().getValue() && (
        <InfoItemSidePane
          subTitle={translate(
            "professional:call.lanuage.client",
            "Taal cliënt",
          )}
        >
          {translate(
            `ui:languages.translated.${human
              .getPreferredLanguage()
              .getValue()}`,
          )}
        </InfoItemSidePane>
      )}

      {human && currentUser && (
        <InfoItemSidePaneTimeZone
          human={human}
          professional={currentUser as Professional}
        />
      )}

      {consultation && (
        <InfoItemSidePanePaymentBox consultation={consultation} />
      )}

      {human && organizationId && (
        <InfoItemSidePanePartnerBox
          human={human}
          organizationId={organizationId}
        />
      )}

      {children}
    </SidePane>
  );
};

export default StandardInfoSidePane;
