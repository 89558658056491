import { Navigate, Route, Routes } from "react-router-dom";
import CrispChat from "../../utils/crisp-chat/CrispChat";
import ClientsPresence from "../../utils/pusher/channels/components/ClientsPresence";
import { defaultConsultationsValues } from "../consultations/context/ConsultationsOverviewContext";
import ConsultationsOverviewContextProvider from "../consultations/context/ConsultationsOverviewProvider";
import SidebarWithState from "../layout/sidebar/SidebarWithState";
import HumanHomePage from "../pages/human-home/HumanHomePage";
import ProfessionalsPageWithState from "../pages/human-professionals/ProfessionalsPageWithState";
import HumanProfile from "../pages/human-profile/HumanProfile";
import HumanProfileContextProvider from "../pages/human-profile/context/HumanProfileContextProvider";
import { defaultRequestConsultationValues } from "../pages/human-request-consultation/context/RequestConsultationContext";
import RequestConsultationStateProvider from "../pages/human-request-consultation/context/RequestConsultationStateProvider";
import OrganizationUsagePage from "../pages/organization-usage/OrganizationUsagePage";
import HRDashboardsRoutes from "./HRDashboardsRoutes";
import HumanGuard from "./guards/HumanGuard";
import HumanRegistrationGuard from "./guards/HumanRegistrationGuard";
import { RouteNames } from "./routeNames";

export default function HumanHomeRoutes() {
  return (
    <HumanGuard>
      <HumanRegistrationGuard>
        <ClientsPresence>
          <ConsultationsOverviewContextProvider {...defaultConsultationsValues}>
            <RequestConsultationStateProvider
              {...defaultRequestConsultationValues}
            >
              <CrispChat />
              <SidebarWithState>
                <Routes>
                  <Route
                    element={<HumanHomePage />}
                    path={RouteNames.Home.Consultations.name}
                  >
                    <Route
                      element={<HumanHomePage />}
                      path={RouteNames.Home.Consultations.ShowConsultation.name}
                    />
                  </Route>
                  <Route
                    element={<ProfessionalsPageWithState />}
                    path={RouteNames.Home.Professionals.name}
                  >
                    <Route
                      element={<HumanHomePage />}
                      path={RouteNames.Home.Professionals.ShowProfessional.name}
                    />
                  </Route>
                  <Route
                    element={
                      <HumanProfileContextProvider>
                        <HumanProfile />
                      </HumanProfileContextProvider>
                    }
                    path={RouteNames.Home.Profile.name}
                  />
                  <Route
                    element={<OrganizationUsagePage />}
                    path={RouteNames.Home.OrganizationUsage.name}
                  />
                  <Route
                    element={<HRDashboardsRoutes />}
                    path={`${RouteNames.Home.HRDashboards.name}/*`}
                  />
                  <Route
                    element={<Navigate replace={true} to="consultations" />}
                    path={""}
                  />
                </Routes>
              </SidebarWithState>
            </RequestConsultationStateProvider>
          </ConsultationsOverviewContextProvider>
        </ClientsPresence>
      </HumanRegistrationGuard>
    </HumanGuard>
  );
}
