import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import HealthInsurance from "../../../data-model/types/reimbursement/HealthInsurance";
import LeftSection from "../../layout/LeftSection";
import LoadingPage from "../../layout/LoadingPage";
import EditableProfile from "../../profile/EditableProfile";
import InlineEditableSingleSelect from "../../ui/edit/InlineEditableSingleSelect";
import { graphql } from "../../../api/__generated__";
import useSelectTranslation from "../../../api/languages/useSelectTranslation";
import { IHumanProfileContext } from "./context/humanProfile";
import HumanProfileContext from "./context/HumanProfileContext";

const queryHumanProfilePage = graphql(`
  query HumanProfilePage {
    human {
      creditsLeft
      id
      organization {
        id
        name {
          ...useSelectTranslationStringInput
        }
      }
    }
  }
`);

export default function HumanProfile() {
  const { t } = useTranslation();

  const {
    human,
    formState,
    onSave,
    updateTimezone,
    updatePreferredLanguage,
    updateHealthInsurance,
  } = useContext<IHumanProfileContext>(HumanProfileContext);

  const { data } = useQuery(queryHumanProfilePage);
  const selectTranslation = useSelectTranslation();

  if (!human) return <LoadingPage full={false} />;

  return (
    <LeftSection
      pageTitle={t("common:myprofile", "Mijn Profiel")}
      short={false}
    >
      <EditableProfile
        creditsLeft={data?.human?.creditsLeft}
        formState={formState}
        onSave={onSave}
        organizationName={selectTranslation(data?.human?.organization?.name)}
        person={human}
        updatePreferredLanguage={updatePreferredLanguage}
        updateTimezone={updateTimezone}
      />
      <Box style={{ paddingLeft: "5px" }}>
        <InlineEditableSingleSelect<HealthInsurance>
          dataObject={human.getHealthInsurance()}
          formState={formState}
          label={t("common:preferences.health.insurance")}
          onSave={() =>
            updateHealthInsurance(
              formState.getInputProps(HealthInsurance.getPath()).value,
            )
          }
          placeholderText={t("common:preferences.health.insurance.placeholder")}
        />
      </Box>
    </LeftSection>
  );
}
