/**
 * Address of psy
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { zipCodes } from "../../../components/utils/zipCodes";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType, { PartialRecord } from "../../DataType";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";
import { APIAddress } from "../../../api/addresses/addresses";

export default class Address<
  PathName extends PossibleFormStatePaths = DataTypePaths.Profile.Address,
> extends DataType<
  Partial<APIAddress.Result>,
  PathName //DataTypePaths.Profile.Address
> {
  protected static path = "address";
  protected static defaultValue = {};

  protected type: any = Address;

  private street: string | undefined;
  private postcode: string | undefined;
  private country: string | undefined;
  private location: string | undefined;

  constructor(model?: Partial<APIAddress.Result>) {
    super(model);

    if (model) {
      this.street = model.street;
      this.postcode = model.postcode;
      this.country = model.country ?? "BE";
      this.location = model.location;
    }
  }

  getStreet() {
    return this.street;
  }

  getPostcode() {
    return this.postcode;
  }

  getCountry() {
    return this.country;
  }

  getLocation() {
    return this.location;
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:address.mandatory",
          "Voeg je adres toe.",
        ),
        path: this.getPath(),
        validate: (a: APIAddress.Result): boolean => {
          return !!a && !!a.street && a.street.trim() !== "";
        },
      },
      {
        message: translate("validation:zip.invalid", "Postcode is foutief."),
        path: this.getPath(),
        validate: (a: APIAddress.Result): any =>
          zipCodes.find((z) => z.value === a.postcode),
      },
    ];
  }

  public prettyPrint(): string {
    if (!this.value) return "";

    return `${this.value.street}, ${this.value.postcode}`;
  }

  getAsFormStateValue(): PartialRecord<
    DataTypePaths.Billing.BillingAddress | DataTypePaths.Profile.Address,
    Partial<APIAddress.Result>
  > {
    return { address: this.value };
  }
}
