import { Theme, styled, Box, useMediaQuery } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "./buttons/Button";

const PREFIX = "EmptyList";

const classes = {
  boxInner: `${PREFIX}-boxInner`,
  container: `${PREFIX}-container`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.boxInner}`]: {
    background: theme.palette.gray.light,
    borderRadius: 5,
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 4, 4, 3),
  },

  [`& .${classes.container}`]: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "center",
    margin: "0 auto",
    maxWidth: "70%",
    width: "100%",
  },
}));

interface RequestConsultationInfoSectionProps {
  description: string;
  navigationRoute: string;
  submitLabel: string;
  title: string;
}

const EmptyList = ({
  title,
  description,
  submitLabel,
  navigationRoute,
}: RequestConsultationInfoSectionProps) => {
  const navigate = useNavigate();
  const windowSizeMedium = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(370),
  );

  const windowSizeSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(325),
  );

  const buttonSize = windowSizeMedium
    ? windowSizeSmall
      ? "small"
      : "medium"
    : "large";

  return (
    <StyledBox className={classes.boxInner}>
      <div className={classes.container}>
        <Typography align="center" variant="h2">
          {title}
        </Typography>
        <Box my={2}>
          <Typography align="center" variant="body1">
            {description}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            label={submitLabel}
            onClick={() => {
              navigate(navigationRoute);
            }}
            size={buttonSize}
          />
        </Box>
      </div>
    </StyledBox>
  );
};

export default EmptyList;
