import { useCallback, useState } from "react";
import { BloomUpToast, IToastContext } from "./toast";
import ToastContext from "./ToastContext";

export default function ToastProvider({ children }: IToastContext) {
  const [toast, setToast] = useState<BloomUpToast | undefined>(undefined);
  const [open, setOpen] = useState<boolean>(false);

  const setNewToast = useCallback((toast: BloomUpToast) => {
    setToast(toast);
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ToastContext.Provider
      value={{
        close,
        open,
        setOpen,
        setToast: setNewToast,
        toast,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}
