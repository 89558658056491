import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { memo } from "react";
import Button from "../../ui/buttons/Button";

const PREFIX = "StepFooter";

const classes = {
  backButton: `${PREFIX}-backButton`,
  black: `${PREFIX}-black`,
  bloom: `${PREFIX}-bloom`,
  button: `${PREFIX}-button`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  container: `${PREFIX}-container`,
  gray: `${PREFIX}-gray`,
  green: `${PREFIX}-green`,
  orange: `${PREFIX}-orange`,
  porple: `${PREFIX}-porple`,
  red: `${PREFIX}-red`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.container}`]: {
    margin: "0 auto",
    maxWidth: "90%",
    padding: theme.spacing(1, 0, 4),
    width: "100%",

    [theme.breakpoints.only("lg")]: {
      padding: theme.spacing(2, 0),
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      padding: theme.spacing(1, 3, 3),
    },
  },

  [`& .${classes.buttonContainer}`]: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap-reverse",
    justifyContent: "space-around",
    margin: "0 auto",
    maxWidth: 400,
    paddingTop: 50,
    rowGap: 20,
  },

  [`& .${classes.backButton}`]: {
    minWidth: 120,
  },

  [`& .${classes.button}`]: {
    margin: 10,
  },

  //TODO; can we extract these colors to a new hook?
  [`& .${classes.bloom}`]: {
    color: theme.palette.bloom.main,
  },

  [`& .${classes.porple}`]: {
    color: theme.palette.porple.main,
  },

  [`& .${classes.orange}`]: {
    color: theme.palette.orange.main,
  },

  [`& .${classes.red}`]: {
    color: theme.palette.red.main,
  },

  [`& .${classes.gray}`]: {
    color: theme.palette.gray.main,
  },

  [`& .${classes.green}`]: {
    color: "green",
  },

  [`& .${classes.black}`]: { color: theme.palette.black.main },
}));

export type BloomColor =
  | "bloom"
  | "porple"
  | "orange"
  | "red"
  | "gray"
  | "green"
  | "black";

interface IStepFooter {
  colorBack: BloomColor;
  colorNext: BloomColor;
  colorSkip?: BloomColor;
  disable: boolean;
  endIconBack?: React.ReactNode;
  endIconNext?: React.ReactNode;
  endIconSkip?: React.ReactNode;
  fullColoredBack?: boolean;
  fullColoredNext?: boolean;
  fullColoredSkip?: boolean;
  labelBack?: string | null;
  labelNext?: string | null;
  labelSkip?: string;
  loading?: boolean;
  onClickBack?: () => void;
  onClickNext?: () => void;
  onClickSkip?: () => void;
}

export default memo(function StepFooter({
  onClickBack,
  onClickNext,
  onClickSkip,
  labelNext,
  labelBack,
  labelSkip,
  colorNext = "bloom",
  colorBack = "bloom",
  colorSkip = "bloom",
  endIconNext,
  endIconBack,
  endIconSkip,
  fullColoredNext = false,
  fullColoredBack = false,
  fullColoredSkip = false,
  disable,
  loading,
}: IStepFooter) {
  return (
    <StyledBox className={classes.container}>
      <Box className={classes.buttonContainer}>
        {onClickBack ? (
          <Button
            bloomColor={colorBack}
            className={clsx(classes.backButton, classes.button)}
            endIcon={endIconBack}
            fullyColored={fullColoredBack}
            id="back-button"
            label={labelBack ?? ""}
            onClick={onClickBack}
            variant="text"
          />
        ) : null}
        {onClickSkip ? (
          <Button
            bloomColor={colorSkip}
            className={classes.button}
            disabled={disable}
            endIcon={endIconSkip}
            fullyColored={fullColoredSkip}
            id="skip-button"
            label={labelSkip ?? ""}
            loading={loading}
            onClick={onClickSkip}
          />
        ) : null}
        {onClickNext ? (
          <Button
            bloomColor={colorNext}
            className={classes.button}
            disabled={disable}
            endIcon={endIconNext}
            fullyColored={fullColoredNext}
            id="next-button"
            label={labelNext ?? ""}
            loading={loading}
            onClick={onClickNext}
          />
        ) : null}
      </Box>
    </StyledBox>
  );
});
