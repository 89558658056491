/* eslint-disable react-hooks/exhaustive-deps */
import { createInstance, MatomoProvider } from "@jonkoops/matomo-tracker-react";
import { ReactNode, useMemo } from "react";
import environment from "../../../environment/environment";

export default function MatomoClientProvider({
  children,
}: {
  children: ReactNode;
}) {
  const matomoInstance = useMemo(
    () =>
      createInstance({
        // optional, default value: true
        configurations: {
          // optional, default value: {}
          // any valid matomo configuration, all below are optional
          disableCookies: true,
          setRequestMethod: "POST",
          setSecureCookie: true,
        },

        // optional, default value: `undefined`.
        heartBeat: {
          // optional, enabled by default
          active: true, // optional, default value: true
          seconds: 10, // optional, default value: `15
        },

        linkTracking: true,

        siteId: Number(environment.REACT_APP_MATOMO_KEY),

        //TODO: get rid of hardcoded URL
        urlBase: "https://analytics.bloomup.org",
        userId: undefined,
      }),
    [environment.REACT_APP_MATOMO_KEY],
  );

  if (environment.REACT_APP_ENV === "programmer") return <>{children}</>;

  return <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>;
}
