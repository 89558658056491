import { useTranslation } from "react-i18next";
import SingleSelect from "./select/SingleSelect";

export default function SelectTraining({ formState, ...props }) {
  const { t: translate } = useTranslation("professional");

  const psychoTherapyTrainings = [
    {
      label: translate(
        "selecttraining.therapytraining.0",
        "Relatie- en Gezinstherapie (VUB in samenwerking met VZW rapunzel)",
      ),
      value: "0",
    },
    {
      label: translate(
        "selecttraining.therapytraining.1",
        "Partnerrelatie-, Gezins- en Systeempsychotherapie (UGent)",
      ),
      value: "1",
    },
    {
      label: translate(
        "selecttraining.therapytraining.2",
        "Gedragstherapie Volwassenen (UGent)",
      ),
      value: "2",
    },
    {
      label: translate(
        "selecttraining.therapytraining.3",
        "Psychoanalytische Therapie Volwassenen (UGent)",
      ),
      value: "3",
    },
    {
      label: translate(
        "selecttraining.therapytraining.4",
        "Cliëntgerichte Psychotherapie Volwassenen (KULeuven)",
      ),
      value: "4",
    },
    {
      label: translate(
        "selecttraining.therapytraining.5",
        "Gestaltpsychotherapie (VIVES Zuid in samenwerking met het IVC (Kortrijk))",
      ),
      value: "5",
    },
    {
      label: translate(
        "selecttraining.therapytraining.6",
        "Psychoanalytische Psychotherapie (KULeuven)",
      ),
      value: "6",
    },
    {
      label: translate(
        "selecttraining.therapytraining.7",
        "Psychotherapie: optie volwassenen (UAntwerpen)",
      ),
      value: "7",
    },
    {
      label: translate(
        "selecttraining.therapytraining.8",
        "Relatie-, Gezins- en Systeempsychotherapie (KULeuven)",
      ),
      value: "8",
    },
    {
      label: translate(
        "selecttraining.therapytraining.9",
        "Systeemtheoretische Psychotherapie (UAntwerpen in samenwerking met de Interactie-Academie)",
      ),
      value: "9",
    },
    {
      label: translate(
        "selecttraining.therapytraining.10",
        "Oplossingsgerichte Cognitieve Systeempsychotherapie (HoWest)",
      ),
      value: "10",
    },
    {
      label: translate(
        "selecttraining.therapytraining.11",
        "Therapie-opleiding (IPRR vzw in samenwerking met Thomas More)",
      ),
      value: "11",
    },
    {
      label: translate(
        "selecttraining.therapytraining.12",
        "Europees Certificaat voor Psychotherapie",
      ),
      value: "12",
    },
    {
      label: translate(
        "selecttraining.therapytraining.13",
        "Certificaat, Focus on Emotion VZW",
      ),
      value: "13",
    },
    {
      label: translate(
        "selecttraining.therapytraining.14",
        "Systeeminterventie en Gezinstherapie (Centrum voor de Studie van het Gezin)",
      ),
      value: "14",
    },
    {
      label: translate(
        "selecttraining.therapytraining.15",
        "Contextuele en Systemische Counseling (Artevelde Hogeschool)",
      ),
      value: "15",
    },
    {
      label: translate(
        "selecttraining.therapytraining.16",
        "Interactionele Vormgeving (Educatieve Academie)",
      ),
      value: "16",
    },
  ];

  return (
    <SingleSelect
      options={psychoTherapyTrainings}
      {...formState.getInputProps("psychoTherapyTraining")}
      {...props}
    />
  );
}
