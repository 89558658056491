import { createContext } from "react";
import { IClientsForProfessionalsContext } from "./clientsForProfessionals";

export const defaulClientsForProfessionalsValues: IClientsForProfessionalsContext =
  {
    clearSelectedClient: () => {},
    clients: [],
    loadingClients: false,
    modalVisible: false,
    refetchClients: () => {},
    selectedClient: undefined,
    setClient: () => {},
    toggleModal: () => {},
  };

const ClientsForProfessionalsContext =
  createContext<IClientsForProfessionalsContext>(
    defaulClientsForProfessionalsValues,
  );

export default ClientsForProfessionalsContext;
