import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EventIcon from "@mui/icons-material/Event";
import Clients from "../../icons/clients";

import { RouteNames } from "../../routes/routeNames";
import { I18Namespaces } from "../../language/I18Namespaces";
import SidebarNavigationItem from "./SidebarNavigationItem";

const PREFIX = "AdminSidebarNavigation";

const classes = {
  actions: `${PREFIX}-actions`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.actions}`]: {
    alignItems: "flex-start",
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(1),
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      background: theme.palette.white.main,
      bottom: 0,
      boxShadow: "0 -1px 7px 0 rgba(181,181,181,0.50)",
      left: 0,
      padding: 0,
      position: "fixed",
      right: 0,
      width: "100%",
      zIndex: 2,
    },
  },

  [`& .${classes.list}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    padding: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "row nowrap",
      margin: 0,
    },
  },
}));

const AdminSidebarNavigation = () => {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  return (
    <Root className={classes.actions}>
      <ul className={classes.list}>
        <SidebarNavigationItem
          exact={false}
          href={RouteNames.Admin.Dashboard.path}
          icon={<DashboardIcon />}
          id="test_sidebar_button_admin"
          label={translate("common:admin-dashboard", "Admin Dashboard")}
        />
        <SidebarNavigationItem
          exact={false}
          href={RouteNames.Home.Availability.path}
          icon={<EventIcon />}
          id="test_sidebar_button_availability"
          label={translate("common:availability", "Beschikbaarheid")}
        />
        <SidebarNavigationItem
          exact={false}
          href={RouteNames.Home.Consultations.path}
          icon={<RecentActorsIcon />}
          id="test_sidebar_button_consulltations"
          label={translate("common:consultations", "Consultaties")}
        />
        <SidebarNavigationItem
          exact={false}
          href={RouteNames.Home.Clients.path}
          icon={<Clients />}
          id="test_sidebar_button_clients"
          label={translate("common:clients", "Cliënteel")}
        />
        <SidebarNavigationItem
          exact={false}
          href={RouteNames.Home.Colleagues.path}
          icon={<FaceOutlinedIcon />}
          id="test_sidebar_button_colleagues"
          label={translate("common:colleagues", "Collega's")}
        />
      </ul>
    </Root>
  );
};

export default AdminSidebarNavigation;
