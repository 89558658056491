import { graphql } from "../__generated__";

export const QUERY_GET_QUESTION_BY_ID = graphql(`
  query getQuestionById($id: Int!) {
    getQuestionById(id: $id) {
      ...QualityQuestion
    }
  }
`);

export const QUERY_GET_QUESTION_BY_ENTRYPOINT = graphql(`
  query questionForUserAtEntryPoint($input: EntryPointsInput!) {
    questionForUserAtEntryPoint(input: $input) {
      ...QualityQuestion
    }
  }
`);
