import { Box, useTheme } from "@mui/material";
import { ReactComponent as BloomCircleVariable } from "../Common/BloomCircleVariable.svg";

const Divider = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        justifyContent: "center",
        paddingY: "30px",
      }}
    >
      <BloomCircleVariable
        fill={theme.palette.bloom.main}
        height="34px"
        width="36px"
      />
      <BloomCircleVariable
        fill={theme.palette.bloom.main}
        height="34px"
        width="36px"
      />
      <BloomCircleVariable
        fill={theme.palette.bloom.main}
        height="34px"
        width="36px"
      />
    </Box>
  );
};

export default Divider;
