import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
// import Avatar from '@mui/material/Avatar';
import { useTranslation } from "react-i18next";
import ButtonWithStatus from "../../ui/buttons/ButtonWithStatus";
import DeprecatedAvatar from "../../ui/DeprecatedAvatar";

import { UserRoles } from "../../../data-model/types/User";
import Human, { HumanStatus } from "../../../data-model/types/human/Human";
import Professional from "../../../data-model/types/professional/Professional";
import { I18Namespaces } from "../../language/I18Namespaces";
import { DateFormat } from "../../../data-model/Date";
import { getMomentLocale } from "../../language/languagesUtil";

const PREFIX = "Client";

const classes = {
  fullName: `${PREFIX}-fullName`,
  text: `${PREFIX}-text`,
  time: `${PREFIX}-time`,
};

const StyledButtonWithStatus = styled(ButtonWithStatus)(({ theme }) => ({
  [`& .${classes.text}`]: {
    paddingLeft: 15,
    [theme.breakpoints.down(340)]: {
      paddingLeft: 20,
    },
  },

  [`& .${classes.fullName}`]: {
    fontSize: theme.typography.sizes.xl,
  },

  [`& .${classes.time}`]: {
    fontSize: theme.typography.sizes.normal,
  },
}));

interface IClient {
  client: Professional | Human;
  handleClick?: (id: number) => void;
  isOpen: boolean;
  roleType: UserRoles;
  status: HumanStatus | undefined;
}

const Client = ({
  status,
  roleType = UserRoles.PROFESSIONAL,
  isOpen,
  client,
  handleClick,
}: IClient) => {
  const { t: translate, i18n } = useTranslation<I18Namespaces>(["human"]);

  return (
    <StyledButtonWithStatus
      isOpen={isOpen}
      key={client.getID()}
      onClick={() => {
        if (handleClick) handleClick(client.getID());
      }}
      requestedBy={
        roleType === UserRoles.PROFESSIONAL ? "PROFESSIONAL" : "HUMAN"
      }
      roleType={roleType}
      status={status}
    >
      <Grid container>
        <Grid item md={1} sm={1} xs={1}>
          <DeprecatedAvatar size={30} user={client} />
        </Grid>
        <Grid className={classes.text} item md={11} sm={11} xs={11}>
          {status === HumanStatus.CURRENT ? (
            <Typography align="left" className={classes.fullName} noWrap>
              {client.getScreenName()}
            </Typography>
          ) : (
            client instanceof Human && (
              <Typography align="left" className={classes.time}>
                {translate("human:description", {
                  date: client.getCreatedAt(
                    DateFormat.FULL,
                    getMomentLocale(i18n.language),
                  ),
                  defaultValue: "{{email}} werd uitgenodigd op {{date}}.",
                  email: client.getEmail().prettyPrint(),
                })}
              </Typography>
            )
          )}
        </Grid>
      </Grid>
    </StyledButtonWithStatus>
  );
};

export default Client;
