import moment from "moment";
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class BirthDate extends DataType<
  Date | null,
  DataTypePaths.Profile.BirthDate
> {
  protected static path = "birthDate";
  protected static defaultValue = null;

  protected type: any = BirthDate;

  constructor(value?: string, fromGQL = false) {
    super(value ? new Date(value) : null, fromGQL);
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {any} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    //Birthdate is optional
    return [
      {
        message: translate(
          "birthDate.beforenow",
          "Je geboortejaar moet in het verleden liggen.",
        ),
        path: this.getPath(),
        validate: (birthDate: Date): boolean =>
          birthDate ? moment(birthDate).isBefore(moment()) : true,
      },
      {
        message: translate(
          "birthDate.min18",
          "Je moet minstens 18 jaar oud zijn.",
        ),
        path: this.getPath(),
        validate: (birthDate: Date): boolean =>
          birthDate
            ? moment(birthDate).isBefore(moment().subtract(19, "years"))
            : true,
      },
      {
        message: translate(
          "birthDate.max100",
          "Je mag niet ouder dan 100 jaar zijn.",
        ),
        path: this.getPath(),
        validate: (birthDate: Date): boolean =>
          birthDate
            ? moment(birthDate).isAfter(moment().subtract(100, "years"))
            : true,
      },
    ];
  }

  getAsFormStateValue(): Partial<
    Record<DataTypePaths.Profile.BirthDate, Date | null>
  > {
    return { birthDate: this.value };
  }
}
