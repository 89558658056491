import React from "react";
import { useTranslation } from "react-i18next";
import { components, MultiValueGenericProps } from "react-select";

const TranslatedMultiValueLabel = (props: MultiValueGenericProps) => {
  const { t } = useTranslation();

  return (
    <components.MultiValueLabel {...props}>
      {t(props.children as string)}
    </components.MultiValueLabel>
  );
};

export default TranslatedMultiValueLabel;
