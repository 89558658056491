/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RegistrationContext from "../context/RegistrationContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import { SUCCESS_STEP } from "../context/registrationSteps";
import { I18Namespaces } from "../../../language/I18Namespaces";

const SuccesStep = () => {
  const { t: translate } = useTranslation<I18Namespaces>(["client", "common"]);

  const { next, setStep, previous } = useContext(RegistrationContext);

  useEffect(() => setStep(SUCCESS_STEP), []);

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={translate("client:registration.success.description")}
        fullCopyright
        headline={translate("human:registration.title")}
        labelNext={translate("client:register.button.next")}
        onClickBack={previous}
        onClickNext={() => next()}
        subhead={translate("common:congratz")}
        subheadicon={<CheckCircleIcon />}
      />
    </OnboardingContainer>
  );
};

export default SuccesStep;
