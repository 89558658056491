import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { WaveBox } from "wave-box";
import LanguageDropdown from "../../../ui/LanguageDropdown";

const NavigationBar = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const bloomUpLogoUrl =
    "https://bloomup-production-www.s3.eu-central-1.amazonaws.com/Logo-no-tagline-white.svg";

  return (
    <WaveBox background="#609D76" fillBackground topWave={false}>
      <Box
        sx={{ display: "flex", justifyContent: "center", padding: "0 1rem" }}
      >
        <Box
          sx={{
            color: theme.palette.white.main,
            display: "flex",
            height: "50px",
            justifyContent: "space-between",
            maxWidth: "1300px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Box
              alt="logo"
              component="img"
              src={bloomUpLogoUrl}
              sx={{
                "@media screen and(max-width: 500px)": {
                  maxHeight: "25px",
                },
                height: "auto",
                maxHeight: "50px",
                maxWidth: "100%",
                paddingTop: "8px",
              }}
            />

            <Typography
              sx={{
                "@media screen and (max-width: 500px)": {
                  display: "none",
                },
                fontFamily: "Mulish",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                padding: "0 1rem",
              }}
            >
              {t("match:header.title")}
            </Typography>
          </Box>

          <Box
            sx={() => ({
              alignItems: "center",
              color: "white",
              display: "flex",
              flexDirection: "row",
              marginTop: "6px",
            })}
          >
            <LanguageDropdown
              textColor={theme.palette.white.main}
              updateMatchedHuman={!!localStorage.getItem("matchingToken")}
            />
          </Box>
        </Box>
      </Box>
    </WaveBox>
  );
};

export default NavigationBar;
