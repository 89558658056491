import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import QualityQuestionFetcher from "../quality-questions/QualityQuestion";
import ThankYouPage from "../quality-questions/ThankYou";
import { RouteNames } from "./routeNames";

const QualityQuestionRoutes = () => {
  return (
    <Routes>
      <Route
        // /quality/question/
        element={
          <Routes>
            <Route
              element={<QualityQuestionFetcher />}
              path={`${RouteNames.QualityQuestions.Question.EntryPointOrId.name}`}
            ></Route>
            <Route
              element={<ThankYouPage />}
              path={`${RouteNames.QualityQuestions.Question.ThankYou.name}`}
            ></Route>
          </Routes>
        }
        path={`${RouteNames.QualityQuestions.Question.name}/*`}
      />
    </Routes>
  );
};

export default memo(QualityQuestionRoutes);
