/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateConsultation from "../../../api/consultations/hooks/useCreateConsultation";
import useFetchOneProfessional from "../../../api/professionals/hooks/useFetchOneProfessional";
import Consultation, {
  ConsultationTypes,
} from "../../../data-model/types/consultation/Consultation";
import ConsultationStatus from "../../../data-model/types/consultation/ConsultationStatus";
import Human from "../../../data-model/types/human/Human";
import Professional from "../../../data-model/types/professional/Professional";
import { UserRoles } from "../../../data-model/types/User";
import { IConsultationsOverviewContext } from "../../consultations/context/consultations";
import ConsultationsOverviewContext from "../../consultations/context/ConsultationsOverviewContext";
import LoadingPage from "../../layout/LoadingPage";
import { IAuthContext } from "../../providers/auth/auth";
import AuthContext from "../../providers/auth/AuthContext";
import { IToastContext } from "../../providers/toast/toast";
import ToastContext from "../../providers/toast/ToastContext";
import { IClientsForProfessionalsContext } from "../professional-clients/context/clientsForProfessionals";
import ClientsForProfessionalsContext from "../professional-clients/context/ClientsForProfessionalsContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import AddConsultationPage from "./AddConsultationPage";
import useAddConsultationFormState from "./useAddConsultationFormState";

export default function AddConsultationPageWithState() {
  const { professional, loading } = useFetchOneProfessional();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const { setToast } = useContext<IToastContext>(ToastContext);
  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const {
    setSelectedConsultation,
    closeAddConsultation,
    consultations,
    loading: loadingConsultations,
    refetchAll: refetchConsultations,
  } = useContext<IConsultationsOverviewContext>(ConsultationsOverviewContext);

  const { clients, loadingClients } =
    useContext<IClientsForProfessionalsContext>(ClientsForProfessionalsContext);

  const formState = useAddConsultationFormState({
    consultations,
  });

  const { createConsultation } = useCreateConsultation();

  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Errors]);

  useEffect(() => {
    if (!loading && professional) {
      const duration = professional.getConsultationDuration().getValue();

      formState.setValue("duration", duration);

      formState.setValue(
        "pricePerMinute",
        professional.getConsultationRate().getValue() /
          professional.getConsultationDuration().getValue(),
      );
      formState.setValue(
        "timeTo",
        formState.getValue("timeFrom").clone().add(duration, "minutes"),
      );
      formState.setValue(
        "price",
        professional.getConsultationRate().getValue(),
      );
    }
  }, [loading, professional]);

  const onSubmit = async () => {
    setSubmitting(true);
    //TODO: this is almost the same function as used in RequestConsultationStateProvider.

    if (formState.validate()) {
      const { timeFrom, timeTo, user, type } = formState.values;

      const newConsultation = new Consultation();

      if (
        currentUser &&
        (currentUser.getRole() === UserRoles.PROFESSIONAL ||
          currentUser.getRole() === UserRoles.ADMIN)
      ) {
        newConsultation
          .assignProfessional(currentUser as Professional)
          .assignClient(
            new Human({ email: "", id: user.value, uuid: "" }, true),
          )
          .setStartDate(timeFrom)
          .setEndDate(timeTo)
          .setFromMatch(false)
          .setStatus(new ConsultationStatus("REQUESTED"))
          .setConsultationType(type.value);

        try {
          const consultation = await createConsultation(
            newConsultation,
            [ConsultationTypes.INTRO, ConsultationTypes.CHECKIN].includes(
              type.value,
            ),
            formState.getValue("price"),
          );

          if (consultation) {
            formState.setValue(
              "link",
              `consultation/${consultation.getUUID()}`,
            );

            if (refetchConsultations) refetchConsultations();

            setSubmitting(false);
            closeAddConsultation();
            setSelectedConsultation(consultation);
          } else {
            throw new Error();
          }
        } catch (error: any) {
          setSubmitting(false);

          if (error.message === "create_consultation_unpaid_consultations") {
            setToast({
              message: t("create_consultation_unpaid_consultations_prof", {
                name: user.label,
              }),
              severity: "error",
            });

            return;
          }

          setToast({
            message: "Sorry, something went wrong. Please try again later.",
            severity: "error",
          });
        }
      }
    }
  };

  if (loadingClients || loadingConsultations || loading || !professional) {
    return <LoadingPage />;
  }

  return (
    <AddConsultationPage
      clients={clients}
      formState={formState}
      onSubmit={onSubmit}
      professional={professional}
      submitting={submitting}
    />
  );
}
