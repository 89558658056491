import { useCallback } from "react";
import type { TFunction } from "i18next";
import { useTranslation } from "react-i18next";
import { IFormState } from "../hooks/useFormState";
import InlineEditableSingleSelect from "../ui/edit/InlineEditableSingleSelect";
import {
  ISelectObject,
  isSelectObject,
} from "../ui/form/select/BaseSimpleSelect";
import PreferredLanguage from "../../data-model/types/profile/PreferredLanguage";
import Human from "../../data-model/types/human/Human";
import Professional from "../../data-model/types/professional/Professional";

interface InlineEditablePreferredLanguageSelectorProps {
  formState: IFormState;
  onSave: (preferredLanguage: ISelectObject<PreferredLanguage>) => void;
  person: Professional | Human;
  translate: TFunction;
}

export default function InlineEditablePreferredLanguageSelector({
  person,
  formState,
  translate,
  onSave,
}: InlineEditablePreferredLanguageSelectorProps) {
  const { i18n } = useTranslation();
  const localOnChange = useCallback(
    (selectedValue: ISelectObject) => {
      // We should not have to do this, but I'm adding it to prevent errors in non-typed formStates
      const selectedLanguage: string = isSelectObject(selectedValue)
        ? selectedValue.value
        : selectedValue;

      i18n.changeLanguage(selectedLanguage);
    },
    [i18n],
  );

  const { onChange, value } = formState.getInputProps(
    PreferredLanguage.getPath(),
  );

  return (
    <InlineEditableSingleSelect<PreferredLanguage>
      cancelBtnTestId="test_profile_language_select_cancel"
      dataObject={person.getPreferredLanguage()}
      editBtnTestId="test_profile_language_select_edit"
      formState={formState}
      label={translate("common:preferences.preferred_language", "Mijn taal")}
      labelTestId="test_profile_language_label"
      onChange={(changedValue) => {
        localOnChange(changedValue);
        onChange(changedValue);
      }}
      onDiscard={(intialValue) => {
        localOnChange(intialValue);
      }}
      onSave={() => onSave(value)}
      placeholderText={translate(
        "common:preferences.preferred_language.mandatory",
        "Geef je voorkeurtaal aan.",
      )}
      saveBtnTestId="test_profile_language_select_save"
      selectTestId="test_profile_language_select"
      translateFunction={translate}
    />
  );
}
