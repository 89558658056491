import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import useFormState from "../../hooks/useFormState";
import createFormValidation from "../../../utils/forms/createFormValidation";
import EmailDataType from "../../../data-model/types/profile/Email";
import PhoneDataType from "../../../data-model/types/profile/Phone";
import FirstNameDataType from "../../../data-model/types/profile/FirstName";
import LastNameDataType from "../../../data-model/types/profile/LastName";
import Website from "../../../data-model/types/professional/Website";
import { I18Namespaces } from "../../language/I18Namespaces";
import ConsultationLanguages from "../../../data-model/types/professional/ConsultationLanguages";

const INITIAL_VALUES = {
  ...EmailDataType.getInitialValue(),
  ...FirstNameDataType.getInitialValue(),
  ...LastNameDataType.getInitialValue(),
  ...PhoneDataType.getInitialValue(),
  ...Website.getInitialValue(),
  ...ConsultationLanguages.getInitialValue(),
  professionalType: "",
};

export default function useRegisterFormState(
  initialValues?: any,
  options?: any,
) {
  const { t } = useTranslation<I18Namespaces>(["validation"]);

  const validation = useMemo(
    () =>
      createFormValidation([
        ...EmailDataType.getFormValidation(t),
        ...FirstNameDataType.getFormValidation(t),
        ...LastNameDataType.getFormValidation(t),
        ...PhoneDataType.getFormValidation(t),
        ...ConsultationLanguages.getFormValidation(t),

        {
          message: t("validation:psytype.mandatory"),
          path: "professionalType",
          validate: (professionalType) => professionalType !== "",
        },
        ...Website.getFormValidation(t),
      ]),
    [t],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
