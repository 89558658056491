import { useContext } from "react";
import { useTranslation } from "react-i18next";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Grid from "@mui/material/Grid";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import MultiSelect from "../../../ui/form/select/MultiSelect";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import Label from "../../../ui/form/FormLabel";
import Therapies from "../../../../data-model/types/professional/Therapies";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";

/**
 * Shows the therapy step in during the Onboarding
 * of a psychologist. It asks which forms of therapy the
 * psy can work with.
 */

export default function TherapyStep() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Common,
  ]);

  const { next, previous, step, totalSteps, formState } = useContext(
    ProfessionalOnboardingContext,
  );

  const error = formState.testForErrors(Therapies.getPath());

  const selectDefaultValue = () => {
    const selectedItems = new Therapies();

    selectedItems.setValue(formState.getValue(Therapies.getPath()));

    return selectedItems.getSelectedOptions();
  };

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je Profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate(
          "professional:onboarding.therapytype.title",
          "Therapievormen",
        )}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Grid container spacing={2}>
          <Label
            label={translate(
              "professional:onboarding.therapytype.description",
              "Selecteer de therapievormen die je beheerst. (Optioneel)",
            )}
          />
          <MultiSelect
            closeMenuOnSelect
            options={new Therapies().getSelectOptions()}
            placeholder={translate(
              "professional:onboarding.therapytype.placeholder",
              "Selecteer maximum 5 therapievormen...",
            )}
            value={selectDefaultValue()}
            {...formState.getInputProps(Therapies.getPath())}
            selectTestId={`test_onboarding_professional_therapy_select`}
          />
        </Grid>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
