export const zipCodes = [
  {
    label: "Bruxelles",
    lat: 50.8465573,
    lng: 4.351697,
    value: "1000",
  },
  {
    label: "Laeken",
    lat: 50.883392,
    lng: 4.3487134,
    value: "1020",
  },
  {
    label: "Schaerbeek",
    lat: 50.8676041,
    lng: 4.3737121,
    value: "1030",
  },
  {
    label: "Etterbeek",
    lat: 50.8368511,
    lng: 4.3895104,
    value: "1040",
  },
  {
    label: "Ixelles",
    lat: 50.8222854,
    lng: 4.3815707,
    value: "1050",
  },
  {
    label: "Saint-Gilles",
    lat: 50.8267409,
    lng: 4.345668,
    value: "1060",
  },
  {
    label: "Anderlecht",
    lat: 50.8381411,
    lng: 4.3123401,
    value: "1070",
  },
  {
    label: "Molenbeek-Saint-Jean",
    lat: 50.8543551,
    lng: 4.3227779,
    value: "1080",
  },
  {
    label: "Koekelberg",
    lat: 50.8622633,
    lng: 4.3257084,
    value: "1081",
  },
  {
    label: "Berchem-Sainte-Agathe",
    lat: 50.8639835,
    lng: 4.292702,
    value: "1082",
  },
  {
    label: "Ganshoren",
    lat: 50.8712396,
    lng: 4.3175103,
    value: "1083",
  },
  {
    label: "Jette",
    lat: 50.8777634,
    lng: 4.3260903,
    value: "1090",
  },
  {
    label: "Neder-Over-Heembeek",
    lat: 50.89779605,
    lng: 4.3904888684211,
    value: "1120",
  },
  {
    label: "Haren",
    lat: 50.8919661,
    lng: 4.4125708732431,
    value: "1130",
  },
  {
    label: "Evere",
    lat: 50.8704524,
    lng: 4.4021602,
    value: "1140",
  },
  {
    label: "Woluwe-Saint-Pierre",
    lat: 50.8292429,
    lng: 4.4432967,
    value: "1150",
  },
  {
    label: "Auderghem",
    lat: 50.8156571,
    lng: 4.4331389,
    value: "1160",
  },
  {
    label: "Watermael-Boitsfort",
    lat: 50.799394,
    lng: 4.4158177,
    value: "1170",
  },
  {
    label: "Uccle",
    lat: 50.8018201,
    lng: 4.3372348,
    value: "1180",
  },
  {
    label: "Forest",
    lat: 50.8091426,
    lng: 4.3177512,
    value: "1190",
  },
  {
    label: "Woluwe-Saint-Lambert",
    lat: 50.8466928,
    lng: 4.4284842,
    value: "1200",
  },
  {
    label: "Saint-Josse-Ten-Noode",
    lat: 50.8530739,
    lng: 4.3723364,
    value: "1210",
  },
  {
    label: "Limal",
    lat: 50.6930127,
    lng: 4.5738899,
    value: "1300",
  },
  {
    label: "Wavre",
    lat: 50.7164189,
    lng: 4.607744,
    value: "1300",
  },
  {
    label: "Bierges",
    lat: 50.7111796,
    lng: 4.5894025,
    value: "1301",
  },
  {
    label: "La Hulpe",
    lat: 50.7315053,
    lng: 4.4796542,
    value: "1310",
  },
  {
    label: "Glimes",
    lat: 50.6799948,
    lng: 4.8384018,
    value: "1315",
  },
  {
    label: "Incourt",
    lat: 50.6918004,
    lng: 4.798944,
    value: "1315",
  },
  {
    label: "Opprebais",
    lat: 50.681817,
    lng: 4.7957745,
    value: "1315",
  },
  {
    label: "Pi\u00e8trebais",
    lat: 50.7311108,
    lng: 4.7433685,
    value: "1315",
  },
  {
    label: "Roux-Miroir",
    lat: 50.7085122,
    lng: 4.7848246,
    value: "1315",
  },
  {
    label: "Beauvechain",
    lat: 50.7810287,
    lng: 4.7716774,
    value: "1320",
  },
  {
    label: "Hamme-Mille",
    lat: 50.7819118,
    lng: 4.7205562,
    value: "1320",
  },
  {
    label: "L'ecluse",
    lat: 50.7717493,
    lng: 4.8320079,
    value: "1320",
  },
  {
    label: "Nodebais",
    lat: 50.7725266,
    lng: 4.733891,
    value: "1320",
  },
  {
    label: "Tourinnes-La-Grosse",
    lat: 50.7777654,
    lng: 4.7472104,
    value: "1320",
  },
  {
    label: "Bonlez",
    lat: 50.7021372,
    lng: 4.6896145,
    value: "1325",
  },
  {
    label: "Chaumont-Gistoux",
    lat: 50.6839808,
    lng: 4.6974182,
    value: "1325",
  },
  {
    label: "Corroy-Le-Grand",
    lat: 50.6617107,
    lng: 4.6747213,
    value: "1325",
  },
  {
    label: "Dion-Valmont",
    lat: 50.7158849,
    lng: 4.6614812,
    value: "1325",
  },
  {
    label: "Longueville",
    lat: 50.7024404,
    lng: 4.7392389,
    value: "1325",
  },
  {
    label: "Rixensart",
    lat: 50.7133548,
    lng: 4.5272896887008,
    value: "1330",
  },
  {
    label: "Rosi\u00e8res",
    lat: 50.73713045,
    lng: 4.5463113019639,
    value: "1331",
  },
  {
    label: "Genval",
    lat: 50.72074495,
    lng: 4.4971389344121,
    value: "1332",
  },
  {
    label: "Ottignies",
    lat: 50.666357,
    lng: 4.5690502,
    value: "1340",
  },
  {
    label: "Ottignies-Louvain-La-Neuve",
    lat: 50.6726725,
    lng: 4.5804937701303,
    value: "1340",
  },
  {
    label: "C\u00e9roux-Mousty",
    lat: 50.6625713,
    lng: 4.5291742660159,
    value: "1341",
  },
  {
    label: "Limelette",
    lat: 50.6851551,
    lng: 4.5549022761211,
    value: "1342",
  },
  {
    label: "Louvain-La-Neuve",
    lat: 50.6741689,
    lng: 4.6141567317211,
    value: "1348",
  },
  {
    label: "Enines",
    lat: 50.6943193,
    lng: 4.9260633,
    value: "1350",
  },
  {
    label: "Folx-Les-Caves",
    lat: 50.6610719,
    lng: 4.9370984,
    value: "1350",
  },
  {
    label: "Jandrain-Jandrenouille",
    lat: 50.6740112,
    lng: 4.9792652,
    value: "1350",
  },
  {
    label: "Jauche",
    lat: 50.6816078,
    lng: 4.9552091,
    value: "1350",
  },
  {
    label: "Marilles",
    lat: 50.7072943,
    lng: 4.9529173,
    value: "1350",
  },
  {
    label: "Noduwez",
    lat: 50.7293197,
    lng: 4.9640083,
    value: "1350",
  },
  {
    label: "Orp-Jauche",
    lat: 50.7001001,
    lng: 4.9889872,
    value: "1350",
  },
  {
    label: "Orp-Le-Grand",
    lat: 50.7034875,
    lng: 4.9912852,
    value: "1350",
  },
  {
    label: "H\u00e9l\u00e9cine",
    lat: 50.7514034,
    lng: 4.9822395,
    value: "1357",
  },
  {
    label: "Linsmeau",
    lat: 50.7322517,
    lng: 5.0030144,
    value: "1357",
  },
  {
    label: "Neerheylissem",
    lat: 50.7567681,
    lng: 4.9890154,
    value: "1357",
  },
  {
    label: "Opheylissem",
    lat: 50.7478407,
    lng: 4.9780806,
    value: "1357",
  },
  {
    label: "Mal\u00e8ves-Sainte-Marie-Wastines",
    lat: 50.6564717,
    lng: 4.7801225,
    value: "1360",
  },
  {
    label: "Orbais",
    lat: 50.6376269,
    lng: 4.7631073,
    value: "1360",
  },
  {
    label: "Perwez",
    lat: 50.6234469,
    lng: 4.8134719,
    value: "1360",
  },
  {
    label: "Thorembais-Les-B\u00e9guines",
    lat: 50.6584082,
    lng: 4.8183242,
    value: "1360",
  },
  {
    label: "Thorembais-Saint-Trond",
    lat: 50.6357579,
    lng: 4.7839333,
    value: "1360",
  },
  {
    label: "Autre-Eglise",
    lat: 50.6631583,
    lng: 4.9249295,
    value: "1367",
  },
  {
    label: "Bomal",
    lat: 50.6681389,
    lng: 4.8735246,
    value: "1367",
  },
  {
    label: "G\u00e9rompont",
    lat: 50.6467516,
    lng: 4.8807352,
    value: "1367",
  },
  {
    label: "Grand-Rosi\u00e8re-Hottomont",
    lat: 50.6316098,
    lng: 4.8674622,
    value: "1367",
  },
  {
    label: "Huppaye",
    lat: 50.6927557,
    lng: 4.8921703,
    value: "1367",
  },
  {
    label: "Mont-Saint-Andr\u00e9",
    lat: 50.6550272,
    lng: 4.8635811,
    value: "1367",
  },
  {
    label: "Ramillies",
    lat: 50.6366892,
    lng: 4.9148994,
    value: "1367",
  },
  {
    label: "Dongelberg",
    lat: 50.7016251,
    lng: 4.8203333,
    value: "1370",
  },
  {
    label: "Jauchelette",
    lat: 50.6866092,
    lng: 4.8435069,
    value: "1370",
  },
  {
    label: "Jodoigne",
    lat: 50.7218206,
    lng: 4.8709616,
    value: "1370",
  },
  {
    label: "Jodoigne-Souveraine",
    lat: 50.7081169,
    lng: 4.8405644,
    value: "1370",
  },
  {
    label: "Lathuy",
    lat: 50.7230097,
    lng: 4.824622,
    value: "1370",
  },
  {
    label: "M\u00e9lin",
    lat: 50.7400918,
    lng: 4.8286208,
    value: "1370",
  },
  {
    label: "Pi\u00e9train",
    lat: 50.7255018,
    lng: 4.9175477,
    value: "1370",
  },
  {
    label: "Saint-Jean-Geest",
    lat: 50.7397177,
    lng: 4.8957659,
    value: "1370",
  },
  {
    label: "Saint-Remy-Geest",
    lat: 50.7471089,
    lng: 4.8583707,
    value: "1370",
  },
  {
    label: "Z\u00e9trud-Lumay",
    lat: 50.7592057,
    lng: 4.8838518,
    value: "1370",
  },
  {
    label: "Couture-Saint-Germain",
    lat: 50.6628104,
    lng: 4.4825691512066,
    value: "1380",
  },
  {
    label: "Lasne",
    lat: 50.6860037,
    lng: 4.4844157,
    value: "1380",
  },
  {
    label: "Lasne-Chapelle-Saint-Lambert",
    lat: 50.6927451,
    lng: 4.5004885,
    value: "1380",
  },
  {
    label: "Maransart",
    lat: 50.6561328,
    lng: 4.4559942489341,
    value: "1380",
  },
  {
    label: "Ohain",
    lat: 50.695114,
    lng: 4.4505534162388,
    value: "1380",
  },
  {
    label: "Plancenoit",
    lat: 50.66254755,
    lng: 4.4212124182736,
    value: "1380",
  },
  {
    label: "Archennes",
    lat: 50.7516077,
    lng: 4.6702286,
    value: "1390",
  },
  {
    label: "Biez",
    lat: 50.7267076,
    lng: 4.7033973,
    value: "1390",
  },
  {
    label: "Bossut-Gottechain",
    lat: 50.7616193,
    lng: 4.6990692,
    value: "1390",
  },
  {
    label: "Grez-Doiceau",
    lat: 50.7386182,
    lng: 4.6962324,
    value: "1390",
  },
  {
    label: "Nethen",
    lat: 50.78382875,
    lng: 4.6736552349571,
    value: "1390",
  },
  {
    label: "Monstreux",
    lat: 50.5931607,
    lng: 4.2860216341575,
    value: "1400",
  },
  {
    label: "Nivelles",
    lat: 50.58916295,
    lng: 4.3319091758631,
    value: "1400",
  },
  {
    label: "Baulers",
    lat: 50.6172472,
    lng: 4.3586482570372,
    value: "1401",
  },
  {
    label: "Thines",
    lat: 50.58678735,
    lng: 4.3825954093731,
    value: "1402",
  },
  {
    label: "Bornival",
    lat: 50.6048658,
    lng: 4.2649262209555,
    value: "1404",
  },
  {
    label: "Waterloo",
    lat: 50.7173563,
    lng: 4.3978047,
    value: "1410",
  },
  {
    label: "Braine-L'alleud",
    lat: 50.6940942,
    lng: 4.3548145440335,
    value: "1420",
  },
  {
    label: "Ophain-Bois-Seigneur-Isaac",
    lat: 50.65630215,
    lng: 4.3507902353934,
    value: "1421",
  },
  {
    label: "Lillois-Witterz\u00e9e",
    lat: 50.6483743,
    lng: 4.3670582,
    value: "1428",
  },
  {
    label: "Bierghes",
    lat: 50.69657845,
    lng: 4.1227257983451,
    value: "1430",
  },
  {
    label: "Quenast",
    lat: 50.6719076,
    lng: 4.1627021400155,
    value: "1430",
  },
  {
    label: "Rebecq",
    lat: 50.6645894,
    lng: 4.1355466,
    value: "1430",
  },
  {
    label: "Rebecq-Rognon",
    lat: 50.67355825,
    lng: 4.1350275503194,
    value: "1430",
  },
  {
    label: "Corbais",
    lat: 50.6491686,
    lng: 4.6408286063936,
    value: "1435",
  },
  {
    label: "H\u00e9villers",
    lat: 50.6220893,
    lng: 4.6167772,
    value: "1435",
  },
  {
    label: "Mont-Saint-Guibert",
    lat: 50.6365628,
    lng: 4.6127019,
    value: "1435",
  },
  {
    label: "Braine-Le-Ch\u00e2teau",
    lat: 50.68088165,
    lng: 4.2666690726132,
    value: "1440",
  },
  {
    label: "Wauthier-Braine",
    lat: 50.68083185,
    lng: 4.3133036337455,
    value: "1440",
  },
  {
    label: "Chastre",
    lat: 50.6080835,
    lng: 4.636678,
    value: "1450",
  },
  {
    label: "Chastre-Villeroux-Blanmont",
    lat: 50.6106296,
    lng: 4.6377941108725,
    value: "1450",
  },
  {
    label: "Cortil-Noirmont",
    lat: 50.5908074,
    lng: 4.6406799,
    value: "1450",
  },
  {
    label: "Gentinnes",
    lat: 50.57939665,
    lng: 4.5927221009772,
    value: "1450",
  },
  {
    label: "Saint-G\u00e9ry",
    lat: 50.57660545,
    lng: 4.6202848419069,
    value: "1450",
  },
  {
    label: "Nil-Saint-Vincent-Saint-Martin",
    lat: 50.6407147,
    lng: 4.6743285,
    value: "1457",
  },
  {
    label: "Tourinnes-Saint-Lambert",
    lat: 50.6395479,
    lng: 4.7220185,
    value: "1457",
  },
  {
    label: "Walhain",
    lat: 50.6182838,
    lng: 4.6952724,
    value: "1457",
  },
  {
    label: "Walhain-Saint-Paul",
    lat: 50.6167794,
    lng: 4.6952241,
    value: "1457",
  },
  {
    label: "Ittre",
    lat: 50.6463367,
    lng: 4.2611675641255,
    value: "1460",
  },
  {
    label: "Virginal-Samme",
    lat: 50.6413403,
    lng: 4.2199759440369,
    value: "1460",
  },
  {
    label: "Haut-Ittre",
    lat: 50.6488039,
    lng: 4.2964719513591,
    value: "1461",
  },
  {
    label: "Baisy-Thy",
    lat: 50.6040225,
    lng: 4.4880778189719,
    value: "1470",
  },
  {
    label: "Bousval",
    lat: 50.63205335,
    lng: 4.5192034141632,
    value: "1470",
  },
  {
    label: "Genappe",
    lat: 50.60800575,
    lng: 4.4479459851741,
    value: "1470",
  },
  {
    label: "Loupoigne",
    lat: 50.5929226,
    lng: 4.4319644959116,
    value: "1471",
  },
  {
    label: "Vieux-Genappe",
    lat: 50.62902485,
    lng: 4.4015030981612,
    value: "1472",
  },
  {
    label: "Glabais",
    lat: 50.63691535,
    lng: 4.4464886939844,
    value: "1473",
  },
  {
    label: "Ways",
    lat: 50.6269861,
    lng: 4.4729429472611,
    value: "1474",
  },
  {
    label: "Houtain-Le-Val",
    lat: 50.5762935,
    lng: 4.4110339517433,
    value: "1476",
  },
  {
    label: "Clabecq",
    lat: 50.6893737,
    lng: 4.2214131,
    value: "1480",
  },
  {
    label: "Oisquercq",
    lat: 50.6700726,
    lng: 4.2167874,
    value: "1480",
  },
  {
    label: "Saintes",
    lat: 50.70737205,
    lng: 4.1600612857846,
    value: "1480",
  },
  {
    label: "Tubize",
    lat: 50.69302,
    lng: 4.204696,
    value: "1480",
  },
  {
    label: "Court-Saint-Etienne",
    lat: 50.6443208,
    lng: 4.5685766,
    value: "1490",
  },
  {
    label: "Marbais",
    lat: 50.55047955,
    lng: 4.5380975970878,
    value: "1495",
  },
  {
    label: "Mellery",
    lat: 50.5817537,
    lng: 4.5691118,
    value: "1495",
  },
  {
    label: "Sart-Dames-Avelines",
    lat: 50.554167,
    lng: 4.4895548874612,
    value: "1495",
  },
  {
    label: "Tilly",
    lat: 50.57640235,
    lng: 4.5522187220517,
    value: "1495",
  },
  {
    label: "Villers-La-Ville",
    lat: 50.5640258,
    lng: 4.516035801462,
    value: "1495",
  },
  {
    label: "Halle",
    lat: 50.7360524,
    lng: 4.2374349,
    value: "1500",
  },
  {
    label: "Buizingen",
    lat: 50.73793415,
    lng: 4.2605080818496,
    value: "1501",
  },
  {
    label: "Lembeek",
    lat: 50.7092954,
    lng: 4.2143430588907,
    value: "1502",
  },
  {
    label: "Herfelingen",
    lat: 50.747729,
    lng: 4.0974868,
    value: "1540",
  },
  {
    label: "Herne",
    lat: 50.72329555,
    lng: 4.0260115663497,
    value: "1540",
  },
  {
    label: "Sint-Pieters-Kapelle",
    lat: 50.70721955,
    lng: 3.9985766720315,
    value: "1541",
  },
  {
    label: "Bi\u00e9v\u00e8ne",
    lat: 50.7164481,
    lng: 3.943147,
    value: "1547",
  },
  {
    label: "Hoeilaart",
    lat: 50.7674846,
    lng: 4.474407,
    value: "1560",
  },
  {
    label: "Galmaarden",
    lat: 50.7527192,
    lng: 3.9709983,
    value: "1570",
  },
  {
    label: "Tollembeek",
    lat: 50.7430853,
    lng: 4.004789341376,
    value: "1570",
  },
  {
    label: "Vollezele",
    lat: 50.76088,
    lng: 4.0247359,
    value: "1570",
  },
  {
    label: "Oudenaken",
    lat: 50.7804875,
    lng: 4.1958992,
    value: "1600",
  },
  {
    label: "Sint-Laureins-Berchem",
    lat: 50.7877313,
    lng: 4.1993838,
    value: "1600",
  },
  {
    label: "Sint-Pieters-Leeuw",
    lat: 50.78118355,
    lng: 4.2452186173064,
    value: "1600",
  },
  {
    label: "Ruisbroek",
    lat: 50.7848617,
    lng: 4.298610469732,
    value: "1601",
  },
  {
    label: "Vlezenbeek",
    lat: 50.805593,
    lng: 4.2360410720845,
    value: "1602",
  },
  {
    label: "Drogenbos",
    lat: 50.786532,
    lng: 4.3173542,
    value: "1620",
  },
  {
    label: "Linkebeek",
    lat: 50.7716092,
    lng: 4.3345641,
    value: "1630",
  },
  {
    label: "Rhode-Saint-Gen\u00e8se",
    lat: 50.74503,
    lng: 4.3463687,
    value: "1640",
  },
  {
    label: "Beersel",
    lat: 50.7684432,
    lng: 4.3051321494591,
    value: "1650",
  },
  {
    label: "Lot",
    lat: 50.76514035,
    lng: 4.2729986221483,
    value: "1651",
  },
  {
    label: "Alsemberg",
    lat: 50.7449476,
    lng: 4.3281703413079,
    value: "1652",
  },
  {
    label: "Dworp",
    lat: 50.73423975,
    lng: 4.2950152833964,
    value: "1653",
  },
  {
    label: "Huizingen",
    lat: 50.74931535,
    lng: 4.2732545968764,
    value: "1654",
  },
  {
    label: "Bogaarden",
    lat: 50.7398062,
    lng: 4.1367803,
    value: "1670",
  },
  {
    label: "Heikruis",
    lat: 50.7348458,
    lng: 4.1126567,
    value: "1670",
  },
  {
    label: "Pepingen",
    lat: 50.7511702,
    lng: 4.1785882891377,
    value: "1670",
  },
  {
    label: "Elingen",
    lat: 50.7793527,
    lng: 4.174198071757,
    value: "1671",
  },
  {
    label: "Beert",
    lat: 50.7354888,
    lng: 4.1853449364222,
    value: "1673",
  },
  {
    label: "Bellingen",
    lat: 50.73779765,
    lng: 4.1601450278998,
    value: "1674",
  },
  {
    label: "Dilbeek",
    lat: 50.84408625,
    lng: 4.2657299667643,
    value: "1700",
  },
  {
    label: "Sint-Martens-Bodegem",
    lat: 50.8627233,
    lng: 4.2150592,
    value: "1700",
  },
  {
    label: "Sint-Ulriks-Kapelle",
    lat: 50.8800337,
    lng: 4.2206526,
    value: "1700",
  },
  {
    label: "Itterbeek",
    lat: 50.8395994,
    lng: 4.2501187,
    value: "1701",
  },
  {
    label: "Groot-Bijgaarden",
    lat: 50.8714357,
    lng: 4.2635668372339,
    value: "1702",
  },
  {
    label: "Schepdaal",
    lat: 50.8363077,
    lng: 4.195149072634,
    value: "1703",
  },
  {
    label: "Asse",
    lat: 50.913594,
    lng: 4.1804375072845,
    value: "1730",
  },
  {
    label: "Bekkerzeel",
    lat: 50.8853713,
    lng: 4.2381789,
    value: "1730",
  },
  {
    label: "Kobbegem",
    lat: 50.9091629,
    lng: 4.2496669,
    value: "1730",
  },
  {
    label: "Mollem",
    lat: 50.9328497,
    lng: 4.2239826,
    value: "1730",
  },
  {
    label: "Relegem",
    lat: 50.9019808,
    lng: 4.2790265927797,
    value: "1731",
  },
  {
    label: "Zellik",
    lat: 50.8842475,
    lng: 4.2744185,
    value: "1731",
  },
  {
    label: "Ternat",
    lat: 50.87155835,
    lng: 4.1834171401216,
    value: "1740",
  },
  {
    label: "Wambeek",
    lat: 50.85672975,
    lng: 4.1646372476962,
    value: "1741",
  },
  {
    label: "Sint-Katherina-Lombeek",
    lat: 50.8726659,
    lng: 4.1535568,
    value: "1742",
  },
  {
    label: "Mazenzele",
    lat: 50.9428791,
    lng: 4.1717632,
    value: "1745",
  },
  {
    label: "Opwijk",
    lat: 50.9690529,
    lng: 4.1896665,
    value: "1745",
  },
  {
    label: "Gaasbeek",
    lat: 50.7998923,
    lng: 4.1888025,
    value: "1750",
  },
  {
    label: "Lennik",
    lat: 50.8085632,
    lng: 4.1648626,
    value: "1750",
  },
  {
    label: "Sint-Kwintens-Lennik",
    lat: 50.81145495,
    lng: 4.1456163063499,
    value: "1750",
  },
  {
    label: "Sint-Martens-Lennik",
    lat: 50.8115319,
    lng: 4.1695303908975,
    value: "1750",
  },
  {
    label: "Gooik",
    lat: 50.7979738,
    lng: 4.1029840674511,
    value: "1755",
  },
  {
    label: "Kester",
    lat: 50.7631284,
    lng: 4.1212845,
    value: "1755",
  },
  {
    label: "Leerbeek",
    lat: 50.7773538,
    lng: 4.1192641,
    value: "1755",
  },
  {
    label: "Oetingen",
    lat: 50.7720719,
    lng: 4.0614624,
    value: "1755",
  },
  {
    label: "Onze-Lieve-Vrouw-Lombeek",
    lat: 50.8220387,
    lng: 4.1119573,
    value: "1760",
  },
  {
    label: "Pamel",
    lat: 50.83471975,
    lng: 4.0820664787057,
    value: "1760",
  },
  {
    label: "Roosdaal",
    lat: 50.8341383,
    lng: 4.0972238,
    value: "1760",
  },
  {
    label: "Strijtem",
    lat: 50.8405541,
    lng: 4.1145703,
    value: "1760",
  },
  {
    label: "Borchtlombeek",
    lat: 50.84817805,
    lng: 4.1369148649611,
    value: "1761",
  },
  {
    label: "Liedekerke",
    lat: 50.8711313,
    lng: 4.0860754,
    value: "1770",
  },
  {
    label: "Wemmel",
    lat: 50.9145955,
    lng: 4.3108723,
    value: "1780",
  },
  {
    label: "Brussegem",
    lat: 50.9277713,
    lng: 4.2658869,
    value: "1785",
  },
  {
    label: "Hamme",
    lat: 51.0881618,
    lng: 4.1202807810427,
    value: "1785",
  },
  {
    label: "Merchtem",
    lat: 50.9591981,
    lng: 4.2327769,
    value: "1785",
  },
  {
    label: "Affligem",
    lat: 50.9035063,
    lng: 4.1174675,
    value: "1790",
  },
  {
    label: "Essene",
    lat: 50.8973851,
    lng: 4.1390421,
    value: "1790",
  },
  {
    label: "Hekelgem",
    lat: 50.9137991,
    lng: 4.1118354895388,
    value: "1790",
  },
  {
    label: "Teralfene",
    lat: 50.8921419,
    lng: 4.09834,
    value: "1790",
  },
  {
    label: "Peutie",
    lat: 50.9271744,
    lng: 4.4551981,
    value: "1800",
  },
  {
    label: "Vilvoorde",
    lat: 50.92813655,
    lng: 4.4329052514838,
    value: "1800",
  },
  {
    label: "Melsbroek",
    lat: 50.9170725,
    lng: 4.4758811,
    value: "1820",
  },
  {
    label: "Perk",
    lat: 50.9331623,
    lng: 4.4960702,
    value: "1820",
  },
  {
    label: "Steenokkerzeel",
    lat: 50.9093488,
    lng: 4.5110471,
    value: "1820",
  },
  {
    label: "Machelen",
    lat: 50.9124219,
    lng: 4.4353614,
    value: "1830",
  },
  {
    label: "Diegem",
    lat: 50.8945555,
    lng: 4.4365042,
    value: "1831",
  },
  {
    label: "Londerzeel",
    lat: 51.0122466,
    lng: 4.2990729416338,
    value: "1840",
  },
  {
    label: "Malderen",
    lat: 51.0198436,
    lng: 4.2437819,
    value: "1840",
  },
  {
    label: "Steenhuffel",
    lat: 50.9944384,
    lng: 4.2624672,
    value: "1840",
  },
  {
    label: "Grimbergen",
    lat: 50.95030565,
    lng: 4.4013732626459,
    value: "1850",
  },
  {
    label: "Humbeek",
    lat: 50.9728229,
    lng: 4.3777599282045,
    value: "1851",
  },
  {
    label: "Beigem",
    lat: 50.9527061,
    lng: 4.3638682,
    value: "1852",
  },
  {
    label: "Strombeek-Bever",
    lat: 50.91096285,
    lng: 4.342355501959,
    value: "1853",
  },
  {
    label: "Meise",
    lat: 50.9341625,
    lng: 4.3287322,
    value: "1860",
  },
  {
    label: "Wolvertem",
    lat: 50.9510266,
    lng: 4.3085198,
    value: "1861",
  },
  {
    label: "Kapelle-Op-Den-Bos",
    lat: 51.0131904,
    lng: 4.3599725,
    value: "1880",
  },
  {
    label: "Nieuwenrode",
    lat: 50.9799835,
    lng: 4.3512293,
    value: "1880",
  },
  {
    label: "Ramsdonk",
    lat: 51.0114961,
    lng: 4.3374964007802,
    value: "1880",
  },
  {
    label: "Berg",
    lat: 51.1695659,
    lng: 5.1521172,
    value: "1910",
  },
  {
    label: "Buken",
    lat: 50.937546,
    lng: 4.617418,
    value: "1910",
  },
  {
    label: "Kampenhout",
    lat: 50.94924405,
    lng: 4.5711630873694,
    value: "1910",
  },
  {
    label: "Nederokkerzeel",
    lat: 50.9190127,
    lng: 4.5645096,
    value: "1910",
  },
  {
    label: "Nossegem",
    lat: 50.88499735,
    lng: 4.5081025949464,
    value: "1930",
  },
  {
    label: "Zaventem",
    lat: 50.8804355,
    lng: 4.4745515,
    value: "1930",
  },
  {
    label: "Sint-Stevens-Woluwe",
    lat: 50.8662434,
    lng: 4.4359939711919,
    value: "1932",
  },
  {
    label: "Sterrebeek",
    lat: 50.85395875,
    lng: 4.5157438438279,
    value: "1933",
  },
  {
    label: "Kraainem",
    lat: 50.8606105,
    lng: 4.4691371,
    value: "1950",
  },
  {
    label: "Wezembeek-Oppem",
    lat: 50.8440554,
    lng: 4.4871796,
    value: "1970",
  },
  {
    label: "Eppegem",
    lat: 50.961429,
    lng: 4.4556575,
    value: "1980",
  },
  {
    label: "Zemst",
    lat: 50.9865028,
    lng: 4.44412333945,
    value: "1980",
  },
  {
    label: "Hofstade",
    lat: 50.9939033,
    lng: 4.5042526607237,
    value: "1981",
  },
  {
    label: "Elewijt",
    lat: 50.96953845,
    lng: 4.5075638918541,
    value: "1982",
  },
  {
    label: "Weerde",
    lat: 50.9722782,
    lng: 4.479869,
    value: "1982",
  },
  {
    label: "Antwerpen",
    lat: 51.2211097,
    lng: 4.3997081,
    value: "2000",
  },
  {
    label: "Antwerpen",
    lat: 51.2211097,
    lng: 4.3997081,
    value: "2018",
  },
  {
    label: "Antwerpen",
    lat: 51.2211097,
    lng: 4.3997081,
    value: "2020",
  },
  {
    label: "Antwerpen",
    lat: 51.2211097,
    lng: 4.3997081,
    value: "2030",
  },
  {
    label: "Antwerpen",
    lat: 51.2211097,
    lng: 4.3997081,
    value: "2040",
  },
  {
    label: "Berendrecht",
    lat: 51.3455396,
    lng: 4.319401,
    value: "2040",
  },
  {
    label: "Lillo",
    lat: 51.3045534,
    lng: 4.2900122,
    value: "2040",
  },
  {
    label: "Zandvliet",
    lat: 51.35984975,
    lng: 4.2836261784487,
    value: "2040",
  },
  {
    label: "Antwerpen",
    lat: 51.2211097,
    lng: 4.3997081,
    value: "2050",
  },
  {
    label: "Antwerpen",
    lat: 51.2211097,
    lng: 4.3997081,
    value: "2060",
  },
  {
    label: "Burcht",
    lat: 51.2028046,
    lng: 4.3416362,
    value: "2070",
  },
  {
    label: "Zwijndrecht",
    lat: 51.23062085,
    lng: 4.3188693282188,
    value: "2070",
  },
  {
    label: "Deurne",
    lat: 51.2115284,
    lng: 4.4695254092764,
    value: "2100",
  },
  {
    label: "Wijnegem",
    lat: 51.2270761,
    lng: 4.5225318,
    value: "2110",
  },
  {
    label: "Borgerhout",
    lat: 51.21029905,
    lng: 4.4403895064496,
    value: "2140",
  },
  {
    label: "Borsbeek",
    lat: 51.1928907,
    lng: 4.488953,
    value: "2150",
  },
  {
    label: "Wommelgem",
    lat: 51.2031044,
    lng: 4.5238022,
    value: "2160",
  },
  {
    label: "Merksem",
    lat: 51.24994295,
    lng: 4.4453401635115,
    value: "2170",
  },
  {
    label: "Ekeren",
    lat: 51.2842045,
    lng: 4.4322870782182,
    value: "2180",
  },
  {
    label: "Herentals",
    lat: 51.1855514,
    lng: 4.83464460985,
    value: "2200",
  },
  {
    label: "Morkhoven",
    lat: 51.1203399,
    lng: 4.8207801,
    value: "2200",
  },
  {
    label: "Noorderwijk",
    lat: 51.1415564,
    lng: 4.8401277,
    value: "2200",
  },
  {
    label: "Hallaar",
    lat: 51.08755725,
    lng: 4.7191120718788,
    value: "2220",
  },
  {
    label: "Heist-Op-Den-Berg",
    lat: 51.06251415,
    lng: 4.7193765534389,
    value: "2220",
  },
  {
    label: "Booischot",
    lat: 51.0471677,
    lng: 4.7692867998141,
    value: "2221",
  },
  {
    label: "Itegem",
    lat: 51.10403005,
    lng: 4.731894344605,
    value: "2222",
  },
  {
    label: "Wiekevorst",
    lat: 51.10609435,
    lng: 4.7853558986493,
    value: "2222",
  },
  {
    label: "Schriek",
    lat: 51.2907379,
    lng: 4.4361161,
    value: "2223",
  },
  {
    label: "Herselt",
    lat: 51.0554081,
    lng: 4.9018080031354,
    value: "2230",
  },
  {
    label: "Ramsel",
    lat: 51.0324718,
    lng: 4.8336098,
    value: "2230",
  },
  {
    label: "Houtvenne",
    lat: 51.04605145,
    lng: 4.8012932645531,
    value: "2235",
  },
  {
    label: "Hulshout",
    lat: 51.0753923,
    lng: 4.7885461,
    value: "2235",
  },
  {
    label: "Westmeerbeek",
    lat: 51.0603638,
    lng: 4.8351334,
    value: "2235",
  },
  {
    label: "Massenhoven",
    lat: 51.19902125,
    lng: 4.6356924683019,
    value: "2240",
  },
  {
    label: "Viersel",
    lat: 51.187272,
    lng: 4.6503063,
    value: "2240",
  },
  {
    label: "Zandhoven",
    lat: 51.2147918,
    lng: 4.6595103,
    value: "2240",
  },
  {
    label: "Pulderbos",
    lat: 51.2257319,
    lng: 4.7033852099385,
    value: "2242",
  },
  {
    label: "Pulle",
    lat: 51.2011609,
    lng: 4.6975445276147,
    value: "2243",
  },
  {
    label: "Olen",
    lat: 51.1438611,
    lng: 4.8597257,
    value: "2250",
  },
  {
    label: "Oevel",
    lat: 51.1377568,
    lng: 4.9053101,
    value: "2260",
  },
  {
    label: "Tongerlo",
    lat: 51.1220734,
    lng: 4.9012622781849,
    value: "2260",
  },
  {
    label: "Westerlo",
    lat: 51.086694,
    lng: 4.9168675,
    value: "2260",
  },
  {
    label: "Zoerle-Parwijs",
    lat: 51.0880889,
    lng: 4.8729402,
    value: "2260",
  },
  {
    label: "Herenthout",
    lat: 51.1392427,
    lng: 4.7543972,
    value: "2270",
  },
  {
    label: "Gierle",
    lat: 51.26758,
    lng: 4.8676797,
    value: "2275",
  },
  {
    label: "Lille",
    lat: 51.238218,
    lng: 4.8242404,
    value: "2275",
  },
  {
    label: "Poederlee",
    lat: 51.2267185,
    lng: 4.8359528,
    value: "2275",
  },
  {
    label: "Wechelderzande",
    lat: 51.26541275,
    lng: 4.7910582697468,
    value: "2275",
  },
  {
    label: "Grobbendonk",
    lat: 51.18870335,
    lng: 4.7318844301963,
    value: "2280",
  },
  {
    label: "Bouwel",
    lat: 51.16210045,
    lng: 4.7370614385825,
    value: "2288",
  },
  {
    label: "Vorselaar",
    lat: 51.2023448,
    lng: 4.769482,
    value: "2290",
  },
  {
    label: "Turnhout",
    lat: 51.3233812,
    lng: 4.948461,
    value: "2300",
  },
  {
    label: "Rijkevorsel",
    lat: 51.3495046,
    lng: 4.760708,
    value: "2310",
  },
  {
    label: "Hoogstraten",
    lat: 51.39574085,
    lng: 4.7440656414186,
    value: "2320",
  },
  {
    label: "Meer",
    lat: 51.46092495,
    lng: 4.7372548575575,
    value: "2321",
  },
  {
    label: "Minderhout",
    lat: 51.43155825,
    lng: 4.8070790487489,
    value: "2322",
  },
  {
    label: "Wortel",
    lat: 51.3975366,
    lng: 4.8085930879726,
    value: "2323",
  },
  {
    label: "Meerle",
    lat: 51.4743516,
    lng: 4.8051679,
    value: "2328",
  },
  {
    label: "Merksplas",
    lat: 51.3580597,
    lng: 4.8627541,
    value: "2330",
  },
  {
    label: "Beerse",
    lat: 51.3134921,
    lng: 4.837570452313,
    value: "2340",
  },
  {
    label: "Vlimmeren",
    lat: 51.2988018,
    lng: 4.7821797,
    value: "2340",
  },
  {
    label: "Vosselaar",
    lat: 51.3129153,
    lng: 4.8878026,
    value: "2350",
  },
  {
    label: "Oud-Turnhout",
    lat: 51.3178319,
    lng: 4.9817086,
    value: "2360",
  },
  {
    label: "Arendonk",
    lat: 51.3202315,
    lng: 5.0864557,
    value: "2370",
  },
  {
    label: "Ravels",
    lat: 51.3808625,
    lng: 5.0198122919518,
    value: "2380",
  },
  {
    label: "Weelde",
    lat: 51.41206885,
    lng: 5.0182445420616,
    value: "2381",
  },
  {
    label: "Poppel",
    lat: 51.45063155,
    lng: 5.0488069670452,
    value: "2382",
  },
  {
    label: "Baarle-Hertog",
    lat: 51.412750431352,
    lng: 4.9004051710883,
    value: "2387",
  },
  {
    label: "Malle",
    lat: 51.3001124,
    lng: 4.7149828,
    value: "2390",
  },
  {
    label: "Oostmalle",
    lat: 51.3019209,
    lng: 4.7331618,
    value: "2390",
  },
  {
    label: "Westmalle",
    lat: 51.29940565,
    lng: 4.6713095211155,
    value: "2390",
  },
  {
    label: "Mol",
    lat: 51.1840421,
    lng: 5.1155267,
    value: "2400",
  },
  {
    label: "Eindhout",
    lat: 51.10171295,
    lng: 4.9972890275518,
    value: "2430",
  },
  {
    label: "Laakdal",
    lat: 51.0802695,
    lng: 5.0073022,
    value: "2430",
  },
  {
    label: "Vorst",
    lat: 51.0790117,
    lng: 5.0188161,
    value: "2430",
  },
  {
    label: "Varendonk",
    lat: 51.0801317,
    lng: 4.9552253,
    value: "2431",
  },
  {
    label: "Veerle",
    lat: 51.0592049,
    lng: 4.9805536902848,
    value: "2431",
  },
  {
    label: "Geel",
    lat: 51.1610826,
    lng: 4.9903187,
    value: "2440",
  },
  {
    label: "Meerhout",
    lat: 51.1317433,
    lng: 5.0772388,
    value: "2450",
  },
  {
    label: "Kasterlee",
    lat: 51.2407915,
    lng: 4.967812,
    value: "2460",
  },
  {
    label: "Lichtaart",
    lat: 51.2309182,
    lng: 4.9037390737022,
    value: "2460",
  },
  {
    label: "Tielen",
    lat: 51.2422595,
    lng: 4.8965455,
    value: "2460",
  },
  {
    label: "Retie",
    lat: 51.2675862,
    lng: 5.0843883,
    value: "2470",
  },
  {
    label: "Dessel",
    lat: 51.2395713,
    lng: 5.113243,
    value: "2480",
  },
  {
    label: "Balen",
    lat: 51.171804,
    lng: 5.1937730761009,
    value: "2490",
  },
  {
    label: "Olmen",
    lat: 51.13368515,
    lng: 5.1648214462792,
    value: "2491",
  },
  {
    label: "Koningshooikt",
    lat: 51.0929433,
    lng: 4.6033828110877,
    value: "2500",
  },
  {
    label: "Lier",
    lat: 51.131069,
    lng: 4.5696516,
    value: "2500",
  },
  {
    label: "Broechem",
    lat: 51.1813951,
    lng: 4.5967162,
    value: "2520",
  },
  {
    label: "Emblem",
    lat: 51.1625761,
    lng: 4.6047327,
    value: "2520",
  },
  {
    label: "Oelegem",
    lat: 51.2110687,
    lng: 4.5972162,
    value: "2520",
  },
  {
    label: "Ranst",
    lat: 51.1961732,
    lng: 4.5637563614009,
    value: "2520",
  },
  {
    label: "Boechout",
    lat: 51.1596941,
    lng: 4.510406865149,
    value: "2530",
  },
  {
    label: "Vremde",
    lat: 51.1753099,
    lng: 4.5226229,
    value: "2531",
  },
  {
    label: "Hove",
    lat: 51.1486117,
    lng: 4.477387,
    value: "2540",
  },
  {
    label: "Lint",
    lat: 51.1268305,
    lng: 4.4922187,
    value: "2547",
  },
  {
    label: "Kontich",
    lat: 51.134377,
    lng: 4.4456359,
    value: "2550",
  },
  {
    label: "Waarloos",
    lat: 51.1052659,
    lng: 4.453,
    value: "2550",
  },
  {
    label: "Bevel",
    lat: 51.1364316,
    lng: 4.6922875770195,
    value: "2560",
  },
  {
    label: "Kessel",
    lat: 51.14453105,
    lng: 4.6393456001407,
    value: "2560",
  },
  {
    label: "Nijlen",
    lat: 51.1611887,
    lng: 4.6709517317049,
    value: "2560",
  },
  {
    label: "Duffel",
    lat: 51.0957176,
    lng: 4.5061988,
    value: "2570",
  },
  {
    label: "Beerzel",
    lat: 51.06299735,
    lng: 4.6630807412987,
    value: "2580",
  },
  {
    label: "Putte",
    lat: 51.0570823,
    lng: 4.6310473,
    value: "2580",
  },
  {
    label: "Berlaar",
    lat: 51.10303735,
    lng: 4.6640387634597,
    value: "2590",
  },
  {
    label: "Gestel",
    lat: 51.12758685,
    lng: 4.6646313199891,
    value: "2590",
  },
  {
    label: "Berchem",
    lat: 51.1918493,
    lng: 4.4317026387884,
    value: "2600",
  },
  {
    label: "Wilrijk",
    lat: 51.1638451,
    lng: 4.3876309610455,
    value: "2610",
  },
  {
    label: "Hemiksem",
    lat: 51.1442105,
    lng: 4.3420555,
    value: "2620",
  },
  {
    label: "Schelle",
    lat: 51.1245635,
    lng: 4.3361875,
    value: "2627",
  },
  {
    label: "Aartselaar",
    lat: 51.133297,
    lng: 4.3870241,
    value: "2630",
  },
  {
    label: "Mortsel",
    lat: 51.1741789,
    lng: 4.4593335,
    value: "2640",
  },
  {
    label: "Edegem",
    lat: 51.1574786,
    lng: 4.4384284,
    value: "2650",
  },
  {
    label: "Hoboken",
    lat: 51.1757824,
    lng: 4.3511502171395,
    value: "2660",
  },
  {
    label: "Mechelen",
    lat: 51.02803335,
    lng: 4.471321199996,
    value: "2800",
  },
  {
    label: "Walem",
    lat: 51.0657941,
    lng: 4.4568748,
    value: "2800",
  },
  {
    label: "Heffen",
    lat: 51.0519355,
    lng: 4.4124762563322,
    value: "2801",
  },
  {
    label: "Hombeek",
    lat: 51.01166405,
    lng: 4.4211910518318,
    value: "2811",
  },
  {
    label: "Leest",
    lat: 51.0350559,
    lng: 4.4150058,
    value: "2811",
  },
  {
    label: "Muizen",
    lat: 51.0083206,
    lng: 4.5182999731643,
    value: "2812",
  },
  {
    label: "Bonheiden",
    lat: 51.0261294,
    lng: 4.5378217779072,
    value: "2820",
  },
  {
    label: "Rijmenam",
    lat: 51.0010818,
    lng: 4.5842221,
    value: "2820",
  },
  {
    label: "Blaasveld",
    lat: 51.0565194,
    lng: 4.3723517,
    value: "2830",
  },
  {
    label: "Heindonk",
    lat: 51.0670264,
    lng: 4.4066584,
    value: "2830",
  },
  {
    label: "Tisselt",
    lat: 51.033992,
    lng: 4.3598407,
    value: "2830",
  },
  {
    label: "Willebroek",
    lat: 51.0626964,
    lng: 4.3572324644033,
    value: "2830",
  },
  {
    label: "Reet",
    lat: 51.1044854,
    lng: 4.4058899586301,
    value: "2840",
  },
  {
    label: "Rumst",
    lat: 51.0792853,
    lng: 4.4240472,
    value: "2840",
  },
  {
    label: "Terhagen",
    lat: 51.078277,
    lng: 4.3993623,
    value: "2840",
  },
  {
    label: "Niel",
    lat: 51.1098649,
    lng: 4.3303396,
    value: "2845",
  },
  {
    label: "Boom",
    lat: 51.0873789,
    lng: 4.3667216,
    value: "2850",
  },
  {
    label: "Sint-Katelijne-Waver",
    lat: 51.06232435,
    lng: 4.5114278140147,
    value: "2860",
  },
  {
    label: "Onze-Lieve-Vrouw-Waver",
    lat: 51.0624913,
    lng: 4.5799614,
    value: "2861",
  },
  {
    label: "Breendonk",
    lat: 51.0452687,
    lng: 4.3136108625549,
    value: "2870",
  },
  {
    label: "Liezele",
    lat: 51.05486435,
    lng: 4.2808516096232,
    value: "2870",
  },
  {
    label: "Puurs",
    lat: 51.07199845,
    lng: 4.2861928678797,
    value: "2870",
  },
  {
    label: "Ruisbroek",
    lat: 51.0802059,
    lng: 4.3379815821239,
    value: "2870",
  },
  {
    label: "Bornem",
    lat: 51.0924649,
    lng: 4.2423260051519,
    value: "2880",
  },
  {
    label: "Hingene",
    lat: 51.103114,
    lng: 4.2930862930359,
    value: "2880",
  },
  {
    label: "Mariekerke",
    lat: 51.06647005,
    lng: 4.2010309553894,
    value: "2880",
  },
  {
    label: "Weert",
    lat: 51.10151195,
    lng: 4.1855882750296,
    value: "2880",
  },
  {
    label: "Lippelo",
    lat: 51.0451478,
    lng: 4.2519213925908,
    value: "2890",
  },
  {
    label: "Oppuurs",
    lat: 51.06220625,
    lng: 4.2389889523045,
    value: "2890",
  },
  {
    label: "Sint-Amands",
    lat: 51.0463409,
    lng: 4.2107348196593,
    value: "2890",
  },
  {
    label: "Schoten",
    lat: 51.2516344,
    lng: 4.4980446,
    value: "2900",
  },
  {
    label: "Essen",
    lat: 51.4677957,
    lng: 4.4701309,
    value: "2910",
  },
  {
    label: "Kalmthout",
    lat: 51.3832723,
    lng: 4.4763255,
    value: "2920",
  },
  {
    label: "Brasschaat",
    lat: 51.2930933,
    lng: 4.489307,
    value: "2930",
  },
  {
    label: "Hoevenen",
    lat: 51.3066168,
    lng: 4.4046996,
    value: "2940",
  },
  {
    label: "Stabroek",
    lat: 51.33518735,
    lng: 4.370312016044,
    value: "2940",
  },
  {
    label: "Kapellen",
    lat: 51.332759,
    lng: 4.4312318305558,
    value: "2950",
  },
  {
    label: "Brecht",
    lat: 51.3347679,
    lng: 4.5972381789962,
    value: "2960",
  },
  {
    label: "Sint-Job-In-'t-Goor",
    lat: 51.2955923,
    lng: 4.5759347,
    value: "2960",
  },
  {
    label: "Sint-Lenaarts",
    lat: 51.3453831,
    lng: 4.6782162,
    value: "2960",
  },
  {
    label: "'s Gravenwezel",
    lat: 51.2624856,
    lng: 4.5556038,
    value: "2970",
  },
  {
    label: "Schilde",
    lat: 51.257095,
    lng: 4.5955157359888,
    value: "2970",
  },
  {
    label: "Halle",
    lat: 51.2428446,
    lng: 4.6455565344486,
    value: "2980",
  },
  {
    label: "Zoersel",
    lat: 51.2716725,
    lng: 4.7132557,
    value: "2980",
  },
  {
    label: "Loenhout",
    lat: 51.3994474,
    lng: 4.6428328,
    value: "2990",
  },
  {
    label: "Wuustwezel",
    lat: 51.38525,
    lng: 4.5651743450402,
    value: "2990",
  },
  {
    label: "Leuven",
    lat: 50.8812533,
    lng: 4.6929903215189,
    value: "3000",
  },
  {
    label: "Heverlee",
    lat: 50.85172925,
    lng: 4.6931307510281,
    value: "3001",
  },
  {
    label: "Kessel Lo",
    lat: 50.88991545,
    lng: 4.7307614946269,
    value: "3010",
  },
  {
    label: "Wilsele",
    lat: 50.9095362,
    lng: 4.7136294,
    value: "3012",
  },
  {
    label: "Wijgmaal",
    lat: 50.926428,
    lng: 4.7001214,
    value: "3018",
  },
  {
    label: "Herent",
    lat: 50.9079329,
    lng: 4.672714,
    value: "3020",
  },
  {
    label: "Veltem-Beisem",
    lat: 50.9020979,
    lng: 4.6255138,
    value: "3020",
  },
  {
    label: "Winksele",
    lat: 50.9124843,
    lng: 4.6404471957661,
    value: "3020",
  },
  {
    label: "Huldenberg",
    lat: 50.7950895,
    lng: 4.5828587400028,
    value: "3040",
  },
  {
    label: "Loonbeek",
    lat: 50.8070345,
    lng: 4.6067572,
    value: "3040",
  },
  {
    label: "Neerijse",
    lat: 50.81115135,
    lng: 4.6290291125613,
    value: "3040",
  },
  {
    label: "Ottenburg",
    lat: 50.7540955,
    lng: 4.6258024576448,
    value: "3040",
  },
  {
    label: "Sint-Agatha-Rode",
    lat: 50.7794761,
    lng: 4.6329556561262,
    value: "3040",
  },
  {
    label: "Oud-Heverlee",
    lat: 50.8217682,
    lng: 4.6678971,
    value: "3050",
  },
  {
    label: "Sint-Joris-Weert",
    lat: 50.8051035,
    lng: 4.6458289787229,
    value: "3051",
  },
  {
    label: "Blanden",
    lat: 50.8281673,
    lng: 4.7056796612254,
    value: "3052",
  },
  {
    label: "Haasrode",
    lat: 50.81785645,
    lng: 4.7283706944623,
    value: "3053",
  },
  {
    label: "Vaalbeek",
    lat: 50.82408165,
    lng: 4.6852713590102,
    value: "3054",
  },
  {
    label: "Bertem",
    lat: 50.8706469,
    lng: 4.6322907,
    value: "3060",
  },
  {
    label: "Korbeek-Dijle",
    lat: 50.847010125156,
    lng: 4.6299439737893,
    value: "3060",
  },
  {
    label: "Leefdaal",
    lat: 50.8419887,
    lng: 4.5905043649411,
    value: "3061",
  },
  {
    label: "Kortenberg",
    lat: 50.88326665,
    lng: 4.5364707039624,
    value: "3070",
  },
  {
    label: "Erps-Kwerps",
    lat: 50.9104969,
    lng: 4.5750864125531,
    value: "3071",
  },
  {
    label: "Everberg",
    lat: 50.87266065,
    lng: 4.5545949589597,
    value: "3078",
  },
  {
    label: "Meerbeek",
    lat: 50.8782935,
    lng: 4.5972692536847,
    value: "3078",
  },
  {
    label: "Duisburg",
    lat: 50.8110528,
    lng: 4.545208431698,
    value: "3080",
  },
  {
    label: "Tervuren",
    lat: 50.8227718,
    lng: 4.5144027,
    value: "3080",
  },
  {
    label: "Vossem",
    lat: 50.83488335,
    lng: 4.5565660886042,
    value: "3080",
  },
  {
    label: "Overijse",
    lat: 50.7729254,
    lng: 4.5385226,
    value: "3090",
  },
  {
    label: "Rotselaar",
    lat: 50.9543637,
    lng: 4.7143205960258,
    value: "3110",
  },
  {
    label: "Wezemaal",
    lat: 50.9482552,
    lng: 4.7683306885409,
    value: "3111",
  },
  {
    label: "Werchter",
    lat: 50.9707189,
    lng: 4.7026877230529,
    value: "3118",
  },
  {
    label: "Tremelo",
    lat: 50.9900457,
    lng: 4.6755134,
    value: "3120",
  },
  {
    label: "Baal",
    lat: 51.0075269,
    lng: 4.7505367662714,
    value: "3128",
  },
  {
    label: "Begijnendijk",
    lat: 51.02422075,
    lng: 4.7826403117004,
    value: "3130",
  },
  {
    label: "Betekom",
    lat: 50.99602795,
    lng: 4.7908301771478,
    value: "3130",
  },
  {
    label: "Keerbergen",
    lat: 51.0049242,
    lng: 4.6265551,
    value: "3140",
  },
  {
    label: "Haacht",
    lat: 50.9769286,
    lng: 4.6386824,
    value: "3150",
  },
  {
    label: "Tildonk",
    lat: 50.94081955,
    lng: 4.6595165776069,
    value: "3150",
  },
  {
    label: "Wespelaar",
    lat: 50.95645295,
    lng: 4.6325890563885,
    value: "3150",
  },
  {
    label: "Boortmeerbeek",
    lat: 50.97828585,
    lng: 4.575800525,
    value: "3190",
  },
  {
    label: "Hever",
    lat: 50.9925865,
    lng: 4.5553278,
    value: "3191",
  },
  {
    label: "Aarschot",
    lat: 50.9841594,
    lng: 4.8245687,
    value: "3200",
  },
  {
    label: "Gelrode",
    lat: 50.9670761,
    lng: 4.8042730440984,
    value: "3200",
  },
  {
    label: "Langdorp",
    lat: 51.0118664,
    lng: 4.8856989624279,
    value: "3201",
  },
  {
    label: "Rillaar",
    lat: 50.97591095,
    lng: 4.8970494266873,
    value: "3202",
  },
  {
    label: "Linden",
    lat: 50.89891075,
    lng: 4.7756847507269,
    value: "3210",
  },
  {
    label: "Lubbeek",
    lat: 50.8821122,
    lng: 4.8400599406417,
    value: "3210",
  },
  {
    label: "Binkom",
    lat: 50.8721422,
    lng: 4.8908885858013,
    value: "3211",
  },
  {
    label: "Pellenberg",
    lat: 50.87211325,
    lng: 4.7894086649098,
    value: "3212",
  },
  {
    label: "Holsbeek",
    lat: 50.92172015,
    lng: 4.7557296717686,
    value: "3220",
  },
  {
    label: "Kortrijk-Dutsel",
    lat: 50.91851005,
    lng: 4.7854555627527,
    value: "3220",
  },
  {
    label: "Sint-Pieters-Rode",
    lat: 50.9208131,
    lng: 4.8291872982184,
    value: "3220",
  },
  {
    label: "Nieuwrode",
    lat: 50.9470265,
    lng: 4.8338045392342,
    value: "3221",
  },
  {
    label: "Scherpenheuvel",
    lat: 50.9725745,
    lng: 4.9547327768843,
    value: "3270",
  },
  {
    label: "Scherpenheuvel-Zichem",
    lat: 50.9689147,
    lng: 4.9754325657663,
    value: "3270",
  },
  {
    label: "Averbode",
    lat: 51.0278406,
    lng: 4.9770845794874,
    value: "3271",
  },
  {
    label: "Zichem",
    lat: 51.0085771,
    lng: 4.984958799435,
    value: "3271",
  },
  {
    label: "Messelbroek",
    lat: 50.98948425,
    lng: 4.924667681525,
    value: "3272",
  },
  {
    label: "Testelt",
    lat: 51.0192772,
    lng: 4.9484707287374,
    value: "3272",
  },
  {
    label: "Deurne",
    lat: 51.0392379,
    lng: 5.0964572,
    value: "3290",
  },
  {
    label: "Diest",
    lat: 50.98456645,
    lng: 5.052873496255,
    value: "3290",
  },
  {
    label: "Schaffen",
    lat: 51.0130411,
    lng: 5.086792549946,
    value: "3290",
  },
  {
    label: "Webbekom",
    lat: 50.9649291,
    lng: 5.054414061955,
    value: "3290",
  },
  {
    label: "Kaggevinne",
    lat: 50.9776976,
    lng: 5.0299404926122,
    value: "3293",
  },
  {
    label: "Molenstede",
    lat: 51.0071839,
    lng: 5.0247465287649,
    value: "3294",
  },
  {
    label: "Bost",
    lat: 50.7846322,
    lng: 4.9339239586832,
    value: "3300",
  },
  {
    label: "Goetsenhoven",
    lat: 50.77139835,
    lng: 4.9603301209235,
    value: "3300",
  },
  {
    label: "Hakendover",
    lat: 50.7971718,
    lng: 4.9886552612345,
    value: "3300",
  },
  {
    label: "Kumtich",
    lat: 50.8245511,
    lng: 4.8863798378559,
    value: "3300",
  },
  {
    label: "Oorbeek",
    lat: 50.79215565,
    lng: 4.9137602382894,
    value: "3300",
  },
  {
    label: "Oplinter",
    lat: 50.8284931,
    lng: 4.9950692073381,
    value: "3300",
  },
  {
    label: "Sint-Margriete-Houtem",
    lat: 50.83464645,
    lng: 4.9623805201831,
    value: "3300",
  },
  {
    label: "Tienen",
    lat: 50.80843175,
    lng: 4.9464292134294,
    value: "3300",
  },
  {
    label: "Vissenaken",
    lat: 50.84379005,
    lng: 4.9111431209758,
    value: "3300",
  },
  {
    label: "Hoegaarden",
    lat: 50.7877912,
    lng: 4.88114193614,
    value: "3320",
  },
  {
    label: "Meldert",
    lat: 50.78956675,
    lng: 4.8294566856141,
    value: "3320",
  },
  {
    label: "Outgaarden",
    lat: 50.7665968,
    lng: 4.9193571,
    value: "3321",
  },
  {
    label: "Drieslinter",
    lat: 50.84486375,
    lng: 5.0533455578997,
    value: "3350",
  },
  {
    label: "Linter",
    lat: 50.8291537,
    lng: 5.0409717,
    value: "3350",
  },
  {
    label: "Melkwezer",
    lat: 50.8249402,
    lng: 5.0585292480567,
    value: "3350",
  },
  {
    label: "Neerhespen",
    lat: 50.78801405,
    lng: 5.0579336298623,
    value: "3350",
  },
  {
    label: "Neerlinter",
    lat: 50.843352,
    lng: 5.0283876,
    value: "3350",
  },
  {
    label: "Orsmaal-Gussenhoven",
    lat: 50.8054143,
    lng: 5.0674769,
    value: "3350",
  },
  {
    label: "Overhespen",
    lat: 50.7968421,
    lng: 5.0300432882597,
    value: "3350",
  },
  {
    label: "Wommersom",
    lat: 50.812361,
    lng: 5.0183106289924,
    value: "3350",
  },
  {
    label: "Bierbeek",
    lat: 50.8241245,
    lng: 4.7714318076029,
    value: "3360",
  },
  {
    label: "Korbeek-Lo",
    lat: 50.86176115,
    lng: 4.7561775068454,
    value: "3360",
  },
  {
    label: "Lovenjoel",
    lat: 50.85475565,
    lng: 4.7866873360324,
    value: "3360",
  },
  {
    label: "Opvelp",
    lat: 50.80732735,
    lng: 4.7912847996064,
    value: "3360",
  },
  {
    label: "Boutersem",
    lat: 50.8473461,
    lng: 4.8336178052558,
    value: "3370",
  },
  {
    label: "Kerkom",
    lat: 50.85699665,
    lng: 4.8643358054912,
    value: "3370",
  },
  {
    label: "Neervelp",
    lat: 50.8166881,
    lng: 4.8136699565725,
    value: "3370",
  },
  {
    label: "Roosbeek",
    lat: 50.84022445,
    lng: 4.8620269340011,
    value: "3370",
  },
  {
    label: "Vertrijk",
    lat: 50.83400235,
    lng: 4.8270179962162,
    value: "3370",
  },
  {
    label: "Willebringen",
    lat: 50.80664425,
    lng: 4.8387022883011,
    value: "3370",
  },
  {
    label: "Bunsbeek",
    lat: 50.8510351,
    lng: 4.9601107928553,
    value: "3380",
  },
  {
    label: "Glabbeek",
    lat: 50.8725203,
    lng: 4.9513009,
    value: "3380",
  },
  {
    label: "Kapellen",
    lat: 50.88734525,
    lng: 4.9608776189751,
    value: "3381",
  },
  {
    label: "Attenrode",
    lat: 50.8694607,
    lng: 4.9167554140969,
    value: "3384",
  },
  {
    label: "Houwaart",
    lat: 50.93414405,
    lng: 4.8605997436813,
    value: "3390",
  },
  {
    label: "Sint-Joris-Winge",
    lat: 50.9121762,
    lng: 4.8754116537611,
    value: "3390",
  },
  {
    label: "Tielt",
    lat: 50.9346235,
    lng: 4.9155513317582,
    value: "3390",
  },
  {
    label: "Tielt-Winge",
    lat: 50.9242724,
    lng: 4.8863781815823,
    value: "3390",
  },
  {
    label: "Meensel-Kiezegem",
    lat: 50.8950541,
    lng: 4.9173431325348,
    value: "3391",
  },
  {
    label: "Eliksem",
    lat: 50.78710135,
    lng: 5.0074922319452,
    value: "3400",
  },
  {
    label: "Ezemaal",
    lat: 50.7766242,
    lng: 4.997333783131,
    value: "3400",
  },
  {
    label: "Laar",
    lat: 51.2687557,
    lng: 4.4410406,
    value: "3400",
  },
  {
    label: "Landen",
    lat: 50.7493167,
    lng: 5.0790966118688,
    value: "3400",
  },
  {
    label: "Neerwinden",
    lat: 50.7652288,
    lng: 5.0409975765006,
    value: "3400",
  },
  {
    label: "Overwinden",
    lat: 50.75242545,
    lng: 5.0448025257015,
    value: "3400",
  },
  {
    label: "Rumsdorp",
    lat: 50.76917005,
    lng: 5.0695564232804,
    value: "3400",
  },
  {
    label: "Wange",
    lat: 50.7838394,
    lng: 5.0302723897201,
    value: "3400",
  },
  {
    label: "Waasmont",
    lat: 50.72608345,
    lng: 5.0609420487376,
    value: "3401",
  },
  {
    label: "Walsbets",
    lat: 50.7385007,
    lng: 5.0878535498936,
    value: "3401",
  },
  {
    label: "Walshoutem",
    lat: 50.71820555,
    lng: 5.0869975953341,
    value: "3401",
  },
  {
    label: "Wezeren",
    lat: 50.732168,
    lng: 5.1094961371256,
    value: "3401",
  },
  {
    label: "Attenhoven",
    lat: 50.7653693,
    lng: 5.1006534330464,
    value: "3404",
  },
  {
    label: "Neerlanden",
    lat: 50.7821026,
    lng: 5.0793874624497,
    value: "3404",
  },
  {
    label: "Budingen",
    lat: 50.8653457,
    lng: 5.0871237674967,
    value: "3440",
  },
  {
    label: "Dormaal",
    lat: 50.8063449,
    lng: 5.0920421498928,
    value: "3440",
  },
  {
    label: "Halle-Booienhoven",
    lat: 50.8127265,
    lng: 5.1205761783641,
    value: "3440",
  },
  {
    label: "Helen-Bos",
    lat: 50.83603965,
    lng: 5.0802259143417,
    value: "3440",
  },
  {
    label: "Zoutleeuw",
    lat: 50.8415653,
    lng: 5.1186447480147,
    value: "3440",
  },
  {
    label: "Geetbets",
    lat: 50.89519195,
    lng: 5.1015810029407,
    value: "3450",
  },
  {
    label: "Grazen",
    lat: 50.8754117,
    lng: 5.1299277800431,
    value: "3450",
  },
  {
    label: "Rummen",
    lat: 50.88594765,
    lng: 5.1564917481959,
    value: "3454",
  },
  {
    label: "Assent",
    lat: 50.94677135,
    lng: 5.0136688435307,
    value: "3460",
  },
  {
    label: "Bekkevoort",
    lat: 50.9338977,
    lng: 4.984616971522,
    value: "3460",
  },
  {
    label: "Molenbeek-Wersbeek",
    lat: 50.9212106,
    lng: 4.9541212,
    value: "3461",
  },
  {
    label: "Kortenaken",
    lat: 50.90566935,
    lng: 5.068392495735,
    value: "3470",
  },
  {
    label: "Ransberg",
    lat: 50.8758246,
    lng: 5.0360646174319,
    value: "3470",
  },
  {
    label: "Sint-Margriete-Houtem",
    lat: 50.83464645,
    lng: 4.9623805201831,
    value: "3470",
  },
  {
    label: "Hoeleden",
    lat: 50.8668096,
    lng: 5.0014628020046,
    value: "3471",
  },
  {
    label: "Kersbeek-Miskom",
    lat: 50.8930932577,
    lng: 5.0065116045983,
    value: "3472",
  },
  {
    label: "Waanrode",
    lat: 50.9129375,
    lng: 5.0037716328589,
    value: "3473",
  },
  {
    label: "Hasselt",
    lat: 50.930358,
    lng: 5.3368383970676,
    value: "3500",
  },
  {
    label: "Sint-Lambrechts-Herk",
    lat: 50.90358435,
    lng: 5.3119462785891,
    value: "3500",
  },
  {
    label: "Wimmertingen",
    lat: 50.8769276,
    lng: 5.3512906396928,
    value: "3501",
  },
  {
    label: "Kermt",
    lat: 50.9524495,
    lng: 5.2509784643733,
    value: "3510",
  },
  {
    label: "Spalbeek",
    lat: 50.9490449,
    lng: 5.2277944313068,
    value: "3510",
  },
  {
    label: "Kuringen",
    lat: 50.9439384,
    lng: 5.3052007,
    value: "3511",
  },
  {
    label: "Stokrooie",
    lat: 50.97085355,
    lng: 5.2747491372719,
    value: "3511",
  },
  {
    label: "Stevoort",
    lat: 50.91698375,
    lng: 5.2444291944072,
    value: "3512",
  },
  {
    label: "Zonhoven",
    lat: 50.9909835,
    lng: 5.3677683,
    value: "3520",
  },
  {
    label: "Helchteren",
    lat: 51.0743268,
    lng: 5.3844821648536,
    value: "3530",
  },
  {
    label: "Houthalen",
    lat: 51.036563,
    lng: 5.4121547136036,
    value: "3530",
  },
  {
    label: "Houthalen-Helchteren",
    lat: 51.0281818,
    lng: 5.3713044,
    value: "3530",
  },
  {
    label: "Berbroek",
    lat: 50.94876395,
    lng: 5.2098576659451,
    value: "3540",
  },
  {
    label: "Donk",
    lat: 50.9582088,
    lng: 4.9053567,
    value: "3540",
  },
  {
    label: "Herk-De-Stad",
    lat: 50.9222911,
    lng: 5.1884301868953,
    value: "3540",
  },
  {
    label: "Schulen",
    lat: 50.9533364,
    lng: 5.171672949688,
    value: "3540",
  },
  {
    label: "Halen",
    lat: 50.9384339,
    lng: 5.1073913444455,
    value: "3545",
  },
  {
    label: "Loksbergen",
    lat: 50.9321193,
    lng: 5.0656234079577,
    value: "3545",
  },
  {
    label: "Zelem",
    lat: 50.980158,
    lng: 5.1092670735921,
    value: "3545",
  },
  {
    label: "Heusden",
    lat: 51.04153335,
    lng: 5.2835531292784,
    value: "3550",
  },
  {
    label: "Heusden-Zolder",
    lat: 51.0233485,
    lng: 5.2750485,
    value: "3550",
  },
  {
    label: "Zolder",
    lat: 51.0236553,
    lng: 5.3135203,
    value: "3550",
  },
  {
    label: "Linkhout",
    lat: 50.9657859,
    lng: 5.1372552,
    value: "3560",
  },
  {
    label: "Lummen",
    lat: 51.00313255,
    lng: 5.2025589998731,
    value: "3560",
  },
  {
    label: "Meldert",
    lat: 51.0067479,
    lng: 5.1472099707378,
    value: "3560",
  },
  {
    label: "Alken",
    lat: 50.8754731,
    lng: 5.3079873,
    value: "3570",
  },
  {
    label: "Beringen",
    lat: 51.0502026,
    lng: 5.2208090823008,
    value: "3580",
  },
  {
    label: "Beverlo",
    lat: 51.08973005,
    lng: 5.2443565507181,
    value: "3581",
  },
  {
    label: "Koersel",
    lat: 51.06978945,
    lng: 5.271159654589,
    value: "3582",
  },
  {
    label: "Paal",
    lat: 51.04746375,
    lng: 5.1616725572109,
    value: "3583",
  },
  {
    label: "Diepenbeek",
    lat: 50.9078485,
    lng: 5.4200307,
    value: "3590",
  },
  {
    label: "Genk",
    lat: 50.9654864,
    lng: 5.5001456,
    value: "3600",
  },
  {
    label: "Gellik",
    lat: 50.8839456,
    lng: 5.6091315,
    value: "3620",
  },
  {
    label: "Lanaken",
    lat: 50.8892784,
    lng: 5.6513208,
    value: "3620",
  },
  {
    label: "Neerharen",
    lat: 50.907778,
    lng: 5.680982,
    value: "3620",
  },
  {
    label: "Veldwezelt",
    lat: 50.8655982,
    lng: 5.6323869,
    value: "3620",
  },
  {
    label: "Rekem",
    lat: 50.9284078,
    lng: 5.6626094755366,
    value: "3621",
  },
  {
    label: "Eisden",
    lat: 50.9863045,
    lng: 5.7128515,
    value: "3630",
  },
  {
    label: "Leut",
    lat: 50.9921686,
    lng: 5.7355142,
    value: "3630",
  },
  {
    label: "Maasmechelen",
    lat: 50.9635024,
    lng: 5.696445,
    value: "3630",
  },
  {
    label: "Mechelen-Aan-De-Maas",
    lat: 50.9764002,
    lng: 5.6456979557208,
    value: "3630",
  },
  {
    label: "Meeswijk",
    lat: 51.001413,
    lng: 5.7468352,
    value: "3630",
  },
  {
    label: "Opgrimbie",
    lat: 50.9436011,
    lng: 5.6810305,
    value: "3630",
  },
  {
    label: "Vucht",
    lat: 50.9770425,
    lng: 5.7134261,
    value: "3630",
  },
  {
    label: "Boorsem",
    lat: 50.9408858,
    lng: 5.7147232,
    value: "3631",
  },
  {
    label: "Uikhoven",
    lat: 50.9264738,
    lng: 5.7199198507341,
    value: "3631",
  },
  {
    label: "Kessenich",
    lat: 51.1492029,
    lng: 5.8089243331137,
    value: "3640",
  },
  {
    label: "Kinrooi",
    lat: 51.14313325,
    lng: 5.7414564606985,
    value: "3640",
  },
  {
    label: "Molenbeersel",
    lat: 51.1684178,
    lng: 5.7260763418645,
    value: "3640",
  },
  {
    label: "Ophoven",
    lat: 51.13225635,
    lng: 5.7881529659009,
    value: "3640",
  },
  {
    label: "Dilsen",
    lat: 51.0328615,
    lng: 5.6991443859626,
    value: "3650",
  },
  {
    label: "Dilsen-Stokkem",
    lat: 51.0284779,
    lng: 5.7311405,
    value: "3650",
  },
  {
    label: "Elen",
    lat: 51.06941595,
    lng: 5.7404005925371,
    value: "3650",
  },
  {
    label: "Lanklaar",
    lat: 51.01085805,
    lng: 5.6747570297643,
    value: "3650",
  },
  {
    label: "Rotem",
    lat: 51.053086,
    lng: 5.7205216032604,
    value: "3650",
  },
  {
    label: "Stokkem",
    lat: 51.0197939,
    lng: 5.7508381619462,
    value: "3650",
  },
  {
    label: "Opglabbeek",
    lat: 51.0429949,
    lng: 5.581962,
    value: "3660",
  },
  {
    label: "As",
    lat: 51.0005396,
    lng: 5.5722025852462,
    value: "3665",
  },
  {
    label: "Niel-Bij-As",
    lat: 51.0182495,
    lng: 5.6161660037867,
    value: "3668",
  },
  {
    label: "Ellikom",
    lat: 51.1303479,
    lng: 5.5289463613944,
    value: "3670",
  },
  {
    label: "Gruitrode",
    lat: 51.08477665,
    lng: 5.5813814347439,
    value: "3670",
  },
  {
    label: "Meeuwen",
    lat: 51.07822635,
    lng: 5.5029170798132,
    value: "3670",
  },
  {
    label: "Meeuwen-Gruitrode",
    lat: 51.0933149,
    lng: 5.5540751,
    value: "3670",
  },
  {
    label: "Neerglabbeek",
    lat: 51.09677995,
    lng: 5.6181697688959,
    value: "3670",
  },
  {
    label: "Wijshagen",
    lat: 51.0770409,
    lng: 5.532541372001,
    value: "3670",
  },
  {
    label: "Maaseik",
    lat: 51.0947181,
    lng: 5.791733,
    value: "3680",
  },
  {
    label: "Neeroeteren",
    lat: 51.0946499,
    lng: 5.7027136767689,
    value: "3680",
  },
  {
    label: "Opoeteren",
    lat: 51.05340005,
    lng: 5.6487312543959,
    value: "3680",
  },
  {
    label: "Zutendaal",
    lat: 50.9318992,
    lng: 5.5725548,
    value: "3690",
  },
  {
    label: "'s Herenelderen",
    lat: 50.8064756,
    lng: 5.5030479,
    value: "3700",
  },
  {
    label: "Berg",
    lat: 51.1695659,
    lng: 5.1521172,
    value: "3700",
  },
  {
    label: "Diets-Heur",
    lat: 50.7450193,
    lng: 5.4843527,
    value: "3700",
  },
  {
    label: "Haren",
    lat: 50.8919661,
    lng: 4.4125708732431,
    value: "3700",
  },
  {
    label: "Henis",
    lat: 50.7993981,
    lng: 5.4696959,
    value: "3700",
  },
  {
    label: "Kolmont",
    lat: 50.8027416,
    lng: 5.4206775,
    value: "3700",
  },
  {
    label: "Koninksem",
    lat: 50.7678379,
    lng: 5.4417282,
    value: "3700",
  },
  {
    label: "Lauw",
    lat: 50.7399124,
    lng: 5.4147831,
    value: "3700",
  },
  {
    label: "Mal",
    lat: 50.7689604,
    lng: 5.5223906,
    value: "3700",
  },
  {
    label: "Neerrepen",
    lat: 50.8121319,
    lng: 5.4449386,
    value: "3700",
  },
  {
    label: "Nerem",
    lat: 50.7630913,
    lng: 5.5102044,
    value: "3700",
  },
  {
    label: "Overrepen",
    lat: 50.8067083,
    lng: 5.4301056,
    value: "3700",
  },
  {
    label: "Piringen",
    lat: 50.7873928,
    lng: 5.4198462,
    value: "3700",
  },
  {
    label: "Riksingen",
    lat: 50.8065023,
    lng: 5.4616724,
    value: "3700",
  },
  {
    label: "Rutten",
    lat: 50.747053,
    lng: 5.4421316,
    value: "3700",
  },
  {
    label: "Sluizen",
    lat: 51.133268,
    lng: 2.7568247,
    value: "3700",
  },
  {
    label: "Tongeren",
    lat: 50.7810151,
    lng: 5.4648131,
    value: "3700",
  },
  {
    label: "Vreren",
    lat: 50.7520293,
    lng: 5.495696,
    value: "3700",
  },
  {
    label: "Widooie",
    lat: 50.7719092,
    lng: 5.409701,
    value: "3700",
  },
  {
    label: "Herstappe",
    lat: 50.7273829,
    lng: 5.4260923,
    value: "3717",
  },
  {
    label: "Kortessem",
    lat: 50.86195255,
    lng: 5.3770000287821,
    value: "3720",
  },
  {
    label: "Vliermaalroot",
    lat: 50.8667966,
    lng: 5.4298574663068,
    value: "3721",
  },
  {
    label: "Wintershoven",
    lat: 50.85566395,
    lng: 5.4073456316551,
    value: "3722",
  },
  {
    label: "Guigoven",
    lat: 50.84255235,
    lng: 5.4005632276197,
    value: "3723",
  },
  {
    label: "Vliermaal",
    lat: 50.8326029,
    lng: 5.4259091735787,
    value: "3724",
  },
  {
    label: "Hoeselt",
    lat: 50.8500788,
    lng: 5.4865309,
    value: "3730",
  },
  {
    label: "Romershoven",
    lat: 50.8580527,
    lng: 5.4593316,
    value: "3730",
  },
  {
    label: "Sint-Huibrechts-Hern",
    lat: 50.8292918,
    lng: 5.4487057,
    value: "3730",
  },
  {
    label: "Werm",
    lat: 50.8335665,
    lng: 5.4804957,
    value: "3730",
  },
  {
    label: "Schalkhoven",
    lat: 50.8422859,
    lng: 5.4481669278797,
    value: "3732",
  },
  {
    label: "Beverst",
    lat: 50.8921566,
    lng: 5.4734935,
    value: "3740",
  },
  {
    label: "Bilzen",
    lat: 50.8713356,
    lng: 5.5163011,
    value: "3740",
  },
  {
    label: "Eigenbilzen",
    lat: 50.8743904,
    lng: 5.5755614,
    value: "3740",
  },
  {
    label: "Grote-Spouwen",
    lat: 50.832524,
    lng: 5.5538483,
    value: "3740",
  },
  {
    label: "Hees",
    lat: 50.8460673,
    lng: 5.6124793,
    value: "3740",
  },
  {
    label: "Kleine-Spouwen",
    lat: 50.8379653,
    lng: 5.5473874,
    value: "3740",
  },
  {
    label: "Mopertingen",
    lat: 50.862295,
    lng: 5.5760673,
    value: "3740",
  },
  {
    label: "Munsterbilzen",
    lat: 50.8883348,
    lng: 5.5270242,
    value: "3740",
  },
  {
    label: "Rijkhoven",
    lat: 50.8342984,
    lng: 5.5163495,
    value: "3740",
  },
  {
    label: "Rosmeer",
    lat: 50.8458315,
    lng: 5.5753682,
    value: "3740",
  },
  {
    label: "Waltwilder",
    lat: 50.8638819,
    lng: 5.5464648,
    value: "3740",
  },
  {
    label: "Martenslinde",
    lat: 50.8523326,
    lng: 5.5340486411054,
    value: "3742",
  },
  {
    label: "Hoelbeek",
    lat: 50.883259,
    lng: 5.556142701219,
    value: "3746",
  },
  {
    label: "Genoelselderen",
    lat: 50.8017668,
    lng: 5.5369013,
    value: "3770",
  },
  {
    label: "Herderen",
    lat: 50.8071071,
    lng: 5.5744881,
    value: "3770",
  },
  {
    label: "Kanne",
    lat: 50.8141622,
    lng: 5.6692203,
    value: "3770",
  },
  {
    label: "Membruggen",
    lat: 50.8178133,
    lng: 5.5391564,
    value: "3770",
  },
  {
    label: "Millen",
    lat: 50.7844721,
    lng: 5.560302,
    value: "3770",
  },
  {
    label: "Riemst",
    lat: 50.8115825,
    lng: 5.5978716,
    value: "3770",
  },
  {
    label: "Val-Meer",
    lat: 50.7871228,
    lng: 5.5971013,
    value: "3770",
  },
  {
    label: "Vlijtingen",
    lat: 50.8329146,
    lng: 5.5895873,
    value: "3770",
  },
  {
    label: "Vroenhoven",
    lat: 50.828018,
    lng: 5.6374847,
    value: "3770",
  },
  {
    label: "Zichen-Zussen-Bolder",
    lat: 50.7943475,
    lng: 5.6153404,
    value: "3770",
  },
  {
    label: "Fouron-Saint-Martin",
    lat: 50.74087835,
    lng: 5.8429342279842,
    value: "3790",
  },
  {
    label: "Fourons",
    lat: 50.7465898,
    lng: 5.839052712207,
    value: "3790",
  },
  {
    label: "Mouland",
    lat: 50.7551858,
    lng: 5.7148469912943,
    value: "3790",
  },
  {
    label: "Remersdaal",
    lat: 50.72838165,
    lng: 5.8818776028446,
    value: "3791",
  },
  {
    label: "Fouron-Saint-Pierre",
    lat: 50.72830265,
    lng: 5.8242427123549,
    value: "3792",
  },
  {
    label: "Teuven",
    lat: 50.75322225,
    lng: 5.8695304694514,
    value: "3793",
  },
  {
    label: "Fouron-Le-Comte",
    lat: 50.7611375,
    lng: 5.7719461900567,
    value: "3798",
  },
  {
    label: "Aalst",
    lat: 50.7813901,
    lng: 5.2127604,
    value: "3800",
  },
  {
    label: "Brustem",
    lat: 50.79912435,
    lng: 5.230273192058,
    value: "3800",
  },
  {
    label: "Engelmanshoven",
    lat: 50.77280875,
    lng: 5.2518063089799,
    value: "3800",
  },
  {
    label: "Gelinden",
    lat: 50.7602497,
    lng: 5.2509345230414,
    value: "3800",
  },
  {
    label: "Groot-Gelmen",
    lat: 50.78320635,
    lng: 5.2621170756458,
    value: "3800",
  },
  {
    label: "Halmaal",
    lat: 50.80055285,
    lng: 5.1499121468986,
    value: "3800",
  },
  {
    label: "Kerkom-Bij-Sint-Truiden",
    lat: 50.77333875,
    lng: 5.1787834432079,
    value: "3800",
  },
  {
    label: "Ordingen",
    lat: 50.8137716,
    lng: 5.2381902322176,
    value: "3800",
  },
  {
    label: "Sint-Truiden",
    lat: 50.82694775,
    lng: 5.2034249116034,
    value: "3800",
  },
  {
    label: "Zepperen",
    lat: 50.840318,
    lng: 5.2579163290148,
    value: "3800",
  },
  {
    label: "Duras",
    lat: 50.83411245,
    lng: 5.1461581286779,
    value: "3803",
  },
  {
    label: "Gorsem",
    lat: 50.8380493,
    lng: 5.1658988739064,
    value: "3803",
  },
  {
    label: "Runkelen",
    lat: 50.8477735,
    lng: 5.1519921,
    value: "3803",
  },
  {
    label: "Wilderen",
    lat: 50.81773655,
    lng: 5.1423025876022,
    value: "3803",
  },
  {
    label: "Velm",
    lat: 50.78000075,
    lng: 5.1322112767812,
    value: "3806",
  },
  {
    label: "Berlingen",
    lat: 50.82338225,
    lng: 5.3111748927674,
    value: "3830",
  },
  {
    label: "Wellen",
    lat: 50.8434693,
    lng: 5.3421653176727,
    value: "3830",
  },
  {
    label: "Herten",
    lat: 50.82704785,
    lng: 5.3322359627296,
    value: "3831",
  },
  {
    label: "Ulbeek",
    lat: 50.84319895,
    lng: 5.2936725006792,
    value: "3832",
  },
  {
    label: "Bommershoven",
    lat: 50.78522885,
    lng: 5.3823531554707,
    value: "3840",
  },
  {
    label: "Borgloon",
    lat: 50.8004351,
    lng: 5.3569929252994,
    value: "3840",
  },
  {
    label: "Broekom",
    lat: 50.7819071,
    lng: 5.3324575,
    value: "3840",
  },
  {
    label: "Gors-Opleeuw",
    lat: 50.8308593,
    lng: 5.3849412792168,
    value: "3840",
  },
  {
    label: "Gotem",
    lat: 50.80105865,
    lng: 5.3055250033294,
    value: "3840",
  },
  {
    label: "Groot-Loon",
    lat: 50.7938946,
    lng: 5.3659957408679,
    value: "3840",
  },
  {
    label: "Haren",
    lat: 50.7913472,
    lng: 5.3972985,
    value: "3840",
  },
  {
    label: "Hendrieken",
    lat: 50.797246,
    lng: 5.3287966873717,
    value: "3840",
  },
  {
    label: "Hoepertingen",
    lat: 50.81158915,
    lng: 5.2852748851765,
    value: "3840",
  },
  {
    label: "Jesseren",
    lat: 50.8058717,
    lng: 5.3905151,
    value: "3840",
  },
  {
    label: "Kerniel",
    lat: 50.82372165,
    lng: 5.3616421143445,
    value: "3840",
  },
  {
    label: "Kolmont",
    lat: 50.8027416,
    lng: 5.4206775,
    value: "3840",
  },
  {
    label: "Kuttekoven",
    lat: 50.8140833,
    lng: 5.3310338354044,
    value: "3840",
  },
  {
    label: "Rijkel",
    lat: 50.80429285,
    lng: 5.2612885523105,
    value: "3840",
  },
  {
    label: "Voort",
    lat: 51.1674467,
    lng: 4.6797906,
    value: "3840",
  },
  {
    label: "Binderveld",
    lat: 50.86548295,
    lng: 5.1684479293466,
    value: "3850",
  },
  {
    label: "Kozen",
    lat: 50.8752909,
    lng: 5.2414389,
    value: "3850",
  },
  {
    label: "Nieuwerkerken",
    lat: 50.87457545,
    lng: 5.1909741787579,
    value: "3850",
  },
  {
    label: "Wijer",
    lat: 50.8972323,
    lng: 5.2233097,
    value: "3850",
  },
  {
    label: "Batsheers",
    lat: 50.7381794,
    lng: 5.2807989,
    value: "3870",
  },
  {
    label: "Bovelingen",
    lat: 50.7375944,
    lng: 5.2551977,
    value: "3870",
  },
  {
    label: "Heers",
    lat: 50.750995,
    lng: 5.3017509,
    value: "3870",
  },
  {
    label: "Heks",
    lat: 50.7687571,
    lng: 5.3567635,
    value: "3870",
  },
  {
    label: "Horpmaal",
    lat: 50.7582823,
    lng: 5.3328245,
    value: "3870",
  },
  {
    label: "Klein-Gelmen",
    lat: 50.7713536,
    lng: 5.2763925,
    value: "3870",
  },
  {
    label: "Mechelen-Bovelingen",
    lat: 50.7427937,
    lng: 5.2629291,
    value: "3870",
  },
  {
    label: "Mettekoven",
    lat: 50.7803053,
    lng: 5.2896635,
    value: "3870",
  },
  {
    label: "Opheers",
    lat: 50.7366903,
    lng: 5.2944311,
    value: "3870",
  },
  {
    label: "Rukkelingen-Loon",
    lat: 50.7278444,
    lng: 5.2539162,
    value: "3870",
  },
  {
    label: "Vechmaal",
    lat: 50.7608875,
    lng: 5.373733,
    value: "3870",
  },
  {
    label: "Veulen",
    lat: 50.7633827,
    lng: 5.3063809,
    value: "3870",
  },
  {
    label: "Boekhout",
    lat: 50.7456072,
    lng: 5.2314326932833,
    value: "3890",
  },
  {
    label: "Gingelom",
    lat: 50.7513838,
    lng: 5.1325789475434,
    value: "3890",
  },
  {
    label: "Jeuk",
    lat: 50.73257605,
    lng: 5.2095391018082,
    value: "3890",
  },
  {
    label: "Kortijs",
    lat: 50.7052793,
    lng: 5.1485502564418,
    value: "3890",
  },
  {
    label: "Montenaken",
    lat: 50.71838125,
    lng: 5.1386239079133,
    value: "3890",
  },
  {
    label: "Niel-Bij-Sint-Truiden",
    lat: 50.7404614,
    lng: 5.14193594683,
    value: "3890",
  },
  {
    label: "Vorsen",
    lat: 50.705232,
    lng: 5.1713288748516,
    value: "3890",
  },
  {
    label: "Borlo",
    lat: 50.7345174,
    lng: 5.1743785728014,
    value: "3891",
  },
  {
    label: "Buvingen",
    lat: 50.75022405,
    lng: 5.1788950800586,
    value: "3891",
  },
  {
    label: "Mielen-Boven-Aalst",
    lat: 50.7573342,
    lng: 5.2141041746035,
    value: "3891",
  },
  {
    label: "Muizen",
    lat: 50.7614659,
    lng: 5.1781361499823,
    value: "3891",
  },
  {
    label: "Overpelt",
    lat: 51.2113458,
    lng: 5.422976,
    value: "3900",
  },
  {
    label: "Neerpelt",
    lat: 51.2289746,
    lng: 5.4314419,
    value: "3910",
  },
  {
    label: "Sint-Huibrechts-Lille",
    lat: 51.2253075,
    lng: 5.4937001151083,
    value: "3910",
  },
  {
    label: "Lommel",
    lat: 51.2305657,
    lng: 5.3076895,
    value: "3920",
  },
  {
    label: "Achel",
    lat: 51.2684314,
    lng: 5.4792895720018,
    value: "3930",
  },
  {
    label: "Hamont",
    lat: 51.25458535,
    lng: 5.5312873136301,
    value: "3930",
  },
  {
    label: "Hamont-Achel",
    lat: 51.2547636,
    lng: 5.5128696,
    value: "3930",
  },
  {
    label: "Hechtel",
    lat: 51.11664635,
    lng: 5.3415643775263,
    value: "3940",
  },
  {
    label: "Hechtel-Eksel",
    lat: 51.11664635,
    lng: 5.3415643775263,
    value: "3940",
  },
  {
    label: "Eksel",
    lat: 51.1567865,
    lng: 5.3440439268887,
    value: "3941",
  },
  {
    label: "Ham",
    lat: 51.11417145,
    lng: 5.1781109743218,
    value: "3945",
  },
  {
    label: "Kwaadmechelen",
    lat: 51.0966275,
    lng: 5.1325674800534,
    value: "3945",
  },
  {
    label: "Oostham",
    lat: 51.10876505,
    lng: 5.1838556043202,
    value: "3945",
  },
  {
    label: "Bocholt",
    lat: 51.1924818,
    lng: 5.5995581946268,
    value: "3950",
  },
  {
    label: "Kaulille",
    lat: 51.19391715,
    lng: 5.5172805548337,
    value: "3950",
  },
  {
    label: "Reppel",
    lat: 51.1470494,
    lng: 5.5522997799873,
    value: "3950",
  },
  {
    label: "Beek",
    lat: 51.16613895,
    lng: 5.6378178047643,
    value: "3960",
  },
  {
    label: "Bree",
    lat: 51.1386239,
    lng: 5.6229805058514,
    value: "3960",
  },
  {
    label: "Gerdingen",
    lat: 51.1304481,
    lng: 5.5648743785157,
    value: "3960",
  },
  {
    label: "Opitter",
    lat: 51.1184171,
    lng: 5.6477954812084,
    value: "3960",
  },
  {
    label: "Tongerlo",
    lat: 51.1305517,
    lng: 5.6788533966978,
    value: "3960",
  },
  {
    label: "Leopoldsburg",
    lat: 51.1220584,
    lng: 5.2638924294785,
    value: "3970",
  },
  {
    label: "Heppen",
    lat: 51.11582725,
    lng: 5.2265355962879,
    value: "3971",
  },
  {
    label: "Tessenderlo",
    lat: 51.0681628,
    lng: 5.0893974,
    value: "3980",
  },
  {
    label: "Grote-Brogel",
    lat: 51.1420032,
    lng: 5.5087098376886,
    value: "3990",
  },
  {
    label: "Kleine-Brogel",
    lat: 51.1726086,
    lng: 5.4623610101885,
    value: "3990",
  },
  {
    label: "Peer",
    lat: 51.10874,
    lng: 5.4491572190061,
    value: "3990",
  },
  {
    label: "Wijchmaal",
    lat: 51.1272018,
    lng: 5.4147469555164,
    value: "3990",
  },
  {
    label: "Glain",
    lat: 50.648205,
    lng: 5.541864,
    value: "4000",
  },
  {
    label: "Li\u00e8ge",
    lat: 50.6451381,
    lng: 5.5734203,
    value: "4000",
  },
  {
    label: "Rocourt",
    lat: 50.678042,
    lng: 5.547616,
    value: "4000",
  },
  {
    label: "Bressoux",
    lat: 50.6412964,
    lng: 5.6111443,
    value: "4020",
  },
  {
    label: "Jupille-Sur-Meuse",
    lat: 50.6431909,
    lng: 5.6301266,
    value: "4020",
  },
  {
    label: "Li\u00e8ge",
    lat: 50.6451381,
    lng: 5.5734203,
    value: "4020",
  },
  {
    label: "Wandre",
    lat: 50.670247,
    lng: 5.6596627,
    value: "4020",
  },
  {
    label: "Grivegnee",
    lat: 50.621845,
    lng: 5.599573,
    value: "4030",
  },
  {
    label: "Angleur",
    lat: 50.6128164,
    lng: 5.5954054,
    value: "4031",
  },
  {
    label: "Ch\u00eanee",
    lat: 50.6111024,
    lng: 5.619261,
    value: "4032",
  },
  {
    label: "Herstal",
    lat: 50.6701887,
    lng: 5.6403906,
    value: "4040",
  },
  {
    label: "Milmort",
    lat: 50.6904632,
    lng: 5.5929155,
    value: "4041",
  },
  {
    label: "Vottem",
    lat: 50.672653,
    lng: 5.5846274,
    value: "4041",
  },
  {
    label: "Liers",
    lat: 50.6933806,
    lng: 5.563754,
    value: "4042",
  },
  {
    label: "Chaudfontaine",
    lat: 50.5847922,
    lng: 5.6469688,
    value: "4050",
  },
  {
    label: "Vaux-Sous-Ch\u00e8vremont",
    lat: 50.6018717,
    lng: 5.6335963,
    value: "4051",
  },
  {
    label: "Beaufays",
    lat: 50.5589633,
    lng: 5.6387759,
    value: "4052",
  },
  {
    label: "Embourg",
    lat: 50.5893639,
    lng: 5.619201,
    value: "4053",
  },
  {
    label: "Boncelles",
    lat: 50.5742979,
    lng: 5.5355698,
    value: "4100",
  },
  {
    label: "Seraing",
    lat: 50.5966392,
    lng: 5.5083375,
    value: "4100",
  },
  {
    label: "Jemeppe-Sur-Meuse",
    lat: 50.6168289,
    lng: 5.5049541,
    value: "4101",
  },
  {
    label: "Ougr\u00e9e",
    lat: 50.5992632,
    lng: 5.5389943,
    value: "4102",
  },
  {
    label: "Ehein",
    lat: 50.5445135,
    lng: 5.4444346,
    value: "4120",
  },
  {
    label: "Neupr\u00e9",
    lat: 50.5431751,
    lng: 5.4901079,
    value: "4120",
  },
  {
    label: "Rotheux-Rimi\u00e8re",
    lat: 50.5353385,
    lng: 5.4811796,
    value: "4120",
  },
  {
    label: "Neuville-En-Condroz",
    lat: 50.5520337,
    lng: 5.4514318,
    value: "4121",
  },
  {
    label: "Plainevaux",
    lat: 50.5446771,
    lng: 5.5215011,
    value: "4122",
  },
  {
    label: "Esneux",
    lat: 50.5337249,
    lng: 5.5757727440324,
    value: "4130",
  },
  {
    label: "Tilff",
    lat: 50.5613241,
    lng: 5.594232762079,
    value: "4130",
  },
  {
    label: "Dolembreux",
    lat: 50.53758625,
    lng: 5.628000939321,
    value: "4140",
  },
  {
    label: "Gomz\u00e9-Andoumont",
    lat: 50.54506305,
    lng: 5.6835772963268,
    value: "4140",
  },
  {
    label: "Rouvreux",
    lat: 50.4893408,
    lng: 5.6642552366155,
    value: "4140",
  },
  {
    label: "Sprimont",
    lat: 50.5065636,
    lng: 5.6466570762919,
    value: "4140",
  },
  {
    label: "Louveign\u00e9",
    lat: 50.5248417,
    lng: 5.7172477995985,
    value: "4141",
  },
  {
    label: "Anthisnes",
    lat: 50.481662,
    lng: 5.5225261,
    value: "4160",
  },
  {
    label: "Villers-Aux-Tours",
    lat: 50.4969412,
    lng: 5.5122974,
    value: "4161",
  },
  {
    label: "Hody",
    lat: 50.4869156,
    lng: 5.5009881,
    value: "4162",
  },
  {
    label: "Tavier",
    lat: 50.4960227,
    lng: 5.4707571,
    value: "4163",
  },
  {
    label: "Comblain-Au-Pont",
    lat: 50.474910743586,
    lng: 5.5764707268884,
    value: "4170",
  },
  {
    label: "Poulseur",
    lat: 50.5097627,
    lng: 5.5827847,
    value: "4171",
  },
  {
    label: "Comblain-Fairon",
    lat: 50.4454473,
    lng: 5.5428656,
    value: "4180",
  },
  {
    label: "Comblain-La-Tour",
    lat: 50.4553077,
    lng: 5.5699525,
    value: "4180",
  },
  {
    label: "Hamoir",
    lat: 50.4253958,
    lng: 5.5328465,
    value: "4180",
  },
  {
    label: "Filot",
    lat: 50.426751,
    lng: 5.5680501,
    value: "4181",
  },
  {
    label: "Ferri\u00e8res",
    lat: 50.3999541,
    lng: 5.605138,
    value: "4190",
  },
  {
    label: "My",
    lat: 50.40526,
    lng: 5.5726031,
    value: "4190",
  },
  {
    label: "Vieuxville",
    lat: 50.3955089,
    lng: 5.5512446,
    value: "4190",
  },
  {
    label: "Werbomont",
    lat: 50.3800099,
    lng: 5.686798,
    value: "4190",
  },
  {
    label: "Xhoris",
    lat: 50.443983,
    lng: 5.602069,
    value: "4190",
  },
  {
    label: "Burdinne",
    lat: 50.5812607,
    lng: 5.072436,
    value: "4210",
  },
  {
    label: "Hann\u00eache",
    lat: 50.5795921,
    lng: 5.0495231,
    value: "4210",
  },
  {
    label: "Lamontz\u00e9e",
    lat: 50.583727,
    lng: 5.0899261,
    value: "4210",
  },
  {
    label: "Marneffe",
    lat: 50.5790977,
    lng: 5.1448898,
    value: "4210",
  },
  {
    label: "Oteppe",
    lat: 50.5823574,
    lng: 5.1270159,
    value: "4210",
  },
  {
    label: "H\u00e9ron",
    lat: 50.5508203,
    lng: 5.0971783,
    value: "4217",
  },
  {
    label: "Lavoir",
    lat: 50.5487123,
    lng: 5.1257368,
    value: "4217",
  },
  {
    label: "Waret-L'ev\u00eaque",
    lat: 50.5557893,
    lng: 5.0671316,
    value: "4217",
  },
  {
    label: "Couthuin",
    lat: 50.5331675,
    lng: 5.1318708,
    value: "4218",
  },
  {
    label: "Acosse",
    lat: 50.5945676,
    lng: 5.0485902,
    value: "4219",
  },
  {
    label: "Ambresin",
    lat: 50.6266779,
    lng: 5.0305133,
    value: "4219",
  },
  {
    label: "Meeffe",
    lat: 50.608964,
    lng: 5.016335,
    value: "4219",
  },
  {
    label: "Wasseiges",
    lat: 50.6221762,
    lng: 5.006384,
    value: "4219",
  },
  {
    label: "Bo\u00eblhe",
    lat: 50.6841901,
    lng: 5.1679183,
    value: "4250",
  },
  {
    label: "Geer",
    lat: 50.6684881,
    lng: 5.1710529,
    value: "4250",
  },
  {
    label: "Hollogne-Sur-Geer",
    lat: 50.6770806,
    lng: 5.2045681,
    value: "4250",
  },
  {
    label: "Lens-Saint-Servais",
    lat: 50.6637114,
    lng: 5.16091,
    value: "4250",
  },
  {
    label: "Omal",
    lat: 50.6554534,
    lng: 5.1969427,
    value: "4252",
  },
  {
    label: "Darion",
    lat: 50.6649171,
    lng: 5.187047,
    value: "4253",
  },
  {
    label: "Ligney",
    lat: 50.66174,
    lng: 5.182381,
    value: "4254",
  },
  {
    label: "Berloz",
    lat: 50.6977331,
    lng: 5.2146261,
    value: "4257",
  },
  {
    label: "Corswarem",
    lat: 50.7095698,
    lng: 5.2131541,
    value: "4257",
  },
  {
    label: "Avennes",
    lat: 50.6287517,
    lng: 5.1159746,
    value: "4260",
  },
  {
    label: "Braives",
    lat: 50.6300711,
    lng: 5.1435257,
    value: "4260",
  },
  {
    label: "Ciplet",
    lat: 50.6171393,
    lng: 5.0953487,
    value: "4260",
  },
  {
    label: "Fallais",
    lat: 50.6093088,
    lng: 5.1738356,
    value: "4260",
  },
  {
    label: "Fumal",
    lat: 50.586887,
    lng: 5.184607,
    value: "4260",
  },
  {
    label: "Ville-En-Hesbaye",
    lat: 50.6182889,
    lng: 5.1157512,
    value: "4260",
  },
  {
    label: "Latinne",
    lat: 50.6242037,
    lng: 5.1632447,
    value: "4261",
  },
  {
    label: "Tourinne",
    lat: 50.639549,
    lng: 5.1765528,
    value: "4263",
  },
  {
    label: "Abolens",
    lat: 50.6720609,
    lng: 5.1498313,
    value: "4280",
  },
  {
    label: "Avernas-Le-Bauduin",
    lat: 50.694616,
    lng: 5.0789239,
    value: "4280",
  },
  {
    label: "Avin",
    lat: 50.620859,
    lng: 5.067671,
    value: "4280",
  },
  {
    label: "Bertr\u00e9e",
    lat: 50.6941776,
    lng: 5.089556,
    value: "4280",
  },
  {
    label: "Blehen",
    lat: 50.6629289,
    lng: 5.1255375,
    value: "4280",
  },
  {
    label: "Cras-Avernas",
    lat: 50.6978983,
    lng: 5.122837,
    value: "4280",
  },
  {
    label: "Crehen",
    lat: 50.6605954,
    lng: 5.0621464,
    value: "4280",
  },
  {
    label: "Grand-Hallet",
    lat: 50.6941211,
    lng: 5.0340611,
    value: "4280",
  },
  {
    label: "Hannut",
    lat: 50.6724649,
    lng: 5.0779916,
    value: "4280",
  },
  {
    label: "Lens-Saint-Remy",
    lat: 50.6534024,
    lng: 5.1329047,
    value: "4280",
  },
  {
    label: "Merdorp",
    lat: 50.6492972,
    lng: 4.9987365,
    value: "4280",
  },
  {
    label: "Moxhe",
    lat: 50.6300028,
    lng: 5.0813689,
    value: "4280",
  },
  {
    label: "Petit-Hallet",
    lat: 50.6875594,
    lng: 5.0184357,
    value: "4280",
  },
  {
    label: "Poucet",
    lat: 50.6777766,
    lng: 5.1124332,
    value: "4280",
  },
  {
    label: "Thisnes",
    lat: 50.6655625,
    lng: 5.0480724,
    value: "4280",
  },
  {
    label: "Trogn\u00e9e",
    lat: 50.6878033,
    lng: 5.1242082,
    value: "4280",
  },
  {
    label: "Villers-Le-Peuplier",
    lat: 50.656503,
    lng: 5.09579,
    value: "4280",
  },
  {
    label: "Wansin",
    lat: 50.678627,
    lng: 5.02018,
    value: "4280",
  },
  {
    label: "Lincent",
    lat: 50.711613,
    lng: 5.0318166,
    value: "4287",
  },
  {
    label: "Pellaines",
    lat: 50.7228078,
    lng: 5.0055524,
    value: "4287",
  },
  {
    label: "Racour",
    lat: 50.7388269,
    lng: 5.0290114,
    value: "4287",
  },
  {
    label: "Bettincourt",
    lat: 50.7099793,
    lng: 5.235361,
    value: "4300",
  },
  {
    label: "Bleret",
    lat: 50.6893285,
    lng: 5.2888884,
    value: "4300",
  },
  {
    label: "Bovenistier",
    lat: 50.6674954,
    lng: 5.2843336,
    value: "4300",
  },
  {
    label: "Grand-Axhe",
    lat: 50.6789987,
    lng: 5.2243853,
    value: "4300",
  },
  {
    label: "Lantremange",
    lat: 50.7114397,
    lng: 5.2947196,
    value: "4300",
  },
  {
    label: "Oleye",
    lat: 50.7125474,
    lng: 5.2808546,
    value: "4300",
  },
  {
    label: "Waremme",
    lat: 50.6976875,
    lng: 5.2546191,
    value: "4300",
  },
  {
    label: "Aineffe",
    lat: 50.6229561,
    lng: 5.256235,
    value: "4317",
  },
  {
    label: "Borlez",
    lat: 50.632984,
    lng: 5.245072,
    value: "4317",
  },
  {
    label: "Celles",
    lat: 50.6538847,
    lng: 5.2455346,
    value: "4317",
  },
  {
    label: "Faimes",
    lat: 50.665246,
    lng: 5.262322,
    value: "4317",
  },
  {
    label: "Les Waleffes",
    lat: 50.6402367,
    lng: 5.2180365,
    value: "4317",
  },
  {
    label: "Viemme",
    lat: 50.6482877,
    lng: 5.2683121,
    value: "4317",
  },
  {
    label: "Awans",
    lat: 50.6669712,
    lng: 5.4620018,
    value: "4340",
  },
  {
    label: "Fooz",
    lat: 50.6762489,
    lng: 5.4376155,
    value: "4340",
  },
  {
    label: "Oth\u00e9e",
    lat: 50.7159281,
    lng: 5.4680939,
    value: "4340",
  },
  {
    label: "Villers-L'ev\u00eaque",
    lat: 50.7028423,
    lng: 5.4418773,
    value: "4340",
  },
  {
    label: "Hognoul",
    lat: 50.6808099,
    lng: 5.4556394,
    value: "4342",
  },
  {
    label: "Fexhe-Le-Haut-Clocher",
    lat: 50.6660818,
    lng: 5.3985197,
    value: "4347",
  },
  {
    label: "Freloux",
    lat: 50.6803347,
    lng: 5.4066762,
    value: "4347",
  },
  {
    label: "Noville",
    lat: 50.6598522,
    lng: 5.3827164,
    value: "4347",
  },
  {
    label: "Roloux",
    lat: 50.6494928,
    lng: 5.3960063,
    value: "4347",
  },
  {
    label: "Voroux-Goreux",
    lat: 50.6547034,
    lng: 5.4271453,
    value: "4347",
  },
  {
    label: "Lamine",
    lat: 50.689076,
    lng: 5.333978,
    value: "4350",
  },
  {
    label: "Momalle",
    lat: 50.6852325,
    lng: 5.3730111,
    value: "4350",
  },
  {
    label: "Pousset",
    lat: 50.6956401,
    lng: 5.3034495,
    value: "4350",
  },
  {
    label: "Remicourt",
    lat: 50.6807151,
    lng: 5.3267174,
    value: "4350",
  },
  {
    label: "Hodeige",
    lat: 50.6960127,
    lng: 5.3426579,
    value: "4351",
  },
  {
    label: "Donceel",
    lat: 50.6478971,
    lng: 5.3199408,
    value: "4357",
  },
  {
    label: "Haneffe",
    lat: 50.6387144,
    lng: 5.3192131,
    value: "4357",
  },
  {
    label: "Jeneffe",
    lat: 50.6532256,
    lng: 5.3559477,
    value: "4357",
  },
  {
    label: "Limont",
    lat: 50.6600262,
    lng: 5.3107335,
    value: "4357",
  },
  {
    label: "Bergilers",
    lat: 50.7145424,
    lng: 5.3277139,
    value: "4360",
  },
  {
    label: "Grandville",
    lat: 50.7220498,
    lng: 5.3379451,
    value: "4360",
  },
  {
    label: "Lens-Sur-Geer",
    lat: 50.7214501,
    lng: 5.3537922,
    value: "4360",
  },
  {
    label: "Oreye",
    lat: 50.7291681,
    lng: 5.3530675,
    value: "4360",
  },
  {
    label: "Otrange",
    lat: 50.7370788,
    lng: 5.384458,
    value: "4360",
  },
  {
    label: "Crisn\u00e9e",
    lat: 50.715885,
    lng: 5.397318,
    value: "4367",
  },
  {
    label: "Fize-Le-Marsal",
    lat: 50.701353,
    lng: 5.386298,
    value: "4367",
  },
  {
    label: "Kemexhe",
    lat: 50.6961807,
    lng: 5.4060353,
    value: "4367",
  },
  {
    label: "Odeur",
    lat: 50.707654,
    lng: 5.4158293,
    value: "4367",
  },
  {
    label: "Thys",
    lat: 50.7225262,
    lng: 5.3894005,
    value: "4367",
  },
  {
    label: "Awirs",
    lat: 50.5985223,
    lng: 5.409295,
    value: "4400",
  },
  {
    label: "Chokier",
    lat: 50.5936483,
    lng: 5.4528969,
    value: "4400",
  },
  {
    label: "Fl\u00e9malle",
    lat: 50.6022808,
    lng: 5.4580872,
    value: "4400",
  },
  {
    label: "Fl\u00e9malle-Grande",
    lat: 50.6155715,
    lng: 5.4729319,
    value: "4400",
  },
  {
    label: "Fl\u00e9malle-Haute",
    lat: 50.6018434,
    lng: 5.4474684,
    value: "4400",
  },
  {
    label: "Ivoz-Ramet",
    lat: 50.5822766,
    lng: 5.4523491,
    value: "4400",
  },
  {
    label: "Mons-Lez-Li\u00e8ge",
    lat: 50.6224546,
    lng: 5.4600597,
    value: "4400",
  },
  {
    label: "Montegn\u00e9e",
    lat: 50.64257,
    lng: 5.5190161,
    value: "4420",
  },
  {
    label: "Saint-Nicolas",
    lat: 50.6305341,
    lng: 5.539419,
    value: "4420",
  },
  {
    label: "Tilleur",
    lat: 50.620651,
    lng: 5.5307061,
    value: "4420",
  },
  {
    label: "Ans",
    lat: 50.6624326,
    lng: 5.5190612,
    value: "4430",
  },
  {
    label: "Loncin",
    lat: 50.665153,
    lng: 5.502732,
    value: "4431",
  },
  {
    label: "Alleur",
    lat: 50.6742549,
    lng: 5.5129926,
    value: "4432",
  },
  {
    label: "Xhendremael",
    lat: 50.7043671,
    lng: 5.4805775,
    value: "4432",
  },
  {
    label: "Juprelle",
    lat: 50.7106926,
    lng: 5.5290689,
    value: "4450",
  },
  {
    label: "Lantin",
    lat: 50.6879619,
    lng: 5.5234944,
    value: "4450",
  },
  {
    label: "Slins",
    lat: 50.7277246,
    lng: 5.5652092,
    value: "4450",
  },
  {
    label: "Voroux-Lez-Liers",
    lat: 50.687631,
    lng: 5.552484,
    value: "4451",
  },
  {
    label: "Paifve",
    lat: 50.7280141,
    lng: 5.5241784,
    value: "4452",
  },
  {
    label: "Wihogne",
    lat: 50.7277451,
    lng: 5.5064112,
    value: "4452",
  },
  {
    label: "Villers-Saint-Sim\u00e9on",
    lat: 50.7097826,
    lng: 5.5434045,
    value: "4453",
  },
  {
    label: "Fexhe-Slins",
    lat: 50.7219179,
    lng: 5.5714611,
    value: "4458",
  },
  {
    label: "Bierset",
    lat: 50.6547149,
    lng: 5.4512607,
    value: "4460",
  },
  {
    label: "Gr\u00e2ce-Berleur",
    lat: 50.6323331,
    lng: 5.501194,
    value: "4460",
  },
  {
    label: "Gr\u00e2ce-Hollogne",
    lat: 50.644955,
    lng: 5.503152,
    value: "4460",
  },
  {
    label: "Hollogne-Aux-Pierres",
    lat: 50.6385937,
    lng: 5.4757847,
    value: "4460",
  },
  {
    label: "Horion-Hoz\u00e9mont",
    lat: 50.6149668,
    lng: 5.3886713,
    value: "4460",
  },
  {
    label: "Velroux",
    lat: 50.6436521,
    lng: 5.4254194,
    value: "4460",
  },
  {
    label: "Saint-Georges-Sur-Meuse",
    lat: 50.6000686,
    lng: 5.3579041,
    value: "4470",
  },
  {
    label: "Clermont-Sous-Huy",
    lat: 50.569046,
    lng: 5.3922,
    value: "4480",
  },
  {
    label: "Ehein",
    lat: 50.5445135,
    lng: 5.4444346,
    value: "4480",
  },
  {
    label: "Engis",
    lat: 50.582433,
    lng: 5.4043113,
    value: "4480",
  },
  {
    label: "Hermalle-Sous-Huy",
    lat: 50.5569911,
    lng: 5.3622542,
    value: "4480",
  },
  {
    label: "Ben-Ahin",
    lat: 50.5033196,
    lng: 5.1773498,
    value: "4500",
  },
  {
    label: "Huy",
    lat: 50.5215385,
    lng: 5.2357453,
    value: "4500",
  },
  {
    label: "Tihange",
    lat: 50.527586,
    lng: 5.259369,
    value: "4500",
  },
  {
    label: "Antheit",
    lat: 50.5512026,
    lng: 5.2358517,
    value: "4520",
  },
  {
    label: "Bas-Oha",
    lat: 50.522397,
    lng: 5.1880583,
    value: "4520",
  },
  {
    label: "Huccorgne",
    lat: 50.56759,
    lng: 5.1647622,
    value: "4520",
  },
  {
    label: "Moha",
    lat: 50.5471619,
    lng: 5.1878867,
    value: "4520",
  },
  {
    label: "Vinalmont",
    lat: 50.5627464,
    lng: 5.2261382,
    value: "4520",
  },
  {
    label: "Wanze",
    lat: 50.5332587,
    lng: 5.2166503,
    value: "4520",
  },
  {
    label: "Fize-Fontaine",
    lat: 50.5849842,
    lng: 5.2835083,
    value: "4530",
  },
  {
    label: "Vaux-Et-Borset",
    lat: 50.6131537,
    lng: 5.23141,
    value: "4530",
  },
  {
    label: "Vieux-Waleffe",
    lat: 50.6161817,
    lng: 5.2044866,
    value: "4530",
  },
  {
    label: "Villers-Le-Bouillet",
    lat: 50.5750242,
    lng: 5.2619239,
    value: "4530",
  },
  {
    label: "Warnant-Dreye",
    lat: 50.5951052,
    lng: 5.2254442,
    value: "4530",
  },
  {
    label: "Bodegn\u00e9e",
    lat: 50.5857931,
    lng: 5.3041269,
    value: "4537",
  },
  {
    label: "Chapon-Seraing",
    lat: 50.609673,
    lng: 5.2734472,
    value: "4537",
  },
  {
    label: "Seraing-Le-Ch\u00e2teau",
    lat: 50.6216405,
    lng: 5.2989318,
    value: "4537",
  },
  {
    label: "Verlaine",
    lat: 50.6075114,
    lng: 5.3184439,
    value: "4537",
  },
  {
    label: "Amay",
    lat: 50.5497761,
    lng: 5.3240992,
    value: "4540",
  },
  {
    label: "Ampsin",
    lat: 50.540238,
    lng: 5.286736,
    value: "4540",
  },
  {
    label: "Fl\u00f4ne",
    lat: 50.5582387,
    lng: 5.3354736,
    value: "4540",
  },
  {
    label: "Jehay",
    lat: 50.575487,
    lng: 5.3202782,
    value: "4540",
  },
  {
    label: "Ombret",
    lat: 50.5442809,
    lng: 5.3371708,
    value: "4540",
  },
  {
    label: "Nandrin",
    lat: 50.506355,
    lng: 5.4190411,
    value: "4550",
  },
  {
    label: "Saint-S\u00e9verin",
    lat: 50.5290054,
    lng: 5.4130878,
    value: "4550",
  },
  {
    label: "Villers-Le-Temple",
    lat: 50.5081151,
    lng: 5.3708571,
    value: "4550",
  },
  {
    label: "Yern\u00e9e-Fraineux",
    lat: 50.5284301,
    lng: 5.3819538,
    value: "4550",
  },
  {
    label: "Ab\u00e9e",
    lat: 50.4735257,
    lng: 5.3564571,
    value: "4557",
  },
  {
    label: "Fraiture",
    lat: 50.478693,
    lng: 5.4196671,
    value: "4557",
  },
  {
    label: "Ramelot",
    lat: 50.4644497,
    lng: 5.3286278,
    value: "4557",
  },
  {
    label: "Seny",
    lat: 50.4595451,
    lng: 5.4029395,
    value: "4557",
  },
  {
    label: "Soheit-Tinlot",
    lat: 50.4794492,
    lng: 5.3766541,
    value: "4557",
  },
  {
    label: "Tinlot",
    lat: 50.4777419,
    lng: 5.3786954,
    value: "4557",
  },
  {
    label: "Bois-Et-Borsu",
    lat: 50.3946271,
    lng: 5.3302671,
    value: "4560",
  },
  {
    label: "Clavier",
    lat: 50.4115085,
    lng: 5.3575692,
    value: "4560",
  },
  {
    label: "Les Avins",
    lat: 50.4154227,
    lng: 5.299356,
    value: "4560",
  },
  {
    label: "Ocquier",
    lat: 50.3965212,
    lng: 5.3954539,
    value: "4560",
  },
  {
    label: "Pailhe",
    lat: 50.4229708,
    lng: 5.2579288,
    value: "4560",
  },
  {
    label: "Terwagne",
    lat: 50.4438855,
    lng: 5.3491827,
    value: "4560",
  },
  {
    label: "Marchin",
    lat: 50.4802051,
    lng: 5.2263372,
    value: "4570",
  },
  {
    label: "Vyle-Et-Tharoul",
    lat: 50.4462845,
    lng: 5.2683004,
    value: "4570",
  },
  {
    label: "Modave",
    lat: 50.4416137,
    lng: 5.3017936,
    value: "4577",
  },
  {
    label: "Outrelouxhe",
    lat: 50.504232,
    lng: 5.335294,
    value: "4577",
  },
  {
    label: "Str\u00e9e-Lez-Huy",
    lat: 50.4922647,
    lng: 5.3271603,
    value: "4577",
  },
  {
    label: "Vierset-Barse",
    lat: 50.4811242,
    lng: 5.2948879,
    value: "4577",
  },
  {
    label: "Ellemelle",
    lat: 50.4664238,
    lng: 5.442913,
    value: "4590",
  },
  {
    label: "Ouffet",
    lat: 50.4377341,
    lng: 5.4653273,
    value: "4590",
  },
  {
    label: "Warz\u00e9e",
    lat: 50.4487876,
    lng: 5.427096,
    value: "4590",
  },
  {
    label: "Lanaye",
    lat: 50.7806558,
    lng: 5.6944381,
    value: "4600",
  },
  {
    label: "Lixhe",
    lat: 50.7550757,
    lng: 5.680109,
    value: "4600",
  },
  {
    label: "Richelle",
    lat: 50.7171434,
    lng: 5.695354,
    value: "4600",
  },
  {
    label: "Vis\u00e9",
    lat: 50.7336644,
    lng: 5.695536,
    value: "4600",
  },
  {
    label: "Argenteau",
    lat: 50.6957645,
    lng: 5.686015,
    value: "4601",
  },
  {
    label: "Cheratte",
    lat: 50.682809,
    lng: 5.670164,
    value: "4602",
  },
  {
    label: "Saint-Andr\u00e9",
    lat: 50.6953487,
    lng: 5.7536486,
    value: "4606",
  },
  {
    label: "Berneau",
    lat: 50.7423427,
    lng: 5.731253,
    value: "4607",
  },
  {
    label: "Bombaye",
    lat: 50.730153,
    lng: 5.7429371,
    value: "4607",
  },
  {
    label: "Dalhem",
    lat: 50.7139302,
    lng: 5.7229816,
    value: "4607",
  },
  {
    label: "Feneur",
    lat: 50.7041818,
    lng: 5.7138885,
    value: "4607",
  },
  {
    label: "Mortroux",
    lat: 50.7132408,
    lng: 5.7513854,
    value: "4607",
  },
  {
    label: "Neufch\u00e2teau",
    lat: 50.7195828,
    lng: 5.7764464,
    value: "4608",
  },
  {
    label: "Warsage",
    lat: 50.73466495,
    lng: 5.773045637996,
    value: "4608",
  },
  {
    label: "Bellaire",
    lat: 50.6447087,
    lng: 5.6654685,
    value: "4610",
  },
  {
    label: "Beyne-Heusay",
    lat: 50.6220566,
    lng: 5.6534141,
    value: "4610",
  },
  {
    label: "Queue-Du-Bois",
    lat: 50.6371513,
    lng: 5.6785831,
    value: "4610",
  },
  {
    label: "Fl\u00e9ron",
    lat: 50.6168354,
    lng: 5.6832219,
    value: "4620",
  },
  {
    label: "Retinne",
    lat: 50.6302993,
    lng: 5.6978166,
    value: "4621",
  },
  {
    label: "Magn\u00e9e",
    lat: 50.6028765,
    lng: 5.6811009,
    value: "4623",
  },
  {
    label: "Roms\u00e9e",
    lat: 50.6068155,
    lng: 5.6655488,
    value: "4624",
  },
  {
    label: "Ayeneux",
    lat: 50.6087645,
    lng: 5.7147473,
    value: "4630",
  },
  {
    label: "Micheroux",
    lat: 50.6315865,
    lng: 5.7355403,
    value: "4630",
  },
  {
    label: "Soumagne",
    lat: 50.6147718,
    lng: 5.739941,
    value: "4630",
  },
  {
    label: "Tign\u00e9e",
    lat: 50.6493983,
    lng: 5.7042703,
    value: "4630",
  },
  {
    label: "Evegn\u00e9e",
    lat: 50.6418082,
    lng: 5.7060994,
    value: "4631",
  },
  {
    label: "C\u00e9rexhe-Heuseux",
    lat: 50.6508837,
    lng: 5.7262388,
    value: "4632",
  },
  {
    label: "Melen",
    lat: 50.6460841,
    lng: 5.7370469,
    value: "4633",
  },
  {
    label: "Chaineux",
    lat: 50.6319367,
    lng: 5.8342711,
    value: "4650",
  },
  {
    label: "Grand-Rechain",
    lat: 50.6072867,
    lng: 5.8107674,
    value: "4650",
  },
  {
    label: "Herve",
    lat: 50.6392112,
    lng: 5.7935245,
    value: "4650",
  },
  {
    label: "Jul\u00e9mont",
    lat: 50.6856398,
    lng: 5.7718682,
    value: "4650",
  },
  {
    label: "Battice",
    lat: 50.6482697,
    lng: 5.8194813,
    value: "4651",
  },
  {
    label: "Xhendelesse",
    lat: 50.6037018,
    lng: 5.7726472,
    value: "4652",
  },
  {
    label: "Bolland",
    lat: 50.6614055,
    lng: 5.7594413,
    value: "4653",
  },
  {
    label: "Charneux",
    lat: 50.669117,
    lng: 5.8048204,
    value: "4654",
  },
  {
    label: "Bl\u00e9gny",
    lat: 50.6727185,
    lng: 5.7252372,
    value: "4670",
  },
  {
    label: "Mortier",
    lat: 50.6821815,
    lng: 5.7432715,
    value: "4670",
  },
  {
    label: "Trembleur",
    lat: 50.6953295,
    lng: 5.7268086,
    value: "4670",
  },
  {
    label: "Barchon",
    lat: 50.6682189,
    lng: 5.6969122,
    value: "4671",
  },
  {
    label: "Housse",
    lat: 50.6796259,
    lng: 5.693985,
    value: "4671",
  },
  {
    label: "Saive",
    lat: 50.6525478,
    lng: 5.6820303,
    value: "4671",
  },
  {
    label: "Saint-Remy",
    lat: 50.6958409,
    lng: 5.700802,
    value: "4672",
  },
  {
    label: "Herm\u00e9e",
    lat: 50.7087526,
    lng: 5.6186068,
    value: "4680",
  },
  {
    label: "Oupeye",
    lat: 50.7091889,
    lng: 5.6450671,
    value: "4680",
  },
  {
    label: "Hermalle-Sous-Argenteau",
    lat: 50.710544,
    lng: 5.6808711,
    value: "4681",
  },
  {
    label: "Heure-Le-Romain",
    lat: 50.7316969,
    lng: 5.6295554,
    value: "4682",
  },
  {
    label: "Houtain-Saint-Sim\u00e9on",
    lat: 50.7391753,
    lng: 5.6104199,
    value: "4682",
  },
  {
    label: "Vivegnis",
    lat: 50.697932,
    lng: 5.6527071,
    value: "4683",
  },
  {
    label: "Haccourt",
    lat: 50.7339784,
    lng: 5.6667674,
    value: "4684",
  },
  {
    label: "Bassenge",
    lat: 50.7583596,
    lng: 5.6094772,
    value: "4690",
  },
  {
    label: "Boirs",
    lat: 50.7539251,
    lng: 5.5788622,
    value: "4690",
  },
  {
    label: "Eben-Emael",
    lat: 50.7994409,
    lng: 5.6702417,
    value: "4690",
  },
  {
    label: "Glons",
    lat: 50.7518981,
    lng: 5.5398432,
    value: "4690",
  },
  {
    label: "Roclenge-Sur-Geer",
    lat: 50.756864,
    lng: 5.59407,
    value: "4690",
  },
  {
    label: "Wonck",
    lat: 50.7677078,
    lng: 5.6333621,
    value: "4690",
  },
  {
    label: "Eupen",
    lat: 50.6306134,
    lng: 6.031452,
    value: "4700",
  },
  {
    label: "Kettenis",
    lat: 50.6461317,
    lng: 6.0458005,
    value: "4701",
  },
  {
    label: "Lontzen",
    lat: 50.6804233,
    lng: 6.0073447,
    value: "4710",
  },
  {
    label: "Walhorn",
    lat: 50.6750768,
    lng: 6.0469422,
    value: "4711",
  },
  {
    label: "La Calamine",
    lat: 50.7154957,
    lng: 6.013341,
    value: "4720",
  },
  {
    label: "Neu-Moresnet",
    lat: 50.7199902,
    lng: 6.0240702,
    value: "4721",
  },
  {
    label: "Hergenrath",
    lat: 50.7091279,
    lng: 6.0316891,
    value: "4728",
  },
  {
    label: "Hauset",
    lat: 50.7080172,
    lng: 6.0708402,
    value: "4730",
  },
  {
    label: "Raeren",
    lat: 50.6761305,
    lng: 6.1108404,
    value: "4730",
  },
  {
    label: "Eynatten",
    lat: 50.6935863,
    lng: 6.081697,
    value: "4731",
  },
  {
    label: "Butgenbach",
    lat: 50.4267259,
    lng: 6.2053573,
    value: "4750",
  },
  {
    label: "Elsenborn",
    lat: 50.4573116,
    lng: 6.2213827,
    value: "4750",
  },
  {
    label: "Bullange",
    lat: 50.4075533,
    lng: 6.257827,
    value: "4760",
  },
  {
    label: "Manderfeld",
    lat: 50.3310938,
    lng: 6.3400555,
    value: "4760",
  },
  {
    label: "Rocherath",
    lat: 50.4344078,
    lng: 6.3009381,
    value: "4761",
  },
  {
    label: "Ambl\u00e8ve",
    lat: 50.3543055,
    lng: 6.1705559,
    value: "4770",
  },
  {
    label: "Meyerode",
    lat: 50.3288415,
    lng: 6.1884932,
    value: "4770",
  },
  {
    label: "Heppenbach",
    lat: 50.3631661,
    lng: 6.218691,
    value: "4771",
  },
  {
    label: "Recht",
    lat: 50.3349273,
    lng: 6.0431296,
    value: "4780",
  },
  {
    label: "Saint-Vith",
    lat: 50.2803881,
    lng: 6.1258953,
    value: "4780",
  },
  {
    label: "Schoenberg",
    lat: 50.2888095,
    lng: 6.2642498,
    value: "4782",
  },
  {
    label: "Lommersweiler",
    lat: 50.2389236,
    lng: 6.1651484,
    value: "4783",
  },
  {
    label: "Crombach",
    lat: 50.2591318,
    lng: 6.0680547,
    value: "4784",
  },
  {
    label: "Burg-Reuland",
    lat: 50.174780874297,
    lng: 6.1216793580592,
    value: "4790",
  },
  {
    label: "Reuland",
    lat: 50.1954121,
    lng: 6.1362567,
    value: "4790",
  },
  {
    label: "Thommen",
    lat: 50.2190586,
    lng: 6.0743425,
    value: "4791",
  },
  {
    label: "Ensival",
    lat: 50.582172,
    lng: 5.843182,
    value: "4800",
  },
  {
    label: "Lambermont",
    lat: 49.7051899,
    lng: 5.191001,
    value: "4800",
  },
  {
    label: "Petit-Rechain",
    lat: 50.6143044,
    lng: 5.8341784,
    value: "4800",
  },
  {
    label: "Polleur",
    lat: 50.542907,
    lng: 5.873929354985,
    value: "4800",
  },
  {
    label: "Verviers",
    lat: 50.5932274,
    lng: 5.8638544,
    value: "4800",
  },
  {
    label: "Stembert",
    lat: 50.593109,
    lng: 5.8952801,
    value: "4801",
  },
  {
    label: "Heusy",
    lat: 50.57804,
    lng: 5.867539,
    value: "4802",
  },
  {
    label: "Dison",
    lat: 50.611374,
    lng: 5.854735,
    value: "4820",
  },
  {
    label: "Andrimont",
    lat: 50.4386821,
    lng: 5.8901888,
    value: "4821",
  },
  {
    label: "Limbourg",
    lat: 50.6122761,
    lng: 5.9402987,
    value: "4830",
  },
  {
    label: "Bilstain",
    lat: 50.623535,
    lng: 5.9212234,
    value: "4831",
  },
  {
    label: "Go\u00e9",
    lat: 50.6086202,
    lng: 5.9562188,
    value: "4834",
  },
  {
    label: "Baelen",
    lat: 50.6311898,
    lng: 5.9715011,
    value: "4837",
  },
  {
    label: "Membach",
    lat: 50.6193792,
    lng: 5.9953656,
    value: "4837",
  },
  {
    label: "Welkenraedt",
    lat: 50.6627086,
    lng: 5.9723396,
    value: "4840",
  },
  {
    label: "Henri-Chapelle",
    lat: 50.6768234,
    lng: 5.9318018,
    value: "4841",
  },
  {
    label: "Jalhay",
    lat: 50.5592528,
    lng: 5.9647526,
    value: "4845",
  },
  {
    label: "Sart-Lez-Spa",
    lat: 50.51744,
    lng: 5.933482,
    value: "4845",
  },
  {
    label: "Montzen",
    lat: 50.7075126,
    lng: 5.9620321,
    value: "4850",
  },
  {
    label: "Moresnet",
    lat: 50.7208739,
    lng: 5.9880362,
    value: "4850",
  },
  {
    label: "Plombi\u00e8res",
    lat: 50.7374801,
    lng: 5.9590725,
    value: "4850",
  },
  {
    label: "Gemmenich",
    lat: 50.7463908,
    lng: 5.9967631,
    value: "4851",
  },
  {
    label: "Sippenaeken",
    lat: 50.7509012,
    lng: 5.9336285,
    value: "4851",
  },
  {
    label: "Hombourg",
    lat: 50.723243,
    lng: 5.9207511,
    value: "4852",
  },
  {
    label: "Cornesse",
    lat: 50.5747936,
    lng: 5.7910525,
    value: "4860",
  },
  {
    label: "Pepinster",
    lat: 50.5675217,
    lng: 5.8037101,
    value: "4860",
  },
  {
    label: "Wegnez",
    lat: 50.5789617,
    lng: 5.8175559,
    value: "4860",
  },
  {
    label: "Soiron",
    lat: 50.5921673,
    lng: 5.7906072,
    value: "4861",
  },
  {
    label: "For\u00eat",
    lat: 50.583997,
    lng: 5.7002354,
    value: "4870",
  },
  {
    label: "Fraipont",
    lat: 50.5663484,
    lng: 5.7227768,
    value: "4870",
  },
  {
    label: "Nessonvaux",
    lat: 50.573671,
    lng: 5.7371309,
    value: "4870",
  },
  {
    label: "Trooz",
    lat: 50.5728102,
    lng: 5.6883726,
    value: "4870",
  },
  {
    label: "Olne",
    lat: 50.5901199,
    lng: 5.7479948,
    value: "4877",
  },
  {
    label: "Aubel",
    lat: 50.7041498,
    lng: 5.8585553,
    value: "4880",
  },
  {
    label: "Clermont",
    lat: 50.6589677,
    lng: 5.883668,
    value: "4890",
  },
  {
    label: "Thimister",
    lat: 50.6529465,
    lng: 5.8650125,
    value: "4890",
  },
  {
    label: "Thimister-Clermont",
    lat: 50.66298735,
    lng: 5.8634486463407,
    value: "4890",
  },
  {
    label: "Spa",
    lat: 50.4920838,
    lng: 5.8626233,
    value: "4900",
  },
  {
    label: "La Reid",
    lat: 50.4894593,
    lng: 5.7905664,
    value: "4910",
  },
  {
    label: "Polleur",
    lat: 50.542907,
    lng: 5.873929354985,
    value: "4910",
  },
  {
    label: "Theux",
    lat: 50.52586075,
    lng: 5.8198955843753,
    value: "4910",
  },
  {
    label: "Aywaille",
    lat: 50.4734827,
    lng: 5.6742167,
    value: "4920",
  },
  {
    label: "Ernonheid",
    lat: 50.4028521,
    lng: 5.6660069,
    value: "4920",
  },
  {
    label: "Harz\u00e9",
    lat: 50.4409138,
    lng: 5.6672743,
    value: "4920",
  },
  {
    label: "Louveign\u00e9",
    lat: 50.5248417,
    lng: 5.7172477995985,
    value: "4920",
  },
  {
    label: "Faymonville",
    lat: 50.4054037,
    lng: 6.1407071,
    value: "4950",
  },
  {
    label: "Robertville",
    lat: 50.4550294,
    lng: 6.1224661,
    value: "4950",
  },
  {
    label: "Sourbrodt",
    lat: 50.4772534,
    lng: 6.1143694,
    value: "4950",
  },
  {
    label: "Waimes",
    lat: 50.4149134,
    lng: 6.1117543,
    value: "4950",
  },
  {
    label: "Beverc\u00e9",
    lat: 50.4415348,
    lng: 6.0391879,
    value: "4960",
  },
  {
    label: "Malmedy",
    lat: 50.4239904,
    lng: 6.0266001,
    value: "4960",
  },
  {
    label: "Francorchamps",
    lat: 50.4531568,
    lng: 5.952833,
    value: "4970",
  },
  {
    label: "Stavelot",
    lat: 50.3940846,
    lng: 5.9308362,
    value: "4970",
  },
  {
    label: "Fosse",
    lat: 50.3388474,
    lng: 5.8378154,
    value: "4980",
  },
  {
    label: "Trois-Ponts",
    lat: 50.3727015,
    lng: 5.8704355,
    value: "4980",
  },
  {
    label: "Wanne",
    lat: 50.3557319,
    lng: 5.9213974,
    value: "4980",
  },
  {
    label: "Basse-Bodeux",
    lat: 50.3579736,
    lng: 5.826789,
    value: "4983",
  },
  {
    label: "Chevron",
    lat: 50.3819564,
    lng: 5.7299282,
    value: "4987",
  },
  {
    label: "La Gleize",
    lat: 50.4128545,
    lng: 5.8459307,
    value: "4987",
  },
  {
    label: "Lorc\u00e9",
    lat: 50.4145319,
    lng: 5.7321495,
    value: "4987",
  },
  {
    label: "Rahier",
    lat: 50.3860353,
    lng: 5.7786187,
    value: "4987",
  },
  {
    label: "Stoumont",
    lat: 50.4069504,
    lng: 5.80836,
    value: "4987",
  },
  {
    label: "Arbrefontaine",
    lat: 50.3012606,
    lng: 5.8353999,
    value: "4990",
  },
  {
    label: "Bra",
    lat: 50.3208964,
    lng: 5.7309407,
    value: "4990",
  },
  {
    label: "Lierneux",
    lat: 50.28574,
    lng: 5.7920929,
    value: "4990",
  },
  {
    label: "Beez",
    lat: 50.4687313,
    lng: 4.9219558,
    value: "5000",
  },
  {
    label: "Namur",
    lat: 50.45620385,
    lng: 4.849314651989,
    value: "5000",
  },
  {
    label: "Belgrade",
    lat: 50.46923485,
    lng: 4.8222958522639,
    value: "5001",
  },
  {
    label: "Saint-Servais",
    lat: 50.48141855,
    lng: 4.8351266178881,
    value: "5002",
  },
  {
    label: "Saint-Marc",
    lat: 50.4948666,
    lng: 4.8469587132153,
    value: "5003",
  },
  {
    label: "Bouge",
    lat: 50.47887385,
    lng: 4.891664450549,
    value: "5004",
  },
  {
    label: "Champion",
    lat: 50.49862565,
    lng: 4.9035112119756,
    value: "5020",
  },
  {
    label: "Daussoulx",
    lat: 50.51724395,
    lng: 4.8769847282413,
    value: "5020",
  },
  {
    label: "Flawinne",
    lat: 50.456602,
    lng: 4.8120768,
    value: "5020",
  },
  {
    label: "Malonne",
    lat: 50.4382517,
    lng: 4.7966661,
    value: "5020",
  },
  {
    label: "Suarl\u00e9e",
    lat: 50.4883008,
    lng: 4.7780268559488,
    value: "5020",
  },
  {
    label: "Temploux",
    lat: 50.4829793,
    lng: 4.7509248,
    value: "5020",
  },
  {
    label: "Vedrin",
    lat: 50.5019599,
    lng: 4.8742389,
    value: "5020",
  },
  {
    label: "Boninne",
    lat: 50.49239555,
    lng: 4.9291903523068,
    value: "5021",
  },
  {
    label: "Cognel\u00e9e",
    lat: 50.5171876,
    lng: 4.9127293838106,
    value: "5022",
  },
  {
    label: "Gelbress\u00e9e",
    lat: 50.5113799,
    lng: 4.9578287274527,
    value: "5024",
  },
  {
    label: "Marche-Les-Dames",
    lat: 50.4864453,
    lng: 4.9615056,
    value: "5024",
  },
  {
    label: "Beuzet",
    lat: 50.5329777,
    lng: 4.7481633,
    value: "5030",
  },
  {
    label: "Ernage",
    lat: 50.5941971,
    lng: 4.6739886,
    value: "5030",
  },
  {
    label: "Gembloux",
    lat: 50.5621964,
    lng: 4.6986933030313,
    value: "5030",
  },
  {
    label: "Grand-Manil",
    lat: 50.556923,
    lng: 4.683029,
    value: "5030",
  },
  {
    label: "Lonz\u00e9e",
    lat: 50.5533536,
    lng: 4.7273547,
    value: "5030",
  },
  {
    label: "Sauveni\u00e8re",
    lat: 50.5810646,
    lng: 4.7248074,
    value: "5030",
  },
  {
    label: "Grand-Leez",
    lat: 50.57820735,
    lng: 4.7609593378472,
    value: "5031",
  },
  {
    label: "Bossi\u00e8re",
    lat: 50.52340745,
    lng: 4.6919946070362,
    value: "5032",
  },
  {
    label: "Bothey",
    lat: 50.521822,
    lng: 4.6524505,
    value: "5032",
  },
  {
    label: "Corroy-Le-Ch\u00e2teau",
    lat: 50.5359706,
    lng: 4.6546979,
    value: "5032",
  },
  {
    label: "Isnes",
    lat: 50.5105186,
    lng: 4.7406918,
    value: "5032",
  },
  {
    label: "Mazy",
    lat: 50.5127674,
    lng: 4.6745108,
    value: "5032",
  },
  {
    label: "Arsimont",
    lat: 50.4273479,
    lng: 4.6388177,
    value: "5060",
  },
  {
    label: "Auvelais",
    lat: 50.4470193,
    lng: 4.6354624,
    value: "5060",
  },
  {
    label: "Falisolle",
    lat: 50.4182456,
    lng: 4.621259,
    value: "5060",
  },
  {
    label: "Keumi\u00e9e",
    lat: 50.4655148,
    lng: 4.5960062,
    value: "5060",
  },
  {
    label: "Moignel\u00e9e",
    lat: 50.4378354,
    lng: 4.5885625,
    value: "5060",
  },
  {
    label: "Sambreville",
    lat: 50.4409806,
    lng: 4.6196692,
    value: "5060",
  },
  {
    label: "Tamines",
    lat: 50.4340969,
    lng: 4.607595,
    value: "5060",
  },
  {
    label: "Velaine-Sur-Sambre",
    lat: 50.4713313,
    lng: 4.60725,
    value: "5060",
  },
  {
    label: "Aisemont",
    lat: 50.4048557,
    lng: 4.6513189,
    value: "5070",
  },
  {
    label: "Fosses-La-Ville",
    lat: 50.3959512,
    lng: 4.6978935,
    value: "5070",
  },
  {
    label: "Le Roux",
    lat: 50.3885408,
    lng: 4.6237797,
    value: "5070",
  },
  {
    label: "Sart-Eustache",
    lat: 50.3760693,
    lng: 4.5998195,
    value: "5070",
  },
  {
    label: "Sart-Saint-Laurent",
    lat: 50.4027187,
    lng: 4.7411668,
    value: "5070",
  },
  {
    label: "Vitrival",
    lat: 50.3941596,
    lng: 4.6558828,
    value: "5070",
  },
  {
    label: "Emines",
    lat: 50.5157255,
    lng: 4.8279560785714,
    value: "5080",
  },
  {
    label: "La Bruy\u00e8re",
    lat: 50.4133942,
    lng: 5.7266989,
    value: "5080",
  },
  {
    label: "Rhisnes",
    lat: 50.5005948,
    lng: 4.7909700280289,
    value: "5080",
  },
  {
    label: "Villers-Lez-Heest",
    lat: 50.5379166,
    lng: 4.8345691476021,
    value: "5080",
  },
  {
    label: "Warisoulx",
    lat: 50.5348173,
    lng: 4.8587823219021,
    value: "5080",
  },
  {
    label: "Bovesse",
    lat: 50.51440005,
    lng: 4.7787022193209,
    value: "5081",
  },
  {
    label: "Meux",
    lat: 50.552563,
    lng: 4.7986774536609,
    value: "5081",
  },
  {
    label: "Dave",
    lat: 50.4110871,
    lng: 4.9043004277231,
    value: "5100",
  },
  {
    label: "Jambes",
    lat: 50.458883332027,
    lng: 4.8785294244785,
    value: "5100",
  },
  {
    label: "Naninne",
    lat: 50.4146082,
    lng: 4.9316703499006,
    value: "5100",
  },
  {
    label: "W\u00e9pion",
    lat: 50.4212957,
    lng: 4.8633768,
    value: "5100",
  },
  {
    label: "Wierde",
    lat: 50.4286275,
    lng: 4.9497445929374,
    value: "5100",
  },
  {
    label: "Erpent",
    lat: 50.4474046,
    lng: 4.9094515331252,
    value: "5101",
  },
  {
    label: "Lives-Sur-Meuse",
    lat: 50.47132665,
    lng: 4.9384059263709,
    value: "5101",
  },
  {
    label: "Loyers",
    lat: 50.4580989,
    lng: 4.9409795174479,
    value: "5101",
  },
  {
    label: "Boign\u00e9e",
    lat: 50.5018413,
    lng: 4.6119938,
    value: "5140",
  },
  {
    label: "Ligny",
    lat: 50.5126287,
    lng: 4.5757572,
    value: "5140",
  },
  {
    label: "Sombreffe",
    lat: 50.5246589,
    lng: 4.6025925,
    value: "5140",
  },
  {
    label: "Tongrinne",
    lat: 50.5157258,
    lng: 4.623887,
    value: "5140",
  },
  {
    label: "Floreffe",
    lat: 50.4348064,
    lng: 4.758878,
    value: "5150",
  },
  {
    label: "Floriffoux",
    lat: 50.4515822,
    lng: 4.7694348,
    value: "5150",
  },
  {
    label: "Frani\u00e8re",
    lat: 50.4389093,
    lng: 4.7317891,
    value: "5150",
  },
  {
    label: "Soye",
    lat: 50.4494828,
    lng: 4.730789,
    value: "5150",
  },
  {
    label: "Arbre",
    lat: 50.365529,
    lng: 4.820627,
    value: "5170",
  },
  {
    label: "Bois-De-Villers",
    lat: 50.389973,
    lng: 4.823377,
    value: "5170",
  },
  {
    label: "Lesve",
    lat: 50.3773499,
    lng: 4.7748094,
    value: "5170",
  },
  {
    label: "Lustin",
    lat: 50.379305,
    lng: 4.8965971,
    value: "5170",
  },
  {
    label: "Profondeville",
    lat: 50.3776473,
    lng: 4.8694363,
    value: "5170",
  },
  {
    label: "Rivi\u00e8re",
    lat: 50.3580729,
    lng: 4.8724268,
    value: "5170",
  },
  {
    label: "Bal\u00e2tre",
    lat: 50.4983123,
    lng: 4.6382422,
    value: "5190",
  },
  {
    label: "Ham-Sur-Sambre",
    lat: 50.44449,
    lng: 4.6734915,
    value: "5190",
  },
  {
    label: "Jemeppe-Sur-Sambre",
    lat: 50.46299585,
    lng: 4.6545986012407,
    value: "5190",
  },
  {
    label: "Mornimont",
    lat: 50.4555129,
    lng: 4.7043344,
    value: "5190",
  },
  {
    label: "Moustier-Sur-Sambre",
    lat: 50.4660542,
    lng: 4.6967162,
    value: "5190",
  },
  {
    label: "Onoz",
    lat: 50.4923374,
    lng: 4.6696856,
    value: "5190",
  },
  {
    label: "Saint-Martin",
    lat: 50.5008367,
    lng: 4.6472621,
    value: "5190",
  },
  {
    label: "Spy",
    lat: 50.4811696,
    lng: 4.7027047,
    value: "5190",
  },
  {
    label: "Andenne",
    lat: 50.4900943,
    lng: 5.1043923,
    value: "5300",
  },
  {
    label: "Bonneville",
    lat: 50.4709296,
    lng: 5.0338525,
    value: "5300",
  },
  {
    label: "Coutisse",
    lat: 50.4636595,
    lng: 5.1169603,
    value: "5300",
  },
  {
    label: "Landenne",
    lat: 50.5142495,
    lng: 5.0646275,
    value: "5300",
  },
  {
    label: "Maizeret",
    lat: 50.4598458,
    lng: 4.978173,
    value: "5300",
  },
  {
    label: "Nam\u00eache",
    lat: 50.4712487,
    lng: 4.9947316,
    value: "5300",
  },
  {
    label: "Sclayn",
    lat: 50.4897139,
    lng: 5.0284735,
    value: "5300",
  },
  {
    label: "Seilles",
    lat: 50.4984249,
    lng: 5.0805501,
    value: "5300",
  },
  {
    label: "Thon",
    lat: 50.4641615,
    lng: 5.0133577,
    value: "5300",
  },
  {
    label: "Vezin",
    lat: 50.4966018,
    lng: 5.0114611,
    value: "5300",
  },
  {
    label: "Aische-En-Refail",
    lat: 50.5988682,
    lng: 4.8359955,
    value: "5310",
  },
  {
    label: "Bolinne",
    lat: 50.6003478,
    lng: 4.9299565,
    value: "5310",
  },
  {
    label: "Boneffe",
    lat: 50.623868,
    lng: 4.957465,
    value: "5310",
  },
  {
    label: "Branchon",
    lat: 50.629691,
    lng: 4.971969,
    value: "5310",
  },
  {
    label: "Dhuy",
    lat: 50.5596814,
    lng: 4.8573789,
    value: "5310",
  },
  {
    label: "Eghez\u00e9e",
    lat: 50.5917416,
    lng: 4.9049523,
    value: "5310",
  },
  {
    label: "Hanret",
    lat: 50.5834955,
    lng: 4.9440702,
    value: "5310",
  },
  {
    label: "Leuze",
    lat: 50.5605615,
    lng: 4.9103111,
    value: "5310",
  },
  {
    label: "Liernu",
    lat: 50.5821124,
    lng: 4.8311032,
    value: "5310",
  },
  {
    label: "Longchamps",
    lat: 50.5772925,
    lng: 4.8962185,
    value: "5310",
  },
  {
    label: "Mehaigne",
    lat: 50.5942203,
    lng: 4.8762345,
    value: "5310",
  },
  {
    label: "Noville-Sur-M\u00e9haigne",
    lat: 50.6082112,
    lng: 4.8909225,
    value: "5310",
  },
  {
    label: "Saint-Germain",
    lat: 50.5730646,
    lng: 4.8427332,
    value: "5310",
  },
  {
    label: "Taviers",
    lat: 50.6170592,
    lng: 4.9318579,
    value: "5310",
  },
  {
    label: "Upigny",
    lat: 50.5756144,
    lng: 4.8653991,
    value: "5310",
  },
  {
    label: "Waret-La-Chauss\u00e9e",
    lat: 50.5417091,
    lng: 4.9217571,
    value: "5310",
  },
  {
    label: "Assesse",
    lat: 50.3704203,
    lng: 5.0233625,
    value: "5330",
  },
  {
    label: "Maillen",
    lat: 50.3775518,
    lng: 4.9699385,
    value: "5330",
  },
  {
    label: "Sart-Bernard",
    lat: 50.405155,
    lng: 4.9506941,
    value: "5330",
  },
  {
    label: "Crupet",
    lat: 50.3468626,
    lng: 4.9581149,
    value: "5332",
  },
  {
    label: "Sorinne-La-Longue",
    lat: 50.3896858,
    lng: 5.0294375,
    value: "5333",
  },
  {
    label: "Flor\u00e9e",
    lat: 50.3730757,
    lng: 5.0704373,
    value: "5334",
  },
  {
    label: "Courri\u00e8re",
    lat: 50.378301,
    lng: 4.9845433,
    value: "5336",
  },
  {
    label: "Faulx-Les-Tombes",
    lat: 50.4272928,
    lng: 5.0191438,
    value: "5340",
  },
  {
    label: "Gesves",
    lat: 50.4028535,
    lng: 5.0776175,
    value: "5340",
  },
  {
    label: "Haltinne",
    lat: 50.4508568,
    lng: 5.0744138,
    value: "5340",
  },
  {
    label: "Mozet",
    lat: 50.4416402,
    lng: 4.9846704,
    value: "5340",
  },
  {
    label: "Sor\u00e9e",
    lat: 50.4001817,
    lng: 5.1258806,
    value: "5340",
  },
  {
    label: "Evelette",
    lat: 50.4135772,
    lng: 5.171436,
    value: "5350",
  },
  {
    label: "Ohey",
    lat: 50.4359066,
    lng: 5.1238439,
    value: "5350",
  },
  {
    label: "Haillot",
    lat: 50.4405961,
    lng: 5.1491871,
    value: "5351",
  },
  {
    label: "Goesnes",
    lat: 50.440101,
    lng: 5.2217845,
    value: "5353",
  },
  {
    label: "Jallet",
    lat: 50.4432621,
    lng: 5.1821601,
    value: "5354",
  },
  {
    label: "Hamois",
    lat: 50.3408694,
    lng: 5.1594475,
    value: "5360",
  },
  {
    label: "Natoye",
    lat: 50.3413559,
    lng: 5.0562173,
    value: "5360",
  },
  {
    label: "Mohiville",
    lat: 50.3176664,
    lng: 5.1923275,
    value: "5361",
  },
  {
    label: "Scy",
    lat: 50.3079186,
    lng: 5.2094413,
    value: "5361",
  },
  {
    label: "Achet",
    lat: 50.3334777,
    lng: 5.1751963,
    value: "5362",
  },
  {
    label: "Emptinne",
    lat: 50.3253905,
    lng: 5.1212547,
    value: "5363",
  },
  {
    label: "Schaltin",
    lat: 50.3579006,
    lng: 5.1237159,
    value: "5364",
  },
  {
    label: "Barvaux-Condroz",
    lat: 50.3291239,
    lng: 5.2607234,
    value: "5370",
  },
  {
    label: "Flostoy",
    lat: 50.3883757,
    lng: 5.1845059,
    value: "5370",
  },
  {
    label: "Havelange",
    lat: 50.3870946,
    lng: 5.23958,
    value: "5370",
  },
  {
    label: "Jeneffe",
    lat: 50.3563281,
    lng: 5.2451952,
    value: "5370",
  },
  {
    label: "Porcheresse",
    lat: 50.3384304,
    lng: 5.2408578,
    value: "5370",
  },
  {
    label: "Verl\u00e9e",
    lat: 50.3675402,
    lng: 5.2795649,
    value: "5370",
  },
  {
    label: "M\u00e9an",
    lat: 50.3619241,
    lng: 5.333856,
    value: "5372",
  },
  {
    label: "Maffe",
    lat: 50.3533462,
    lng: 5.3125869,
    value: "5374",
  },
  {
    label: "Mi\u00e9cret",
    lat: 50.365689,
    lng: 5.24941,
    value: "5376",
  },
  {
    label: "Baillonville",
    lat: 50.288759,
    lng: 5.338213,
    value: "5377",
  },
  {
    label: "Bonsin",
    lat: 50.3727364,
    lng: 5.3788758,
    value: "5377",
  },
  {
    label: "Heure",
    lat: 50.2945343,
    lng: 5.2963125,
    value: "5377",
  },
  {
    label: "Hogne",
    lat: 50.24859,
    lng: 5.279464,
    value: "5377",
  },
  {
    label: "Nettinne",
    lat: 50.2910362,
    lng: 5.2612996,
    value: "5377",
  },
  {
    label: "Noiseux",
    lat: 50.2976637,
    lng: 5.3737372,
    value: "5377",
  },
  {
    label: "Sinsin",
    lat: 50.275734,
    lng: 5.2623625,
    value: "5377",
  },
  {
    label: "Somme-Leuze",
    lat: 50.336585,
    lng: 5.3669021,
    value: "5377",
  },
  {
    label: "Waillet",
    lat: 50.260584,
    lng: 5.304196,
    value: "5377",
  },
  {
    label: "Bierwart",
    lat: 50.5580545,
    lng: 5.0360917,
    value: "5380",
  },
  {
    label: "Cortil-Wodon",
    lat: 50.567323,
    lng: 4.958369,
    value: "5380",
  },
  {
    label: "Fernelmont",
    lat: 50.548118,
    lng: 4.9746742,
    value: "5380",
  },
  {
    label: "Forville",
    lat: 50.574642,
    lng: 5.0006655,
    value: "5380",
  },
  {
    label: "Franc-Waret",
    lat: 50.5210841,
    lng: 4.9759589,
    value: "5380",
  },
  {
    label: "Hemptinne",
    lat: 50.6010427,
    lng: 4.9869761,
    value: "5380",
  },
  {
    label: "Hingeon",
    lat: 50.5244901,
    lng: 5.008801,
    value: "5380",
  },
  {
    label: "Marchovelette",
    lat: 50.5231886,
    lng: 4.9411438,
    value: "5380",
  },
  {
    label: "Noville-Les-Bois",
    lat: 50.5555805,
    lng: 4.982708,
    value: "5380",
  },
  {
    label: "Pontillas",
    lat: 50.5500591,
    lng: 5.01707,
    value: "5380",
  },
  {
    label: "Tillier",
    lat: 50.544287,
    lng: 4.946342,
    value: "5380",
  },
  {
    label: "Anseremme",
    lat: 50.23791,
    lng: 4.907923,
    value: "5500",
  },
  {
    label: "Bouvignes-Sur-Meuse",
    lat: 50.2725677,
    lng: 4.8984129,
    value: "5500",
  },
  {
    label: "Dinant",
    lat: 50.2608166,
    lng: 4.91242,
    value: "5500",
  },
  {
    label: "Dr\u00e9hance",
    lat: 50.2368273,
    lng: 4.9388148,
    value: "5500",
  },
  {
    label: "Falmagne",
    lat: 50.2003101,
    lng: 4.8971396,
    value: "5500",
  },
  {
    label: "Falmignoul",
    lat: 50.2037856,
    lng: 4.8924373,
    value: "5500",
  },
  {
    label: "Furfooz",
    lat: 50.223335,
    lng: 4.9584822,
    value: "5500",
  },
  {
    label: "Lisogne",
    lat: 50.2814293,
    lng: 4.9715412,
    value: "5501",
  },
  {
    label: "Thynes",
    lat: 50.2798224,
    lng: 4.9882689,
    value: "5502",
  },
  {
    label: "Sorinnes",
    lat: 50.260159,
    lng: 4.982409,
    value: "5503",
  },
  {
    label: "Foy-Notre-Dame",
    lat: 50.2471757,
    lng: 4.9897127,
    value: "5504",
  },
  {
    label: "Anth\u00e9e",
    lat: 50.2395304,
    lng: 4.7604034,
    value: "5520",
  },
  {
    label: "Onhaye",
    lat: 50.2435657,
    lng: 4.840864,
    value: "5520",
  },
  {
    label: "Serville",
    lat: 50.2500754,
    lng: 4.7806526,
    value: "5521",
  },
  {
    label: "Falaen",
    lat: 50.2782156,
    lng: 4.793357,
    value: "5522",
  },
  {
    label: "Sommi\u00e8re",
    lat: 50.274944,
    lng: 4.8454743,
    value: "5523",
  },
  {
    label: "Weillen",
    lat: 50.2596445,
    lng: 4.8245344,
    value: "5523",
  },
  {
    label: "Gerin",
    lat: 50.2452911,
    lng: 4.8158102,
    value: "5524",
  },
  {
    label: "Dorinne",
    lat: 50.3173377,
    lng: 4.97325,
    value: "5530",
  },
  {
    label: "Durnal",
    lat: 50.3380783,
    lng: 4.9857278,
    value: "5530",
  },
  {
    label: "Evrehailles",
    lat: 50.3202787,
    lng: 4.9125689,
    value: "5530",
  },
  {
    label: "Godinne",
    lat: 50.3505281,
    lng: 4.875987,
    value: "5530",
  },
  {
    label: "Houx",
    lat: 50.3031942,
    lng: 4.8978933,
    value: "5530",
  },
  {
    label: "Mont",
    lat: 50.5341607,
    lng: 5.7909538,
    value: "5530",
  },
  {
    label: "Purnode",
    lat: 50.3142896,
    lng: 4.9455473,
    value: "5530",
  },
  {
    label: "Spontin",
    lat: 50.322619,
    lng: 5.006949,
    value: "5530",
  },
  {
    label: "Yvoir",
    lat: 50.3264425,
    lng: 4.8806618,
    value: "5530",
  },
  {
    label: "Anh\u00e9e",
    lat: 50.310778,
    lng: 4.8796936,
    value: "5537",
  },
  {
    label: "Annevoie-Rouillon",
    lat: 50.343103,
    lng: 4.841866,
    value: "5537",
  },
  {
    label: "Bioul",
    lat: 50.3335584,
    lng: 4.7976225,
    value: "5537",
  },
  {
    label: "Den\u00e9e",
    lat: 50.3179627,
    lng: 4.7512903,
    value: "5537",
  },
  {
    label: "Haut-Le-Wastia",
    lat: 50.3053165,
    lng: 4.8417104,
    value: "5537",
  },
  {
    label: "Sosoye",
    lat: 50.2955787,
    lng: 4.7815722,
    value: "5537",
  },
  {
    label: "Warnant",
    lat: 50.3238206,
    lng: 4.836084,
    value: "5537",
  },
  {
    label: "Hasti\u00e8re",
    lat: 50.1973562,
    lng: 4.8225541872005,
    value: "5540",
  },
  {
    label: "Hasti\u00e8re-Lavaux",
    lat: 50.216541,
    lng: 4.8242423,
    value: "5540",
  },
  {
    label: "Hermeton-Sur-Meuse",
    lat: 50.1967082,
    lng: 4.8189189,
    value: "5540",
  },
  {
    label: "Waulsort",
    lat: 50.2032886,
    lng: 4.8632452,
    value: "5540",
  },
  {
    label: "Hasti\u00e8re-Par-Del\u00e0",
    lat: 50.2150767,
    lng: 4.8294854,
    value: "5541",
  },
  {
    label: "Blaimont",
    lat: 50.192216,
    lng: 4.8352693,
    value: "5542",
  },
  {
    label: "Heer",
    lat: 50.161698,
    lng: 4.831747,
    value: "5543",
  },
  {
    label: "Agimont",
    lat: 50.16421,
    lng: 4.7942801,
    value: "5544",
  },
  {
    label: "Alle",
    lat: 49.8421207,
    lng: 4.9726784,
    value: "5550",
  },
  {
    label: "Bagimont",
    lat: 49.8241964,
    lng: 4.8751221,
    value: "5550",
  },
  {
    label: "Bohan",
    lat: 49.864219,
    lng: 4.8860106,
    value: "5550",
  },
  {
    label: "Chairi\u00e8re",
    lat: 49.8612272,
    lng: 4.9489546,
    value: "5550",
  },
  {
    label: "Laforet",
    lat: 49.8639125,
    lng: 4.9294849,
    value: "5550",
  },
  {
    label: "Membre",
    lat: 49.8655398,
    lng: 4.9002694,
    value: "5550",
  },
  {
    label: "Mouzaive",
    lat: 49.8534637,
    lng: 4.9620137,
    value: "5550",
  },
  {
    label: "Nafraiture",
    lat: 49.9106502,
    lng: 4.9172157,
    value: "5550",
  },
  {
    label: "Orchimont",
    lat: 49.8936753,
    lng: 4.9274653,
    value: "5550",
  },
  {
    label: "Pussemange",
    lat: 49.8110791,
    lng: 4.8697858,
    value: "5550",
  },
  {
    label: "Sugny",
    lat: 49.8140011,
    lng: 4.9032847,
    value: "5550",
  },
  {
    label: "Vresse-Sur-Semois",
    lat: 49.8729769,
    lng: 4.9340633,
    value: "5550",
  },
  {
    label: "Baillamont",
    lat: 49.8994469,
    lng: 5.0267634,
    value: "5555",
  },
  {
    label: "Bellefontaine",
    lat: 49.9177,
    lng: 4.973101,
    value: "5555",
  },
  {
    label: "Bi\u00e8vre",
    lat: 49.9411216,
    lng: 5.0168496,
    value: "5555",
  },
  {
    label: "Cornimont",
    lat: 49.8626123,
    lng: 4.9979774,
    value: "5555",
  },
  {
    label: "Graide",
    lat: 49.9514336,
    lng: 5.0674675,
    value: "5555",
  },
  {
    label: "Gros-Fays",
    lat: 49.869524,
    lng: 4.9830915,
    value: "5555",
  },
  {
    label: "Monceau-En-Ardenne",
    lat: 49.9046481,
    lng: 4.9859857,
    value: "5555",
  },
  {
    label: "Naom\u00e9",
    lat: 49.9226206,
    lng: 5.0894297,
    value: "5555",
  },
  {
    label: "Oizy",
    lat: 49.8934868,
    lng: 5.0089274,
    value: "5555",
  },
  {
    label: "Petit-Fays",
    lat: 49.9015726,
    lng: 4.9675442,
    value: "5555",
  },
  {
    label: "Ciergnon",
    lat: 50.1666464,
    lng: 5.0899109,
    value: "5560",
  },
  {
    label: "Finnevaux",
    lat: 50.1581671,
    lng: 4.9452635,
    value: "5560",
  },
  {
    label: "Houyet",
    lat: 50.1900646,
    lng: 5.0051364,
    value: "5560",
  },
  {
    label: "Hulsonniaux",
    lat: 50.201583,
    lng: 4.947825,
    value: "5560",
  },
  {
    label: "Mesnil-Eglise",
    lat: 50.1635695,
    lng: 4.9616357,
    value: "5560",
  },
  {
    label: "Mesnil-Saint-Blaise",
    lat: 50.1671763,
    lng: 4.8854633,
    value: "5560",
  },
  {
    label: "Celles",
    lat: 50.2312617,
    lng: 5.0058339,
    value: "5561",
  },
  {
    label: "Custinne",
    lat: 50.2117661,
    lng: 5.0484175,
    value: "5562",
  },
  {
    label: "Hour",
    lat: 50.1613066,
    lng: 5.0372408,
    value: "5563",
  },
  {
    label: "Wanlin",
    lat: 50.1593381,
    lng: 5.061512,
    value: "5564",
  },
  {
    label: "Baronville",
    lat: 50.1262206,
    lng: 4.9468551,
    value: "5570",
  },
  {
    label: "Beauraing",
    lat: 50.1105211,
    lng: 4.9576144,
    value: "5570",
  },
  {
    label: "Dion",
    lat: 50.1178162,
    lng: 4.8883798,
    value: "5570",
  },
  {
    label: "Felenne",
    lat: 50.0680742,
    lng: 4.8490158,
    value: "5570",
  },
  {
    label: "Feschaux",
    lat: 50.15093,
    lng: 4.910576,
    value: "5570",
  },
  {
    label: "Honnay",
    lat: 50.0666286,
    lng: 5.0123245,
    value: "5570",
  },
  {
    label: "Javingue",
    lat: 50.1018962,
    lng: 4.9187569,
    value: "5570",
  },
  {
    label: "Von\u00eache",
    lat: 50.0608665,
    lng: 4.9800374,
    value: "5570",
  },
  {
    label: "Wancennes",
    lat: 50.0920374,
    lng: 4.961015,
    value: "5570",
  },
  {
    label: "Winenne",
    lat: 50.0980233,
    lng: 4.8930998,
    value: "5570",
  },
  {
    label: "Wiesme",
    lat: 50.1487792,
    lng: 4.9767431,
    value: "5571",
  },
  {
    label: "Focant",
    lat: 50.1314538,
    lng: 5.0408552,
    value: "5572",
  },
  {
    label: "Martouzin-Neuville",
    lat: 50.1176141,
    lng: 5.003949,
    value: "5573",
  },
  {
    label: "Pondr\u00f4me",
    lat: 50.099609,
    lng: 5.008842,
    value: "5574",
  },
  {
    label: "Bourseigne-Neuve",
    lat: 50.0250695,
    lng: 4.855026,
    value: "5575",
  },
  {
    label: "Bourseigne-Vieille",
    lat: 50.0221032,
    lng: 4.871198,
    value: "5575",
  },
  {
    label: "Gedinne",
    lat: 49.9792667,
    lng: 4.9388966,
    value: "5575",
  },
  {
    label: "Houdremont",
    lat: 49.942041,
    lng: 4.9445943,
    value: "5575",
  },
  {
    label: "Louette-Saint-Denis",
    lat: 49.9589147,
    lng: 4.9572488,
    value: "5575",
  },
  {
    label: "Louette-Saint-Pierre",
    lat: 49.960145,
    lng: 4.9276767,
    value: "5575",
  },
  {
    label: "Malvoisin",
    lat: 50.0121497,
    lng: 4.9609972,
    value: "5575",
  },
  {
    label: "Patignies",
    lat: 49.9999798,
    lng: 4.9521201,
    value: "5575",
  },
  {
    label: "Rienne",
    lat: 49.9922547,
    lng: 4.8847815,
    value: "5575",
  },
  {
    label: "Sart-Custinne",
    lat: 50.0014538,
    lng: 4.9175574,
    value: "5575",
  },
  {
    label: "Vencimont",
    lat: 50.0323702,
    lng: 4.9198406,
    value: "5575",
  },
  {
    label: "Willerzie",
    lat: 49.9874142,
    lng: 4.8475671,
    value: "5575",
  },
  {
    label: "Froidfontaine",
    lat: 50.0604028,
    lng: 5.000634,
    value: "5576",
  },
  {
    label: "Ave-Et-Auffe",
    lat: 50.11195305,
    lng: 5.1530857761777,
    value: "5580",
  },
  {
    label: "Buissonville",
    lat: 50.2172183,
    lng: 5.1958839,
    value: "5580",
  },
  {
    label: "Eprave",
    lat: 50.1502721,
    lng: 5.1713525249725,
    value: "5580",
  },
  {
    label: "Han-Sur-Lesse",
    lat: 50.1254505,
    lng: 5.1877385,
    value: "5580",
  },
  {
    label: "Jemelle",
    lat: 50.1624278,
    lng: 5.2624584,
    value: "5580",
  },
  {
    label: "Lessive",
    lat: 50.13800485,
    lng: 5.1458087816947,
    value: "5580",
  },
  {
    label: "Mont-Gauthier",
    lat: 50.2092771,
    lng: 5.120824,
    value: "5580",
  },
  {
    label: "Rochefort",
    lat: 50.1725817,
    lng: 5.2140311511356,
    value: "5580",
  },
  {
    label: "Villers-Sur-Lesse",
    lat: 50.1442751,
    lng: 5.1050287385834,
    value: "5580",
  },
  {
    label: "Wavreille",
    lat: 50.11639205,
    lng: 5.2419236738324,
    value: "5580",
  },
  {
    label: "Ach\u00eane",
    lat: 50.2668644,
    lng: 5.0458772,
    value: "5590",
  },
  {
    label: "Braibant",
    lat: 50.3129076,
    lng: 5.0629626,
    value: "5590",
  },
  {
    label: "Chevetogne",
    lat: 50.223801,
    lng: 5.1188335,
    value: "5590",
  },
  {
    label: "Ciney",
    lat: 50.2949558,
    lng: 5.0974251,
    value: "5590",
  },
  {
    label: "Conneux",
    lat: 50.2496153,
    lng: 5.0603857,
    value: "5590",
  },
  {
    label: "Haversin",
    lat: 50.2531051,
    lng: 5.208871,
    value: "5590",
  },
  {
    label: "Leignon",
    lat: 50.2687357,
    lng: 5.1102616,
    value: "5590",
  },
  {
    label: "Pessoux",
    lat: 50.2825466,
    lng: 5.1707753,
    value: "5590",
  },
  {
    label: "Serinchamps",
    lat: 50.2319269,
    lng: 5.2343634,
    value: "5590",
  },
  {
    label: "Sovet",
    lat: 50.296303,
    lng: 5.0349178,
    value: "5590",
  },
  {
    label: "Fagnolle",
    lat: 50.1050216,
    lng: 4.5703749,
    value: "5600",
  },
  {
    label: "Franchimont",
    lat: 50.1900466,
    lng: 4.6393972,
    value: "5600",
  },
  {
    label: "Jamagne",
    lat: 50.2180956,
    lng: 4.531286,
    value: "5600",
  },
  {
    label: "Jamiolle",
    lat: 50.2122411,
    lng: 4.5079027,
    value: "5600",
  },
  {
    label: "Merlemont",
    lat: 50.1728667,
    lng: 4.6072911,
    value: "5600",
  },
  {
    label: "Neuville",
    lat: 50.3868446,
    lng: 5.7242282,
    value: "5600",
  },
  {
    label: "Omez\u00e9e",
    lat: 50.1933288,
    lng: 4.6995791,
    value: "5600",
  },
  {
    label: "Philippeville",
    lat: 50.195588,
    lng: 4.5449033,
    value: "5600",
  },
  {
    label: "Roly",
    lat: 50.1356527,
    lng: 4.5381501,
    value: "5600",
  },
  {
    label: "Romedenne",
    lat: 50.1724056,
    lng: 4.6979645,
    value: "5600",
  },
  {
    label: "Samart",
    lat: 50.17794,
    lng: 4.534019,
    value: "5600",
  },
  {
    label: "Sart-En-Fagne",
    lat: 50.1579167,
    lng: 4.6199447,
    value: "5600",
  },
  {
    label: "Sautour",
    lat: 50.1694005,
    lng: 4.5612425,
    value: "5600",
  },
  {
    label: "Surice",
    lat: 50.1824646,
    lng: 4.6962604,
    value: "5600",
  },
  {
    label: "Villers-En-Fagne",
    lat: 50.1457902,
    lng: 4.5846227,
    value: "5600",
  },
  {
    label: "Villers-Le-Gambon",
    lat: 50.1904615,
    lng: 4.6084845,
    value: "5600",
  },
  {
    label: "Vodec\u00e9e",
    lat: 50.1972001,
    lng: 4.5920788,
    value: "5600",
  },
  {
    label: "Corenne",
    lat: 50.2519501,
    lng: 4.6792792,
    value: "5620",
  },
  {
    label: "Flavion",
    lat: 50.2496186,
    lng: 4.7134091,
    value: "5620",
  },
  {
    label: "Florennes",
    lat: 50.2514227,
    lng: 4.6030187,
    value: "5620",
  },
  {
    label: "Hemptinne-Lez-Florennes",
    lat: 50.2282738,
    lng: 4.5631279,
    value: "5620",
  },
  {
    label: "Morville",
    lat: 50.2330003,
    lng: 4.7440112,
    value: "5620",
  },
  {
    label: "Ros\u00e9e",
    lat: 50.2323028,
    lng: 4.6876566,
    value: "5620",
  },
  {
    label: "Saint-Aubin",
    lat: 50.2469253,
    lng: 4.5778492,
    value: "5620",
  },
  {
    label: "Hanzinelle",
    lat: 50.2949873,
    lng: 4.5607099,
    value: "5621",
  },
  {
    label: "Hanzinne",
    lat: 50.3084666,
    lng: 4.5435524,
    value: "5621",
  },
  {
    label: "Morialm\u00e9",
    lat: 50.2776442,
    lng: 4.5634203,
    value: "5621",
  },
  {
    label: "Cerfontaine",
    lat: 50.1687764,
    lng: 4.4125932,
    value: "5630",
  },
  {
    label: "Daussois",
    lat: 50.218825,
    lng: 4.455439,
    value: "5630",
  },
  {
    label: "Senzeille",
    lat: 50.1762834,
    lng: 4.466208,
    value: "5630",
  },
  {
    label: "Silenrieux",
    lat: 50.2251302,
    lng: 4.4103561,
    value: "5630",
  },
  {
    label: "Soumoy",
    lat: 50.1896906,
    lng: 4.4366472,
    value: "5630",
  },
  {
    label: "Villers-Deux-Eglises",
    lat: 50.189702,
    lng: 4.4827391,
    value: "5630",
  },
  {
    label: "Biesme",
    lat: 50.3346461,
    lng: 4.6073303,
    value: "5640",
  },
  {
    label: "Biesmer\u00e9e",
    lat: 50.297376,
    lng: 4.6803521,
    value: "5640",
  },
  {
    label: "Graux",
    lat: 50.325597,
    lng: 4.7203188,
    value: "5640",
  },
  {
    label: "Mettet",
    lat: 50.3212094,
    lng: 4.6584949,
    value: "5640",
  },
  {
    label: "Oret",
    lat: 50.3004056,
    lng: 4.615806,
    value: "5640",
  },
  {
    label: "Saint-G\u00e9rard",
    lat: 50.3463414,
    lng: 4.7395306,
    value: "5640",
  },
  {
    label: "Furnaux",
    lat: 50.3080016,
    lng: 4.7055271,
    value: "5641",
  },
  {
    label: "Ermeton-Sur-Biert",
    lat: 50.2975439,
    lng: 4.7197934,
    value: "5644",
  },
  {
    label: "Stave",
    lat: 50.2828212,
    lng: 4.6590441,
    value: "5646",
  },
  {
    label: "Castillon",
    lat: 50.2472813,
    lng: 4.3528279,
    value: "5650",
  },
  {
    label: "Chastr\u00e8s",
    lat: 50.2649096,
    lng: 4.4592009,
    value: "5650",
  },
  {
    label: "Clermont",
    lat: 50.2598853,
    lng: 4.3173399,
    value: "5650",
  },
  {
    label: "Fontenelle",
    lat: 50.2490122,
    lng: 4.3827342,
    value: "5650",
  },
  {
    label: "Fraire",
    lat: 50.2613846,
    lng: 4.5081639,
    value: "5650",
  },
  {
    label: "Pry",
    lat: 50.273814,
    lng: 4.4326804,
    value: "5650",
  },
  {
    label: "Vogen\u00e9e",
    lat: 50.2398955,
    lng: 4.4523592,
    value: "5650",
  },
  {
    label: "Walcourt",
    lat: 50.2532022,
    lng: 4.4347509,
    value: "5650",
  },
  {
    label: "Yves-Gomez\u00e9e",
    lat: 50.2392649,
    lng: 4.4947935,
    value: "5650",
  },
  {
    label: "Berz\u00e9e",
    lat: 50.2892396,
    lng: 4.4012206,
    value: "5651",
  },
  {
    label: "Gourdinne",
    lat: 50.2899588,
    lng: 4.456597,
    value: "5651",
  },
  {
    label: "Laneffe",
    lat: 50.2778386,
    lng: 4.4950207,
    value: "5651",
  },
  {
    label: "Rogn\u00e9e",
    lat: 50.2691529,
    lng: 4.3892146,
    value: "5651",
  },
  {
    label: "Somz\u00e9e",
    lat: 50.2951613,
    lng: 4.4829777,
    value: "5651",
  },
  {
    label: "Tarcienne",
    lat: 50.3119341,
    lng: 4.4983015,
    value: "5651",
  },
  {
    label: "Thy-Le-Ch\u00e2teau",
    lat: 50.2827004,
    lng: 4.4278716,
    value: "5651",
  },
  {
    label: "Aublain",
    lat: 50.0672297,
    lng: 4.4090395,
    value: "5660",
  },
  {
    label: "Boussu-En-Fagne",
    lat: 50.0754838,
    lng: 4.4710191,
    value: "5660",
  },
  {
    label: "Br\u00fbly",
    lat: 49.9695906,
    lng: 4.5296813,
    value: "5660",
  },
  {
    label: "Br\u00fbly-De-Pesche",
    lat: 50.0009202,
    lng: 4.4602842,
    value: "5660",
  },
  {
    label: "Couvin",
    lat: 50.0524906,
    lng: 4.4955009,
    value: "5660",
  },
  {
    label: "Cul-Des-Sarts",
    lat: 49.9615402,
    lng: 4.4549981,
    value: "5660",
  },
  {
    label: "Dailly",
    lat: 50.0577541,
    lng: 4.4360525,
    value: "5660",
  },
  {
    label: "Frasnes",
    lat: 50.0796144,
    lng: 4.5105493,
    value: "5660",
  },
  {
    label: "Gonrieux",
    lat: 50.0374018,
    lng: 4.4236449,
    value: "5660",
  },
  {
    label: "Mariembourg",
    lat: 50.0941307,
    lng: 4.5233186,
    value: "5660",
  },
  {
    label: "Pesche",
    lat: 50.0431267,
    lng: 4.4584819,
    value: "5660",
  },
  {
    label: "Petigny",
    lat: 50.0583821,
    lng: 4.5324267,
    value: "5660",
  },
  {
    label: "Petite-Chapelle",
    lat: 49.949789,
    lng: 4.505529,
    value: "5660",
  },
  {
    label: "Presgaux",
    lat: 50.0247898,
    lng: 4.4206168,
    value: "5660",
  },
  {
    label: "Dourbes",
    lat: 50.091356,
    lng: 4.59145,
    value: "5670",
  },
  {
    label: "Le Mesnil",
    lat: 50.030867,
    lng: 4.6712991,
    value: "5670",
  },
  {
    label: "Maz\u00e9e",
    lat: 50.1008805,
    lng: 4.6964875,
    value: "5670",
  },
  {
    label: "Nismes",
    lat: 50.0745275,
    lng: 4.5486173,
    value: "5670",
  },
  {
    label: "Oignies-En-Thi\u00e9rache",
    lat: 50.0237221,
    lng: 4.6396988,
    value: "5670",
  },
  {
    label: "Olloy-Sur-Viroin",
    lat: 50.0726831,
    lng: 4.607218,
    value: "5670",
  },
  {
    label: "Treignes",
    lat: 50.0922698,
    lng: 4.6692715,
    value: "5670",
  },
  {
    label: "Vierves-Sur-Viroin",
    lat: 50.080269,
    lng: 4.634249,
    value: "5670",
  },
  {
    label: "Viroinval",
    lat: 50.0516361,
    lng: 4.6270287,
    value: "5670",
  },
  {
    label: "Doische",
    lat: 50.1357152,
    lng: 4.7458219,
    value: "5680",
  },
  {
    label: "Gimn\u00e9e",
    lat: 50.1315986,
    lng: 4.7141334,
    value: "5680",
  },
  {
    label: "Gochen\u00e9e",
    lat: 50.1841874,
    lng: 4.7597215,
    value: "5680",
  },
  {
    label: "Matagne-La-Grande",
    lat: 50.1171293,
    lng: 4.6236448,
    value: "5680",
  },
  {
    label: "Matagne-La-Petite",
    lat: 50.1186008,
    lng: 4.6468816,
    value: "5680",
  },
  {
    label: "Niverl\u00e9e",
    lat: 50.1177554,
    lng: 4.7026857,
    value: "5680",
  },
  {
    label: "Romer\u00e9e",
    lat: 50.135223,
    lng: 4.6748777,
    value: "5680",
  },
  {
    label: "Soulme",
    lat: 50.1873423,
    lng: 4.7367955,
    value: "5680",
  },
  {
    label: "Vaucelles",
    lat: 50.1131712,
    lng: 4.7424981,
    value: "5680",
  },
  {
    label: "Vodel\u00e9e",
    lat: 50.1702393,
    lng: 4.7323157,
    value: "5680",
  },
  {
    label: "Charleroi",
    lat: 50.41571025,
    lng: 4.4491649999234,
    value: "6000",
  },
  {
    label: "Marcinelle",
    lat: 50.3977245,
    lng: 4.4442531,
    value: "6001",
  },
  {
    label: "Couillet",
    lat: 50.391219,
    lng: 4.468631,
    value: "6010",
  },
  {
    label: "Dampremy",
    lat: 50.4186181,
    lng: 4.4322464,
    value: "6020",
  },
  {
    label: "Goutroux",
    lat: 50.41684425,
    lng: 4.3611946147288,
    value: "6030",
  },
  {
    label: "Marchienne-Au-Pont",
    lat: 50.4067102,
    lng: 4.3960065,
    value: "6030",
  },
  {
    label: "Monceau-Sur-Sambre",
    lat: 50.4170168,
    lng: 4.382402,
    value: "6031",
  },
  {
    label: "Mont-Sur-Marchienne",
    lat: 50.3902046,
    lng: 4.4046365,
    value: "6032",
  },
  {
    label: "Jumet",
    lat: 50.44466065,
    lng: 4.4360232749854,
    value: "6040",
  },
  {
    label: "Gosselies",
    lat: 50.4653846,
    lng: 4.4301757,
    value: "6041",
  },
  {
    label: "Lodelinsart",
    lat: 50.431853,
    lng: 4.4489421,
    value: "6042",
  },
  {
    label: "Ransart",
    lat: 50.45615655,
    lng: 4.481573078145,
    value: "6043",
  },
  {
    label: "Roux",
    lat: 50.4415002,
    lng: 4.3907758274107,
    value: "6044",
  },
  {
    label: "Gilly",
    lat: 50.4269705,
    lng: 4.485649013438,
    value: "6060",
  },
  {
    label: "Montignies-Sur-Sambre",
    lat: 50.4109216,
    lng: 4.4729234863084,
    value: "6061",
  },
  {
    label: "Montigny-Le-Tilleul",
    lat: 50.369072045553,
    lng: 4.3602314806535,
    value: "6110",
  },
  {
    label: "Landelies",
    lat: 50.377822,
    lng: 4.3496404,
    value: "6111",
  },
  {
    label: "Cour-Sur-Heure",
    lat: 50.2974701,
    lng: 4.3868963,
    value: "6120",
  },
  {
    label: "Ham-Sur-Heure",
    lat: 50.3219897,
    lng: 4.3889214,
    value: "6120",
  },
  {
    label: "Jamioulx",
    lat: 50.3528702,
    lng: 4.4127635,
    value: "6120",
  },
  {
    label: "Marbaix",
    lat: 50.3268072,
    lng: 4.3713936,
    value: "6120",
  },
  {
    label: "Nalinnes",
    lat: 50.3249486,
    lng: 4.4466922,
    value: "6120",
  },
  {
    label: "Fontaine-L'ev\u00eaque",
    lat: 50.411224661019,
    lng: 4.3239637925238,
    value: "6140",
  },
  {
    label: "Forchies-La-Marche",
    lat: 50.4383794,
    lng: 4.3198191,
    value: "6141",
  },
  {
    label: "Leernes",
    lat: 50.3974398,
    lng: 4.3307851,
    value: "6142",
  },
  {
    label: "Anderlues",
    lat: 50.4079969,
    lng: 4.2696102,
    value: "6150",
  },
  {
    label: "Courcelles",
    lat: 50.4601576,
    lng: 4.3763406,
    value: "6180",
  },
  {
    label: "Gouy-Lez-Pi\u00e9ton",
    lat: 50.4875628,
    lng: 4.3287132,
    value: "6181",
  },
  {
    label: "Souvret",
    lat: 50.450999,
    lng: 4.350827,
    value: "6182",
  },
  {
    label: "Trazegnies",
    lat: 50.4665295,
    lng: 4.3317415,
    value: "6183",
  },
  {
    label: "Bouffioulx",
    lat: 50.390213,
    lng: 4.5151961,
    value: "6200",
  },
  {
    label: "Ch\u00e2telet",
    lat: 50.4046075,
    lng: 4.5243511,
    value: "6200",
  },
  {
    label: "Ch\u00e2telineau",
    lat: 50.4155938,
    lng: 4.5179328,
    value: "6200",
  },
  {
    label: "Frasnes-Lez-Gosselies",
    lat: 50.5376477,
    lng: 4.4501525,
    value: "6210",
  },
  {
    label: "Les Bons Villers",
    lat: 50.53495775,
    lng: 4.4252588130793,
    value: "6210",
  },
  {
    label: "R\u00e8ves",
    lat: 50.543725,
    lng: 4.4054735658856,
    value: "6210",
  },
  {
    label: "Villers-Perwin",
    lat: 50.5262014,
    lng: 4.4783773,
    value: "6210",
  },
  {
    label: "Wayaux",
    lat: 50.4896309,
    lng: 4.4723314,
    value: "6210",
  },
  {
    label: "Mellet",
    lat: 50.50462075,
    lng: 4.4782005997401,
    value: "6211",
  },
  {
    label: "Fleurus",
    lat: 50.47194905,
    lng: 4.5446545325944,
    value: "6220",
  },
  {
    label: "Heppignies",
    lat: 50.4817059,
    lng: 4.4935448,
    value: "6220",
  },
  {
    label: "Lambusart",
    lat: 50.45472595,
    lng: 4.5579198971642,
    value: "6220",
  },
  {
    label: "Wangenies",
    lat: 50.4776689,
    lng: 4.519112,
    value: "6220",
  },
  {
    label: "Saint-Amand",
    lat: 50.5111679,
    lng: 4.5319238043004,
    value: "6221",
  },
  {
    label: "Brye",
    lat: 50.53007005,
    lng: 4.561356496071,
    value: "6222",
  },
  {
    label: "Wagnel\u00e9e",
    lat: 50.52262195,
    lng: 4.5273110200086,
    value: "6223",
  },
  {
    label: "Wanferc\u00e9e-Baulet",
    lat: 50.47584335,
    lng: 4.5816274059151,
    value: "6224",
  },
  {
    label: "Buzet",
    lat: 50.53893345,
    lng: 4.3713097181263,
    value: "6230",
  },
  {
    label: "Obaix",
    lat: 50.53439825,
    lng: 4.3401322241149,
    value: "6230",
  },
  {
    label: "Pont-\u00c0-Celles",
    lat: 50.5043242,
    lng: 4.3622972938902,
    value: "6230",
  },
  {
    label: "Thim\u00e9on",
    lat: 50.4842821,
    lng: 4.4306784623925,
    value: "6230",
  },
  {
    label: "Viesville",
    lat: 50.4906258,
    lng: 4.4030299776693,
    value: "6230",
  },
  {
    label: "Liberchies",
    lat: 50.5152429,
    lng: 4.4243830944654,
    value: "6238",
  },
  {
    label: "Luttre",
    lat: 50.51456265,
    lng: 4.3888817117527,
    value: "6238",
  },
  {
    label: "Farciennes",
    lat: 50.4273228,
    lng: 4.553041,
    value: "6240",
  },
  {
    label: "Pironchamps",
    lat: 50.4285932,
    lng: 4.526056,
    value: "6240",
  },
  {
    label: "Aiseau",
    lat: 50.4111232,
    lng: 4.5870152,
    value: "6250",
  },
  {
    label: "Aiseau-Presles",
    lat: 50.4065228,
    lng: 4.584081,
    value: "6250",
  },
  {
    label: "Pont-De-Loup",
    lat: 50.4173702,
    lng: 4.5459892,
    value: "6250",
  },
  {
    label: "Presles",
    lat: 50.3850088,
    lng: 4.5786617,
    value: "6250",
  },
  {
    label: "Roselies",
    lat: 50.4289142,
    lng: 4.5728849,
    value: "6250",
  },
  {
    label: "Acoz",
    lat: 50.3588065,
    lng: 4.5323568,
    value: "6280",
  },
  {
    label: "Gerpinnes",
    lat: 50.3378166,
    lng: 4.5276771,
    value: "6280",
  },
  {
    label: "Gougnies",
    lat: 50.3544437,
    lng: 4.5771625,
    value: "6280",
  },
  {
    label: "Joncret",
    lat: 50.3528064,
    lng: 4.5125952,
    value: "6280",
  },
  {
    label: "Loverval",
    lat: 50.374997,
    lng: 4.47342,
    value: "6280",
  },
  {
    label: "Villers-Poterie",
    lat: 50.3513008,
    lng: 4.547498,
    value: "6280",
  },
  {
    label: "Boussu-Lez-Walcourt",
    lat: 50.2262753,
    lng: 4.3767743,
    value: "6440",
  },
  {
    label: "Fourbechies",
    lat: 50.1666667,
    lng: 4.3166667,
    value: "6440",
  },
  {
    label: "Froidchapelle",
    lat: 50.1511597,
    lng: 4.3269129,
    value: "6440",
  },
  {
    label: "Vergnies",
    lat: 50.198614,
    lng: 4.3059105,
    value: "6440",
  },
  {
    label: "Erpion",
    lat: 50.2118893,
    lng: 4.3512321,
    value: "6441",
  },
  {
    label: "Baili\u00e8vre",
    lat: 50.069276,
    lng: 4.238617,
    value: "6460",
  },
  {
    label: "Chimay",
    lat: 50.0479266,
    lng: 4.3173338,
    value: "6460",
  },
  {
    label: "Robechies",
    lat: 50.072802,
    lng: 4.278378,
    value: "6460",
  },
  {
    label: "Saint-Remy",
    lat: 50.050354,
    lng: 4.309222,
    value: "6460",
  },
  {
    label: "Salles",
    lat: 50.0560102,
    lng: 4.247403,
    value: "6460",
  },
  {
    label: "Villers-La-Tour",
    lat: 50.035369,
    lng: 4.26287,
    value: "6460",
  },
  {
    label: "Virelles",
    lat: 50.0648008,
    lng: 4.3329805,
    value: "6461",
  },
  {
    label: "Vaulx-Lez-Chimay",
    lat: 50.061113,
    lng: 4.363579,
    value: "6462",
  },
  {
    label: "Lompret",
    lat: 50.0642146,
    lng: 4.3790041,
    value: "6463",
  },
  {
    label: "Baileux",
    lat: 50.0298544,
    lng: 4.3755329,
    value: "6464",
  },
  {
    label: "Bourlers",
    lat: 50.027359,
    lng: 4.341293,
    value: "6464",
  },
  {
    label: "Forges",
    lat: 50.022579,
    lng: 4.3211041,
    value: "6464",
  },
  {
    label: "L'escaill\u00e8re",
    lat: 49.947246,
    lng: 4.431664,
    value: "6464",
  },
  {
    label: "Ri\u00e8zes",
    lat: 49.960822,
    lng: 4.368311,
    value: "6464",
  },
  {
    label: "Grandrieu",
    lat: 50.200517,
    lng: 4.1714631,
    value: "6470",
  },
  {
    label: "Montbliart",
    lat: 50.1344457,
    lng: 4.224365,
    value: "6470",
  },
  {
    label: "Rance",
    lat: 50.1430111,
    lng: 4.2731851,
    value: "6470",
  },
  {
    label: "Sautin",
    lat: 50.163816,
    lng: 4.226143,
    value: "6470",
  },
  {
    label: "Sivry",
    lat: 50.1678978,
    lng: 4.1792797,
    value: "6470",
  },
  {
    label: "Sivry-Rance",
    lat: 50.1640023,
    lng: 4.1899982,
    value: "6470",
  },
  {
    label: "Barben\u00e7on",
    lat: 50.2206905,
    lng: 4.2859259,
    value: "6500",
  },
  {
    label: "Beaumont",
    lat: 50.2355294,
    lng: 4.2383115,
    value: "6500",
  },
  {
    label: "Leugnies",
    lat: 50.224642,
    lng: 4.196155,
    value: "6500",
  },
  {
    label: "Leval-Chaudeville",
    lat: 50.2349363,
    lng: 4.2289677,
    value: "6500",
  },
  {
    label: "Renlies",
    lat: 50.189927,
    lng: 4.2670432,
    value: "6500",
  },
  {
    label: "Solre-Saint-G\u00e9ry",
    lat: 50.216245,
    lng: 4.246304,
    value: "6500",
  },
  {
    label: "Thirimont",
    lat: 50.2608137,
    lng: 4.2364418,
    value: "6500",
  },
  {
    label: "Str\u00e9e",
    lat: 50.2766332,
    lng: 4.2980462,
    value: "6511",
  },
  {
    label: "Leers-Et-Fosteau",
    lat: 50.3041589,
    lng: 4.244767,
    value: "6530",
  },
  {
    label: "Thuin",
    lat: 50.3397445,
    lng: 4.2870475,
    value: "6530",
  },
  {
    label: "Biesme-Sous-Thuin",
    lat: 50.320724,
    lng: 4.3079897,
    value: "6531",
  },
  {
    label: "Ragnies",
    lat: 50.3081977,
    lng: 4.2837674,
    value: "6532",
  },
  {
    label: "Bierc\u00e9e",
    lat: 50.3250862,
    lng: 4.2591396,
    value: "6533",
  },
  {
    label: "Goz\u00e9e",
    lat: 50.3332279,
    lng: 4.3515595,
    value: "6534",
  },
  {
    label: "Donstiennes",
    lat: 50.2854167,
    lng: 4.3119991,
    value: "6536",
  },
  {
    label: "Thuillies",
    lat: 50.2947304,
    lng: 4.329007,
    value: "6536",
  },
  {
    label: "Lobbes",
    lat: 50.3460194,
    lng: 4.2654874,
    value: "6540",
  },
  {
    label: "Mont-Sainte-Genevi\u00e8ve",
    lat: 50.3766211,
    lng: 4.2377392,
    value: "6540",
  },
  {
    label: "Sars-La-Buissi\u00e8re",
    lat: 50.3352549,
    lng: 4.209803,
    value: "6542",
  },
  {
    label: "Bienne-Lez-Happart",
    lat: 50.3512087,
    lng: 4.2155807,
    value: "6543",
  },
  {
    label: "Bersillies-L'abbaye",
    lat: 50.2631919,
    lng: 4.1520931,
    value: "6560",
  },
  {
    label: "Erquelinnes",
    lat: 50.3102184,
    lng: 4.1241436,
    value: "6560",
  },
  {
    label: "Grand-Reng",
    lat: 50.3287642,
    lng: 4.0705909,
    value: "6560",
  },
  {
    label: "Hantes-Wih\u00e9ries",
    lat: 50.3057605,
    lng: 4.1783639,
    value: "6560",
  },
  {
    label: "Montignies-Saint-Christophe",
    lat: 50.2815734,
    lng: 4.1884975,
    value: "6560",
  },
  {
    label: "Solre-Sur-Sambre",
    lat: 50.3067709,
    lng: 4.1558267,
    value: "6560",
  },
  {
    label: "Fontaine-Valmont",
    lat: 50.3208131,
    lng: 4.2137302,
    value: "6567",
  },
  {
    label: "Labuissi\u00e8re",
    lat: 50.3141612,
    lng: 4.194796,
    value: "6567",
  },
  {
    label: "Merbes-Le-Ch\u00e2teau",
    lat: 50.3234035,
    lng: 4.1644816,
    value: "6567",
  },
  {
    label: "Merbes-Sainte-Marie",
    lat: 50.3552041,
    lng: 4.1711042,
    value: "6567",
  },
  {
    label: "Momignies",
    lat: 50.0286317,
    lng: 4.1649981,
    value: "6590",
  },
  {
    label: "Macon",
    lat: 50.052122,
    lng: 4.2096331,
    value: "6591",
  },
  {
    label: "Monceau-Imbrechies",
    lat: 50.036452,
    lng: 4.224118,
    value: "6592",
  },
  {
    label: "Macquenoise",
    lat: 49.9755399,
    lng: 4.1802891,
    value: "6593",
  },
  {
    label: "Beauwelz",
    lat: 50.015365,
    lng: 4.156972,
    value: "6594",
  },
  {
    label: "Forge-Philippe",
    lat: 49.96543,
    lng: 4.2520681,
    value: "6596",
  },
  {
    label: "Seloignes",
    lat: 50.016087,
    lng: 4.255615,
    value: "6596",
  },
  {
    label: "Bastogne",
    lat: 50.0009951,
    lng: 5.7153203,
    value: "6600",
  },
  {
    label: "Longvilly",
    lat: 50.0249524,
    lng: 5.8368151,
    value: "6600",
  },
  {
    label: "Noville",
    lat: 50.0651684,
    lng: 5.7616864,
    value: "6600",
  },
  {
    label: "Villers-La-Bonne-Eau",
    lat: 49.9345566,
    lng: 5.7477792,
    value: "6600",
  },
  {
    label: "Wardin",
    lat: 49.9902268,
    lng: 5.7857948,
    value: "6600",
  },
  {
    label: "Martelange",
    lat: 49.8313323,
    lng: 5.7376366,
    value: "6630",
  },
  {
    label: "Fauvillers",
    lat: 49.8509306,
    lng: 5.665031,
    value: "6637",
  },
  {
    label: "Hollange",
    lat: 49.9064531,
    lng: 5.6881541,
    value: "6637",
  },
  {
    label: "Tintange",
    lat: 49.878638,
    lng: 5.7518438,
    value: "6637",
  },
  {
    label: "Hompr\u00e9",
    lat: 49.9437838,
    lng: 5.6866728,
    value: "6640",
  },
  {
    label: "Morhet",
    lat: 49.957603,
    lng: 5.5824265,
    value: "6640",
  },
  {
    label: "Nives",
    lat: 49.9121387,
    lng: 5.602746,
    value: "6640",
  },
  {
    label: "Sibret",
    lat: 49.968275,
    lng: 5.6352461,
    value: "6640",
  },
  {
    label: "Vaux-Lez-Rosi\u00e8res",
    lat: 49.9133632,
    lng: 5.5705339,
    value: "6640",
  },
  {
    label: "Vaux-Sur-S\u00fbre",
    lat: 49.908755829225,
    lng: 5.5908285596978,
    value: "6640",
  },
  {
    label: "Juseret",
    lat: 49.8805193,
    lng: 5.5493342,
    value: "6642",
  },
  {
    label: "Houffalize",
    lat: 50.1323761,
    lng: 5.7886928,
    value: "6660",
  },
  {
    label: "Nadrin",
    lat: 50.1611829,
    lng: 5.6807442,
    value: "6660",
  },
  {
    label: "Mont",
    lat: 50.5341607,
    lng: 5.7909538,
    value: "6661",
  },
  {
    label: "Tailles",
    lat: 50.2272012,
    lng: 5.7447558,
    value: "6661",
  },
  {
    label: "Tavigny",
    lat: 50.1081487,
    lng: 5.837773,
    value: "6662",
  },
  {
    label: "Mabompr\u00e9",
    lat: 50.098929,
    lng: 5.7384527,
    value: "6663",
  },
  {
    label: "Wibrin",
    lat: 50.1661182,
    lng: 5.7156009,
    value: "6666",
  },
  {
    label: "Gouvy",
    lat: 50.1907895,
    lng: 5.9549305,
    value: "6670",
  },
  {
    label: "Limerl\u00e9",
    lat: 50.1608553,
    lng: 5.9393768987183,
    value: "6670",
  },
  {
    label: "Bovigny",
    lat: 50.2244199,
    lng: 5.9182421,
    value: "6671",
  },
  {
    label: "Beho",
    lat: 50.220365,
    lng: 5.9972692,
    value: "6672",
  },
  {
    label: "Cherain",
    lat: 50.1806741,
    lng: 5.8646609,
    value: "6673",
  },
  {
    label: "Montleban",
    lat: 50.1876021,
    lng: 5.8288862,
    value: "6674",
  },
  {
    label: "Amberloup",
    lat: 50.0292955,
    lng: 5.5272931,
    value: "6680",
  },
  {
    label: "Sainte-Ode",
    lat: 50.0362888,
    lng: 5.5245094,
    value: "6680",
  },
  {
    label: "Tillet",
    lat: 50.0097939,
    lng: 5.5297763,
    value: "6680",
  },
  {
    label: "Lavacherie",
    lat: 50.052317,
    lng: 5.5136455,
    value: "6681",
  },
  {
    label: "Flamierge",
    lat: 50.0333519,
    lng: 5.6038758,
    value: "6686",
  },
  {
    label: "Bertogne",
    lat: 50.0842908,
    lng: 5.6675577,
    value: "6687",
  },
  {
    label: "Longchamps",
    lat: 50.0535428,
    lng: 5.6892466,
    value: "6688",
  },
  {
    label: "Bihain",
    lat: 50.2390245,
    lng: 5.8082072,
    value: "6690",
  },
  {
    label: "Vielsalm",
    lat: 50.2883685,
    lng: 5.9170187,
    value: "6690",
  },
  {
    label: "Petit-Thier",
    lat: 50.3074461,
    lng: 5.9672766,
    value: "6692",
  },
  {
    label: "Grand-Halleux",
    lat: 50.3262753,
    lng: 5.9072517,
    value: "6698",
  },
  {
    label: "Arlon",
    lat: 49.6827625,
    lng: 5.811919,
    value: "6700",
  },
  {
    label: "Bonnert",
    lat: 49.7107729,
    lng: 5.8190143,
    value: "6700",
  },
  {
    label: "Heinsch",
    lat: 49.7007526,
    lng: 5.7464566,
    value: "6700",
  },
  {
    label: "Toernich",
    lat: 49.6512359,
    lng: 5.7795299,
    value: "6700",
  },
  {
    label: "Guirsch",
    lat: 49.7193575,
    lng: 5.853381,
    value: "6704",
  },
  {
    label: "Autelbas",
    lat: 49.6499559,
    lng: 5.8675666,
    value: "6706",
  },
  {
    label: "Attert",
    lat: 49.7514982,
    lng: 5.7872217,
    value: "6717",
  },
  {
    label: "Nobressart",
    lat: 49.7399333,
    lng: 5.7187077,
    value: "6717",
  },
  {
    label: "Nothomb",
    lat: 49.7717529,
    lng: 5.7861375,
    value: "6717",
  },
  {
    label: "Thiaumont",
    lat: 49.7159959,
    lng: 5.7291343,
    value: "6717",
  },
  {
    label: "Tontelange",
    lat: 49.7266352,
    lng: 5.8099377,
    value: "6717",
  },
  {
    label: "Habay",
    lat: 49.73558035,
    lng: 5.5932514325174,
    value: "6720",
  },
  {
    label: "Habay-La-Neuve",
    lat: 49.7286061,
    lng: 5.6503942,
    value: "6720",
  },
  {
    label: "Hachy",
    lat: 49.7015507,
    lng: 5.6805003,
    value: "6720",
  },
  {
    label: "Anlier",
    lat: 49.772082,
    lng: 5.620414,
    value: "6721",
  },
  {
    label: "Habay-La-Vieille",
    lat: 49.7228424,
    lng: 5.6225107,
    value: "6723",
  },
  {
    label: "Houdemont",
    lat: 49.7189016,
    lng: 5.5843337,
    value: "6724",
  },
  {
    label: "Rulles",
    lat: 49.7181041,
    lng: 5.558116,
    value: "6724",
  },
  {
    label: "Bellefontaine",
    lat: 49.6649541,
    lng: 5.4979103,
    value: "6730",
  },
  {
    label: "Rossignol",
    lat: 49.718767,
    lng: 5.4851582,
    value: "6730",
  },
  {
    label: "Saint-Vincent",
    lat: 49.6774116,
    lng: 5.475602,
    value: "6730",
  },
  {
    label: "Tintigny",
    lat: 49.6820986,
    lng: 5.5185152,
    value: "6730",
  },
  {
    label: "Etalle",
    lat: 49.6729527,
    lng: 5.5982415,
    value: "6740",
  },
  {
    label: "Sainte-Marie-Sur-Semois",
    lat: 49.6732168,
    lng: 5.5644887,
    value: "6740",
  },
  {
    label: "Villers-Sur-Semois",
    lat: 49.6987456,
    lng: 5.5614663,
    value: "6740",
  },
  {
    label: "Vance",
    lat: 49.6701655,
    lng: 5.6698138,
    value: "6741",
  },
  {
    label: "Chantemelle",
    lat: 49.6535106,
    lng: 5.6551334,
    value: "6742",
  },
  {
    label: "Buzenol",
    lat: 49.6478969,
    lng: 5.5942184,
    value: "6743",
  },
  {
    label: "Ch\u00e2tillon",
    lat: 49.6255848,
    lng: 5.6983633,
    value: "6747",
  },
  {
    label: "Meix-Le-Tige",
    lat: 49.6151951,
    lng: 5.7187012,
    value: "6747",
  },
  {
    label: "Saint-L\u00e9ger",
    lat: 49.6134362,
    lng: 5.6569569,
    value: "6747",
  },
  {
    label: "Musson",
    lat: 49.5580794,
    lng: 5.705881,
    value: "6750",
  },
  {
    label: "Mussy-La-Ville",
    lat: 49.5714143,
    lng: 5.6617004,
    value: "6750",
  },
  {
    label: "Signeulx",
    lat: 49.5528606,
    lng: 5.6403955,
    value: "6750",
  },
  {
    label: "Bleid",
    lat: 49.5680419,
    lng: 5.6272894,
    value: "6760",
  },
  {
    label: "Ethe",
    lat: 49.5814209,
    lng: 5.5766897,
    value: "6760",
  },
  {
    label: "Ruette",
    lat: 49.5376723,
    lng: 5.5937884,
    value: "6760",
  },
  {
    label: "Virton",
    lat: 49.5677263,
    lng: 5.5329559,
    value: "6760",
  },
  {
    label: "Latour",
    lat: 49.5581925,
    lng: 5.5704642,
    value: "6761",
  },
  {
    label: "Saint-Mard",
    lat: 49.5577562,
    lng: 5.5284511,
    value: "6762",
  },
  {
    label: "Dampicourt",
    lat: 49.5553011,
    lng: 5.4979579,
    value: "6767",
  },
  {
    label: "Harnoncourt",
    lat: 49.534288,
    lng: 5.4996797,
    value: "6767",
  },
  {
    label: "Lamorteau",
    lat: 49.5262083,
    lng: 5.4795124,
    value: "6767",
  },
  {
    label: "Rouvroy",
    lat: 49.539075,
    lng: 5.48971,
    value: "6767",
  },
  {
    label: "Torgny",
    lat: 49.5085018,
    lng: 5.4735035,
    value: "6767",
  },
  {
    label: "G\u00e9rouville",
    lat: 49.6184295,
    lng: 5.4287902,
    value: "6769",
  },
  {
    label: "Meix-Devant-Virton",
    lat: 49.6055933,
    lng: 5.4816156,
    value: "6769",
  },
  {
    label: "Robelmont",
    lat: 49.595344,
    lng: 5.5071291,
    value: "6769",
  },
  {
    label: "Sommethonne",
    lat: 49.5764582,
    lng: 5.4456876,
    value: "6769",
  },
  {
    label: "Villers-La-Loue",
    lat: 49.5749479,
    lng: 5.481043,
    value: "6769",
  },
  {
    label: "Hondelange",
    lat: 49.6351714,
    lng: 5.8350151,
    value: "6780",
  },
  {
    label: "Messancy",
    lat: 49.5969055,
    lng: 5.8169286,
    value: "6780",
  },
  {
    label: "Wolkrange",
    lat: 49.6350021,
    lng: 5.7983045,
    value: "6780",
  },
  {
    label: "S\u00e9lange",
    lat: 49.6081105,
    lng: 5.848409,
    value: "6781",
  },
  {
    label: "Habergy",
    lat: 49.6153377,
    lng: 5.7608307,
    value: "6782",
  },
  {
    label: "Aubange",
    lat: 49.5675296,
    lng: 5.804352,
    value: "6790",
  },
  {
    label: "Athus",
    lat: 49.5642065,
    lng: 5.8317661,
    value: "6791",
  },
  {
    label: "Halanzy",
    lat: 49.5590364,
    lng: 5.7422467,
    value: "6792",
  },
  {
    label: "Rachecourt",
    lat: 49.5903125,
    lng: 5.7248143,
    value: "6792",
  },
  {
    label: "Bras",
    lat: 49.9760182,
    lng: 5.3877654,
    value: "6800",
  },
  {
    label: "Freux",
    lat: 49.9708428,
    lng: 5.4497307,
    value: "6800",
  },
  {
    label: "Libramont-Chevigny",
    lat: 49.9197154,
    lng: 5.3823537,
    value: "6800",
  },
  {
    label: "Moircy",
    lat: 49.9894846,
    lng: 5.468077,
    value: "6800",
  },
  {
    label: "Recogne",
    lat: 49.9112853,
    lng: 5.3607943,
    value: "6800",
  },
  {
    label: "Remagne",
    lat: 49.9764499,
    lng: 5.494626,
    value: "6800",
  },
  {
    label: "Saint-Pierre",
    lat: 49.9047868,
    lng: 5.386433,
    value: "6800",
  },
  {
    label: "Sainte-Marie-Chevigny",
    lat: 49.9235741,
    lng: 5.4578106,
    value: "6800",
  },
  {
    label: "Chiny",
    lat: 49.738581,
    lng: 5.3394252,
    value: "6810",
  },
  {
    label: "Izel",
    lat: 49.693986,
    lng: 5.3747807,
    value: "6810",
  },
  {
    label: "Jamoigne",
    lat: 49.6958089,
    lng: 5.420477,
    value: "6810",
  },
  {
    label: "Les Bulles",
    lat: 49.7031011,
    lng: 5.4263273,
    value: "6811",
  },
  {
    label: "Suxy",
    lat: 49.7626877,
    lng: 5.4005563,
    value: "6812",
  },
  {
    label: "Termes",
    lat: 49.7071328,
    lng: 5.4579753,
    value: "6813",
  },
  {
    label: "Florenville",
    lat: 49.6993182,
    lng: 5.3100518,
    value: "6820",
  },
  {
    label: "Fontenoille",
    lat: 49.71508,
    lng: 5.234469,
    value: "6820",
  },
  {
    label: "Muno",
    lat: 49.717679,
    lng: 5.1759524,
    value: "6820",
  },
  {
    label: "Sainte-C\u00e9cile",
    lat: 49.7293745,
    lng: 5.2429556,
    value: "6820",
  },
  {
    label: "Lacuisine",
    lat: 49.7170106,
    lng: 5.318262,
    value: "6821",
  },
  {
    label: "Villers-Devant-Orval",
    lat: 49.6185771,
    lng: 5.331466,
    value: "6823",
  },
  {
    label: "Chassepierre",
    lat: 49.7076905,
    lng: 5.2621817,
    value: "6824",
  },
  {
    label: "Bouillon",
    lat: 49.7949836,
    lng: 5.0672528,
    value: "6830",
  },
  {
    label: "Les Hayons",
    lat: 49.8133379,
    lng: 5.1456628,
    value: "6830",
  },
  {
    label: "Poupehan",
    lat: 49.8115586,
    lng: 5.0035785,
    value: "6830",
  },
  {
    label: "Rochehaut",
    lat: 49.8404522,
    lng: 5.0070138,
    value: "6830",
  },
  {
    label: "Noirfontaine",
    lat: 50.6175774,
    lng: 5.412251,
    value: "6831",
  },
  {
    label: "Sensenruth",
    lat: 49.8254037,
    lng: 5.0738804,
    value: "6832",
  },
  {
    label: "Ucimont",
    lat: 49.8314004,
    lng: 5.0573896,
    value: "6833",
  },
  {
    label: "Vivy",
    lat: 49.8675973,
    lng: 5.0388784,
    value: "6833",
  },
  {
    label: "Bellevaux",
    lat: 50.3912772,
    lng: 6.0129575,
    value: "6834",
  },
  {
    label: "Dohan",
    lat: 49.7973741,
    lng: 5.1418578,
    value: "6836",
  },
  {
    label: "Corbion",
    lat: 49.7976297,
    lng: 5.0065337,
    value: "6838",
  },
  {
    label: "Grandvoir",
    lat: 49.8576034,
    lng: 5.3717959,
    value: "6840",
  },
  {
    label: "Grapfontaine",
    lat: 49.8215033,
    lng: 5.4076005,
    value: "6840",
  },
  {
    label: "Hamipr\u00e9",
    lat: 49.8359615,
    lng: 5.4564462,
    value: "6840",
  },
  {
    label: "Longlier",
    lat: 49.8562222,
    lng: 5.4609005,
    value: "6840",
  },
  {
    label: "Neufch\u00e2teau",
    lat: 49.8411765,
    lng: 5.4339147,
    value: "6840",
  },
  {
    label: "Tournay",
    lat: 49.8543387,
    lng: 5.3969928,
    value: "6840",
  },
  {
    label: "Carlsbourg",
    lat: 49.8947611,
    lng: 5.0830529,
    value: "6850",
  },
  {
    label: "Offagne",
    lat: 49.8855714,
    lng: 5.175013,
    value: "6850",
  },
  {
    label: "Paliseul",
    lat: 49.9035269,
    lng: 5.1349041,
    value: "6850",
  },
  {
    label: "Nollevaux",
    lat: 49.8710591,
    lng: 5.1220764,
    value: "6851",
  },
  {
    label: "Maissin",
    lat: 49.9651106,
    lng: 5.1805775,
    value: "6852",
  },
  {
    label: "Opont",
    lat: 49.9348514,
    lng: 5.1201252,
    value: "6852",
  },
  {
    label: "Framont",
    lat: 49.9185629,
    lng: 5.1601932,
    value: "6853",
  },
  {
    label: "Fays-Les-Veneurs",
    lat: 49.8662058,
    lng: 5.1607144,
    value: "6856",
  },
  {
    label: "Assenois",
    lat: 49.8029977,
    lng: 5.4625426,
    value: "6860",
  },
  {
    label: "Ebly",
    lat: 49.8508818,
    lng: 5.534876707362,
    value: "6860",
  },
  {
    label: "L\u00e9glise",
    lat: 49.8003582,
    lng: 5.5381496,
    value: "6860",
  },
  {
    label: "Mellier",
    lat: 49.7671531,
    lng: 5.5209461,
    value: "6860",
  },
  {
    label: "Witry",
    lat: 49.8589203,
    lng: 5.6138516,
    value: "6860",
  },
  {
    label: "Arville",
    lat: 50.0323986,
    lng: 5.3166577,
    value: "6870",
  },
  {
    label: "Awenne",
    lat: 50.074242,
    lng: 5.306188,
    value: "6870",
  },
  {
    label: "Hatrival",
    lat: 50.0052874,
    lng: 5.348119,
    value: "6870",
  },
  {
    label: "Mirwart",
    lat: 50.0569945,
    lng: 5.2653304,
    value: "6870",
  },
  {
    label: "Saint-Hubert",
    lat: 50.0251428,
    lng: 5.3738561,
    value: "6870",
  },
  {
    label: "Vesqueville",
    lat: 50.0058391,
    lng: 5.3968709,
    value: "6870",
  },
  {
    label: "Auby-Sur-Semois",
    lat: 49.8157173,
    lng: 5.1823728,
    value: "6880",
  },
  {
    label: "Bertrix",
    lat: 49.8546564,
    lng: 5.2529266,
    value: "6880",
  },
  {
    label: "Cugnon",
    lat: 49.8020427,
    lng: 5.2033082,
    value: "6880",
  },
  {
    label: "Jehonville",
    lat: 49.9065515,
    lng: 5.2022069,
    value: "6880",
  },
  {
    label: "Orgeo",
    lat: 49.8343079,
    lng: 5.3022804,
    value: "6880",
  },
  {
    label: "Herbeumont",
    lat: 49.7815364,
    lng: 5.2377736,
    value: "6887",
  },
  {
    label: "Saint-M\u00e9dard",
    lat: 49.8158829,
    lng: 5.3277128,
    value: "6887",
  },
  {
    label: "Straimont",
    lat: 49.7961667,
    lng: 5.3811714,
    value: "6887",
  },
  {
    label: "Anloy",
    lat: 49.9499043,
    lng: 5.2215609,
    value: "6890",
  },
  {
    label: "Libin",
    lat: 49.9809464,
    lng: 5.2569017,
    value: "6890",
  },
  {
    label: "Ochamps",
    lat: 49.9244612,
    lng: 5.2781878,
    value: "6890",
  },
  {
    label: "Redu",
    lat: 50.0077873,
    lng: 5.1601828,
    value: "6890",
  },
  {
    label: "Smuid",
    lat: 50.0188027,
    lng: 5.2662483,
    value: "6890",
  },
  {
    label: "Transinne",
    lat: 50.0004466,
    lng: 5.2026639,
    value: "6890",
  },
  {
    label: "Villance",
    lat: 49.9709626,
    lng: 5.2221196,
    value: "6890",
  },
  {
    label: "Aye",
    lat: 50.2232964,
    lng: 5.2931158,
    value: "6900",
  },
  {
    label: "Hargimont",
    lat: 50.1825868,
    lng: 5.3094652,
    value: "6900",
  },
  {
    label: "Humain",
    lat: 50.2053202,
    lng: 5.2552435,
    value: "6900",
  },
  {
    label: "Marche-En-Famenne",
    lat: 50.2240814,
    lng: 5.3428796,
    value: "6900",
  },
  {
    label: "On",
    lat: 50.1715967,
    lng: 5.2862777,
    value: "6900",
  },
  {
    label: "Roy",
    lat: 50.1879034,
    lng: 5.4095929,
    value: "6900",
  },
  {
    label: "Waha",
    lat: 50.2119666,
    lng: 5.3441742,
    value: "6900",
  },
  {
    label: "Sohier",
    lat: 50.0685885,
    lng: 5.0702515,
    value: "6920",
  },
  {
    label: "Wellin",
    lat: 50.0822967,
    lng: 5.1146708,
    value: "6920",
  },
  {
    label: "Chanly",
    lat: 50.0786533,
    lng: 5.1559991,
    value: "6921",
  },
  {
    label: "Halma",
    lat: 50.0754135,
    lng: 5.1374206,
    value: "6922",
  },
  {
    label: "Lomprez",
    lat: 50.0762474,
    lng: 5.092143,
    value: "6924",
  },
  {
    label: "Bure",
    lat: 50.0885262,
    lng: 5.261162,
    value: "6927",
  },
  {
    label: "Grupont",
    lat: 50.0923432,
    lng: 5.2798077,
    value: "6927",
  },
  {
    label: "Resteigne",
    lat: 50.0828516,
    lng: 5.1778089,
    value: "6927",
  },
  {
    label: "Tellin",
    lat: 50.081019,
    lng: 5.216837,
    value: "6927",
  },
  {
    label: "Daverdisse",
    lat: 50.0217646,
    lng: 5.1184644,
    value: "6929",
  },
  {
    label: "Gembes",
    lat: 49.997198,
    lng: 5.063233,
    value: "6929",
  },
  {
    label: "Haut-Fays",
    lat: 50.0017421,
    lng: 5.016947,
    value: "6929",
  },
  {
    label: "Porcheresse",
    lat: 49.978801,
    lng: 5.0866787,
    value: "6929",
  },
  {
    label: "Durbuy",
    lat: 50.3524116,
    lng: 5.456248,
    value: "6940",
  },
  {
    label: "Grandhan",
    lat: 50.3299795,
    lng: 5.4086678,
    value: "6940",
  },
  {
    label: "Septon",
    lat: 50.3570338,
    lng: 5.423028,
    value: "6940",
  },
  {
    label: "W\u00e9ris",
    lat: 50.3266395,
    lng: 5.5307553,
    value: "6940",
  },
  {
    label: "Bende",
    lat: 50.4176261,
    lng: 5.4142387,
    value: "6941",
  },
  {
    label: "Bomal-Sur-Ourthe",
    lat: 50.3760087,
    lng: 5.5223752,
    value: "6941",
  },
  {
    label: "Borlon",
    lat: 50.3783963,
    lng: 5.4048529,
    value: "6941",
  },
  {
    label: "Heyd",
    lat: 50.346445,
    lng: 5.562158,
    value: "6941",
  },
  {
    label: "Izier",
    lat: 50.3848936,
    lng: 5.5793252,
    value: "6941",
  },
  {
    label: "Tohogne",
    lat: 50.3810361,
    lng: 5.481405,
    value: "6941",
  },
  {
    label: "Villers-Sainte-Gertrude",
    lat: 50.3619168,
    lng: 5.5764092,
    value: "6941",
  },
  {
    label: "Harsin",
    lat: 50.173788,
    lng: 5.3475606,
    value: "6950",
  },
  {
    label: "Nassogne",
    lat: 50.1294031,
    lng: 5.3455462,
    value: "6950",
  },
  {
    label: "Bande",
    lat: 50.1665822,
    lng: 5.4154822,
    value: "6951",
  },
  {
    label: "Grune",
    lat: 50.1549918,
    lng: 5.3828952,
    value: "6952",
  },
  {
    label: "Ambly",
    lat: 50.1441815,
    lng: 5.3142288,
    value: "6953",
  },
  {
    label: "Forri\u00e8res",
    lat: 50.1324941,
    lng: 5.2842239,
    value: "6953",
  },
  {
    label: "Lesterny",
    lat: 50.1109713,
    lng: 5.2799209,
    value: "6953",
  },
  {
    label: "Masbourg",
    lat: 50.1143521,
    lng: 5.3136921,
    value: "6953",
  },
  {
    label: "Dochamps",
    lat: 50.234199,
    lng: 5.6230297,
    value: "6960",
  },
  {
    label: "Grandmenil",
    lat: 50.2922823,
    lng: 5.6625445,
    value: "6960",
  },
  {
    label: "Harre",
    lat: 50.3516772,
    lng: 5.6587925,
    value: "6960",
  },
  {
    label: "Malempr\u00e9",
    lat: 50.2816159,
    lng: 5.7155249,
    value: "6960",
  },
  {
    label: "Manhay",
    lat: 50.2929635,
    lng: 5.6756341,
    value: "6960",
  },
  {
    label: "Odeigne",
    lat: 50.2563686,
    lng: 5.682551,
    value: "6960",
  },
  {
    label: "Vaux-Chavanne",
    lat: 50.3021516,
    lng: 5.6968767,
    value: "6960",
  },
  {
    label: "Tenneville",
    lat: 50.091248,
    lng: 5.5242133,
    value: "6970",
  },
  {
    label: "Champlon",
    lat: 50.1077503,
    lng: 5.5034047,
    value: "6971",
  },
  {
    label: "Erneuville",
    lat: 50.1137831,
    lng: 5.5507905,
    value: "6972",
  },
  {
    label: "Beausaint",
    lat: 50.170322,
    lng: 5.5532565,
    value: "6980",
  },
  {
    label: "La Roche-En-Ardenne",
    lat: 50.182916023074,
    lng: 5.5701521878995,
    value: "6980",
  },
  {
    label: "Samr\u00e9e",
    lat: 50.209256,
    lng: 5.6394828,
    value: "6982",
  },
  {
    label: "Ortho",
    lat: 50.1249111,
    lng: 5.6138065,
    value: "6983",
  },
  {
    label: "Hives",
    lat: 50.1506704,
    lng: 5.5783344,
    value: "6984",
  },
  {
    label: "Halleux",
    lat: 50.1717133,
    lng: 5.5020384,
    value: "6986",
  },
  {
    label: "Beffe",
    lat: 50.245425,
    lng: 5.5230608,
    value: "6987",
  },
  {
    label: "Hodister",
    lat: 50.2006652,
    lng: 5.4954367,
    value: "6987",
  },
  {
    label: "Marcourt",
    lat: 50.2145396,
    lng: 5.5268173,
    value: "6987",
  },
  {
    label: "Rendeux",
    lat: 50.2330466,
    lng: 5.5043243,
    value: "6987",
  },
  {
    label: "Fronville",
    lat: 50.2943293,
    lng: 5.4221284,
    value: "6990",
  },
  {
    label: "Hampteau",
    lat: 50.2579039,
    lng: 5.4729019,
    value: "6990",
  },
  {
    label: "Hotton",
    lat: 50.2688011,
    lng: 5.4463304,
    value: "6990",
  },
  {
    label: "Marenne",
    lat: 50.2418351,
    lng: 5.4165812,
    value: "6990",
  },
  {
    label: "Amonines",
    lat: 50.2655197,
    lng: 5.5571509,
    value: "6997",
  },
  {
    label: "Erez\u00e9e",
    lat: 50.2930897,
    lng: 5.5597229,
    value: "6997",
  },
  {
    label: "Mormont",
    lat: 50.1458065,
    lng: 5.7189108,
    value: "6997",
  },
  {
    label: "Soy",
    lat: 50.285577,
    lng: 5.5100929,
    value: "6997",
  },
  {
    label: "Mons",
    lat: 50.4549568,
    lng: 3.951958,
    value: "7000",
  },
  {
    label: "Ghlin",
    lat: 50.4760502,
    lng: 3.903746,
    value: "7011",
  },
  {
    label: "Fl\u00e9nu",
    lat: 50.4367571,
    lng: 3.8878381,
    value: "7012",
  },
  {
    label: "Jemappes",
    lat: 50.448422,
    lng: 3.8894392,
    value: "7012",
  },
  {
    label: "Maisi\u00e8res",
    lat: 50.4901522,
    lng: 3.962763,
    value: "7020",
  },
  {
    label: "Nimy",
    lat: 50.4759358,
    lng: 3.9545894,
    value: "7020",
  },
  {
    label: "Havre",
    lat: 50.4643434,
    lng: 4.0453329,
    value: "7021",
  },
  {
    label: "Harmignies",
    lat: 50.4076446,
    lng: 4.0177899,
    value: "7022",
  },
  {
    label: "Harveng",
    lat: 50.3949004,
    lng: 3.986884,
    value: "7022",
  },
  {
    label: "Hyon",
    lat: 50.4392359,
    lng: 3.9619447,
    value: "7022",
  },
  {
    label: "Mesvin",
    lat: 50.4283672,
    lng: 3.960668,
    value: "7022",
  },
  {
    label: "Nouvelles",
    lat: 50.4100798,
    lng: 3.9689185,
    value: "7022",
  },
  {
    label: "Ciply",
    lat: 50.4193042,
    lng: 3.9438349,
    value: "7024",
  },
  {
    label: "Saint-Symphorien",
    lat: 50.4366253,
    lng: 4.012574,
    value: "7030",
  },
  {
    label: "Villers-Saint-Ghislain",
    lat: 50.4312421,
    lng: 4.0389071,
    value: "7031",
  },
  {
    label: "Spiennes",
    lat: 50.4254505,
    lng: 3.9868957,
    value: "7032",
  },
  {
    label: "Cuesmes",
    lat: 50.436171,
    lng: 3.9206652,
    value: "7033",
  },
  {
    label: "Obourg",
    lat: 50.4760289,
    lng: 4.0062232,
    value: "7034",
  },
  {
    label: "Saint-Denis",
    lat: 50.4920472,
    lng: 4.0181787,
    value: "7034",
  },
  {
    label: "Asquillies",
    lat: 50.4020009,
    lng: 3.9570563,
    value: "7040",
  },
  {
    label: "Aulnois",
    lat: 50.340829,
    lng: 3.9031862,
    value: "7040",
  },
  {
    label: "Blaregnies",
    lat: 50.357777,
    lng: 3.8980429,
    value: "7040",
  },
  {
    label: "Bougnies",
    lat: 50.386751,
    lng: 3.9391701,
    value: "7040",
  },
  {
    label: "Genly",
    lat: 50.3922864,
    lng: 3.9168758,
    value: "7040",
  },
  {
    label: "Goegnies-Chauss\u00e9e",
    lat: 50.3422836,
    lng: 3.9442029,
    value: "7040",
  },
  {
    label: "Qu\u00e9vy",
    lat: 50.3667226,
    lng: 3.9439033,
    value: "7040",
  },
  {
    label: "Qu\u00e9vy-Le-Grand",
    lat: 50.3609637,
    lng: 3.9492347,
    value: "7040",
  },
  {
    label: "Qu\u00e9vy-Le-Petit",
    lat: 50.367996,
    lng: 3.93752,
    value: "7040",
  },
  {
    label: "Givry",
    lat: 50.3795142,
    lng: 4.0293435,
    value: "7041",
  },
  {
    label: "Havay",
    lat: 50.3611541,
    lng: 3.9841693,
    value: "7041",
  },
  {
    label: "Erbaut",
    lat: 50.53309,
    lng: 3.884409,
    value: "7050",
  },
  {
    label: "Erbisoeul",
    lat: 50.5155495,
    lng: 3.8961158,
    value: "7050",
  },
  {
    label: "Herchies",
    lat: 50.528238,
    lng: 3.8577095,
    value: "7050",
  },
  {
    label: "Jurbise",
    lat: 50.5325067,
    lng: 3.9113179,
    value: "7050",
  },
  {
    label: "Masnuy-Saint-Jean",
    lat: 50.5310206,
    lng: 3.9423599,
    value: "7050",
  },
  {
    label: "Masnuy-Saint-Pierre",
    lat: 50.5369939,
    lng: 3.9598418,
    value: "7050",
  },
  {
    label: "Horrues",
    lat: 50.6093445,
    lng: 4.0413076,
    value: "7060",
  },
  {
    label: "Soignies",
    lat: 50.5775309,
    lng: 4.0732968,
    value: "7060",
  },
  {
    label: "Casteau",
    lat: 50.5130195,
    lng: 4.0031319,
    value: "7061",
  },
  {
    label: "Thieusies",
    lat: 50.5151015,
    lng: 4.0489552,
    value: "7061",
  },
  {
    label: "Naast",
    lat: 50.5536883,
    lng: 4.1011662,
    value: "7062",
  },
  {
    label: "Chauss\u00e9e-Notre-Dame-Louvignies",
    lat: 50.5921606,
    lng: 3.9979808,
    value: "7063",
  },
  {
    label: "Neufvilles",
    lat: 50.5683075,
    lng: 4.0029098,
    value: "7063",
  },
  {
    label: "Gottignies",
    lat: 50.4971875,
    lng: 4.0622054,
    value: "7070",
  },
  {
    label: "Le Roeulx",
    lat: 50.5034034,
    lng: 4.112245,
    value: "7070",
  },
  {
    label: "Mignault",
    lat: 50.5291801,
    lng: 4.152112,
    value: "7070",
  },
  {
    label: "Thieu",
    lat: 50.473352,
    lng: 4.0922224,
    value: "7070",
  },
  {
    label: "Ville-Sur-Haine",
    lat: 50.4767906,
    lng: 4.0655313,
    value: "7070",
  },
  {
    label: "Eugies",
    lat: 50.3906692,
    lng: 3.8883703,
    value: "7080",
  },
  {
    label: "Frameries",
    lat: 50.4087571,
    lng: 3.8905656,
    value: "7080",
  },
  {
    label: "La Bouverie",
    lat: 50.407951,
    lng: 3.8774491,
    value: "7080",
  },
  {
    label: "Noirchain",
    lat: 50.4018707,
    lng: 3.9298869,
    value: "7080",
  },
  {
    label: "Sars-La-Bruy\u00e8re",
    lat: 50.3719019,
    lng: 3.8764088,
    value: "7080",
  },
  {
    label: "Braine-Le-Comte",
    lat: 50.6123481,
    lng: 4.1425138,
    value: "7090",
  },
  {
    label: "Hennuy\u00e8res",
    lat: 50.6386981,
    lng: 4.1715689,
    value: "7090",
  },
  {
    label: "Henripont",
    lat: 50.5996119,
    lng: 4.1861019,
    value: "7090",
  },
  {
    label: "Petit-Roeulx-Lez-Braine",
    lat: 50.6240857,
    lng: 4.0894781,
    value: "7090",
  },
  {
    label: "Ronqui\u00e8res",
    lat: 50.6084328,
    lng: 4.2235457,
    value: "7090",
  },
  {
    label: "Steenkerque",
    lat: 50.6432692,
    lng: 4.0698505,
    value: "7090",
  },
  {
    label: "Haine-Saint-Paul",
    lat: 50.454942,
    lng: 4.1883094,
    value: "7100",
  },
  {
    label: "Haine-Saint-Pierre",
    lat: 50.4547557,
    lng: 4.2000181,
    value: "7100",
  },
  {
    label: "La Louvi\u00e8re",
    lat: 50.479487,
    lng: 4.1852682,
    value: "7100",
  },
  {
    label: "Saint-Vaast",
    lat: 50.4525018,
    lng: 4.1604101,
    value: "7100",
  },
  {
    label: "Trivi\u00e8res",
    lat: 50.4517378,
    lng: 4.1475857,
    value: "7100",
  },
  {
    label: "Boussoit",
    lat: 50.4588091,
    lng: 4.081995,
    value: "7110",
  },
  {
    label: "Houdeng-Aimeries",
    lat: 50.4835958,
    lng: 4.1524459,
    value: "7110",
  },
  {
    label: "Houdeng-Goegnies",
    lat: 50.4879863,
    lng: 4.1575303,
    value: "7110",
  },
  {
    label: "Maurage",
    lat: 50.4577279,
    lng: 4.0979858,
    value: "7110",
  },
  {
    label: "Str\u00e9py-Bracquegnies",
    lat: 50.474474,
    lng: 4.1207031,
    value: "7110",
  },
  {
    label: "Croix-Lez-Rouveroy",
    lat: 50.3609408,
    lng: 4.0758701,
    value: "7120",
  },
  {
    label: "Estinnes",
    lat: 50.3976702,
    lng: 4.0975758,
    value: "7120",
  },
  {
    label: "Estinnes-Au-Mont",
    lat: 50.3952117,
    lng: 4.0989347,
    value: "7120",
  },
  {
    label: "Estinnes-Au-Val",
    lat: 50.4127908,
    lng: 4.1064835,
    value: "7120",
  },
  {
    label: "Fauroeulx",
    lat: 50.3703355,
    lng: 4.1095709,
    value: "7120",
  },
  {
    label: "Haulchin",
    lat: 50.3831523,
    lng: 4.0801348,
    value: "7120",
  },
  {
    label: "Peissant",
    lat: 50.3512136,
    lng: 4.1208612,
    value: "7120",
  },
  {
    label: "Rouveroy",
    lat: 50.3555625,
    lng: 4.062845,
    value: "7120",
  },
  {
    label: "Vellereille-Le-Sec",
    lat: 50.401796,
    lng: 4.0573258,
    value: "7120",
  },
  {
    label: "Vellereille-Les-Brayeux",
    lat: 50.3790081,
    lng: 4.1507915,
    value: "7120",
  },
  {
    label: "Battignies",
    lat: 50.4165125,
    lng: 4.1685575,
    value: "7130",
  },
  {
    label: "Binche",
    lat: 50.412824846085,
    lng: 4.1701677159951,
    value: "7130",
  },
  {
    label: "Bray",
    lat: 50.4299906,
    lng: 4.1024947,
    value: "7130",
  },
  {
    label: "Waudrez",
    lat: 50.4137808,
    lng: 4.150981,
    value: "7131",
  },
  {
    label: "Buvrinnes",
    lat: 50.3897538,
    lng: 4.2055425,
    value: "7133",
  },
  {
    label: "Epinois",
    lat: 50.4049792,
    lng: 4.2093685,
    value: "7134",
  },
  {
    label: "Leval-Trahegnies",
    lat: 50.416547,
    lng: 4.218073,
    value: "7134",
  },
  {
    label: "P\u00e9ronnes-Lez-Binche",
    lat: 50.4364165,
    lng: 4.1458468,
    value: "7134",
  },
  {
    label: "Ressaix",
    lat: 50.4238109,
    lng: 4.1910482,
    value: "7134",
  },
  {
    label: "Morlanwelz",
    lat: 50.4509843,
    lng: 4.2523562,
    value: "7140",
  },
  {
    label: "Morlanwelz-Mariemont",
    lat: 50.4544626,
    lng: 4.243909,
    value: "7140",
  },
  {
    label: "Carni\u00e8res",
    lat: 50.443039,
    lng: 4.2542126,
    value: "7141",
  },
  {
    label: "Mont-Sainte-Aldegonde",
    lat: 50.4293887,
    lng: 4.2340727,
    value: "7141",
  },
  {
    label: "Chapelle-Lez-Herlaimont",
    lat: 50.4713265,
    lng: 4.2804202,
    value: "7160",
  },
  {
    label: "Godarville",
    lat: 50.4955503,
    lng: 4.2865899,
    value: "7160",
  },
  {
    label: "Pi\u00e9ton",
    lat: 50.4414944,
    lng: 4.2984261,
    value: "7160",
  },
  {
    label: "Bellecourt",
    lat: 50.4843145,
    lng: 4.254013,
    value: "7170",
  },
  {
    label: "Bois-D'haine",
    lat: 50.5023427,
    lng: 4.2156931,
    value: "7170",
  },
  {
    label: "Fayt-Lez-Manage",
    lat: 50.4898236,
    lng: 4.2282511,
    value: "7170",
  },
  {
    label: "La Hestre",
    lat: 50.4738724,
    lng: 4.2358174,
    value: "7170",
  },
  {
    label: "Manage",
    lat: 50.5036133,
    lng: 4.2343288,
    value: "7170",
  },
  {
    label: "Seneffe",
    lat: 50.52505545,
    lng: 4.2664609240834,
    value: "7180",
  },
  {
    label: "Arquennes",
    lat: 50.5640405,
    lng: 4.287514819651,
    value: "7181",
  },
  {
    label: "Familleureux",
    lat: 50.52567625,
    lng: 4.199657690926,
    value: "7181",
  },
  {
    label: "Feluy",
    lat: 50.5625639,
    lng: 4.2430926037513,
    value: "7181",
  },
  {
    label: "Petit-Roeulx-Lez-Nivelles",
    lat: 50.55876715,
    lng: 4.3186986634815,
    value: "7181",
  },
  {
    label: "Ecaussinnes",
    lat: 50.5696129,
    lng: 4.1752492,
    value: "7190",
  },
  {
    label: "Ecaussinnes-D'enghien",
    lat: 50.5714795,
    lng: 4.1776303,
    value: "7190",
  },
  {
    label: "Marche-Lez-Ecaussinnes",
    lat: 50.5468953,
    lng: 4.1832813,
    value: "7190",
  },
  {
    label: "Ecaussinnes-Lalaing",
    lat: 50.5703563,
    lng: 4.1785518,
    value: "7191",
  },
  {
    label: "Boussu",
    lat: 50.4331253,
    lng: 3.7960902,
    value: "7300",
  },
  {
    label: "Hornu",
    lat: 50.4337613,
    lng: 3.8276077,
    value: "7301",
  },
  {
    label: "Bernissart",
    lat: 50.475502,
    lng: 3.6504677,
    value: "7320",
  },
  {
    label: "Blaton",
    lat: 50.5011724,
    lng: 3.6611754,
    value: "7321",
  },
  {
    label: "Harchies",
    lat: 50.4794445,
    lng: 3.6936,
    value: "7321",
  },
  {
    label: "Pommeroeul",
    lat: 50.4611322,
    lng: 3.7107354,
    value: "7322",
  },
  {
    label: "Ville-Pommeroeul",
    lat: 50.471652,
    lng: 3.7304437,
    value: "7322",
  },
  {
    label: "Saint-Ghislain",
    lat: 50.448359502766,
    lng: 3.8214781396442,
    value: "7330",
  },
  {
    label: "Baudour",
    lat: 50.4814352,
    lng: 3.8327577,
    value: "7331",
  },
  {
    label: "Neufmaison",
    lat: 50.5304672,
    lng: 3.7918519,
    value: "7332",
  },
  {
    label: "Sirault",
    lat: 50.5060592,
    lng: 3.7881267,
    value: "7332",
  },
  {
    label: "Tertre",
    lat: 50.4673149,
    lng: 3.8081735,
    value: "7333",
  },
  {
    label: "Hautrage",
    lat: 50.4823864,
    lng: 3.7650049,
    value: "7334",
  },
  {
    label: "Villerot",
    lat: 50.4861629,
    lng: 3.7902777,
    value: "7334",
  },
  {
    label: "Colfontaine",
    lat: 50.4056635,
    lng: 3.8513958,
    value: "7340",
  },
  {
    label: "Paturages",
    lat: 50.4136507,
    lng: 3.8629223,
    value: "7340",
  },
  {
    label: "Warquignies",
    lat: 50.4002638,
    lng: 3.8223517,
    value: "7340",
  },
  {
    label: "Wasmes",
    lat: 50.414181,
    lng: 3.846637,
    value: "7340",
  },
  {
    label: "Hainin",
    lat: 50.4311514,
    lng: 3.7661218,
    value: "7350",
  },
  {
    label: "Hensies",
    lat: 50.4328388,
    lng: 3.6847215,
    value: "7350",
  },
  {
    label: "Montroeul-Sur-Haine",
    lat: 50.436001,
    lng: 3.703666,
    value: "7350",
  },
  {
    label: "Thulin",
    lat: 50.4292832,
    lng: 3.7393229,
    value: "7350",
  },
  {
    label: "Blaugies",
    lat: 50.3729779,
    lng: 3.8051335,
    value: "7370",
  },
  {
    label: "Dour",
    lat: 50.3978815,
    lng: 3.7806897,
    value: "7370",
  },
  {
    label: "Elouges",
    lat: 50.4036968,
    lng: 3.7521047,
    value: "7370",
  },
  {
    label: "Wih\u00e9ries",
    lat: 50.3852902,
    lng: 3.7521224,
    value: "7370",
  },
  {
    label: "Baisieux",
    lat: 50.387884,
    lng: 3.6957835,
    value: "7380",
  },
  {
    label: "Qui\u00e9vrain",
    lat: 50.4084818,
    lng: 3.6819263,
    value: "7380",
  },
  {
    label: "Audregnies",
    lat: 50.3830135,
    lng: 3.7177788,
    value: "7382",
  },
  {
    label: "Angre",
    lat: 50.366983,
    lng: 3.6954509,
    value: "7387",
  },
  {
    label: "Angreau",
    lat: 50.3509285,
    lng: 3.6911259,
    value: "7387",
  },
  {
    label: "Athis",
    lat: 50.3647711,
    lng: 3.776059,
    value: "7387",
  },
  {
    label: "Autreppe",
    lat: 50.3463188,
    lng: 3.7317059,
    value: "7387",
  },
  {
    label: "Erquennes",
    lat: 50.3590043,
    lng: 3.7933404,
    value: "7387",
  },
  {
    label: "Fayt-Le-Franc",
    lat: 50.3566928,
    lng: 3.7724828,
    value: "7387",
  },
  {
    label: "Honnelles",
    lat: 50.352089,
    lng: 3.731019,
    value: "7387",
  },
  {
    label: "Marchipont",
    lat: 50.377885,
    lng: 3.666982,
    value: "7387",
  },
  {
    label: "Montignies-Sur-Roc",
    lat: 50.3680079,
    lng: 3.7333223,
    value: "7387",
  },
  {
    label: "Onnezies",
    lat: 50.363513,
    lng: 3.71633,
    value: "7387",
  },
  {
    label: "Roisin",
    lat: 50.333082,
    lng: 3.6938101,
    value: "7387",
  },
  {
    label: "Quaregnon",
    lat: 50.4421804,
    lng: 3.8636971,
    value: "7390",
  },
  {
    label: "Wasmuel",
    lat: 50.445727,
    lng: 3.8466719,
    value: "7390",
  },
  {
    label: "Ere",
    lat: 50.5822217,
    lng: 3.3668477,
    value: "7500",
  },
  {
    label: "Saint-Maur",
    lat: 50.5721668,
    lng: 3.3923658,
    value: "7500",
  },
  {
    label: "Tournai",
    lat: 50.60566,
    lng: 3.3878259,
    value: "7500",
  },
  {
    label: "Orcq",
    lat: 50.6049549,
    lng: 3.3492864,
    value: "7501",
  },
  {
    label: "Esplechin",
    lat: 50.5743852,
    lng: 3.3038112,
    value: "7502",
  },
  {
    label: "Froyennes",
    lat: 50.6195489,
    lng: 3.3569842,
    value: "7503",
  },
  {
    label: "Froidmont",
    lat: 50.57792,
    lng: 3.3287795,
    value: "7504",
  },
  {
    label: "Willemeau",
    lat: 50.573981,
    lng: 3.350025,
    value: "7506",
  },
  {
    label: "Ramegnies-Chin",
    lat: 50.651295,
    lng: 3.335337,
    value: "7520",
  },
  {
    label: "Templeuve",
    lat: 50.6449056,
    lng: 3.2831577,
    value: "7520",
  },
  {
    label: "Chercq",
    lat: 50.5885516,
    lng: 3.4212542,
    value: "7521",
  },
  {
    label: "Blandain",
    lat: 50.6249665,
    lng: 3.3035015,
    value: "7522",
  },
  {
    label: "Hertain",
    lat: 50.6141111,
    lng: 3.2880161,
    value: "7522",
  },
  {
    label: "Lamain",
    lat: 50.5982618,
    lng: 3.2931148,
    value: "7522",
  },
  {
    label: "Marquain",
    lat: 50.6078843,
    lng: 3.3226398,
    value: "7522",
  },
  {
    label: "Gaurain-Ramecroix",
    lat: 50.5924891,
    lng: 3.4834445,
    value: "7530",
  },
  {
    label: "Havinnes",
    lat: 50.6156953,
    lng: 3.4655917,
    value: "7531",
  },
  {
    label: "Beclers",
    lat: 50.6217774,
    lng: 3.5028761,
    value: "7532",
  },
  {
    label: "Thimougies",
    lat: 50.6349458,
    lng: 3.5102508,
    value: "7533",
  },
  {
    label: "Barry",
    lat: 50.5875423,
    lng: 3.5430623,
    value: "7534",
  },
  {
    label: "Maulde",
    lat: 50.6169646,
    lng: 3.5483951,
    value: "7534",
  },
  {
    label: "Vaulx",
    lat: 50.589889,
    lng: 3.426172,
    value: "7536",
  },
  {
    label: "Vezon",
    lat: 50.5683976,
    lng: 3.5017888,
    value: "7538",
  },
  {
    label: "Kain",
    lat: 50.6379097,
    lng: 3.3809106,
    value: "7540",
  },
  {
    label: "Melles",
    lat: 50.646728,
    lng: 3.4818996,
    value: "7540",
  },
  {
    label: "Quartes",
    lat: 50.6501425,
    lng: 3.5120068,
    value: "7540",
  },
  {
    label: "Rumillies",
    lat: 50.6201039,
    lng: 3.4372618,
    value: "7540",
  },
  {
    label: "Mont-Saint-Aubert",
    lat: 50.6554352,
    lng: 3.4000171,
    value: "7542",
  },
  {
    label: "Mourcourt",
    lat: 50.6530409,
    lng: 3.445164,
    value: "7543",
  },
  {
    label: "Warchin",
    lat: 50.6096187,
    lng: 3.4267528,
    value: "7548",
  },
  {
    label: "P\u00e9ruwelz",
    lat: 50.5097897,
    lng: 3.5908864,
    value: "7600",
  },
  {
    label: "Roucourt",
    lat: 50.529165,
    lng: 3.5870901,
    value: "7601",
  },
  {
    label: "Bury",
    lat: 50.5426384,
    lng: 3.59458,
    value: "7602",
  },
  {
    label: "Bon-Secours",
    lat: 50.4976184,
    lng: 3.607483,
    value: "7603",
  },
  {
    label: "Baugnies",
    lat: 50.561586,
    lng: 3.552247,
    value: "7604",
  },
  {
    label: "Braffe",
    lat: 50.5523518,
    lng: 3.5828589,
    value: "7604",
  },
  {
    label: "Brasmenil",
    lat: 50.5417372,
    lng: 3.5472808,
    value: "7604",
  },
  {
    label: "Callenelle",
    lat: 50.5297342,
    lng: 3.5253447,
    value: "7604",
  },
  {
    label: "Wasmes-Audemez-Briffoeil",
    lat: 50.5539524,
    lng: 3.5352247,
    value: "7604",
  },
  {
    label: "Wiers",
    lat: 50.508665,
    lng: 3.533035,
    value: "7608",
  },
  {
    label: "Rumes",
    lat: 50.5557678,
    lng: 3.3039251,
    value: "7610",
  },
  {
    label: "La Glanerie",
    lat: 50.5298416,
    lng: 3.3010202,
    value: "7611",
  },
  {
    label: "Taintignies",
    lat: 50.5502028,
    lng: 3.3416855,
    value: "7618",
  },
  {
    label: "Bl\u00e9haries",
    lat: 50.5131151,
    lng: 3.4155171,
    value: "7620",
  },
  {
    label: "Brunehaut",
    lat: 50.5178063,
    lng: 3.3795619,
    value: "7620",
  },
  {
    label: "Guignies",
    lat: 50.5471385,
    lng: 3.3663017,
    value: "7620",
  },
  {
    label: "Hollain",
    lat: 50.5421847,
    lng: 3.4271843,
    value: "7620",
  },
  {
    label: "Jollain-Merlin",
    lat: 50.54598165,
    lng: 3.4041047456312,
    value: "7620",
  },
  {
    label: "Wez-Velvain",
    lat: 50.5468427,
    lng: 3.388586,
    value: "7620",
  },
  {
    label: "Lesdain",
    lat: 50.520291,
    lng: 3.3880963285681,
    value: "7621",
  },
  {
    label: "Laplaigne",
    lat: 50.52296155,
    lng: 3.4418587625525,
    value: "7622",
  },
  {
    label: "Rongy",
    lat: 50.50665205,
    lng: 3.3815632027097,
    value: "7623",
  },
  {
    label: "Howardries",
    lat: 50.5170287,
    lng: 3.3541900391658,
    value: "7624",
  },
  {
    label: "Antoing",
    lat: 50.565911,
    lng: 3.4513116,
    value: "7640",
  },
  {
    label: "Maubray",
    lat: 50.5515108,
    lng: 3.5020267,
    value: "7640",
  },
  {
    label: "P\u00e9ronnes-Lez-Antoing",
    lat: 50.551862,
    lng: 3.4538057,
    value: "7640",
  },
  {
    label: "Bruyelle",
    lat: 50.557403,
    lng: 3.426992,
    value: "7641",
  },
  {
    label: "Calonne",
    lat: 50.578325,
    lng: 3.4371661,
    value: "7642",
  },
  {
    label: "Fontenoy",
    lat: 50.567887,
    lng: 3.473373,
    value: "7643",
  },
  {
    label: "Luingne",
    lat: 50.7386186,
    lng: 3.2344942,
    value: "7700",
  },
  {
    label: "Mouscron",
    lat: 50.7433351,
    lng: 3.2139093,
    value: "7700",
  },
  {
    label: "Dottignies",
    lat: 50.7273981,
    lng: 3.3037301,
    value: "7711",
  },
  {
    label: "Herseaux",
    lat: 50.7227127,
    lng: 3.2342044,
    value: "7712",
  },
  {
    label: "Bailleul",
    lat: 50.6689214,
    lng: 3.3176015,
    value: "7730",
  },
  {
    label: "Estaimbourg",
    lat: 50.6826198,
    lng: 3.3180722,
    value: "7730",
  },
  {
    label: "Estaimpuis",
    lat: 50.7059794,
    lng: 3.2640184,
    value: "7730",
  },
  {
    label: "Evregnies",
    lat: 50.7130523,
    lng: 3.2875443,
    value: "7730",
  },
  {
    label: "Leers-Nord",
    lat: 50.6875576,
    lng: 3.2703911,
    value: "7730",
  },
  {
    label: "N\u00e9chin",
    lat: 50.6669438,
    lng: 3.2690175,
    value: "7730",
  },
  {
    label: "Saint-L\u00e9ger",
    lat: 50.7056533,
    lng: 3.3149729,
    value: "7730",
  },
  {
    label: "Pecq",
    lat: 50.685616,
    lng: 3.3407492,
    value: "7740",
  },
  {
    label: "Warcoing",
    lat: 50.702173,
    lng: 3.3451985,
    value: "7740",
  },
  {
    label: "H\u00e9rinnes-Lez-Pecq",
    lat: 50.7008798,
    lng: 3.3623581,
    value: "7742",
  },
  {
    label: "Esquelmes",
    lat: 50.665077,
    lng: 3.347779,
    value: "7743",
  },
  {
    label: "Obigies",
    lat: 50.662055,
    lng: 3.364281,
    value: "7743",
  },
  {
    label: "Amougies",
    lat: 50.743719,
    lng: 3.5066795,
    value: "7750",
  },
  {
    label: "Anseroeul",
    lat: 50.728033,
    lng: 3.520558,
    value: "7750",
  },
  {
    label: "Mont-De-L'enclus",
    lat: 50.7456511,
    lng: 3.5110542,
    value: "7750",
  },
  {
    label: "Orroir",
    lat: 50.747596,
    lng: 3.481663,
    value: "7750",
  },
  {
    label: "Russeignies",
    lat: 50.7447633,
    lng: 3.5309528,
    value: "7750",
  },
  {
    label: "Celles",
    lat: 50.7125435,
    lng: 3.4571664,
    value: "7760",
  },
  {
    label: "Escanaffles",
    lat: 50.7546292,
    lng: 3.4486114,
    value: "7760",
  },
  {
    label: "Molenbaix",
    lat: 50.6930043,
    lng: 3.4294567,
    value: "7760",
  },
  {
    label: "Popuelles",
    lat: 50.6621501,
    lng: 3.5192597,
    value: "7760",
  },
  {
    label: "Pottes",
    lat: 50.7314164,
    lng: 3.4050325,
    value: "7760",
  },
  {
    label: "Velaines",
    lat: 50.6685604,
    lng: 3.4865934,
    value: "7760",
  },
  {
    label: "Comines",
    lat: 50.768736,
    lng: 2.998666,
    value: "7780",
  },
  {
    label: "Comines-Warneton",
    lat: 50.77120012074,
    lng: 3.0019077817068,
    value: "7780",
  },
  {
    label: "Houthem",
    lat: 50.7868243,
    lng: 2.9640553,
    value: "7781",
  },
  {
    label: "Ploegsteert",
    lat: 50.7262204,
    lng: 2.8803139,
    value: "7782",
  },
  {
    label: "Bizet",
    lat: 50.7060943,
    lng: 2.88999,
    value: "7783",
  },
  {
    label: "Bas-Warneton",
    lat: 50.7588153,
    lng: 2.9625206,
    value: "7784",
  },
  {
    label: "Warneton",
    lat: 50.7529932,
    lng: 2.9502023,
    value: "7784",
  },
  {
    label: "Ath",
    lat: 50.639504499385,
    lng: 3.7926793502157,
    value: "7800",
  },
  {
    label: "Lanquesaint",
    lat: 50.6534242,
    lng: 3.8042549,
    value: "7800",
  },
  {
    label: "Irchonwelz",
    lat: 50.6201619,
    lng: 3.7536768,
    value: "7801",
  },
  {
    label: "Ormeignies",
    lat: 50.5947224,
    lng: 3.7516336,
    value: "7802",
  },
  {
    label: "Bouvignies",
    lat: 50.6477056,
    lng: 3.7677307,
    value: "7803",
  },
  {
    label: "Ostiches",
    lat: 50.6788509,
    lng: 3.7584252,
    value: "7804",
  },
  {
    label: "Rebaix",
    lat: 50.660198,
    lng: 3.7836911,
    value: "7804",
  },
  {
    label: "Maffle",
    lat: 50.615068,
    lng: 3.8060162,
    value: "7810",
  },
  {
    label: "Arbre",
    lat: 50.6103517,
    lng: 3.8148309,
    value: "7811",
  },
  {
    label: "Houtaing",
    lat: 50.6397485,
    lng: 3.67998,
    value: "7812",
  },
  {
    label: "Ligne",
    lat: 50.6222783,
    lng: 3.7054981,
    value: "7812",
  },
  {
    label: "Mainvault",
    lat: 50.6495829,
    lng: 3.7184421,
    value: "7812",
  },
  {
    label: "Moulbaix",
    lat: 50.600191,
    lng: 3.7156806,
    value: "7812",
  },
  {
    label: "Villers-Notre-Dame",
    lat: 50.617881,
    lng: 3.7360962,
    value: "7812",
  },
  {
    label: "Villers-Saint-Amand",
    lat: 50.6226624,
    lng: 3.730712,
    value: "7812",
  },
  {
    label: "Ghislenghien",
    lat: 50.6566864,
    lng: 3.875818,
    value: "7822",
  },
  {
    label: "Isi\u00e8res",
    lat: 50.6637962,
    lng: 3.8181687,
    value: "7822",
  },
  {
    label: "Meslin-L'ev\u00eaque",
    lat: 50.6485784,
    lng: 3.8480962,
    value: "7822",
  },
  {
    label: "Gibecq",
    lat: 50.637673,
    lng: 3.8878195,
    value: "7823",
  },
  {
    label: "Bassilly",
    lat: 50.6724203,
    lng: 3.9360497,
    value: "7830",
  },
  {
    label: "Fouleng",
    lat: 50.6182841,
    lng: 3.9282801,
    value: "7830",
  },
  {
    label: "Gondregnies",
    lat: 50.627067,
    lng: 3.9118667,
    value: "7830",
  },
  {
    label: "Graty",
    lat: 50.6311726,
    lng: 3.9967288,
    value: "7830",
  },
  {
    label: "Hellebecq",
    lat: 50.663165,
    lng: 3.8887882,
    value: "7830",
  },
  {
    label: "Hoves",
    lat: 50.6717098,
    lng: 4.0358365,
    value: "7830",
  },
  {
    label: "Silly",
    lat: 50.6489028,
    lng: 3.9243973,
    value: "7830",
  },
  {
    label: "Thoricourt",
    lat: 50.6101161,
    lng: 3.9509699,
    value: "7830",
  },
  {
    label: "Enghien",
    lat: 50.6927553,
    lng: 4.0406912,
    value: "7850",
  },
  {
    label: "Marcq",
    lat: 50.6903571,
    lng: 4.0173479,
    value: "7850",
  },
  {
    label: "Petit-Enghien",
    lat: 50.6899321,
    lng: 4.0845939,
    value: "7850",
  },
  {
    label: "Lessines",
    lat: 50.7123036,
    lng: 3.8301131,
    value: "7860",
  },
  {
    label: "Papignies",
    lat: 50.6861533,
    lng: 3.819132,
    value: "7861",
  },
  {
    label: "Wannebecq",
    lat: 50.6940794,
    lng: 3.7998354,
    value: "7861",
  },
  {
    label: "Ogy",
    lat: 50.7208405,
    lng: 3.780225,
    value: "7862",
  },
  {
    label: "Ghoy",
    lat: 50.7283427,
    lng: 3.8094385,
    value: "7863",
  },
  {
    label: "Deux-Acren",
    lat: 50.7307336,
    lng: 3.8522036,
    value: "7864",
  },
  {
    label: "Bois-De-Lessines",
    lat: 50.6953023,
    lng: 3.8856306,
    value: "7866",
  },
  {
    label: "Ollignies",
    lat: 50.6874157,
    lng: 3.8598773,
    value: "7866",
  },
  {
    label: "Bauffe",
    lat: 50.5701441,
    lng: 3.8537045,
    value: "7870",
  },
  {
    label: "Cambron-Saint-Vincent",
    lat: 50.5827811,
    lng: 3.9162237,
    value: "7870",
  },
  {
    label: "Lens",
    lat: 50.5570816,
    lng: 3.9005461,
    value: "7870",
  },
  {
    label: "Lombise",
    lat: 50.6007682,
    lng: 3.9397913,
    value: "7870",
  },
  {
    label: "Montignies-Lez-Lens",
    lat: 50.5643065,
    lng: 3.9433795,
    value: "7870",
  },
  {
    label: "Flobecq",
    lat: 50.7371675,
    lng: 3.7378707,
    value: "7880",
  },
  {
    label: "Ellezelles",
    lat: 50.7338701,
    lng: 3.6802062,
    value: "7890",
  },
  {
    label: "Lahamaide",
    lat: 50.69558,
    lng: 3.7246735,
    value: "7890",
  },
  {
    label: "Wodecq",
    lat: 50.7178459,
    lng: 3.7444299,
    value: "7890",
  },
  {
    label: "Grandmetz",
    lat: 50.6220544,
    lng: 3.6322713,
    value: "7900",
  },
  {
    label: "Leuze-En-Hainaut",
    lat: 50.599042981582,
    lng: 3.617736902638,
    value: "7900",
  },
  {
    label: "Thieulain",
    lat: 50.6179826,
    lng: 3.6085857,
    value: "7901",
  },
  {
    label: "Blicquy",
    lat: 50.5878005,
    lng: 3.6857513,
    value: "7903",
  },
  {
    label: "Chapelle-\u00c0-Oie",
    lat: 50.5967927,
    lng: 3.6709445,
    value: "7903",
  },
  {
    label: "Chapelle-\u00c0-Wattines",
    lat: 50.6125001,
    lng: 3.6551354,
    value: "7903",
  },
  {
    label: "Pipaix",
    lat: 50.582865,
    lng: 3.575887,
    value: "7904",
  },
  {
    label: "Tourpes",
    lat: 50.572688,
    lng: 3.6493593,
    value: "7904",
  },
  {
    label: "Willaupuis",
    lat: 50.566635,
    lng: 3.6038961,
    value: "7904",
  },
  {
    label: "Gallaix",
    lat: 50.6060715,
    lng: 3.5748349,
    value: "7906",
  },
  {
    label: "Anvaing",
    lat: 50.6817661,
    lng: 3.5617225,
    value: "7910",
  },
  {
    label: "Arc-Wattripont",
    lat: 50.7282535,
    lng: 3.5489704,
    value: "7910",
  },
  {
    label: "Cordes",
    lat: 50.688202,
    lng: 3.5342514,
    value: "7910",
  },
  {
    label: "Ellignies-Lez-Frasnes",
    lat: 50.6747768,
    lng: 3.5903652,
    value: "7910",
  },
  {
    label: "Forest",
    lat: 50.6736151,
    lng: 3.5373149,
    value: "7910",
  },
  {
    label: "Frasnes-Lez-Anvaing",
    lat: 50.684567271165,
    lng: 3.5455253970222,
    value: "7910",
  },
  {
    label: "Wattripont",
    lat: 50.7282535,
    lng: 3.5489704,
    value: "7910",
  },
  {
    label: "Buissenal",
    lat: 50.6648633,
    lng: 3.6551307,
    value: "7911",
  },
  {
    label: "Frasnes-Lez-Buissenal",
    lat: 50.667755,
    lng: 3.6194481,
    value: "7911",
  },
  {
    label: "Hacquegnies",
    lat: 50.6522435,
    lng: 3.5938505,
    value: "7911",
  },
  {
    label: "Herquegies",
    lat: 50.6349335,
    lng: 3.5785557,
    value: "7911",
  },
  {
    label: "Montroeul-Au-Bois",
    lat: 50.642909,
    lng: 3.571359,
    value: "7911",
  },
  {
    label: "Moustier",
    lat: 50.6557475,
    lng: 3.619931,
    value: "7911",
  },
  {
    label: "Oeudeghien",
    lat: 50.6780664,
    lng: 3.7117971,
    value: "7911",
  },
  {
    label: "Dergneau",
    lat: 50.712579,
    lng: 3.5666,
    value: "7912",
  },
  {
    label: "Saint-Sauveur",
    lat: 50.7061803,
    lng: 3.5977459,
    value: "7912",
  },
  {
    label: "Brugelette",
    lat: 50.5954253,
    lng: 3.8546563,
    value: "7940",
  },
  {
    label: "Cambron-Casteau",
    lat: 50.5885597,
    lng: 3.8807488,
    value: "7940",
  },
  {
    label: "Attre",
    lat: 50.6102428,
    lng: 3.8410951,
    value: "7941",
  },
  {
    label: "M\u00e9vergnies-Lez-Lens",
    lat: 50.6044689,
    lng: 3.8507081,
    value: "7942",
  },
  {
    label: "Gages",
    lat: 50.6068018,
    lng: 3.8910777,
    value: "7943",
  },
  {
    label: "Chi\u00e8vres",
    lat: 50.587693,
    lng: 3.810128,
    value: "7950",
  },
  {
    label: "Grosage",
    lat: 50.5444446,
    lng: 3.7571017,
    value: "7950",
  },
  {
    label: "Huissignies",
    lat: 50.5658217,
    lng: 3.7532905,
    value: "7950",
  },
  {
    label: "Ladeuze",
    lat: 50.567984,
    lng: 3.7713023,
    value: "7950",
  },
  {
    label: "Tongre-Saint-Martin",
    lat: 50.5860413,
    lng: 3.7907921,
    value: "7950",
  },
  {
    label: "Tongre-Notre-Dame",
    lat: 50.581313,
    lng: 3.7748043,
    value: "7951",
  },
  {
    label: "Beloeil",
    lat: 50.5479197,
    lng: 3.7361279,
    value: "7970",
  },
  {
    label: "Bas\u00e8cles",
    lat: 50.5252131,
    lng: 3.6472042,
    value: "7971",
  },
  {
    label: "Ramegnies",
    lat: 50.544393,
    lng: 3.6351295,
    value: "7971",
  },
  {
    label: "Thumaide",
    lat: 50.5404186,
    lng: 3.6304664,
    value: "7971",
  },
  {
    label: "Wadelincourt",
    lat: 50.5385955,
    lng: 3.6483678,
    value: "7971",
  },
  {
    label: "Aubechies",
    lat: 50.573932,
    lng: 3.6769173,
    value: "7972",
  },
  {
    label: "Quevaucamps",
    lat: 50.5302519,
    lng: 3.6909731,
    value: "7972",
  },
  {
    label: "Grandglise",
    lat: 50.5044413,
    lng: 3.6966742,
    value: "7973",
  },
  {
    label: "Stambruges",
    lat: 50.508349,
    lng: 3.7202435,
    value: "7973",
  },
  {
    label: "Brugge",
    lat: 51.2147083,
    lng: 3.2073611,
    value: "8000",
  },
  {
    label: "Koolkerke",
    lat: 51.2419954,
    lng: 3.2473789,
    value: "8000",
  },
  {
    label: "Hertsberge",
    lat: 51.10996785,
    lng: 3.2612349549978,
    value: "8020",
  },
  {
    label: "Oostkamp",
    lat: 51.1511469,
    lng: 3.2497137262681,
    value: "8020",
  },
  {
    label: "Ruddervoorde",
    lat: 51.08464525,
    lng: 3.2014781027698,
    value: "8020",
  },
  {
    label: "Waardamme",
    lat: 51.1123296,
    lng: 3.2222872,
    value: "8020",
  },
  {
    label: "Sint-Andries",
    lat: 51.18830895,
    lng: 3.1750054151928,
    value: "8200",
  },
  {
    label: "Sint-Michiels",
    lat: 51.1884619,
    lng: 3.2111722,
    value: "8200",
  },
  {
    label: "Loppem",
    lat: 51.1557488,
    lng: 3.1955523,
    value: "8210",
  },
  {
    label: "Veldegem",
    lat: 51.1048944,
    lng: 3.1606518,
    value: "8210",
  },
  {
    label: "Zedelgem",
    lat: 51.1355326,
    lng: 3.1404473279372,
    value: "8210",
  },
  {
    label: "Aartrijke",
    lat: 51.1180002,
    lng: 3.0913265545139,
    value: "8211",
  },
  {
    label: "Knokke",
    lat: 51.34942965,
    lng: 3.3233738606453,
    value: "8300",
  },
  {
    label: "Knokke-Heist",
    lat: 51.3396066,
    lng: 3.2702133,
    value: "8300",
  },
  {
    label: "Westkapelle",
    lat: 51.3207807,
    lng: 3.3172865697943,
    value: "8300",
  },
  {
    label: "Ramskapelle",
    lat: 51.3126954,
    lng: 3.2505004,
    value: "8301",
  },
  {
    label: "Assebroek",
    lat: 51.1933202,
    lng: 3.2570785402003,
    value: "8310",
  },
  {
    label: "Sint-Kruis",
    lat: 51.2142018,
    lng: 3.2506508,
    value: "8310",
  },
  {
    label: "Damme",
    lat: 51.2553021,
    lng: 3.2920911130762,
    value: "8340",
  },
  {
    label: "Hoeke",
    lat: 51.2936953,
    lng: 3.3302838831505,
    value: "8340",
  },
  {
    label: "Lapscheure",
    lat: 51.2821424,
    lng: 3.3523918963854,
    value: "8340",
  },
  {
    label: "Moerkerke",
    lat: 51.2422333,
    lng: 3.3558017705651,
    value: "8340",
  },
  {
    label: "Oostkerke",
    lat: 51.2751719,
    lng: 3.2884674049058,
    value: "8340",
  },
  {
    label: "Sijsele",
    lat: 51.210525,
    lng: 3.3435315097294,
    value: "8340",
  },
  {
    label: "Blankenberge",
    lat: 51.31651475,
    lng: 3.1297578035057,
    value: "8370",
  },
  {
    label: "Uitkerke",
    lat: 51.2996935,
    lng: 3.1404868099971,
    value: "8370",
  },
  {
    label: "Houtave",
    lat: 51.2388018,
    lng: 3.1071768079055,
    value: "8377",
  },
  {
    label: "Meetkerke",
    lat: 51.2356311,
    lng: 3.1469831971503,
    value: "8377",
  },
  {
    label: "Nieuwmunster",
    lat: 51.275802,
    lng: 3.0994149719104,
    value: "8377",
  },
  {
    label: "Zuienkerke",
    lat: 51.2685834,
    lng: 3.1567406094326,
    value: "8377",
  },
  {
    label: "Dudzele",
    lat: 51.2747463,
    lng: 3.2292277,
    value: "8380",
  },
  {
    label: "Lissewege",
    lat: 51.31711375,
    lng: 3.1991824156265,
    value: "8380",
  },
  {
    label: "Zeebrugge",
    lat: 51.331382,
    lng: 3.2078842,
    value: "8380",
  },
  {
    label: "Oostende",
    lat: 51.2303177,
    lng: 2.9203275,
    value: "8400",
  },
  {
    label: "Stene",
    lat: 51.20214645,
    lng: 2.9200835535007,
    value: "8400",
  },
  {
    label: "Zandvoorde",
    lat: 51.20484775,
    lng: 2.9668008654261,
    value: "8400",
  },
  {
    label: "De Haan",
    lat: 51.2415107,
    lng: 3.0049248,
    value: "8420",
  },
  {
    label: "Klemskerke",
    lat: 51.242521,
    lng: 3.0238828,
    value: "8420",
  },
  {
    label: "Wenduine",
    lat: 51.3019771,
    lng: 3.0927740709639,
    value: "8420",
  },
  {
    label: "Vlissegem",
    lat: 51.2641695,
    lng: 3.0635598193415,
    value: "8421",
  },
  {
    label: "Middelkerke",
    lat: 51.18331695,
    lng: 2.8063401225473,
    value: "8430",
  },
  {
    label: "Wilskerke",
    lat: 51.1809498,
    lng: 2.8401373,
    value: "8431",
  },
  {
    label: "Leffinge",
    lat: 51.1746761,
    lng: 2.8776766,
    value: "8432",
  },
  {
    label: "Mannekensvere",
    lat: 51.1258003,
    lng: 2.8183418,
    value: "8433",
  },
  {
    label: "Schore",
    lat: 51.1110889,
    lng: 2.8402112,
    value: "8433",
  },
  {
    label: "Sint-Pieters-Kapelle",
    lat: 51.1228258,
    lng: 2.8859081205773,
    value: "8433",
  },
  {
    label: "Slijpe",
    lat: 51.1543955,
    lng: 2.8476959,
    value: "8433",
  },
  {
    label: "Lombardsijde",
    lat: 51.15402425,
    lng: 2.7549864166944,
    value: "8434",
  },
  {
    label: "Westende",
    lat: 51.15833025,
    lng: 2.7777512824641,
    value: "8434",
  },
  {
    label: "Bredene",
    lat: 51.23890495,
    lng: 2.9725769468284,
    value: "8450",
  },
  {
    label: "Ettelgem",
    lat: 51.19765475,
    lng: 3.0384504834689,
    value: "8460",
  },
  {
    label: "Oudenburg",
    lat: 51.20118315,
    lng: 3.0061089520182,
    value: "8460",
  },
  {
    label: "Roksem",
    lat: 51.1662204,
    lng: 3.0333284671459,
    value: "8460",
  },
  {
    label: "Westkerke",
    lat: 51.16355795,
    lng: 3.0047445512126,
    value: "8460",
  },
  {
    label: "Gistel",
    lat: 51.1582502,
    lng: 2.9670810948205,
    value: "8470",
  },
  {
    label: "Moere",
    lat: 51.1319588,
    lng: 2.9565554411399,
    value: "8470",
  },
  {
    label: "Snaaskerke",
    lat: 51.17625885,
    lng: 2.9333678137411,
    value: "8470",
  },
  {
    label: "Zevekote",
    lat: 51.1381923,
    lng: 2.9016605351732,
    value: "8470",
  },
  {
    label: "Bekegem",
    lat: 51.14886485,
    lng: 3.0582322396597,
    value: "8480",
  },
  {
    label: "Eernegem",
    lat: 51.12819605,
    lng: 3.023122345129,
    value: "8480",
  },
  {
    label: "Ichtegem",
    lat: 51.08742745,
    lng: 3.0296222129927,
    value: "8480",
  },
  {
    label: "Jabbeke",
    lat: 51.1846386,
    lng: 3.0902953889402,
    value: "8490",
  },
  {
    label: "Snellegem",
    lat: 51.17552695,
    lng: 3.1229871043255,
    value: "8490",
  },
  {
    label: "Stalhille",
    lat: 51.21970915,
    lng: 3.0730014206469,
    value: "8490",
  },
  {
    label: "Varsenare",
    lat: 51.19920035,
    lng: 3.1361350320817,
    value: "8490",
  },
  {
    label: "Zerkegem",
    lat: 51.1712593,
    lng: 3.0612486455732,
    value: "8490",
  },
  {
    label: "Kortrijk",
    lat: 50.81788085,
    lng: 3.2784153903743,
    value: "8500",
  },
  {
    label: "Bissegem",
    lat: 50.8228226,
    lng: 3.2284723,
    value: "8501",
  },
  {
    label: "Heule",
    lat: 50.8511736,
    lng: 3.2382004806211,
    value: "8501",
  },
  {
    label: "Bellegem",
    lat: 50.7781502,
    lng: 3.2800137,
    value: "8510",
  },
  {
    label: "Kooigem",
    lat: 50.739664,
    lng: 3.3261001,
    value: "8510",
  },
  {
    label: "Marke",
    lat: 50.8070452,
    lng: 3.2336226,
    value: "8510",
  },
  {
    label: "Rollegem",
    lat: 50.77232975,
    lng: 3.2564730180436,
    value: "8510",
  },
  {
    label: "Aalbeke",
    lat: 50.77745545,
    lng: 3.2186046321991,
    value: "8511",
  },
  {
    label: "Kuurne",
    lat: 50.86005145,
    lng: 3.2736322150643,
    value: "8520",
  },
  {
    label: "Harelbeke",
    lat: 50.8293249,
    lng: 3.3425511,
    value: "8530",
  },
  {
    label: "Bavikhove",
    lat: 50.8790473,
    lng: 3.3126044844799,
    value: "8531",
  },
  {
    label: "Hulste",
    lat: 50.8820656,
    lng: 3.2990273,
    value: "8531",
  },
  {
    label: "Deerlijk",
    lat: 50.83878495,
    lng: 3.3622790229139,
    value: "8540",
  },
  {
    label: "Zwevegem",
    lat: 50.80406995,
    lng: 3.3419067426276,
    value: "8550",
  },
  {
    label: "Heestert",
    lat: 50.7821543,
    lng: 3.4120184,
    value: "8551",
  },
  {
    label: "Moen",
    lat: 50.775415,
    lng: 3.3825263241918,
    value: "8552",
  },
  {
    label: "Otegem",
    lat: 50.80927585,
    lng: 3.4245499365566,
    value: "8553",
  },
  {
    label: "Sint-Denijs",
    lat: 50.76440175,
    lng: 3.349745322203,
    value: "8554",
  },
  {
    label: "Gullegem",
    lat: 50.84493445,
    lng: 3.2026154878227,
    value: "8560",
  },
  {
    label: "Moorsele",
    lat: 50.84259085,
    lng: 3.1549318040629,
    value: "8560",
  },
  {
    label: "Wevelgem",
    lat: 50.8121065,
    lng: 3.1864450062666,
    value: "8560",
  },
  {
    label: "Anzegem",
    lat: 50.844003,
    lng: 3.463734552107,
    value: "8570",
  },
  {
    label: "Gijzelbrechtegem",
    lat: 50.8279177,
    lng: 3.5060602,
    value: "8570",
  },
  {
    label: "Ingooigem",
    lat: 50.8297876,
    lng: 3.4271837357678,
    value: "8570",
  },
  {
    label: "Vichte",
    lat: 50.8353247,
    lng: 3.4068379,
    value: "8570",
  },
  {
    label: "Kaster",
    lat: 50.8121917,
    lng: 3.4976090001837,
    value: "8572",
  },
  {
    label: "Tiegem",
    lat: 50.8130531,
    lng: 3.4697738543929,
    value: "8573",
  },
  {
    label: "Avelgem",
    lat: 50.7754997,
    lng: 3.4468985313677,
    value: "8580",
  },
  {
    label: "Kerkhove",
    lat: 50.8008418,
    lng: 3.5058971661987,
    value: "8581",
  },
  {
    label: "Waarmaarde",
    lat: 50.7903789,
    lng: 3.4854202,
    value: "8581",
  },
  {
    label: "Outrijve",
    lat: 50.75868715,
    lng: 3.4257331809137,
    value: "8582",
  },
  {
    label: "Bossuit",
    lat: 50.74804505,
    lng: 3.4080819304802,
    value: "8583",
  },
  {
    label: "Espierres",
    lat: 50.72568025,
    lng: 3.3465264652837,
    value: "8587",
  },
  {
    label: "Espierres-Helchin",
    lat: 50.72568025,
    lng: 3.3465264652837,
    value: "8587",
  },
  {
    label: "Helchin",
    lat: 50.7300021,
    lng: 3.3837254,
    value: "8587",
  },
  {
    label: "Beerst",
    lat: 51.05367055,
    lng: 2.873227743412,
    value: "8600",
  },
  {
    label: "Diksmuide",
    lat: 51.0333408,
    lng: 2.8647185,
    value: "8600",
  },
  {
    label: "Driekapellen",
    lat: 50.7059709,
    lng: 3.9855229,
    value: "8600",
  },
  {
    label: "Esen",
    lat: 51.02078675,
    lng: 2.8990845692587,
    value: "8600",
  },
  {
    label: "Kaaskerke",
    lat: 51.0358094,
    lng: 2.8387074,
    value: "8600",
  },
  {
    label: "Keiem",
    lat: 51.08623835,
    lng: 2.8814585998043,
    value: "8600",
  },
  {
    label: "Lampernisse",
    lat: 51.0396362,
    lng: 2.7703028703735,
    value: "8600",
  },
  {
    label: "Leke",
    lat: 51.10066415,
    lng: 2.8993179258716,
    value: "8600",
  },
  {
    label: "Nieuwkapelle",
    lat: 50.9974451,
    lng: 2.8042814087195,
    value: "8600",
  },
  {
    label: "Oostkerke",
    lat: 51.04353425,
    lng: 2.7997074366181,
    value: "8600",
  },
  {
    label: "Oudekapelle",
    lat: 51.0206897,
    lng: 2.8040816730756,
    value: "8600",
  },
  {
    label: "Pervijze",
    lat: 51.08053165,
    lng: 2.7914713556336,
    value: "8600",
  },
  {
    label: "Stuivekenskerke",
    lat: 51.07690075,
    lng: 2.823381776939,
    value: "8600",
  },
  {
    label: "Vladslo",
    lat: 51.06253345,
    lng: 2.9207800886428,
    value: "8600",
  },
  {
    label: "Woumen",
    lat: 50.99494815,
    lng: 2.8520595441482,
    value: "8600",
  },
  {
    label: "Handzame",
    lat: 51.0321939,
    lng: 2.996075151704,
    value: "8610",
  },
  {
    label: "Kortemark",
    lat: 51.0285109,
    lng: 3.0441178,
    value: "8610",
  },
  {
    label: "Werken",
    lat: 51.0290062,
    lng: 2.9637026,
    value: "8610",
  },
  {
    label: "Zarren",
    lat: 51.0181095,
    lng: 2.9597764,
    value: "8610",
  },
  {
    label: "Nieuwpoort",
    lat: 51.14416005,
    lng: 2.7283986495034,
    value: "8620",
  },
  {
    label: "Ramskapelle",
    lat: 51.10665355,
    lng: 2.7811249526825,
    value: "8620",
  },
  {
    label: "Sint-Joris",
    lat: 51.1300222,
    lng: 2.7781339,
    value: "8620",
  },
  {
    label: "Avekapelle",
    lat: 51.0631103,
    lng: 2.7457710712354,
    value: "8630",
  },
  {
    label: "Booitshoeke",
    lat: 51.08832895,
    lng: 2.7399983485035,
    value: "8630",
  },
  {
    label: "Bulskamp",
    lat: 51.0444613,
    lng: 2.6434487702288,
    value: "8630",
  },
  {
    label: "De Moeren",
    lat: 51.03587905,
    lng: 2.5953950379549,
    value: "8630",
  },
  {
    label: "Eggewaartskapelle",
    lat: 51.0495264,
    lng: 2.7298247748665,
    value: "8630",
  },
  {
    label: "Houtem",
    lat: 51.0069146,
    lng: 2.604305716244,
    value: "8630",
  },
  {
    label: "Steenkerke",
    lat: 51.0527661,
    lng: 2.6895389537109,
    value: "8630",
  },
  {
    label: "Veurne",
    lat: 51.071043,
    lng: 2.6548858237238,
    value: "8630",
  },
  {
    label: "Vinkem",
    lat: 51.01364415,
    lng: 2.6570581890086,
    value: "8630",
  },
  {
    label: "Wulveringem",
    lat: 51.03030765,
    lng: 2.6550439487441,
    value: "8630",
  },
  {
    label: "Zoutenaaie",
    lat: 51.05092295,
    lng: 2.7515311775286,
    value: "8630",
  },
  {
    label: "Oostvleteren",
    lat: 50.92313355,
    lng: 2.7492224474635,
    value: "8640",
  },
  {
    label: "Vleteren",
    lat: 50.930143,
    lng: 2.7289867,
    value: "8640",
  },
  {
    label: "Westvleteren",
    lat: 50.9103823,
    lng: 2.7205508961772,
    value: "8640",
  },
  {
    label: "Woesten",
    lat: 50.9035513,
    lng: 2.7887585311487,
    value: "8640",
  },
  {
    label: "Lo",
    lat: 50.9931276,
    lng: 2.7607800723908,
    value: "8647",
  },
  {
    label: "Lo-Reninge",
    lat: 50.9638066,
    lng: 2.7643391,
    value: "8647",
  },
  {
    label: "Noordschote",
    lat: 50.9484727,
    lng: 2.8217318560837,
    value: "8647",
  },
  {
    label: "Pollinkhove",
    lat: 50.9726562,
    lng: 2.7198585358008,
    value: "8647",
  },
  {
    label: "Reninge",
    lat: 50.94228475,
    lng: 2.7771170853989,
    value: "8647",
  },
  {
    label: "Houthulst",
    lat: 50.97407785,
    lng: 2.9438110508216,
    value: "8650",
  },
  {
    label: "Klerken",
    lat: 50.99044185,
    lng: 2.9155135952546,
    value: "8650",
  },
  {
    label: "Merkem",
    lat: 50.962074,
    lng: 2.8736172801014,
    value: "8650",
  },
  {
    label: "Adinkerke",
    lat: 51.06926315,
    lng: 2.5896028375712,
    value: "8660",
  },
  {
    label: "De Panne",
    lat: 51.09437775,
    lng: 2.5806696887141,
    value: "8660",
  },
  {
    label: "Koksijde",
    lat: 51.1091101,
    lng: 2.6353426015022,
    value: "8670",
  },
  {
    label: "Oostduinkerke",
    lat: 51.12531835,
    lng: 2.7012679966518,
    value: "8670",
  },
  {
    label: "Wulpen",
    lat: 51.09779175,
    lng: 2.7073119164927,
    value: "8670",
  },
  {
    label: "Bovekerke",
    lat: 51.05621365,
    lng: 2.9633414725432,
    value: "8680",
  },
  {
    label: "Koekelare",
    lat: 51.0911765,
    lng: 2.9682187344976,
    value: "8680",
  },
  {
    label: "Zande",
    lat: 51.1174383,
    lng: 2.9186353800579,
    value: "8680",
  },
  {
    label: "Alveringem",
    lat: 51.01509585,
    lng: 2.7225688102727,
    value: "8690",
  },
  {
    label: "Hoogstade",
    lat: 50.9800701,
    lng: 2.6903321,
    value: "8690",
  },
  {
    label: "Oeren",
    lat: 51.0235332,
    lng: 2.7051716,
    value: "8690",
  },
  {
    label: "Sint-Rijkers",
    lat: 50.9945231,
    lng: 2.6881434,
    value: "8690",
  },
  {
    label: "Gijverinkhove",
    lat: 50.9761749,
    lng: 2.6727552,
    value: "8691",
  },
  {
    label: "Izenberge",
    lat: 50.9896601,
    lng: 2.6608332486701,
    value: "8691",
  },
  {
    label: "Leisele",
    lat: 50.9844835,
    lng: 2.622401,
    value: "8691",
  },
  {
    label: "Stavele",
    lat: 50.9398411,
    lng: 2.673321,
    value: "8691",
  },
  {
    label: "Aarsele",
    lat: 50.9972412,
    lng: 3.4139884196625,
    value: "8700",
  },
  {
    label: "Kanegem",
    lat: 51.021401,
    lng: 3.4035567083156,
    value: "8700",
  },
  {
    label: "Schuiferskapelle",
    lat: 51.03700665,
    lng: 3.3283107336881,
    value: "8700",
  },
  {
    label: "Tielt",
    lat: 50.99996475,
    lng: 3.3414263284671,
    value: "8700",
  },
  {
    label: "Ooigem",
    lat: 50.8949817,
    lng: 3.3304964142362,
    value: "8710",
  },
  {
    label: "Sint-Baafs-Vijve",
    lat: 50.9245338,
    lng: 3.3862545030012,
    value: "8710",
  },
  {
    label: "Wielsbeke",
    lat: 50.91140755,
    lng: 3.3604037488967,
    value: "8710",
  },
  {
    label: "Dentergem",
    lat: 50.966125,
    lng: 3.4022937173313,
    value: "8720",
  },
  {
    label: "Markegem",
    lat: 50.95014795,
    lng: 3.394380172379,
    value: "8720",
  },
  {
    label: "Oeselgem",
    lat: 50.94008805,
    lng: 3.429680547389,
    value: "8720",
  },
  {
    label: "Wakken",
    lat: 50.93483565,
    lng: 3.4009830853574,
    value: "8720",
  },
  {
    label: "Beernem",
    lat: 51.12774395,
    lng: 3.3144732597608,
    value: "8730",
  },
  {
    label: "Oedelem",
    lat: 51.166016,
    lng: 3.3441122702909,
    value: "8730",
  },
  {
    label: "Sint-Joris",
    lat: 51.125095,
    lng: 3.3646568393591,
    value: "8730",
  },
  {
    label: "Egem",
    lat: 51.0209559,
    lng: 3.2540634837689,
    value: "8740",
  },
  {
    label: "Pittem",
    lat: 50.99996475,
    lng: 3.2622730034453,
    value: "8740",
  },
  {
    label: "Wingene",
    lat: 51.06808895,
    lng: 3.2847683081115,
    value: "8750",
  },
  {
    label: "Zwevezele",
    lat: 51.0422255,
    lng: 3.2053756921268,
    value: "8750",
  },
  {
    label: "Ruiselede",
    lat: 51.062755,
    lng: 3.3789495803068,
    value: "8755",
  },
  {
    label: "Meulebeke",
    lat: 50.9484788,
    lng: 3.2859605761212,
    value: "8760",
  },
  {
    label: "Ingelmunster",
    lat: 50.92196635,
    lng: 3.2617189739329,
    value: "8770",
  },
  {
    label: "Oostrozebeke",
    lat: 50.93434535,
    lng: 3.3468206372996,
    value: "8780",
  },
  {
    label: "Waregem",
    lat: 50.87620025,
    lng: 3.4257380400092,
    value: "8790",
  },
  {
    label: "Beveren",
    lat: 50.871701,
    lng: 3.3427472358752,
    value: "8791",
  },
  {
    label: "Desselgem",
    lat: 50.88302825,
    lng: 3.3669119563274,
    value: "8792",
  },
  {
    label: "Sint-Eloois-Vijve",
    lat: 50.9057236,
    lng: 3.4036062,
    value: "8793",
  },
  {
    label: "Beveren",
    lat: 50.9687312,
    lng: 3.14416,
    value: "8800",
  },
  {
    label: "Oekene",
    lat: 50.9042588,
    lng: 3.1627760972777,
    value: "8800",
  },
  {
    label: "Roeselare",
    lat: 50.9444948,
    lng: 3.124765,
    value: "8800",
  },
  {
    label: "Rumbeke",
    lat: 50.91511475,
    lng: 3.1223026883986,
    value: "8800",
  },
  {
    label: "Lichtervelde",
    lat: 51.03033065,
    lng: 3.1422717715338,
    value: "8810",
  },
  {
    label: "Torhout",
    lat: 51.0585915,
    lng: 3.0913072045454,
    value: "8820",
  },
  {
    label: "Gits",
    lat: 50.9975916,
    lng: 3.1103285411089,
    value: "8830",
  },
  {
    label: "Hooglede",
    lat: 50.98218015,
    lng: 3.0687215849639,
    value: "8830",
  },
  {
    label: "Oostnieuwkerke",
    lat: 50.94159445,
    lng: 3.0641586047981,
    value: "8840",
  },
  {
    label: "Staden",
    lat: 50.97048785,
    lng: 3.001340623187,
    value: "8840",
  },
  {
    label: "Westrozebeke",
    lat: 50.933009,
    lng: 3.0095525945894,
    value: "8840",
  },
  {
    label: "Ardooie",
    lat: 50.97076545,
    lng: 3.2045936674459,
    value: "8850",
  },
  {
    label: "Koolskamp",
    lat: 51.0075272,
    lng: 3.2016142835313,
    value: "8851",
  },
  {
    label: "Lendelede",
    lat: 50.8850661,
    lng: 3.2320134658174,
    value: "8860",
  },
  {
    label: "Emelgem",
    lat: 50.9336597,
    lng: 3.2212852352972,
    value: "8870",
  },
  {
    label: "Izegem",
    lat: 50.91382225,
    lng: 3.2090654210557,
    value: "8870",
  },
  {
    label: "Kachtem",
    lat: 50.93988415,
    lng: 3.1890400909612,
    value: "8870",
  },
  {
    label: "Ledegem",
    lat: 50.86898195,
    lng: 3.1176598783352,
    value: "8880",
  },
  {
    label: "Rollegem-Kapelle",
    lat: 50.87005025,
    lng: 3.1510232055719,
    value: "8880",
  },
  {
    label: "Sint-Eloois-Winkel",
    lat: 50.8765142,
    lng: 3.179116,
    value: "8880",
  },
  {
    label: "Dadizele",
    lat: 50.84693275,
    lng: 3.1056665757612,
    value: "8890",
  },
  {
    label: "Moorslede",
    lat: 50.89006565,
    lng: 3.0732075972642,
    value: "8890",
  },
  {
    label: "Brielen",
    lat: 50.8689046,
    lng: 2.8475388,
    value: "8900",
  },
  {
    label: "Dikkebus",
    lat: 50.8192065,
    lng: 2.8309734,
    value: "8900",
  },
  {
    label: "Ieper",
    lat: 50.851808,
    lng: 2.8914774512132,
    value: "8900",
  },
  {
    label: "Sint-Jan",
    lat: 50.8646017,
    lng: 2.9038085,
    value: "8900",
  },
  {
    label: "Hollebeke",
    lat: 50.8056486,
    lng: 2.9383694,
    value: "8902",
  },
  {
    label: "Voormezele",
    lat: 50.8169949,
    lng: 2.8757825,
    value: "8902",
  },
  {
    label: "Zillebeke",
    lat: 50.8346385,
    lng: 2.9224029,
    value: "8902",
  },
  {
    label: "Boezinge",
    lat: 50.89329445,
    lng: 2.8624482523982,
    value: "8904",
  },
  {
    label: "Zuidschote",
    lat: 50.9129617,
    lng: 2.8285726,
    value: "8904",
  },
  {
    label: "Elverdinge",
    lat: 50.88120105,
    lng: 2.8086072093094,
    value: "8906",
  },
  {
    label: "Vlamertinge",
    lat: 50.84722495,
    lng: 2.8146596555515,
    value: "8908",
  },
  {
    label: "Bikschote",
    lat: 50.92504495,
    lng: 2.863108835832,
    value: "8920",
  },
  {
    label: "Langemark",
    lat: 50.91011515,
    lng: 2.912173087276,
    value: "8920",
  },
  {
    label: "Langemark-Poelkapelle",
    lat: 50.9122567,
    lng: 2.9338635,
    value: "8920",
  },
  {
    label: "Poelkapelle",
    lat: 50.9278167,
    lng: 2.9632606863338,
    value: "8920",
  },
  {
    label: "Lauwe",
    lat: 50.78879055,
    lng: 3.1938978797182,
    value: "8930",
  },
  {
    label: "Menen",
    lat: 50.7969879,
    lng: 3.1156565,
    value: "8930",
  },
  {
    label: "Rekkem",
    lat: 50.7743769,
    lng: 3.1658835832872,
    value: "8930",
  },
  {
    label: "Geluwe",
    lat: 50.826939,
    lng: 3.0689009201802,
    value: "8940",
  },
  {
    label: "Wervik",
    lat: 50.79981455,
    lng: 3.0429882329964,
    value: "8940",
  },
  {
    label: "Heuvelland",
    lat: 50.76764855,
    lng: 2.8457459110391,
    value: "8950",
  },
  {
    label: "Nieuwkerke",
    lat: 50.7457387,
    lng: 2.8251932,
    value: "8950",
  },
  {
    label: "Dranouter",
    lat: 50.7662832,
    lng: 2.7832991,
    value: "8951",
  },
  {
    label: "Wulvergem",
    lat: 50.7675035,
    lng: 2.840869239241,
    value: "8952",
  },
  {
    label: "Wijtschate",
    lat: 50.7832683,
    lng: 2.9126192876216,
    value: "8953",
  },
  {
    label: "Westouter",
    lat: 50.8003371,
    lng: 2.7409610507934,
    value: "8954",
  },
  {
    label: "Kemmel",
    lat: 50.7952131,
    lng: 2.8383675286243,
    value: "8956",
  },
  {
    label: "Messines",
    lat: 50.7596526,
    lng: 2.9001214234264,
    value: "8957",
  },
  {
    label: "Loker",
    lat: 50.78372855,
    lng: 2.7797309731213,
    value: "8958",
  },
  {
    label: "Poperinge",
    lat: 50.84362245,
    lng: 2.7222592638771,
    value: "8970",
  },
  {
    label: "Reningelst",
    lat: 50.8170498,
    lng: 2.7636029,
    value: "8970",
  },
  {
    label: "Krombeke",
    lat: 50.9045753,
    lng: 2.6729063768433,
    value: "8972",
  },
  {
    label: "Proven",
    lat: 50.8903437,
    lng: 2.6542871,
    value: "8972",
  },
  {
    label: "Roesbrugge-Haringe",
    lat: 50.9150531,
    lng: 2.6262588,
    value: "8972",
  },
  {
    label: "Watou",
    lat: 50.84518645,
    lng: 2.6449117149406,
    value: "8978",
  },
  {
    label: "Beselare",
    lat: 50.85048855,
    lng: 3.0293494282427,
    value: "8980",
  },
  {
    label: "Geluveld",
    lat: 50.8340552,
    lng: 2.9962568059484,
    value: "8980",
  },
  {
    label: "Passendale",
    lat: 50.89915125,
    lng: 3.0054428297242,
    value: "8980",
  },
  {
    label: "Zandvoorde",
    lat: 50.8151226,
    lng: 2.9770015427192,
    value: "8980",
  },
  {
    label: "Zonnebeke",
    lat: 50.8731885,
    lng: 2.9874678,
    value: "8980",
  },
  {
    label: "Gent",
    lat: 51.0397129,
    lng: 3.7141549000597,
    value: "9000",
  },
  {
    label: "Mariakerke",
    lat: 51.0731177,
    lng: 3.6781210300259,
    value: "9030",
  },
  {
    label: "Drongen",
    lat: 51.0528944,
    lng: 3.6395168043236,
    value: "9031",
  },
  {
    label: "Wondelgem",
    lat: 51.09349535,
    lng: 3.7034612746567,
    value: "9032",
  },
  {
    label: "Sint-Amandsberg",
    lat: 51.061875,
    lng: 3.7488657,
    value: "9040",
  },
  {
    label: "Oostakker",
    lat: 51.1001986,
    lng: 3.7644128,
    value: "9041",
  },
  {
    label: "Desteldonk",
    lat: 51.1221778,
    lng: 3.7836111,
    value: "9042",
  },
  {
    label: "Mendonk",
    lat: 51.1467255,
    lng: 3.8221384,
    value: "9042",
  },
  {
    label: "Sint-Kruis-Winkel",
    lat: 51.1541943,
    lng: 3.8234371,
    value: "9042",
  },
  {
    label: "Gentbrugge",
    lat: 51.0447236,
    lng: 3.7588813,
    value: "9050",
  },
  {
    label: "Ledeberg",
    lat: 51.0372053,
    lng: 3.7414088,
    value: "9050",
  },
  {
    label: "Afsnee",
    lat: 51.0299989,
    lng: 3.6575415863856,
    value: "9051",
  },
  {
    label: "Sint-Denijs-Westrem",
    lat: 51.0199405,
    lng: 3.6676767,
    value: "9051",
  },
  {
    label: "Zwijnaarde",
    lat: 51.00092235,
    lng: 3.7021790930106,
    value: "9052",
  },
  {
    label: "Zelzate",
    lat: 51.2000036,
    lng: 3.8105002,
    value: "9060",
  },
  {
    label: "Destelbergen",
    lat: 51.0556204,
    lng: 3.7978887,
    value: "9070",
  },
  {
    label: "Heusden",
    lat: 51.0287341,
    lng: 3.8003347,
    value: "9070",
  },
  {
    label: "Beervelde",
    lat: 51.0802144,
    lng: 3.8792625,
    value: "9080",
  },
  {
    label: "Lochristi",
    lat: 51.0991574,
    lng: 3.8271355,
    value: "9080",
  },
  {
    label: "Zaffelare",
    lat: 51.1323736,
    lng: 3.8601634,
    value: "9080",
  },
  {
    label: "Zeveneken",
    lat: 51.1078751,
    lng: 3.9000296,
    value: "9080",
  },
  {
    label: "Gontrode",
    lat: 50.9842505,
    lng: 3.7976423,
    value: "9090",
  },
  {
    label: "Melle",
    lat: 51.00303,
    lng: 3.7988833,
    value: "9090",
  },
  {
    label: "Nieuwkerken-Waas",
    lat: 51.1933908,
    lng: 4.1780279,
    value: "9100",
  },
  {
    label: "Sint-Niklaas",
    lat: 51.163813060072,
    lng: 4.149464320724,
    value: "9100",
  },
  {
    label: "Belsele",
    lat: 51.1570236,
    lng: 4.0917750185456,
    value: "9111",
  },
  {
    label: "Sinaai-Waas",
    lat: 51.1866966,
    lng: 3.9795579,
    value: "9112",
  },
  {
    label: "Haasdonk",
    lat: 51.1806482,
    lng: 4.2383861,
    value: "9120",
  },
  {
    label: "Kallo",
    lat: 51.2652143,
    lng: 4.2588008641609,
    value: "9120",
  },
  {
    label: "Melsele",
    lat: 51.2211394,
    lng: 4.2821446,
    value: "9120",
  },
  {
    label: "Vrasene",
    lat: 51.2191283,
    lng: 4.194377,
    value: "9120",
  },
  {
    label: "Doel",
    lat: 51.3181461,
    lng: 4.2456374474582,
    value: "9130",
  },
  {
    label: "Kallo",
    lat: 51.2519515,
    lng: 4.27803,
    value: "9130",
  },
  {
    label: "Kieldrecht",
    lat: 51.3038751,
    lng: 4.1983386657846,
    value: "9130",
  },
  {
    label: "Verrebroek",
    lat: 51.2540739,
    lng: 4.1871839695525,
    value: "9130",
  },
  {
    label: "Elversele",
    lat: 51.12017065,
    lng: 4.1457568844955,
    value: "9140",
  },
  {
    label: "Steendorp",
    lat: 51.1310863,
    lng: 4.2646567358993,
    value: "9140",
  },
  {
    label: "Temse",
    lat: 51.1421436,
    lng: 4.2133505278525,
    value: "9140",
  },
  {
    label: "Tielrode",
    lat: 51.12202775,
    lng: 4.1736809973609,
    value: "9140",
  },
  {
    label: "Bazel",
    lat: 51.14685855,
    lng: 4.2873842506575,
    value: "9150",
  },
  {
    label: "Kruibeke",
    lat: 51.1712275,
    lng: 4.3091107,
    value: "9150",
  },
  {
    label: "Rupelmonde",
    lat: 51.1283692,
    lng: 4.2906315,
    value: "9150",
  },
  {
    label: "Daknam",
    lat: 51.12798595,
    lng: 3.9808547859604,
    value: "9160",
  },
  {
    label: "Eksaarde",
    lat: 51.1487608,
    lng: 3.965212,
    value: "9160",
  },
  {
    label: "Lokeren",
    lat: 51.1042159,
    lng: 3.9911114,
    value: "9160",
  },
  {
    label: "De Klinge",
    lat: 51.2564526,
    lng: 4.0898144,
    value: "9170",
  },
  {
    label: "Meerdonk",
    lat: 51.2588943,
    lng: 4.1511549,
    value: "9170",
  },
  {
    label: "Sint-Gillis-Waas",
    lat: 51.2180687,
    lng: 4.1233014,
    value: "9170",
  },
  {
    label: "Sint-Pauwels",
    lat: 51.1939255,
    lng: 4.1016628795877,
    value: "9170",
  },
  {
    label: "Moerbeke-Waas",
    lat: 51.1742698,
    lng: 3.9446436,
    value: "9180",
  },
  {
    label: "Wachtebeke",
    lat: 51.1701927,
    lng: 3.8574284,
    value: "9185",
  },
  {
    label: "Kemzeke",
    lat: 51.2065228,
    lng: 4.0771037,
    value: "9190",
  },
  {
    label: "Stekene",
    lat: 51.2104744,
    lng: 4.0240412532131,
    value: "9190",
  },
  {
    label: "Appels",
    lat: 51.02983825,
    lng: 4.0562275450955,
    value: "9200",
  },
  {
    label: "Baasrode",
    lat: 51.03987315,
    lng: 4.1444016705284,
    value: "9200",
  },
  {
    label: "Dendermonde",
    lat: 51.0312293,
    lng: 4.098112,
    value: "9200",
  },
  {
    label: "Grembergen",
    lat: 51.0533487,
    lng: 4.1025435644905,
    value: "9200",
  },
  {
    label: "Mespelare",
    lat: 50.99557415,
    lng: 4.0652617659305,
    value: "9200",
  },
  {
    label: "Oudegem",
    lat: 51.0081807,
    lng: 4.0513674371884,
    value: "9200",
  },
  {
    label: "Schoonaarde",
    lat: 50.9986902,
    lng: 4.0158906182168,
    value: "9200",
  },
  {
    label: "Sint-Gillis-Dendermonde",
    lat: 51.0226035,
    lng: 4.1276622058379,
    value: "9200",
  },
  {
    label: "Hamme",
    lat: 51.0881618,
    lng: 4.1202807810427,
    value: "9220",
  },
  {
    label: "Moerzeke",
    lat: 51.06183125,
    lng: 4.1585956393745,
    value: "9220",
  },
  {
    label: "Massemen",
    lat: 50.9809343,
    lng: 3.8735741,
    value: "9230",
  },
  {
    label: "Westrem",
    lat: 50.9697855,
    lng: 3.8614248,
    value: "9230",
  },
  {
    label: "Wetteren",
    lat: 51.0068804,
    lng: 3.8855494,
    value: "9230",
  },
  {
    label: "Zele",
    lat: 51.0683621,
    lng: 4.0385864,
    value: "9240",
  },
  {
    label: "Waasmunster",
    lat: 51.1097147,
    lng: 4.0842158,
    value: "9250",
  },
  {
    label: "Buggenhout",
    lat: 51.0113077,
    lng: 4.192804319009,
    value: "9255",
  },
  {
    label: "Opdorp",
    lat: 51.0286063,
    lng: 4.2213781726505,
    value: "9255",
  },
  {
    label: "Schellebelle",
    lat: 51.0102518,
    lng: 3.928141226004,
    value: "9260",
  },
  {
    label: "Serskamp",
    lat: 50.98997155,
    lng: 3.9309567805899,
    value: "9260",
  },
  {
    label: "Wichelen",
    lat: 51.0020843,
    lng: 3.9806438098974,
    value: "9260",
  },
  {
    label: "Kalken",
    lat: 51.0370133,
    lng: 3.918568,
    value: "9270",
  },
  {
    label: "Laarne",
    lat: 51.029657,
    lng: 3.8504738,
    value: "9270",
  },
  {
    label: "Denderbelle",
    lat: 51.0011357,
    lng: 4.0954432870762,
    value: "9280",
  },
  {
    label: "Lebbeke",
    lat: 51.0004013,
    lng: 4.1307711,
    value: "9280",
  },
  {
    label: "Wieze",
    lat: 50.9786275,
    lng: 4.0981810157403,
    value: "9280",
  },
  {
    label: "Berlare",
    lat: 51.0301606,
    lng: 4.0113470297154,
    value: "9290",
  },
  {
    label: "Overmere",
    lat: 51.05073005,
    lng: 3.9569121644559,
    value: "9290",
  },
  {
    label: "Uitbergen",
    lat: 51.0278435,
    lng: 3.9639785004366,
    value: "9290",
  },
  {
    label: "Aalst",
    lat: 50.9429755,
    lng: 4.0396424203486,
    value: "9300",
  },
  {
    label: "Gijzegem",
    lat: 50.9831967,
    lng: 4.0413684723722,
    value: "9308",
  },
  {
    label: "Hofstade",
    lat: 50.96682385,
    lng: 4.0298563154701,
    value: "9308",
  },
  {
    label: "Baardegem",
    lat: 50.9559778,
    lng: 4.1400064082098,
    value: "9310",
  },
  {
    label: "Herdersem",
    lat: 50.97349005,
    lng: 4.0687165350537,
    value: "9310",
  },
  {
    label: "Meldert",
    lat: 50.93483295,
    lng: 4.1339380209476,
    value: "9310",
  },
  {
    label: "Moorsel",
    lat: 50.95235015,
    lng: 4.1008746699882,
    value: "9310",
  },
  {
    label: "Erembodegem",
    lat: 50.9160962,
    lng: 4.0573610045016,
    value: "9320",
  },
  {
    label: "Nieuwerkerken",
    lat: 50.92226365,
    lng: 4.0060975097656,
    value: "9320",
  },
  {
    label: "Impe",
    lat: 50.9605364,
    lng: 3.9521618,
    value: "9340",
  },
  {
    label: "Lede",
    lat: 50.9660758,
    lng: 3.9775435,
    value: "9340",
  },
  {
    label: "Oordegem",
    lat: 50.9574043,
    lng: 3.90209,
    value: "9340",
  },
  {
    label: "Smetlede",
    lat: 50.9660712,
    lng: 3.9269566,
    value: "9340",
  },
  {
    label: "Wanzele",
    lat: 50.9741595,
    lng: 3.9570133,
    value: "9340",
  },
  {
    label: "Appelterre-Eichem",
    lat: 50.819114,
    lng: 3.9673266115113,
    value: "9400",
  },
  {
    label: "Denderwindeke",
    lat: 50.7986183,
    lng: 4.0241352,
    value: "9400",
  },
  {
    label: "Lieferinge",
    lat: 50.7918522,
    lng: 4.0533344,
    value: "9400",
  },
  {
    label: "Nederhasselt",
    lat: 50.8463587,
    lng: 3.9750229,
    value: "9400",
  },
  {
    label: "Ninove",
    lat: 50.8353611,
    lng: 4.0242373,
    value: "9400",
  },
  {
    label: "Okegem",
    lat: 50.8563924,
    lng: 4.0561164,
    value: "9400",
  },
  {
    label: "Voorde",
    lat: 50.8254777,
    lng: 3.9454543,
    value: "9400",
  },
  {
    label: "Pollare",
    lat: 50.8168641,
    lng: 3.9969451,
    value: "9401",
  },
  {
    label: "Meerbeke",
    lat: 50.81972985,
    lng: 4.0458501718008,
    value: "9402",
  },
  {
    label: "Neigem",
    lat: 50.803005,
    lng: 4.0606636,
    value: "9403",
  },
  {
    label: "Aspelare",
    lat: 50.8421637,
    lng: 3.9584406,
    value: "9404",
  },
  {
    label: "Outer",
    lat: 50.84658595,
    lng: 3.9976558921405,
    value: "9406",
  },
  {
    label: "Aaigem",
    lat: 50.8894174,
    lng: 3.9366909,
    value: "9420",
  },
  {
    label: "Bambrugge",
    lat: 51.0545566,
    lng: 3.4154479,
    value: "9420",
  },
  {
    label: "Burst",
    lat: 50.9136525,
    lng: 3.9203695,
    value: "9420",
  },
  {
    label: "Erondegem",
    lat: 50.9407226,
    lng: 3.9555369,
    value: "9420",
  },
  {
    label: "Erpe",
    lat: 50.9333485,
    lng: 3.976602,
    value: "9420",
  },
  {
    label: "Erpe-Mere",
    lat: 50.9226391,
    lng: 3.9459888,
    value: "9420",
  },
  {
    label: "Mere",
    lat: 50.923005,
    lng: 3.9710267,
    value: "9420",
  },
  {
    label: "Ottergem",
    lat: 50.9341483,
    lng: 3.9468882,
    value: "9420",
  },
  {
    label: "Denderhoutem",
    lat: 50.878059,
    lng: 4.0059338596505,
    value: "9450",
  },
  {
    label: "Haaltert",
    lat: 50.9022532,
    lng: 4.0057717,
    value: "9450",
  },
  {
    label: "Heldergem",
    lat: 50.8816742,
    lng: 3.9548012,
    value: "9450",
  },
  {
    label: "Kerksken",
    lat: 50.88457295,
    lng: 3.9792346670559,
    value: "9451",
  },
  {
    label: "Denderleeuw",
    lat: 50.8843903,
    lng: 4.0665468327116,
    value: "9470",
  },
  {
    label: "Iddergem",
    lat: 50.8746795,
    lng: 4.0440509206633,
    value: "9472",
  },
  {
    label: "Welle",
    lat: 50.9005405,
    lng: 4.0448599,
    value: "9473",
  },
  {
    label: "Geraardsbergen",
    lat: 50.7689045,
    lng: 3.879789,
    value: "9500",
  },
  {
    label: "Goeferdinge",
    lat: 50.7654043,
    lng: 3.8392513,
    value: "9500",
  },
  {
    label: "Moerbeke",
    lat: 51.1742698,
    lng: 3.9446436,
    value: "9500",
  },
  {
    label: "Nederboelare",
    lat: 50.78113635,
    lng: 3.8718226231289,
    value: "9500",
  },
  {
    label: "Onkerzele",
    lat: 50.77876845,
    lng: 3.9040294677949,
    value: "9500",
  },
  {
    label: "Ophasselt",
    lat: 50.8218582,
    lng: 3.897035,
    value: "9500",
  },
  {
    label: "Overboelare",
    lat: 50.7622936,
    lng: 3.8625157,
    value: "9500",
  },
  {
    label: "Viane",
    lat: 50.7386591,
    lng: 3.923884698906,
    value: "9500",
  },
  {
    label: "Zarlardinge",
    lat: 50.76432255,
    lng: 3.8252714980661,
    value: "9500",
  },
  {
    label: "Grimminge",
    lat: 50.7945316,
    lng: 3.9525416,
    value: "9506",
  },
  {
    label: "Idegem",
    lat: 50.8043243,
    lng: 3.9295308016087,
    value: "9506",
  },
  {
    label: "Nieuwenhove",
    lat: 50.7875051,
    lng: 3.9891935,
    value: "9506",
  },
  {
    label: "Schendelbeke",
    lat: 50.7968671,
    lng: 3.8989486,
    value: "9506",
  },
  {
    label: "Smeerebbe-Vloerzegem",
    lat: 50.81624535,
    lng: 3.9259210427614,
    value: "9506",
  },
  {
    label: "Waarbeke",
    lat: 50.7779309,
    lng: 3.968228,
    value: "9506",
  },
  {
    label: "Zandbergen",
    lat: 50.8013287,
    lng: 3.9673318,
    value: "9506",
  },
  {
    label: "Bavegem",
    lat: 50.9449691,
    lng: 3.8680104604272,
    value: "9520",
  },
  {
    label: "Oombergen",
    lat: 50.8988376,
    lng: 3.8381185,
    value: "9520",
  },
  {
    label: "Sint-Lievens-Houtem",
    lat: 50.91972825,
    lng: 3.8521321366835,
    value: "9520",
  },
  {
    label: "Vlierzele",
    lat: 50.935088,
    lng: 3.9009360317784,
    value: "9520",
  },
  {
    label: "Zonnegem",
    lat: 50.93017155,
    lng: 3.9163011372068,
    value: "9520",
  },
  {
    label: "Letterhoutem",
    lat: 50.92696,
    lng: 3.8812597,
    value: "9521",
  },
  {
    label: "Herzele",
    lat: 50.88830225,
    lng: 3.8873953834097,
    value: "9550",
  },
  {
    label: "Hillegem",
    lat: 50.895806,
    lng: 3.853568,
    value: "9550",
  },
  {
    label: "Sint-Antelinks",
    lat: 50.8495126,
    lng: 3.9249438,
    value: "9550",
  },
  {
    label: "Sint-Lievens-Esse",
    lat: 50.8553127,
    lng: 3.8871129,
    value: "9550",
  },
  {
    label: "Steenhuize-Wijnhuize",
    lat: 50.8411146,
    lng: 3.8903745,
    value: "9550",
  },
  {
    label: "Woubrechtegem",
    lat: 50.8726761,
    lng: 3.9158325,
    value: "9550",
  },
  {
    label: "Ressegem",
    lat: 50.8920791,
    lng: 3.9109808,
    value: "9551",
  },
  {
    label: "Borsbeke",
    lat: 50.9047179,
    lng: 3.8945771,
    value: "9552",
  },
  {
    label: "Deftinge",
    lat: 50.7863579,
    lng: 3.8409061,
    value: "9570",
  },
  {
    label: "Lierde",
    lat: 50.815045,
    lng: 3.8249605,
    value: "9570",
  },
  {
    label: "Sint-Maria-Lierde",
    lat: 50.8217365,
    lng: 3.8370299582538,
    value: "9570",
  },
  {
    label: "Hemelveerdegem",
    lat: 50.8088369,
    lng: 3.8638783508569,
    value: "9571",
  },
  {
    label: "Sint-Martens-Lierde",
    lat: 50.80452055,
    lng: 3.8157846440419,
    value: "9572",
  },
  {
    label: "Renaix",
    lat: 50.7476192,
    lng: 3.6020465,
    value: "9600",
  },
  {
    label: "Elene",
    lat: 50.8907864,
    lng: 3.8078492,
    value: "9620",
  },
  {
    label: "Erwetegem",
    lat: 50.8558332,
    lng: 3.8142328,
    value: "9620",
  },
  {
    label: "Godveerdegem",
    lat: 50.8594123,
    lng: 3.8204374,
    value: "9620",
  },
  {
    label: "Grotenberge",
    lat: 50.872305,
    lng: 3.8333978,
    value: "9620",
  },
  {
    label: "Leeuwergem",
    lat: 50.8880639,
    lng: 3.829278,
    value: "9620",
  },
  {
    label: "Oombergen",
    lat: 50.8988376,
    lng: 3.8381185,
    value: "9620",
  },
  {
    label: "Sint-Goriks-Oudenhove",
    lat: 50.8522592,
    lng: 3.7863829,
    value: "9620",
  },
  {
    label: "Sint-Maria-Oudenhove",
    lat: 50.82512665,
    lng: 3.7998568349562,
    value: "9620",
  },
  {
    label: "Strijpen",
    lat: 50.8690354,
    lng: 3.7893247,
    value: "9620",
  },
  {
    label: "Velzeke-Ruddershove",
    lat: 50.8824839,
    lng: 3.7825766,
    value: "9620",
  },
  {
    label: "Zottegem",
    lat: 50.86909165,
    lng: 3.8155460176798,
    value: "9620",
  },
  {
    label: "Beerlegem",
    lat: 50.90267645,
    lng: 3.719796314005,
    value: "9630",
  },
  {
    label: "Dikkele",
    lat: 50.9034583,
    lng: 3.7418503,
    value: "9630",
  },
  {
    label: "Hundelgem",
    lat: 50.8891513,
    lng: 3.7552865,
    value: "9630",
  },
  {
    label: "Meilegem",
    lat: 50.9051876,
    lng: 3.6992879,
    value: "9630",
  },
  {
    label: "Munkzwalm",
    lat: 50.8776841,
    lng: 3.7327378,
    value: "9630",
  },
  {
    label: "Paulatem",
    lat: 50.8963806,
    lng: 3.7148522,
    value: "9630",
  },
  {
    label: "Roborst",
    lat: 50.8636242,
    lng: 3.7535689,
    value: "9630",
  },
  {
    label: "Rozebeke",
    lat: 50.8488955,
    lng: 3.7533417,
    value: "9630",
  },
  {
    label: "Sint-Blasius-Boekel",
    lat: 50.8515679,
    lng: 3.7206404,
    value: "9630",
  },
  {
    label: "Sint-Denijs-Boekel",
    lat: 50.8660879,
    lng: 3.7130873,
    value: "9630",
  },
  {
    label: "Sint-Maria-Latem",
    lat: 50.890215,
    lng: 3.7059521,
    value: "9630",
  },
  {
    label: "Zwalm",
    lat: 50.8793307,
    lng: 3.719334,
    value: "9630",
  },
  {
    label: "Nederzwalm-Hermelgem",
    lat: 50.8867594,
    lng: 3.6879894099711,
    value: "9636",
  },
  {
    label: "Brakel",
    lat: 50.7979739,
    lng: 3.762778,
    value: "9660",
  },
  {
    label: "Elst",
    lat: 50.7685711,
    lng: 5.5556351,
    value: "9660",
  },
  {
    label: "Everbeek",
    lat: 50.7685909,
    lng: 3.7859967948024,
    value: "9660",
  },
  {
    label: "Michelbeke",
    lat: 50.834125,
    lng: 3.7638335,
    value: "9660",
  },
  {
    label: "Nederbrakel",
    lat: 50.8052186,
    lng: 3.7617767441185,
    value: "9660",
  },
  {
    label: "Opbrakel",
    lat: 50.7921614,
    lng: 3.7463234,
    value: "9660",
  },
  {
    label: "Sint-Maria-Oudenhove",
    lat: 50.82512665,
    lng: 3.7998568349562,
    value: "9660",
  },
  {
    label: "Zegelsem",
    lat: 50.8135755,
    lng: 3.7170799912553,
    value: "9660",
  },
  {
    label: "Parike",
    lat: 50.7849168,
    lng: 3.799316,
    value: "9661",
  },
  {
    label: "Horebeke",
    lat: 50.8348628,
    lng: 3.6920346,
    value: "9667",
  },
  {
    label: "Sint-Kornelis-Horebeke",
    lat: 50.8356339,
    lng: 3.6991826,
    value: "9667",
  },
  {
    label: "Sint-Maria-Horebeke",
    lat: 50.8383442,
    lng: 3.6875097,
    value: "9667",
  },
  {
    label: "Etikhove",
    lat: 50.8129246,
    lng: 3.625998,
    value: "9680",
  },
  {
    label: "Maarke-Kerkem",
    lat: 50.807217,
    lng: 3.6489314,
    value: "9680",
  },
  {
    label: "Maarkedal",
    lat: 50.7973072,
    lng: 3.642779,
    value: "9680",
  },
  {
    label: "Nukerke",
    lat: 50.7965606,
    lng: 3.5954667,
    value: "9681",
  },
  {
    label: "Schorisse",
    lat: 50.8061799,
    lng: 3.6782376,
    value: "9688",
  },
  {
    label: "Berchem",
    lat: 50.7903918,
    lng: 3.5082669,
    value: "9690",
  },
  {
    label: "Kluisbergen",
    lat: 50.7782986,
    lng: 3.5212498,
    value: "9690",
  },
  {
    label: "Kwaremont",
    lat: 50.7750908,
    lng: 3.5230939,
    value: "9690",
  },
  {
    label: "Ruien",
    lat: 50.7739701,
    lng: 3.4860074,
    value: "9690",
  },
  {
    label: "Zulzeke",
    lat: 50.7912081,
    lng: 3.569982,
    value: "9690",
  },
  {
    label: "Bevere",
    lat: 50.8513634,
    lng: 3.5955691,
    value: "9700",
  },
  {
    label: "Edelare",
    lat: 50.8316945,
    lng: 3.6281646,
    value: "9700",
  },
  {
    label: "Eine",
    lat: 50.8695429,
    lng: 3.6226347,
    value: "9700",
  },
  {
    label: "Ename",
    lat: 50.8553126,
    lng: 3.6317612,
    value: "9700",
  },
  {
    label: "Heurne",
    lat: 50.8878782,
    lng: 3.6405818,
    value: "9700",
  },
  {
    label: "Leupegem",
    lat: 50.8336184,
    lng: 3.6086057,
    value: "9700",
  },
  {
    label: "Mater",
    lat: 50.8428971,
    lng: 3.6636488,
    value: "9700",
  },
  {
    label: "Melden",
    lat: 50.8155547,
    lng: 3.5655354,
    value: "9700",
  },
  {
    label: "Mullem",
    lat: 50.8964155,
    lng: 3.6039655,
    value: "9700",
  },
  {
    label: "Nederename",
    lat: 50.8678376,
    lng: 3.6322868,
    value: "9700",
  },
  {
    label: "Ooike",
    lat: 50.8711812,
    lng: 3.5514004,
    value: "9700",
  },
  {
    label: "Oudenaarde",
    lat: 50.8442604,
    lng: 3.604782,
    value: "9700",
  },
  {
    label: "Volkegem",
    lat: 50.8408663,
    lng: 3.6356493,
    value: "9700",
  },
  {
    label: "Welden",
    lat: 50.8791033,
    lng: 3.6536587,
    value: "9700",
  },
  {
    label: "Huise",
    lat: 50.8988246,
    lng: 3.5907777,
    value: "9750",
  },
  {
    label: "Ouwegem",
    lat: 50.9121208,
    lng: 3.5978246,
    value: "9750",
  },
  {
    label: "Zingem",
    lat: 50.9039326,
    lng: 3.653793,
    value: "9750",
  },
  {
    label: "Kruishoutem",
    lat: 50.9200896,
    lng: 3.5404462768494,
    value: "9770",
  },
  {
    label: "Nokere",
    lat: 50.8853618,
    lng: 3.5108474,
    value: "9771",
  },
  {
    label: "Wannegem-Lede",
    lat: 50.8902386,
    lng: 3.5605794173077,
    value: "9772",
  },
  {
    label: "Elsegem",
    lat: 50.8247164,
    lng: 3.5366137,
    value: "9790",
  },
  {
    label: "Moregem",
    lat: 50.8503548,
    lng: 3.5623575,
    value: "9790",
  },
  {
    label: "Ooike",
    lat: 50.8711812,
    lng: 3.5514004,
    value: "9790",
  },
  {
    label: "Petegem-Aan-De-Schelde",
    lat: 50.8330877,
    lng: 3.5581136,
    value: "9790",
  },
  {
    label: "Wortegem",
    lat: 50.8533808,
    lng: 3.5101135,
    value: "9790",
  },
  {
    label: "Wortegem-Petegem",
    lat: 50.845989,
    lng: 3.5329831121506,
    value: "9790",
  },
  {
    label: "Astene",
    lat: 50.9845547,
    lng: 3.5628186,
    value: "9800",
  },
  {
    label: "Bachte-Maria-Leerne",
    lat: 51.0038237,
    lng: 3.5624453,
    value: "9800",
  },
  {
    label: "Deinze",
    lat: 50.9840526,
    lng: 3.5274017,
    value: "9800",
  },
  {
    label: "Gottem",
    lat: 50.9646883,
    lng: 3.4640122,
    value: "9800",
  },
  {
    label: "Grammene",
    lat: 50.9764412,
    lng: 3.468333,
    value: "9800",
  },
  {
    label: "Meigem",
    lat: 51.0175334,
    lng: 3.5413767,
    value: "9800",
  },
  {
    label: "Petegem-Aan-De-Leie",
    lat: 50.969075,
    lng: 3.5324987,
    value: "9800",
  },
  {
    label: "Sint-Martens-Leerne",
    lat: 51.0134905,
    lng: 3.5837978,
    value: "9800",
  },
  {
    label: "Vinkt",
    lat: 51.0078663,
    lng: 3.4808321,
    value: "9800",
  },
  {
    label: "Wontergem",
    lat: 50.9786839,
    lng: 3.4443004,
    value: "9800",
  },
  {
    label: "Zeveren",
    lat: 50.9967515,
    lng: 3.5031988,
    value: "9800",
  },
  {
    label: "Eke",
    lat: 50.9569377,
    lng: 3.6418029,
    value: "9810",
  },
  {
    label: "Nazareth",
    lat: 50.959802,
    lng: 3.5963218,
    value: "9810",
  },
  {
    label: "Bottelare",
    lat: 50.9635195,
    lng: 3.7552702,
    value: "9820",
  },
  {
    label: "Lemberge",
    lat: 50.9779856,
    lng: 3.7726469,
    value: "9820",
  },
  {
    label: "Melsen",
    lat: 50.9579283,
    lng: 3.6928827,
    value: "9820",
  },
  {
    label: "Merelbeke",
    lat: 50.9942924,
    lng: 3.7459119,
    value: "9820",
  },
  {
    label: "Munte",
    lat: 50.9452452,
    lng: 3.7445458,
    value: "9820",
  },
  {
    label: "Schelderode",
    lat: 50.9698809,
    lng: 3.7131906,
    value: "9820",
  },
  {
    label: "Sint-Martens-Latem",
    lat: 51.0134462,
    lng: 3.6306291238782,
    value: "9830",
  },
  {
    label: "Deurle",
    lat: 51.0000909,
    lng: 3.6111658724762,
    value: "9831",
  },
  {
    label: "De Pinte",
    lat: 50.9924205,
    lng: 3.6495958,
    value: "9840",
  },
  {
    label: "Zevergem",
    lat: 50.9786614,
    lng: 3.6943144,
    value: "9840",
  },
  {
    label: "Hansbeke",
    lat: 51.0747776,
    lng: 3.5345703,
    value: "9850",
  },
  {
    label: "Landegem",
    lat: 51.0546369,
    lng: 3.5888091437976,
    value: "9850",
  },
  {
    label: "Merendree",
    lat: 51.0829292,
    lng: 3.5715236988666,
    value: "9850",
  },
  {
    label: "Nevele",
    lat: 51.0330372,
    lng: 3.5491068,
    value: "9850",
  },
  {
    label: "Poesele",
    lat: 51.03238865,
    lng: 3.5117304815871,
    value: "9850",
  },
  {
    label: "Vosselare",
    lat: 51.0289033,
    lng: 3.5661276338188,
    value: "9850",
  },
  {
    label: "Balegem",
    lat: 50.9252028,
    lng: 3.7896135,
    value: "9860",
  },
  {
    label: "Gijzenzele",
    lat: 50.971061,
    lng: 3.8164475,
    value: "9860",
  },
  {
    label: "Landskouter",
    lat: 50.9694937,
    lng: 3.7928259,
    value: "9860",
  },
  {
    label: "Moortsele",
    lat: 50.9591122,
    lng: 3.7800022,
    value: "9860",
  },
  {
    label: "Oosterzele",
    lat: 50.9456388,
    lng: 3.8032713,
    value: "9860",
  },
  {
    label: "Scheldewindeke",
    lat: 50.9392571,
    lng: 3.78971,
    value: "9860",
  },
  {
    label: "Machelen",
    lat: 50.9591856,
    lng: 3.4858873,
    value: "9870",
  },
  {
    label: "Olsene",
    lat: 50.9357958,
    lng: 3.4651619,
    value: "9870",
  },
  {
    label: "Zulte",
    lat: 50.9206638,
    lng: 3.4485632,
    value: "9870",
  },
  {
    label: "Aalter",
    lat: 51.088195,
    lng: 3.4176803282422,
    value: "9880",
  },
  {
    label: "Lotenhulle",
    lat: 51.0497022,
    lng: 3.4596784,
    value: "9880",
  },
  {
    label: "Poeke",
    lat: 51.0414698,
    lng: 3.4455642,
    value: "9880",
  },
  {
    label: "Bellem",
    lat: 51.0902776,
    lng: 3.4994525656039,
    value: "9881",
  },
  {
    label: "Baaigem",
    lat: 50.9309503,
    lng: 3.721942,
    value: "9890",
  },
  {
    label: "Dikkelvenne",
    lat: 50.9176673,
    lng: 3.6894495,
    value: "9890",
  },
  {
    label: "Gavere",
    lat: 50.9292335,
    lng: 3.6614192,
    value: "9890",
  },
  {
    label: "Vurste",
    lat: 50.9459212,
    lng: 3.6845236,
    value: "9890",
  },
  {
    label: "Eeklo",
    lat: 51.1844827,
    lng: 3.5665965,
    value: "9900",
  },
  {
    label: "Knesselare",
    lat: 51.1392692,
    lng: 3.412339,
    value: "9910",
  },
  {
    label: "Ursel",
    lat: 51.1285879,
    lng: 3.4841052,
    value: "9910",
  },
  {
    label: "Lovendegem",
    lat: 51.0956807,
    lng: 3.6076129,
    value: "9920",
  },
  {
    label: "Vinderhoute",
    lat: 51.0876396,
    lng: 3.6412401,
    value: "9921",
  },
  {
    label: "Zomergem",
    lat: 51.1195778,
    lng: 3.5642436785112,
    value: "9930",
  },
  {
    label: "Oostwinkel",
    lat: 51.150867,
    lng: 3.5253176,
    value: "9931",
  },
  {
    label: "Ronsele",
    lat: 51.1303816,
    lng: 3.5532444,
    value: "9932",
  },
  {
    label: "Ertvelde",
    lat: 51.1793505,
    lng: 3.7471789,
    value: "9940",
  },
  {
    label: "Evergem",
    lat: 51.1087891,
    lng: 3.7078565,
    value: "9940",
  },
  {
    label: "Kluizen",
    lat: 51.1547125,
    lng: 3.732612,
    value: "9940",
  },
  {
    label: "Sleidinge",
    lat: 51.131929,
    lng: 3.6789045,
    value: "9940",
  },
  {
    label: "Waarschoot",
    lat: 51.1515304,
    lng: 3.6061712,
    value: "9950",
  },
  {
    label: "Assenede",
    lat: 51.23004155,
    lng: 3.7568076657628,
    value: "9960",
  },
  {
    label: "Boekhoute",
    lat: 51.2509311,
    lng: 3.7059614986083,
    value: "9961",
  },
  {
    label: "Bassevelde",
    lat: 51.2311917,
    lng: 3.6789952,
    value: "9968",
  },
  {
    label: "Oosteeklo",
    lat: 51.1929943,
    lng: 3.687828,
    value: "9968",
  },
  {
    label: "Kaprijke",
    lat: 51.228952,
    lng: 3.6219251372375,
    value: "9970",
  },
  {
    label: "Lembeke",
    lat: 51.19424,
    lng: 3.6346312134542,
    value: "9971",
  },
  {
    label: "Sint-Laureins",
    lat: 51.2330483,
    lng: 3.5374820679851,
    value: "9980",
  },
  {
    label: "Sint-Margriete",
    lat: 51.26767195,
    lng: 3.5315005565828,
    value: "9981",
  },
  {
    label: "Sint-Jan-In-Eremo",
    lat: 51.2682182,
    lng: 3.580094,
    value: "9982",
  },
  {
    label: "Waterland-Oudeman",
    lat: 51.2891181,
    lng: 3.5905485,
    value: "9988",
  },
  {
    label: "Watervliet",
    lat: 51.2776499,
    lng: 3.626982177111,
    value: "9988",
  },
  {
    label: "Maldegem",
    lat: 51.2263133,
    lng: 3.4154538,
    value: "9990",
  },
  {
    label: "Adegem",
    lat: 51.2035291,
    lng: 3.4858471,
    value: "9991",
  },
  {
    label: "Middelburg",
    lat: 51.25583235,
    lng: 3.4071425562584,
    value: "9992",
  },
];
