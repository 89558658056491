import { useContext } from "react";
import { PresenceChannel } from "pusher-js";
import OnlineContext from "../context/OnlineContext";
import { CHANNEL_PRESENCE_CLIENT } from "../channels";
import Presence from "./Presence";

export default function ClientsPresence({ children }) {
  const { setHumansChannel } = useContext(OnlineContext);

  const clientAdded = () => {}; //console.log('Added new client to CLIENTS:', user);
  const clientRemoved = () => {}; //console.log('Removed client from CLIENTS:', user);
  const subscribed = (channel: PresenceChannel) => setHumansChannel(channel);

  return (
    <Presence
      channel={CHANNEL_PRESENCE_CLIENT}
      memberAdded={clientAdded}
      memberRemoved={clientRemoved}
      subscribedToChannel={subscribed}
    >
      {children}
    </Presence>
  );
}
