/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { BloomUpNamespacesEnum } from "../../language/I18Namespaces";
import {
  I18NexusLanguages,
  DatabaseLanguageEquivalents,
  parseOneLanguageFromAPI,
  prepareOneLanguageForAPI,
} from "../../language/languagesUtil";
import InlineEditableFieldNewFormState from "../../ui/edit/InlineEditableFieldNewFormState";
import Bio from "../../../data-model/types/professional/Bio";
import Description from "../../../data-model/types/professional/Description";
import Professional from "../../../data-model/types/professional/Professional";
import { getTitleAndPlaceholderForDescriptionField } from "./util/profileHelpers";

interface DescriptionsMultipleLanguagesProps {
  //TODO; update this any
  onSave: (input: any) => Promise<void>;
  professional: Professional;
}

// This helps us type the content of the Formik values, but might change is the names of the
// languages ever change or more languages get added.
export interface IDescriptionsValues {
  en?: string;
  frBe?: string;
  nlBe?: string;
}

export default function EditableDescriptionsMultipleLanguages({
  professional,
  onSave,
}: DescriptionsMultipleLanguagesProps) {
  const { t: translate } = useTranslation(BloomUpNamespacesEnum.Validation);
  // Get all languages in which this professional offers therapy.
  const languages: Array<I18NexusLanguages> = useMemo(
    () => professional.getConsultationLanguages().getValue(),
    [professional],
  );

  // Convert the languages to DatabaseEquivalents.
  const consultationLanguages = useMemo(
    () => languages.map((languageKey) => prepareOneLanguageForAPI(languageKey)),
    [languages],
  );

  // Get the full bio of a professional
  const professionalBio: Bio = useMemo(
    () => professional.getBio(),
    [professional],
  );

  // get all descriptions from the bio and put them in a map with the language as key.
  const descriptionKeyValues: IDescriptionsValues = useMemo(() => {
    const keyValues: IDescriptionsValues = {
      en: undefined,
      frBe: undefined,
      nlBe: undefined,
    };

    languages.forEach((language) => {
      const lang = prepareOneLanguageForAPI(language);

      let description: Description | null =
        professionalBio.getDescriptionByLanguage(lang);

      if (description === null) description = new Description("");

      keyValues[lang] = description.getValue();
    });

    return keyValues;
  }, [professionalBio]);

  // Validation
  const formik = useFormik<IDescriptionsValues>({
    enableReinitialize: true,
    initialValues: {
      ...descriptionKeyValues,
    },
    onSubmit: (values: IDescriptionsValues) => onSave(values),
    validate: (descriptions: IDescriptionsValues) => {
      let validationObject: Record<string, any> = {};

      Object.entries(descriptions)
        .filter(([d]) =>
          // only validate those that are shown (so the languages a professional chose.)
          consultationLanguages.includes(d as DatabaseLanguageEquivalents),
        )
        .forEach(
          // Actual validation.
          ([descriptionLanguageKey, descriptionLanguageValue]) => {
            validationObject = {
              ...validationObject,
              ...Description.validate(
                descriptionLanguageValue,
                translate,
                descriptionLanguageKey.toString(),
              ),
            };
          },
        );

      return validationObject;
    },
    validateOnChange: true,
  });

  // Fields to display
  const fields = useMemo(() => {
    return Object.entries(descriptionKeyValues).map(([languageKey]) => {
      const l = parseOneLanguageFromAPI(languageKey);

      return {
        field: getTitleAndPlaceholderForDescriptionField(l),
        languageKey,
      };
    });
  }, [descriptionKeyValues]);

  return (
    <>
      {fields
        .filter((f) => f !== null)
        .filter((f) =>
          consultationLanguages.includes(
            f.languageKey as DatabaseLanguageEquivalents,
          ),
        )
        .map((field) => {
          return (
            <Box key={field.field.label} mt={3}>
              <InlineEditableFieldNewFormState
                errorText={formik.errors[field.languageKey]}
                hasError={formik.errors[field.languageKey] !== undefined}
                id={field.languageKey}
                label={field.field.label}
                multiline
                name={field.languageKey}
                onChange={formik.handleChange}
                onDiscard={formik.resetForm}
                onSave={formik.handleSubmit}
                placeholder={field.field.placeholder}
                value={formik.values[field.languageKey]}
              />
            </Box>
          );
        })}
    </>
  );
}
