import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  LocalizationProvider,
  TimePicker as MuiTimePicker,
} from "@mui/x-date-pickers";

import moment from "moment";

import "moment/locale/nl-be";
import "moment/locale/fr";
import "moment/locale/en-gb";

import { useTranslation } from "react-i18next";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getMomentLocale } from "../../language/languagesUtil";

const PREFIX = "TimePicker";

const classes = {
  textField: `${PREFIX}-textField`,
};

const Root = styled("span")(({ theme }) => ({
  [`& .${classes.textField}`]: {
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,

      cursor: "pointer",
      ...theme.controls.border?.normal,
      ...theme.controls.input,

      "& .MuiIconButton-root": {
        marginRight: "-4px",
      },
      "& INPUT": {
        padding: 0,
      },

      "&:before": {
        border: 0,
      },

      "&:hover": {
        ...theme.controls.border?.hover,

        "&:before": { border: 0 },
      },

      marginTop: 5,

      paddingRight: 5,
    },

    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
}));

export const CustomTimePicker = ({
  name,
  onChange,
  onClose,
  value,
  hasError,
  ...args
}) => {
  const { i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const momentLocale = getMomentLocale(i18n.language);

  moment.locale(momentLocale);

  return (
    <Root>
      <LocalizationProvider
        adapterLocale={momentLocale}
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <MuiTimePicker
          ampm={false}
          className={classes.textField}
          minutesStep={5}
          onChange={onChange}
          onClose={() => {
            setOpen(false);

            if (onClose) onClose();
          }}
          onOpen={() => setOpen(true)}
          open={open}
          slotProps={{
            textField: {
              error: hasError,
              fullWidth: true,
              name,
            },
          }}
          value={value}
          {...args}
        />
      </LocalizationProvider>
    </Root>
  );
};
