import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import useFormState from "../../../hooks/useFormState";
import createFormValidation from "../../../../utils/forms/createFormValidation";
import FirstNameDataType from "../../../../data-model/types/profile/FirstName";
import LastNameDataType from "../../../../data-model/types/profile/LastName";
import EmailDataType from "../../../../data-model/types/profile/Email";
import PhoneDataType from "../../../../data-model/types/profile/Phone";
import PreferredLanguage from "../../../../data-model/types/profile/PreferredLanguage";
import { I18Namespaces } from "../../../language/I18Namespaces";
import Timezone from "../../../../data-model/types/profile/Timezone";
import BirthDate from "../../../../data-model/types/profile/BirthDate";

const INITIAL_VALUES = {
  //...AvatarDataType.getInitialValue(),
  ...FirstNameDataType.getInitialValue(),
  ...LastNameDataType.getInitialValue(),
  ...EmailDataType.getInitialValue(),
  ...PhoneDataType.getInitialValue(),
  ...BirthDate.getInitialValue(),
  ...PreferredLanguage.getInitialValue(),
  ...Timezone.getInitialValue(),
};

export default function useHumanProfileFormState(
  initialValues?: any,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(["validation"]);

  const validation = useMemo(
    () =>
      createFormValidation([
        //...AvatarDataType.getFormValidation(translate),
        ...FirstNameDataType.getFormValidation(translate),
        ...LastNameDataType.getFormValidation(translate),
        ...EmailDataType.getFormValidation(translate),
        ...PhoneDataType.getFormValidation(translate),
        ...PreferredLanguage.getFormValidation(translate),
        ...Timezone.getFormValidation(translate),
        ...BirthDate.getFormValidation(translate),
      ]),
    [translate],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
