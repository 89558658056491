import React from "react";
import { Button, Typography } from "@mui/material";
import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import { RouteNames } from "../routes/routeNames";
import SanitizedText from "./text/SanitizedText";

interface IErrorPage {
  bodyText?: string | null;
  header?: string | null;
  redirectPath?: string;
  showRedirectButton?: boolean;
}

export default function ErrorPage({
  header: headerInput,
  bodyText: bodyTextInput,
  showRedirectButton = false,
  redirectPath: redirectPathInput,
}: IErrorPage) {
  const header = headerInput ?? "Er ging iets mis!";

  const redirectPath = redirectPathInput ?? RouteNames.Login.path;

  return (
    <OnboardingContainer>
      <h1>{header}</h1>
      <div>
        <Typography>
          {bodyTextInput ? (
            <SanitizedText input={bodyTextInput} />
          ) : (
            <>
              Oeps! Hier ging iets fout. We hebben de fout opgemerkt en werken
              er aan! <br />
              Contacteer <a href="mailto: support@bloomup.org">support</a> voor
              verdere hulp.
            </>
          )}
        </Typography>
        {showRedirectButton && redirectPath && (
          <Button
            onClick={(e) => {
              e.preventDefault();
              window.location.replace(redirectPath);
            }}
          />
        )}
      </div>
    </OnboardingContainer>
  );
}
