import { createContext } from "react";
import { IProfessionalOnboardingContext } from "./professionalOnboarding";

const defaultProfessionalOnboardingValues: IProfessionalOnboardingContext = {
  disable: false,
  error: undefined,
  formState: {},
  next: () => {},
  previous: () => {},
  setStep: () => {},
  specialties: undefined,
  step: 0,
  totalSteps: 0,
};

const ProfessionalOnboardingContext =
  createContext<IProfessionalOnboardingContext>(
    defaultProfessionalOnboardingValues,
  );

export default ProfessionalOnboardingContext;
