/**
 * First name of user, psy or client.
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class FirstName extends DataType<
  string,
  DataTypePaths.Profile.FirstName
> {
  protected static path = "firstName";
  protected static defaultValue = "";

  protected type: any = FirstName;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "firstname.mandatory",
          "Gelieve je voornaam op te geven.",
        ),
        path: this.getPath(),
        validate: (firstName: string): boolean =>
          !!firstName && firstName.trim() !== "",
      },
    ];
  }

  public getAsFormStateValue(): Partial<
    Record<DataTypePaths.Profile.FirstName, string>
  > {
    return {
      firstName: this.value,
    };
  }
}
