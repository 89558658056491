import moment from "moment";
import "moment-timezone";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import Human from "../../../../../data-model/types/human/Human";
import { Timezones } from "../../../../../data-model/types/profile/Timezone";
import Professional from "../../../../../data-model/types/professional/Professional";
import InfoItemSidePane from "./InfoItemSidePane";

interface IInfoItemSidePaneTimeZone {
  human: Human;
  professional: Professional;
}

const getTimeDiffText = (human: Human, timeDifferenceInHours: number) => {
  const tzValue = human.getTimezone().getValue();

  return `${tzValue.replace("_", " ")} (${timeDifferenceInHours} hrs)`;
};

const InfoItemSidePaneTimeZone = ({
  human,
  professional,
}: IInfoItemSidePaneTimeZone) => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const timeDifferenceInHours = useMemo(() => {
    // get the current time so we know which offset to take (DST is such bullkitten)
    const now = moment.utc();

    // get the zone offsets for this time, in minutes
    const human_tz_offset = now
      .tz(human.getTimezone().getValue() || Timezones.DEFAULT)
      .utcOffset();

    const professional_tz_offset = now
      .tz(professional.getTimezone().getValue() || Timezones.DEFAULT)
      .utcOffset();

    const sign = human_tz_offset < professional_tz_offset ? -1 : 1;

    // calculate the difference in hours
    return ((professional_tz_offset - human_tz_offset) / 60) * sign;
  }, [professional, human]);

  if (timeDifferenceInHours === 0) return null;

  return (
    <InfoItemSidePane
      subTitle={translate(
        "professional:call.timezone.client",
        "Tijdszone cliënt",
      )}
    >
      {getTimeDiffText(human, timeDifferenceInHours)}
      <br />
      {`Nu is het daar ${moment()
        .tz(human.getTimezone().getValue())
        .format("HH:mm")}`}
    </InfoItemSidePane>
  );
};

export default InfoItemSidePaneTimeZone;
