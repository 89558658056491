import React from "react";
import { colors } from "../providers/theme/ThemeProvider";

const BloomUpLogo = ({
  width = "200px",
  height = "59px",
  fill = colors.bloom.main,
}) => (
  <svg
    fill={fill}
    height={height}
    viewBox="0 0 200 59"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.4586 9.22905C10.5746 9.22905 8.07486 10.3346 6.15221 12.5459V0H0V33.8394H6.15221V31.291C8.07486 33.5022 10.5746 34.6078 13.4586 34.6078C20.0436 34.6078 25.3788 28.9356 25.3788 21.9177C25.3788 14.8999 20.0436 9.22905 13.4586 9.22905ZM12.6887 28.4556C9.08331 28.4556 6.15221 25.5231 6.15221 21.9177C6.15221 18.3124 9.08478 15.3813 12.6887 15.3813C16.294 15.3813 19.2251 18.3138 19.2251 21.9177C19.2251 25.5216 16.294 28.4556 12.6887 28.4556Z" />
    <path d="M35.7576 0H29.6054V33.8393H35.7576V0Z" />
    <path d="M52.293 9.22905C45.2752 9.22905 39.6029 14.9013 39.6029 21.9192C39.6029 28.937 45.2752 34.6093 52.293 34.6093C59.3109 34.6093 64.9832 28.937 64.9832 21.9192C64.9832 14.9013 59.3109 9.22905 52.293 9.22905ZM52.293 28.4556C48.6877 28.4556 45.7566 25.5231 45.7566 21.9177C45.7566 18.3124 48.6892 15.3813 52.293 15.3813C55.8984 15.3813 58.8295 18.3138 58.8295 21.9177C58.8295 25.5216 55.8984 28.4556 52.293 28.4556Z" />
    <path d="M80.7457 9.22905C73.7279 9.22905 68.0556 14.9013 68.0556 21.9192C68.0556 28.937 73.7279 34.6093 80.7457 34.6093C87.7636 34.6093 93.4359 28.937 93.4359 21.9192C93.4359 14.9013 87.7636 9.22905 80.7457 9.22905ZM80.7457 28.4556C77.1404 28.4556 74.2078 25.5231 74.2078 21.9177C74.2078 18.3124 77.1404 15.3813 80.7457 15.3813C84.3511 15.3813 87.2822 18.3138 87.2822 21.9177C87.2822 25.5216 84.3511 28.4556 80.7457 28.4556Z" />
    <path d="M132.656 33.8379V19.9951C132.656 12.7858 128.906 9.22758 123.427 9.22758C120.159 9.22758 118.043 10.4775 116.265 12.4487C114.822 10.2861 112.612 9.22758 109.583 9.22758C107.131 9.22758 104.92 10.4289 103.43 12.3044V9.99753H97.2782V33.8379H103.43V21.5335C103.43 17.2082 105.257 15.3813 108.046 15.3813C110.785 15.3813 111.891 17.064 111.891 20.765V33.8394H118.043V21.5349C118.043 17.2097 119.87 15.3827 122.657 15.3827C125.59 15.3827 126.502 17.0654 126.502 20.7665V33.8408H132.656V33.8379Z" />
    <path d="M145.823 34.6078C149.428 34.6078 151.398 33.4537 153.032 31.531V33.8379H159.184V9.99752H153.032V22.3019C153.032 26.6272 150.437 28.4542 147.649 28.4542C144.476 28.4542 143.035 26.7715 143.035 23.0704V9.99605H136.883V23.8389C136.883 31.0511 140.344 34.6078 145.823 34.6078Z" />
    <path d="M171.103 31.291C173.026 33.5022 175.526 34.6078 178.41 34.6078C184.995 34.6078 190.33 28.9356 190.33 21.9177C190.33 14.8999 184.993 9.22758 178.41 9.22758C175.526 9.22758 173.026 10.3332 171.103 12.5444V9.99753H164.951V46.1438H171.103V31.291ZM177.64 15.3813C181.245 15.3813 184.178 18.3138 184.178 21.9177C184.178 25.5216 181.245 28.4556 177.64 28.4556C174.034 28.4556 171.103 25.5231 171.103 21.9177C171.103 18.3124 174.034 15.3813 177.64 15.3813Z" />
    <path d="M199.376 46.3087C198.543 45.4018 197.158 45.2914 196.183 46.0452C192.366 49.0028 187.5 51.1831 181.657 52.5448C173.594 54.4263 165.706 54.2864 160.508 53.8374C152.555 53.1513 144.894 51.292 138.355 48.4625C136.155 47.5114 134.062 46.4367 132.13 45.2693C131.191 44.701 129.962 44.807 129.183 45.5799C128.608 46.1511 128.365 46.9653 128.54 47.7705C128.681 48.4168 129.105 48.9659 129.67 49.3075C131.777 50.5794 134.068 51.7543 136.478 52.7966C143.479 55.8263 151.647 57.8137 160.1 58.5425C162.417 58.7427 164.77 58.8443 167.09 58.8443C172.631 58.8443 177.893 58.2716 182.729 57.1439C189.252 55.6217 194.733 53.1469 199.094 49.7639C200.183 48.9218 200.307 47.323 199.376 46.3087Z" />
  </svg>
);

export default BloomUpLogo;
