import React from "react";
import Box from "@mui/material/Box";
import { formatISO } from "date-fns";
import ViewOnlyProfileInfo, {
  viewOnlyProfileInfoFragment,
} from "../../profile/ViewOnlyProfileInfo";
import Professional from "../../../data-model/types/professional/Professional";
import ViewOnlyProfessionalProfile, {
  viewOnlyProfessionalProfileFragment,
} from "../professional-profile/ViewOnlyProfessionalProfile";
import { Language } from "../../../api/__generated__/graphql";
import { makeFragmentData } from "../../../api/__generated__";
import { UseSelectTranslationStringInputFragment } from "../../../api/languages/fragments";

interface IProfessionalSummary {
  professional: Professional;
}

export default function ProfessionalSummary({
  professional,
}: IProfessionalSummary) {
  const birthDate = professional.getBirthDate().getValue();
  const address = professional.getPractice().getAddress();

  return (
    <Box>
      <ViewOnlyProfileInfo
        user={makeFragmentData(
          {
            birthDate: birthDate && formatISO(birthDate),
            email: professional.getEmail().getValue(),
            firstName: professional.getFirstName().getValue(),
            id: professional.getUUID(),
            lastName: professional.getLastName().getValue(),
            phone: professional.getPhone().getValue(),
            preferredLanguage: professional
              .getPreferredLanguage()
              .getValue() as Language,
            professional: {
              avatar: { url: professional.getAvatar().getUrl() },
              gender: professional.getGender().getValue(),
            },
            timezone: professional.getTimezone().getValue(),
          },
          viewOnlyProfileInfoFragment,
        )}
      />
      <ViewOnlyProfessionalProfile
        professional={makeFragmentData(
          {
            consultationLanguages: professional
              .getConsultationLanguages()
              .getValue(),
            experienceSince: professional
              .getExperience()
              .getValue() as unknown as string,
            id: professional.getID(),
            practice: {
              address: {
                id: 0,
                postcode: address.getPostcode(),
                street: address.getStreet(),
              },
              id: 0,
            },
            profileDescription: makeFragmentData(
              {
                en: professional
                  .getBio()
                  .getDescriptionByLanguage("en")
                  ?.getValue(),
                fr: professional
                  .getBio()
                  .getDescriptionByLanguage("frBe")
                  ?.getValue(),
                nl: professional
                  .getBio()
                  .getDescriptionByLanguage("nlBe")
                  ?.getValue(),
              },
              UseSelectTranslationStringInputFragment,
            ),
            specialties: professional
              .getSpecialties()
              .getValue()
              .map((specialty) => ({
                id: specialty.getId() as string,
                secondaryColor: specialty.getSecondaryColor() as string,
                subcategories: specialty
                  .getSubcategories()
                  .map((subCategory) => ({
                    id: subCategory.getId(),
                    tooltip: subCategory.getTooltip(),
                    translationKey: subCategory.translationKey,
                  })),
              })),
            standardDuration: professional.getConsultationDuration().getValue(),
            standardRate: professional.getConsultationRate().getValue(),
            therapyTypes: JSON.stringify(
              professional.getTherapies().getValue(),
            ),
            website: professional.getWebsite().getValue(),
          },
          viewOnlyProfessionalProfileFragment,
        )}
      />
    </Box>
  );
}
