/**
 * Standard rate of a psy. Can be anywhere below 70 EUR / consult.
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class ConsultationRate extends DataType<
  number,
  DataTypePaths.Consultation.StandardRate
> {
  protected static path = "standardRate";
  protected static default = 0;

  protected type: any = ConsultationRate;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:rate.mandatory",
          "Geef een tarief op dat je jouw klanten gaat aanrekenen.",
        ),
        path: this.getPath(),
        validate: (rate: number): boolean => rate > 0,
      },
      {
        message: translate(
          "validation:rate.notnatural",
          "Je tarief moet een geheel getal zijn.",
        ),
        path: this.getPath(),
        validate: (rate: number): boolean => rate - Math.floor(rate) === 0,
      },
    ];
  }

  getAsFormStateValue(): Record<"standardRate", number> {
    return { standardRate: this.value };
  }
}
