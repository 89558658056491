import { Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import RangeSlider from "../../ui/form/RangeSlider";
import { QualityQuestions } from "../QualityQuestions.types";
import { SharedAnswerWithOptionsProps } from "./answers";

export default function AnswerSlider({
  options,
  onAnswerChanged,
}: SharedAnswerWithOptionsProps<QualityQuestions.Options.Slider>) {
  const [selectedRange, setSelectedRange] = useState<number>(0);

  const onChange = useCallback(
    (value) => {
      setSelectedRange(value);
      onAnswerChanged([value]);
    },
    [onAnswerChanged],
  );

  return (
    <Box width="100%">
      <Typography color="primary" variant="h2">
        {selectedRange}
      </Typography>
      <RangeSlider
        max={Number(options.max.value)}
        min={Number(options.min.value)}
        onChange={onChange}
        value={selectedRange}
      />
    </Box>
  );
}
