import { BloomUpNamespacesEnum } from "../../../components/language/I18Namespaces";
import DataType from "../../DataType";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";

export interface SpecialtyModel {
  id: string;
  name: string;
  tooltip?: string;
  translationKey: string;
}

export default class Specialty extends DataType<
  SpecialtyModel,
  DataTypePaths.Professional.OneSpecialty
> {
  protected static path = "specialty";
  protected static defaultValue = [];

  protected type: any = Specialty;

  protected id: string;
  protected name: string;
  public translationKey: string;
  protected tooltip?: string;

  constructor(model: SpecialtyModel) {
    super(model);
    this.id = model.id;
    this.name = model.name;
    this.translationKey = model.translationKey;
    this.tooltip = model.tooltip;
  }

  public getId = (): string => this.id;
  public getName = (): string => this.name;
  public getTranslationKey = (): string =>
    `${BloomUpNamespacesEnum.Glossary}:${this.translationKey}`;

  public getTooltip = (): string =>
    this.tooltip ? `${BloomUpNamespacesEnum.Glossary}:${this.tooltip}` : "";

  getAsFormStateValue(): Partial<
    Record<PossibleFormStatePaths, SpecialtyModel>
  > {
    return { specialty: this.value };
  }
}
