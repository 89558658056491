import { useMutation, useQuery } from "@apollo/client";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { add, format } from "date-fns";
import { Formik } from "formik";
import { parse, toSeconds } from "iso8601-duration";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { graphql } from "../../api/__generated__";
import { getDateFnsLocale } from "../language/languagesUtil";
import Button from "../ui/buttons/Button";
import { CustomDateTimePicker } from "../ui/form/CustomDateTimePicker";
import CustomTextField from "../ui/form/CustomTextField";
import Label from "../ui/text/Label";
import SanitizedText from "../ui/text/SanitizedText";
import ValueWithLabel from "../ui/text/ValueWithLabel";
import ToastContext from "../providers/toast/ToastContext";
import { IToastContext } from "../providers/toast/toast";
import { useContext } from "react";

const rescheduleConsulationDetailQuery = graphql(`
  query RescheduleConsultationDetail($uuid: String!) {
    consultation(uuid: $uuid) {
      cashTransaction {
        amount
        id
        status
      }
      creditTransactions {
        id
      }
      duration
      human {
        canPayWithCredits
        creditsLeft
        id
      }
      id
      scheduledFrom
      scheduledTo
      type
    }
  }
`);

const professionalRescheduleConsulationMutation = graphql(`
  mutation ProfessionalRescheduleConsultation(
    $input: RescheduleConsultationInput!
  ) {
    rescheduleConsultation(input: $input) {
      acceptedByProfessional
      cashTransaction {
        amount
        id
      }
      duration
      id
      message {
        body
        id
      }
      scheduledFrom
      scheduledTo
      status
    }
  }
`);

const ProfessionalRescheduleConsultation = ({
  onReschedule,
  consultationUuid,
  cancelReschedule,
}: {
  cancelReschedule: () => void;
  consultationUuid: string;
  onReschedule: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const dateFnsLocale = getDateFnsLocale(i18n.language);
  const { setToast } = useContext<IToastContext>(ToastContext);

  const { data } = useQuery(rescheduleConsulationDetailQuery, {
    variables: {
      uuid: consultationUuid,
    },
  });

  const [updateConsulation, { loading: updating }] = useMutation(
    professionalRescheduleConsulationMutation,
    {
      onError(error) {
        if (error.message === "OVERLAPPING_EVENTS") {
          setToast({
            message: t(
              "common:professional.reschedule.consultation.validtation.overlapping.events",
            ),
            severity: "error",
          });

          return;
        }

        setToast({
          message: t("errors:error.generic"),
          severity: "error",
        });
      },
    },
  );

  const smallScreen = useMediaQuery("(max-width: 500px)");

  if (!data?.consultation) return null;

  return (
    <Formik
      initialValues={{
        duration: toSeconds(parse(data.consultation.duration)) / 60,
        message: "",
        price: data.consultation.cashTransaction?.amount,
        scheduleFrom: new Date(data.consultation.scheduledFrom),
      }}
      onSubmit={(values) => {
        data.consultation &&
          updateConsulation({
            variables: {
              input: {
                id: data.consultation.id,
                message: values.message,
                price: values.price,
                scheduledFrom: values.scheduleFrom,
                scheduledTo: add(values.scheduleFrom, {
                  minutes: values.duration,
                }),
              },
            },
          });

        onReschedule();
      }}
      validationSchema={Yup.object({
        duration: Yup.number().min(
          1,
          t("common:professional.reschedule.consultation.validation.duration"),
        ),
        message: Yup.string().required(
          t("common:professional.reschedule.consultation.validation.message"),
        ),
        price:
          data.consultation.type === "INTRO" ||
          data.consultation.creditTransactions.length > 0
            ? Yup.number()
            : Yup.number().required(
                t(
                  "common:professional.reschedule.consultation.validation.price",
                ),
              ),
        scheduleFrom: Yup.date().min(
          new Date(),
          t(
            "common:professional.reschedule.consultation.validation.schedule_from",
          ),
        ),
      }).required()}
    >
      {(props) => {
        if (!data.consultation) return null;

        return (
          <form onSubmit={props.handleSubmit}>
            <Box mt={1}>
              <Box
                alignItems={"justify"}
                display="flex"
                flexWrap={undefined}
                mt={3}
                width="100%"
              >
                <Box mr={1} pr={1} width={"75%"}>
                  <Label value={t("common:from")} />
                  <CustomDateTimePicker
                    error={props.errors.scheduleFrom as string}
                    fullWidth
                    onChange={(value) => {
                      if (value) {
                        props.setFieldValue("scheduleFrom", value);
                      }
                    }}
                    value={props.values.scheduleFrom}
                  />
                </Box>
                <Box mr={1} width={"25%"}>
                  <Label value={t("common:duration")} />
                  <CustomTextField
                    error={props.errors.duration}
                    onChange={(event) => {
                      props.setFieldValue(
                        "duration",
                        Number(event.target.value),
                      );
                    }}
                    value={props.values.duration}
                  />
                </Box>
              </Box>
              <Box alignItems={"center"} mt={3}>
                <Label value={t("common:consultation.time", "Tijd")} />
                <Typography>
                  {`${format(props.values.scheduleFrom, "p", {
                    locale: dateFnsLocale,
                  })} →
                    ${format(
                      add(props.values.scheduleFrom, {
                        minutes: props.values.duration,
                      }),
                      "p",
                      {
                        locale: dateFnsLocale,
                      },
                    )}`}
                </Typography>
              </Box>
              <Box display={"flex"} flexWrap={"nowrap"} py={2}>
                {data.consultation.cashTransaction && (
                  <Box mr={2}>
                    <Label
                      tooltip={t("professional:consultation.pricing.tooltip")}
                      value={t("common:price.label")}
                    />
                    <Box>
                      <CustomTextField
                        data-testid="price-input"
                        error={props.errors.price}
                        onChange={(event) => {
                          props.setFieldValue(
                            "price",
                            Number(event.target.value),
                          );
                        }}
                        value={props.values.price}
                      />
                    </Box>
                  </Box>
                )}
                {data.consultation.type !== "INTRO" && (
                  <Box mt={2}>
                    <SanitizedText
                      input={t("professional:consultation.pricing.info", {
                        clientPrice: data.consultation.cashTransaction?.amount,
                        context: !data.consultation.human?.canPayWithCredits
                          ? ""
                          : data.consultation.human.creditsLeft !== null
                          ? "credits"
                          : "unlimited_credits",
                        credits: data.consultation.human?.creditsLeft,
                        professionalNetto:
                          (data.consultation.cashTransaction?.amount
                            ? data.consultation.cashTransaction.amount
                            : 0) - 6,
                        professionalNettoCredits: 60,
                        serviceCost: 6,
                      })}
                    />
                  </Box>
                )}
              </Box>
              {data.consultation.cashTransaction?.status && (
                <Box>
                  <ValueWithLabel
                    label={t("payments:payment.status.text", {
                      context: "professional",
                      defaultValue: "Status van je betaling",
                    })}
                  >
                    {data.consultation.cashTransaction.status
                      ? t(
                          `payments:payment.status.${data.consultation.cashTransaction.status}`,
                        )
                      : t("payments:payment.none")}
                  </ValueWithLabel>
                </Box>
              )}
              <Box>
                <Label value={t("common:consultation.cancel.comment")} />
                <CustomTextField
                  data-testid={"message-input"}
                  error={props.errors.message}
                  onChange={(event) =>
                    props.setFieldValue("message", event.target.value)
                  }
                  value={props.values.message}
                />
              </Box>
            </Box>
            <Box display={"flex"} gap={3} marginBottom={"2rem"} marginTop={6}>
              <Button
                bloomColor="green"
                icon={<CheckCircleIcon />}
                label={t("common:confirm")}
                loading={updating}
                onClick={() => {
                  props.submitForm();
                }}
                size={smallScreen ? "medium" : "large"}
              />
              <Button
                bloomColor="red"
                icon={<CancelIcon />}
                label={t("common:cancel_adjustment")}
                onClick={() => cancelReschedule()}
                size={smallScreen ? "medium" : "large"}
              />
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfessionalRescheduleConsultation;
