import React from "react";

const HumanSorrySitting = () => (
  <svg
    height="284px"
    version="1.1"
    viewBox="0 0 221 284"
    width="221px"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M130.516484,308.035573 C176.294955,308.035573 215.314654,277.441833 226.689225,235.300653 C227.613255,231.877256 231.927193,205.449632 229.735086,197.611975 C224.797489,179.958102 278,218.599116 278,212.637696 C278,191.908418 291.677811,137.152386 261.334635,94.9289697 C210.995046,24.8799609 175.420283,-4.54747351e-13 141,-4.54747351e-13 C85.8322157,-4.54747351e-13 -33.5487629,193.401107 10.1578111,228.25221 C16.4591943,233.276858 31.0828388,238.341833 34.8736755,244.5 C46.7667373,263.820131 55.9673611,274.959493 60.9603635,279.863984 C78.8526927,297.439134 103.476261,308.035573 130.516484,308.035573 Z"
        id="path-1"
      />
    </defs>
    <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
      <g
        id="userflow-desktop-no-matching"
        transform="translate(-604.000000, -256.000000)"
      >
        <g id="Human" transform="translate(574.000000, 231.000000)">
          <ellipse
            cx="130.516484"
            cy="208.233202"
            fill="#AADDC7"
            id="Background"
            rx="99.8901099"
            ry="99.8023715"
          />
          <mask fill="white" id="mask-2">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="Mask" />
          <g id="A-Human/Sitting" mask="url(#mask-2)">
            <g transform="translate(-3.000000, 4.000000)">
              <g
                id="Head/Front/Short-2"
                transform="translate(74.893333, 0.000000)"
              >
                <g
                  fill="#B28B67"
                  id="Head"
                  strokeWidth="1"
                  transform="translate(49.320000, 28.189802)"
                >
                  <path d="M34.8375851,8.30592767 C40.1301789,18.1279109 39.5012812,43.042186 32.6610753,44.7976484 C29.933158,45.4977379 24.1218238,43.7827525 18.2072978,40.2332855 L21.92,66.3824363 L0,66.3824363 L7.54526786,31.3624418 C3.33722761,26.4442041 0.489248636,20.4169847 0.965189052,13.6633731 C2.33660827,-5.79711503 29.5449913,-1.51605559 34.8375851,8.30592767 Z" />
                </g>
                <path
                  d="M55.1818997,63.9340338 C52.4307239,51.7477996 44.5930732,43.4408521 45.7900705,37.9941265 C46.5880687,34.3629762 48.714973,32.1048912 52.1707836,31.2198717 C53.9580961,24.0208945 59.7225457,20.958889 69.4641323,22.033855 C84.0765122,23.646304 92.9950955,29.6881885 89.1436001,42.9829711 C85.6031702,42.9829711 81.3291146,41.7081635 75.418984,43.8923195 C73.5437234,44.5853434 72.2024821,46.7344714 71.39526,50.3397033 L68.7244954,50.3397033 C66.1324058,46.0612786 63.5765888,44.4890743 61.0570445,45.6230904 C58.5375002,46.7571064 57.9647669,49.5116359 59.3388444,53.8866789 L58.0232228,63.9340338 L55.1818997,63.9340338 Z"
                  fill="#191847"
                  id="Hair"
                />
              </g>
              <g
                id="Bottom/Sitting/Baggy-Pants"
                transform="translate(0.000000, 170.048159)"
              >
                <g
                  fill="#C5CFD6"
                  id="Objects/Seat/Cube-2"
                  transform="translate(9.133333, 38.192635)"
                >
                  <path
                    d="M155.933333,0 C165.653545,-1.78557394e-15 173.533333,7.87978857 173.533333,17.6000004 L173.533333,95.1592064 C173.533333,104.879418 165.653545,112.759207 155.933333,112.759207 L56.8733337,112.759207 C47.1531219,112.759207 39.2733333,104.879418 39.2733333,95.1592064 L39.2733333,17.6000004 C39.2733333,7.87978857 47.1531219,1.2443715e-14 56.8733337,0 L155.933333,0 Z M143.56,25.4617564 L69.2466668,25.4617564 C66.8166138,25.4617564 64.8466667,27.4317035 64.8466667,29.8617565 L64.8466667,29.8617565 L64.8466667,82.8974503 C64.8466667,85.3275033 66.8166138,87.2974504 69.2466668,87.2974504 L69.2466668,87.2974504 L143.56,87.2974504 C145.990053,87.2974504 147.96,85.3275033 147.96,82.8974503 L147.96,82.8974503 L147.96,29.8617565 C147.96,27.4317035 145.990053,25.4617564 143.56,25.4617564 L143.56,25.4617564 Z"
                    id="Seat"
                  />
                </g>
                <path
                  d="M273.231854,146.405099 C255.224693,124.511785 243.040619,108.281061 236.679629,97.7129269 C225.788071,79.6177157 216.873851,63.5163197 214.060745,57.3656589 C207.352534,42.6986194 229.504131,35.8307094 234.517541,42.6719031 C242.105536,53.0263215 258.3108,85.6087607 283.133333,140.419221 L273.231854,146.405099 Z"
                  fill="#B28B67"
                  id="LegLower"
                />
                <path
                  d="M236.331911,140.03966 L202.819547,56.049307 C195.651919,40.7384257 230.265725,30.6742112 235.53339,37.7995885 C247.492621,53.976401 275.916946,117.79158 279.48,122.611193 L236.331911,140.03966 Z"
                  fill="#2F3676"
                  id="LegLower"
                />
                <g
                  fill="#B28B67"
                  id="Lower-Leg"
                  strokeWidth="1"
                  transform="translate(185.498981, 95.933396) rotate(39.000000) translate(-185.498981, -95.933396) translate(170.968678, 45.505892)"
                >
                  <path
                    d="M1.90264607,95.6887008 C0.983251467,84.2303509 -0.724869754,44.5639304 0.984174958,26.2038137 C1.47528587,20.9278526 1.99270418,15.8268445 2.51413741,11.0416464 C4.06402147,-3.18165554 30.552603,-2.73204793 28.4490776,10.7808017 C23.2104678,44.4331379 13.9899174,91.5949386 13.3290459,95.6699837 C12.4028063,101.381333 2.48045692,102.889916 1.90264607,95.6887008 Z"
                    id="LegLower"
                  />
                </g>
                <g
                  fill="#E4E4E4"
                  id="Accessories/Shoe/Flat-Sneaker"
                  transform="translate(173.076667, 127.308782) rotate(30.000000) translate(-173.076667, -127.308782) translate(145.220000, 109.121813)"
                >
                  <path
                    d="M2.30068428,23.4902721 C1.37578365,26.0108336 0.913333333,27.9124901 0.913333333,29.1952417 C0.913333333,30.8350572 1.18771164,33.2279559 1.73646825,36.3739377 C3.64710547,36.3739377 20.8183238,36.3739377 53.2501234,36.3739377 C54.5887807,31.9676964 53.8160301,29.5889085 50.9318715,29.237574 C48.047713,28.8862395 45.6517935,28.5690126 43.7441131,28.2858933 L19.9739294,17.7352612 C19.4186564,17.4887978 18.7687206,17.7391374 18.5222571,18.2944104 C18.5185119,18.3028482 18.5148731,18.3113328 18.5113415,18.3198622 L17.0457346,21.8595499 L17.0457346,21.8595499 C14.3757582,23.0998622 12.2061251,23.7200183 10.5368354,23.7200183 C9.24566667,23.7200183 7.48616112,23.2257487 5.25831881,22.2372094 L5.25831961,22.2372076 C4.14771518,21.7444097 2.84790075,22.2452408 2.35510282,23.3558452 C2.33549093,23.4000439 2.31734156,23.4448773 2.30068428,23.4902721 Z"
                    id="shoe"
                  />
                </g>
                <g
                  fill="#E4E4E4"
                  id="Accessories/Shoe/Flat-Sneaker"
                  transform="translate(263.040000, 120.033994)"
                >
                  <path
                    d="M2.30068428,23.4902721 C1.37578365,26.0108336 0.913333333,27.9124901 0.913333333,29.1952417 C0.913333333,30.8350572 1.18771164,33.2279559 1.73646825,36.3739377 C3.64710547,36.3739377 20.8183238,36.3739377 53.2501234,36.3739377 C54.5887807,31.9676964 53.8160301,29.5889085 50.9318715,29.237574 C48.047713,28.8862395 45.6517935,28.5690126 43.7441131,28.2858933 L19.9739294,17.7352612 C19.4186564,17.4887978 18.7687206,17.7391374 18.5222571,18.2944104 C18.5185119,18.3028482 18.5148731,18.3113328 18.5113415,18.3198622 L17.0457346,21.8595499 L17.0457346,21.8595499 C14.3757582,23.0998622 12.2061251,23.7200183 10.5368354,23.7200183 C9.24566667,23.7200183 7.48616112,23.2257487 5.25831881,22.2372094 L5.25831961,22.2372076 C4.14771518,21.7444097 2.84790075,22.2452408 2.35510282,23.3558452 C2.33549093,23.4000439 2.31734156,23.4448773 2.30068428,23.4902721 Z"
                    id="shoe"
                  />
                </g>
                <path
                  d="M190.760709,60.2443818 C162.906949,55.7264504 144.250122,52.6371063 134.790229,50.9763497 C107.825805,46.2425385 99.96275,31.7956689 104.611808,0.27483116 L154.2933,0.27483116 C160.867847,2.16515283 188.338855,13.8561791 236.706324,35.3479101 C245.408586,39.6489864 243.787939,52.3307278 242.19732,56.5088007 C242.132448,56.6791984 227.462103,81.7076991 198.186283,131.594303 L157.544727,105.816777 L190.837035,63.2819105 C191.716253,61.5187747 191.690811,60.5062651 190.760709,60.2443818 Z"
                  fill="#5C63AB"
                  id="Leg-and-Butt"
                />
              </g>
              <g
                id="Body/Long-Sleeve"
                transform="translate(20.093333, 74.566572)"
              >
                <path
                  d="M62.0790766,67.7007036 L82.0786944,67.7754336 C60.1485707,116.910991 48.713864,142.637591 47.7745743,144.955231 C45.6611726,150.169922 50.1334148,158.111807 52.0459231,162.379717 C45.8169473,165.154837 46.4806513,154.877434 38.622966,158.517159 C31.4508351,161.839331 25.995013,167.858884 17.7086522,162.768253 C16.6898928,162.14239 15.5734985,159.785902 18.2708482,157.944431 C24.9909302,153.356656 34.6749754,145.327223 35.9834424,142.684273 C37.767771,139.080139 46.4663157,114.085616 62.0790766,67.7007036 L62.0790766,67.7007036 Z M231.706414,50.300788 C227.665788,53.6545953 223.566004,57.3646065 224.285921,59.1544749 C225.005838,60.9443433 228.763569,60.2783924 229.386076,63.0313622 C229.801081,64.8666754 223.702513,65.1648955 211.090372,63.9260225 L181.829938,75.4759207 L175.36,59.390265 L211.421027,54.4928553 C218.634695,50.4979617 225.27092,47.7934887 231.329702,46.3794361 C233.034706,46.3298722 235.747039,46.9469807 231.706414,50.300788 Z"
                  fill="#997659"
                  id="Skin"
                />
                <path
                  d="M112.790989,8.81056661 L128.480971,8.48046014 C135.350437,42.7503669 158.189174,62.7780981 204.394547,51.2790123 L214.518097,95.2992732 C171.220031,104.765283 131.287964,90.6696887 119.282299,44.0968847 C116.174412,32.040657 113.451654,19.4490496 112.790989,8.81056661 Z"
                  fill="#AFB9C5"
                  id="Clothes-Back"
                  transform="translate(163.654543, 53.281783) rotate(5.000000) translate(-163.654543, -53.281783) "
                />
                <path
                  d="M109.122468,0 L109.133827,0.014 L132.860412,5.42451496 C132.860412,45.6471394 145.600677,71.4554375 152.324767,100.952041 L152.526667,101.847025 L88.4504983,101.847473 C87.5478972,112.169254 86.9936155,122.77817 86.7047796,133.674221 L86.7047796,133.674221 L38.7268273,133.674221 C49.5989003,76.0796947 72.5461683,31.521621 107.568631,0 L107.568631,0 L109.122468,0 Z"
                  fill="#C5CFD6"
                  id="Clothes"
                />
                <path
                  d="M98.5599113,43.8864582 C96.908794,69.2029071 97.8800125,88.5230962 101.473567,101.847025 L101.473567,101.847025 L88.4504983,101.847473 C90.1992897,81.8490019 93.2556422,62.9284103 98.222314,45.0856986 Z"
                  fill="#000000"
                  fillOpacity="0.1"
                  id="Shade"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default HumanSorrySitting;
