import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useQueryParams, { getParamAsString } from "../hooks/useQueryParams";
import { IAuthContext } from "../providers/auth/auth";
import AuthContext from "../providers/auth/AuthContext";
import { parseOneLanguageFromAPI } from "./languagesUtil";

export const InitLangauge = () => {
  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const { i18n } = useTranslation();
  // We should stop using this and use the lang param implemented by i18n
  const { lng: languageURLParam } = useQueryParams();
  const languageFromUrl = getParamAsString(languageURLParam);

  useEffect(() => {
    if (currentUser) {
      // If a user is logged in, use the language that is set in his settings
      i18n.changeLanguage(currentUser.getPreferredLanguage().getValue());
    } else if (languageURLParam) {
      // a param "lng" in the url takes precedence over the inital language set in the browser.
      i18n.changeLanguage(parseOneLanguageFromAPI(languageFromUrl));
    }
  }, [currentUser, i18n, languageFromUrl, languageURLParam]);

  return null;
};
