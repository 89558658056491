import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { IWithChildren } from "../../../../../baseInterfaces";
import { BloomColor } from "../../../../layout/wizard/StepFooter";
import { ICallContext } from "../../context/Call";
import CallContext from "../../context/CallContext";

const PREFIX = "ChimeButton";

const classes = {
  root: `${PREFIX}-root`,
};

const StyledButton = styled(Button)<{
  bloomColor: BloomColor;
  isSmallerThanThirdBreakpoint: boolean;
}>(({ theme, bloomColor, isSmallerThanThirdBreakpoint }) => ({
  [`&.${classes.root}`]: {
    "&:hover": {
      background: theme.palette[bloomColor].main,
      opacity: 1,
    },
    background: theme.palette[bloomColor].dark,
    borderRadius: "50%",
    color: theme.palette.black.main,
    height: isSmallerThanThirdBreakpoint ? 40 : 60,
    minWidth: isSmallerThanThirdBreakpoint ? 40 : 60,
    opacity: 0.6,
    width: isSmallerThanThirdBreakpoint ? 40 : 60,
  },
}));

interface IChimeButton extends IWithChildren {
  bloomColor: BloomColor;
  onClick: () => void;
}

export default function ChimeButton({
  bloomColor,
  onClick,
  children,
}: IChimeButton) {
  const { isSmallerThanThirdBreakpoint } =
    useContext<ICallContext>(CallContext);

  return (
    <StyledButton
      bloomColor={bloomColor}
      className={classes.root}
      isSmallerThanThirdBreakpoint={isSmallerThanThirdBreakpoint}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
}
