import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { IWithChildren } from "../../baseInterfaces";
import TextField from "../ui/form/TextField";
import TitleWithButton from "../ui/text/TitleWithButton";

const PREFIX = "LeftSection";

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  search: `${PREFIX}-search`,
  section: `${PREFIX}-section`,
};

const StyledGrid = styled(Grid)<{ short: boolean }>(({ theme, short }) => ({
  [`&.${classes.gridContainer}`]: {
    borderRight: `solid 2px ${theme.palette.bloom.light}`,
    height: "100%",
    overflowY: "auto",
    padding: theme.spacing(4, 0, 4, 2),

    [theme.breakpoints.down("md")]: {
      height: short ? "auto" : "100%",
    },

    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4, 1),
    },
  },

  [`& .${classes.section}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    height: "100%",
    padding: theme.spacing(0, 2),

    [theme.breakpoints.down("sm")]: {
      height: "auto",
      padding: theme.spacing(2),
    },
  },

  [`& .${classes.search}`]: {
    marginBottom: theme.spacing(4),
  },
}));

interface ILeftSection extends IWithChildren {
  buttonIcon?: React.ReactNode;
  buttonLabel?: string;
  handleSearch?: (value: string) => void;
  handleTitleButton?: () => void;
  labelTestId?: string;
  pageTitle: string;
  searchLabel?: string;
  searchTerm?: string;
  short: boolean;
}

const LeftSection = ({
  searchTerm,
  searchLabel,
  handleSearch,
  handleTitleButton,
  pageTitle,
  buttonLabel,
  buttonIcon,
  short,
  children,
  labelTestId,
}: ILeftSection) => {
  const onTitleButtonClick = handleTitleButton ? handleTitleButton : () => {};

  return (
    <StyledGrid
      className={classes.gridContainer}
      id="leftSection" // needed for infiniteScroll component
      item
      lg={5}
      md={6}
      short={short}
      sm={12}
      xs={12}
      xss={12}
    >
      <section className={classes.section}>
        <TitleWithButton
          buttonIcon={buttonIcon}
          buttonLabel={buttonLabel}
          handleButton={onTitleButtonClick}
          labelTestId={labelTestId}
          title={pageTitle}
        />

        {handleSearch && (
          <Box className={classes.search} mt={buttonLabel ? 0 : 2}>
            <TextField
              autoComplete="search"
              fullWidth
              id="search"
              label={searchLabel}
              name="search"
              onChange={handleSearch}
              required={false}
              type="search"
              value={searchTerm}
            />
          </Box>
        )}
        {children}
      </section>
    </StyledGrid>
  );
};

export default LeftSection;
