import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const Copyright = () => (
  <Typography align="center" color="textSecondary" variant="body2">
    {"Copyright © "}
    <Link color="inherit" href="https://www.bloomup.org/" underline="hover">
      bloomup.org
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

export default Copyright;
