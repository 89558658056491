import { Box, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import { useTranslation } from "react-i18next";
import { animated, useChain, useSpring, useSpringRef } from "@react-spring/web";
import { IWithChildren } from "../../../baseInterfaces";
import useWindowDimensions from "../../../custom-hooks/useWindowDimensions";
import CallClockWithContext from "../../pages/professional-call/components/CallClockWithContext";
import { I18Namespaces } from "../../language/I18Namespaces";

const PREFIX = "OverlayDrawer";

const classes = {
  controlIcon: `${PREFIX}-controlIcon`,
  controlLabel: `${PREFIX}-controlLabel`,
  pane: `${PREFIX}-pane`,
  paneControl: `${PREFIX}-paneControl`,
  paneInfo: `${PREFIX}-paneInfo`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")<{ width: number }>(({ theme, width }) => ({
  [`& .${classes.pane}`]: {
    background: theme.palette.whiteSmoke.main,
    borderRadius: 20,
    color: theme.palette.bloom.main,
    display: "flex",
    height: "80%",
    left: 30,
    position: "absolute",
    top: 30,
    width,
    zIndex: 8,
    [theme.breakpoints.down(500)]: {
      borderRadius: 0,
      height: "100%",
      left: 0,
      position: "absolute",
      top: 0,
      width: "100%",
    },
  },

  [`& .${classes.paneControl}`]: {
    background: theme.palette.bloom.dark,
    borderRadius: 30,
    color: theme.palette.bloom.light,
    cursor: "pointer",

    display: "flex",
    flexFlow: "row nowrap",
    height: 35,
    marginLeft: 10,
    overflow: "hidden",
    padding: 5,

    position: "absolute",
    top: 90,
    width: 35,
  },

  [`& .${classes.controlIcon}`]: {
    height: 17,
    margin: "4px 0 4px 4px",
    width: 17,
  },

  [`& .${classes.controlLabel}`]: {
    fontSize: theme.typography.sizes.large,
    padding: "2px 0px 0px 8px",
    whiteSpace: "nowrap",
  },

  [`& .${classes.paneInfo}`]: {
    background: theme.palette.bloom.light,
    borderRadius: 30,
    color: theme.palette.bloom.dark,
    display: "flex",
    flexFlow: "row nowrap",

    height: 35,
    left: 20,
    opacity: 0,
    overflow: "hidden",
    padding: 5,
    paddingRight: 15,

    position: "absolute",
    top: 20,
    zIndex: 8,
  },
}));

/**
 * Interface for the config of an OverlayDrawer.
 */
interface IOverlayDrawer extends IWithChildren {
  collapsedInfo?: string;
  isOpen: boolean;
  label?: string;
  width?: number;
  withClock?: boolean;
}

/**
 * A left drawer that can be used to display content. Now mainly used for
 * the client or professional information shown during the call.
 *
 * @param {number} width - How wide does the drawer need to be?
 * @param {string} label - The label that is shown in the hidden state next to the arrow.
 * @param {string} label - What additional info should be shown when the panel is collapsed?
 */
export default function OverlayDrawer({
  width = 350,
  label = "",
  children,
  isOpen,
  withClock = true,
}: IOverlayDrawer) {
  const xs = useMediaQuery("only screen and (max-width: 500px)");

  const { width: widthOfWindow } = useWindowDimensions();

  const paneWidth = xs ? widthOfWindow : width;

  const { t: translate } = useTranslation<I18Namespaces>("ui");

  if (label === "")
    label = translate("professional:client.info", "Cliënt info");

  const AnimatedDrawer = animated(Box);
  const animatedDrawerRef = useSpringRef();
  const animatedDrawerProps = useSpring({
    left: isOpen ? (xs ? 0 : 30) : paneWidth * -1,
    ref: animatedDrawerRef,
  });

  const animatedToggleRef = useSpringRef();

  const AnimatedInfo = animated(Box);
  const animatedInfoRef = useSpringRef();
  const animatedInfoProps = useSpring({
    height: isOpen ? 0 : 35,
    opacity: isOpen ? 0 : 1,
    ref: animatedInfoRef,
  });

  useChain(
    isOpen
      ? [animatedInfoRef, animatedDrawerRef, animatedToggleRef]
      : [animatedToggleRef, animatedDrawerRef, animatedInfoRef],
    isOpen ? [0, 0.5, 0.5] : [0, 0, 0.5],
  );

  return (
    <Root width={width}>
      <AnimatedDrawer className={classes.pane} style={animatedDrawerProps}>
        {children}
      </AnimatedDrawer>
      {withClock && (
        <AnimatedInfo className={classes.paneInfo} style={animatedInfoProps}>
          <AccessAlarmIcon className={classes.controlIcon} />
          <CallClockWithContext displayClock={!isOpen} smallVersion={true} />
        </AnimatedInfo>
      )}
    </Root>
  );
}
