import Box from "@mui/material/Box";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LeftSection from "../../layout/LeftSection";
import LoadingPage from "../../layout/LoadingPage";
import EditableProfileInfo from "../../profile/EditableProfile";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import TitleWithButton from "../../ui/text/TitleWithButton";
import { IProfessionalProfileContext } from "./context/professionalProfile";
import ProfessionalProfileContext from "./context/ProfessionalProfileContext";
import EditableProfessionalProfile from "./EditableProfessionalProfile";
import ProfessionalBillingProfile from "./ProfessionalBillingProfile";

const ProfessionalProfilePage = () => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
  ]);

  const {
    professional,
    formState,
    onSave,
    onSaveSpecialties,
    updatePractice,
    updateBillingInformation,
    updatePreferredLanguage,
    updateDescriptions,
    updateTimezone,
  } = useContext<IProfessionalProfileContext>(ProfessionalProfileContext);

  if (!professional) return <LoadingPage full={false} />;

  return (
    <LeftSection
      labelTestId="test_profile_title_label"
      pageTitle={translate("common:myprofile", "Mijn Profiel")}
      short={false}
    >
      <Box>
        <EditableProfileInfo
          formState={formState}
          onSave={onSave}
          person={professional}
          updatePreferredLanguage={updatePreferredLanguage}
          updateTimezone={updateTimezone}
        />
      </Box>
      <Box>
        <TitleWithButton
          title={translate("professional:profile.title", "Professional")}
        />
        <EditableProfessionalProfile
          formState={formState}
          onSave={onSave}
          onSaveSpecialties={onSaveSpecialties}
          professional={professional}
          updateProfessionalDescriptions={updateDescriptions}
          updateProfessionalPractice={updatePractice}
        />
      </Box>
      <Box>
        <TitleWithButton
          title={translate("professional:profile.billing.title", "Uitbetaling")}
        />
        <ProfessionalBillingProfile
          formState={formState}
          onSave={updateBillingInformation}
          professional={professional}
        />
      </Box>
    </LeftSection>
  );
};

export default ProfessionalProfilePage;
