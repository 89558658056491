import { useState } from "react";
import Professional from "../../../../data-model/types/professional/Professional";
import useVerifyProfessional from "../../../../api/professionals/hooks/useVerifyProfessional";
import ProfessionalTableRow from "./ProfessionalTableRow";
import ProfessionalTableEditRow from "./PsysTableEditRow";

interface IProfessionalsTableRowWithState {
  onDeleteComplete: () => void;
  professional: Professional;
  refetch: () => void;
}

const ProfessionalsTableRowWithState = ({
  professional,
  refetch,
}: IProfessionalsTableRowWithState) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const { verifyProfessional } = useVerifyProfessional();

  //TODO: these functions should not be here!
  const handleSubmitUpdate = async (/* input */) => {
    try {
      /*await updatePsy({
                variables: {
                    input,
                    id: psy.id,
                },
            });*/
    } catch (e: any) {
      throw new Error(e);
    }
    setIsEditing(false);
  };

  const handleSubmitDelete = async () => {
    try {
      /*await deletePsy({
                variables: {
                    id: psy.id,
                },
            });
            onDeleteComplete(psy);*/
    } catch (e: any) {
      setIsRemoving(false);

      throw new Error(e);
    }
  };

  const handleSubmitVerify = async (id: number) => {
    try {
      await verifyProfessional(id);
      await refetch();
    } catch (e: any) {
      throw new Error(e);
    }
  };

  if (isEditing) {
    return (
      <ProfessionalTableEditRow
        key={professional.getID()}
        onCancelUpdate={() => setIsEditing(false)}
        onSubmitUpdate={handleSubmitUpdate}
        professional={professional}
      />
    );
  }

  return (
    <ProfessionalTableRow
      isRemoving={isRemoving}
      isVerifying={false}
      key={professional.getID()}
      onCancelDelete={() => setIsRemoving(false)}
      onDeleteRow={() => setIsRemoving(true)}
      onEditRow={() => setIsEditing(true)}
      onSubmitDelete={handleSubmitDelete}
      onVerifyProfessional={handleSubmitVerify}
      professional={professional}
    />
  );
};

export default ProfessionalsTableRowWithState;
