import { Typography } from "@mui/material";
import MuiToolTip, { TooltipProps } from "@mui/material/Tooltip";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SanitizedText from "./text/SanitizedText";

type Variant = "dark" | "light";

const Tooltip = ({
  text,
  placement = "right-end",
  children,
  title,
  variant = "dark",
}: Pick<TooltipProps, "placement"> & {
  children?: ReactElement<any, any>;
  placement?: string;
  text: string;
  title?: string;
  variant?: Variant;
}) => {
  const { t } = useTranslation();

  return (
    <MuiToolTip
      placement={placement}
      slotProps={{
        tooltip: {
          sx: (theme) => ({
            background:
              variant === "dark"
                ? theme.palette.bloom.dark
                : theme.palette.bloom.light,
            borderColor: theme.palette.bloom.main,
            borderRadius: "10px",
            borderStyle: "solid",
            borderWidth: "1px",
            color:
              variant === "dark"
                ? theme.palette.bloom.light
                : theme.palette.bloom.dark,
            fontSize: "0.8rem",
            padding: theme.spacing(2),
          }),
        },
      }}
      title={
        <>
          {title && (
            <Typography style={{ fontWeight: "bold" }}>{t(title)}</Typography>
          )}
          <SanitizedText input={text} />
        </>
      }
    >
      {children || (
        <InfoOutlined
          fontSize="small"
          sx={(theme) => ({
            color: theme.palette.bloom.main,
            marginLeft: "5px",
            marginTop: "1px",
            position: "absolute",
          })}
        />
      )}
    </MuiToolTip>
  );
};

export default Tooltip;
