import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";
import Professional from "../../../data-model/types/professional/Professional";
import { UserRoles } from "../../../data-model/types/User";
import ProfessionalDetailWithState from "./ProfessionalDetailWithState";
import ListOfProfessionals from "./ListOfProfessionals";

interface IProfessionalsPage {
  focus: number | undefined;
  handleClick: (id: number | undefined) => void;
  onResponse: () => void;
  pageTitle: string;
  professionals: Array<Professional>;
  roleType: UserRoles;
}

export default function ProfessionalsPage({
  focus,
  handleClick,
  pageTitle,
  professionals,
  roleType,
}: IProfessionalsPage) {
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  if (small) {
    return focus ? (
      <ProfessionalDetailWithState
        handleClick={handleClick}
        professional={
          professionals.filter((c) => {
            return c.getID() === focus;
          })[0]
        }
      />
    ) : (
      <ListOfProfessionals
        buttonIcon=""
        buttonLabel=""
        focus={focus}
        handleButton={() => console.log("handlebutton")}
        handleClick={handleClick}
        pageTitle={pageTitle}
        professionals={professionals}
        roleType={roleType}
      />
    );
  }

  return (
    <>
      <ListOfProfessionals
        buttonIcon=""
        buttonLabel=""
        focus={focus}
        handleButton={() => console.log("handlebutton")}
        handleClick={handleClick}
        pageTitle={pageTitle}
        professionals={professionals}
        roleType={roleType}
      />
      {focus && (
        <ProfessionalDetailWithState
          handleClick={handleClick}
          professional={professionals.filter((c) => c.getID() === focus)[0]}
        />
      )}
    </>
  );
}
