import React from "react";
import { styled, lighten } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

const PREFIX = "ProgressBar";

const classes = {
  bar: `${PREFIX}-bar`,
  contour: `${PREFIX}-contour`,
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.white.main,
    borderRadius: 20,
    height: 6,
  },

  [`& .${classes.bar}`]: {
    backgroundColor: theme.palette.green.dark,
    padding: theme.spacing(3),
  },

  [`&.${classes.contour}`]: {
    "& P": {
      color: theme.palette.green.dark,
    },
    background: lighten(theme.palette.green.light, 0.5),
    bottom: -28,
    left: "50%",
    margin: "0 auto",
    maxWidth: "400px",
    position: "absolute",
    transform: "translateX(-50%)",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },

    zIndex: 2,
  },
}));

export default function ProgressBar({ step, totalSteps }) {
  return (
    <StyledBox
      borderRadius="50px"
      className={classes.contour}
      px={4}
      py={2}
      textAlign="center"
    >
      <Box alignItems="center" display="flex">
        <Box justifyContent="flex-start" width="90%">
          <LinearProgress
            classes={{ bar: classes.bar, root: classes.root }}
            color="secondary"
            value={(step / totalSteps) * 100}
            variant="determinate"
          />
        </Box>
        <Box justifyContent="flex-end" ml={1}>
          <Typography align="right" id="step-counter">
            {`${step}/${totalSteps}`}
          </Typography>
        </Box>
      </Box>
    </StyledBox>
  );
}
