/**
 * Avatar of user, psy or client.
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export interface AvatarData {
  url: string;
}

export default class Avatar extends DataType<
  AvatarData,
  DataTypePaths.Profile.Avatar
> {
  protected static path = "avatar";
  protected static defaultValue = {};

  protected type: any = Avatar;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:avatar.mandatory",
          "Voeg een profielfoto toe.",
        ),
        path: this.getPath(),
        validate: (avatar: any): boolean => avatar instanceof File,
      },
    ];
  }

  /**
   * Get the url of the avatar.
   */
  getUrl(): string {
    if (this.value) {
      return this.value.url;
    } else {
      return "";
    }
  }

  getAsFormStateValue(): Partial<
    Record<DataTypePaths.Profile.Avatar, AvatarData>
  > {
    return { avatar: this.value };
  }
}
