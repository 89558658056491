import { Box, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useLocalAudioInputActivity } from "amazon-chime-sdk-component-library-react";
import { useCallback, useState, useMemo } from "react";
import { BloomColor } from "../../../../layout/wizard/StepFooter";
import Block from "./Block";

const PREFIX = "ActivityBar";

const classes = {
  block: `${PREFIX}-block`,
  root: `${PREFIX}-root`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: "100%",
  },

  [`& .${classes.block}`]: {
    background: theme.palette.white.dark,
    borderRadius: 50,
    height: 10,
    width: "100%",
  },
}));

interface IActivityBar {
  blocks?: number;
  bloomColor?: BloomColor;
}

export default function ActivityBar({
  blocks = 6,
  bloomColor = "bloom",
}: IActivityBar) {
  const [value, setValue] = useState(0);

  const callback = useCallback((decimal: number) => {
    const timeOut = setTimeout(() => {
      setValue(0);
    }, 500);

    if (decimal !== 0) {
      setValue(decimal * 100);
      clearTimeout(timeOut);
    }
  }, []);

  useLocalAudioInputActivity(callback);

  const blocksPercentage: number[] = [];

  const blockValue = useMemo(() => 100 / blocks, [blocks]);

  const [fullBlocks, emptyBlocks] = useMemo(
    () => [
      Math.floor(value / blockValue),
      Math.floor((100 - value) / blockValue),
    ],
    [value, blockValue],
  );

  const restBlockValuePercentage = useMemo(() => {
    const restBlockValue = value - fullBlocks * blockValue;

    return (restBlockValue / blockValue) * 100;
  }, [value, fullBlocks, blockValue]);

  for (let i = 0; i < fullBlocks; i++) {
    blocksPercentage.push(100);
  }

  if (fullBlocks !== blocks && restBlockValuePercentage !== 0)
    blocksPercentage.push(restBlockValuePercentage);

  for (let i = 0; i < emptyBlocks; i++) {
    blocksPercentage.push(0);
  }

  return (
    <StyledBox className={classes.root}>
      <Grid container spacing={2}>
        {blocksPercentage.map((percentage, i) => (
          <Grid item key={i} xs>
            <Block bloomColor={bloomColor} percentage={percentage} />
          </Grid>
        ))}
      </Grid>
    </StyledBox>
  );
}
