import { useContext, useEffect, useState } from "react";
import LoadingPage from "../../layout/LoadingPage";
import useInviteClientMutation from "../../../api/clients/hooks/useInviteClientMutation";
import AuthContext from "../../providers/auth/AuthContext";
import ToastContext from "../../providers/toast/ToastContext";
import { IAuthContext } from "../../providers/auth/auth";
import ClientsForProfessionalsContext from "./context/ClientsForProfessionalsContext";
import { IClientsForProfessionalsContext } from "./context/clientsForProfessionals";
import AddClientModal from "./AddClientModal";
import useAddClientFormState from "./useAddClientFormState";

interface IAddClientModalWithState {
  clients: Array<any>;
  isModalOpen: boolean;
  toggleModal: () => void;
}

export default function AddClientModalWithState({
  clients,
  isModalOpen,
  toggleModal,
}: IAddClientModalWithState) {
  const { setToast } = useContext(ToastContext);
  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const formState = useAddClientFormState({
    clients,
    ownEmail: currentUser ? currentUser.getEmail() : "",
  });

  const {
    inviteClient,
    newClientWasCreated,
    loading: inviteLoading,
  } = useInviteClientMutation();

  const { refetchClients } = useContext<IClientsForProfessionalsContext>(
    ClientsForProfessionalsContext,
  );

  const [success, setSuccess] = useState(false);
  //TODO: whether or not this is an invite, should be up to the backend.
  // email does not exist => invite
  const [invite, setInvite] = useState(false);

  useEffect(() => {
    if (newClientWasCreated) setSuccess(true);
  }, [newClientWasCreated]);

  const handleInvite = async () => {
    if (formState.validate()) {
      try {
        const { email } = formState.values;
        const input = { email };

        await inviteClient(input);

        if (refetchClients) refetchClients();
      } catch (e) {
        setToast({
          message: "Error. Try again later.",
          severity: "error",
        });
        console.log({ e });
      }
    }
  };

  const handleReset = () => {
    setInvite(false);
    setSuccess(false);
    formState.resetForm();
  };

  if (inviteLoading) return <LoadingPage />;

  return (
    <AddClientModal
      formState={formState}
      handleInvite={handleInvite}
      handleReset={handleReset}
      invite={invite}
      isModalOpen={isModalOpen}
      success={success}
      toggleModal={toggleModal}
    />
  );
}
