import { useContext } from "react";
import * as PusherTypes from "pusher-js";
import OnlineContext from "../context/OnlineContext";
import { CHANNEL_PRESENCE_ALL } from "../channels";
import Presence, { SpecificPresenceProps } from "./Presence";

export default function AllUsersPresence({ children }: SpecificPresenceProps) {
  const { setAllUsersChannel } = useContext(OnlineContext);

  const userAdded = () => {}; //console.log('Added new user to ALL USERS:', user);
  const userRemoved = () => {}; //console.log('Removed user from ALL USERS:', user);
  const subscribed = (channel: PusherTypes.PresenceChannel) =>
    setAllUsersChannel(channel);

  return (
    <Presence
      channel={CHANNEL_PRESENCE_ALL}
      memberAdded={userAdded}
      memberRemoved={userRemoved}
      subscribedToChannel={subscribed}
    >
      {children}
    </Presence>
  );
}
