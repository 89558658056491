import type { TFunction } from "i18next";
import { I18Namespaces } from "../components/language/I18Namespaces";
import { IGroupSelectObject } from "../components/ui/form/select/BaseGroupSelect";
import { ISelectObject } from "../components/ui/form/select/BaseSimpleSelect";
import DataType from "./DataType";

export default abstract class GroupSelectionDataType<
  ValueType,
  PathName extends string,
  GroupSelectObjectType = string,
> extends DataType<
  Array<ValueType>,
  PathName,
  IGroupSelectObject<GroupSelectObjectType>[]
> {
  abstract getSelectOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<IGroupSelectObject<GroupSelectObjectType>>;

  abstract getSelectedOptions(
    translate: TFunction<I18Namespaces>,
  ): Array<ISelectObject<GroupSelectObjectType>>;

  abstract getSelectedOptionsAsGroupSelectObject(
    translate: TFunction<I18Namespaces>,
  ): Array<IGroupSelectObject<GroupSelectObjectType>>;

  abstract getTranslatedValues(
    translate: TFunction<I18Namespaces>,
  ): Array<string>;

  abstract getOptions(): Array<ValueType> | null;
}
