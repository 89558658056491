import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import Avatar from "../ui/Avatar";
import ValueWithLabel from "../ui/text/ValueWithLabel";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";

export const viewOnlyProfileInfoFragment = graphql(`
  fragment ViewOnlyProfileInfo on User {
    ...Avatar
    birthDate
    email
    firstName
    id
    lastName
    phone
    preferredLanguage
    timezone
  }
`);

interface ViewOnlyProfileInfoProps {
  showBirthYear?: boolean;
  user: FragmentType<typeof viewOnlyProfileInfoFragment>;
}

export default function ViewOnlyProfileInfo({
  user,
  showBirthYear = false,
}: ViewOnlyProfileInfoProps) {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Ui,
  ]);

  const viewOnlyProfileData = getFragmentData(
    viewOnlyProfileInfoFragment,
    user,
  );

  const birthYear =
    viewOnlyProfileData.birthDate !== null &&
    viewOnlyProfileData.birthDate !== undefined
      ? parseISO(viewOnlyProfileData.birthDate).getFullYear()
      : undefined;

  return (
    <Grid container>
      <Box
        sx={{
          "& .edit .inline-edit-controls-edit": {
            width: "187px !important",
          },
          display: "grid",
          gridTemplateColumns: "140px auto",

          width: "100%",
        }}
      >
        <Box
          sx={{
            gridColumnStart: 1,
            gridRowStart: 1,
            marginTop: "5px",
            position: "relative",
          }}
        >
          <Avatar showGender size={120} user={viewOnlyProfileData} />
        </Box>
        <Box sx={{ gridColumnStart: 2, gridRowStart: 1 }}>
          <Box mb={1}>
            <ValueWithLabel label={t("common:firstname")} large>
              {viewOnlyProfileData.firstName}
            </ValueWithLabel>
          </Box>
          <Box>
            <ValueWithLabel label={t("common:lastname")} large>
              {viewOnlyProfileData.lastName}
            </ValueWithLabel>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "30px 5px", width: "100%" }}>
        {showBirthYear && (
          <Box mb={3}>
            <ValueWithLabel label={t("common:birthdate")}>
              {birthYear ? birthYear : "-"}
            </ValueWithLabel>
          </Box>
        )}
        <Box mb={3}>
          <ValueWithLabel label={t("common:email")}>
            {viewOnlyProfileData.email}
          </ValueWithLabel>
        </Box>
        <Box mb={3}>
          <ValueWithLabel label={t("common:phone")}>
            {viewOnlyProfileData.phone ? viewOnlyProfileData.phone : "-"}
          </ValueWithLabel>
        </Box>
        <Box mb={3}>
          <ValueWithLabel label={t("common:preferences.preferred_language")}>
            {t(`ui:languages.${viewOnlyProfileData.preferredLanguage}`)}
          </ValueWithLabel>
        </Box>
        <Box>
          <ValueWithLabel label={t("common:preferences.timezone")}>
            {viewOnlyProfileData.timezone ? viewOnlyProfileData.timezone : "-"}
          </ValueWithLabel>
        </Box>
      </Box>
    </Grid>
  );
}
