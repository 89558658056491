import { ConsultationStatus } from "../../../api/__generated__/graphql";

export const allStatuses: ConsultationStatus[] = [
  "CANCELLED_BY_HUMAN",
  "NO_SHOW_HUMAN",
  "NO_SHOW_PROFESSIONAL",
  "IN_PROGRESS",
  "ACCEPTED",
  "DONE",
  "WAITING",
];
