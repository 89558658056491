import { Route, Routes } from "react-router-dom";
import MollieRedirectPage from "../payments/MollieRedirectPage";
import PaymentFailurePage from "../payments/PaymentFailurePage";
import PaymentSuccessPage from "../payments/PaymentSuccessPage";
import ReimbursementPage from "../payments/ReimbursementPage";
import PaymentPage from "../payments/PaymentPage";
import PaymentContextProvider from "../payments/context/PaymentContextProvider";
import { RouteNames } from "./routeNames";

export default function PaymentRoutes() {
  return (
    <PaymentContextProvider>
      <Routes>
        <Route element={<PaymentPage />} path={""} />
        <Route
          element={<ReimbursementPage />}
          path={RouteNames.Payment.Consultation.Pay.Reimbursement.name}
        />
        <Route
          element={<PaymentSuccessPage />}
          path={RouteNames.Payment.Consultation.Pay.Success.name}
        />
        <Route
          element={<PaymentFailurePage />}
          path={RouteNames.Payment.Consultation.Pay.Failure.name}
        />
        <Route
          element={<MollieRedirectPage />}
          path={RouteNames.Payment.Consultation.Pay.Pending.name}
        />
      </Routes>
    </PaymentContextProvider>
  );
}
