import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";
import MicOffOutlinedIcon from "@mui/icons-material/MicOffOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import {
  useLocalVideo,
  useMeetingManager,
  useToggleLocalMute,
} from "amazon-chime-sdk-component-library-react";
import { useCallback, useContext } from "react";
import { IWithChildren } from "../../../../../baseInterfaces";
import { ChimeVideoCall } from "../../ChimeVideoCall.types";
import { ICallContext } from "../../context/Call";
import CallContext from "../../context/CallContext";
import ChimeButton from "./ChimeButton";
import ChimeButtonBar from "./ChimeButtonBar";

export default function ChimeStandardButtonBar({ children }: IWithChildren) {
  const { paneToPresent, setPaneToPresent, exitMeeting } =
    useContext<ICallContext>(CallContext);

  const { isVideoEnabled, setIsVideoEnabled } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const meetingManager = useMeetingManager();

  const toggleCamera = useCallback(async () => {
    if (meetingManager) {
      if (isVideoEnabled || !meetingManager.selectedVideoInputDevice) {
        meetingManager.meetingSession?.audioVideo.stopLocalVideoTile();

        setIsVideoEnabled(false);
      } else {
        await meetingManager.meetingSession?.audioVideo.startVideoInput(
          meetingManager.selectedVideoInputDevice,
        );

        meetingManager.meetingSession?.audioVideo.startLocalVideoTile();

        setIsVideoEnabled(true);
      }
    }
  }, [meetingManager, isVideoEnabled, setIsVideoEnabled]);

  return (
    <ChimeButtonBar>
      {children}
      <ChimeButton
        bloomColor={isVideoEnabled ? "green" : "bloom"}
        onClick={toggleCamera}
      >
        {isVideoEnabled ? (
          <VideocamOutlinedIcon />
        ) : (
          <VideocamOffOutlinedIcon />
        )}
      </ChimeButton>
      <ChimeButton bloomColor={muted ? "bloom" : "green"} onClick={toggleMute}>
        {muted ? <MicOffOutlinedIcon /> : <MicNoneOutlinedIcon />}
      </ChimeButton>
      <ChimeButton
        bloomColor={
          paneToPresent === ChimeVideoCall.SidePaneProfessional.menuPane ||
          paneToPresent === ChimeVideoCall.SidePaneProfessional.settingsPane
            ? "green"
            : "bloom"
        }
        onClick={() => {
          if (setPaneToPresent) {
            setPaneToPresent(
              paneToPresent === ChimeVideoCall.SidePaneProfessional.menuPane
                ? ChimeVideoCall.SidePaneProfessional.none
                : ChimeVideoCall.SidePaneProfessional.menuPane,
            );
          }
        }}
      >
        <MoreHorizOutlinedIcon />
      </ChimeButton>
      <ChimeButton bloomColor="red" onClick={exitMeeting}>
        <ExitToAppOutlinedIcon />
      </ChimeButton>
    </ChimeButtonBar>
  );
}
