import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import EuroIcon from "@mui/icons-material/Euro";
import Box from "@mui/material/Box";

import RadioQuestion from "../../../ui/form/RadioQuestion";
import TextField from "../../../ui/form/TextField";
import Label from "../../../ui/form/FormLabel";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";

import ConsultationDurationDataType from "../../../../data-model/types/consultation/ConsultationDuration";
import ConsultationRateDataType from "../../../../data-model/types/consultation/ConsultationRate";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";

export default function TargetRateStep() {
  const { next, previous, step, totalSteps, formState } = useContext(
    ProfessionalOnboardingContext,
  );

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Common,
  ]);

  const error =
    formState.testForErrors(ConsultationDurationDataType.getPath()) ||
    formState.testForErrors(ConsultationRateDataType.getPath());

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate(
          "professional:onboarding.targetrate.title",
          "Je tarief",
        )}
        subheadicon={<EuroIcon />}
        totalSteps={totalSteps}
      >
        <Box mb={4}>
          <Label
            component="legend"
            label={translate(
              "professional:onboarding.duration",
              "Mijn consultaties duren...",
            )}
          />
          <RadioQuestion
            options={ConsultationDurationDataType.getSelectOptions(translate)}
            {...formState.getInputProps(ConsultationDurationDataType.getPath())}
            onChange={(val) => {
              formState.setValue(
                ConsultationDurationDataType.getPath(),
                Number(val),
              );
            }}
          />
        </Box>
        <Box>
          <Label
            label={translate(
              "professional:onboarding.targetrate.description",
              "Welk tarief reken je aan voor een consultatie?",
            )}
          />
          <TextField
            label={translate(
              "professional:onboarding.targetrate.amount",
              "Tarief",
            )}
            type="number"
            {...formState.getInputProps(ConsultationRateDataType.getPath())}
            id={`test_onboarding_professional_rate`}
            onChange={(val) => {
              formState.setValue(
                ConsultationRateDataType.getPath(),
                Number(val),
              );
            }}
          />
          <Label
            label={translate(
              "professional:onboarding.targetrate.explainer",
              "Dit is het tarief dat gecommuniceerd wordt met de cliënt.",
            )}
          />
        </Box>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
