import { graphql } from "../__generated__";

export const MUTATION_CREATE_FULL_CALENDAR_EVENT = graphql(`
  mutation createFullCalendarEvent($input: CalendarEventInput!) {
    createCalendarEvent(input: $input) {
      ...FullCalendarEvent
    }
  }
`);

export const MUTATION_UPDATE_FULL_CALENDAR_EVENT = graphql(`
  mutation updateCalendarEvent($input: UpdateCalendarEventInput!) {
    updateCalendarEvent(input: $input) {
      ...FullCalendarEvent
    }
  }
`);

export const MUTATION_COPY_CALENDAR_EVENTS = graphql(`
  mutation copyCalendarEvents($input: CopyCalendarEventsInput!) {
    copyCalendarEvents(input: $input) {
      ...FullCalendarEvent
    }
  }
`);

export const DELETE_CALENDAR_EVENT = graphql(`
  mutation deleteCalendarEvents($input: DeleteCalendarEventsInput!) {
    deleteCalendarEvents(input: $input)
  }
`);
