import { Routes, Route } from "react-router-dom";
import LandingPage from "../pages/hr-dashboard/LandingPage";
import VideoConsultPage from "../pages/hr-dashboard/VideoConsultPage";
import WellbeingPage from "../pages/hr-dashboard/WellbeingPage";
import { RouteNames } from "./routeNames";

const HRDashboardsRoutes = () => {
  return (
    <Routes>
      <Route element={<VideoConsultPage />} path="" />
      <Route
        element={<LandingPage />}
        path={RouteNames.Home.HRDashboards.landingPage.name}
      />
      <Route
        element={<WellbeingPage />}
        path={RouteNames.Home.HRDashboards.wellbeingPage.name}
      />
    </Routes>
  );
};

export default HRDashboardsRoutes;
