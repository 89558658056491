/**
 * Phone number of user, psy or client.
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

class Phone extends DataType<string, DataTypePaths.Profile.Phone> {
  protected static path: DataTypePaths.Profile.Phone = "phone";
  protected static defaultValue = "";

  protected static phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/;

  protected type: any = Phone;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {any} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:phone.mandatory",
          "Geef een telefoon op. Gebruik enkel cijfers.",
        ),
        path: this.getPath(),
        validate: (phone: string): boolean =>
          Phone.phoneRegex.test(phone) || phone.match(/^[0-9]+$/) !== null,
      },
    ];
  }

  getAsFormStateValue(): Partial<Record<DataTypePaths.Profile.Phone, string>> {
    return { [Phone.path]: this.value };
  }
}

export default Phone;
