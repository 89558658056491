import { useTranslation } from "react-i18next";
import Specialties from "../../../../data-model/types/professional/Specialties";
import Theme from "../../../../data-model/types/professional/Theme";
import { IFormState } from "../../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import MultiGroupSelect from "../../../ui/form/select/MultiGroupSelect";

interface SpecialtiesSelectProps {
  formState: IFormState;
  pathOverride?: string;
  placeholder?: string;
  specialtiesObject: Specialties;
}

export default function SpecialtiesSelect({
  formState,
  specialtiesObject,
  pathOverride,
  placeholder,
}: SpecialtiesSelectProps) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Glossary,
  ]);

  const path: string = pathOverride
    ? pathOverride
    : specialtiesObject.getType().getPath();

  return (
    <MultiGroupSelect<string, Theme>
      closeMenuOnSelect
      options={specialtiesObject.getSelectOptions()}
      {...formState.getInputProps(path)}
      menuWithTooltip={true}
      placeholder={
        placeholder ||
        translate(
          "professional:onboarding.specialities.label",
          "Selecteer een specialiteit...",
        )
      }
    />
  );
}
