/**
 * The number of years experience a professional has.
 */
import type { TFunction } from "i18next";
import moment from "moment";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class Experience extends DataType<
  Date,
  DataTypePaths.Professional.ExperienceSince
> {
  public static path = "experienceSince";
  protected static defaultValue = new Date();
  protected type: any = Experience;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(translate: TFunction): Array<IFormValidation> {
    return [
      {
        message: translate(
          "experience.mandatory",
          "Wanneer ben je gestart met het geven van?",
        ),
        path: this.getPath(),
        validate: (e: Date): boolean => {
          return e && e <= new Date();
        },
      },
    ];
  }

  public getInYears(): number {
    return moment().diff(this.value, "years");
  }

  getAsFormStateValue(): Record<"experienceSince", Date> {
    return { experienceSince: this.value };
  }
}
