import { useQuery } from "@apollo/client";
import { graphql } from "../../../api/__generated__";
import { useSearchParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

import { useContext } from "react";
import ToastContext from "../../providers/toast/ToastContext";
import { useTranslation } from "react-i18next";
import environment from "../../../environment/environment";

const getSSOUrlQuery = graphql(`
  query SsoUrl($redirectUrl: String) {
    getSSOUrl(redirectUrl: $redirectUrl)
  }
`);

const SsoLanding = () => {
  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get("redirectUrl");

  const { setToast } = useContext(ToastContext);
  const { t } = useTranslation();

  const { data, loading, error } = useQuery(getSSOUrlQuery, {
    variables: {
      redirectUrl: encodeURI(
        redirectUrl || `${environment.REACT_APP_URL}/home`,
      ),
    },
  });

  error &&
    setToast({
      message: t("api_errors:login.failure"),
      noHide: true,
      severity: "warning",
    });

  if (data?.getSSOUrl) window.location.href = data.getSSOUrl;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        top: "40vh",
      }}
    >
      {loading && <CircularProgress />}
    </Box>
  );
};

export default SsoLanding;
