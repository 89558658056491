import Chip from "@mui/material/Chip";

interface props {
  href: string;
  label: string;
}

export default function ChipItem(props: props) {
  const { label, href } = props;

  return (
    <Chip
      clickable
      component="a"
      href={href}
      label={label}
      sx={(theme) => ({ margin: theme.spacing(0.5) })}
    />
  );
}
