/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import type { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SelectionDataType from "../../../data-model/SelectionDataType";
import { IFormState } from "../../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import ChipList from "../form/ChipList";
import { ISelectObject, isSelectObject } from "../form/select/BaseSimpleSelect";
import MultiSelect from "../form/select/MultiSelect";
import InlineEditableControls from "./common/InlineEditableControls";

export interface IEASelectableProps<T extends SelectionDataType<T, any, any>> {
  cancelBtnTestId?: string;
  chipListTestId?: string;
  dataObject: T;
  editBtnTestId?: string;
  formState: IFormState;
  label: string;
  labelTestId?: string;
  onChange?: (value: ISelectObject) => void;
  onDiscard?: (intialValue: any) => void;
  onSave?: () => void;
  placeholderText?: string | null;
  saveBtnTestId?: string;
  selectTestId?: string;

  /**
   * Format: "namespace:key"
   */
  // translationKey: string;

  /**
   * Optional function that performs translation, and should accept a "full" nexus key (namepace:key) as 'item'
   */
  translateFunction?: TFunction<I18Namespaces> | ((key: string) => string);
}

const InlineEditableMultiSelect = <T extends SelectionDataType<any, any, any>>({
  label,
  placeholderText,
  dataObject,
  formState,
  onSave,
  labelTestId,
  editBtnTestId,
  saveBtnTestId,
  cancelBtnTestId,
  chipListTestId,
}: IEASelectableProps<T>) => {
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Common]);

  const [canUpdateInitialValue, setCanUpdateInitialValue] =
    useState<boolean>(true);

  const [initialValue, setInitialValue] = useState();

  const path: string = dataObject.getType().getPath();

  useEffect(() => {
    if (canUpdateInitialValue) {
      setInitialValue(dataObject.getValue());
      setCanUpdateInitialValue(false);
    }
  });

  const selectedValues = () => {
    const selectedOptions = dataObject.getSelectedOptions(t);

    return selectedOptions.map((sO) => sO.label);
  };

  const formStateInputProps = formState.getInputProps(path);

  return (
    <Box mt={3}>
      <InlineEditableControls
        cancelBtnTestId={cancelBtnTestId}
        edit={
          <MultiSelect<ISelectObject>
            closeMenuOnSelect
            options={dataObject.getSelectOptions(t)}
            placeholder={placeholderText}
            {...formStateInputProps}
            onChange={(selected) => {
              let newValues: Array<ISelectObject> = [];

              if (selected && Array.isArray(selected)) {
                newValues = selected.map((s) =>
                  isSelectObject(s) ? s.value : s,
                );
              }

              dataObject.setValue(newValues);

              formStateInputProps.onChange(selected);
            }}
          />
        }
        editBtnTestId={editBtnTestId}
        editable={formState !== undefined}
        label={label || t("common:-", "-")}
        labelTestId={labelTestId}
        onDiscard={() => {
          if (initialValue) {
            dataObject.setValue(initialValue);

            formState.setValue(path, dataObject.getSelectedOptions(t));
            setCanUpdateInitialValue(true);
          }

          return true;
        }}
        onSave={async () => {
          if (formState.validate(path)) {
            if (onSave) onSave();

            return true;
          } else {
            return false;
          }
        }}
        read={
          <ChipList chipListTestId={chipListTestId} list={selectedValues()} />
        }
        saveBtnTestId={saveBtnTestId}
      />
    </Box>
  );
};

export default InlineEditableMultiSelect;
