import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { Suspense } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import LoadingPage from "./components/layout/LoadingPage";
import App from "./components/App";
import Providers from "./components/providers/Providers";
import ThemeProvider from "./components/providers/theme/ThemeProvider";
import ErrorPage from "./components/ui/ErrorPage";
import MaintenancePage from "./components/pages/maintenance/MaintenancePage";
import environment from "./environment/environment";
import { InitLangauge } from "./components/language/InitLangauge";

const history = createBrowserHistory();

if (environment.REACT_APP_SENTRY_DSN) {
  //TODO: extract to other script
  Sentry.init({
    dsn: environment.REACT_APP_SENTRY_DSN,
    environment: environment.REACT_APP_ENV,
    integrations: [
      Sentry.browserTracingIntegration({
        //TODO: we can expand this with parametrized routes:
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation:
          Sentry.reactRouterV5BrowserTracingIntegration(history),
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: environment.REACT_APP_SENTRY_SAMPLE_RATE,
  });
}

const Root = (
  <Sentry.ErrorBoundary
    beforeCapture={(scope, error) =>
      console.log(`Scope:${scope}, error=${error.stack}`)
    }
    fallback={<ErrorPage />}
  >
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <Suspense fallback={<LoadingPage logText="Suspense fallback" />}>
          <Providers>
            <BrowserRouter>
              <InitLangauge />
              {environment.REACT_APP_MAINTENANCE === "true" ? (
                <MaintenancePage />
              ) : (
                <App history={history} />
              )}
            </BrowserRouter>
          </Providers>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  </Sentry.ErrorBoundary>
);

console.log("root");

ReactDOM.render(Root, document.getElementById("root"));
