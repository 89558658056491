import { useContext } from "react";
import OnlineContext from "../context/OnlineContext";
import { CHANNEL_PRESENCE_WAITING_ROOM } from "../channels";
import { IOnlineContext } from "../context/onlineContext.s";
import Presence, { SpecificPresenceProps } from "./Presence";

export default function WaitingRoomPresence({
  children,
}: SpecificPresenceProps) {
  const { setWaitingRoomChannel } = useContext<IOnlineContext>(OnlineContext);

  const waitingRoomUserAdded = () => {}; //console.log('New user in waiting room:', user);
  const waitingRoomUserRemoved = () => {}; // console.log('A user left the waiting room:', user);
  const subscribed = (channel) => {
    //console.log(`Subscribed to channel: ${CHANNEL_PRESENCE_WAITING_ROOM}`);
    setWaitingRoomChannel(channel);
  };

  return (
    <Presence
      channel={CHANNEL_PRESENCE_WAITING_ROOM}
      memberAdded={waitingRoomUserAdded}
      memberRemoved={waitingRoomUserRemoved}
      subscribedToChannel={subscribed}
    >
      {children}
    </Presence>
  );
}
