import { createContext } from "react";
import { IProfessionalRegistrationContext } from "./professionalRegistration";

export const defaultProfessionalRegistrationValues: IProfessionalRegistrationContext =
  {
    acceptedConsent: false,
    hasUploadError: false,
    isRegistering: false,
    next: () => {},
    previous: () => {},
    registerProfessional: () => {},
    setAcceptedConsent: () => {},
  };

const ProfessionalRegistrationContext =
  createContext<IProfessionalRegistrationContext>(
    defaultProfessionalRegistrationValues,
  );

export default ProfessionalRegistrationContext;
