import { useCallback, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { MUTATION_PROFESSIONAL_UPDATE } from "../mutations";
import ToastContext from "../../../components/providers/toast/ToastContext";
import { IToastContext } from "../../../components/providers/toast/toast";
import { ProfessionalInput } from "../../__generated__/graphql";

const useUpdateProfessionalMutation = () => {
  const { t } = useTranslation();
  const { setToast } = useContext<IToastContext>(ToastContext);

  const [updateProfessionalFunction, { data, loading, error }] = useMutation(
    MUTATION_PROFESSIONAL_UPDATE,
    {
      onError: () => {
        setToast({
          message: t("profile.update.failed"),
          severity: "error",
        });
      },
    },
  );

  const updateProfessional = useCallback(
    async (input: ProfessionalInput): Promise<void> => {
      await updateProfessionalFunction({ variables: { input } });
    },
    [updateProfessionalFunction],
  );

  return {
    error,
    loading,
    professional: data?.updateProfessional,
    updateProfessional,
  };
};

export default useUpdateProfessionalMutation;
