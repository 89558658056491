import * as PusherTypes from "pusher-js";
import { useState } from "react";
import Presence, { SpecificPresenceProps } from "./Presence";

const WAITING_ROOM_CHANNEL = "presence-waiting-room";

interface SpecificWaitingRoomPresenceProps extends SpecificPresenceProps {
  userAddedCallback?: (user) => void;
  userRemovedCallback?: (user) => void;
  uuid: string;
}

export default function SpecificWaitingRoomPresence({
  children,
  uuid,
  userAddedCallback,
  userRemovedCallback,
}: SpecificWaitingRoomPresenceProps) {
  /*eslint-disable @typescript-eslint/no-unused-vars */
  const [channel, setChannel] = useState<PusherTypes.PresenceChannel>();

  const userAdded = (user) => {
    if (userAddedCallback) userAddedCallback(user);
  };

  const userRemoved = (user) => {
    if (userRemovedCallback) userRemovedCallback(user);
  };

  const subscribed = (channel) => {
    setChannel(channel);
  };

  return (
    <Presence
      channel={`${WAITING_ROOM_CHANNEL}-${uuid}`}
      memberAdded={userAdded}
      memberRemoved={userRemoved}
      subscribedToChannel={subscribed}
    >
      {children}
    </Presence>
  );
}
