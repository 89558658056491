import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";
import Organization from "../Organization";
import Professional from "../professional/Professional";
import HealthInsurance from "../reimbursement/HealthInsurance";
import User, { UserRoles } from "../User";
import { ID, APIUser } from "../../../api/api";
import { APIHuman } from "../../../api/humans/humans";
import { APIProfessional } from "../../../api/professionals/professionals";
import PreferredGender from "./PreferredGender";

export enum HumanStatus {
  CURRENT = "CURRENT",
  INVITED = "INVITED",
  ONLY_INTRO = "ONLY_INTRO",
  PENDING = "PENDING",
}

class Human extends User<DataTypePaths.HumanPath> {
  protected type: any = Human;

  protected preferredGender: PreferredGender = new PreferredGender();
  protected recurring = false;
  protected status: HumanStatus = HumanStatus.CURRENT;
  protected description = "";
  protected contactForFeedback = false;
  protected professionals: Array<Professional> = [];
  protected creditsLeft?: number;
  protected healthIsurance: HealthInsurance = new HealthInsurance();
  public clientID?: ID;
  public canPayWithCredits = false;

  constructor(
    model?: APIHuman.GeneralizedResult,
    fromGQL = false,
    isOrganizationAdmin = false,
  ) {
    super(model, fromGQL);

    this.setRole(
      isOrganizationAdmin ? UserRoles.ORGANIZATION_ADMIN : UserRoles.HUMAN,
    );

    if (!model) return;

    if (model.canPayWithCredits) {
      this.canPayWithCredits = model.canPayWithCredits;
    }

    this.preferredGender.setValue(model[PreferredGender.getPath()], fromGQL);

    if (model.status) {
      this.setStatus(model.status as HumanStatus);
    }

    if (model.description) {
      this.setDescription(model.description);
    }

    // This is the id of the client in the clients table.
    if (model.clientID) {
      this.clientID = model.clientID;
    }

    if (model.contactForFeedback) {
      this.contactForFeedback = model.contactForFeedback;
    }

    if (model.professionals) {
      this.professionals = model.professionals.map(
        (p: APIProfessional.Result) => new Professional(p),
      );
    }

    if (model.creditsLeft !== undefined) this.creditsLeft = model.creditsLeft;

    if (model.organization)
      this.organization = new Organization(model.organization);

    if (model.healthInsurance) {
      this.healthIsurance = new HealthInsurance(model.healthInsurance);
    }
  }

  /**
   * Get the client's preferred gender for a professional as an object.
   * You can call .getValue or .prettyPrint from this to get it textual.
   */
  public getPreferredGender(): PreferredGender {
    return this.preferredGender;
  }

  /**
   * Set the set the preferred gender object of this professional.
   * If you want to change the bio of an existing professional
   * call getGender() and setValue on the returning value.
   */
  public setPreferredGender(gender: PreferredGender): Human {
    this.preferredGender = gender;

    return this;
  }

  /**
   * Set the status of this client.
   * Can ben any value of ClientStatus.
   */
  public setStatus(status: HumanStatus): Human {
    this.status = status;

    return this;
  }

  /**
   * Get the client's status as a prop of ClientStatus.
   */
  public getStatus(): HumanStatus {
    return this.status;
  }

  /**
   * Set the description of this client.
   * TODO: This should be removed and handled differently.
   */
  public setDescription(description: string): Human {
    this.description = description;

    return this;
  }

  /**
   * Get the client's description.
   */
  public getDescription(): string {
    return this.description;
  }

  /**
   * Return an object containing the values to be used
   * as the values in the formState.
   */
  public getAsFormStateValues(translate: TFunction<I18Namespaces>): any {
    return {
      ...super.getAsFormStateValues(translate),
      // ...this.bio.getAsFormStateValue(),
    };
  }

  public getOrganization(): Organization {
    return this.organization;
  }

  /**
   * Set the status of this client.
   * Can ben any value of ClientStatus.
   */
  public setContactForFeedback(contactForFeedback: boolean): Human {
    this.contactForFeedback = contactForFeedback;

    return this;
  }

  /**
   * Get the client's status as a prop of ClientStatus.
   */
  public getContactForFeedback(): boolean {
    return this.contactForFeedback;
  }

  public getProfessionals(): Array<Professional> {
    return this.professionals;
  }

  /**
   * Is this user a recurring client?
   * A user that has just gone through onboarding without a full profile is not.
   *
   * Giving it a boolean value as first param will set it.
   */
  public isRecurring(rec?: boolean): boolean {
    if (rec !== undefined) this.recurring = rec;

    return this.recurring;
  }

  /**
   *
   * @returns the amount of credits that this person has got left. If undefined, all credits are
   * available
   */
  public getCreditsLeft(): number | undefined {
    return this.creditsLeft;
  }

  /**
   * Get the data type object itself.
   * Useful to call static members without import after passing it around.
   */
  public getType(): any {
    return this.type;
  }

  /**
   * Get the client ID of this human.
   */
  public getClientID(): number | undefined {
    return this.clientID;
  }

  public getHealthInsurance(): HealthInsurance {
    return this.healthIsurance;
  }

  getAsFormStateValue(): Partial<
    Record<PossibleFormStatePaths, APIUser.GeneralizedResult>
  > {
    return { human: this.value };
  }

  static isHuman(
    possibleHuman: Human | Professional | User,
  ): possibleHuman is Human {
    if (possibleHuman === null || possibleHuman === undefined) return false;

    return (
      possibleHuman.getRole() === UserRoles.HUMAN ||
      possibleHuman.getRole() === UserRoles.ORGANIZATION_ADMIN
    );
  }
}

export default Human;
