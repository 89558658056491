import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class Website extends DataType<
  string | undefined,
  DataTypePaths.Professional.Website
> {
  protected static path = "website";
  protected static defaultValue = "";
  protected static websiteRegex =
    /^((https?|ftp|smtp):\/\/)?([a-z0-9]+\.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#.]+)*(\/?$)/;

  protected type: any = Website;

  static validateWebsite(website: string) {
    return website === "" || Website.websiteRegex.test(website);
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param translate
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "website.correctformat",
          "Gelieve een geldig webadres in te voeren. (vb: http://www.bloomup.org)",
        ),
        path: this.getPath(),
        validate: Website.validateWebsite,
      },
    ];
  }

  getAsFormStateValue(): Record<"website", string | undefined> {
    return { website: this.value };
  }
}
