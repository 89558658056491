import { useMutation } from "@apollo/client";
import { graphql } from "../../../api/__generated__";
import { useSearchParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect } from "react";
import AuthContext from "../../providers/auth/AuthContext";
import { useTranslation } from "react-i18next";
import ToastContext from "../../providers/toast/ToastContext";
import environment from "../../../environment/environment";

const loginWithSSOMutation = graphql(`
  mutation LoginWithSso($input: LoginWithSSOInput!) {
    loginWithSSO(input: $input) {
      ... on TokenResponse {
        accessToken
        mindlabToken
        refreshToken
      }
    }
  }
`);

const SsoRedirect = () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");
  const redirectUrl = searchParams.get("redirectUrl");
  const { internalAppLogin } = useContext(AuthContext);

  const { t } = useTranslation();

  const { setToast, close: closeToast } = useContext(ToastContext);

  const [loginWithSSO, { loading }] = useMutation(loginWithSSOMutation, {
    async onCompleted(data) {
      if (data.loginWithSSO.__typename === "TokenResponse") {
        // Temporary fallback while mindlabToken is optional on the graph
        if (!data.loginWithSSO.accessToken || !data.loginWithSSO.refreshToken) {
          setToast({
            message: t("api_errors:login.failure"),
            noHide: true,
            severity: "warning",
          });

          return;
        }

        closeToast();

        await internalAppLogin(
          data.loginWithSSO.accessToken,
          data.loginWithSSO.refreshToken,
        );

        if (redirectUrl && redirectUrl.includes(environment.REACT_APP_URL))
          window.location.href = redirectUrl;
      }
    },
  });

  //The useEffect is a workaround to have the function run async at render
  useEffect(() => {
    if (redirectUrl) {
      const loginFunc = async () => {
        await loginWithSSO({
          variables: {
            input: {
              authorizationCode: code || "",
              redirectUrl: redirectUrl,
            },
          },
        });
      };

      loginFunc();
    }
  }, [code, loginWithSSO, redirectUrl]);

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        position: "relative",
        top: "40vh",
      }}
    >
      {loading && <CircularProgress />}
    </Box>
  );
};

export default SsoRedirect;
