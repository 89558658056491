/**
 * Bio of a psy.
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";

export const DESCRIPTION_PREFIX = "description";

const DESCRIPTION_MIN_LENGTH = 50;

const DESCRIPTION_MAX_LENGTH = 300;

export default class Description extends DataType<
  string,
  DataTypePaths.Professional.Description
> {
  //protected static path = "description";
  protected static defaultValue = "";
  protected type: any = Description;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @param pathToValidate
   * @return {Array<unknown>}
   */

  static validate(
    value: string | undefined,
    translate: TFunction<I18Namespaces>,
    key: string | undefined = value,
  ): Record<string, string> {
    if (!key) {
      console.warn("'undefined' was supplied as a key for validation");

      return {};
    }

    const errors: Record<string, string> = {};

    if (!value || value.trim() === "") {
      errors[key] = translate(
        "validation:profile.mandatory",
        "Geef een korte beschrijving van jezelf op.",
      );
    } else if (value.length < DESCRIPTION_MIN_LENGTH) {
      errors[key] = `${translate(
        "validation:profile.minlength",
        `Je beschrijving mag niet korter zijn dan`,
      )} ${DESCRIPTION_MIN_LENGTH} ${translate(
        "validation:profile.characters",
        "karakters",
      )}.`;
    } else if (value.length > DESCRIPTION_MAX_LENGTH) {
      errors[key] = `${translate(
        "validation:profile.maxlength",
        `Je beschrijving mag niet langer zijn dan`,
      )} ${DESCRIPTION_MAX_LENGTH} ${translate(
        "validation:profile.characters",
        "karakters",
      )}.`;
    }

    return errors;
  }

  // The Description 'path' is dependent on the language key that is its suffix ("description-langKey" --> "description-nlBE"),
  // and thus is not static, so it needs to be sent in the second param to be validated.
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
    pathToValidate: string,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "validation:profile.mandatory",
          "Geef een korte beschrijving van jezelf op.",
        ),
        path: pathToValidate,
        validate: (profile: any): boolean => {
          if (!profile) return false;

          if (typeof profile !== "string") {
            console.warn(
              "Description validation: Value received for profile was not a string",
            );

            return false;
          }

          return !!profile && profile.trim() !== "";
        },
      },
      {
        message: `${translate(
          "validation:profile.maxlength",
          `Je beschrijving mag niet langer zijn dan`,
        )} ${DESCRIPTION_MAX_LENGTH} ${translate(
          "validation:profile.characters",
          "karakters",
        )}.`,
        path: pathToValidate,
        validate: (profile: string): boolean =>
          profile.length <= DESCRIPTION_MAX_LENGTH,
      },
      {
        message: `${translate(
          "validation:profile.minlength",
          `Je beschrijving mag niet korter zijn dan`,
        )} ${DESCRIPTION_MIN_LENGTH} ${translate(
          "validation:profile.characters",
          "karakters",
        )}.`,
        path: pathToValidate,
        validate: (profile: string): boolean =>
          profile.length > DESCRIPTION_MIN_LENGTH,
      },
    ];
  }

  getAsFormStateValue(): Partial<Record<PossibleFormStatePaths, string>> {
    return { description: this.value };
  }
}
