/* eslint-disable react-hooks/exhaustive-deps */

import moment from "moment";
import "moment/locale/nl";
import { useContext, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { AlertColor } from "@mui/material";
import useCheckIfHumanIsAClient from "../../../../api/clients/hooks/useCheckIfHumanIsAClient";
import useInviteClientMutation from "../../../../api/clients/hooks/useInviteClientMutation";
import useCreateConsultation from "../../../../api/consultations/hooks/useCreateConsultation";
import { IWithChildren } from "../../../../baseInterfaces";
import Consultation from "../../../../data-model/types/consultation/Consultation";
import ConsultationStatus from "../../../../data-model/types/consultation/ConsultationStatus";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import { IToastContext } from "../../../providers/toast/toast";
import ToastContext from "../../../providers/toast/ToastContext";
import { ChimeVideoCall } from "../../../ui/call/ChimeVideoCall.types";
import { ICallContext } from "../../../ui/call/context/Call";
import CallContext from "../../../ui/call/context/CallContext";
import useAddConsultationFormState from "../../add-consultation/useAddConsultationFormState";
import AddConsultationInCallContext from "./AddConsultationInCallContext";

interface AddConsultationInCallProviderProps extends IWithChildren {
  consultation: Consultation;
  professionalConsultations: Consultation[] | undefined;
}

const AddConsultationInCallProvider = ({
  consultation,
  professionalConsultations,
  children,
}: AddConsultationInCallProviderProps) => {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
    BloomUpNamespacesEnum.Errors,
  ]);

  const human = consultation.getClient();
  const professional = consultation.getProfessional();
  const organization = human.getOrganization();
  const isAClient = useCheckIfHumanIsAClient(human.getID()).isAClient;

  const { setToast } = useContext<IToastContext>(ToastContext);
  const { setPaneToPresent } = useContext<ICallContext>(CallContext);
  const { inviteClient } = useInviteClientMutation();
  const { createConsultation, loading: isBooking } = useCreateConsultation();

  const startTime: moment.Moment = moment();

  const newConsult = new Consultation();

  newConsult.setStartDate(startTime);
  newConsult.setEndDate(
    startTime
      .clone()
      .add(professional.getConsultationDuration().getValue(), "minutes"),
  );

  const formState = useAddConsultationFormState({
    consultations: professionalConsultations,
    duration: professional.getConsultationDuration().getValue(),
    price: professional.getConsultationRate().getValue(),
    pricePerMinute:
      professional.getConsultationRate().getValue() /
      professional.getConsultationDuration().getValue(),
  });

  useEffect(() => {
    if (formState)
      formState.setValue(
        "timeTo",
        moment().add(
          professional.getConsultationDuration().getValue(),
          "minutes",
        ),
      );
  }, []);

  async function createNewConsult(): Promise<Consultation | undefined> {
    try {
      //If this person is not already a client, invite him / her first.
      if (isAClient !== undefined && !isAClient) {
        await inviteClient({
          email: human.getEmail().getValue(),
        });
      }

      newConsult
        .setStartDate(formState.getValue("timeFrom"))
        .setEndDate(formState.getValue("timeTo"))
        .setStatus(new ConsultationStatus("ACCEPTED"))
        .setFromMatch(false)
        .setRequestedByClient(false)
        .assignProfessional(consultation.getProfessional())
        .assignClient(human);

      const newlyCreatedConsult = await createConsultation(
        newConsult,
        false,
        formState.getValue("price"),
      );

      if (newlyCreatedConsult) {
        setToast({
          message: t("ui:toast.consultation.created"),
          severity: "success",
        });
        setPaneToPresent(ChimeVideoCall.SidePaneProfessional.menuPane);
      }

      return newlyCreatedConsult;
    } catch (error: any) {
      let message: string;
      let severity: AlertColor = "warning";

      if (error.message === "create_consultation_unpaid_consultations") {
        setToast({
          message: t("errors:create_consultation_unpaid_consultations_prof", {
            name:
              human.getFirstName().getValue() +
              " " +
              human.getLastName().getValue(),
          }),
          severity: "error",
        });

        return;
      }

      if (error.message.includes("already exists")) {
        message = t("validation:consultation.conflict");
      } else {
        message = t("validation:consultation.error");
        severity = "error";
      }

      setToast({ message, severity });
    }
  }

  return (
    <AddConsultationInCallContext.Provider
      value={{
        createNewConsult,
        currentConsultation: consultation,
        formState,
        human,
        isBooking,
        newConsult,
        organization,
        professional,
      }}
    >
      {children}
    </AddConsultationInCallContext.Provider>
  );
};

export default AddConsultationInCallProvider;
