/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAnswerQuestion from "../../api/qualityQuestions/hooks/useAnswerQuestion";
import useFetchQualityQuestionById from "../../api/qualityQuestions/hooks/useFetchQualityQuestionById";
import useFetchQualityQuestionForUserByEntrypoint from "../../api/qualityQuestions/hooks/useFetchQualityQuestionForUserByEntrypoint";
import { APIQualityQuestions } from "../../api/qualityQuestions/qualityQuestions";
import useQueryParams, {
  getParamAsBoolean,
  getParamAsNumber,
  getParamAsString,
} from "../hooks/useQueryParams";
import LoadingPage from "../layout/LoadingPage";
import { RouteNames } from "../routes/routeNames";
import QualityQuestionImplementation from "./QualityQuestionImplementation";
import { QualityQuestions } from "./QualityQuestions.types";

const isEntryPoint = (
  toBeDetermined: QualityQuestions.Entrypoints | any,
): toBeDetermined is QualityQuestions.Entrypoints => {
  if (isNaN(toBeDetermined)) {
    return !!QualityQuestions.stringToEntryPoint.get(toBeDetermined);
  }

  return false;
};

const QualityQuestionFetcher = () => {
  const { entrypointOrId } = useParams();

  const { redirectPath, thankyouPage, consultationId } = useQueryParams();

  const consultationIdAsNumber = getParamAsNumber(consultationId);

  let redirectPathAsString = getParamAsString(redirectPath);

  const thanYouPageAsBoolean = getParamAsBoolean(thankyouPage);

  if (!redirectPathAsString) {
    redirectPathAsString = RouteNames.Home.path;
  }

  const navigate = useNavigate();
  const { fetchQuestionById, loading: loadingById } =
    useFetchQualityQuestionById();

  const { fetchQuestionForUserByEntrypoint, loading: loadingByEntrypoint } =
    useFetchQualityQuestionForUserByEntrypoint();

  const { answerQuestion } = useAnswerQuestion();
  const [question, setQuestion] = useState<
    APIQualityQuestions.Question | undefined | null
  >(undefined);

  useEffect(() => {
    // There isn't a question left

    if (question === null && !loadingById && !loadingByEntrypoint) {
      navigate(
        redirectPathAsString ? redirectPathAsString : RouteNames.Home.path,
      );
    }
  }, [question, loadingByEntrypoint, loadingById, redirectPathAsString]);

  useEffect(() => {
    const getQuestionByEntryPoint = async (
      entrypoint: QualityQuestions.Entrypoints,
    ) => {
      const { data } = await fetchQuestionForUserByEntrypoint(entrypoint);

      if (data?.questionForUserAtEntryPoint) {
        setQuestion(data.questionForUserAtEntryPoint);
      } else {
        setQuestion(null);
      }
    };

    const getQuestionById = async (id: number) => {
      const { data } = await fetchQuestionById(id);

      console.log("Fetched new question with data:", data);

      if (data.getQuestionById) {
        setQuestion(data.getQuestionById);
      } else {
        setQuestion(null);
      }
    };

    if (entrypointOrId) {
      if (isEntryPoint(entrypointOrId)) {
        const ep: QualityQuestions.Entrypoints | undefined =
          QualityQuestions.stringToEntryPoint.get(entrypointOrId);

        if (ep) getQuestionByEntryPoint(ep);
      } else {
        const id = Number(entrypointOrId);

        if (!isNaN(id)) getQuestionById(id);
        else console.warn(`${id} is not an entrypoint or an id!`);
      }
    }
    // What do we do here?
  }, [
    entrypointOrId,
    redirectPath,
    getParamAsString,
    fetchQuestionForUserByEntrypoint,
    fetchQuestionById,
  ]);

  if (loadingById || loadingByEntrypoint) return <LoadingPage />;

  if (!question) return <LoadingPage />;

  return (
    <QualityQuestionImplementation
      category={question.category}
      component={question.component}
      id={question.id}
      nextQuestionId={question.nextQuestionId}
      onAnswerQuestion={(answer) => {
        answerQuestion(
          question.id,
          Array.isArray(answer) ? answer : [answer],
          consultationIdAsNumber,
        );
      }}
      options={question.options}
      questionType={question.questionType}
      redirectPathAfterQuestions={redirectPathAsString}
      showThankyouPage={thanYouPageAsBoolean}
      title={question.title}
      titleTranslationKey={question.translationKey}
    />
  );
};

export default QualityQuestionFetcher;
