import Consultation from "../../../data-model/types/consultation/Consultation";

export const CHANNEL_PRESENCE_ALL = "presence-all-channel";

export const CHANNEL_PRESENCE_CLIENT = "presence-client-channel";

export const CHANNEL_PRESENCE_PROFESSIONAL = "presence-professional-channel";

export const CHANNEL_PRESENCE_WAITING_ROOM =
  "presence-all-waiting-room-channel";

export function constructPaymentChannelName(
  consultation: Consultation,
): string {
  return `payment-${consultation.getUUID()}`;
}
