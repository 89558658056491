import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import EventIcon from "@mui/icons-material/Event";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
import Clients from "../../icons/clients";
import { I18Namespaces } from "../../language/I18Namespaces";
import { RouteNames } from "../../routes/routeNames";
import SidebarNavigationItem from "./SidebarNavigationItem";

const PREFIX = "ProfessionalSidebarNavigation";

const classes = {
  actions: `${PREFIX}-actions`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.actions}`]: {
    alignItems: "flex-start",
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(1),
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      background: theme.palette.white.main,
      bottom: 0,
      boxShadow: "0 -1px 7px 0 rgba(181,181,181,0.50)",
      left: 0,
      padding: 0,
      position: "fixed",
      right: 0,
      width: "100%",
      zIndex: 2,
    },
  },

  [`& .${classes.list}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    padding: 0,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      flexFlow: "row nowrap",
      margin: 0,
    },
  },
}));

export default function ProfessionalSidebarNavigation() {
  const { t: translate } = useTranslation<I18Namespaces>([
    "common",
    "professional",
  ]);

  return (
    <Root className={classes.actions}>
      <ul className={classes.list}>
        <SidebarNavigationItem
          href={RouteNames.Home.Consultations.path}
          icon={<RecentActorsOutlinedIcon />}
          id="test_sidebar_button_consultations"
          label={translate("common:consultations", "Consultaties")}
        />
        <SidebarNavigationItem
          href={"/home/completed-consultations"}
          icon={<RecentActorsIcon />}
          id="test_sidebar_button_consultations"
          label={translate("professional:completedConsultations.title")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Availability.path}
          icon={<EventIcon />}
          id="test_sidebar_button_availability"
          label={translate("common:availability", "Beschikbaarheid")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Clients.path}
          icon={<Clients />}
          id="test_sidebar_button_clients"
          label={translate("common:clients", "Cliënteel")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Community.path}
          icon={<ForumOutlinedIcon />}
          id="test_sidebar_button_community"
          isExternalLink={true}
          label={translate("common:community", "Community")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Colleagues.path}
          icon={<FaceOutlinedIcon />}
          id="test_sidebar_button_colleagues"
          label={translate("common:colleagues", "Collega's")}
        />
      </ul>
    </Root>
  );
}
