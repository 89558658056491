import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";

import OnboardingContainer from "../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../layout/wizard/OnboardingStepContainer";
import TextField from "../ui/form/TextField";
import Label from "../ui/form/FormLabel";
import Button from "../ui/buttons/Button";
import ListItemWithIcon from "../ui/lists/ListItemWithIcon";
import FAQAccordionItem, { IFAQData } from "../ui/FAQAccordionItem";
import FAQAccordion from "../ui/FAQAccordion";
import CircleWithNumber from "../ui/lists/CircleWithNumber";
import NationalId from "../../data-model/types/human/NationalId";
import { IToastContext } from "../providers/toast/toast";
import ToastContext from "../providers/toast/ToastContext";
import { IPaymentContext } from "./context/payment";
import PaymentContext from "./context/PaymentContext";

const PREFIX = "ReimbursementPage";

const classes = {
  button1Container: `${PREFIX}-button1Container`,
  button2Container: `${PREFIX}-button2Container`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  conditions: `${PREFIX}-conditions`,
  description1: `${PREFIX}-description1`,
  description2: `${PREFIX}-description2`,
  iconListItem: `${PREFIX}-iconListItem`,
  leftSide: `${PREFIX}-leftSide`,
  nationalNumberContainer: `${PREFIX}-nationalNumberContainer`,
  rightSide: `${PREFIX}-rightSide`,
  root: `${PREFIX}-root`,
  stepContainer: `${PREFIX}-stepContainer`,
  stepText: `${PREFIX}-stepText`,
  title1: `${PREFIX}-title1`,
  title2: `${PREFIX}-title2`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  [`& .${classes.rightSide}`]: { flex: 1, margin: theme.spacing(5) },
  [`& .${classes.leftSide}`]: { flex: 1, margin: theme.spacing(5) },
  [`& .${classes.title1}`]: { marginBottom: theme.spacing(4) },
  [`& .${classes.description1}`]: { marginBottom: theme.spacing(2) },
  [`& .${classes.conditions}`]: { marginBottom: theme.spacing(2) },
  [`& .${classes.description2}`]: { marginBottom: theme.spacing(4) },
  [`& .${classes.title2}`]: { marginBottom: theme.spacing(4) },

  [`& .${classes.stepContainer}`]: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },

  [`& .${classes.stepText}`]: {
    marginLeft: 15,
  },

  [`& .${classes.nationalNumberContainer}`]: {
    backgroundColor: theme.palette.bloom.light,
    borderRadius: 14,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(5),
    position: "relative",
  },

  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.button1Container}`]: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.button2Container}`]: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },

  [`& .${classes.iconListItem}`]: {
    color: theme.palette.bloom.main,
    fontSize: "small",
  },
}));

export default function ReimbursementPage() {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Payments,
  ]);

  const { formState, requestReimbursement, cancelSignup } =
    useContext<IPaymentContext>(PaymentContext);

  const { setToast } = useContext<IToastContext>(ToastContext);

  const { uuid } = useParams();

  const onClickHandlerRequestReimbursement = useCallback(() => {
    if (uuid) {
      requestReimbursement(uuid);
    } else {
      setToast({
        message: "Something went wrong",
        severity: "error",
      });
    }
  }, [uuid, requestReimbursement, setToast]);

  const questions: Array<IFAQData> = useMemo(
    () => [
      {
        answer: t("payments:reimbursement.page.faq.nomorereimbursement.answer"),
        question: t(
          "payments:reimbursement.page.faq.nomorereimbursement.question",
        ),
      },
      {
        answer: t("payments:reimbursement.page.faq.cancellation.answer"),
        question: t("payments:reimbursement.page.faq.cancellation.question"),
      },
      {
        answer: t("payments:reimbursement.page.faq.nationalid.answer"),
        question: t("payments:reimbursement.page.faq.nationalid.question"),
      },
      {
        answer: t("payments:reimbursement.page.faq.datasharing.answer"),
        question: t("payments:reimbursement.page.faq.datasharing.question"),
      },
    ],
    [t],
  );

  const error = formState.testForErrors(NationalId.getPath());

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        fullContent={true}
        fullCopyright
        headline={t("payments:payment")}
        showLanguageSelector={false}
      >
        <StyledBox className={classes.root}>
          <Box className={classes.leftSide}>
            <Typography className={classes.title1} variant="h2">
              {t("payments:health.insurance.signup.title1")}
            </Typography>

            <Typography className={classes.description1}>
              {t("payments:health.insurance.signup.description1")}
            </Typography>
            <Box className={classes.conditions}>
              <ListItemWithIcon
                text={t("payments:health.insurance.signup.list.item1")}
              />
              <ListItemWithIcon
                text={t("payments:health.insurance.signup.list.item2")}
              />
              <ListItemWithIcon
                text={t("payments:health.insurance.signup.list.item3")}
              />
            </Box>

            <Typography className={classes.description2}>
              {t("payments:health.insurance.signup.description2")}
            </Typography>
            <Typography className={classes.title2} variant="h2">
              {t("payments:health.insurance.signup.title2")}
            </Typography>
            <Box>
              <FAQAccordion>
                {questions.map((question) => {
                  return (
                    <FAQAccordionItem
                      answer={question.answer}
                      key={question.question}
                      question={question.question}
                    />
                  );
                })}
              </FAQAccordion>
            </Box>
          </Box>

          <Box className={classes.rightSide}>
            <Typography variant="h2">
              {t("payments:health.insurance.signup.title3")}
            </Typography>
            <Box className={classes.stepContainer}>
              <CircleWithNumber number={1} />
              <Typography className={classes.stepText}>
                {t("payments:health.insurance.signup.step1")}
              </Typography>
            </Box>
            <Box className={classes.nationalNumberContainer}>
              <Label
                label={t("common:nationalnumber")}
                style={{
                  marginBottom: 10,
                  marginTop: 0,
                }}
              />
              <TextField
                placeholder={
                  t(
                    "payments:health.insurance.signup.textfield.nationalnumber.placeholder",
                  ) ?? undefined
                }
                {...formState.getInputProps(NationalId.getPath())}
              />
            </Box>

            <Box className={classes.stepContainer}>
              <CircleWithNumber number={2} />
              <Typography className={classes.stepText}>
                {t("payments:health.insurance.signup.step2")}
              </Typography>
            </Box>
            <Box className={classes.stepContainer}>
              <CircleWithNumber number={3} />
              <Typography className={classes.stepText}>
                {t("payments:health.insurance.signup.step3")}
              </Typography>
            </Box>
            <Box className={classes.buttonContainer}>
              <Box className={classes.button1Container}>
                <Button
                  disabled={error}
                  label={t("payments:health.insurance.signup.button.askfor")}
                  onClick={onClickHandlerRequestReimbursement}
                />
              </Box>
              <Box className={classes.button2Container}>
                <Button
                  label={t("payments:health.insurance.signup.button.no.signup")}
                  onClick={cancelSignup}
                />
              </Box>
            </Box>
          </Box>
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
