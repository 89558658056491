/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import FormLabel from "../form/FormLabel";
import RadioQuestion from "../form/RadioQuestion";
import TextField from "../form/TextField";
import ConsultationRate from "../../../data-model/types/consultation/ConsultationRate";
import ConsultationDuration from "../../../data-model/types/consultation/ConsultationDuration";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import InlineEditableControls from "./common/InlineEditableControls";

interface IERateProps {
  durationDataObject: ConsultationDuration;
  formState?: any;
  label: string;
  onSave: () => Promise<void>;
  rateDataObject: ConsultationRate;
}

const InlineEditableRate = ({
  label,
  rateDataObject,
  durationDataObject,
  onSave,
  formState,
}: IERateProps) => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Professional,
  ]);

  const [rate, setRate] = useState<number>(rateDataObject.getValue());
  const [duration, setDuration] = useState<number>(
    durationDataObject.getValue(),
  );

  useEffect(() => {
    setDuration(durationDataObject.getValue());
  }, [durationDataObject]);

  const standardRateLine = translate("professional:profile.rateline", {
    defaultValue: "{{rate}} EUR voor {{duration}} minuten",
    duration: duration ? duration : "-",
    rate: rate ? rate : "-",
  });

  const pathRate: string = useMemo(() => {
    return rateDataObject.getType().getPath();
  }, [rateDataObject]);

  const pathDuration: string = useMemo(() => {
    return durationDataObject.getType().getPath();
  }, []);

  const save = useCallback(async () => {
    // TODO figure out why setValue on formstate doesnt work and this does
    formState.values[pathRate] = rate;
    formState.values[pathDuration] = duration;

    if (formState.validate(pathRate) && formState.validate(pathDuration)) {
      rateDataObject.setValue(rate);
      durationDataObject.setValue(duration);

      setRate(rate);
      setDuration(duration);

      await onSave();

      return true;
    } else {
      return false;
    }
  }, [rate, duration]);

  return (
    <InlineEditableControls
      edit={
        <Box>
          <Box mb={4}>
            <FormLabel
              component="legend"
              label={translate(
                "professional:onboarding.duration",
                "Mijn consultaties duren...",
              )}
            />
            <RadioQuestion
              onChange={(val) => {
                setDuration(Number(val));
              }}
              options={durationDataObject.getType().getSelectOptions(translate)}
              value={duration}
              {...(formState ? formState.getValidationProps(pathDuration) : {})}
            />
          </Box>
          <Box>
            <FormLabel
              label={translate(
                "professional:onboarding.targetrate.description",
                "Welk tarief reken je aan voor een consultatie?",
              )}
            />
            <TextField
              label={translate(
                "professional:onboarding.targetrate.amount",
                "Tarief",
              )}
              type="number"
              value={rate || translate("common:-", "-")}
              {...(formState ? formState.getValidationProps(pathRate) : {})}
              onChange={(val) => setRate(Number(val))}
            />
            <FormLabel label="Je tarief wordt enkel gedeeld met het BloomUp team. Jij moet zelf je tarief communiceren met de klant." />
          </Box>
        </Box>
      }
      editable={formState !== undefined}
      label={label || translate("common:-", "-")}
      onDiscard={() => {
        // TODO figure out why setValue on formstate doesnt work and this does
        formState.values[pathRate] = rateDataObject.getValue();
        formState.values[pathDuration] = durationDataObject.getValue();

        if (formState.validate(pathRate) && formState.validate(pathDuration)) {
          setRate(rateDataObject.getValue());
          setDuration(durationDataObject.getValue());

          return true;
        } else {
          return false;
        }
      }}
      onSave={save}
      read={standardRateLine}
    />
  );
};

export default InlineEditableRate;
