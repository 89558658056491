import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import useFormState from "../../hooks/useFormState";
import createFormValidation from "../../../utils/forms/createFormValidation";
import { I18Namespaces } from "../../language/I18Namespaces";
import Email from "../../../data-model/types/profile/Email";

const INITIAL_VALUES = {
  ...Email.getInitialValue(),
};

export default function useAddClientFormState(
  initialValues?: any,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(["validation"]);

  const validation = useMemo(
    () =>
      createFormValidation([
        ...Email.getFormValidation(translate),
        {
          message: translate(
            "clients.add.yourself",
            "Je kan jezelf niet als client toevoegen.",
          ),
          path: "email",
          validate: (email, { ownEmail }) => email !== ownEmail,
        },
        {
          message: translate("clients.add.exists", "Deze client bestaat al."),
          path: "email",
          validate: (email, { clients }) =>
            clients.every((client) => email !== client.email),
        },
      ]),
    [translate],
  );

  return useFormState(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
