/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { APIAddress } from "../../../api/addresses/addresses";
import TextField from "./TextField";

const PREFIX = "AddressFields";

const classes = {
  addressType: `${PREFIX}-addressType`,
  closeBtn: `${PREFIX}-closeBtn`,
  header: `${PREFIX}-header`,
  helperText: `${PREFIX}-helperText`,
  inputBox: `${PREFIX}-inputBox`,
  inputBoxWrapper: `${PREFIX}-inputBoxWrapper`,
  number: `${PREFIX}-number`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.inputBoxWrapper}`]: {
    marginBottom: theme.spacing(1),
    position: "relative",
    textAlign: "left",
  },

  [`& .${classes.inputBox}`]: {
    marginBottom: 10,
  },

  [`& .${classes.closeBtn}`]: {
    "&:hover": {
      background: "transparent",
      color: theme.palette.black.main,
    },
    padding: 0,
    position: "absolute",
    right: 0,
    top: 0,
  },

  [`& .${classes.addressType}`]: {
    display: "inline-block",
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  [`& .${classes.number}`]: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  [`& .${classes.header}`]: {
    display: "flex",
  },

  [`& .${classes.helperText}`]: {
    ...theme.controls.validation.error,
    marginTop: -2,
  },
}));

interface AddressFieldsProps {
  disablePostCodeField?: boolean;
  errorText: string;
  hasError: boolean;
  initialAddress: APIAddress.Input;
  isMulti: boolean;
  onChange: (input: APIAddress.Input) => void;
  postcodeTextFieldTestId: string;
  primary: boolean;
  streetNameTextFieldTestId: string;
}

const AddressFields = ({
  isMulti,
  initialAddress,
  onChange,
  errorText,
  hasError,
  primary,
  disablePostCodeField = false,
  streetNameTextFieldTestId,
  postcodeTextFieldTestId,
}: AddressFieldsProps) => {
  const { t } = useTranslation(["ui"]);

  const [addressModel, setAddressModel] = useState<APIAddress.Input>(
    initialAddress
      ? {
          postcode: initialAddress.postcode,
          street: initialAddress.street,
        }
      : { postcode: undefined, street: undefined },
  );

  useEffect(() => {
    if (initialAddress && addressModel) {
      setAddressModel({
        ...addressModel,
        postcode: initialAddress.postcode,
      });
    }
  }, [initialAddress]);

  const onChangeStreet = (street: string) => {
    onChangeAddress({ ...addressModel, street });
  };

  const onChangePostcode = (postcode: string) => {
    onChangeAddress({ ...addressModel, postcode });
  };

  const onChangeAddress = (data: APIAddress.Input) => {
    setAddressModel(data);
    onChange(data);
  };

  return (
    <StyledBox className={classes.inputBoxWrapper}>
      {isMulti ? (
        <Box className={classes.header}>
          <Typography className={classes.addressType} variant="h5">
            {primary
              ? t("ui:addressfields.main")
              : t("ui:addressfields.secondary")}
          </Typography>
          {hasError && (
            <FormHelperText
              className={classes.helperText}
              error={hasError}
              id="form-helper-text"
            >
              {errorText}
            </FormHelperText>
          )}
        </Box>
      ) : (
        hasError && (
          <FormHelperText error={hasError} id="form-helper-text">
            {errorText}
          </FormHelperText>
        )
      )}
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Box className={classes.inputBox} py={1}>
            <FormControl error={hasError} fullWidth variant="outlined">
              <TextField
                autoComplete="street-address"
                autoFocus
                id={streetNameTextFieldTestId}
                label={t("addressfields.street")}
                onChange={onChangeStreet}
                value={addressModel.street}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.inputBox} py={1}>
            <FormControl error={hasError} fullWidth variant="outlined">
              <TextField
                autoComplete="postal-code"
                disabled={disablePostCodeField}
                id={postcodeTextFieldTestId}
                inputProps={{
                  className: classes.number,
                  max: 9999,
                }}
                label={t("addressfields.zip")}
                onChange={onChangePostcode}
                type="number"
                value={addressModel.postcode || ""}
              />
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default AddressFields;
