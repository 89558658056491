/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Members } from "pusher-js";
import * as PusherTypes from "pusher-js";
import usePresenceSubscription from "../hooks/usePresenceSubscription";
import { PusherEvents } from "../pusherEvents";
/*eslint-disable no-duplicate-imports */

export interface SpecificPresenceProps {
  children?: React.ReactNode;
}
interface PresenceProps {
  channel: string | undefined;
  children?: React.ReactNode;
  memberAdded: (member: Members) => void;
  memberRemoved: (member: Members) => void;
  subscribedToChannel: (channel: PusherTypes.PresenceChannel) => void;
}

export default function Presence({
  channel,
  memberAdded,
  memberRemoved,
  subscribedToChannel,
  children,
}: PresenceProps) {
  const { isSubscribed, presenceChannel } = usePresenceSubscription({
    channel,
  });

  useEffect(() => {
    if (isSubscribed && presenceChannel) subscribedToChannel(presenceChannel);
  }, [isSubscribed]);

  useEffect(() => {
    if (presenceChannel) {
      presenceChannel.bind(PusherEvents.MEMBER_ADDED, (member: Members) =>
        memberAdded(member),
      );
      presenceChannel.bind(PusherEvents.MEMBER_REMOVED, (member: Members) =>
        memberRemoved(member),
      );
    }
  }, [presenceChannel]);

  return <>{children}</>;
}
