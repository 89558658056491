import { ResponsiveLine } from "@nivo/line";
import Paper from "@mui/material/Paper";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import { subMonths } from "date-fns";
import DatePicker from "../../ui/form/DatePicker";
import { BloomUpNamespacesEnum } from "../../language/I18Namespaces";
import BreadCrumbs from "../../ui/hr-dashboard/BreadCrumbs";
import { graphql } from "../../../api/__generated__";

const HRDashboards = BloomUpNamespacesEnum.HRDashboards;

const query = graphql(`
  query LandingPageUniqueVisitors(
    $endDate: Date!
    $organizationId: Int!
    $startDate: Date!
  ) {
    landingPageUniqueVisitors(
      endDate: $endDate
      organizationID: $organizationId
      startDate: $startDate
    ) {
      x
      y
    }
    videoConsultFunnel {
      name
      value
    }
  }
`);

const LandingPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [startDateState, setStartDateState] = useState(
    new Date(`${new Date().getFullYear() - 1}-01-01`),
  );

  const [endDateState, setEndDateState] = useState(new Date(Date.now()));

  const { data } = useQuery(query, {
    variables: {
      endDate: endDateState.toISOString().split("T")[0],
      organizationId: 1,
      startDate: startDateState.toISOString().split("T")[0],
    },
  });

  const amountOfClients =
    data?.videoConsultFunnel[data.videoConsultFunnel.length - 1].value;

  return (
    <div>
      <BreadCrumbs
        pages={location.pathname.replace("/home/hr-dashboards/", "").split("/")}
      />

      <div style={{ margin: "0px auto", width: "80%" }}>
        <Grid
          container
          spacing={1}
          style={{
            width: "100%",
          }}
        >
          <Grid
            item
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
            xs={12}
          >
            <h1 style={{ flexShrink: 0 }}>
              {t(`${HRDashboards}:translation.landingPage.landing`)}
            </h1>
            <div
              style={{ alignItems: "center", display: "flex", flexShrink: 0 }}
            >
              <span
                style={{
                  paddingRight: "10px",
                }}
              >
                {t(
                  `${HRDashboards}:translation.landingLineChart.viewReportFrom`,
                )}
              </span>

              <DatePicker
                appointment={false}
                fullWidth={false}
                maxDate={subMonths(Date.now(), 4)}
                minDate={
                  new Date(
                    `${new Date().getFullYear() - 2}-${
                      new Date().getMonth() + 1
                    }-${new Date().getDay()}`,
                  )
                }
                onChange={(date) => setStartDateState(date._d)}
                setAppointment={false}
                value={startDateState}
              ></DatePicker>
              <span
                style={{
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                {t(`${HRDashboards}:translation.landingLineChart.to`)}
              </span>
              <DatePicker
                appointment={false}
                fullWidth={false}
                maxDate={Date.now()}
                minDate={startDateState}
                onChange={(date) => setEndDateState(date._d)}
                setAppointment={false}
                value={endDateState}
              ></DatePicker>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid
            item
            sm={6}
            style={{ height: 250, marginBottom: 1, width: "100%" }}
            xs={12}
          >
            <Paper
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                height: "100%",
                justifyContent: "center",
                margin: 0,
                width: "100%",
              }}
              variant="outlined"
            >
              <h1 style={{ fontSize: "30pt" }}>
                {data?.landingPageUniqueVisitors !== undefined ? (
                  data.landingPageUniqueVisitors.reduce(
                    (acc, curr) => acc + curr.y,
                    0,
                  )
                ) : (
                  <CircularProgress
                    style={{
                      margin: "50% 50%",
                    }}
                  ></CircularProgress>
                )}
              </h1>
              <p style={{ fontWeight: "bold" }}>
                {t(
                  `${HRDashboards}:translation.landingLineChart.uniqueVisitors`,
                )}
              </p>
            </Paper>
          </Grid>
          <Grid
            item
            sm={6}
            style={{ height: 250, marginBottom: 1, width: "100%" }}
            xs={12}
          >
            <Paper
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                fontWeight: "bold",
                height: "100%",
                justifyContent: "center",
                margin: 0,
                width: "100%",
              }}
              variant="outlined"
            >
              <h1 style={{ fontSize: "30pt" }}>
                {data?.videoConsultFunnel !== undefined ? (
                  amountOfClients
                ) : (
                  <CircularProgress
                    style={{
                      margin: "50% 50%",
                    }}
                  ></CircularProgress>
                )}
              </h1>
              <p style={{ fontWeight: "bold" }}>
                {t(`${HRDashboards}:translation.landingLineChart.becameClient`)}
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                fontWeight: "bold",
                height: "280px",
                margin: "auto",
                width: "100%",
              }}
              variant="outlined"
            >
              {data?.landingPageUniqueVisitors !== undefined ? (
                <ResponsiveLine
                  axisBottom={{
                    legend: t(
                      `${HRDashboards}:translation.landingLineChart.time`,
                    ),
                    legendOffset: 36,
                    legendPosition: "middle",
                    tickPadding: 5,
                    tickRotation: 0,
                    tickSize: 5,
                  }}
                  axisLeft={{
                    legend: t(
                      `${HRDashboards}:translation.landingLineChart.uniqueVisitors`,
                    ),
                    legendOffset: -40,
                    legendPosition: "middle",
                    tickPadding: 5,
                    tickRotation: 0,
                    tickSize: 5,
                  }}
                  colors="#80A18C"
                  curve="linear"
                  data={[
                    {
                      data: data.landingPageUniqueVisitors,
                      id: new Date().getFullYear().toString(),
                    },
                  ]}
                  margin={{ bottom: 60, left: 60, right: 60, top: 30 }}
                  pointBorderColor={{ from: "serieColor" }}
                  pointBorderWidth={2}
                  pointColor="white"
                  pointLabelYOffset={-10}
                  pointSize={8}
                  useMesh={true}
                  xScale={{
                    type: "point",
                  }}
                  yFormat=" >-.0~f"
                  yScale={{
                    max: "auto",
                    min: "auto",
                    reverse: false,
                    stacked: true,
                    type: "linear",
                  }}
                />
              ) : (
                <CircularProgress
                  style={{
                    margin: "140px 50%",
                  }}
                ></CircularProgress>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default LandingPage;
