/* This is an edit of the original component from AWS.
 For the original file, see https://github.com/aws/amazon-chime-sdk-component-library-react/blob/main/src/components/ui/VideoGrid/index.tsx*/

import React from "react";

import {
  useContentShareState,
  useLocalVideo,
  useRemoteVideoTileState,
  VideoGrid,
  ContentShare,
  LocalVideo,
  RemoteVideos,
  FeaturedRemoteVideos,
  useFeaturedTileState,
} from "amazon-chime-sdk-component-library-react";
import { BaseProps } from "amazon-chime-sdk-component-library-react/lib/components/ui/Base";
import { Layout } from "amazon-chime-sdk-component-library-react/lib/components/ui/VideoGrid";

const fluidStyles = `
  height: 100%;
  width: 100%;
`;

//Changed the 'me' video to appear top right and have minimum dimensions
const staticStyles = `
display: flex;
position: absolute;
top: 1rem;
right: 1rem;
width: 20vw;
min-width: 120px;
min-height: 90px;
max-height: 30vh;
height: auto;

video {
  position: static;
}
`;

interface Props extends BaseProps {
  layout?: Layout;
  noRemoteVideoView?: React.ReactNode;
}

export const VideoTileGrid: React.FC<Props> = ({
  noRemoteVideoView,
  layout,
  ...rest
}) => {
  const { tileId: featureTileId } = useFeaturedTileState();
  const { tiles } = useRemoteVideoTileState();
  const { tileId: contentTileId } = useContentShareState();
  const { isVideoEnabled } = useLocalVideo();
  const featured =
    (layout === "featured" && !!featureTileId) || !!contentTileId;

  const remoteSize = tiles.length + (contentTileId ? 1 : 0);
  const gridSize =
    remoteSize > 1 && isVideoEnabled ? remoteSize + 1 : remoteSize;

  return (
    <VideoGrid {...rest} layout={featured ? "featured" : null} size={gridSize}>
      <ContentShare css="grid-area: ft;" />
      {layout === "featured" ? <FeaturedRemoteVideos /> : <RemoteVideos />}
      <LocalVideo
        css={gridSize > 1 ? fluidStyles : staticStyles}
        nameplate="Me"
      />
      {remoteSize === 0 && noRemoteVideoView}
    </VideoGrid>
  );
};
