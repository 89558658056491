import MuiAvatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import {
  graphql,
  FragmentType,
  getFragmentData,
} from "../../api/__generated__";

import { FemaleIcon, MaleIcon } from "../icons/emoticons";

const StyledMuiAvatar = styled(MuiAvatar)<{ size: number }>(
  ({ theme, size }) => ({
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.primary.main}`,
    height: size,
    width: size,
  }),
);

export const avatarFragment = graphql(`
  fragment Avatar on User {
    firstName
    id
    lastName
    professional {
      avatar {
        url
      }
      gender
      id
    }
  }
`);

type AvatarProps = {
  showGender?: boolean;
  size: number;
  user: FragmentType<typeof avatarFragment>;
};

export default function Avatar({ user, showGender, size }: AvatarProps) {
  const avatarData = getFragmentData(avatarFragment, user);

  const url = avatarData.professional?.avatar?.url;

  const firstName = avatarData.firstName;

  const initials = `${firstName?.charAt(0)}${avatarData.lastName?.charAt(0)}`;

  const gender = (() => {
    switch (avatarData.professional?.gender) {
      case "male":
        return "male";

      case "female":
        return "female";

      default:
        return undefined;
    }
  })();

  return (
    <>
      {url ? (
        <StyledMuiAvatar alt={firstName ?? ""} size={size} src={url} />
      ) : (
        <StyledMuiAvatar size={size}>
          {initials.length === 0 ? "?" : initials}
        </StyledMuiAvatar>
      )}
      {showGender && gender && (
        <Box
          sx={{
            "& SVG": {
              fill: "white",
              height: "50px",
              width: "50px",
            },
            position: "absolute",

            top: "85px",
          }}
        >
          {gender === "female" && <FemaleIcon />}
          {gender === "male" && <MaleIcon />}
        </Box>
      )}
    </>
  );
}
