import { IconButton } from "@mui/material";
import { Clear as ClearIcon, Check as CheckIcon } from "@mui/icons-material";
import TextField from "../../../ui/form/TextField";
import Table from "../../../ui/Table";
import SingleSelect from "../../../ui/form/select/SingleSelect";
import { UserRoles } from "../../../../data-model/types/User.ts";
import useUpdatePsyFormState from "./useUpdatePsyFormState";

const roleTypes = [
  {
    label: "Professional",
    value: UserRoles.PROFESSIONAL,
  },
  {
    label: "Administrator",
    value: UserRoles.ADMIN,
  },
];

export default function ProfessionalTableEditRow({
  professional,
  onSubmitUpdate,
  onCancelUpdate,
}) {
  const formState = useUpdatePsyFormState({
    email: professional.getEmail().prettyPrint(),
    firstName: professional.getFirstName().prettyPrint(),
    lastName: professional.getLastName().prettyPrint(),
    phone: professional.getPhone().prettyPrint(),
    roleType: professional.getRole(),
  });

  return (
    <Table.Row>
      <Table.Cell>
        <TextField {...formState.getInputProps("firstName")} />
      </Table.Cell>
      <Table.Cell>
        <TextField {...formState.getInputProps("lastName")} />
      </Table.Cell>
      <Table.Cell>
        <SingleSelect
          options={roleTypes}
          {...formState.getInputProps("roleType")}
        />
      </Table.Cell>
      <Table.Cell>
        <TextField {...formState.getInputProps("email")} />
      </Table.Cell>
      <Table.Cell>
        <TextField {...formState.getInputProps("phone")} />
      </Table.Cell>
      <Table.Cell>
        <IconButton
          onClick={() => {
            const isValid = formState.validate();

            if (isValid) {
              onSubmitUpdate(formState.values);
            }
          }}
          size="large"
        >
          <CheckIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            formState.resetForm();
            onCancelUpdate();
          }}
          size="large"
        >
          <ClearIcon />
        </IconButton>
      </Table.Cell>
    </Table.Row>
  );
}
