import { createContext } from "react";
import Consultation from "../../../../data-model/types/consultation/Consultation";
import {
  defaultFormStateValues,
  IFormState,
} from "../../../hooks/useFormState";
import Professional from "../../../../data-model/types/professional/Professional";
import Organization from "../../../../data-model/types/Organization";
import Human from "../../../../data-model/types/human/Human";
import { IWithChildren } from "../../../../baseInterfaces";

export interface IAddConsultationInCallContext extends IWithChildren {
  createNewConsult: () => void;
  currentConsultation: Consultation;
  formState: IFormState;
  human: Human;
  isBooking: boolean;
  newConsult: Consultation;
  organization: Organization;
  professional: Professional;
}

const defaultAddConsultationInCallValues: IAddConsultationInCallContext = {
  createNewConsult: () => {},
  currentConsultation: new Consultation(),
  formState: defaultFormStateValues,
  human: new Human(),
  isBooking: false,
  newConsult: new Consultation(),
  organization: new Organization(),
  professional: new Professional(),
};

const AddConsultationInCallContext =
  createContext<IAddConsultationInCallContext>(
    defaultAddConsultationInCallValues,
  );

export default AddConsultationInCallContext;
