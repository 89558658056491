import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Label from "../../ui/form/FormLabel";
import TextField from "../../ui/form/TextField";
import SanitizedText from "../../ui/text/SanitizedText";
import { I18Namespaces } from "../../language/I18Namespaces";

const PREFIX = "ConsultationCancellation";

const classes = {
  cancellation: `${PREFIX}-cancellation`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.cancellation}`]: {
    borderTop: `1px ${theme.palette.bloom.light} solid`,
  },
}));

interface IConsultationCancellation {
  editingMessage: boolean;
  formState: any;
}

const ConsultationCancellation = ({
  formState,
  editingMessage,
}: IConsultationCancellation) => {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  return (
    <StyledBox mt={1}>
      {editingMessage && (
        <Box className={classes.cancellation}>
          <Label
            label={translate(
              "common:consultation.cancel.comment",
              "Opmerking bij annulatie",
            )}
          />
          <TextField
            onChange={() => {}}
            {...formState.getInputProps("message")}
            autoFocus
            required={true}
            value={formState.getInputProps("message").body}
          />
          <Box mb={3} mt={2}>
            <SanitizedText
              input={translate("common:consultation.cancellation_policy")}
            />
          </Box>
        </Box>
      )}
      {!editingMessage && <p>{formState.getInputProps("message").body}</p>}
    </StyledBox>
  );
};

export default ConsultationCancellation;
