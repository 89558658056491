/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Description, {
  DESCRIPTION_PREFIX,
} from "../../../../data-model/types/professional/Description";
import createFormValidation, {
  IFormValidation,
} from "../../../../utils/forms/createFormValidation";
import useFormState from "../../../hooks/useFormState";
import { I18Namespaces } from "../../../language/I18Namespaces";
import { DatabaseLanguageEquivalents } from "../../../language/languagesUtil";
import { _sharedProfessionalFormState } from "../../professional-onboard/useProfessionalOnboardFormState";
import AccountNumber from "../../../../data-model/types/billing/AccountNumber";
import Bio from "../../../../data-model/types/professional/Bio";
import CMNumber from "../../../../data-model/types/professional/CMNumber";
import ConsultationLanguages from "../../../../data-model/types/professional/ConsultationLanguages";
import Experience from "../../../../data-model/types/professional/Experience";
import Specialties from "../../../../data-model/types/professional/Specialties";
import Therapies from "../../../../data-model/types/professional/Therapies";
import VisumNumber from "../../../../data-model/types/professional/VisumNumber";
import Website from "../../../../data-model/types/professional/Website";
import Address from "../../../../data-model/types/profile/Address";
import BirthDate from "../../../../data-model/types/profile/BirthDate";
import FirstName from "../../../../data-model/types/profile/FirstName";
import Gender from "../../../../data-model/types/profile/Gender";
import LastName from "../../../../data-model/types/profile/LastName";
import Timezone from "../../../../data-model/types/profile/Timezone";
import Avatar from "../../../../data-model/types/profile/Avatar";
import Phone from "../../../../data-model/types/profile/Phone";
import Email from "../../../../data-model/types/profile/Email";
import ConsultationDuration from "../../../../data-model/types/consultation/ConsultationDuration";
import ConsultationRate from "../../../../data-model/types/consultation/ConsultationRate";
import PreferredLanguage from "../../../../data-model/types/profile/PreferredLanguage";

const INITIAL_VALUES = {
  ...Avatar.getInitialValue(),
  ...FirstName.getInitialValue(),
  ...LastName.getInitialValue(),
  ...Email.getInitialValue(),
  ...Bio.getInitialValue(),
  ...Specialties.getInitialValue(),
  ...Therapies.getInitialValue(),
  ...Address.getInitialValue(),
  ...Phone.getInitialValue(),
  ...Gender.getInitialValue(),
  ...PreferredLanguage.getInitialValue(),
  ...ConsultationRate.getInitialValue(),
  ...ConsultationDuration.getInitialValue(),
  ...ConsultationLanguages.getInitialValue(),
  ...VisumNumber.getInitialValue(),
  ...CMNumber.getInitialValue(),
  ...Experience.getInitialValue(),
  ...Website.getInitialValue(),
  ...Timezone.getInitialValue(),
  ...BirthDate.getInitialValue(),
};

export interface IProfessionalFormState extends _sharedProfessionalFormState {
  accountNumber: string;
  firstName: string;
  lastName: string;
  phone: string;
  profile: Bio;
}

export default function useProfessionalProfileFormState(
  initialValues?: Partial<IProfessionalFormState>,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(["validation"]);
  const validation = useMemo(() => {
    // weird flex to get the descriptions to be validated.
    const descriptionValidations: Array<IFormValidation> = [];

    Object.values(DatabaseLanguageEquivalents).forEach((dbLang) =>
      Description.getFormValidation(
        translate,
        `${DESCRIPTION_PREFIX}-${dbLang}`,
      ).forEach((validation) => descriptionValidations.push(validation)),
    );

    return createFormValidation([
      ...Avatar.getFormValidation(translate),
      ...AccountNumber.getFormValidation(translate),
      ...FirstName.getFormValidation(translate),
      ...LastName.getFormValidation(translate),
      ...Email.getFormValidation(translate),
      ...Specialties.getFormValidation(translate),
      ...Therapies.getFormValidation(translate),
      ...ConsultationLanguages.getFormValidation(translate),
      ...Address.getFormValidation(translate),
      ...Phone.getFormValidation(translate),
      ...Gender.getFormValidation(translate),
      ...ConsultationRate.getFormValidation(translate),
      ...ConsultationDuration.getFormValidation(translate),
      ...PreferredLanguage.getFormValidation(translate),
      ...CMNumber.getFormValidation(translate),
      ...VisumNumber.getFormValidation(translate),
      ...Experience.getFormValidation(translate),
      ...BirthDate.getFormValidation(translate),
      ...Website.getFormValidation(translate),
      ...Timezone.getFormValidation(translate),
      ...descriptionValidations,
    ]);
  }, []);

  return useFormState<IProfessionalFormState>(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
