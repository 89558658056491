import { useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { useTranslation } from "react-i18next";
import UnavailableSection from "../../layout/UnavailableSection";
import ExpansionToggle from "../../ui/ExpansionToggle";
import { default as ConsultationDataType } from "../../../data-model/types/consultation/Consultation";
import { UserRoles } from "../../../data-model/types/User";
import { I18Namespaces } from "../../language/I18Namespaces";
import ConsultationListItem from "./ConsultationListItem";

export interface IConsultationListDescription {
  consultations?: ConsultationDataType[];
  emptyText?: string | null;
  isOpen: boolean;
  listName: string;
  onSelectConsultation: (uuid: string) => void;
  //TODO: check if we still need these optional properties
  pt?: number;
  roleType?: UserRoles;
}

const ConsultationList = ({
  pt = 0,
  onSelectConsultation,
  consultations = [],
  isOpen = true,
  listName,
  roleType,
  emptyText,
}: IConsultationListDescription) => {
  const { t } = useTranslation<I18Namespaces>("common");
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const empty = emptyText || t("common:consultations.none.planned");

  return (
    <Box pb={2} pt={pt}>
      <ExpansionToggle
        isOpen={isExpanded}
        label={`${listName} (${consultations.length})`}
        mb={2}
        onClick={() => setIsExpanded(!isExpanded)}
        primary={false}
      />
      {isExpanded &&
        (consultations.length ? (
          <List>
            {consultations.map((consultation: ConsultationDataType) => (
              <ConsultationListItem
                consultation={consultation}
                handleClick={() => onSelectConsultation(consultation.getUUID())}
                key={consultation.getID()}
                roleType={roleType}
              />
            ))}
          </List>
        ) : (
          <UnavailableSection text={empty} />
        ))}
    </Box>
  );
};

export default ConsultationList;
