import Professional from "../../../../data-model/types/professional/Professional";
import Table from "../../../ui/Table";
import ProfessionalsTableRowWithState from "./ProfessionalsTableRowWithState";

interface IProfessionalsTable {
  onDeleteComplete: () => void;
  professionals: Array<Professional>;
  refetch: () => void;
}

const ProfessionalsTable = ({
  onDeleteComplete,
  professionals,
  refetch,
}: IProfessionalsTable) => {
  return (
    <Table
      header={
        <Table.Row>
          <Table.Cell style={{ width: 200 }}>First name</Table.Cell>
          <Table.Cell style={{ width: 200 }}>Last name</Table.Cell>
          <Table.Cell style={{ width: 150 }}>Role</Table.Cell>
          <Table.Cell>Email</Table.Cell>
          <Table.Cell style={{ width: 150 }}>Phone</Table.Cell>
          <Table.Cell style={{ width: 100 }}>Licence</Table.Cell>
          <Table.Cell>Is verified?</Table.Cell>
          <Table.Cell style={{ width: 130 }}>Actions</Table.Cell>
        </Table.Row>
      }
    >
      {professionals.map((professional) => (
        <ProfessionalsTableRowWithState
          key={professional.getID()}
          onDeleteComplete={onDeleteComplete}
          professional={professional}
          refetch={refetch}
        />
      ))}
    </Table>
  );
};

export default ProfessionalsTable;
