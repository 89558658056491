import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { useCallback, useState } from "react";
import { APIQualityQuestions } from "../qualityQuestions";
import { QUERY_GET_QUESTION_BY_ID } from "../queries";

interface IUseFetchQualityQuestionByIdResult {
  error?: any;
  fetchQuestionById: (
    id: number,
  ) => Promise<ApolloQueryResult<APIQualityQuestions.ById.Result>>;
  loading: boolean;
  question?: APIQualityQuestions.Question;
}

export default function useFetchQualityQuestionById(): IUseFetchQualityQuestionByIdResult {
  /*const [execute, { data, loading, error }] = useLazyQuery<
        APIQualityQuestions.ById.Result,
        APIQualityQuestions.ById.Input
    >(QUERY_GET_QUESTION_BY_ID, { fetchPolicy: "no-cache" });*/

  const apolloClient = useApolloClient();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchQuestionById = useCallback(
    (id: number) => {
      setLoading(true);

      const result = apolloClient.query<APIQualityQuestions.ById.Result>({
        query: QUERY_GET_QUESTION_BY_ID,
        variables: { id },
      });

      setLoading(false);

      return result;
    },
    [apolloClient],
  );

  return {
    fetchQuestionById,

    loading,
  };
}
