/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import RegisterForm from "../RegisterFormInfo";
import RegistrationContext from "../context/RegistrationContext";
import { INFO_STEP } from "../context/registrationSteps";
import useQueryParams, {
  getParamAsString,
} from "../../../hooks/useQueryParams";
import { IRegistrationContext } from "../context/registration";

const HumanInfoStep = () => {
  const { t } = useTranslation();
  const [onCheckedConsent, setOnCheckedConsent] = useState(false);
  const { formState, isSubmitting, setStep, next, handleSubmit } =
    useContext<IRegistrationContext>(RegistrationContext);

  const { email: emailParam, invite: inviteParam } = useQueryParams();
  const email: string = getParamAsString(emailParam);
  const invite: string = getParamAsString(inviteParam);

  const headline: string = invite
    ? t("human:registration.title")
    : t("human:registration.complete_profile");

  useEffect(() => setStep(INFO_STEP), []);

  useEffect(() => {
    if (invite && formState) {
      //load invite query param values
      const formEmail = formState.getValue("email");
      const formInvite = formState.getValue("inviteToken");

      if (
        formState &&
        email &&
        email.toLocaleLowerCase() !== formEmail &&
        invite !== formInvite
      ) {
        formState.setValues({
          email: email.toLocaleLowerCase(),
          inviteToken: invite,
        });
      }
    }
  }, [email, invite, formState]);

  const onNext = () => {
    if (invite) {
      next();
    } else {
      handleSubmit();
    }
  };

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={isSubmitting || (!onCheckedConsent && !!invite)}
        fullCopyright
        headline={headline}
        labelNext={t("common:next")}
        onClickNext={onNext}
      >
        <RegisterForm
          formState={formState}
          onTAndCCheck={invite ? setOnCheckedConsent : undefined}
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
};

export default HumanInfoStep;
