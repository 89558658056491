/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import LeftSection from "../../layout/LeftSection";
import Human, { HumanStatus } from "../../../data-model/types/human/Human";
import { UserRoles } from "../../../data-model/types/User";
import Professional from "../../../data-model/types/professional/Professional";
import UnavailableSection from "../../layout/UnavailableSection";
import { I18Namespaces } from "../../language/I18Namespaces";
import ClientSubList, { IClientSubList } from "./ClientSubList";

interface IClientList {
  buttonIcon: any;
  buttonLabel: string;
  clients: Array<Human>;
  handleButton: () => void;
  handleClick: (id: number) => void;
  isHuman: boolean;
  pageTitle: string;
  roleType: UserRoles;
  selectedClient?: Human | Professional;
}

const ClientList = ({
  clients,
  roleType,
  pageTitle,
  buttonLabel,
  buttonIcon,
  handleClick,
  handleButton,
}: IClientList) => {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);
  const emptyMessage = (
    <UnavailableSection
      text={translate(
        "common:status.not_found.clients",
        "Geen cliënten gevonden.",
      )}
    />
  );

  const groupedClients: Array<IClientSubList> = useMemo(() => {
    const lists: Array<IClientSubList> = [];

    const confirmed = clients.filter(
      (c) => c.getStatus() === HumanStatus.CURRENT && c.getID(),
    );

    const confirmedList: IClientSubList = {
      clients: confirmed,
      emptyText: translate(
        "common:client_list.empty.no_clients",
        "Je hebt nog geen cliënten.",
      ),
      isOpen: true,
      listName: translate(
        "common:client_list.approved_clients",
        "Bevestigde cliënten",
      ),
      onSelectClient: handleClick,
      roleType,
    };

    if (confirmed && confirmed.length > 0) lists.push(confirmedList);

    const awaitingConfirmation = clients.filter(
      (c) => c.getStatus() !== HumanStatus.CURRENT,
    );

    const awaitingConfirmationList: IClientSubList = {
      clients: awaitingConfirmation,
      emptyText: translate(
        "common:client_list.empty.no_invited_clients",
        "Er zijn geen cliënten uitgenodigd.",
      ),
      isOpen: true,
      listName: translate(
        "common:client_list.name.to_be_approved",
        "Te bevestigen",
      ),
      roleType,
    };

    if (awaitingConfirmation && awaitingConfirmation.length > 0)
      lists.push(awaitingConfirmationList);

    return lists;
  }, [clients]);

  const hasClients = useMemo((): boolean => {
    for (let i = 0; i < groupedClients.length; i++) {
      const list = groupedClients[i];

      if (list.clients.length > 0) return true;
    }

    return false;
  }, [groupedClients]);

  return (
    <LeftSection
      buttonIcon={buttonIcon}
      buttonLabel={buttonLabel}
      handleTitleButton={handleButton}
      pageTitle={pageTitle}
      short={false}
    >
      {hasClients &&
        groupedClients.map((list, i) => (
          <ClientSubList key={i} {...list} pt={i ? 2 : 0} />
        ))}
      {!hasClients && emptyMessage}
    </LeftSection>
  );
};

export default ClientList;
