import { styled } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { ISelectObject } from "./form/select/BaseSimpleSelect";

const PREFIX = "PopUpMenu";

const classes = {
  paper: `${PREFIX}-paper`,
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    border: "1px solid #d3d4d5",
  },

  [`& .${classes.root}`]: {
    "&:hover": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const StyledMenu = (props: MenuProps) => (
  <Menu
    anchorOrigin={{
      horizontal: "center",
      vertical: "bottom",
    }}
    elevation={0}
    transformOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
    {...props}
  />
);

const StyledMenuItem = MenuItem;

interface IPopUpMenuProperties {
  anchorEl: null | HTMLElement;
  handleMenuItemClick: (option: ISelectObject) => void;
  onChange: (value: ISelectObject) => void;
  onClose: (value: ISelectObject | null) => void;
  options: Array<ISelectObject<Date>>;
  value?: ISelectObject;
}

export default function PopUpMenu({
  onClose,
  anchorEl,
  options,
  handleMenuItemClick,
  onChange,
}: IPopUpMenuProperties) {
  return (
    <Root>
      <StyledMenu
        anchorEl={anchorEl}
        classes={{
          paper: classes.paper,
        }}
        id="customized-menu"
        keepMounted
        onClose={() => {
          onClose(null);
        }}
        open={Boolean(anchorEl)}
      >
        {options.map((option) => (
          <StyledMenuItem
            classes={{
              root: classes.root,
            }}
            key={option.label}
            onClick={() => {
              handleMenuItemClick(option);
              onChange(option);
              onClose(option);
            }}
          >
            <ListItemText primary={option.label} />
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </Root>
  );
}
