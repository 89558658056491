import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { RouteNames } from "../../routes/routeNames";
import ValueWithLabel from "../../ui/text/ValueWithLabel";
import Consultation from "../../../data-model/types/consultation/Consultation";

interface ReimbursementInfoProps {
  consultation: Consultation;
  forProfessional: boolean;
}

export default function ReimbursementInfo({
  consultation,
  forProfessional,
}: ReimbursementInfoProps) {
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Payments]);

  const [containsLink, setContainsLink] = useState(false);

  const reimbursementLink = useMemo(() => {
    return `${RouteNames.Payment.Consultation.Pay.Reimbursement.path}`.replace(
      ":uuid",
      consultation.getUUID(),
    );
  }, [consultation]);

  const displayValue: string | null = useMemo(() => {
    const cashTransaction = consultation.getCashTransaction();
    const expectedReimbursementDate =
      consultation.getExpectedReimbursementDate();

    let returnValue: string | null = null;

    if (
      !cashTransaction ||
      cashTransaction.getStatus() === "new" ||
      cashTransaction.getStatus() === "open"
    ) {
      setContainsLink(false);
      returnValue = t(
        `${BloomUpNamespacesEnum.Payments}:reimbursement.not.requested`,
      );
    } else {
      if (expectedReimbursementDate === null) {
        setContainsLink(true);
        returnValue = t(
          `${BloomUpNamespacesEnum.Payments}:reimbursement.request`,
        );
      } else {
        setContainsLink(false);
        returnValue = t(
          `${BloomUpNamespacesEnum.Payments}:reimbursement.expected`,
          {
            defaultValue: "→ {{expectedReimbursementDate}} minuten",
            expectedReimbursementDate: consultation
              .getExpectedReimbursementDate()
              ?.format("DD/MM/YYYY"),
          },
        );
      }
    }

    return returnValue;
  }, [consultation, t]);

  return (
    <>
      {!forProfessional && displayValue && (
        <ValueWithLabel
          label={t(
            `${BloomUpNamespacesEnum.Payments}:reimbursement.status.text`,
          )}
        >
          {containsLink ? (
            <a href={reimbursementLink}>{displayValue}</a>
          ) : (
            displayValue
          )}
        </ValueWithLabel>
      )}
    </>
  );
}
