import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Table,
  TableCell,
  TableContainer,
} from "@mui/material";
import { MuiMarkdown, getOverrides } from "mui-markdown";
import { useTranslation } from "react-i18next";
import { CloseOutlined, DoneOutlined } from "@mui/icons-material";
import {
  FragmentType,
  getFragmentData,
  graphql,
} from "../../api/__generated__";

import useSelectTranslation from "../../api/languages/useSelectTranslation";
import Button from "../ui/buttons/Button";

const OrgProfileDialogFragment = graphql(`
  fragment OrgProfileDialogOrganization on Organization {
    ggtEnabled
    id
    mindlabEnabled
    name {
      ...useSelectTranslationStringInput
    }
    profile {
      ...useSelectTranslationStringInput
    }
    videoEnabled
  }
`);

const MarkdownWrapper = ({ children }) => (
  <Box sx={{ marginTop: 2 }}>{children}</Box>
);

const CustomTable = (props) => (
  <TableContainer>
    <Table {...props} />
  </TableContainer>
);

const CustomTableCell = (props) => (
  <TableCell {...props} sx={{ textWrap: "wrap" }} />
);

const CustomTableHeader = (props) => (
  <TableCell
    {...props}
    sx={{ fontWeight: "bold", textWrap: "wrap" }}
    variant="head"
  />
);

const CustomH2 = (props) => (
  <Typography
    component={"h2"}
    variant={"h2"}
    {...props}
    sx={{ marginTop: 3 }}
  />
);

export function OrgProfileDialog({
  confirmationFooter = false,
  onClose,
  open,
  organization: organizationData,
}: {
  confirmationFooter?: boolean;
  onClose: (accepted: boolean) => void;
  open: boolean;
  organization: FragmentType<typeof OrgProfileDialogFragment>;
}) {
  const { t } = useTranslation();
  const selectTranslation = useSelectTranslation();
  const organization = getFragmentData(
    OrgProfileDialogFragment,
    organizationData,
  );

  const organizationName = selectTranslation(organization.name);
  const organizationProfile = selectTranslation(organization.profile);

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={() => {
        onClose(false);
      }}
      open={open}
      scroll="paper"
    >
      <DialogTitle>{organizationName}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <DialogContentText>
            {t("organization:profileDialog.videoTherapy")}{" "}
            {organization.videoEnabled ? <AvailableTag /> : <UnavailableTag />}
          </DialogContentText>
          {organization.ggtEnabled && (
            <DialogContentText sx={{ marginLeft: 2 }}>
              {t("organization:profileDialog.ggt")} <AvailableTag />
            </DialogContentText>
          )}
          {organization.mindlabEnabled && (
            <DialogContentText sx={{ marginLeft: 2 }}>
              {t("organization:profileDialog.selfHelp")} <AvailableTag />
            </DialogContentText>
          )}
          {!organization.mindlabEnabled && !organization.ggtEnabled && (
            <DialogContentText sx={{ marginLeft: 2 }}>
              {t("organization:profileDialog.selfHelp")} <UnavailableTag />
            </DialogContentText>
          )}
        </Box>
        {organizationProfile && (
          <MuiMarkdown
            options={{
              forceWrapper: true,
              overrides: {
                ...getOverrides(),
                h2: { component: CustomH2 },
                table: { component: CustomTable },
                td: { component: CustomTableCell },
                th: { component: CustomTableHeader },
              },
              wrapper: MarkdownWrapper,
            }}
          >
            {organizationProfile}
          </MuiMarkdown>
        )}
      </DialogContent>
      <DialogActions>
        {confirmationFooter ? (
          <>
            <Typography sx={{ mx: "8px" }}>
              {t("organization:profileDialog.confirmReading")}
            </Typography>
            <Button
              icon={<DoneOutlined />}
              label={t("organization:profileDialog.yes")}
              onClick={() => {
                onClose(true);
              }}
            ></Button>
            <Button
              icon={<CloseOutlined />}
              label={t("organization:profileDialog.no")}
              onClick={() => {
                onClose(false);
              }}
            ></Button>
          </>
        ) : (
          <Button
            icon={<CloseOutlined />}
            label={t("organization:profileDialog.closeButton")}
            onClick={() => {
              onClose(false);
            }}
          ></Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

const AvailableTag = () => {
  const { t } = useTranslation();

  return (
    <Chip
      color="success"
      label={t("organization:profileDialog.productAvailable")}
      sx={{ textTransform: "uppercase" }}
    ></Chip>
  );
};

const UnavailableTag = () => {
  const { t } = useTranslation();

  return (
    <Chip
      color="error"
      label={t("organization:profileDialog.productUnavailable")}
      sx={{ textTransform: "uppercase" }}
    ></Chip>
  );
};
