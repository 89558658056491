import { styled } from "@mui/material/styles";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import { useTranslation } from "react-i18next";
import environment from "../../../environment/environment";
import { I18Namespaces } from "../../language/I18Namespaces";
import { RouteNames } from "../../routes/routeNames";
import SidebarNavigationItem from "./SidebarNavigationItem";

const PREFIX = "OrgnanizationAdminSidebarNavigation";

const classes = {
  actions: `${PREFIX}-actions`,
  list: `${PREFIX}-list`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.actions}`]: {
    alignItems: "flex-start",
    display: "flex",
    flexFlow: "column nowrap",
    padding: theme.spacing(1),
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      background: theme.palette.white.main,
      bottom: 0,
      boxShadow: "0 -1px 7px 0 rgba(181,181,181,0.50)",
      left: 0,
      padding: 0,
      position: "fixed",
      right: 0,
      width: "100%",
      zIndex: 2,
    },
  },

  [`& .${classes.list}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    padding: 0,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "row nowrap",
      margin: 0,
    },
  },
}));

export default function OrgnanizationAdminSidebarNavigation() {
  const { t: translate } = useTranslation<I18Namespaces>(["ui"]);

  return (
    <Root className={classes.actions}>
      <ul className={classes.list}>
        <SidebarNavigationItem
          href={RouteNames.Home.OrganizationUsage.path}
          icon={<EqualizerIcon />}
          id={"test_sidebar_button_professionals"}
          label={translate("ui:menu.organization.usage", "Gebruik")}
        />
        <SidebarNavigationItem
          href="/match"
          icon={<AllInclusiveIcon />}
          id={"test_sidebar_button_start_matching"}
          label={translate("ui:menu.startmatching")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Consultations.path}
          icon={<TodayOutlinedIcon />}
          id={"test_sidebar_button_consultations"}
          label={translate("ui:menu.consultations", "Consultaties")}
        />
        <SidebarNavigationItem
          href={RouteNames.Home.Professionals.path}
          icon={<FaceOutlinedIcon />}
          id={"test_sidebar_button_professionals"}
          label={translate("ui:menu.professionals", "Professionals")}
        />
        {environment.REACT_APP_HRDASHBOARD_VISIBLE === "true" ? (
          <SidebarNavigationItem
            href={RouteNames.Home.HRDashboards.path}
            icon={<DashboardIcon />}
            id="test_sidebar_button_hrdashboard"
            label={translate("ui:menu.hrdashboard", "HR Dashboard")}
          />
        ) : null}
      </ul>
    </Root>
  );
}
