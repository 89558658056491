export enum DateFormat {
  FULL = "ddd D MMMM HH:mm",
  GRAPHQL = "YYYY-MM-DDTHH:mm:ss",
  ONLY_DATE = "dddd D MMMM",
  ONLY_DATE_SHORT = "YYYY-MM-DD",
  ONLY_DATE_SHORT_MONTH = "DD MMM YYYY",
  ONLY_TIME = "HH:mm",
  ONLY_YEAR = "YYYY",
  SHORT = "YYYY-MM-DD HH:mm",
}
