import { useCallback, useMemo } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  QUERY_FETCH_PROFESSIONALS,
  QUERY_FETCH_PROFESSIONALS_FOR_ADMIN,
} from "../queries";
import Professional from "../../../data-model/types/professional/Professional";
import { connectionToCollection } from "../../../utils/graphql";

interface IProfessionalsResult {
  error: any;
  fetchProfessionals: any;
  loading: boolean;
  professionals: Array<Professional>;
}

interface IUseProfessionalsInput {
  forAdmin?: boolean;
}

const useFetchProfessionals = ({
  forAdmin = false,
}: IUseProfessionalsInput): IProfessionalsResult => {
  const [execute, { data, loading, error }] = useLazyQuery(
    forAdmin ? QUERY_FETCH_PROFESSIONALS_FOR_ADMIN : QUERY_FETCH_PROFESSIONALS,
    {
      fetchPolicy: "network-only",
    },
  );

  const fetchProfessionals = useCallback(
    (input) => execute({ variables: { input: { ...input } } }),
    [execute],
  );

  const extract = (dataObjects: any) => connectionToCollection(dataObjects);
  const createProfessionals = (professionals: any) =>
    professionals
      ? professionals.map((p: any) => new Professional(p, true), true)
      : [];

  const professionalsFromData = useMemo(() => {
    if (data) {
      const extracted = extract(data.professionals);

      return createProfessionals(extracted);
    }

    return [];
  }, [data]);

  return {
    error,
    fetchProfessionals,
    loading,
    professionals: professionalsFromData,
  };
};

export default useFetchProfessionals;
