const DEFAULT_MESSAGE = "Invalid";

interface IValidateFunc {
  (input: any, extraData: any, options?: any): boolean;
}

export interface IFormErrorMessage {
  message: string;
  path: string;
}

interface IValidationFunc {
  (
    path: string,
    values: any,
    options?: any,
    allFormValues?: any,
  ): Array<IFormErrorMessage>;
}

export interface IFormValidationFunc {
  (values: any, options?: any, allFormValues?: any): Array<IFormErrorMessage>;
}

export interface IFormValidation {
  message: string;
  path: string;
  validate: IValidateFunc;
}

interface IFormValidationRules {
  path: string;
  validation: IValidationFunc;
}

export default function createFormValidation(
  formRules: IFormValidation[],
  defaultMessage?: string,
): IFormValidationFunc {
  const validationRules: IFormValidationRules[] = createValidationRules(
    formRules,
    defaultMessage,
  );

  return (values, options, allFormValues) =>
    validationRules
      .map((rule) => {
        const messages: Array<IFormErrorMessage> = rule.validation(
          rule.path,
          values,
          options,
          allFormValues,
        );

        if (!Array.isArray(messages)) {
          throw Error(
            "A validation function must return an array of messages.",
          );
        }

        return messages;
      })
      .reduce(
        (a: Array<IFormErrorMessage>, b: Array<IFormErrorMessage>) => [
          ...a,
          ...b,
        ],
        [],
      );
}

/**
 * Function that returns an array of all validation paths with their validation function.
 */
function createValidationRules(
  formRules: IFormValidation[],
  defaultMessage?: string,
): IFormValidationRules[] {
  return formRules.map((rule: IFormValidation) => {
    const validation: IValidationFunc = createFieldValidation(
      rule.validate,
      rule.message,
      defaultMessage,
    );

    return {
      path: rule.path,
      validation,
    };
  });
}

function createFieldValidation(
  validate: IValidateFunc,
  message: string,
  defaultMessage = DEFAULT_MESSAGE,
): IValidationFunc {
  return (path, values, options, allFormValues) => {
    const value = values[path];

    if (
      typeof value !== "undefined" &&
      !validate(value, { ...values, ...allFormValues }, options)
    ) {
      let finalMessage = defaultMessage;

      if (typeof message !== "undefined") {
        finalMessage = message;
      }

      return [
        {
          message: finalMessage,
          path,
        },
      ];
    }

    return [];
  };
}
