import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IFormState } from "../../hooks/useFormState";
import { I18Namespaces } from "../../language/I18Namespaces";
import AddressFields from "../form/AddressFields";
import { APIAddress } from "../../../api/addresses/addresses";
import Address from "../../../data-model/types/profile/Address";
import InlineEditableControls from "./common/InlineEditableControls";

interface IEAddressProps<T extends Address> {
  formState: IFormState;
  initialAddress: T;
  label: string;
  onSave: (address: APIAddress.Input) => void;
}

const InlineEditdableAddress = <T extends Address>({
  label,
  initialAddress,
  onSave,
  formState,
}: IEAddressProps<T>) => {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);
  const [address, setAddress] = useState<Address>(initialAddress);

  const path: string = initialAddress.getType().getPath();

  return (
    <InlineEditableControls
      edit={
        <AddressFields
          initialAddress={address.getValue()}
          {...(formState ? formState.getValidationProps(path) : {})}
          onChange={(val) => {
            const newVal: Partial<APIAddress.Result> = {
              ...val,
              country: "BE",
            };

            setAddress(new Address(newVal));
          }}
        />
      }
      editable={formState !== undefined}
      label={label || translate("common:-", "-")}
      onDiscard={() => {
        // TODO figure out why setValue on formstate doesnt work and this does
        formState.values[path] = address.getValue();

        if (formState.validate(path)) {
          setAddress(address);

          return true;
        } else {
          return false;
        }
      }}
      onSave={async () => {
        // TODO figure out why setValue on formstate doesnt work and this does
        formState.values[path] = address.getValue();

        if (formState.validate(path)) {
          address.setValue(address.getValue());
          setAddress(address);
          onSave({
            postcode: address.getValue().postcode,
            street: address.getValue().street,
          });

          return true;
        } else {
          return false;
        }
      }}
      read={address.prettyPrint()}
    />
  );
};

export default InlineEditdableAddress;
