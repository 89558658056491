import { useContext } from "react";
import { IClockContext } from "../context/clock";
import ClockContext from "../context/ClockContext";
import CallClock from "./CallClock";

export interface ICallClock {
  displayClock?: boolean;
  smallVersion?: boolean;
}

const CallClockWithContext = ({
  smallVersion,
  displayClock: display,
}: ICallClock) => {
  const {
    count,
    running,
    displayValue,
    toggleRunning,
    resetClock,
    updateClockFunction,
  } = useContext<IClockContext>(ClockContext);

  return (
    <CallClock
      clockValueOverride={count}
      displayClock={display}
      displayValueOverride={displayValue}
      resetClockOverride={resetClock}
      runningOverride={running}
      smallVersion={smallVersion}
      toggleRunningOverride={toggleRunning}
      updateClockOverride={updateClockFunction}
    />
  );
};

export default CallClockWithContext;
