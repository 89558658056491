import { Box, Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { I18Namespaces } from "../language/I18Namespaces";
import SanitizedText from "../ui/text/SanitizedText";

const PREFIX = "TermsAndConditions";

const classes = {
  checkbox: `${PREFIX}-checkbox`,
  root: `${PREFIX}-root`,
  terms: `${PREFIX}-terms`,
};

const StyledBox = styled(Box)<{ checkbox: boolean }>(({ theme, checkbox }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    margin: "0 auto",
    maxWidth: checkbox ? "default" : "70%",
  },

  [`& .${classes.checkbox}`]: {
    margin: "0 17px",
  },

  [`& .${classes.terms}`]: {
    "& A": {
      color: `${theme.palette.bloom.main} !important`,
    },

    textAlign: "start",
  },
}));

interface TermsAndConditionsProps {
  onCheck?: (checked: boolean) => void;
}

export default function TermsAndConditions({
  onCheck,
}: TermsAndConditionsProps) {
  const { t: translate } = useTranslation<I18Namespaces>(["human", "common"]);

  return (
    <StyledBox checkbox={!!onCheck} className={classes.root}>
      {onCheck && (
        <Checkbox
          className={classes.checkbox}
          onChange={(_, checked) => {
            onCheck(checked);
          }}
        />
      )}
      <Typography className={classes.terms} variant="body1">
        <SanitizedText
          input={translate("human:onboarding.consent.termsandprivacy")}
        />
      </Typography>
    </StyledBox>
  );
}
