import { useMutation } from "@apollo/client";
import { MUTATION_PROFESSIONAL_VERIFY } from "../mutations";

interface IVerifyProfessionalResult {
  verifyProfessional: boolean;
}
interface IVerifyProfessionalInput {
  id: number;
}

const useVerifyProfessional = () => {
  const [verifyProfessionalFunction, { data, loading, error }] = useMutation<
    IVerifyProfessionalResult,
    IVerifyProfessionalInput
  >(MUTATION_PROFESSIONAL_VERIFY);

  const verifyProfessional = (id: number) =>
    verifyProfessionalFunction({ variables: { id } });

  return {
    data,
    error,
    loading,
    verifyProfessional,
  };
};

export default useVerifyProfessional;
