import { useMutation } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { MUTATION_START_VIDEOCALL } from "../queries";
import { getFragmentData } from "../../__generated__";
import { VIDEO_CALL_DATA_FRAGMENT } from "../fragments";

export default function useStartVideoCall() {
  const [startVideoCall, { error, loading, data }] = useMutation(
    MUTATION_START_VIDEOCALL,
  );

  const execute = useCallback(
    (consultationUuid: string) => {
      startVideoCall({ variables: { consultationUuid } });
    },
    [startVideoCall],
  );

  const response = useMemo(
    () => getFragmentData(VIDEO_CALL_DATA_FRAGMENT, data?.startVideoCall),
    [data?.startVideoCall],
  );

  return {
    error,
    execute,
    loading,
    response,
  };
}
