import { useEffect, useRef } from "react";

type UseIntervalCallback = () => any | undefined;

export default function useInterval(
  callback: UseIntervalCallback,
  delay: number | null,
) {
  const savedCallback = useRef<UseIntervalCallback>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback && savedCallback.current) savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
}
