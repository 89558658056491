import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useRosterState } from "amazon-chime-sdk-component-library-react";
import { IWithChildren } from "../../../../../baseInterfaces";
import LoadingPage from "../../../../layout/LoadingPage";
import { defaultClockValues } from "../../../../pages/professional-call/context/ClockContext";
import ClockStateProvider from "../../../../pages/professional-call/context/ClockStateProvider";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import OverlayDrawer from "../../../dialogs/OverlayDrawer";
import { ChimeVideoCall } from "../../ChimeVideoCall.types";
import { ICallContext } from "../../context/Call";
import CallContext from "../../context/CallContext";
import ActivityBar from "../audioAndVideoComponents/ActivityBar";
import AudioInputDeviceSelection from "../audioAndVideoComponents/AudioInputDeviceSelection";
import VideoInputDeviceSelection from "../audioAndVideoComponents/VideoInputDeviceSelection";
import SidePane from "./SidePane";

interface IChimeStandardOverlaySidePane extends IWithChildren {
  withClock?: boolean;
}

export default function ChimeStandardOverlaySidePane({
  withClock = true,
  children,
}: IChimeStandardOverlaySidePane) {
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Ui]);

  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  const { paneToPresent, callInfo } = useContext<ICallContext>(CallContext);

  if (!callInfo) return <LoadingPage full />;

  return (
    <ClockStateProvider
      {...defaultClockValues}
      canStart={attendees.length === 2}
    >
      <OverlayDrawer
        isOpen={paneToPresent !== ChimeVideoCall.SidePaneProfessional.none}
        withClock={withClock}
      >
        {children}
        {paneToPresent === ChimeVideoCall.SidePaneProfessional.settingsPane && (
          <SidePane
            TitleIcon={DevicesOutlinedIcon}
            title={t("ui:sidepane.menu.videoaudio.title")}
          >
            <VideoInputDeviceSelection />
            <AudioInputDeviceSelection />
            <ActivityBar />
          </SidePane>
        )}
      </OverlayDrawer>
    </ClockStateProvider>
  );
}
