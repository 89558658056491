/* eslint-disable react-hooks/exhaustive-deps */

import { Typography } from "@mui/material";
import "moment-timezone";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useLazyGetCashTransactionsByConsultationId from "../../../../../api/cashTransactions/hooks/useLazyGetCashTransactionsByConsultationId";
import useSubscribeToPaymentUpdates from "../../../../../utils/pusher/channels/hooks/useSubscribeToPaymentUpdates";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import Consultation from "../../../../../data-model/types/consultation/Consultation";
import InfoItemSidePane from "./InfoItemSidePane";

interface IInfoItemSidePanePaymentBox {
  consultation: Consultation;
}

const InfoItemSidePanePaymentBox = ({
  consultation,
}: IInfoItemSidePanePaymentBox) => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const { cashTransactions, getCashTransaction, refetch, loading } =
    useLazyGetCashTransactionsByConsultationId();

  const { addOnReceivedFunction } = useSubscribeToPaymentUpdates(consultation);

  useEffect(() => {
    getCashTransaction(consultation.getID());
  }, []);

  useEffect(() => {
    if (addOnReceivedFunction && refetch !== undefined) {
      addOnReceivedFunction(() => refetch());
    }
  }, [addOnReceivedFunction, refetch]);

  if (loading || cashTransactions.length < 1) return null;

  return (
    <InfoItemSidePane subTitle={translate("common:payment", "Betaalstatus")}>
      {cashTransactions.map((ct) => (
        <Typography>
          €{ct.getAmount()} -{" "}
          {translate(`common:payment.status.${ct.getStatus()}`, "")}
        </Typography>
      ))}
    </InfoItemSidePane>
  );
};

export default InfoItemSidePanePaymentBox;
