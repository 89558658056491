import { createContext } from "react";
import { defaultFormStateValues } from "../../../hooks/useFormState";
import { IRequestConsultationContext } from "./RequestConsultation";

export const defaultRequestConsultationValues: IRequestConsultationContext = {
  formState: defaultFormStateValues,
  onSubmit: () => {},
  professionals: [],
  resetRequestConsultationForm: () => {},
  submitting: false,
};

const RequestConsultationContext = createContext<IRequestConsultationContext>(
  defaultRequestConsultationValues,
);

export default RequestConsultationContext;
