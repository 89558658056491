import { createContext } from "react";
import { IBeamsContext } from "./beams";

export const defaultBeamsProviderValues: IBeamsContext = {
  addSubscription: () => {},
  askPermissions: () => {},
  error: undefined,
  interests: [],
  ready: false,
  setMultipleSubscriptions: () => {},
};

const BeamsContext = createContext<IBeamsContext>(defaultBeamsProviderValues);

export default BeamsContext;
