/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useFetchHuman from "../../../../api/humans/hooks/useFetchHuman";
import LoadingPage from "../../../layout/LoadingPage";
import ToastContext from "../../../providers/toast/ToastContext";
import useUpdateHumanMutation from "../../../../api/humans/hooks/useUpdateHumanMutation";
import FirstName from "../../../../data-model/types/profile/FirstName";
import Email from "../../../../data-model/types/profile/Email";
import LastName from "../../../../data-model/types/profile/LastName";
import Phone from "../../../../data-model/types/profile/Phone";
import { IToastContext } from "../../../providers/toast/toast";
import Human from "../../../../data-model/types/human/Human";
import useHumanProfileFormState from "../hooks/useHumanProfileFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import useUpdateUser from "../../../profile/hooks/useUpdateUser";
import { HealthInsuranceCompanies } from "../../../../data-model/types/reimbursement/HealthInsurance";
import { ISelectObject } from "../../../ui/form/select/BaseSimpleSelect";
import AuthContext from "../../../../components/providers/auth/AuthContext";
import { IAuthContext } from "../../../../components/providers/auth/auth";
import BirthDate from "../../../../data-model/types/profile/BirthDate";
import { HumanInput } from "../../../../api/__generated__/graphql";
import HumanProfileContext from "./HumanProfileContext";

export default function HumanProfileContextProvider({ children }) {
  const { setToast } = useContext<IToastContext>(ToastContext);

  const { setCurrentUser, currentUser } = useContext<IAuthContext>(AuthContext);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Ui,
  ]);

  const formState = useHumanProfileFormState();
  const [human, setHuman] = useState<Human | undefined>(undefined);
  const { loading, human: profile } = useFetchHuman(currentUser);

  const { updateHuman } = useUpdateHumanMutation();
  const { updateTimezone, updatePreferredLanguage } = useUpdateUser("human");

  useEffect(() => {
    if (!loading && profile) {
      formState.setValues(profile.getAsFormStateValues(translate));
      setHuman(profile);
      setCurrentUser(profile);
    }
  }, [loading, profile]);

  const updateHealthInsurance = (
    healthInsurance: ISelectObject<HealthInsuranceCompanies>,
  ) => {
    const final =
      healthInsurance.value === HealthInsuranceCompanies.None
        ? null
        : healthInsurance.value;

    const input = { healthInsurance: final };

    return updateHumanFn(input);
  };

  const onSave = async () => {
    const input = {
      birthDate: formState.getValue(BirthDate.getPath()),
      email: formState.getValue(Email.getPath()),
      firstName: formState.getValue(FirstName.getPath()),
      lastName: formState.getValue(LastName.getPath()),
      phone: formState.getValue(Phone.getPath()),
    };

    await updateHumanFn(input);
  };

  const updateHumanFn = async (data: HumanInput) => {
    try {
      await updateHuman(data);
    } catch (e) {
      setToast({
        message:
          "There was an error updating your profile. Please check your answers and try again.",
        severity: "warning",
      });
      console.log(e);
    }
  };

  if (loading) return <LoadingPage full={false} />;

  return (
    <HumanProfileContext.Provider
      value={{
        formState,
        human,
        loading,
        onSave,
        updateHealthInsurance,
        updatePreferredLanguage,
        updateTimezone,
      }}
    >
      {children}
    </HumanProfileContext.Provider>
  );
}
