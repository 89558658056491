/**
 * Email of user, psy or client.
 */
import type { TFunction } from "i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class Email extends DataType<
  string,
  DataTypePaths.Profile.Email
> {
  protected static path = "email";
  protected static defaultValue = "";
  protected static emailRegex =
    /^(([^<>()[\]\\.,;é:\s@"]+(\.[^<>()[\]\\.,;é:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  protected type: any = Email;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          `${BloomUpNamespacesEnum.Validation}:email.mandatory`,
          "Geef een e-mail op.",
        ),
        path: this.getPath(),
        validate: (email: string): boolean => email !== "",
      },
      {
        message: translate(
          `${BloomUpNamespacesEnum.Validation}:email.validity`,
          "Gelieve een geldig e-mailadres in te vullen.",
        ),
        path: this.getPath(),
        validate: (email: string): boolean => Email.emailRegex.test(email),
      },
    ];
  }

  getAsFormStateValue(): Partial<Record<DataTypePaths.Profile.Email, string>> {
    return { email: this.value };
  }
}
