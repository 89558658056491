/**
 * The number of years experience a professional has.
 */
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import DataType from "../../DataType";
import { BloomUpNamespacesEnum } from "../../../components/language/I18Namespaces";
import { DataTypePaths, PossibleFormStatePaths } from "../../dataTypePaths";
import Specialty, { SpecialtyModel } from "./Specialty";

// TODO:
export interface ThemeModel {
  id: string;
  primaryColor: string;
  secondaryColor: string;
  subcategories: Array<SpecialtyModel>;
  theme: string;
  tooltip: string;
  translationKey: string;
}

export default class Theme extends DataType<
  ThemeModel,
  DataTypePaths.Professional.Theme
> {
  protected static path = "theme";
  protected static defaultValue = [];

  protected type: any = Theme;

  protected id: string | undefined;
  protected theme: string | undefined;
  protected primaryColor: string | undefined;
  protected secondaryColor: string | undefined;
  protected subcategories: Array<Specialty> | undefined;
  protected tooltip: string | undefined;
  protected translationKey: string | undefined;

  constructor(model?: ThemeModel) {
    super(model);

    if (!model) return;

    this.id = model.id;
    this.theme = model.theme;
    this.primaryColor = model.primaryColor;
    this.secondaryColor = model.secondaryColor;
    this.translationKey = model.translationKey;
    this.subcategories = model.subcategories
      ? model.subcategories.map((sc) => new Specialty(sc))
      : [];
  }

  public getId(): string | undefined {
    return this.id;
  }

  public getName(): string {
    return `${BloomUpNamespacesEnum.Glossary}:${this.translationKey}`;
  }

  public getPrimaryColor(): string | undefined {
    return this.primaryColor;
  }

  public getSecondaryColor(): string | undefined {
    return this.secondaryColor;
  }

  public getSubcategories(): Array<Specialty> {
    return this.subcategories ?? [];
  }

  public getTranslationKey(): string | undefined {
    return this.getTranslationKey();
  }

  public getSubcategoriesAsSelectOption(): Array<ISelectObject<string>> {
    return this.getSubcategories().map((subItem) => ({
      color: this.secondaryColor,
      label: subItem.getName(),
      toolTip: subItem.getTooltip(),
      value: subItem.getId(),
    }));
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  // static getFormValidation(
  //     translate: TFunction<I18Namespaces>
  // ): Array<IFormValidation> {
  //
  //     // return [
  //     //     {
  //     //         path: this.getPath(),
  //     //         validate: (specialties: Array<string>): boolean =>
  //     //             specialties.length > 0,
  //     //         message: translate(
  //     //             "specialties.mandatory",
  //     //             "Geef minstens 1 specialiteit op."
  //     //         ),
  //     //     },
  //     //     {
  //     //         path: this.getPath(),
  //     //         validate: (specialties: Array<string>): boolean =>
  //     //             specialties.length < 6,
  //     //         message: translate(
  //     //             "specialties.toomany",
  //     //             "Geef maximum 5 specialiteiten op."
  //     //         ),
  //     //     },
  //     // ];
  // }

  static isTheme = (dataStructure: Theme): dataStructure is Theme => {
    return !!(
      dataStructure.subcategories &&
      dataStructure.theme &&
      dataStructure.getSubcategories
    );
  };

  getAsFormStateValue(): Partial<Record<PossibleFormStatePaths, ThemeModel>> {
    return { theme: this.value };
  }
}
