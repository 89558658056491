import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Button from "../../ui/buttons/Button";
import TextField from "../../ui/form/TextField";
import FormLabel from "../../ui/form/FormLabel";
import { I18Namespaces } from "../../language/I18Namespaces";

const PREFIX = "ClientForm";

const classes = {
  boxInner: `${PREFIX}-boxInner`,
  buttons: `${PREFIX}-buttons`,
  subtitle: `${PREFIX}-subtitle`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.boxInner}`]: {
    padding: theme.spacing(3, 4),
  },

  [`& .${classes.subtitle}`]: {
    fontSize: 16,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },

  [`& .${classes.buttons}`]: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface IClientForm {
  formState: any;
  onSubmit: () => void;
  toggleModal: () => void;
}

export default function ClientForm({
  formState,
  onSubmit,
  toggleModal,
}: IClientForm) {
  const { t: translate } = useTranslation<I18Namespaces>([
    "professional",
    "common",
  ]);

  return (
    <Root>
      <Box className={classes.boxInner}>
        <Typography gutterBottom variant="h2">
          {translate("professional:add.client.title", "Nieuwe Cliënt")}
        </Typography>
        <FormLabel
          label={translate(
            "professional:add.client.description",
            "Voeg een bestaande of nieuwe cliënt toe. Deze zal een e-mail ontvangen om zich te registreren indien die nog niet op het platform zit. Als we deze cliënt wel al kennen vragen we toestemming om je profiel met dat van hen te linken.",
          )}
        />
        <Box mt={2}>
          <TextField
            autoComplete="none"
            autoFocus
            label={translate("common:email", "E-mail")}
            type="email"
            {...formState.getInputProps("email")}
            onKeyPress={(e) => (e.key === "Enter" ? onSubmit() : null)}
          />
        </Box>
        <Box className={classes.buttons} mt={2}>
          <Button
            label={translate("common:cancel", "Annuleer")}
            onClick={toggleModal}
            variant="text"
          />
          <Button
            label={translate("common:invite.label", "Nodig uit")}
            onClick={onSubmit}
            style={{ marginLeft: 32 }}
          />
        </Box>
      </Box>
    </Root>
  );
}
