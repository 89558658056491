import { MultiValue } from "react-select";
import BaseGroupSelect, { IGroupSelectProps } from "./BaseGroupSelect";
import { ISelectObject } from "./BaseSimpleSelect";

interface IMultiSelect<T = any, K = any> extends IGroupSelectProps<T> {
  onChange: (value: MultiValue<ISelectObject<T>>) => void;
  value?: K[];
}

export default function MultiGroupSelect<T, K>({
  onChange,
  name,
  hasError,
  errorText,
  value = [],
  options,
  placeholder,
  menuWithTooltip,
}: IMultiSelect<T, K>) {
  return (
    <BaseGroupSelect<T>
      closeMenuOnSelect={false}
      errorText={errorText}
      hasError={hasError}
      isMulti={true}
      menuWithTooltip={menuWithTooltip}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      values={value}
    />
  );
}
