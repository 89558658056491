/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLazyFetchConsultationByUuid from "../../../api/consultations/hooks/useLazyFetchConsultationByUuid";
import usePayForConsultation from "../../../api/payments/hooks/usePayForConsultation";
import usePaymentFormState from "../usePaymentFormState";
import { RouteNames } from "../../routes/routeNames";
import NationalId from "../../../data-model/types/human/NationalId";
import useUpdateHumanMutation from "../../../api/humans/hooks/useUpdateHumanMutation";
import HealthInsurance from "../../../data-model/types/reimbursement/HealthInsurance";
import useRequestReimbursement from "../../../api/reimbursements/hooks/useRequestReimbursement";
import CashTransaction from "../../../data-model/types/CashTransaction";
import PaymentContext from "./PaymentContext";

export default function PaymentContextProvider({ children }) {
  const { fetchConsultation, consultation } = useLazyFetchConsultationByUuid();

  const navigate = useNavigate();
  const { updateHuman } = useUpdateHumanMutation();
  const [checked, setChecked] = useState<boolean>(false);
  const { requestReimbursement: requestReimbursementExecute } =
    useRequestReimbursement();

  const formState = usePaymentFormState();
  const [paymentStarted, setPaymentStarted] = useState<boolean>(false);

  const {
    payForConsultation,
    loading: paymentOngoing,
    result,
  } = usePayForConsultation();

  useEffect(() => {
    if (!paymentOngoing && result) {
      window.location.replace(result);
    }
  }, [paymentOngoing, result]);

  useEffect(() => {
    if (!consultation) return;

    const cashTransaction: CashTransaction | undefined =
      consultation.getCashTransaction();

    if (cashTransaction && !paymentOngoing && !paymentStarted) {
      setPaymentStarted(true);
      payForConsultation(cashTransaction.getID());
    }
  }, [consultation, paymentOngoing]);

  const pay = async (uuid: string) => {
    await updateHuman({
      healthInsurance: formState.getValue(HealthInsurance.getPath()),
    });

    fetchConsultation(uuid);
  };

  const formIsValid = (path: string) => formState.validate(path);

  const requestReimbursement = async (uuid: string) => {
    if (formIsValid(NationalId.getPath())) {
      await requestReimbursementExecute({
        consultationUuid: uuid,
        nationalId: formState.getValue(NationalId.getPath()),
      });
      navigate(
        `${RouteNames.Home.Consultations.ShowConsultation.path.replace(
          ":uuid",
          uuid,
        )}?reimbursementState=requested`,
      );
    }
  };

  const cancelSignup = () => {
    navigate(RouteNames.Home.Consultations.path);
  };
  // if (/*|| loading*/)
  //     return <LoadingPage full={false} />;

  return (
    <PaymentContext.Provider
      value={{
        cancelSignup,
        checked,
        formState,
        pay,
        requestReimbursement,
        setChecked,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}
