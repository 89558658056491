import { useQuery } from "@apollo/client";
import { InfoOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import "moment-timezone";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "../../../../../api/__generated__";
import useSelectTranslation from "../../../../../api/languages/useSelectTranslation";
import Human from "../../../../../data-model/types/human/Human";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";
import InfoItemSidePane from "./InfoItemSidePane";
import { OrgProfileDialog } from "../../../../organizations/OrgProfileDialog";

const PREFIX = "InfoItemSidePanePartnerBox";

const classes = {
  partner: `${PREFIX}-partner`,
};

const StyledInfoItemSidePane = styled(InfoItemSidePane)(() => ({
  [`& .${classes.partner}`]: {
    flexGrow: 1,
    marginBottom: 0,
  },
}));

const videoCallPartnerBox = graphql(`
  query VideoCallPartnerBox($organizationId: Int!) {
    organizationById(id: $organizationId) {
      id
      name {
        ...useSelectTranslationStringInput
      }
      ...OrgProfileDialogOrganization
    }
  }
`);

const InfoItemSidePanePartnerBox = ({
  organizationId,
  human,
}: {
  human: Human;
  organizationId: number;
}) => {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Partners,
  ]);

  const [orgProfileDialogOpen, setOrgProfileDialogOpen] = useState(false);

  const selectTranslation = useSelectTranslation();
  const { data } = useQuery(videoCallPartnerBox, {
    variables: {
      organizationId,
    },
  });

  const creditsLeft = human.getCreditsLeft();

  const getCreditsLeftText = () => {
    if (creditsLeft === null) {
      //This should not happen.
      console.warn("Credits left was null, should not happen.");

      return null;
    }

    return `${
      creditsLeft === 0
        ? t("professional:call.drawer.partner.creditsleft.part_1")
        : creditsLeft
    } ${t("professional:call.drawer.partner.creditsleft.part_2")}`;
  };

  if (!getCreditsLeftText()) return null;

  return (
    <StyledInfoItemSidePane subTitle={t("common:partner")}>
      <Box className={classes.partner} mb={4}>
        <Box
          onClick={() => setOrgProfileDialogOpen(true)}
          sx={{ alignItems: "center", cursor: "pointer", display: "flex" }}
        >
          {selectTranslation(data?.organizationById?.name)}
          <InfoOutlined color="action" sx={{ marginLeft: "4px" }} />
        </Box>
        <br />
        <b>{getCreditsLeftText()}</b>
        <br />
        <br />
        {creditsLeft && creditsLeft > 0
          ? t("professional:call.drawer.partner.follow_up_sessions")
          : null}
      </Box>
      {data?.organizationById && (
        <OrgProfileDialog
          onClose={() => {
            setOrgProfileDialogOpen(false);
          }}
          open={orgProfileDialogOpen}
          organization={data.organizationById}
        />
      )}
    </StyledInfoItemSidePane>
  );
};

export default InfoItemSidePanePartnerBox;
