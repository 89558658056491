import { graphql } from "../__generated__";

export const QUERY_GET_FULL_CALENDAR_EVENTS = graphql(`
  query getFullCalendarEvents($endDate: DateTime, $startDate: DateTime) {
    calendarEvents(
      asFullCalendar: true
      endDate: $endDate
      startDate: $startDate
    ) {
      ...FullCalendarEvent
    }
  }
`);
