import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { IconButton, Tooltip, Box } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import PauseIcon from "@mui/icons-material/Pause";
import PlayIcon from "@mui/icons-material/PlayArrow";
import { useCallback, useMemo, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import useInterval from "../../../../custom-hooks/useInterval";
import { I18Namespaces } from "../../../language/I18Namespaces";
import { ICallClock } from "./CallClockWithContext";

const PREFIX = "ONE_MINUTE_MS";

const classes = {
  callClockButton: `${PREFIX}-callClockButton`,
  callClockButtons: `${PREFIX}-callClockButtons`,
  clock: `${PREFIX}-clock`,
  clockWithButtons: `${PREFIX}-clockWithButtons`,
  clockWrapper: `${PREFIX}-clockWrapper`,
  controlLabel: `${PREFIX}-controlLabel`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.clock}`]: {
    color: theme.palette.bloom.dark,
  },

  [`&.${classes.clockWithButtons}`]: {
    display: "flex",
  },

  [`&.${classes.clockWrapper}`]: {
    border: "3px solid",
    borderColor: theme.palette.bloom.main,
    borderRadius: 5,
    marginTop: 10,
    maxWidth: "110px",
    padding: theme.spacing(1, 2),
    width: "100%",
    zIndex: 1000,
  },

  [`& .${classes.controlLabel}`]: {
    fontSize: theme.typography.sizes.large,
    padding: "2px 0px 0px 8px",
    whiteSpace: "nowrap",
  },

  [`& .${classes.callClockButtons}`]: {
    display: "flex",
    flexGrow: 5,

    padding: "5px 16px 0",
  },

  [`& .${classes.callClockButton}`]: {
    "&:hover": {
      color: theme.palette.bloom.light,
    },
    color: theme.palette.bloom.main,
    height: 25,
    margin: "10px 0 0 10px",

    width: 25,
  },
}));

export const ONE_MINUTE_MS = 1000 * 60;

export const ONE_HOUR_MS = ONE_MINUTE_MS * 60;

export const FULL_DAY_MS = ONE_HOUR_MS * 24;

export interface ClockState {
  count: number;
  displayValue: string;
}

interface ICallClockOverrides extends ICallClock {
  clockValueOverride?: number;
  defaultCount?: number;
  defaultDisplayValue?: string;
  displayValueOverride?: string;
  resetClockOverride?: () => void;
  runningOverride?: boolean;
  toggleRunningOverride?: () => void;
  updateClockOverride?: (count: number) => void;
}

/**
 * This component can be used as a standalone clock (use 'runningOverride' to control running state
 * from a HOC) or as component that is fully controlled by a HOC or a context. This allows for
 * multiple clocks being controlled by the same state and thus to stay in sync.
 */
export default function CallClock({
  displayClock: display = true,
  smallVersion = false,
  clockValueOverride,
  runningOverride,
  defaultDisplayValue = "00:00:00",
  defaultCount = 0,
  displayValueOverride,
  updateClockOverride,
  toggleRunningOverride,
  resetClockOverride,
}: ICallClockOverrides) {
  const { t: translate } = useTranslation<I18Namespaces>("ui");

  // Local state for standalone operation mode
  const [localRunning, setLocalRunning] = useState<boolean>(true);
  const [localClockState, setLocalClockState] = useState<ClockState>({
    count: defaultCount,
    displayValue: defaultDisplayValue,
  });

  // Local update function for standalone operation mode
  const localUpdateClock = useCallback(
    (currentCount: number) => {
      const hours = Math.floor((currentCount % FULL_DAY_MS) / ONE_HOUR_MS);

      const minutes = Math.floor((currentCount % ONE_HOUR_MS) / ONE_MINUTE_MS);

      const seconds = Math.floor((currentCount % ONE_MINUTE_MS) / 1000);

      const hoursTxt: string = hours < 10 ? `0${hours}` : `${hours}`;
      const minutesTxt: string = minutes < 10 ? `0${minutes}` : `${minutes}`;

      const secondsTxt = seconds < 10 ? `0${seconds}` : `${seconds}`;

      const newTime = `${hoursTxt}:${minutesTxt}:${secondsTxt}`;

      setLocalClockState({
        count: currentCount + 1000,
        displayValue: newTime,
      });
    },
    [setLocalClockState],
  );

  const _running = runningOverride ?? localRunning;
  const _updateClockFunction = useMemo(
    () =>
      updateClockOverride && clockValueOverride !== undefined
        ? () => updateClockOverride(clockValueOverride)
        : () => localUpdateClock(localClockState.count),
    [
      updateClockOverride,
      clockValueOverride,
      localUpdateClock,
      localClockState,
    ],
  );

  useInterval(
    _updateClockFunction,
    _running && !updateClockOverride ? 1000 : null,
  );

  const localResetClock = () => {
    setLocalRunning(false);
    setLocalClockState({ count: 0, displayValue: "00:00:00" });
  };

  const localToggleRunning = () => setLocalRunning(!localRunning);

  const _resetClockFn = resetClockOverride ?? localResetClock;
  const _toggleRunningFn = toggleRunningOverride ?? localToggleRunning;
  const _displayValue = displayValueOverride ?? localClockState.displayValue;

  if (!display) return null;

  return smallVersion ? (
    <Box className={classes.controlLabel}>{_displayValue}</Box>
  ) : (
    <Root className={classes.clockWithButtons}>
      <ClockDisplay value={_displayValue} />
      <Box className={classes.callClockButtons}>
        <Tooltip
          title={
            _running
              ? (translate("ui:clock.pause", "Pauzeer de klok") as string)
              : (translate("ui:clock.restart", "Hervat de klok") as string)
          }
        >
          <IconButton
            className={classes.callClockButton}
            onClick={_toggleRunningFn}
            size="large"
          >
            {_running ? <PauseIcon /> : <PlayIcon />}
          </IconButton>
        </Tooltip>

        <Tooltip title={translate("ui:clock.reset", "Reset de klok") as string}>
          <IconButton
            className={classes.callClockButton}
            onClick={_resetClockFn}
            size="large"
          >
            <RotateLeftIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Root>
  );
}

const ClockDisplay = memo(({ value }: { value: string }) => {
  return (
    <div className={classes.clockWrapper}>
      <Typography align="center" className={classes.clock} variant="body1">
        {value}
      </Typography>
    </div>
  );
});
