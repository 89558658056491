import { lighten, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const PREFIX = "UploadErrorAlert";

const classes = {
  boxedBox: `${PREFIX}-boxedBox`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.boxedBox}`]: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.5),
    borderRadius: 5,
    margin: "24px auto 70px",
    maxWidth: "80%",
    padding: theme.spacing(3),
    width: "100%",
  },
}));

const UploadErrorAlert = () => {
  return (
    <StyledBox className={classes.boxedBox}>
      <Typography align="center" variant="h5">
        Opmerking
      </Typography>
      <Typography align="center" variant="body2">
        Jouw account werd geregistreerd, maar we konden jouw documenten niet
        opslaan. Kan je deze doorsturen via support@bloomup.org zodat we jouw
        account kunnen nakijken?
      </Typography>
    </StyledBox>
  );
};

export default UploadErrorAlert;
