import { memo } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { BloomColor } from "../../../../layout/wizard/StepFooter";

const PREFIX = "Block";

const classes = {
  block: `${PREFIX}-block`,
};

const StyledBox = styled(Box)<{
  bloomColor: BloomColor;
  percentage: number;
}>(({ theme, percentage, bloomColor }) => ({
  [`&.${classes.block}`]: {
    background: `linear-gradient(to left ,${theme.palette.whiteSmoke.main} ${
      100 - percentage
    }%, ${theme.palette[bloomColor].main} ${100 - percentage}%)`,
    borderRadius: 50,
    height: 10,
    width: "100%",
  },
}));

interface IBlock {
  bloomColor: BloomColor;
  percentage: number;
}

const Block = ({ percentage, bloomColor }: IBlock) => {
  return (
    <StyledBox
      bloomColor={bloomColor}
      className={classes.block}
      percentage={percentage}
    ></StyledBox>
  );
};

export default memo(Block);
