import { useMutation } from "@apollo/client";
import { MUTATION_HUMAN_REGISTER } from "../mutations";
import { APIHuman } from "../humans";

export default function useCompleteHumanRegistrationMutation() {
  const [completeHumanRegistrationFunction, { data, error, loading }] =
    useMutation<APIHuman.Register.Result, APIHuman.Register.APIInput>(
      MUTATION_HUMAN_REGISTER,
    );

  const completeRegistration = async (input: APIHuman.Register.Input) =>
    completeHumanRegistrationFunction({
      variables: { input },
    });

  return {
    completeHumanRegistration: completeRegistration,
    data,
    error,
    loading,
  };
}
