import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { Box } from "@mui/material";
import moment from "moment";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import Label from "../../../ui/form/FormLabel";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import { CustomDatePicker } from "../../../ui/form/DatePicker";
import BirthDate from "../../../../data-model/types/profile/BirthDate";

const PREFIX = "BirthDateStep";

const classes = {
  container: `${PREFIX}-container`,
  description: `${PREFIX}-description`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.container}`]: {
    marginBottom: 50,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 300,
  },

  [`& .${classes.description}`]: {
    fontSize: theme.typography.sizes.normal,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 500,
  },
}));

export default function BirthDateStep() {
  const { next, previous, step, totalSteps, formState } = useContext(
    ProfessionalOnboardingContext,
  );

  const { t: translate } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Professional,
  );

  const error = formState.testForErrors(BirthDate.getPath());

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title")}
        labelBack={translate("common:onboarding.button.label.previous")}
        labelNext={translate("common:onboarding.button.label.next")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate("professional:onboarding.birthdate.title")}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <StyledBox>
          <Box className={classes.container}>
            <Label
              label={translate("professional:onboarding.birthdate.datepicker")}
            />
            <CustomDatePicker
              autoFocus
              disableFuture
              emptyLabel=""
              format="YYYY"
              fullWidth
              maxDate={moment().subtract(18, "year")}
              minDate={moment().subtract(100, "year")}
              openTo="year"
              placeholder={translate("ui:birthyear.placeholder")}
              style={{
                marginBottom: 20,
              }}
              variant="inline"
              views={["year"]}
              {...formState.getInputProps(BirthDate.getPath())}
              id={`test_onboarding_professional_birthdate_datepicker`}
            />
          </Box>
          <Box className={classes.description}>
            {translate("professional:onboarding.birthdate.description")}
          </Box>
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
