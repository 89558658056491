/* eslint-disable react-hooks/exhaustive-deps */

import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";

import { useCallback } from "react";
import moment from "moment";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import Button from "../../ui/buttons/Button";
import Consultation from "../../../data-model/types/consultation/Consultation";
import { IWithChildren } from "../../../baseInterfaces";
import CashTransaction from "../../../data-model/types/CashTransaction";

const PREFIX = "CashTransactionActions";

const classes = {
  buttonContainer: `${PREFIX}-buttonContainer`,
  fontContainer: `${PREFIX}-fontContainer`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.buttonContainer}`]: {
    background: theme.palette.bloom.light,
    borderRadius: 10,
    marginBottom: 35,
    padding: 20,
  },

  [`& .${classes.fontContainer}`]: {
    fontSize: theme.typography.sizes.normal,
    marginBottom: 20,
  },
}));

interface CashTransactionActionsProps extends IWithChildren {
  cashTransaction: CashTransaction;
  consultation: Consultation;
  redirectToPayment: () => void;
}

export default function CashTransactionActions({
  cashTransaction,
  redirectToPayment,
  consultation,
  children,
}: CashTransactionActionsProps) {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Payments,
  ]);

  const getLabel = useCallback(() => {
    const status = cashTransaction.getStatus();

    switch (status) {
      case "new":

      // falls through
      case "open":
        return translate("payments:pay.now", "Betaal nu");

      case "failed":

      // falls through
      case "expired":

      // falls through
      case "canceled":
        return translate("payments:payment.retry", "Probeer opnieuw");

      default:
        return "";
    }
  }, [cashTransaction]);

  // Don't render any action if the status is 'Paid'
  if (cashTransaction.getStatus() === "paid") return null;

  const afterConsult = moment(consultation.getStartDate()).isBefore(moment());

  return (
    <StyledBox className={classes.buttonContainer}>
      {children}
      <Typography className={classes.fontContainer}>
        {!afterConsult && translate("payments:explainer.before")}
        {afterConsult && translate("payments:explainer.after")}
      </Typography>
      <Button
        bloomColor="porple"
        icon={<PaymentIcon />}
        label={getLabel()}
        onClick={redirectToPayment}
      />
    </StyledBox>
  );
}
