import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Button from "@mui/material/Button";

const PREFIX = "ExpansionToggle";

const classes = {
  toggle: `${PREFIX}-toggle`,
};

const StyledBox = styled(Box)<{ primary: boolean }>(({ theme, primary }) => ({
  [`& .${classes.toggle}`]: {
    "&:hover": {
      background: "transparent",
    },
    color: primary ? theme.palette.primary.main : theme.palette.black.main,
    fontSize: theme.typography.sizes.normal,
    padding: 0,
    textTransform: "none",
  },
}));

const ExpansionToggle = ({
  onClick,
  isOpen,
  primary,
  label,
  mb = 0,
  mt = 0,
}) => {
  return (
    <StyledBox mb={mb} mt={mt} primary={primary}>
      <Button
        className={classes.toggle}
        endIcon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={onClick}
      >
        {label}
      </Button>
    </StyledBox>
  );
};

export default ExpansionToggle;
