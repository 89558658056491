import { useContext } from "react";
import { UserRoles } from "../../../data-model/types/User";
import { IWithChildren } from "../../../baseInterfaces";
import AuthContext from "../../providers/auth/AuthContext";
import Guard from "./Guard";

export default function ProfessionalGuard({ children }: IWithChildren) {
  const { currentUser } = useContext(AuthContext);

  return (
    <Guard
      condition={
        currentUser?.getRole() === UserRoles.ADMIN ||
        currentUser?.getRole() === UserRoles.PROFESSIONAL
      }
    >
      {children}
    </Guard>
  );
}
