import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LoadingPage from "../../layout/LoadingPage";
import UnavailableSection from "../../layout/UnavailableSection";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { IConsultationsOverviewContext } from "../context/consultations";
import ConsultationsOverviewContext from "../context/ConsultationsOverviewContext";
import ConsultationList from "./ConsultationList";

// This shows a list of consultations.
const ConsultationsOverview = () => {
  const { loading, groupedConsultations } =
    useContext<IConsultationsOverviewContext>(ConsultationsOverviewContext);

  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
  ]);

  if (loading && !groupedConsultations)
    return <LoadingPage full logText="ConsultationsOverview" />;

  return (
    <>
      {groupedConsultations.length > 0 &&
        groupedConsultations.map((list, i) => (
          <ConsultationList key={list.listName} {...list} pt={i ? 2 : 0} />
        ))}
      {groupedConsultations.length === 0 && (
        <UnavailableSection text={t("professional:consultation_list.empty")} />
      )}
    </>
  );
};

export default ConsultationsOverview;
