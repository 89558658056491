/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Theme } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { UserRoles } from "../../../data-model/types/User";
import { I18Namespaces } from "../../language/I18Namespaces";
import AddClientModalWithState from "./AddClientModalWithState";
import ClientList from "./ClientList";
import ClientsForProfessionalsContext from "./context/ClientsForProfessionalsContext";
import ClientDetailWithState from "./ClientDetail";
import { IClientsForProfessionalsContext } from "./context/clientsForProfessionals";

const ClientsForProfessionalsPage = () => {
  const { selectedClient, clients, modalVisible, toggleModal, setClient } =
    useContext<IClientsForProfessionalsContext>(ClientsForProfessionalsContext);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setClient(parseInt(id));
    }
  }, [id]);

  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t: translate } = useTranslation<I18Namespaces>(["client"]);
  const pageTitle = translate("client:clients.title", "Cliënten");
  const buttonLabel = translate("client:clients.button.add_client", "Cliënt");
  const handleClick = (id: number) => setClient(id);

  if (small) {
    return selectedClient ? (
      <ClientDetailWithState
        client={selectedClient}
        handleClick={() => setClient(0)}
        handleClientNotFound={() =>
          console.log("clients for professionals handle client not found")
        }
      />
    ) : (
      <>
        <ClientList
          buttonIcon={<PersonAddIcon />}
          buttonLabel={buttonLabel}
          clients={clients}
          handleButton={toggleModal}
          handleClick={handleClick}
          isHuman={false}
          pageTitle={pageTitle}
          roleType={UserRoles.PROFESSIONAL}
          selectedClient={selectedClient}
        />
        {modalVisible && (
          <AddClientModalWithState
            clients={clients}
            isModalOpen={modalVisible}
            toggleModal={toggleModal}
          />
        )}
      </>
    );
  }

  return (
    <>
      <ClientList
        buttonIcon={<PersonAddIcon />}
        buttonLabel={buttonLabel}
        clients={clients}
        handleButton={toggleModal}
        handleClick={handleClick}
        isHuman={false}
        pageTitle={pageTitle}
        roleType={UserRoles.PROFESSIONAL}
        selectedClient={selectedClient}
      />
      {selectedClient && (
        <ClientDetailWithState
          client={selectedClient}
          handleClick={handleClick}
          handleClientNotFound={() => console.log("Client not found")}
        />
      )}
      {modalVisible && (
        <AddClientModalWithState
          clients={clients}
          isModalOpen={modalVisible}
          toggleModal={toggleModal}
        />
      )}
    </>
  );
};

export default ClientsForProfessionalsPage;
