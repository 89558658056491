import { graphql } from "../__generated__";

// The result of this query is meant to be seen by humans
export const QUERY_FETCH_CONSULTATIONS_ALL_FOR_HUMAN = graphql(`
  #info for this request is included in the request context
  query allHumanConsultations {
    allConsultationsForHuman {
      edges {
        node {
          ...ConsultationWithPayment
        }
      }
    }
  }
`);

// The result of this query is meant to be seen by professionals
export const QUERY_FETCH_CONSULTATIONS_ALL_FOR_PROFESSIONAL = graphql(`
  #info for this request is included in the request context
  query allProfessionalConsultations {
    allConsultationsForProfessional {
      edges {
        node {
          ...Consultation
        }
      }
    }
  }
`);

// The result of this query is meant to be seen by professionals
export const QUERY_FETCH_CONSULTATIONS_FOR_PROFESSIONAL_AND_HUMAN = graphql(`
  #info for this request is included in the request context
  query consultationsForProfessionalAndHumanResolver($humanId: Int!) {
    consultationsForProfessionalAndHuman(humanId: $humanId) {
      edges {
        node {
          ...Consultation
        }
      }
    }
  }
`);

export const QUERY_FETCH_CONSULTATION_FOR_CALL_BY_UUID = graphql(`
  query fetchConsultationForCallByUuid($uuid: String!) {
    consultation(uuid: $uuid) {
      ...ConsultationForCall
    }
  }
`);

export const QUERY_FETCH_CONSULTATION_WITH_CASH_TRANSACTION = graphql(`
  query fetchConsultationWithCashTransaction($uuid: String!) {
    consultation(uuid: $uuid) {
      ...ConsultationWithPayment
      id
    }
  }
`);
