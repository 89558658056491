import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";

import { Box } from "@mui/material";
import moment from "moment";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import YesNoQuestion from "../../../ui/form/YesNoQuestion";
import Label from "../../../ui/form/FormLabel";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import {
  FORM_KEY_DOES_SUPERVISION,
  FORM_KEY_IS_FIRST_LINE,
} from "../useProfessionalOnboardFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import { CustomDatePicker } from "../../../ui/form/DatePicker";
import Experience from "../../../../data-model/types/professional/Experience";

const PREFIX = "ExperienceStep";

const classes = {
  container: `${PREFIX}-container`,
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.container}`]: {
    marginBottom: 50,
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 400,
  },
}));

export default function ExperienceStep() {
  const { next, previous, step, totalSteps, formState } = useContext(
    ProfessionalOnboardingContext,
  );

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
  ]);

  const error = formState.testForErrors(Experience.getPath());

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je Profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={() => next()}
        showLanguageSelector={false}
        step={step}
        subhead={translate(
          "professional:onboarding.experience.title",
          "Ervaring",
        )}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <StyledBox className={classes.container}>
          <Label
            label={translate(
              "professional:onboarding.experience.years.description",
              "Wanneer ben je gestart met het zelfstandig geven van therapie?",
            )}
          />
          <CustomDatePicker
            autoFocus
            format="MM/YYYY"
            fullWidth
            maxDate={moment()}
            minDate={moment().subtract(100, "year")}
            openTo="year"
            placeholder={translate("ui:experience.placeholder")}
            style={{
              marginBottom: 20,
            }}
            variant="inline"
            views={["year", "month"]}
            {...formState.getInputProps(Experience.getPath())}
            id={`test_onboarding_professional_experience_years_datepicker`}
            value={moment(formState.getInputProps(Experience.getPath()).value)}
          />
          <YesNoQuestion
            formLabel={translate(
              "professional:onboarding.experience.supervision.label",
              "Bied je supervisie aan, ben je klinisch psycholoog en heb je meer dan 5 jaar therapie ervaring?",
            )}
            hasError={false}
            name={FORM_KEY_DOES_SUPERVISION}
            noBtnTestId={`test_onboarding_professional_experience_supervision_no`}
            onChange={(val) => {
              formState.setValue(FORM_KEY_DOES_SUPERVISION, val === "1");
            }}
            style={{
              marginBottom: 20,
            }}
            value={formState.getValue(FORM_KEY_DOES_SUPERVISION) ? "1" : "0"}
            yesBtnTestId={`test_onboarding_professional_experience_supervision_yes`}
          />
          <YesNoQuestion
            formLabel={translate(
              "professional:onboarding.experience.firstline.label",
              "Ben je geconventioneerd eerstelijnspsycholoog?",
            )}
            hasError={false}
            name={FORM_KEY_IS_FIRST_LINE}
            noBtnTestId={`test_onboarding_professional_experience_firstline_no`}
            onChange={(val) => {
              formState.setValue(FORM_KEY_IS_FIRST_LINE, val === "1");
            }}
            style={{
              marginBottom: 20,
            }}
            value={formState.getValue(FORM_KEY_IS_FIRST_LINE) ? "1" : "0"}
            yesBtnTestId={`test_onboarding_professional_experience_firstline_yes`}
          />
        </StyledBox>
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
