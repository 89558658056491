import { createContext } from "react";
import { IOnlineContext } from "./onlineContext.s";

export const defaultOnlineValues: IOnlineContext = {
  allUsersChannel: undefined,
  humansChannel: undefined,
  isReady: false,
  professionalsChannel: undefined,
  pusher: undefined,
  setAllUsersChannel: () => {},
  setHumansChannel: () => {},
  setProfessionalsChannel: () => {},
  setSpecificWaitingRoomChannel: () => {},
  setWaitingRoomChannel: () => {},
  specificWaitingRoomChannel: undefined,
  unsubscribeFromAllChannels: () => {},
  unsubscribeFromChannel: () => {},
  unsubscribeFromChannelWithName: () => {},
  waitingRoomChannel: undefined,
};

const OnlineContext = createContext<IOnlineContext>(defaultOnlineValues);

export default OnlineContext;
