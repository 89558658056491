import React from "react";
import Professional from "../../../data-model/types/professional/Professional";
import ProfessionalDetail from "./ProfessionalDetail";

interface IProfessionalDetailWithState {
  handleClick: (id: number | undefined) => void;
  professional: Professional;
}

export default function ProfessionalDetailWithState({
  professional,
  handleClick,
}: IProfessionalDetailWithState) {
  //TODO: this query should check (in the backend) if the professionalID actually belongs to
  // a professional that is linked to this human

  return (
    <ProfessionalDetail handleClick={handleClick} professional={professional} />
  );
}
