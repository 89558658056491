import { Box, Divider, SvgIconTypeMap, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "moment-timezone";
import { useContext } from "react";
import { IWithChildren } from "../../../../../baseInterfaces";
import { ICallContext } from "../../context/Call";
import CallContext from "../../context/CallContext";

const PREFIX = "SidePane";

const classes = {
  headerIconContainer: `${PREFIX}-headerIconContainer`,
  headerTitleContainer: `${PREFIX}-headerTitleContainer`,
  hidePaneButtonContainer: `${PREFIX}-hidePaneButtonContainer`,
  paneBody: `${PREFIX}-paneBody`,
  paneFooter: `${PREFIX}-paneFooter`,
  paneHeader: `${PREFIX}-paneHeader`,
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.black.main,
    display: "flex",
    flexFlow: "column nowrap",
    fontSize: theme.typography.sizes.large,
    justifyContent: "space-between",
    margin: 5,
    overflow: "auto",
    width: "100%",
  },

  [`& .${classes.paneHeader}`]: {
    alignItems: "center",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
    minHeight: 55,
    paddingLeft: 30,
  },

  [`& .${classes.hidePaneButtonContainer}`]: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    flexGrow: 1,
    flexWrap: "nowrap",
    justifyContent: "center",
  },

  [`& .${classes.headerTitleContainer}`]: {
    display: "flex",
    flexGrow: 7,
    flexWrap: "nowrap",
  },

  [`& .${classes.title}`]: {
    marginLeft: 10,
    ...theme.typography.h5,
    fontWeight: 700,
  },

  [`& .${classes.headerIconContainer}`]: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    flexWrap: "nowrap",
    fontSize: 30,
  },

  [`& .${classes.paneBody}`]: {
    flexGrow: 6,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
  },

  [`& .${classes.paneFooter}`]: {
    flexGrow: 1,
    minHeight: 30,
    paddingLeft: 30,
  },
}));

interface IClientInformationDrawer extends IWithChildren {
  FooterContent?: any;
  title: string;
  TitleIcon: OverridableComponent<SvgIconTypeMap<unknown, "svg">>;
}

const SidePane = ({
  TitleIcon,
  title,
  children,
  FooterContent,
}: IClientInformationDrawer) => {
  const { returnToPreviousPane } = useContext<ICallContext>(CallContext);

  return (
    <StyledBox className={classes.root}>
      <Box className={classes.paneHeader}>
        <Box className={classes.headerIconContainer}>
          <TitleIcon />
        </Box>
        <Box className={classes.headerTitleContainer}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
        <Box
          className={classes.hidePaneButtonContainer}
          onClick={returnToPreviousPane}
        >
          <ArrowBackIosIcon />
        </Box>
      </Box>
      <Divider />
      <Box className={classes.paneBody}>{children}</Box>
      {FooterContent && (
        <>
          <Divider />
          <Box className={classes.paneFooter}>
            <FooterContent />
          </Box>
        </>
      )}
    </StyledBox>
  );
};

export default SidePane;
