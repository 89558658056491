import { Route, Routes } from "react-router-dom";
import ProfessionalsPresence from "../../utils/pusher/channels/components/ProfessionalPresence";
import ProfessionalCallPageWithState from "../pages/professional-call/ProfessionalCallPageWithState";
import OnboardRequired from "./guards/OnboardedGuard";
import ProfessionalGuard from "./guards/ProfessionalGuard";
import { RouteNames } from "./routeNames";

export default function ProfessionalCallRoutes() {
  return (
    <ProfessionalGuard>
      <OnboardRequired>
        <ProfessionalsPresence>
          <Routes>
            <Route
              element={<ProfessionalCallPageWithState />}
              path={`${RouteNames.Call.Enter.name}/*`}
            />
          </Routes>
        </ProfessionalsPresence>
      </OnboardRequired>
    </ProfessionalGuard>
  );
}
