import { I18nextProvider } from "react-i18next";
import { defaultBeamsProviderValues } from "../../utils/pusher/beams/context/BeamsContext";
import BeamsProvider from "../../utils/pusher/beams/context/BeamsProvider";
import AllUsersPresence from "../../utils/pusher/channels/components/AllUsersPresence";
import OnlineContextProvider from "../../utils/pusher/channels/context/OnlineContextProvider";
import { IWithChildren } from "../../baseInterfaces";
import i18n from "../utils/i18n";
import { defaultOnlineValues } from "../../utils/pusher/channels/context/OnlineContext";
import ApolloClientProvider from "./ApolloClientProvider";
import AuthStateProvider from "./auth/AuthStateProvider";
import MatomoClientProvider from "./matomo/MatomoClientProvider";
import { defaultToastProviderValues } from "./toast/ToastContext";
import { defaultAuthProviderValues } from "./auth/AuthContext";
import ToastProvider from "./toast/ToastProvider";

export default function Providers({ children }: IWithChildren) {
  return (
    <MatomoClientProvider>
      <ApolloClientProvider>
        <I18nextProvider i18n={i18n}>
          <AuthStateProvider {...defaultAuthProviderValues}>
            <OnlineContextProvider {...defaultOnlineValues}>
              <AllUsersPresence>
                <BeamsProvider {...defaultBeamsProviderValues}>
                  <ToastProvider {...defaultToastProviderValues}>
                    {children}
                  </ToastProvider>
                </BeamsProvider>
              </AllUsersPresence>
            </OnlineContextProvider>
          </AuthStateProvider>
        </I18nextProvider>
      </ApolloClientProvider>
    </MatomoClientProvider>
  );
}
