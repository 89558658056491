import { graphql } from "../__generated__";

export const INITIAL_DATA_QUERY = graphql(`
  query InitialData {
    currentUser {
      email
      firstName
      human {
        canPayWithCredits
        id
      }
      id
      lastName
      onboardedAt
      phone
      preferredLanguage
      professional {
        avatar {
          url
        }
        id
      }
      timezone
    }
  }
`);
