import { useState } from "react";
import { PresenceChannel } from "pusher-js";
import usePusher from "../hooks/usePusher";
import OnlineContext from "./OnlineContext";
import { IOnlineContext } from "./onlineContext.s";

export default function OnlineContextProvider({ children }: IOnlineContext) {
  const { pusher, isReady } = usePusher();

  const [allUsersChannel, setAllUsersChannel] = useState<PresenceChannel>();
  const [humansChannel, setHumansChannel] = useState<PresenceChannel>();
  const [professionalsChannel, setProfessionalsChannel] =
    useState<PresenceChannel>();

  const [waitingRoomChannel, setWaitingRoomChannel] =
    useState<PresenceChannel>();

  const [specificWaitingRoomChannel, setSpecificWaitingRoomChannel] =
    useState<PresenceChannel>();

  const unsubscribeFromAllChannels = () => {
    if (pusher) {
      if (allUsersChannel) pusher.unsubscribe(allUsersChannel.name);

      if (humansChannel) pusher.unsubscribe(humansChannel.name);

      if (professionalsChannel) pusher.unsubscribe(professionalsChannel.name);

      if (waitingRoomChannel) pusher.unsubscribe(waitingRoomChannel.name);

      if (specificWaitingRoomChannel)
        pusher.unsubscribe(specificWaitingRoomChannel.name);
    }
  };

  const unsubscribeFromChannel = (channel: PresenceChannel) => {
    if (pusher) {
      if (
        (allUsersChannel && channel.name === allUsersChannel.name) ||
        (humansChannel && channel.name === humansChannel.name) ||
        (professionalsChannel && channel.name === professionalsChannel.name) ||
        (waitingRoomChannel && channel.name === waitingRoomChannel.name)
      ) {
        pusher.unsubscribe(channel.name);
      }
    }
  };

  const unsubscribeFromChannelWithName = (channelName: string) => {
    if (pusher && channelName) pusher.unsubscribe(channelName);
  };

  return (
    <OnlineContext.Provider
      value={{
        allOnlineCount: allUsersChannel?.members.count,
        allUsersChannel,
        humansChannel,
        humansOnlineCount: humansChannel?.members.count,
        inWaitingRoomCount: waitingRoomChannel?.members.count,
        isReady,
        professionalsChannel,
        professionalsOnlineCount: professionalsChannel?.members.count,
        pusher,
        setAllUsersChannel,
        setHumansChannel,
        setProfessionalsChannel,
        setSpecificWaitingRoomChannel,
        setWaitingRoomChannel,
        specificWaitingRoomChannel,
        unsubscribeFromAllChannels,
        unsubscribeFromChannel,
        unsubscribeFromChannelWithName,
        waitingRoomChannel,
      }}
    >
      {children}
    </OnlineContext.Provider>
  );
}
