import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import NationalId from "../../data-model/types/human/NationalId";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import useFormState from "../hooks/useFormState";
import createFormValidation from "../../utils/forms/createFormValidation";
import HealthInsurance, {
  HealthInsuranceCompanies,
} from "../../data-model/types/reimbursement/HealthInsurance";

export interface _paymentFormState {
  healthInsurance: HealthInsuranceCompanies | null;
  nationalRegistryNumer: NationalId;
}

const INITIAL_VALUES = {
  ...NationalId.getInitialValue(),
  ...HealthInsurance.getInitialValue(),
};

export default function usePaymentFormState(
  initialValues: Partial<_paymentFormState> = INITIAL_VALUES,
  options?: any,
) {
  const { t: translate } = useTranslation<I18Namespaces>(
    BloomUpNamespacesEnum.Validation,
  );

  const validation = useMemo(() => {
    return createFormValidation([
      ...NationalId.getFormValidation(translate),
      ...HealthInsurance.getFormValidation(translate),
    ]);
  }, [translate]);

  return useFormState<_paymentFormState>(
    {
      ...INITIAL_VALUES,
      ...initialValues,
    },
    {
      validation,
      ...options,
    },
  );
}
