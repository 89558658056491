/**
 * Last name of user, psy or client.
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export default class LastName extends DataType<
  string,
  DataTypePaths.Profile.LastName
> {
  protected static path = "lastName";
  protected static defaultValue = "";

  protected type: any = LastName;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {TFunction} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "lastname.mandatory",
          "Gelieve je familienaam op te geven.",
        ),
        path: this.getPath(),
        validate: (lastName: string): boolean =>
          !!lastName && lastName.trim() !== "",
      },
    ];
  }

  getAsFormStateValue(): Partial<
    Record<DataTypePaths.Profile.LastName, string>
  > {
    return { lastName: this.value };
  }
}
