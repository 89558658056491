import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import RightSection from "../../layout/RightSection";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import ViewOnlyProfileInfo from "../../profile/ViewOnlyProfileInfo";
import ViewOnlyProfessionalProfile from "../professional-profile/ViewOnlyProfessionalProfile";
import { graphql } from "../../../api/__generated__";
import LoadingPage from "../../layout/LoadingPage";
import UnavailableSection from "../../layout/UnavailableSection";

const queryColleague = graphql(`
  query colleague($id: Int!) {
    professional(id: $id) {
      id
      user {
        id
        ...ViewOnlyProfileInfo
      }
      ...ViewOnlyProfessionalProfile
    }
  }
`);

interface ColleagueDetailProps {
  closeDetailScreen: () => void;
  professionalId: number;
}

const ColleagueDetail = ({
  professionalId,
  closeDetailScreen,
}: ColleagueDetailProps) => {
  const { t } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
  ]);

  const { data, loading } = useQuery(queryColleague, {
    variables: {
      id: professionalId,
    },
  });

  return (
    <RightSection
      handleClose={closeDetailScreen}
      title={t("professional:colleagues.pro.title")}
    >
      {loading ? (
        <LoadingPage />
      ) : data?.professional ? (
        <Box>
          <ViewOnlyProfileInfo showBirthYear user={data.professional.user} />
          <ViewOnlyProfessionalProfile professional={data.professional} />
        </Box>
      ) : (
        <UnavailableSection text={t("common:status.not_found.colleague")} />
      )}
    </RightSection>
  );
};

export default ColleagueDetail;
