import { useQuery } from "@apollo/client";
import { QUERY_CHECK_IF_HUMAN_IS_CLIENT } from "../queries";
import { APIClient } from "../clients";

export default function useCheckIfHumanIsAClient(humanId: number) {
  const { data, loading, error } = useQuery<
    APIClient.IsClient.Result,
    APIClient.IsClient.Input
  >(QUERY_CHECK_IF_HUMAN_IS_CLIENT, {
    variables: { humanId },
  });

  return {
    checkingIfHumanIsAClient: loading,
    error,
    isAClient: data?.checkIfHumanIsAClient,
  };
}
