import { useMutation, useQuery } from "@apollo/client";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { add, format } from "date-fns";
import { Formik } from "formik";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { graphql } from "../../api/__generated__";
import { ConsultationTypes } from "../../data-model/types/consultation/Consultation";
import { getDateFnsLocale } from "../language/languagesUtil";
import ToastContext from "../providers/toast/ToastContext";
import { IToastContext } from "../providers/toast/toast";
import Button from "../ui/buttons/Button";
import { CustomDateTimePicker } from "../ui/form/CustomDateTimePicker";
import CustomTextField from "../ui/form/CustomTextField";
import SearchableSelect from "../ui/form/SearchableSelect";
import Label from "../ui/text/Label";
import SanitizedText from "../ui/text/SanitizedText";
import environment from "../../environment/environment";

const requestConsultationProfessionalQuery = graphql(`
  query RequestConsultationProfessional($id: Int!) {
    professional(id: $id) {
      clients {
        edges {
          node {
            canPayCoachingWithCredits
            canPayConsultWithCredits
            creditsLeft
            id
            user {
              email
              firstName
              id
              lastName
            }
          }
        }
      }
      coachingEnabled
      id
      standardDuration
      standardRate
      therapyEnabled
    }
  }
`);

const professionalRequestConsultationMutation = graphql(`
  mutation ProfessionalRequestConsultation($input: CreateConsultationInput!) {
    createConsultation(input: $input) {
      id
      uuid
    }
  }
`);

const ProfessionalScheduleConsultation = ({
  professionalId,
  onSchedule,
}: {
  onSchedule: (uuid: string) => void;
  professionalId: number;
}) => {
  const { t, i18n } = useTranslation();
  const dateFnsLocale = getDateFnsLocale(i18n.language);
  const { setToast } = useContext<IToastContext>(ToastContext);

  const { data: clientsData } = useQuery(requestConsultationProfessionalQuery, {
    variables: {
      id: professionalId,
    },
  });

  const [createConsultation, { loading: creating }] = useMutation(
    professionalRequestConsultationMutation,
    {
      onCompleted(data) {
        if (data.createConsultation?.uuid) {
          onSchedule(data.createConsultation.uuid);
        }
      },
      onError(error) {
        if (error.message === "create_consultation_unpaid_consultations") {
          setToast({
            message: t("errors:create_consultation_unpaid_consultations"),
            severity: "error",
          });

          return;
        }

        if (error.message === "OVERLAPPING_EVENTS") {
          setToast({
            message: t(
              "common:professional.reschedule.consultation.validtation.overlapping.events",
            ),
            severity: "error",
          });

          return;
        }

        setToast({
          message: "Sorry, something went wrong. Please try again later.",
          severity: "error",
        });
      },

      refetchQueries: ["ProfessionalConsultations"],
    },
  );

  const smallScreen = useMediaQuery("(max-width: 500px)");

  if (!clientsData?.professional) return null;

  return (
    <Formik
      initialValues={{
        clientId: undefined,
        consultationType: ConsultationTypes.APPOINTMENT,
        duration: clientsData.professional.standardDuration || 0,
        price: clientsData.professional.standardRate,
        scheduleFrom: add(new Date().setSeconds(0, 0), { minutes: 10 }),
        scheduleTo: add(new Date().setSeconds(0, 0), {
          minutes: clientsData.professional.standardDuration ?? 50,
        }),
      }}
      onSubmit={(values) => {
        const clientId = clientsData.professional?.clients.edges.find(
          (edge) => edge.node.id === values.clientId,
        )?.node.id;

        if (clientId) {
          createConsultation({
            variables: {
              input: {
                fromMatching: false,
                otherPartyId: clientId,
                price: values.price,
                scheduledFrom: values.scheduleFrom,
                scheduledTo: add(values.scheduleFrom, {
                  minutes:
                    values.consultationType === "INTRO"
                      ? 15
                      : values.consultationType === "COACHING"
                      ? 30
                      : values.duration,
                }),
                status: "REQUESTED",
                type: values.consultationType,
              },
            },
          });
        }
      }}
      validationSchema={Yup.object({
        clientId: Yup.string().required(
          t("common:professional.request.consultation.validation.client"),
        ),
        consultationType: Yup.string<ConsultationTypes>()
          .oneOf([
            ConsultationTypes.APPOINTMENT,
            ConsultationTypes.INTRO,
            ConsultationTypes.COACHING,
          ])
          .required(
            t(
              "common:professional.request.consultation.validation.consultation_type",
            ),
          ),
        duration: Yup.number().min(
          1,
          t("common:professional.request.consultation.validation.duration"),
        ),
        price: Yup.number().required(
          t("common:professional.request.consultation.validation.price"),
        ),
        scheduleFrom: Yup.date().min(
          new Date(),
          t(
            "common:professional.request.consultation.validation.schedule_from",
          ),
        ),
      })}
    >
      {(props) => {
        const selectedHuman = clientsData.professional?.clients.edges.find(
          (edge) => edge.node.id === props.values.clientId,
        )?.node;

        const canPayWithCredits =
          (props.values.consultationType === "APPOINTMENT" &&
            !!selectedHuman?.canPayConsultWithCredits) ||
          (props.values.consultationType === "COACHING" &&
            !!selectedHuman?.canPayCoachingWithCredits);

        return (
          <form onSubmit={props.handleSubmit}>
            <Box mt={1}>
              <Box>
                {clientsData.professional && (
                  <Box mb={3}>
                    <Label value={t("common:client")} />
                    <SearchableSelect
                      items={clientsData.professional.clients.edges.map(
                        (edge, id) => {
                          return {
                            label:
                              edge.node.user.firstName !== null
                                ? edge.node.user.firstName +
                                  " " +
                                  edge.node.user.lastName
                                : edge.node.user.email,

                            testId: `client-menu-item-${id}`,
                            value: edge.node.id,
                          };
                        },
                      )}
                      onChange={(value) => {
                        props.setFieldValue("clientId", value);

                        //Set to appointment if coaching is selected and new client doesn't have access
                        if (
                          !clientsData.professional?.clients.edges.find(
                            (edge) => edge.node.id === value,
                          )?.node.canPayCoachingWithCredits &&
                          props.values.consultationType ===
                            ConsultationTypes.COACHING
                        ) {
                          props.setFieldValue(
                            "consultationType",
                            ConsultationTypes.APPOINTMENT,
                          );
                        }
                      }}
                      testId="client-select"
                      value={props.values.clientId ?? ""}
                    />
                  </Box>
                )}
              </Box>
              {selectedHuman && (
                <Box>
                  <Label value={t("professional:consultations.select.type")} />
                  <Select
                    data-testid="consultation-type-select"
                    onChange={(event) => {
                      props.setFieldValue(
                        "consultationType",
                        event.target.value,
                      );
                    }}
                    sx={{
                      width: "100%",
                    }}
                    value={props.values.consultationType}
                  >
                    {clientsData.professional?.therapyEnabled && (
                      <MenuItem
                        data-testid="consultation-type-appointment"
                        value={ConsultationTypes.APPOINTMENT}
                      >
                        {t(`common:consultation_type.APPOINTMENT`)}
                      </MenuItem>
                    )}
                    <MenuItem value={ConsultationTypes.INTRO}>
                      {t(`common:consultation_type.INTRO`)}
                    </MenuItem>
                    {selectedHuman.canPayCoachingWithCredits &&
                      clientsData.professional?.coachingEnabled && (
                        <MenuItem value={ConsultationTypes.COACHING}>
                          {t(`common:consultation_type.COACHING`)}
                        </MenuItem>
                      )}
                  </Select>
                </Box>
              )}
              {selectedHuman && props.values.consultationType && (
                <Box>
                  <Box
                    alignItems={"justify"}
                    display="flex"
                    mt={3}
                    width="100%"
                  >
                    <Box mr={1} pr={1} width={"75%"}>
                      <Label value={t("common:from")} />
                      <CustomDateTimePicker
                        disablePast
                        error={props.errors.scheduleFrom as string}
                        fullWidth
                        onChange={(value) => {
                          if (value) {
                            props.setFieldValue("scheduleFrom", value);
                          }
                        }}
                        value={props.values.scheduleFrom}
                      />
                    </Box>
                    {props.values.consultationType ===
                      ConsultationTypes.APPOINTMENT && (
                      <Box mr={1} width={"25%"}>
                        <Label value={t("common:duration")} />
                        <CustomTextField
                          error={props.errors.duration}
                          onChange={(event) => {
                            props.setFieldValue(
                              "duration",
                              Number(event.target.value),
                            );
                          }}
                          value={props.values.duration}
                        />
                      </Box>
                    )}
                  </Box>
                  <Box alignItems={"center"} mt={3}>
                    <Label value={t("common:consultation.time", "Tijd")} />
                    <Typography width={"100%"}>
                      {`${format(props.values.scheduleFrom, "p", {
                        locale: dateFnsLocale,
                      })} →
                    ${format(
                      add(props.values.scheduleFrom, {
                        minutes:
                          props.values.consultationType === "INTRO"
                            ? 15
                            : props.values.consultationType === "COACHING"
                            ? 30
                            : props.values.duration,
                      }),
                      "p",
                      {
                        locale: dateFnsLocale,
                      },
                    )}`}
                    </Typography>
                  </Box>
                  <Box display={"flex"} flexWrap={"nowrap"} py={2}>
                    {!canPayWithCredits &&
                      props.values.consultationType ===
                        ConsultationTypes.APPOINTMENT && (
                        <Box mr={2}>
                          <Label
                            tooltip={t(
                              "professional:consultation.pricing.tooltip",
                            )}
                            value={t("common:price.label")}
                          />
                          <Box>
                            <CustomTextField
                              error={props.errors.price}
                              onChange={(event) => {
                                props.setFieldValue(
                                  "price",
                                  Number(event.target.value),
                                );
                              }}
                              value={props.values.price}
                            />
                          </Box>
                        </Box>
                      )}
                    {props.values.consultationType !== "INTRO" && (
                      <Box mt={2}>
                        <SanitizedText
                          input={
                            canPayWithCredits
                              ? selectedHuman.creditsLeft
                                ? t(
                                    "professional:consultation.pricing.info.credits",
                                    {
                                      credits: selectedHuman.creditsLeft,
                                    },
                                  )
                                : t(
                                    "professional:consultation.pricing.info.credits.unlimited",
                                  )
                              : t("professional:consultation.pricing.info", {
                                  clientPrice: props.values.price,
                                  professionalNetto:
                                    (props.values.price
                                      ? props.values.price
                                      : 0) -
                                    environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT,
                                  serviceCost:
                                    environment.REACT_APP_BLOOMUP_FEE_FOR_B2C_CONSULT,
                                })
                          }
                        />
                      </Box>
                    )}
                  </Box>
                  <Box sx={{ marginBottom: "2rem" }}>
                    <Button
                      bloomColor="green"
                      icon={<CheckCircleIcon />}
                      label={t("common:book", "Boek consultatie")}
                      loading={creating}
                      onClick={() => props.submitForm()}
                      size={smallScreen ? "medium" : "large"}
                      type="submit"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfessionalScheduleConsultation;
