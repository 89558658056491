import localForage from "localforage";
import { Token } from "../components/hooks/useStorage";

const KEY_ACCESS_TOKEN = "accessToken";
const KEY_REFRESH_TOKEN = "refreshToken";
const KEY_MATCHING_TOKEN = "matchingToken";
const KEY_WORRIES = "matching_worries";
const CURRENT_ORGANISATION_ID = "currentOrganisationId";
const CONSULTATION_KEY = "consultation";
//TODO; we can use other keys for DEV / PROD, but also for humans / psys. Note: human / psy is also contained in the token.
// In memory cache

export default class BULocalStorage {
  private static instance: BULocalStorage | null = null;

  private accessToken: string | null = null;
  private refreshToken: string | null = null;
  private matchingToken: string | null = null;

  private constructor() {}

  public static getInstance(): BULocalStorage {
    if (!this.instance) {
      this.instance = new BULocalStorage();
    }

    return this.instance;
  }

  loadWithKey = async <T>(key: string) => await localForage.getItem<T>(key);

  storeWithKey = async (key: string, value: any) =>
    await localForage.setItem(key, value);

  getAccessToken = async (): Promise<string | null> => {
    if (this.accessToken === null) {
      this.accessToken = await localForage.getItem<string>(KEY_ACCESS_TOKEN);
    }

    return this.accessToken;
  };

  getRefreshToken = async (): Promise<string | null> => {
    if (this.refreshToken === null) {
      this.refreshToken = await localForage.getItem<string>(KEY_REFRESH_TOKEN);
    }

    return this.refreshToken;
  };

  getMatchingToken = async (): Promise<Token> => {
    if (this.matchingToken === null) {
      this.matchingToken =
        await localForage.getItem<string>(KEY_MATCHING_TOKEN);
    }

    return this.matchingToken;
  };

  getWorries = async () => {
    return await localForage.getItem(KEY_WORRIES);
  };

  getCurrentOrganizationId = async (): Promise<number | null> =>
    localForage.getItem<number>(CURRENT_ORGANISATION_ID);

  setAccessToken = async (newAccessToken: string) => {
    this.accessToken = newAccessToken;

    return localForage.setItem(KEY_ACCESS_TOKEN, newAccessToken);
  };

  setRefreshToken = async (newRefreshToken: string) => {
    this.refreshToken = newRefreshToken;

    return localForage.setItem(KEY_REFRESH_TOKEN, newRefreshToken);
  };

  setMatchingToken = async (newMatchingToken: string) => {
    this.matchingToken = newMatchingToken;

    return localForage.setItem(KEY_MATCHING_TOKEN, newMatchingToken);
  };

  setCurrentOrganisationId = async (newCurrentOrganisationId: number) => {
    return localForage.setItem(
      CURRENT_ORGANISATION_ID,
      newCurrentOrganisationId,
    );
  };

  setWorries = (worries) => {
    return localForage.setItem(KEY_WORRIES, worries);
  };

  removeAccessToken = () => {
    this.accessToken = null;

    return localForage.removeItem(KEY_ACCESS_TOKEN);
  };

  removeRefreshToken = () => {
    this.refreshToken = null;

    return localForage.removeItem(KEY_REFRESH_TOKEN);
  };

  removeMatchingToken = () => {
    this.matchingToken = null;

    return localForage.removeItem(KEY_MATCHING_TOKEN);
  };

  removeConsultation = async () => {
    try {
      await localForage.removeItem(CONSULTATION_KEY);

      return true;
    } catch {
      return false;
    }
  };
}
