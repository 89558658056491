import { MicSelection } from "amazon-chime-sdk-component-library-react";
import { useTranslation } from "react-i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../../language/I18Namespaces";

export default function AudioInputDeviceSelection() {
  const { t } = useTranslation<I18Namespaces>([BloomUpNamespacesEnum.Ui]);

  return (
    <MicSelection
      label={t("ui:audio.input.device.selection.label") ?? undefined}
    />
  );
}
