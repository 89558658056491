import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useCallback, useState } from "react";
import { QualityQuestions } from "../QualityQuestions.types";
import { SharedAnswerWithOptionsProps } from "./answers";

export default function AnswerRadioButtons({
  options,
  onAnswerChanged,
}: SharedAnswerWithOptionsProps<QualityQuestions.Options.RadioButtons>) {
  const [selected, setSelected] = useState(Number(options[0].value));

  const onChange = useCallback(
    (_, value) => {
      setSelected(Number(value));

      const chosenOption = options.find(
        (o) => Number(o.value) === Number(value),
      );

      if (chosenOption) onAnswerChanged([chosenOption.value]);
    },

    [onAnswerChanged, options],
  );

  return (
    <FormControl variant="standard">
      <RadioGroup
        name="radio-buttons-group"
        onChange={onChange}
        value={selected}
      >
        {options.map((option: QualityQuestions.Options.Option, i: number) => (
          <FormControlLabel
            control={<Radio />}
            key={i}
            label={option.label}
            value={option.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
