import { graphql } from "../__generated__";

export const MUTATION_CONSULTATION_CREATE = graphql(`
  mutation createConsultation($input: CreateConsultationInput!) {
    createConsultation(input: $input) {
      ...Consultation
    }
  }
`);

export const MUTATION_CONSULTATION_UPDATE = graphql(`
  mutation consultationUpdate($input: UpdateConsultationInput!) {
    updateConsultation(input: $input) {
      ...Consultation
    }
  }
`);

export const MUTATION_CONSULTATION_CONFIRM = graphql(`
  mutation confirmConsultationMutation($input: ConfirmConsultationInput!) {
    confirmConsultation(input: $input) {
      acceptedByHuman
      acceptedByProfessional
      id
      status
      uuid
    }
  }
`);
