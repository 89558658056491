import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import "moment-timezone";
import { IWithChildren } from "../../../../../baseInterfaces";

const PREFIX = "InfoItemSidePane";

const classes = {
  root: `${PREFIX}-root`,
  subTitle: `${PREFIX}-subTitle`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
    flexFlow: "column nowrap",
    paddingTop: 20,
    width: "100%",
  },

  [`& .${classes.subTitle}`]: {
    ...theme.typography.h6,
    fontWeight: 700,
  },
}));

interface IInfoItemSidePane extends IWithChildren {
  subTitle: string;
}

const InfoItemSidePane = ({ subTitle, children }: IInfoItemSidePane) => {
  return (
    <StyledBox className={classes.root}>
      <Typography className={classes.subTitle}>{subTitle}</Typography>
      <Box>{children}</Box>
    </StyledBox>
  );
};

export default InfoItemSidePane;
