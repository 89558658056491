import { useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Professional from "../../../data-model/types/professional/Professional";
import ChipList from "../form/ChipList";
import YesNoQuestion from "../form/YesNoQuestion";
import { I18Namespaces } from "../../language/I18Namespaces";
import InlineEditableControls from "./common/InlineEditableControls";

interface IEProfessionalProps {
  formState?: any;
  label: string;
  onSave: () => void;
  professional: Professional;
}

const InlineEditableProfessionalOther = ({
  label,
  professional,
  formState,
  onSave,
}: IEProfessionalProps) => {
  const { t } = useTranslation<I18Namespaces>(["common", "professional"]);

  const [supervision, setSupervision] = useState<string>(
    professional.doesSupervision() ? "1" : "0",
  );

  const [firstline, setFirstline] = useState<string>(
    professional.isFirstLine() ? "1" : "0",
  );

  const otherItems: string[] = [];

  if (professional.doesSupervision()) {
    otherItems.push(t("professional:profile.supervision"));
  }

  if (professional.isFirstLine()) {
    otherItems.push(t("professional:profile.firstlinepsy"));
  }

  return (
    <InlineEditableControls
      edit={
        <Box>
          <YesNoQuestion
            formLabel={t(
              "professional:onboarding.experience.supervision.label",
              "Bied je supervisie aan?",
            )}
            hasError={false}
            name="offersSupervision"
            onChange={setSupervision}
            style={{
              marginBottom: 20,
            }}
            value={supervision}
          />
          <YesNoQuestion
            formLabel={t(
              "professional:onboarding.experience.firstline.label",
              "Ben je geconventioneerd eerstelijnspsycholoog?",
            )}
            hasError={false}
            name="isFirstLine"
            onChange={setFirstline}
            style={{
              marginBottom: 20,
            }}
            value={firstline}
          />
        </Box>
      }
      editable={formState !== undefined}
      label={label || t("common:-", "-")}
      onDiscard={() => {
        if (
          formState.validate("supervision") &&
          formState.validate("isFirstLine")
        ) {
          formState.values.supervision = professional.doesSupervision()
            ? "1"
            : "0";
          formState.values.isFirstLine = professional.isFirstLine() ? "1" : "0";

          setSupervision(professional.doesSupervision() ? "1" : "0");
          setFirstline(professional.isFirstLine() ? "1" : "0");

          return true;
        } else {
          return false;
        }
      }}
      onSave={async () => {
        formState.values.supervision = supervision === "1";
        formState.values.isFirstLine = firstline === "1";

        if (
          formState.validate("supervision") &&
          formState.validate("isFirstLine")
        ) {
          professional.doesSupervision(supervision === "1");
          professional.isFirstLine(firstline === "1");
          setSupervision(supervision);
          setFirstline(firstline);

          onSave();

          return true;
        } else {
          return false;
        }
      }}
      read={<ChipList list={otherItems} />}
    />
  );
};

export default InlineEditableProfessionalOther;
