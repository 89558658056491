import { useMutation } from "@apollo/client";
import { MUTATION_HUMAN_REGISTER_WITH_INVITE } from "../mutations";
import { APIHuman } from "../humans";

export default function useRegisterHumanWithInviteMutation() {
  const [registerHumanWithInviteFunction, { data, error, loading }] =
    useMutation<
      APIHuman.RegisterWithToken.Result,
      APIHuman.RegisterWithToken.Input
    >(MUTATION_HUMAN_REGISTER_WITH_INVITE);

  const registerHumanWithInvite = (
    input: APIHuman.Register.Input,
    inviteToken: string,
  ) => registerHumanWithInviteFunction({ variables: { input, inviteToken } });

  return {
    data,
    error,
    loading,
    registerHumanWithInvite,
  };
}
