import { useState } from "react";
import { parseGraphQLErrors } from "../../../../utils/graphql";
import Toast from "../../../ui/alerts/Toast.tsx";
import CreatePsyModal from "./CreatePsyModal";
import useCreatePsyForm from "./useCreatePsyForm";

const CreateProfessionalModalWithState = ({
  isOpen,
  onClose,
  onSubmit,
  ...props
}) => {
  const formState = useCreatePsyForm();
  //const [createPsy, { loading: isCreatingPsy }] = useCreatePsyMutation();
  const [errorMessage, setErrorMessage] = useState();

  const createProfessional = () => {
    if (formState.validate()) {
      try {
        // const input = formState.values;
        // await createPsy({ variables: { input } });
      } catch (e) {
        const graphqlErrors = parseGraphQLErrors(e);

        if (graphqlErrors === null) {
          setErrorMessage("Er liep iets mis. Probeer het later opnieuw aub.");
        } else {
          formState.updateErrors(graphqlErrors);
        }
      }
      onSubmit();
      onClose();
    }
  };

  return (
    <>
      <CreatePsyModal
        {...props}
        formState={formState}
        isCreatingPsy={false} // isCreatingPsy
        isOpen={isOpen}
        onCancel={onClose}
        onClose={onClose}
        onCreatePsy={createProfessional}
      />
      <Toast
        message={errorMessage}
        onClose={() => setErrorMessage()}
        severity="error"
      />
    </>
  );
};

export default CreateProfessionalModalWithState;
