import { useContext, useMemo } from "react";
import { IWithChildren } from "../../../baseInterfaces";
import useQueryParams from "../../hooks/useQueryParams";
import AuthContext from "../../providers/auth/AuthContext";
import { RouteNames } from "../routeNames";
import Guard from "./Guard";

export default function HumanRegistrationGuard({ children }: IWithChildren) {
  const { currentUser } = useContext(AuthContext);

  const condition = useMemo(() => {
    const firstName = currentUser?.getFirstName().getValue();
    const lastName = currentUser?.getLastName().getValue();
    const firstNameOk =
      firstName !== undefined && firstName !== null && firstName !== "";

    const lastNameOk =
      lastName !== undefined && lastName !== null && lastName !== "";

    return lastNameOk && firstNameOk;
  }, [currentUser]);

  const { fromMail } = useQueryParams();
  const redirectFromEmail = fromMail !== null && fromMail === "true";

  return (
    <Guard
      condition={condition || redirectFromEmail}
      logText="HumanRegistrationGuard"
      redirectPath={RouteNames.Register.Human.Start.path}
    >
      {children}
    </Guard>
  );
}
