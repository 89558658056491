import { useCallback, useMemo, useState } from "react";
import { ISelectObject } from "../../ui/form/select/BaseSimpleSelect";
import MultiSelect from "../../ui/form/select/MultiSelect";
import { QualityQuestions } from "../QualityQuestions.types";
import AnswerCheckboxes from "./AnswerCheckboxes";
import { SharedAnswerWithOptionsProps } from "./answers";

type MultiSelectComponentOptions =
  | QualityQuestions.Component.Checkbox
  | QualityQuestions.Component.Dropdown;

interface AnswerMultiSelectProps
  extends SharedAnswerWithOptionsProps<QualityQuestions.Options.MultiSelect> {
  component?: MultiSelectComponentOptions;
}

export default function AnswerMultiSelect({
  component = QualityQuestions.Component.Dropdown,
  options,
  selectedAnswers,
  onAnswerChanged,
}: AnswerMultiSelectProps) {
  const componentToRender = useMemo(() => {
    switch (component) {
      case QualityQuestions.Component.Checkbox:
        return (
          <AnswerCheckboxes
            onAnswerChanged={onAnswerChanged}
            options={options}
            selectedAnswers={selectedAnswers}
          />
        );

      case QualityQuestions.Component.Dropdown:

      // Fallthrough because Dropdown is the default
      default:
        return (
          <MultiSelectForAnswerWrapper
            onAnswerChanged={onAnswerChanged}
            options={options}
          />
        );
    }
  }, [component, options, selectedAnswers, onAnswerChanged]);

  return componentToRender;
}

const MultiSelectForAnswerWrapper = ({
  options,
  onAnswerChanged,
}: SharedAnswerWithOptionsProps<QualityQuestions.Options.MultiSelect>) => {
  const [selected, setSelected] = useState<
    Array<ISelectObject<string | number>>
  >([]);

  const onChange = useCallback(
    (value: Array<ISelectObject<string | number>>) => {
      setSelected(value);
      onAnswerChanged(value.map((v) => v.value));
    },
    [onAnswerChanged],
  );

  return (
    <MultiSelect<string | number>
      onChange={onChange}
      options={options}
      value={selected}
    />
  );
};
