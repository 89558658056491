import { useQuery } from "@apollo/client";
import { List, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";

import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { WaveBox } from "wave-box";
import { compact } from "lodash";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import useSelectTranslation from "../../../../api/languages/useSelectTranslation";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import {
  I18NexusLanguages,
  i18NexusLanguageToDatabaseLanguageEquivalentsMap,
} from "../../../language/languagesUtil";
import LoadingPage from "../../../layout/LoadingPage";
import { theme } from "../../../providers/theme/ThemeProvider";
import { RouteNames } from "../../../routes/routeNames";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NavigationBar from "../components/NavBar";
import Steps from "../components/content/Steps";
import useOrganizationForLandingPage from "../hooks/useOrganizationForLandingPage";
import { graphql, makeFragmentData } from "../../../../api/__generated__";
import { UseSelectTranslationStringInputFragment } from "../../../../api/languages/fragments";
import { Language } from "../../../../api/__generated__/graphql";
import ListItemWithIcon from "../../../ui/lists/ListItemWithIcon";

const availableTracksQuery = graphql(`
  query GgtLandingPage($language: Language) {
    assessments: availableTracks(language: $language, type: assessment) {
      id
      title {
        ...useSelectTranslationStringInput
      }
    }
    exercises: availableTracks(language: $language, type: exercise) {
      id
      title {
        ...useSelectTranslationStringInput
      }
    }
    growthTracks: availableTracks(language: $language, type: growthTrack) {
      id
      title {
        ...useSelectTranslationStringInput
      }
    }
  }
`);

const appleStoreIconUrls = makeFragmentData(
  {
    en: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Apple+store+badge+en.svg",
    fr: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Apple+store+badge+fr.svg",
    nl: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Apple+store+badge+nl.svg",
  },
  UseSelectTranslationStringInputFragment,
);

const googlePlayIconUrls = makeFragmentData(
  {
    en: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Google+play+badge+en.png",
    fr: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Google+play+badge+fr.png",
    nl: "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Google+play+badge+nl.png",
  },
  UseSelectTranslationStringInputFragment,
);

interface ContentItemProps {
  imageAlt: string;
  imageSrc: string;
  text: string;
}

const ContentItem = ({ imageAlt, imageSrc, text }: ContentItemProps) => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        fontSize: "18px",
        margin: "24px",
        textAlign: "center",
        width: "150px",
      }}
    >
      <img
        alt={imageAlt}
        src={imageSrc}
        style={{ height: "auto", marginBottom: "24px", maxWidth: "80%" }}
      ></img>
      <Typography>{text}</Typography>
    </Box>
  );
};

const GgtLandingPage = () => {
  const navigate = useNavigate();
  const smallScreen = useMediaQuery("(max-width: 500px)");
  const downloadLinksRef = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Organization,
  ]);

  const selectTranslation = useSelectTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const { pathname } = useLocation();
  const { trackPageView, trackEvent } = useMatomo();
  const scrollToLinks = useCallback(() => {
    downloadLinksRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [downloadLinksRef]);

  const { data, loading } = useQuery(availableTracksQuery, {
    variables: {
      language:
        (i18NexusLanguageToDatabaseLanguageEquivalentsMap.get(
          i18n.language as I18NexusLanguages,
        ) as unknown as Language) ?? null,
    },
  });

  const organization = useOrganizationForLandingPage({
    emailOrCode: searchParams.get("email-or-code"),
  });

  useEffect(() => {
    if (organization)
      trackPageView({
        customDimensions: [
          {
            id: 2,
            value: organization.id.toString(),
          },
        ],
        documentTitle: "Organization landing ggt",
      });
  }, [organization, selectTranslation, trackPageView]);

  useEffect(() => {
    if (!organization) return;

    if (!organization.ggtEnabled)
      navigate("/employee/?" + searchParams.toString());
  }, [navigate, organization, searchParams]);

  useLayoutEffect(() => {
    if (!organization || loading) return;

    if (searchParams.get("target") === "download_link") {
      scrollToLinks();
    } else window.scrollTo(0, 0);
  }, [
    loading,
    organization,
    pathname,
    scrollToLinks,
    searchParams,
    setSearchParams,
  ]);

  if (!organization) return <LoadingPage full={false} />;

  const {
    color,
    logoUrl,
    ggtEnabled,
    mindlabEnabled,
    videoEnabled,
    ssoEnabled,
  } = organization;

  const baseUrl = "/employee";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavigationBar
        baseUrl={baseUrl}
        navigation={compact([
          ggtEnabled && {
            active: true,
            label: t("organization:landing.navigation.ggt"),
            path: `${baseUrl}${
              RouteNames.NewB2BLandingPages.ggt.path
            }/?${searchParams.toString()}`,
          },
          videoEnabled && {
            label: t("organization:landing.navigation.video"),
            path: `${baseUrl}/video/?${searchParams.toString()}`,
          },
        ])}
        organizationColor={color ?? "#15262A"}
        organizationLogoUrl={selectTranslation(logoUrl)}
        organizationName={selectTranslation(organization.name)}
      />
      <Header
        backgroundColor="#DBEEFA"
        button={{
          label: t("organization:landingGgt.header.button"),
          onClick: scrollToLinks,
        }}
        headerImageUrl="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/eating+smiling+girl.jpg"
        text={t(
          "organization:landingGgt.header.text",
          "BloomUp biedt 2 formules waarmee we jou helpen mentaal weerbaarder te worden. Ontdek ze hier!",
        )}
        title={t(
          "organization:landingGgt.header.title",
          "Mentaal weerbaar met BloomUp",
        )}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: "120px 48px 0",
          position: "relative",
          top: "-50px",
          width: "100%",
          [theme.breakpoints.down(500)]: {
            padding: "100px 18px 0px",
          },
        }}
      >
        <Typography
          sx={{ textAlign: "center" }}
          variant={smallScreen ? "h2" : "h1"}
        >
          {t("organization:landingGgt.workTogether.mainTitle")}
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          {t("organization:landingGgt.workTogether.mainText")}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gap: "24px",
            gridTemplateColumns: "1fr 1fr 1fr",
            justifyContent: "space-around",
            justifyItems: "center",
            maxWidth: "1300px",
            paddingTop: "36px",
            width: "70%",
            [theme.breakpoints.down(1050)]: {
              width: "100%",
            },
            [theme.breakpoints.down(780)]: {
              gridTemplateColumns: "fit-content(100%)",
              justifyContent: "center",
              justifyItems: "start",
            },
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              {t("organization:landingGgt.growthTracksHeader")}
            </Typography>
            <List>
              {data?.growthTracks.map((track) => {
                return (
                  <ListItemWithIcon
                    sx={{ paddingBottom: 0, paddingTop: 0 }}
                    text={selectTranslation(track.title)}
                  ></ListItemWithIcon>
                );
              })}
            </List>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              {t("organization:landingGgt.exercisesHeader")}
            </Typography>
            <List>
              {data?.exercises.map((track) => {
                return (
                  <ListItemWithIcon
                    sx={{ paddingBottom: 0, paddingTop: 0 }}
                    text={selectTranslation(track.title)}
                  ></ListItemWithIcon>
                );
              })}
            </List>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold" }}>
              {t("organization:landingGgt.assessmentsHeader")}
            </Typography>
            <List>
              {data?.assessments.map((track) => {
                return (
                  <ListItemWithIcon
                    sx={{ paddingBottom: 0, paddingTop: 0 }}
                    text={selectTranslation(track.title)}
                  ></ListItemWithIcon>
                );
              })}
            </List>
          </Box>
        </Box>
      </Box>
      <WaveBox
        background={theme.palette.green.main}
        fillBackground
        waveHeight="100px"
      >
        <Box
          ref={downloadLinksRef}
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            padding: "0 96px",
            textAlign: "center",
            [theme.breakpoints.down(900)]: {
              padding: "0 6rem",
            },
            [theme.breakpoints.down(600)]: {
              padding: "0 3rem",
            },
            [theme.breakpoints.down(400)]: {
              padding: "0 2rem",
            },
            [theme.breakpoints.down(280)]: {
              padding: "0 1rem",
            },
          }}
        >
          <img
            alt="Bloomup icon"
            src="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/iPhone_App_60_3x.svg"
          ></img>
          <Typography
            sx={{ marginBottom: "16px", marginTop: "24px" }}
            variant={"h1"}
          >
            {t("organization:landingGgt.downloadApp.title")}
          </Typography>
          {organization.code && (
            <>
              <Typography sx={{ marginBottom: "20px" }}>
                {t("organization:landingGgt.downloadApp.text")}
              </Typography>
              <Box
                sx={{
                  alignItems: "center",
                  background: "#daeee1",
                  borderRadius: "12px",
                  display: "flex",
                  justifyContent: "center",
                  padding: "12px 48px",
                }}
              >
                <Typography sx={{ marginTop: "8px" }} variant={"h1"}>
                  {organization.code}
                </Typography>
              </Box>
            </>
          )}

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "24px",
              maxWidth: "100%",
              width: "400px",
              [theme.breakpoints.down(890)]: {
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <a
              href="https://apps.apple.com/app/id1639537792"
              onClick={() => {
                trackEvent({
                  action: "click-event",
                  category: "Apple download ggt-app button",
                  customDimensions: [
                    {
                      id: 2,
                      value: organization.id.toString(),
                    },
                  ],
                });
              }}
            >
              <img
                alt="apple store"
                src={
                  selectTranslation(appleStoreIconUrls) ??
                  "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Apple+store+badge+en.svg"
                }
                style={{ height: "60px", maxWidth: "100%" }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=org.bloomup.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              onClick={() => {
                trackEvent({
                  action: "click-event",
                  category: "Android download ggt-app button",
                  customDimensions: [
                    {
                      id: 2,
                      value: organization.id.toString(),
                    },
                  ],
                });
              }}
            >
              <img
                alt="google play"
                src={
                  selectTranslation(googlePlayIconUrls) ??
                  "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Google+play+badge+en.png"
                }
                style={{ height: "77px", maxWidth: "100%" }}
              />
            </a>
          </Box>
        </Box>
      </WaveBox>
      <Steps
        mainText={t("organization:landingGgt.steps.text")}
        mainTitle={t("organization:landingGgt.steps.title")}
        steps={[
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+mirror+reverse.svg",
            text: t("organization:landingGgt.steps.steps.1.text"),
            title: t("organization:landingGgt.steps.step.1.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+1o1.svg",
            text: t("organization:landingGgt.steps.step.2.text"),
            title: t("organization:landingGgt.steps.step.2.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+notes.svg",
            text: t("organization:landingGgt.steps.step.3.text"),
            title: t("organization:landingGgt.steps.step.3.title"),
          },
          {
            imageUrl:
              "https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Plant+steps.svg",
            text: t("organization:landingGgt.steps.step.4.text"),
            title: t("organization:landingGgt.steps.step.4.title"),
          },
        ]}
      />
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          marginTop: "3rem",
          padding: "0 96px",
          textAlign: "center",
          [theme.breakpoints.down(900)]: {
            padding: "0 6rem",
          },
          [theme.breakpoints.down(600)]: {
            padding: "0 3rem",
          },
          [theme.breakpoints.down(400)]: {
            padding: "0 2rem",
          },
          [theme.breakpoints.down(280)]: {
            padding: "0 1rem",
          },
        }}
      >
        <Typography sx={{ marginBottom: "48px" }} variant="h1">
          {t("organization:landingGgt.resultsPromises.title")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            maxWidth: "1000px",
            width: "100%",
            [theme.breakpoints.down(745)]: {
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            },
          }}
        >
          <ContentItem
            imageAlt="Hourglass"
            imageSrc="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Hourglass.svg"
            text={t("organization:landingGgt.resultsPromises.fiveMinutes.v2")}
          />
          <ContentItem
            imageAlt="Bullseye"
            imageSrc="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Bullseye.svg"
            text={t(
              "organization:landingGgt.resultsPromises.problemSolving.v2",
            )}
          />
          <ContentItem
            imageAlt="Sitting behind a laptop"
            imageSrc="https://bloomup-production-api-assets.s3.eu-central-1.amazonaws.com/ORGANIZATION_ASSETS/Behind+laptop.svg"
            text={t("organization:landingGgt.resultsPromises.mentalSidekick")}
          />
        </Box>
      </Box>
      <Box height={"3rem"} />
      <Footer
        baseUrl={baseUrl}
        ggtEnabled={ggtEnabled}
        mindlabEnabled={mindlabEnabled}
        ssoEnabled={ssoEnabled}
        videoEnabled={videoEnabled}
      />
    </Box>
  );
};

export default GgtLandingPage;
