/* eslint-disable react-hooks/exhaustive-deps */

import { useQuery } from "@apollo/client";
import { useContext, useMemo } from "react";
import {
  QUERY_FETCH_CONSULTATIONS_ALL_FOR_HUMAN,
  QUERY_FETCH_CONSULTATIONS_ALL_FOR_PROFESSIONAL,
} from "../queries";
import { UserRoles } from "../../../data-model/types/User";
import Consultation from "../../../data-model/types/consultation/Consultation";
import { IAuthContext } from "../../../components/providers/auth/auth";
import AuthContext from "../../../components/providers/auth/AuthContext";
import IQueryHookReturnType from "../../IQueryHookReturnType";
import { APIConsultation, APIConsultations } from "../consultations";
import { APINode } from "../../api";

export default function useFetchAllConsultationsForCurrentUser(): IQueryHookReturnType<Consultation> {
  const { currentUser } = useContext<IAuthContext>(AuthContext);
  const role = currentUser ? currentUser.getRole() : UserRoles.HUMAN;
  const isProfessional: boolean = role === UserRoles.PROFESSIONAL;
  const isAdmin: boolean = role === UserRoles.ADMIN;
  const isTest: boolean = role === UserRoles.TEST;
  const getQuery = useMemo(() => {
    if (isProfessional || isAdmin || isTest)
      return QUERY_FETCH_CONSULTATIONS_ALL_FOR_PROFESSIONAL;
    else return QUERY_FETCH_CONSULTATIONS_ALL_FOR_HUMAN;
  }, [currentUser]);

  const { loading, error, data, refetch } =
    useQuery<APIConsultations.AllForCurrentUser.Output>(getQuery, {
      // fetchPolicy: "network-only",
    });

  const parsedData = useMemo((): Array<Consultation> | undefined => {
    if (data && !loading) {
      const edges =
        isProfessional || isAdmin
          ? data.allConsultationsForProfessional?.edges
          : data.allConsultationsForHuman?.edges;

      if (!edges) return [];

      return edges.map(
        (edge: APINode<APIConsultation.Result>) => new Consultation(edge.node),
      );
    }
  }, [loading, data]);

  return {
    data: parsedData,
    error,
    loading,
    refetch,
  };
}
