import type { TFunction } from "i18next";
import { I18Namespaces } from "../components/language/I18Namespaces";
import { ISelectObject } from "../components/ui/form/select/BaseSimpleSelect";
import DataType from "./DataType";

export default abstract class SelectionDataType<
  ValueType,
  SelectionType,
  PathName extends string,
> extends DataType<ValueType, PathName, SelectionType> {
  abstract getSelectOptions(
    translate: TFunction<I18Namespaces> | ((key: string) => string),
  ): Array<ISelectObject<SelectionType>>;

  abstract getSelectedOptions(
    translate: TFunction<I18Namespaces> | ((key: string) => string),
  ): Array<ISelectObject<SelectionType>>;

  abstract getTranslatedValues(
    translate: TFunction<I18Namespaces> | ((key: string) => string),
  ): Array<string>;

  abstract getOptions(): any;
}
