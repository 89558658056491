/* eslint-disable react-hooks/exhaustive-deps */

import dotProp from "dot-prop-immutable";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
  IFormErrorMessage,
  IFormValidationFunc,
} from "../../utils/forms/createFormValidation";

interface InternalFormStateOptions<K> {
  debug?: boolean;
  validation: IFormValidationFunc;
  validationOptions?: K;
  valuesToInput?: any;
}

export interface FormStateOptions<T> {
  debug?: boolean;
  validation?: IFormValidationFunc;
  validationOptions?: T;
  valuesToInput?: any;
}

type Path = string | number;

export interface IFormState<T = any> {
  errors: Array<IFormErrorMessage>;
  getButtonProps;
  getErrorMessage;
  getErrorMessages;
  getInputProps: (path: keyof T) => InputProps;
  getInputPropsWithoutValidation;
  getValidationProps;
  getValue;
  handleChange;
  handleClick;
  handleNativeChange;
  hasError;
  isDirty: boolean;
  isPristine: boolean;
  onValueChange;
  removeOnValueChange;
  resetForm;
  setValue;
  setValues;
  testForErrors: (path?: string) => boolean;
  updateErrors;
  validate: (path?: Path) => boolean;
  values: T;
  valuesToInput: (values) => void;
}

export const defaultFormStateValues: IFormState = {
  errors: [],
  getButtonProps: () => {},
  getErrorMessage: () => {},
  getErrorMessages: () => {},
  getInputProps: () => ({
    errorText: "",
    hasError: false,
    name: "",
    onChange: () => {},
    value: undefined,
  }),
  getInputPropsWithoutValidation: () => {},
  getValidationProps: () => {},
  getValue: () => {},
  handleChange: () => {},
  handleClick: () => {},
  handleNativeChange: () => {},
  hasError: () => {},
  isDirty: true,
  isPristine: true,
  onValueChange: () => {},
  removeOnValueChange: () => {},
  resetForm: () => {},
  setValue: () => {},
  setValues: () => {},
  testForErrors: () => false,
  updateErrors: () => {},
  validate: () => false,
  values: {},
  valuesToInput: () => {},
};

interface IStateObject<T, K> {
  errors: Array<IFormErrorMessage>;
  isDirty: boolean;
  isPristine: boolean;
  isValid: boolean;
  validation: IFormValidationFunc;
  validationOptions: K | undefined;
  valueListeners: Array<any>;
  values: T;
}

type Action = {
  errors?: Array<IFormErrorMessage>;
  initialValidation?: IFormValidationFunc;
  initialValidationOptions?: Record<string, unknown>;
  initialValues?: Record<string, unknown>;
  key?: string | number | symbol;
  path?: Path;
  state?: Record<string, unknown>;
  type: FormStateReducerActions;
  value?: any;
  values?: Array<any>;
};

type ValidationProps = {
  errorText: string;
  hasError: boolean;
};

type InputProps = {
  name: string;
  onChange: (value: any) => void;
  value: any;
} & ValidationProps;

enum FormStateReducerActions {
  RESET_FORM = "RESET_FORM",
  SET_STATE = "SET_STATE",
  SET_VALUE = "SET_VALUE",
  UPDATE_ERRORS = "UPDATE_ERRORS",
  UPDATE_VALUES = "UPDATE_VALUES",
  VALIDATE = "VALIDATE",
  VALIDATE_ONE = "VALIDATE_ONE",
}

const containsNoErrors = (errors: Array<any>) => errors.length === 0;

function initState<T, K>(initialValues?): IStateObject<T, K> {
  return {
    errors: [],
    isDirty: false,
    isPristine: true,
    isValid: false,
    validation: () => [],
    validationOptions: undefined,
    valueListeners: [],
    values: initialValues ? _.cloneDeep(initialValues) : {},
  };
}

const reducer = <T, K>(state: IStateObject<T, K>, action: Action) => {
  const { validation, validationOptions } = state;

  switch (action.type) {
    case FormStateReducerActions.SET_VALUE: {
      const { key, value } = action;

      const valuesObject = {
        values: {
          ...state.values,
        },
      };

      if (key) valuesObject.values[key] = value;

      if (
        key &&
        state.valueListeners &&
        state.valueListeners[key] &&
        Array.isArray(state.valueListeners[key])
      ) {
        state.valueListeners[key].forEach((fct) => fct(value));
      }

      return {
        ...state,
        ...valuesObject,
        isDirty: true,
      };
    }

    case FormStateReducerActions.UPDATE_VALUES: {
      const { values } = action;
      const newValues = {
        ...state.values,
        ...values,
      };

      const newState = dotProp.set(state, "values", newValues);

      return {
        ...newState,
        isDirty: true,
      };
    }

    case FormStateReducerActions.UPDATE_ERRORS: {
      const { errors } = action;

      return dotProp.set(state, "errors", errors);
    }

    case FormStateReducerActions.VALIDATE: {
      const errors: Array<IFormErrorMessage> = validation(
        state.values,
        validationOptions,
      );

      const isValid = containsNoErrors(errors);

      return {
        ...state,
        errors,
        isPristine: false,
        isValid,
      };
    }

    case FormStateReducerActions.VALIDATE_ONE: {
      const { path } = action;

      const values = path ? { [path]: state.values[path] } : {};
      const pathErrors = validation(values, validationOptions, state.values);

      let errors = state.errors;

      if (errors)
        errors = errors.filter((a) => a.path !== path).concat(pathErrors);

      const isValid = containsNoErrors(pathErrors);

      return {
        ...state,
        errors,
        isPristine: false,
        isValid,
      };
    }

    case FormStateReducerActions.RESET_FORM: {
      const { initialValues, initialValidation } = action;
      const errors = [];

      return {
        ...initState<T, K>(initialValues),
        errors,
        validation: initialValidation,
        values: initialValues,
      };
    }

    case FormStateReducerActions.SET_STATE: {
      return action.state;
    }

    default:
      throw new Error(`Unknown form state action '${action.type}'.`);
  }
};

const useFormState = <T = any, K extends Record<string, unknown> = any>(
  initialValues: Record<string, any> = {},
  formStateOptions: InternalFormStateOptions<K> = {
    validation: () => [],
  },
): IFormState<T> => {
  const { validation, validationOptions, valuesToInput } = formStateOptions;

  const initialState = useMemo(
    () => ({
      ...initState(initialValues),
      validation,
      validationOptions,
      values: _.cloneDeep(initialValues),
    }),
    [initialValues, _.cloneDeep, formStateOptions],
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({
      state: {
        validation: formStateOptions.validation,
        values: state.values,
      },
      type: FormStateReducerActions.SET_STATE,
    });
  }, [formStateOptions.validation]);

  useDeepCompareEffect(() => {
    dispatch({
      state: {
        ...initState(initialValues),
        validation,
        validationOptions,
        values: _.cloneDeep(initialValues),
      },
      type: FormStateReducerActions.SET_STATE,
    });
  }, [initialValues, validationOptions]);

  const resetForm = useCallback(() => {
    dispatch({
      errors: [],
      initialValidation: validation,
      initialValidationOptions: validationOptions,
      initialValues,
      type: FormStateReducerActions.RESET_FORM,
    });
  }, [dispatch, validation, validationOptions]);

  const setErrors = useCallback(
    (errors): void => {
      dispatch({
        errors,
        type: FormStateReducerActions.UPDATE_ERRORS,
      });
    },
    [dispatch],
  );

  const setValues = useCallback(
    (values): void => {
      dispatch({
        type: FormStateReducerActions.UPDATE_VALUES,
        values,
      });
    },
    [dispatch],
  );

  const setValue = useCallback(
    (key: keyof T, value): void => {
      _.debounce(() => {
        if (key && (typeof key === "string" || typeof key === "number"))
          validate(key);
      }, 2000)();

      dispatch({
        key,
        type: FormStateReducerActions.SET_VALUE,
        value,
      });
    },
    [dispatch],
  );

  const onValueChange = (key: string, fct: any): void => {
    if (!state.valueListeners) state.valueListeners = [];

    if (!Array.isArray(state.valueListeners[key]))
      state.valueListeners[key] = [];

    state.valueListeners[key].push(fct);
  };

  const removeOnValueChange = (key: string, fct: any): void => {
    if (!state.valueListeners && !state.valueListeners[key]) return;

    const items = state.valueListeners[key];
    const index = items.indexOf(fct);

    if (index > -1) state.valueListeners[key] = items.splice(index, 1);
  };

  const handleNativeChange = useCallback(
    (e): void => {
      const { name, type, value, checked, files } = e.target;
      let finalValue = value;

      if (type === "checkbox") {
        finalValue = checked;
      } else if (type === "file") {
        [finalValue] = files;
      }

      setValue(name, finalValue);
    },
    [setValue],
  );

  const handleClick = useCallback(
    (e) => {
      const { name, value } = e.currentTarget;

      setValue(name, value);
    },
    [setValue],
  );

  const getValue = useCallback(
    //TODO: check the impact of setting the default value here to null
    (path: Path) => dotProp.get(state.values, path, ""),
    [state.values],
  );

  const validate = useCallback(
    (path?: string | number) => {
      let isValid: boolean;

      if (!validation) return true;

      if (path) {
        const errors = validation(
          { [path]: getValue(path) },
          validationOptions,
          state.values,
        );

        isValid = containsNoErrors(errors);
        dispatch({
          path,
          type: FormStateReducerActions.VALIDATE_ONE,
        });
      } else {
        const errors = validation(state.values, validationOptions);

        isValid = containsNoErrors(errors);
        dispatch({
          type: FormStateReducerActions.VALIDATE,
        });
      }

      return isValid;
    },
    [
      validation,
      containsNoErrors,
      dispatch,
      getValue,
      state.values,
      validationOptions,
    ],
  );

  const getErrorMessages = useCallback(
    (path) =>
      state.errors
        ?.filter((error) => error.path === path)
        .map(({ message }) => message),
    [state.errors],
  );

  const getErrorMessage = useCallback(
    (path) => {
      const messages = getErrorMessages(path);

      return messages && messages.length > 0 ? messages[0] : "";
    },
    [getErrorMessages],
  );

  const hasError = useCallback(
    (path) => {
      if (!state.errors) return false;

      return (
        typeof state.errors?.find((error) => {
          if (!error || !error.path) return false;

          return error.path.includes(path);
        }) !== "undefined"
      );
    },
    [state.errors],
  );

  /**
   * Function that can be used to test a value for errors without saving
   * this in the state. So no error message will be shown.
   */
  const testForErrors = useCallback(
    (path) => {
      const values = path ? { [path]: getValue(path) } : state.values;

      const errors = validation(values, {
        ...validationOptions,
        ...state.values,
      });

      return !containsNoErrors(errors);
    },
    [getValue, state.values, validationOptions],
  );

  const getButtonProps = useCallback(
    (path) => {
      return {
        name: path,
        onChange: handleClick,
        value: getValue(path),
      };
    },
    [getValue, handleClick],
  );

  const getValidationProps: (path: Path) => ValidationProps = useCallback(
    (path) => ({
      errorText: getErrorMessage(path),
      hasError: hasError(path),
    }),
    [hasError, getErrorMessage],
  );

  const getInputProps = useCallback(
    (path: keyof T): InputProps => {
      const pathString = String(path);

      return {
        name: pathString,
        onChange: (value) => setValue(path, value),
        value: getValue(pathString),
        ...getValidationProps(pathString),
      };
    },
    [setValue, getValue, getValidationProps],
  );

  const getInputPropsWithoutValidation = useCallback(
    (path) => {
      return {
        name: path,
        onChange: (value) => setValue(path, value),
        value: getValue(path),
      };
    },
    [setValue, getValue],
  );

  return {
    errors: state.errors,
    getButtonProps,
    getErrorMessage,
    getErrorMessages,
    getInputProps,
    getInputPropsWithoutValidation,
    getValidationProps,
    getValue,
    handleChange: setValue,
    handleClick,
    handleNativeChange,
    hasError,
    isDirty: state.isDirty,
    isPristine: state.isPristine,
    onValueChange,
    removeOnValueChange,
    resetForm,
    setValue,
    setValues,
    testForErrors,
    updateErrors: setErrors,
    validate,
    values: state.values,
    valuesToInput: () => valuesToInput(state.values),
  };
};

export default useFormState;
