import { useContext } from "react";
import { UserRoles } from "../../../data-model/types/User";
import { IWithChildren } from "../../../baseInterfaces";
import { IAuthContext } from "../../providers/auth/auth";
import AuthContext from "../../providers/auth/AuthContext";
import Guard from "./Guard";

export default function AdminGuard({ children }: IWithChildren) {
  const { currentUser } = useContext<IAuthContext>(AuthContext);

  return (
    <Guard condition={currentUser?.getRole() === UserRoles.ADMIN}>
      {children}
    </Guard>
  );
}
