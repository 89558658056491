/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import { isSafari } from "react-device-detect";
import environment from "../../../../environment/environment";

export default function useBeams() {
  const [error, setError] = useState<Error | undefined>();
  const [client, setClient] = useState<PusherPushNotifications.Client>();
  const [registered, setRegistered] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);

  const triggerPermissionRequest = async () => {
    if (!client) return;

    try {
      console.log("Starting beams");
      const newClient = await client.start();

      if (newClient) {
        setStarted(true);
        setClient(newClient);
      }
    } catch (error: any) {
      setError(error);
    }
  };

  useEffect(() => {
    if (client && !started) triggerPermissionRequest();
  }, [client]);

  useEffect(() => {
    const checkRegistration = async () => {
      if (client) {
        const deviceId = await client.getDeviceId();

        if (deviceId) {
          console.log(
            "Succesfully registered with Beams: (deviceId)",
            deviceId,
          );
          setRegistered(true);
        }
      }
    };

    checkRegistration();
  }, [started, client]);

  const { serviceWorker, permissions } = window.navigator;

  useEffect(() => {
    // TODO: This does not work on IOS
    if (isSafari) return;

    const instantiateBeamsClient = async (
      serviceWorkerRegistration: ServiceWorkerRegistration,
    ) => {
      try {
        if (environment.REACT_APP_PUSHER_BEAMS_INSTANCE) {
          const beamsClient = new PusherPushNotifications.Client({
            instanceId: environment.REACT_APP_PUSHER_BEAMS_INSTANCE,
            serviceWorkerRegistration,
          });

          setClient(beamsClient);
        } else {
          console.error("REACT_APP_PUSHER_BEAMS_INSTANCE is not defined.");
        }
      } catch (error) {
        console.error("Could not instantiate or start beamsclient:", error);
      }
    };

    const register = async () => {
      try {
        if (serviceWorker) {
          const registration = await serviceWorker.ready;

          instantiateBeamsClient(registration);
        }
      } catch (error: any) {
        console.error("useEffect: ", error);
        setError(error);
      }
    };

    // Listens for changes in permissions and tries to register when permissions are granted.
    if (permissions) {
      permissions
        .query({ name: "notifications" })
        .then((result) => {
          result.onchange = () => {
            if (result.state === "granted") {
              register();
            }
          };
        })
        .catch((error) => {
          console.error("Could not register upon permission change: ", error);
        });
    } else {
      console.log(
        "window.navigator.permissions does not exist. We're probably on iOS",
      );
    }

    register();
  }, [serviceWorker, permissions]);

  // Clean up
  useEffect(() => {
    return () => {
      setRegistered(false);
      setStarted(false);

      if (client) client.stop();
    };
  }, []);

  return {
    client,
    error,
    registered,
    triggerPermissionRequest,
  };
}
