/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";

import { styled } from "@mui/material/styles";
import moment from "moment";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import clsx from "clsx";
import Consultation, {
  ConsultationTypes,
} from "../../data-model/types/consultation/Consultation";
import { IFormState } from "../hooks/useFormState";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../language/I18Namespaces";
import ValueWithLabel from "../ui/text/ValueWithLabel";
import { DateFormat } from "../../data-model/Date";
import Label from "../ui/text/Label";
import { CustomTimePicker } from "../ui/form/TimePicker";
import { CustomDatePicker } from "../ui/form/DatePicker";
import Professional from "../../data-model/types/professional/Professional";
import { IAuthContext } from "../providers/auth/auth";
import AuthContext from "../providers/auth/AuthContext";
import ConsultationDuration, {
  ConsultationDurationOptions,
} from "../../data-model/types/consultation/ConsultationDuration";
import { getMomentLocale } from "../language/languagesUtil";

const PREFIX = "ConsultationDateSelection";

const classes = {
  duration: `${PREFIX}-duration`,
  narrowDuration: `${PREFIX}-narrowDuration`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.duration}`]: {
    marginTop: 19,
  },

  [`& .${classes.narrowDuration}`]: {
    fontSize: 14,
    marginTop: 5,
  },
}));

interface IConsultationDateSelection {
  consultation: Consultation;
  formState: IFormState;
  isEditing: boolean;
  narrow?: boolean;
  professional?: Professional;
}

const ConsultationDateSelection = ({
  professional,
  consultation,
  isEditing,
  formState,
  narrow = false,
}: IConsultationDateSelection) => {
  const { t: translate, i18n } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  const { currentUser } = useContext<IAuthContext>(AuthContext);

  const calculateAndSetDurationAndEndTime = (duration: number) => {
    formState.setValue("duration", duration);
    formState.setValue(
      ConsultationDuration.timeToPath,
      formState
        .getValue(ConsultationDuration.timeFromPath)
        .clone()
        .add(duration, "minutes"),
    );
  };

  useEffect(() => {
    const fct = (val) => {
      if (
        [ConsultationTypes.INTRO, ConsultationTypes.CHECKIN].includes(
          val?.value,
        )
      ) {
        // Make sure that the duration is set to the default 15 minutes when switching types.
        calculateAndSetDurationAndEndTime(ConsultationDurationOptions.FIFTEEN);
      } else {
        calculateAndSetDurationAndEndTime(
          professional
            ? professional.getConsultationDuration().getValue()
            : ConsultationDuration.getDefault(),
        );
      }
    };

    formState.onValueChange("type", fct);

    return () => {
      formState.removeOnValueChange("type", fct);
    };
  }, []);

  return (
    <Root>
      <Box>
        {!isEditing && (
          <Box>
            <ValueWithLabel
              label={translate("common:consultation.date", "Datum")}
            >
              {consultation
                .getStartDate(
                  DateFormat.ONLY_DATE,
                  getMomentLocale(i18n.language),
                )
                .toString() || translate("common:-", "-")}
            </ValueWithLabel>
          </Box>
        )}
        {isEditing && (
          <>
            <Label value={translate("common:consultation.date", "Datum")} />
            <CustomDatePicker
              {...formState.getInputProps("date")}
              fullWidth
              onChange={(val?: moment.Moment) => {
                if (!val || (val && !val.isValid())) return;

                const date = {
                  date: val.get("date"),
                  month: val.get("month"),
                  year: val.get("year"),
                };

                const timeFromFromFormState = formState.getValue(
                  ConsultationDuration.timeFromPath,
                );

                const timeToFromFormState = formState.getValue(
                  ConsultationDuration.timeToPath,
                );

                let newTimeFrom, newTimeTo;

                if (timeFromFromFormState) {
                  newTimeFrom = timeFromFromFormState.clone().set(date);
                }

                if (timeToFromFormState) {
                  newTimeTo = timeToFromFormState.clone().set(date);
                }

                formState.setValue("date", val);
                // Overrule debounce behavior of setValue by validating immediately.
                // We don't need the debounche because of the val.isValid earlier.
                formState.validate("date");
                formState.setValue(
                  ConsultationDuration.timeFromPath,
                  newTimeFrom,
                );
                formState.setValue(ConsultationDuration.timeToPath, newTimeTo);
              }}
            />
          </>
        )}
      </Box>
      <Box>
        {!isEditing && (
          <ValueWithLabel label={translate("common:consultation.time", "Tijd")}>
            {translate("common:consultation.time.format", {
              defaultValue:
                "{{startTime}} &rarr; {{endTime}} ({{duration}} mins.)",
              duration: consultation.getDurationInMinutes(),
              endTime: consultation.getEndDate(DateFormat.ONLY_TIME),
              startTime: consultation.getStartDate(DateFormat.ONLY_TIME),
            })}
          </ValueWithLabel>
        )}
        {isEditing && (
          <Box
            alignItems={narrow ? "" : "justify"}
            display="flex"
            flexWrap={narrow ? "wrap" : undefined}
            mt={3}
            width="100%"
          >
            <Box mr={narrow ? 0 : 1} pr={1} width={narrow ? "50%" : "100%"}>
              <Label value={translate("common:from", "Van")} />
              <CustomTimePicker
                //TODO: should this do something?
                onClose={() => {}}
                {...formState.getInputProps(ConsultationDuration.timeFromPath)}
                onChange={(val?: moment.Moment) => {
                  if (!val || (val && !val.isValid())) return;

                  const date = formState.getValue("date");

                  val.set({
                    date: date.get("date"),
                    month: date.get("month"),
                    year: date.get("year"),
                  });

                  // Make sure we don't end up in an infinite loop.
                  if (
                    formState.getValue(ConsultationDuration.timeFromPath) ===
                    val
                  )
                    return;

                  // Update timeFrom in formState
                  formState.setValue(ConsultationDuration.timeFromPath, val);
                  // Overrule debounce behavior of setValue by validating immediately.
                  // We don't need the debounche because of the val.isValid earlier.
                  formState.validate(ConsultationDuration.timeFromPath);

                  // Update timeTo with new interval
                  const newTimeTo = val
                    .clone()
                    .add(formState.getValue("duration"), "minutes");

                  formState.setValue("timeTo", newTimeTo);
                }}
              />
            </Box>
            {(currentUser?.isProfessional() || currentUser?.isAdmin()) && (
              <Box mr={narrow ? 0 : 1} width={narrow ? "50%" : "100%"}>
                <Label value={translate("common:to", "Tot")} />
                <CustomTimePicker
                  //TODO: should this do something?
                  onClose={() => {}}
                  {...formState.getInputProps(ConsultationDuration.timeToPath)}
                  onChange={(val: moment.Moment) => {
                    if (!val) return;

                    if (!val.isValid()) return;

                    const date = formState.getValue("date");

                    val.set({
                      date: date.get("date"),
                      month: date.get("month"),
                      year: date.get("year"),
                    });

                    // Make sure we don't end up in an infinite loop by updating the formState unnecessary.
                    if (
                      formState.getValue(ConsultationDuration.timeToPath) ===
                      val
                    )
                      return;

                    // Update timeTo in formState
                    formState.setValue(ConsultationDuration.timeToPath, val);
                    // Overrule debounce behavior of setValue by validating immediately.
                    // We don't need the debounche because of the val.isValid earlier.
                    formState.validate(ConsultationDuration.timeToPath);

                    formState.setValue(
                      "duration",
                      val.diff(
                        formState.getValue(ConsultationDuration.timeFromPath),
                        "minutes",
                      ),
                    );
                  }}
                />
              </Box>
            )}
            {narrow && <Box style={{ flexBasis: "100%", height: 0 }} />}
            <Box mt={narrow ? 3 : 0} width="100%">
              <Label value={translate("common:duration", "Duur")} />
              <Box
                className={clsx(
                  classes.duration,
                  narrow && classes.narrowDuration,
                )}
              >
                {translate("common:consultation.time.duration", {
                  context: narrow ? "small" : "",
                  defaultValue: "→ {{duration}} minuten",
                  duration: formState.getValue("duration"),
                })}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Root>
  );
};

export default ConsultationDateSelection;
