import { useContext } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { useNavigate } from "react-router-dom";
import { Home } from "@mui/icons-material";
import Logout from "../icons/logout";
import AuthContext from "../providers/auth/AuthContext";
import SidebarNavigationAction from "./sidebar/SidebarNavigationAction";

export default function AdminPageHeader() {
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  return (
    <AppBar color="primary" position="static">
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        m="0px 1%"
        width="98%"
      >
        <Toolbar className="" />
        <Box ml="auto">
          <SidebarNavigationAction
            icon={<Home />}
            id={"btnHome"}
            label="Home"
            onClick={() => navigate("/home")}
          />
          <SidebarNavigationAction
            icon={<Logout />}
            id={"btnLogout"}
            label="Afmelden"
            onClick={logout}
          />
        </Box>
      </Box>
    </AppBar>
  );
}
