/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "@react-spring/web";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import Logout from "../icons/logout";
import Profile from "../icons/Profile";
import SidebarNavigationAction from "../layout/sidebar/SidebarNavigationAction";
import { RouteNames } from "../routes/routeNames";

const PREFIX = "UserMenu";

const classes = {
  menuBelow: `${PREFIX}-menuBelow`,
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.menuBelow}`]: {
    background: theme.palette.bloom.dark,
    display: "flex",
    flexDirection: "column",
    height: 0,
    overflow: "hidden",
    position: "absolute",
    top: theme.spacing(10),
    width: "100%",
    zIndex: 2,
  },
}));

const UserMenu = ({ logout, openMenu, isMenuOpen, buttonRef }) => {
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const { t: translate } = useTranslation(["common"]);

  const handleClick = (e) => {
    if ((menuRef.current as any).contains(e.target)) return;

    if (buttonRef.current.contains(e.target)) return;

    openMenu(false);
  };

  const handleEsc = (e) => {
    if (e.key === "Escape") openMenu(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick, false);
    document.addEventListener("keydown", handleEsc, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
      document.removeEventListener("keydown", handleEsc, false);
    };
  }, []);

  const UserMenuBox = animated(StyledBox);

  const animatedUserMenuBoxBelow = useSpring({
    height: isMenuOpen ? 105 : 0,
  });

  return (
    <UserMenuBox
      className={classes.menuBelow}
      ref={menuRef}
      style={animatedUserMenuBoxBelow}
    >
      <SidebarNavigationAction
        icon={<Profile />}
        id={"test_sidebar_button_my_profile"}
        label={translate("common:myprofile", "Mijn Profiel")}
        onClick={(ev) => {
          ev.stopPropagation();
          openMenu(false);
          navigate(RouteNames.Home.Profile.path);
        }}
      />
      <SidebarNavigationAction
        icon={<Logout />}
        id={"test_sidebar_button_logout"}
        label={translate("common:signout", "Afmelden")}
        onClick={logout}
      />
    </UserMenuBox>
  );
};

export default UserMenu;
