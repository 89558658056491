import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";

export default function SuccessStep() {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Glossary,
    BloomUpNamespacesEnum.Common,
  ]);

  const { next, step, totalSteps, error } = useContext(
    ProfessionalOnboardingContext,
  );

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        description={
          error || translate("professional:onboarding.success.description")
        }
        error={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je Profiel")}
        labelNext={translate("common:to.dashboard", "Naar dashboard")}
        onClickNext={next}
        showLanguageSelector={false}
        step={step}
        subhead={
          error
            ? translate("common:awch", "Oei!!")
            : translate("common:congratz", "Proficiat! 🎉")
        }
        subheadicon={error ? <ErrorIcon /> : <CheckCircleIcon />}
        totalSteps={totalSteps}
      />
    </OnboardingContainer>
  );
}
