/**
 * Specialties of a psy. This can be something like 'Burnout'
 */
import type { TFunction } from "i18next";
import { I18Namespaces } from "../../../components/language/I18Namespaces";
import { IGroupSelectObject } from "../../../components/ui/form/select/BaseGroupSelect";
import { ISelectObject } from "../../../components/ui/form/select/BaseSimpleSelect";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import GroupSelectionDataType from "../../GroupSelectionDataType";
import { DataTypePaths } from "../../dataTypePaths";
import Theme, { ThemeModel } from "./Theme";
import Specialty from "./Specialty";

export default class Specialties extends GroupSelectionDataType<
  Theme,
  DataTypePaths.Professional.Specialties
> {
  protected type: any = Specialties;
  protected static path: DataTypePaths.Professional.Specialties = "specialties";

  protected static defaultValue = [];
  protected static allOptions: Array<Theme> | null = null;

  protected fromGraphQL = (value: string): Array<Theme> => JSON.parse(value);

  protected forGraphQL = (value: Array<Theme>): string => JSON.stringify(value);

  constructor(value?: Array<Theme>, fromGQL = false) {
    super(value || Specialties.defaultValue, fromGQL);
  }

  public static setSelectOptions(specialties: Array<ThemeModel>) {
    Specialties.allOptions = specialties.map((s) => new Theme(s));
  }

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {Function} translate The translate function for validation strings.
   * @return {Array<Object>}
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          "specialties.mandatory",
          "Geef minstens 1 specialiteit op.",
        ),
        path: this.getPath(),
        validate: (specialties: Array<string>): boolean =>
          specialties.length > 0,
      },
      {
        message: translate(
          "specialties.toomany",
          "Geef maximum 20 specialiteiten op.",
        ),
        path: this.getPath(),
        validate: (specialties: Array<string>): boolean =>
          specialties.length <= 20,
      },
    ];
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This contains the value, label and an optionally, an icon.
   *
   * @param  {Function} translate The i18next translate hook with the glossary namespace.
   */
  public getSelectOptions(): Array<IGroupSelectObject> {
    if (Specialties.allOptions === null) Specialties.allOptions = [];

    return Specialties.convertThemesToGroupSelectObjects(
      Specialties.allOptions,
    );
  }

  /**
   * Get all the options this data type can hold.
   *
   * @return {Object<Constant>}
   */
  getOptions(): Theme[] | null {
    return Specialties.allOptions;
  }

  /**
   * Return an array of objects usable for a select-type of control.
   * This function only returns the values set to this object.
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   * @return {Array<Object>}
   */

  public getSelectedOptions(): Array<ISelectObject<string>> {
    return this.value
      .map((theme: Theme) => {
        return theme.getSubcategories().map((s: Specialty) => {
          return {
            color: theme.getSecondaryColor(),
            label: s.getTranslationKey(),
            toolTip: s.getTooltip(),
            value: s.getId(),
          };
        });
      })
      .flat();
  }

  public getSelectedOptionsAsGroupSelectObject(): IGroupSelectObject[] {
    return Specialties.convertThemesToGroupSelectObjects(this.value);
  }

  /**
   * Return an array of translated values.
   * This function only returns the values set to this object.
   *
   * @param  {Function} translate The i18next translate hook with the glossary namespace.
   * @return {Array<String>}
   */
  public getTranslatedValues(): Array<string> {
    throw new Error(
      "Please don't use this function, it's only here to statisfy typescript",
    );
  }

  static convertThemesToGroupSelectObjects(
    themes: Array<Theme>,
  ): Array<IGroupSelectObject<string>> {
    return themes.map((sps: Theme) => {
      return {
        color: sps.getPrimaryColor(),
        label: sps.getName(),
        options: sps.getSubcategories().map((s) => {
          return {
            color: sps.getSecondaryColor(),
            label: s.getTranslationKey(),
            toolTip: s.getTooltip(),
            value: s.getId(),
          };
        }),
      };
    });
  }

  /**
   * Get the humanreadible string for this data type's value.
   * Ex: Burn-out, Angsstoornis
   *
   * @param  {any} translate The i18next translate hook with the glossary namespace.
   */
  public prettyPrint(): string {
    return this.getSelectedOptions()
      .map(({ label }) => label)
      .filter(Boolean)
      .join(", ");
  }

  getAsFormStateValue(): Record<
    DataTypePaths.Professional.Specialties,
    IGroupSelectObject<string>[]
  > {
    return {
      [Specialties.path]: Specialties.convertThemesToGroupSelectObjects(
        this.value,
      ),
    };
  }
}
