import { useState } from "react";
import useFetchAllConsultationsForCurrentUser from "../../../../api/consultations/hooks/useFetchAllConsultationsForCurrentUser";
import { IWithChildren } from "../../../../baseInterfaces";
import Consultation from "../../../../data-model/types/consultation/Consultation";
import Professional from "../../../../data-model/types/professional/Professional";
import ClientInformationDrawerContext from "./ClientInformationDrawerContext";

interface ClientInformationDrawerProviderProps extends IWithChildren {
  consult: Consultation;
}

const ClientInformationDrawerProvider = ({
  consult: consultation,
  children,
}: ClientInformationDrawerProviderProps) => {
  const [isBooking, setIsBooking] = useState<boolean>(false);

  const human = consultation.getClient();

  const professional: Professional = consultation.getProfessional();
  const organization = consultation.getClient().getOrganization();

  const { data } = useFetchAllConsultationsForCurrentUser();

  return (
    <ClientInformationDrawerContext.Provider
      value={{
        currentConsultation: consultation,
        human,
        isBooking,
        organization,
        professional,
        professionalConsultations: data,
        setIsBooking,
      }}
    >
      {children}
    </ClientInformationDrawerContext.Provider>
  );
};

export default ClientInformationDrawerProvider;
