import { useQuery } from "@apollo/client";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import GaugeChart from "../../ui/hr-dashboard/GaugeChart";
import ChipItem from "../../ui/hr-dashboard/ChipItem";
import { BloomUpNamespacesEnum } from "../../language/I18Namespaces";
import BreadCrumbs from "../../ui/hr-dashboard/BreadCrumbs";
import { graphql } from "../../../api/__generated__";
const HRDashboards = BloomUpNamespacesEnum.HRDashboards;
const Glossary = BloomUpNamespacesEnum.Glossary;

const query = graphql(`
  query WellBeingPage {
    wellbeingChallenges {
      count
      id
      name
    }
    wellbeingEnergyGauge {
      averageFirstScore
      averageImprovement
      averageLatestScore
    }
    wellbeingProductivityGauge {
      averageFirstScore
      averageImprovement
      averageLatestScore
    }
    wellbeingStressGauge {
      averageFirstScore
      averageImprovement
      averageLatestScore
    }
  }
`);

const WellbeingPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { data } = useQuery(query);

  const stressGaugeData = [
    {
      data: [
        {
          color: "#FEE06C",
          radius: 0.75,
          x: "Yellow",
          y: (data?.wellbeingStressGauge.averageLatestScore || 0) * 100,
        },
      ],
      id: "Stress after",
    },
    {
      data: [
        {
          color: "#F0C82B",
          radius: 0.25,
          x: "Yellow",
          y: (data?.wellbeingStressGauge.averageFirstScore || 0) * 100,
        },
      ],
      id: "Stress before",
    },
  ];

  const productivityGaugeData = [
    {
      data: [
        {
          color: "#84C59C",
          radius: 0.75,
          x: "Green",
          y: (data?.wellbeingProductivityGauge.averageLatestScore || 0) * 100,
        },
      ],
      id: "Productivity after",
    },
    {
      data: [
        {
          color: "#4F7D5F",
          radius: 0.25,
          x: "Green",
          y: (data?.wellbeingProductivityGauge.averageFirstScore || 0) * 100,
        },
      ],
      id: "Productivity before",
    },
  ];

  const energyGaugeData = [
    {
      data: [
        {
          color: "#F9A8A8",
          radius: 0.75,
          x: "Red",
          y: (data?.wellbeingEnergyGauge.averageLatestScore || 0) * 100,
        },
      ],
      id: "Energy after",
    },
    {
      data: [
        {
          color: "#C94C4C",
          radius: 0.25,
          x: "Red",
          y: (data?.wellbeingEnergyGauge.averageFirstScore || 0) * 100,
        },
      ],
      id: "Energy before",
    },
  ];

  const DifferenceBeforeAndAfter = ({ center, bars }) => {
    const ref = useRef(null);

    return (
      <text
        ref={ref}
        style={{ fontSize: "16pt" }}
        x={center[0] - 20}
        y={center[1]}
      >
        {bars[0].value - bars[1].value}%
      </text>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <BreadCrumbs
        pages={location.pathname.replace("/home/hr-dashboards/", "").split("/")}
      ></BreadCrumbs>
      <div style={{ margin: "0px auto", width: "80%" }}>
        <Grid
          container
          spacing={1}
          style={{
            width: "100%",
          }}
        >
          <Grid
            item
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              margin: 0,
            }}
            xs={12}
          >
            <h1 style={{ margin: "15px 0px" }}>
              {t(`${HRDashboards}:translation.wellbeing.header`)}
            </h1>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ margin: 0 }}>
          <h2 style={{ margin: 0, width: "100%" }}>Video</h2>
          <Grid item sm={4} xs={12}>
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                height: "200px",
              }}
              variant="outlined"
            >
              <GaugeChart
                color={(bar) => bar.data.color}
                data={stressGaugeData}
                layer={DifferenceBeforeAndAfter}
                radius={0.5}
              ></GaugeChart>
              <h2
                style={{
                  bottom: "100px",
                  margin: "0px",
                  position: "relative",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {t(`${HRDashboards}:translation.wellbeing.stress`)}
              </h2>
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                height: "200px",
              }}
              variant="outlined"
            >
              <GaugeChart
                color={(bar) => bar.data.color}
                data={productivityGaugeData}
                layer={DifferenceBeforeAndAfter}
                radius={0.5}
              ></GaugeChart>

              <h2
                style={{
                  bottom: "100px",
                  margin: "0px",
                  position: "relative",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {t(`${HRDashboards}:translation.wellbeing.productivity`)}
              </h2>
            </Paper>
          </Grid>
          <Grid item sm={4} xs={12}>
            <Paper
              style={{
                display: "flex",
                flexDirection: "column",
                height: "200px",
              }}
              variant="outlined"
            >
              <GaugeChart
                color={(bar) => bar.data.color}
                data={energyGaugeData}
                layer={DifferenceBeforeAndAfter}
                radius={0.5}
              ></GaugeChart>

              <h2
                style={{
                  bottom: "100px",
                  margin: "0px",
                  position: "relative",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {t(`${HRDashboards}:translation.wellbeing.energy`)}
              </h2>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{ minHeight: "130px", padding: "5px" }}
              variant="outlined"
            >
              <h3 style={{ margin: "5px" }}>
                {t(`${HRDashboards}:translation.wellbeing.topChallenges`)}
              </h3>
              {data?.wellbeingChallenges.map((challenge) => (
                <ChipItem
                  href={""}
                  label={t(`${Glossary}:${challenge.name}`)}
                ></ChipItem>
              ))}
              <ChipItem
                href=""
                label={t(
                  `${HRDashboards}:translation.wellbeing.viewAllChallenges`,
                )}
              ></ChipItem>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default WellbeingPage;
