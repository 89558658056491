import React from "react";
import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const Table = ({ header, children }) => {
  const hasHeader = typeof header !== "undefined";

  return (
    <TableContainer>
      <MuiTable fixedHeader={true} style={{ tableLayout: "auto" }}>
        {hasHeader && <TableHead>{header}</TableHead>}
        <TableBody>{children}</TableBody>
      </MuiTable>
    </TableContainer>
  );
};

Table.Row = TableRow;
Table.Cell = ({ ...props }) => <TableCell size="small" {...props} />;

export default Table;
