import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Specialties from "../../../../data-model/types/professional/Specialties";
import OnboardingContainer from "../../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../../layout/wizard/OnboardingStepContainer";
import Label from "../../../ui/form/FormLabel";
import SpecialtiesSelect from "../../professional-profile/components/SpecialtiesSelect";
import { IProfessionalOnboardingContext } from "../context/professionalOnboarding";
import ProfessionalOnboardingContext from "../context/ProfessionalOnboardingContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";

export default function SpecialtiesStep() {
  const { next, previous, step, formState, totalSteps, specialties } =
    useContext<IProfessionalOnboardingContext>(ProfessionalOnboardingContext);

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Professional,
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Glossary,
  ]);

  const error = formState.testForErrors(Specialties.getPath());

  return (
    <OnboardingContainer>
      <OnboardingStepContainer
        disable={error}
        fullCopyright
        headline={translate("professional:onboarding.title", "Je profiel")}
        labelBack={translate(
          "common:onboarding.button.label.previous",
          "Vorige",
        )}
        labelNext={translate("common:onboarding.button.label.next", "Volgende")}
        onClickBack={previous}
        onClickNext={next}
        showLanguageSelector={false}
        step={step}
        subhead={translate(
          "professional:onboarding.specialities.title",
          "Je specialiteiten",
        )}
        subheadicon={<PersonOutlinedIcon />}
        totalSteps={totalSteps}
      >
        <Label
          label={translate(
            "professional:onboarding.specialities.description",
            "Kies tot 20 specialiteiten uit.",
          )}
        />
        <SpecialtiesSelect
          formState={formState}
          specialtiesObject={specialties ? specialties : new Specialties()}
        />
      </OnboardingStepContainer>
    </OnboardingContainer>
  );
}
