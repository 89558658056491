import { createContext } from "react";
import { IConsultationsOverviewContext } from "./consultations";

export const defaultConsultationsValues: IConsultationsOverviewContext = {
  closeAddConsultation: () => {},
  closeConsultation: () => {},
  consultations: undefined,
  groupedConsultations: [],
  loading: true,
  openAddConsultation: () => {},
  openConsultationWithUuid: () => {},
  refetchAll: () => {},
  refetchOneConsultation: () => {},
  selectedConsultation: undefined,
  setSelectedConsultation: () => {},
  showAddConsultation: false,
};

const ConsultationsOverviewContext =
  createContext<IConsultationsOverviewContext>(defaultConsultationsValues);

export default ConsultationsOverviewContext;
