import { styled } from "@mui/material/styles";

const PREFIX = "SharedSelectStyles";

export const classes = {
  formHelper: `${PREFIX}-formHelper`,
  root: `${PREFIX}-root`,
};

export const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",

  [`& .${classes.root}`]: {
    "& .select__control": {
      background: theme.palette.white.main,
      cursor: "pointer",
      minHeight: 52,
      minWidth: 200,
      ...theme.controls.border.normal,

      "& .select__indicators": {
        "& .select__clear-indicator": {
          display: "none",
        },
        "& .select__indicator-separator": {
          display: "none",
        },
        padding: 0,
        position: "absolute",

        right: 10,

        top: 5,
      },

      "& .select__value-container": {
        alignItems: "left",
        display: "flex",
        width: "100%",
        ...theme.controls.input,
        "& .select__multi-value": {
          ...theme.controls.chip.container,

          "& .select__multi-value__label": {
            ...theme.controls.chip?.label,
          },

          "& .select__multi-value__remove": {
            ...theme.controls.chip?.remove,
          },
        },
        "& .select__placeholder": {
          ...theme.controls.placeholder,
          paddingLeft: 0,
        },

        "& input": {
          // We need to use an ID because this element is not used but is messing with our styling
          height: 0,
        },

        paddingBottom: 0,

        paddingTop: 0,
      },

      "&:hover": {
        ...theme.controls.border?.hover,
      },
    },
    "& .select__control--is-focused": {
      ...theme.controls.border?.hover,
      boxShadow: "none",
    },

    "& .select__menu": {
      "& .select__option": {
        cursor: "pointer",
        fontSize: theme.controls.fontSize,
        textAlign: "left",
      },
      borderColor: theme.palette.bloom.main,
      borderStyle: "solid",
      borderWidth: 3,
      boxShadow: "none",
      marginTop: -3,

      zIndex: 5,
    },

    height: "100%",

    width: "100%",
  } as const,
  [`& .${classes.formHelper}`]: {
    color: `${theme.palette.red.main} !important`,
    fontSize: 14,
    textAlign: "left",
  } as const,
}));
