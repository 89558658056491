/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react";
import * as PusherTypes from "pusher-js";
import * as Sentry from "@sentry/react";
import { PusherEvents } from "../pusherEvents";
import OnlineContext from "../context/OnlineContext";
import { IOnlineContext } from "../context/onlineContext.s";

interface PresenceSubscriptionProps {
  channel: string | undefined;
  subscribe?: boolean;
}

interface IPresenceSubscriptionReturnValue {
  isSubscribed: boolean;
  presenceChannel?: PusherTypes.PresenceChannel;
}

export default function usePresenceSubscription({
  channel,
  subscribe = true,
}: PresenceSubscriptionProps): IPresenceSubscriptionReturnValue {
  const { pusher, isReady } = useContext<IOnlineContext>(OnlineContext);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [presenceChannel, setPresenceChannel] = useState<
    PusherTypes.PresenceChannel | undefined
  >(undefined);

  useEffect(() => {
    if (pusher && isReady && !isSubscribed && channel && subscribe) {
      setPresenceChannel(
        pusher.subscribe(channel) as PusherTypes.PresenceChannel,
      );

      pusher.connection.bind(PusherEvents.ERROR, (error) => {
        Sentry.captureMessage(JSON.stringify(error));
      });

      if (presenceChannel) {
        presenceChannel.bind(PusherEvents.SUBSCRIPTION_SUCCEEDED, () =>
          setIsSubscribed(true),
        );
        presenceChannel.bind(PusherEvents.SUBSCRIPTION_ERROR, (error) => {
          setIsSubscribed(false);
          Sentry.captureMessage(JSON.stringify(error));
        });
      }
    }
  }, [isReady, presenceChannel, pusher, subscribe]);

  useEffect(() => {
    // Clean up subscriptions
    return () => {
      if (pusher && channel) pusher.unsubscribe(channel);

      setPresenceChannel(undefined);
      setIsSubscribed(false);
    };
  }, []);

  return {
    isSubscribed,
    presenceChannel,
  };
}
