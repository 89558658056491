import List from "@mui/material/List";
import React from "react";
import { useTranslation } from "react-i18next";
import Professional from "../../../data-model/types/professional/Professional";
import { UserRoles } from "../../../data-model/types/User";
import LeftSection from "../../layout/LeftSection";
import UnavailableSection from "../../layout/UnavailableSection";
import { I18Namespaces } from "../../language/I18Namespaces";
import ProfessionalListItem from "./ProfessionalForOtherUser";

interface IListOfProfessionals {
  buttonIcon: any;
  buttonLabel: string;
  focus: number | undefined;
  handleButton: () => void;
  handleClick: (id: number) => void;
  pageTitle: string;
  professionals: Array<Professional>;
  roleType: UserRoles;
}

export default function ListOfProfessionals({
  professionals,
  focus,
  pageTitle,
  buttonLabel,
  buttonIcon,
  handleClick,
  handleButton,
}: IListOfProfessionals) {
  const { t: translate } = useTranslation<I18Namespaces>("client");

  const empty = translate(
    "client:professionals.none",
    "Je bent nog niet in contact met één van onze psychologen.",
  );

  return (
    <LeftSection
      buttonIcon={buttonIcon}
      buttonLabel={buttonLabel}
      handleTitleButton={handleButton}
      pageTitle={pageTitle}
      short={false}
    >
      {professionals.length ? (
        <List>
          {professionals.map((professional: Professional) => (
            <ProfessionalListItem
              handleClick={handleClick}
              isOpen={focus ? professional.getID() === focus : false}
              key={professional.getID()}
              professional={professional}
            />
          ))}
        </List>
      ) : (
        <UnavailableSection text={empty} />
      )}
    </LeftSection>
  );
}
