import React from "react";
import { styled } from "@mui/material/styles";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Label from "../../ui/form/FormLabel";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import OnboardingContainer from "../../layout/wizard/OnboardingContainer";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
const PREFIX = "MaintenancePage";

const classes = {
  description: `${PREFIX}-description`,
  descriptionContainer: `${PREFIX}-descriptionContainer`,
  icon: `${PREFIX}-icon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.descriptionContainer}`]: {
    margin: "0 auto",
  },

  [`& .${classes.icon}`]: {
    color: theme.palette.bloom.light,
    fontSize: 200,
  },

  [`& .${classes.description}`]: {
    fontSize: 20,
    margin: "0 auto",
    paddingBottom: 10,
    paddingTop: 20,
    textAlign: "center",
    width: "70%",
  },
}));

const MaintenancePage = () => {
  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  return (
    <Root>
      <CssBaseline />
      <OnboardingContainer>
        <OnboardingStepContainer
          description={""}
          fullCopyright
          headline={translate("maintenance.page.title")}
          showLanguageSelector={true}
        >
          <Grid container style={{ position: "relative" }}>
            <Box className={classes.descriptionContainer}>
              <Label
                className={classes.description}
                label={translate("maintenance.page.description")}
              />
              <CloudOffIcon className={classes.icon} />
            </Box>
          </Grid>
        </OnboardingStepContainer>
      </OnboardingContainer>
    </Root>
  );
};

export default MaintenancePage;
