/* eslint-disable react-hooks/exhaustive-deps */

import { EventContentArg } from "@fullcalendar/core";
import { Box, Typography, styled, useTheme } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import moment from "moment";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConsultationStatus } from "../../../api/__generated__/graphql";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../language/I18Namespaces";
import { APICalendarEvent } from "../../../api/calendarEvents/calendarEvents";
import { CalendarEventTypes } from "../../../data-model/types/consultation/CalendarEvent";

const PREFIX = "CalendarEventRenderer";

const classes = {
  eventContainer: `${PREFIX}-eventContainer`,
  eventDataContainer: `${PREFIX}-eventDataContainer`,
  eventTime: `${PREFIX}-eventTime`,
  eventTitle: `${PREFIX}-eventTitle`,
  removeEventButton: `${PREFIX}-removeEventButton`,
};

const StyledBox = styled(Box)<{
  cursor: string;
  eventColor: string | null;
}>(({ theme, eventColor, cursor }) => ({
  [`& .${classes.removeEventButton}`]: {
    cursor: "pointer",
  },

  [`&.${classes.eventContainer}`]: {
    alignItems: "center",
    backgroundColor: `${eventColor}`,
    borderRadius: 5,
    cursor: `${cursor}`,
    display: "flex",
    height: "100%",
    justifyContent: "center",
    position: "relative",
  },

  [`& .${classes.eventDataContainer}`]: {
    left: "0",
    position: "absolute",
    top: "0",
  },

  [`& .${classes.eventTime}`]: {
    color: theme.palette.white.main,
    margin: "0",
    marginLeft: 3,
  },
  [`& .${classes.eventTitle}`]: {
    fontSize: "80%",
    marginLeft: 5,
    marginTop: 2,
  },
}));

interface ICalendarEventRenderer {
  deleteCalendarEvent: (input: APICalendarEvent.Delete.Input) => void;
  eventInfo: EventContentArg;
}

interface IEventProperties {
  calendarEventTypes?: CalendarEventTypes | null;
  color: string;
  description: string;
  typographyGutterBottom?: boolean | undefined;
  typographyVariant?: "inherit" | Variant | undefined;
}

function CalendarEventRenderer({
  eventInfo,
  deleteCalendarEvent,
}: ICalendarEventRenderer) {
  const theme = useTheme();
  const eventType: CalendarEventTypes = eventInfo.event.extendedProps.type;
  const status: ConsultationStatus = eventInfo.event.extendedProps.status;

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
    BloomUpNamespacesEnum.Professional,
  ]);

  const getEventProperties = (): IEventProperties => {
    if (
      status &&
      status !== "CANCELLED_BY_HUMAN" &&
      status !== "CANCELLED_BY_PROFESSIONAL" &&
      status !== "CANCELLED"
    )
      return {
        color: theme.palette.bloom.dark,
        description: eventInfo.event.title,
        typographyGutterBottom: true,
        typographyVariant: "h5",
      };
    else if (
      status &&
      (status === "CANCELLED_BY_HUMAN" ||
        status === "CANCELLED_BY_PROFESSIONAL" ||
        status === "CANCELLED")
    )
      return {
        color: theme.palette.red.dark,
        description: eventInfo.event.title,
        typographyGutterBottom: true,
        typographyVariant: "h5",
      };
    else if (eventType === CalendarEventTypes.Availability)
      return {
        calendarEventTypes: CalendarEventTypes.Availability,
        color: theme.palette.green.dark,
        description: `${moment(eventInfo.event.start)
          .format("HH:mm")
          .toLocaleString()} → ${moment(eventInfo.event.end)
          .format("HH:mm")
          .toLocaleString()}`,
        typographyGutterBottom: true,
        typographyVariant: "subtitle1",
      };
    else if (eventType === CalendarEventTypes.Online)
      return {
        color: theme.palette.orange.dark,
        description: "Online",
        typographyGutterBottom: true,
        typographyVariant: "h5",
      };
    else
      return {
        color: theme.palette.porple.dark,
        description: translate("common:unknown_event", "Onbekend event"),
        typographyGutterBottom: true,
        typographyVariant: "h5",
      };
  };

  const [eventColor, setEventColor] = useState<string>(
    getEventProperties().color,
  );

  const eventProps: IEventProperties = useMemo(getEventProperties, [eventInfo]);

  return (
    <StyledBox
      className={classes.eventContainer}
      cursor={eventType === CalendarEventTypes.Availability ? "move" : ""}
      eventColor={eventColor}
    >
      <Box className={classes.eventDataContainer}>
        <Typography
          className={
            eventProps.calendarEventTypes === CalendarEventTypes.Availability
              ? classes.eventTime
              : classes.eventTitle
          }
          component="div"
          gutterBottom={eventProps.typographyGutterBottom}
          variant={eventProps.typographyVariant}
        >
          {eventProps.description}
        </Typography>
      </Box>
      {eventType === CalendarEventTypes.Availability && (
        <Box
          onMouseLeave={() => {
            setEventColor(theme.palette.green.dark);
          }}
          onMouseOver={() => {
            setEventColor(theme.palette.red.dark);
          }}
        >
          <DeleteOutlineOutlinedIcon
            className={classes.removeEventButton}
            onClick={() => {
              deleteCalendarEvent({
                ids: [Number(eventInfo.event.id)],
              });
            }}
          />
        </Box>
      )}
    </StyledBox>
  );
}

export default CalendarEventRenderer;
