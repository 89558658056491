import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import OnboardingStepContainer from "../../layout/wizard/OnboardingStepContainer";
import { I18Namespaces } from "../../language/I18Namespaces";

export default function Success() {
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  return (
    <OnboardingStepContainer
      description={translate(
        "common:password.reset.complete",
        "Wij hebben u een email gestuurd met een link om uw wachtwoord te resetten.",
      )}
      fullCopyright
      headline={translate("common:sent", "Verzonden")}
      noFooter
      subheadicon={<EmailIcon />}
    />
  );
}
