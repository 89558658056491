/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SpecificWaitingRoomPresence from "../../../utils/pusher/channels/components/SpecificWaitingRoomPresence";
import WaitingRoomPresence from "../../../utils/pusher/channels/components/WaitingRoomPresence";
import { QualityQuestions } from "../../quality-questions/QualityQuestions.types";
import { RouteNames } from "../../routes/routeNames";
import { ICallContext } from "../../ui/call/context/Call";
import CallContext from "../../ui/call/context/CallContext";
import ProfessionalCallContainer from "./ProfessionalCallContainer";

export default function ProfessionalCallPageWithState() {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const { callInfo } = useContext<ICallContext>(CallContext);

  useEffect(() => {
    if (callInfo && callInfo.isCallEnded) {
      if (callInfo.someoneHasEntered && callInfo.endTime && uuid) {
        const redirectPath = RouteNames.Home.path;

        navigate(
          QualityQuestions.getQuestionPath(
            QualityQuestions.Entrypoints.AfterConsultation,
            redirectPath,
            true,
          ),
        );
      } else {
        // Call did not really "start" (because the client was never connected at the same time as professional),
        // so we can push the professional to the dashboard)
        navigate(RouteNames.Home.path);
      }
    }
  }, [callInfo, navigate, uuid]);

  return (
    <WaitingRoomPresence>
      <SpecificWaitingRoomPresence uuid={uuid || ""}>
        <ProfessionalCallContainer />
      </SpecificWaitingRoomPresence>
    </WaitingRoomPresence>
  );
}
