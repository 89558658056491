import { useContext, useEffect, useState } from "react";
import Pusher from "pusher-js";
import AuthContext from "../../../../components/providers/auth/AuthContext";
import environment from "../../../../environment/environment";

interface IUsePusherReturnValue {
  isReady: boolean;
  pusher: Pusher | undefined;
}

export default function usePusher(): IUsePusherReturnValue {
  const [pusher, setPusher] = useState<Pusher>();
  const [isReady, setIsReady] = useState<boolean>(false);
  const { accessToken, isAuthenticated } = useContext(AuthContext);

  if (environment.REACT_APP_ENV !== "production") Pusher.logToConsole = true;

  useEffect(() => {
    if (
      !pusher &&
      isAuthenticated &&
      accessToken &&
      environment.REACT_APP_PUSHER_APP_KEY
    ) {
      try {
        const instance: Pusher = new Pusher(
          environment.REACT_APP_PUSHER_APP_KEY,
          {
            auth: {
              headers: {
                authorization: `Bearer ${accessToken}`,
              },
            },
            authEndpoint: `${environment.REACT_APP_SERVER_URL}${environment.REACT_APP_PUSHER_AUTH}`,
            cluster: environment.REACT_APP_PUSHER_CLUSTER,
          },
        );

        setPusher(instance);
      } catch (ex) {
        console.error("Could not create pusher instance:", ex);
      }
    }
  }, [isAuthenticated, accessToken, pusher]);

  useEffect(() => {
    if (pusher) setIsReady(true);
  }, [pusher]);

  return { isReady, pusher };
}
