import { useContext, useEffect } from "react";
import LoginForm from "./LoginForm";
import AuthContext from "../../providers/auth/AuthContext";
import useTrackPageView from "../../providers/matomo/useTrackPageView";
import { useLocation, useNavigate } from "react-router-dom";
import { getRedirectRoute } from "../../routes/routingUtils";
import { RouteNames } from "../../routes/routeNames";

export default function LoginPage() {
  useTrackPageView("Login Page");

  const { isAuthenticated, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && !loading) {
      //   Captures redirect if present, and executes it.
      navigate(getRedirectRoute(location, RouteNames.Home.path));
    }
  }, [isAuthenticated, loading, location, navigate]);

  return <LoginForm></LoginForm>;
}
