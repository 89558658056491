import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// TODO: Redesign

const UnauthorizedPage = () => (
  <Box p={3}>
    <Typography variant="h4">You're not allowed to see this page</Typography>
  </Box>
);

export default UnauthorizedPage;
