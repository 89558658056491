import {
  Box,
  Button,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useMutation } from "@apollo/client";
import { useContext, useState } from "react";
import { graphql } from "../../api/__generated__";
import { Language } from "../../api/__generated__/graphql";
import {
  I18NexusLanguages,
  languageToCaptionMap,
} from "../language/languagesUtil";
import AuthContext from "../providers/auth/AuthContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface LanguageDropdownProps {
  textColor?: string;
  updateMatchedHuman?: boolean;
}

const i18nToGraphLanguageMap: Record<I18NexusLanguages, Language> = {
  en: "en",
  "fr-BE": "frBe",
  "nl-BE": "nlBe",
};

const MUTATION_LANGUAGEDROPDOWN_UPDATE = graphql(`
  mutation LanguageDropdownUpdate($language: Language!) {
    setCurrentUserLanguage(language: $language) {
      id
      preferredLanguage
    }
  }
`);

const updateMatchLanguageMutation = graphql(`
  mutation UpdateMatchedHumanLanguage($language: Language!) {
    updateMatchedHumanLanguage(language: $language) {
      id
    }
  }
`);

export default function LanguageDropdown({
  textColor = "#000000",
  updateMatchedHuman = false,
}: LanguageDropdownProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { i18n, ready } = useTranslation();

  const { isAuthenticated } = useContext(AuthContext);

  const [updatePreferredLanguage] = useMutation(
    MUTATION_LANGUAGEDROPDOWN_UPDATE,
  );

  const [updateLanguage] = useMutation(updateMatchLanguageMutation);

  if (!ready) return <LinearProgress />;

  const changeLanguage = (selectedLanguage: I18NexusLanguages) => {
    i18n.changeLanguage(selectedLanguage);

    if (updateMatchedHuman) {
      updateLanguage({
        variables: {
          language:
            selectedLanguage === "nl-BE"
              ? "nl"
              : selectedLanguage === "fr-BE"
              ? "fr"
              : "en",
        },
      });
    } else {
      if (isAuthenticated) {
        updatePreferredLanguage({
          variables: {
            language: i18nToGraphLanguageMap[selectedLanguage],
          },
        });
      }
    }
  };

  return (
    <Box>
      <Button
        aria-controls={open ? "language-select-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        id="choose-language-button"
        onClick={handleClick}
        sx={{
          alignItems: "center",
          color: textColor,
          display: "flex",
          paddingLeft: "0px",
          paddingRight: "0px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: "15px",
            fontStyle: "normal",
            textTransform: "none",
          }}
        >
          {languageToCaptionMap.get(i18n.language as I18NexusLanguages)}
        </Typography>
        <ArrowDropDownIcon sx={{ marginBottom: "4px" }} />
      </Button>
      <Menu
        MenuListProps={{
          "aria-labelledby": "choose-language-button",
        }}
        anchorEl={anchorEl}
        id="language-select-menu"
        onClose={handleClose}
        open={open}
        sx={{ "& .MuiMenu-paper": { border: "none" } }}
        variant="selectedMenu"
      >
        <MenuItem
          onClick={() => {
            changeLanguage(I18NexusLanguages.FLEMISH);
            handleClose();
          }}
        >
          Nederlands
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage(I18NexusLanguages.FRENCH);
            handleClose();
          }}
        >
          Français
        </MenuItem>
        <MenuItem
          onClick={() => {
            changeLanguage(I18NexusLanguages.ENGLISH);
            handleClose();
          }}
        >
          English
        </MenuItem>
      </Menu>
    </Box>
  );
}
