import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import Paper from "@mui/material/Paper";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import TabBar from "./TabBar";

const PREFIX = "BreadCrumbs";

const classes = {
  icon: `${PREFIX}-icon`,
  link: `${PREFIX}-link`,
  nextIcon: `${PREFIX}-nextIcon`,
  pageName: `${PREFIX}-pageName`,
  paper: `${PREFIX}-paper`,
  root: `${PREFIX}-root`,
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.link}`]: {
    color: "black",
    display: "flex",
    textDecoration: "none",
  },

  [`& .${classes.icon}`]: {
    alignItems: "flex-start",
    flexDirection: "row",
    flexWrap: "nowrap",
    height: 30,
    justifyContent: "flex-start",
    marginRight: theme.spacing(0.5),
    width: 30,
  },

  [`&.${classes.paper}`]: {
    backgroundColor: "#FFFFFF",
    borderBottom: "1px solid #E5DCD4",
    color: theme.palette.text.secondary,
    display: "flex",
    height: "82px",
    padding: 26,
    textAlign: "center",
    width: "calc(100vw - 270px)",
  },

  [`& .${classes.nextIcon}`]: {
    height: "100%",
  },

  [`& .${classes.pageName}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    fontSize: "16pt",
    justifyContent: "space-between",
    margin: "0px",
  },
}));

const BreadCrumbs = (props) => {
  return (
    <StyledPaper className={classes.paper}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        style={{ width: "50%" }}
      >
        <Typography className={classes.link} color="textPrimary">
          <Link
            className={classes.link}
            color="inherit"
            to="/home/hr-dashboards/"
          >
            <HomeIcon className={classes.icon} />
          </Link>
          {props.pages[0] !== "" ? (
            props.pages.map((page) => {
              return (
                <Link
                  className={classes.link}
                  color="inherit"
                  to={`/home/hr-dashboards/${page}`}
                >
                  <NavigateNextIcon
                    className={classes.nextIcon}
                    fontSize="small"
                  />
                  <h3 className={classes.pageName}>
                    {page.replace("-", "").replace("page", "")}
                  </h3>
                </Link>
              );
            })
          ) : (
            <div></div>
          )}
        </Typography>
      </Breadcrumbs>
      <TabBar></TabBar>
    </StyledPaper>
  );
};

export default BreadCrumbs;
