import {
  RadialBar,
  RadialBarCustomLayer,
  RadialBarDatum,
  RadialBarLayerId,
  RadialBarSerie,
} from "@nivo/radial-bar";
import { compact } from "lodash";
import { useTranslation } from "react-i18next";
import { BloomUpNamespacesEnum } from "../../language/I18Namespaces";
const HRDashboards = BloomUpNamespacesEnum.HRDashboards;

interface props {
  color: (value) => string;
  data: RadialBarSerie<RadialBarDatum>[];
  layer: RadialBarLayerId | RadialBarCustomLayer | undefined;
  radius: number;
}

function GaugeChart(props: props) {
  const { radius, data, color, layer } = props;
  const { t } = useTranslation();

  return (
    <RadialBar
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.9]],
      }}
      borderWidth={1}
      circularAxisOuter={null}
      colors={color}
      cornerRadius={2}
      data={data}
      enableCircularGrid={false}
      enableRadialGrid={false}
      endAngle={90}
      height={250}
      innerRadius={radius}
      layers={compact(["tracks", "bars", "labels", layer])}
      margin={{ bottom: 20, left: 20, right: 20, top: 20 }}
      maxValue={100}
      padding={0.1}
      radialAxisStart={null}
      startAngle={-90}
      tooltip={(x) => {
        return (
          <div
            style={{
              backgroundColor: "white",
              borderRadius: 2,
              boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
              padding: 5,
            }}
          >
            {t(
              `${HRDashboards}:translation.wellbeing.${x.bar.groupId
                .toLowerCase()
                .replace(" ", "_")}`,
            )}
            : {Math.round(x.bar.data.y)}%
          </div>
        );
      }}
      valueFormat=">-.2f"
      width={300}
    />
  );
}

export default GaugeChart;
