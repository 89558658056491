import { createContext } from "react";
import Consultation from "../../../../data-model/types/consultation/Consultation";
import Human from "../../../../data-model/types/human/Human";
import Organization from "../../../../data-model/types/Organization";
import Professional from "../../../../data-model/types/professional/Professional";
import { IClientInformationDrawerContext } from "./ClientInformationDrawer";

const defaultClientInformationDrawerValues: IClientInformationDrawerContext = {
  currentConsultation: new Consultation(),
  human: new Human(),
  isBooking: false,
  organization: new Organization(),
  professional: new Professional(),
  professionalConsultations: [],
  setIsBooking: () => {},
};

const ClientInformationDrawerContext =
  createContext<IClientInformationDrawerContext>(
    defaultClientInformationDrawerValues,
  );

export default ClientInformationDrawerContext;
