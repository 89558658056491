import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { useContext, useState } from "react";
import { animated, useSpring } from "@react-spring/web";
import { IWithChildren } from "../../../../../baseInterfaces";
import { ICallContext } from "../../context/Call";
import CallContext from "../../context/CallContext";

const PREFIX = "ChimeButtonBar";

const classes = {
  MoreHorizIcon: `${PREFIX}-MoreHorizIcon`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  centeredButtons: `${PREFIX}-centeredButtons`,
  expandMoreIcon: `${PREFIX}-expandMoreIcon`,
  hideToggleButton: `${PREFIX}-hideToggleButton`,
  hideToggleButtonContainer: `${PREFIX}-hideToggleButtonContainer`,
  root: `${PREFIX}-root`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")<{ isSmallerThanThirdBreakpoint: boolean }>(
  ({ isSmallerThanThirdBreakpoint }) => ({
    [`& .${classes.root}`]: {
      height: "100%",
      overflow: "hidden",
      position: "relative",
      width: "100%",
    },

    [`& .${classes.buttonContainer}`]: {
      display: "flex",
      gap: 15,
      justifyContent: "space-between",
      position: "absolute",
      width: "100%",
      zIndex: 6,
    },

    [`& .${classes.centeredButtons}`]: {
      display: "flex",
      flexGrow: 8,
      gap: 15,
      justifyContent: "center",
    },

    [`& .${classes.hideToggleButtonContainer}`]: {
      bottom: 0,
      color: "white",
      display: "flex",
      justifyContent: "center",
      position: "absolute",
      width: "100%",
      zIndex: 6,
    },

    [`& .${classes.hideToggleButton}`]: {
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
    },

    [`& .${classes.expandMoreIcon}`]: {
      fontSize: isSmallerThanThirdBreakpoint ? 35 : 40,
    },

    [`& .${classes.MoreHorizIcon}`]: {
      fontSize: isSmallerThanThirdBreakpoint ? 35 : 40,
    },
  }),
);

export default function ChimeButtonBar({ children }: IWithChildren) {
  const [isOpen, setIsOpen] = useState(true);

  const { isSmallerThanThirdBreakpoint } =
    useContext<ICallContext>(CallContext);

  const AnimatedDrawer = animated(Box);

  const animatedDrawerProps = useSpring({ bottom: isOpen ? 60 : 60 * -1 });

  return (
    <Root isSmallerThanThirdBreakpoint={isSmallerThanThirdBreakpoint}>
      <AnimatedDrawer
        className={classes.buttonContainer}
        style={animatedDrawerProps}
      >
        <Box className={classes.centeredButtons}>{children}</Box>
      </AnimatedDrawer>
      <Box className={classes.hideToggleButtonContainer}>
        <Box
          className={classes.hideToggleButton}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <ExpandMoreOutlinedIcon className={classes.expandMoreIcon} />
          ) : (
            <ExpandLessIcon className={classes.expandMoreIcon} />
          )}
        </Box>
      </Box>
    </Root>
  );
}
