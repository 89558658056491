import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { ReactText } from "react";

const PREFIX = "CardItem";

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
};

const StyledCard = styled(Card)({
  [`&.${classes.root}`]: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    fontSize: 36,
    fontWeight: "bold",
    height: "100%",
    justifyContent: "center",
    textAlign: "center",
  },
  [`& .${classes.title}`]: {
    fontSize: 18,
  },
});

interface CardItemProps {
  title?: string | null;
  value?: ReactText;
}

export default function CardItem(props: CardItemProps) {
  return (
    <StyledCard className={classes.root} variant="outlined">
      <CardContent>{props.value ?? "..."}</CardContent>
      <CardContent className={classes.title}>{props.title}</CardContent>
    </StyledCard>
  );
}
