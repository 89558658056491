import moment from "moment";
import DataType from "./DataType";
import { DateFormat } from "./Date";

interface BaseTypeModel {
  createdAt?: moment.MomentInput;
  id?: number | undefined;
  updatedAt?: moment.MomentInput;
}

/**
 * The base type is used for higher order data model objects
 * like organization, user, human, etc.
 */
//TODO : should we provide a real path for BaseTypes?
abstract class BaseType<T, PathName extends string> extends DataType<
  T,
  PathName
> {
  protected id = 0;
  protected createdAt: moment.Moment | undefined = undefined;
  protected updatedAt: moment.Moment | undefined = undefined;

  constructor(model?: BaseTypeModel) {
    super();

    if (!model) return;

    if (model.id) this.id = model.id;

    if (model.createdAt) this.createdAt = moment(model.createdAt);

    if (model.updatedAt) this.updatedAt = moment(model.updatedAt);
  }

  /**
   * Get the id of this data type.
   */
  public getID(): number {
    return this.id;
  }

  /**
   * Get the date, time and timezone of the moment this data type was created.
   */
  public getCreatedAt(
    dateFormat?: DateFormat | string,
    locale?: string,
  ): moment.Moment | string | undefined {
    if (!this.createdAt) return;

    if (locale) {
      return moment(this.createdAt).locale(locale).format(dateFormat);
    }

    const formatted = dateFormat
      ? this.createdAt.format(dateFormat)
      : this.createdAt;

    return formatted;
  }

  /**
   * Get the date, time and timezone of the moment this data type was last updated.
   */
  public getUpdatedAt(
    dateFormat?: DateFormat,
    locale?: string,
  ): moment.Moment | string | undefined {
    if (!this.updatedAt) return;

    if (locale) moment.locale(locale);

    const formatted = dateFormat
      ? this.updatedAt.format(dateFormat)
      : this.updatedAt;

    return formatted;
  }
}

export default BaseType;
