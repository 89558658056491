import React from "react";
import { colors } from "../providers/theme/ThemeProvider";

export const MaleIcon = ({ fill = "new 0 0 24 24", size = "24px" }) => (
  <svg
    enableBackground={fill}
    fill="#fff"
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <g>
      <path
        d="M12,0C5.3828125,0,0,5.3833008,0,12s5.3828125,12,12,12s12-5.3833008,12-12S18.6171875,0,12,0z"
        fill={colors.bloom.dark}
      />
      <path
        d="M12,23C5.9345703,23,1,18.0654297,1,12S5.9345703,1,12,1s11,4.9345703,11,11S18.0654297,23,12,23z"
        fill={colors.white.main}
      />
      <path
        d="M19.119751,3.630249C20.7561646,5.5509644,21.75,8.0349121,21.75,10.75   c0,6.0654297-4.9345703,11-11,11c-2.7150879,0-5.1990356-0.9938354-7.119751-2.630249C5.6494751,21.4898682,8.6496582,23,12,23   c6.0654297,0,11-4.9345703,11-11C23,8.6496582,21.4898682,5.6494751,19.119751,3.630249z"
        fill={colors.bloom.main}
        opacity="0.1"
      />
      <circle cx="8" cy="9" fill={colors.bloom.dark} r="1" />
      <circle cx="16" cy="9" fill={colors.bloom.dark} r="1" />
      <path
        d="M19.6201172,14.0185547c-0.2226563-0.0541992-0.4550781,0.0493164-0.5644531,0.2514648   C19.0400391,14.2998047,18.6494141,15,17.5,15c-0.6318359,0-1.046875-0.2070313-1.5263672-0.4472656   C15.4550781,14.293457,14.8681641,14,14,14c-0.9749146,0-1.6104736,0.3659058-2,0.8853149   C11.6104736,14.3659058,10.9749146,14,10,14c-0.8681641,0-1.4550781,0.293457-1.9736328,0.5527344   C7.546875,14.7929688,7.1318359,15,6.5,15c-1.1494141,0-1.5400391-0.7001953-1.5527344-0.7236328   c-0.1035156-0.2075195-0.3320313-0.3168945-0.5625-0.2631836C4.1591797,14.0664063,4,14.2680664,4,14.5   C4,14.5449219,4.0624928,19,9.5,19c1.1314697,0,1.9953613-0.4864502,2.5-1.1372681C12.5046387,18.5135498,13.3685303,19,14.5,19   c5.4374924,0,5.5-4.4550781,5.5-4.5C20,14.2709961,19.8427734,14.074707,19.6201172,14.0185547z"
        fill={colors.bloom.dark}
      />
      <path
        d="M9.5,18c-2.6582031,0-3.7460938-1.215332-4.1914063-2.2143555C5.6308594,15.9135742,6.0244141,16,6.5,16   c0.8681641,0,1.4550781-0.293457,1.9736328-0.5527344C8.953125,15.2070313,9.3681641,15,10,15c1.2402344,0,1.5,0.815918,1.5,1.5   C11.5,17.2211914,10.7353516,18,9.5,18z"
        fill={colors.white.main}
      />
      <path
        d="M9.5,17.5c-2.1364746,0-3.2491455-0.7857056-3.838501-1.6126099   c-0.1213379-0.0314331-0.2472534-0.0598145-0.3529053-0.1017456C5.7539063,16.784668,6.8417969,18,9.5,18   c1.2353516,0,2-0.7788086,2-1.5c0-0.1040649-0.0090942-0.2109375-0.0236816-0.3178101   C11.3606567,16.8463745,10.6317139,17.5,9.5,17.5z"
        fill={colors.bloom.dark}
        opacity="0.1"
      />
      <path
        d="M14.5,18c-1.2353516,0-2-0.7788086-2-1.5c0-0.684082,0.2597656-1.5,1.5-1.5   c0.6318359,0,1.046875,0.2070313,1.5263672,0.4472656C16.0449219,15.706543,16.6318359,16,17.5,16   c0.4814453,0,0.8779297-0.0883789,1.203125-0.2192383C18.2646484,16.78125,17.1806641,18,14.5,18z"
        fill={colors.white.main}
      />
      <path
        d="M14.5,17.5c-1.1317139,0-1.8607178-0.6535645-1.9763184-1.317749   C12.5090942,16.2890625,12.5,16.3959351,12.5,16.5c0,0.7211914,0.7646484,1.5,2,1.5   c2.6806641,0,3.7646484-1.21875,4.203125-2.2192383c-0.1044312,0.0420532-0.229187,0.0701904-0.3488159,0.1019287   C17.7689819,16.7114258,16.6553345,17.5,14.5,17.5z"
        fill={colors.bloom.dark}
        opacity="0.1"
      />
    </g>
  </svg>
);

export const FemaleIcon = ({ fill = "new 0 0 24 24", size = "24px" }) => (
  <svg
    enableBackground={fill}
    fill="#fff"
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <g>
      <path
        d="M12,0C5.3828125,0,0,5.3833008,0,12s5.3828125,12,12,12s12-5.3833008,12-12S18.6171875,0,12,0z"
        fill={colors.orange.dark}
      />
      <path
        d="M12,23C5.9345703,23,1,18.0654297,1,12S5.9345703,1,12,1s11,4.9345703,11,11S18.0654297,23,12,23z"
        fill={colors.white.main}
      />
      <path
        d="M19.119751,3.630249C20.7561646,5.5509644,21.75,8.0349121,21.75,10.75   c0,6.0654297-4.9345703,11-11,11c-2.7150879,0-5.1990356-0.9938354-7.119751-2.630249C5.6494751,21.4898682,8.6496582,23,12,23   c6.0654297,0,11-4.9345703,11-11C23,8.6496582,21.4898682,5.6494751,19.119751,3.630249z"
        fill={colors.orange.main}
        opacity="0.1"
      />
      <circle cx="8" cy="9" fill={colors.orange.dark} r="1" />
      <circle cx="16" cy="9" fill={colors.orange.dark} r="1" />
      <path
        d="M15.3310547,15.4980469c-0.9375-0.7036133-2.4169922-0.6201172-3.2666016,0.230957l-0.0693359,0.0698242   l-0.1005859-0.0966797c-0.8515625-0.8251953-2.2802734-0.9145508-3.2246094-0.2041016l-1.4697266,1.1020508   c-0.2177734,0.1630859-0.2646484,0.4697266-0.1074219,0.6904297l1.3378906,1.8725586C9.2519531,20.3134766,10.5869141,21,12,21   s2.7480469-0.6865234,3.5693359-1.8369141l1.3378906-1.8725586c0.1572266-0.2207031,0.1103516-0.5273438-0.1074219-0.6904297   L15.3310547,15.4980469z"
        fill={colors.orange.dark}
      />
      <path
        d="M9.2705078,16.2978516c0.5644531-0.425293,1.4189453-0.3720703,1.9287109,0.1220703l0.453125,0.4389648   c0.1953125,0.190918,0.5078125,0.1879883,0.7011719-0.0048828l0.4189453-0.418457   c0.4931641-0.4951172,1.3789063-0.5717773,1.9580078-0.1376953l0.8457031,0.6340942   C14.3476563,17.4832153,13.0147705,17.5,12.9990234,17.5c-0.0771484,0-0.1533203,0.0180664-0.2226563,0.0527344L12,17.940918   l-0.7763672-0.3881836C11.1542969,17.5180664,11.0771484,17.5,11,17.5c-0.8225098,0-1.8337402-0.3410645-2.5145874-0.6140747   L9.2705078,16.2978516z"
        fill={colors.white.main}
      />
      <path
        d="M13.9110107,16.4552612l0.5114136,0.8527222   c0.3660278-0.0838623,0.7631836-0.2007446,1.1537476-0.3760376l-0.8457031-0.6340942   c-0.3491821-0.2617188-0.8041992-0.3171997-1.2230835-0.2277832   C13.6520386,16.1339722,13.7912598,16.2556763,13.9110107,16.4552612z"
        fill={colors.orange.dark}
        opacity="0.1"
      />
      <path
        d="M12,20c-1.0908203,0-2.1210938-0.5302734-2.7548828-1.4179688l-0.3451538-0.4827271   c0.6186523,0.2020874,1.3260498,0.3787842,1.9779663,0.3987427l0.8984375,0.4492188C11.8466797,18.9824219,11.9238281,19,12,19   s0.1533203-0.0175781,0.2236328-0.0527344l0.8994141-0.4501953c0.2905273-0.0103149,1.0494995-0.064209,1.9121704-0.3071899   l-0.2803345,0.3921509C14.1210938,19.4697266,13.0908203,20,12,20z"
        fill={colors.white.main}
      />
      <path
        d="M14.3907471,18.3427124l-0.1710815,0.2393188   c-0.5808716,0.8135986-1.4989014,1.3056641-2.4872437,1.3856812C11.8224487,19.9749756,11.9089355,20,12,20   c1.0908203,0,2.1210938-0.5302734,2.7548828-1.4179688l0.2803345-0.3921509   C14.8106689,18.2531128,14.5947876,18.3024902,14.3907471,18.3427124z"
        fill={colors.orange.dark}
        opacity="0.1"
      />
    </g>
  </svg>
);

export const NoGenderPreferenceIcon = ({
  fill = "new 0 0 24 24",
  size = "24px",
}) => (
  <svg
    enableBackground={fill}
    fill="#fff"
    height={size}
    viewBox="0 0 24 24"
    width={size}
  >
    <g>
      <path
        d="M12,0C5.3833008,0,0,5.3833008,0,12s5.3833008,12,12,12s12-5.3833008,12-12S18.6166992,0,12,0z"
        fill={colors.green.dark}
      />
      <path
        d="M12,23C5.9345703,23,1,18.0654297,1,12S5.9345703,1,12,1s11,4.9345703,11,11S18.0654297,23,12,23z"
        fill={colors.white.main}
      />
      <path
        d="M19.119751,3.630249C20.7561646,5.5509644,21.75,8.0349121,21.75,10.75   c0,6.0654297-4.9345703,11-11,11c-2.7150879,0-5.1990356-0.9938354-7.119751-2.630249C5.6494751,21.4898682,8.6496582,23,12,23   c6.0654297,0,11-4.9345703,11-11C23,8.6496582,21.4898682,5.6494751,19.119751,3.630249z"
        fill={colors.green.main}
        opacity="0.1"
      />
      <path
        d="M18.4399414,14.0209961c-0.2709961-0.0649414-0.5385742,0.1020508-0.6020508,0.3706055   C17.1933594,17.1049805,14.7929688,19,12,19c-2.7963867,0-5.1977539-1.8979492-5.8393555-4.6147461   c-0.0639648-0.2695313-0.3349609-0.4331055-0.6015625-0.3720703c-0.269043,0.0634766-0.4355469,0.3330078-0.3720703,0.6015625   C5.9360352,17.7856445,8.737793,20,12,20c3.2583008,0,6.059082-2.2109375,6.8105469-5.3769531   C18.8745117,14.3540039,18.7084961,14.0844727,18.4399414,14.0209961z"
        fill={colors.green.dark}
      />
      <circle cx="8" cy="9" fill={colors.green.dark} r="1" />
      <circle cx="16" cy="9" fill={colors.green.dark} r="1" />
    </g>
  </svg>
);
