import { useMutation } from "@apollo/client";
import { DELETE_CALENDAR_EVENT } from "../mutations";
import { DeleteCalendarEventsInput } from "../../__generated__/graphql";

export default function useDeleteCalendarEvents() {
  const [execute, { data, loading, error }] = useMutation(
    DELETE_CALENDAR_EVENT,
  );

  function deleteCalendarEvents(input: DeleteCalendarEventsInput) {
    return execute({
      variables: { input },
    });
  }

  return {
    data: data?.deleteCalendarEvents,
    deleteCalendarEvents,
    error,
    loading,
  };
}
