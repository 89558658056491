import { Theme, styled } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";
import Tooltip from "../../ui/Tooltip";

const PREFIX = "SidebarNavigationAction";

const classes = {
  actionItem: `${PREFIX}-actionItem`,
  itemActive: `${PREFIX}-itemActive`,
  listLinkLabel: `${PREFIX}-listLinkLabel`,
};

const Root = styled("button")<{ tablet: boolean }>(({ theme, tablet }) => ({
  [`&.${classes.actionItem}`]: {
    "&:hover": {
      background: theme.palette.bloom.main,
    },
    background: "transparent",
    border: "none",
    borderRadius: 5,
    color: theme.palette.white.main,
    cursor: "pointer",
    display: "flex",
    justifyContent: tablet ? "center" : "left",
    margin: "2px 5px",
    padding: "10px 15px",
    textDecoration: "none",
  },

  [`& .${classes.listLinkLabel}`]: {
    fontSize: 16,
    fontWeight: 500,
    margin: "0 0 0 16px",
  },

  [`& .${classes.itemActive}`]: {
    background: theme.palette.tertiary.main,
    position: "relative",
  },
}));

export default function SidebarNavigationAction({ label, onClick, icon, id }) {
  const tablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.between("sm", "lg"),
  );

  return (
    <Root
      className={classes.actionItem}
      id={id}
      onClick={onClick}
      tablet={tablet}
      type="button"
    >
      {tablet ? (
        <Tooltip text={label} variant="light">
          <span>{icon}</span>
        </Tooltip>
      ) : (
        <>
          {icon}
          <Typography className={classes.listLinkLabel}>{label}</Typography>
        </>
      )}
    </Root>
  );
}
