import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERY_GET_FULL_CALENDAR_EVENTS } from "../queries";
import { getFragmentData } from "../../__generated__";
import { FULL_CALENDAR_EVENT_FRAGMENT } from "../fragments";
import { FullCalendarEventFragment } from "../../__generated__/graphql";

export default function useFetchCalendarEvents(start: Date, end: Date) {
  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_FULL_CALENDAR_EVENTS,
    {
      variables: {
        endDate: end,
        startDate: start,
      },
    },
  );

  const dataWithClasses = useMemo(() => {
    return data?.calendarEvents?.reduce((acc, calendarEvent) => {
      if (calendarEvent?.__typename === "FullCalendarEvent") {
        acc.push(getFragmentData(FULL_CALENDAR_EVENT_FRAGMENT, calendarEvent));
      }

      return acc;
    }, [] as FullCalendarEventFragment[]);
  }, [data?.calendarEvents]);

  return {
    data: dataWithClasses,
    error,
    loading,
    refetch,
  };
}
