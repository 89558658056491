import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext } from "react";
import ClearIcon from "@mui/icons-material/Clear";

import { useTranslation } from "react-i18next";
import ConsultationDateSelection from "../../../consultations/ConsultationDateSelection";
import ConsultationPriceSelection from "../../../consultations/ConsultationPriceSelection";
import Button from "../../../ui/buttons/Button";
import AddConsultationInCallContext, {
  IAddConsultationInCallContext,
} from "../context/AddConsultationInCallContext";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../language/I18Namespaces";
import { ICallContext } from "../../../ui/call/context/Call";
import CallContext from "../../../ui/call/context/CallContext";
import LoadingPage from "../../../layout/LoadingPage";
import { ChimeVideoCall } from "../../../ui/call/ChimeVideoCall.types";

const PREFIX = "ConsultationBooker";

const classes = {
  actions: `${PREFIX}-actions`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(() => ({
  [`& .${classes.actions}`]: {
    "& .clearBox": {
      flexGrow: 1,
      height: 50,
      overflow: "hidden",
      textAlign: "left",
    },
    "& .okBox": {
      flexGrow: 5,
    },
    display: "flex",
    flexFlow: "row nowrap",
    flexGrow: 1,
    height: 50,
    marginBottom: 30,

    marginTop: 25,

    textAlign: "center",
  },
}));

export default function ConsultationBooker() {
  const { formState, newConsult, human, professional, createNewConsult } =
    useContext(AddConsultationInCallContext);

  const { setPaneToPresent } = useContext<ICallContext>(CallContext);

  const { isBooking } = useContext<IAddConsultationInCallContext>(
    AddConsultationInCallContext,
  );

  const { t: translate } = useTranslation<I18Namespaces>([
    BloomUpNamespacesEnum.Common,
  ]);

  if (isBooking) return <LoadingPage isTransparant />;

  return (
    <Root>
      <ConsultationDateSelection
        consultation={newConsult}
        formState={formState}
        isEditing={true}
        narrow
        professional={professional}
      />
      <ConsultationPriceSelection
        canPayWithCredits={human.canPayWithCredits}
        creditsLeft={human.getCreditsLeft()}
        formState={formState}
        isEditing={true}
        narrow
      />
      <Box className={classes.actions}>
        <Box className="okBox">
          <Button
            label={translate("common:book", "Boek")}
            onClick={() => {
              createNewConsult();
            }}
          />
        </Box>
        <Box className="clearBox">
          <Button
            bloomColor="red"
            icon={<ClearIcon />}
            noLabel
            onClick={() =>
              setPaneToPresent(ChimeVideoCall.SidePaneProfessional.menuPane)
            }
          />
        </Box>
      </Box>
    </Root>
  );
}
