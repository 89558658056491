/**
 * Password of user, psy or client.
 */
import type { TFunction } from "i18next";
import {
  BloomUpNamespacesEnum,
  I18Namespaces,
} from "../../../components/language/I18Namespaces";
import { IFormValidation } from "../../../utils/forms/createFormValidation";
import DataType from "../../DataType";
import { DataTypePaths } from "../../dataTypePaths";

export const passwordValidationMessage =
  "Zorg voor een sterk wachtwoord van minimum 8 karakters met minstens 1 hoofdletter, 1 cijfer en 1 speciaal karakter.";

export default class Password extends DataType<
  string,
  DataTypePaths.Profile.Password
> {
  protected static path = "password";
  protected static defaultValue = "";

  protected static passwordRegex = /.{16,}/;

  protected type: any = Password;

  /**
   * Return an array with validation functions for this data type.
   *
   * @param  {any} translate The translate function for validation strings.
   */
  static getFormValidation(
    translate: TFunction<I18Namespaces>,
  ): Array<IFormValidation> {
    return [
      {
        message: translate(
          `${BloomUpNamespacesEnum.Validation}:password.strength`,
          passwordValidationMessage,
        ),
        path: this.getPath(),
        validate: (password: string): boolean =>
          Password.passwordRegex.test(password),
      },
      {
        message: translate(
          `${BloomUpNamespacesEnum.Validation}:password.mandatory`,
          "Geef een wachtwoord op.",
        ),
        path: this.getPath(),
        validate: (password: string): boolean =>
          password !== undefined && password.length > 0,
      },
      {
        message: translate(
          `${BloomUpNamespacesEnum.Validation}:password.equal`,
          "Beide wachtwoorden moeten gelijk zijn.",
        ),
        path: "repeatPassword",
        validate: (password, other): boolean => {
          return password === other.password;
        },
      },
    ];
  }

  getAsFormStateValue(): Partial<
    Record<DataTypePaths.Profile.Password, string>
  > {
    return { password: this.value };
  }
}
