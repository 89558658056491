/* eslint-disable react-hooks/exhaustive-deps */

import { Theme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserRoles } from "../../data-model/types/User";
import LeftSection from "../layout/LeftSection";
import RightSection from "../layout/RightSection";
import AddConsultationPageWithState from "../pages/add-consultation/AddConsultationPageWithState";
import { IRequestConsultationContext } from "../pages/human-request-consultation/context/RequestConsultation";
import RequestConsultationContext from "../pages/human-request-consultation/context/RequestConsultationContext";
import RequestConsultationPage from "../pages/human-request-consultation/RequestConsultationPage";
import { I18Namespaces } from "../language/I18Namespaces";
import { IConsultationsOverviewContext } from "./context/consultations";
import ConsultationsOverviewContext from "./context/ConsultationsOverviewContext";
import ConsultationDetailWithState from "./detail/ConsultationDetailWithState";
import ConsultationsOverview from "./overview/ConsultationsOverview";

interface IConsultationsPage {
  addButtonIcon?: any;
  addButtonLabel?: string | null;
  pageTitle: string;
  userRole: UserRoles;
}

const shouldShowForUser = (userRole: UserRoles): boolean =>
  userRole === UserRoles.HUMAN ||
  userRole === UserRoles.PROFESSIONAL ||
  userRole === UserRoles.ADMIN;

export default function ConsultationsPage({
  pageTitle,
  userRole,
  addButtonIcon,
  addButtonLabel,
}: IConsultationsPage) {
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t: translate } = useTranslation<I18Namespaces>(["common"]);

  const icon: string = addButtonIcon ?? <AddIcon />;
  const label: string = addButtonLabel ?? translate("common:add", "Toevoegen");

  const { resetRequestConsultationForm } =
    useContext<IRequestConsultationContext>(RequestConsultationContext);

  const {
    selectedConsultation,
    showAddConsultation,
    closeConsultation,
    openAddConsultation,
  } = useContext<IConsultationsOverviewContext>(ConsultationsOverviewContext);

  useEffect(() => {
    if (showAddConsultation) resetRequestConsultationForm();
  }, [showAddConsultation]);

  const rightSectionContent = () => {
    return (
      <>
        {showAddConsultation &&
          (userRole === UserRoles.PROFESSIONAL ||
            userRole === UserRoles.ADMIN) && <AddConsultationPageWithState />}
        {showAddConsultation && userRole === UserRoles.HUMAN && (
          <RequestConsultationPage />
        )}
        {!showAddConsultation && (
          <ConsultationDetailWithState userRole={userRole} />
        )}
      </>
    );
  };

  if (small) {
    if (!selectedConsultation && !showAddConsultation) {
      return (
        <LeftSection
          buttonIcon={shouldShowForUser(userRole) ? icon : undefined}
          buttonLabel={shouldShowForUser(userRole) ? label : undefined}
          handleTitleButton={openAddConsultation}
          pageTitle={pageTitle}
          short={false}
        >
          <ConsultationsOverview />
        </LeftSection>
      );
    } else {
      return (
        <RightSection
          handleClose={closeConsultation}
          title={translate("common:consultation", "Consultatie")}
        >
          {rightSectionContent()}
        </RightSection>
      );
    }
  }

  return (
    <>
      <LeftSection
        buttonIcon={shouldShowForUser(userRole) ? icon : undefined}
        buttonLabel={shouldShowForUser(userRole) ? label : undefined}
        handleTitleButton={openAddConsultation}
        pageTitle={pageTitle}
        short={false}
      >
        <ConsultationsOverview />
      </LeftSection>
      {(selectedConsultation || showAddConsultation) && (
        <RightSection
          handleClose={closeConsultation}
          title={translate("common:consultation", "Consultatie")}
        >
          {rightSectionContent()}
        </RightSection>
      )}
    </>
  );
}
