import { useLazyQuery } from "@apollo/client";
import { useMemo } from "react";
import { QUERY_GET_CASH_TRANSACTIONS_BY_CONSULTATION_ID } from "../queries";
import CashTransaction, {
  CashTransactionModel,
} from "../../../data-model/types/CashTransaction";

interface IGetCashTransaction {
  cashTransactionsByConsultationId: Array<CashTransactionModel>;
}

interface IGetCashTransactionInput {
  consultationId: number;
}

export default function useLazyGetCashTransactionsByConsultationId() {
  const [execute, { data, loading, error, refetch }] = useLazyQuery<
    IGetCashTransaction,
    IGetCashTransactionInput
  >(QUERY_GET_CASH_TRANSACTIONS_BY_CONSULTATION_ID);

  const getCashTransaction = (consultationId: number) =>
    execute({
      variables: {
        consultationId,
      },
    });

  const cashTransactions: Array<CashTransaction> = useMemo<
    Array<CashTransaction>
  >(() => {
    if (data && data.cashTransactionsByConsultationId) {
      return data.cashTransactionsByConsultationId.map(
        (c) => new CashTransaction(c),
      );
    } else {
      return [];
    }
  }, [data]);

  return {
    cashTransactions: cashTransactions,
    error,
    getCashTransaction,
    loading,
    refetch,
  };
}
